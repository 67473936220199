import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AmountType } from 'schema';

export type ValidateAmountProps = {
  required: boolean;
  allowZero: boolean;
  type: AmountType;
  value: any;
};

export type UseAmountValidationProps = {
  max?: number;
};

export const useAmountValidation = (props: UseAmountValidationProps) => {
  const { t } = useTranslation();

  const { max = 99999999999 } = props;

  const greaterThanZeroErrorMessage = t('GreaterThanZeroErrorMessage');
  const lessThan100ErrorMessage = t('LessThan100ErrorMessage');
  const isRequiredMessage = t('IsRequiredMessage');
  const greaterThanMaxErrorMessage = t('GreaterThanMaxErrorMessage', { max });

  const validateAmount = useCallback(
    (props: ValidateAmountProps) => {
      const { required, allowZero, type, value } = props;
      const isString = typeof value === 'string';
      const hasValue = (value !== undefined && value !== null) || (isString && value !== '');
      if (required && !hasValue) {
        return isRequiredMessage;
      }

      if (hasValue) {
        const numberValue = isString ? parseFloat(value as string) : (value as number);

        if (isNaN(numberValue) || numberValue < 0) {
          return greaterThanZeroErrorMessage;
        }

        if (required && numberValue === 0 && !allowZero) {
          return greaterThanZeroErrorMessage;
        }

        if (max && numberValue > max) {
          return greaterThanMaxErrorMessage;
        }

        if (type === AmountType.Percents && numberValue > 100) {
          return lessThan100ErrorMessage;
        }
      }
    },
    [
      greaterThanZeroErrorMessage,
      lessThan100ErrorMessage,
      isRequiredMessage,
      max,
      greaterThanMaxErrorMessage,
    ]
  );

  return { validateAmount };
};
