import { useParams } from 'react-router-dom';
import { RatingSortBy, SortOrder } from 'schema';
import { useRatingsQuery } from 'services/api/useRatingsBackend';
import { UserViewModel } from '../../users/types';

export type UseCounterpartyRatingsProps = {
  users?: UserViewModel[];
  order: SortOrder;
  sortBy: RatingSortBy;
};

export const useCounterpartyRatings = (props: UseCounterpartyRatingsProps) => {
  const { inn } = useParams<{ inn: string }>();
  const { users, order, sortBy } = props;

  const { data: ratings, ...rest } = useRatingsQuery(inn, sortBy, order, users);

  return {
    ratings,
    ...rest,
  };
};
