import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2.5),
      marginBottom: theme.spacing(2),
    },
    paper: {
      '&+& $divider': {
        display: 'block',
      },
    },
    divider: {
      display: 'none',
      marginBottom: theme.spacing(2.5),
    },
    delete: {
      marginTop: 3,
    },
    add: {
      color: theme.palette.primary.main,
    },
    button: {
      display: 'block',
    },
  })
);
