import { RatingViewModel } from 'schema/serverTypes';
import { formatNumber, Grid, IconSprite } from 'components';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { themeOrange as theme } from 'theme';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { getRatingString } from './getRatingString';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(1, 0, 1, 2.5),
    },
    label: {
      fontSize: 9,
      whiteSpace: 'nowrap',
      color: theme.palette.text.secondary,
    },
    value: {
      fontSize: 12,
      color: theme.palette.common.black,
    },
    gradeWrapper: {
      '&&': {
        backgroundColor: theme.palette.secondary.light,
        lineHeight: 1.2,
        display: 'flex',
        alignItems: 'center',
      },
    },
    gradeLabel: {
      whiteSpace: 'nowrap',
      fontSize: 12,
      color: theme.palette.text.primary,
      paddingLeft: theme.spacing(1.5),
    },
    gradeValue: {
      fontSize: 24,
      fontWeight: 700,
      color: theme.palette.common.black,
      '&$gradeRed': {
        color: theme.palette.error.main,
      },
    },
    gradeRed: {},
    compare: {
      cursor: 'pointer',
    },
    colorValueRed: {
      color: theme.palette.error.main,
    },
    colorValueGreen: {
      color: theme.palette.green.main,
    },
  })
);

type RatingOutputProps = {
  rating: RatingViewModel;
  compareIds: number[];
  setCompareIds: Dispatch<SetStateAction<number[]>>;
};

export const RatingCompareOutput = (props: RatingOutputProps) => {
  const classes = useStyles();

  const { rating, compareIds, setCompareIds } = props;

  const { output, id: ratingId } = rating;

  const handlerUpdateCompare = useCallback(() => {
    setCompareIds((prev: number[]) => {
      if (prev.indexOf(ratingId) >= 0) {
        return [
          ...prev.slice(0, prev.indexOf(ratingId)),
          ...prev.slice(prev.indexOf(ratingId) + 1),
        ];
      } else {
        return [...prev, ratingId];
      }
    });
  }, [setCompareIds, ratingId]);

  const ratingLabel = getRatingString(rating.output.ratingGrade, '-');

  return (
    <>
      <Grid container columnSpacing={0} rowSpacing={2} className={classes.container}>
        <Grid item xs={10}>
          <span className={classes.label}>Total Score</span>
          <br />
          <span className={classes.value}>{output ? output?.totalScore : '-'}</span>
        </Grid>
        <Grid item xs={14}>
          <span className={classes.label}>Расчетный рейтинг</span>
          <br />
          <span className={classes.value}>{ratingLabel}</span>
        </Grid>
        <Grid item xs={10}>
          <span className={classes.label}>Возраст</span>
          <br />
          <span
            className={clsx(
              classes.value,
              (output?.ageCorrection ?? 0) < 0
                ? classes.colorValueGreen
                : (output?.ageCorrection ?? 0) > 0
                ? classes.colorValueRed
                : null
            )}
          >
            {(output?.ageCorrection ?? 0) > 0 ? '+' : ''}
            {output?.ageCorrection === 0 ? '-' : output?.ageCorrection}
          </span>
        </Grid>
        <Grid item xs={14}>
          <span className={classes.label}>Корректировка</span>
          <br />
          <span
            className={clsx(
              classes.value,
              (output?.manualCorrection ?? 0) < 0
                ? classes.colorValueGreen
                : (output?.manualCorrection ?? 0) > 0
                ? classes.colorValueRed
                : null
            )}
          >
            {output?.manualCorrection === 0 ? '-' : output?.manualCorrection}
          </span>
        </Grid>
        <Grid item xs={10}>
          <span className={classes.label}>Calculated PD (bp)</span>
          <br />
          <span className={classes.value}>
            {output ? `${formatNumber(output?.calculatedPD, 6)} %` : '-'}
          </span>
        </Grid>
        <Grid item xs={14}>
          <span className={classes.label}>Итоговый PD</span>
          <br />
          <span className={classes.value}>
            {output ? `${formatNumber(output?.finalPD, 6)} %` : '-'}
          </span>
        </Grid>
        <Grid xs={14} item className={classes.gradeWrapper}>
          <span className={classes.gradeLabel}>Итоговый рейтинг</span>
        </Grid>
        <Grid xs={8} item className={classes.gradeWrapper}>
          <span className={clsx(classes.gradeValue)}>{ratingLabel}</span>
        </Grid>
        <Grid xs={2} item className={classes.gradeWrapper}>
          <div onClick={handlerUpdateCompare} className={classes.compare}>
            <IconSprite
              icon="compare"
              width="14px"
              color={
                compareIds.indexOf(ratingId) >= 0
                  ? theme.palette.error.main
                  : theme.palette.text.secondary
              }
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};
