import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useIndustriesData } from './useIndustriesData';
import { ApplicationRole, IndustryViewModel } from 'schema';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TableSortLabel } from 'components/data-grid/TableSortLabel';
import { Role } from '../../authentication';
import { EditButton } from '../EditButton';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: 'auto',
      maxWidth: 'calc(100vw - 190px - 48px - var(--scrollbar-width)) ',
      '& .MuiTableBody-root .MuiTableRow-root:hover .MuiTableCell-root': {
        backgroundColor: theme.palette.background.light,
      },
    },
    wrapper: {
      position: 'relative',
      overflow: 'auto',
      border: '1px solid black',
      whiteSpace: 'nowrap',
    },
    fixed: {
      position: 'sticky',
      backgroundColor: '#FFFFFF',
      zIndex: 1,
      minWidth: 300,
      width: 300,
      left: 0,
      '&:after': {
        content: "''",
        top: 0,
        right: '0',
        position: 'absolute',
        display: 'block',
        width: 2,
        height: '100%',
        backgroundColor: theme.palette.mobileGray.main,
        boxShadow: '5px 0px 8px 3px #0000001A',
      },
    },
    chart: {
      position: 'absolute',
      minWidth: 100,
      left: 1080,
    },
    value: {
      '&:nth-child(2)': {
        padding: '16px 6px 16px 20px',
        minWidth: 68,
      },
      '&:nth-child(3)': {
        minWidth: 80,
      },
      '&:nth-child(4)': {
        minWidth: 80,
      },
      '&:nth-child(5)': {
        minWidth: 100,
      },
      '&:nth-child(6)': {
        minWidth: 150,
      },
      '&:nth-child(7)': {
        minWidth: 202,
      },
      '&:nth-child(8)': {
        minWidth: 152,
      },
      '&:nth-child(9)': {
        minWidth: 136,
      },
      '&:last-child': {
        minWidth: 150,
        padding: '16px 20px 16px 6px',
      },
    },
    row: {
      '&:hover $editBtn': {
        display: 'block',
      },
    },
    edit: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      position: 'sticky',
      right: 0,
      width: 80,
    },
    editBtn: {
      display: 'none',
    },
  })
);

export type IndustriesTableProps = Omit<ReturnType<typeof useIndustriesData>, 'paging' | 'filter'>;

type TableItemProps = {
  row: IndustryViewModel;
};

const TableItem = (props: TableItemProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { row } = props;

  const answer1 =
    (row.answer1 as unknown as string) === 'high'
      ? t('Answer1.High')
      : (row.answer1 as unknown as string) === 'medium'
      ? t('Answer1.Medium')
      : (row.answer1 as unknown as string) === 'low'
      ? t('Answer1.Low')
      : null;

  const answer2 =
    (row.answer2 as unknown as string) === 'positively'
      ? t('Answer2.Positively')
      : (row.answer2 as unknown as string) === 'neutrally'
      ? t('Answer2.Neutrally')
      : (row.answer2 as unknown as string) === 'negatively'
      ? t('Answer2.Negatively')
      : null;

  const answer3 =
    (row.answer3 as unknown as string) === 'outperformsCompetition'
      ? t('Answer3.OutperformsCompetition')
      : (row.answer3 as unknown as string) === 'roughtlyInLineWithCompetition'
      ? t('Answer3.RoughtlyInLineWithCompetition')
      : (row.answer3 as unknown as string) === 'dontKnow'
      ? t('Answer3.DontKnow')
      : (row.answer3 as unknown as string) === 'oftenBelowCompetition'
      ? t('Answer3.OftenBelowCompetition')
      : null;

  const answer4 =
    (row.answer4 as unknown as string) === 'stable'
      ? t('Answer4.Stable')
      : (row.answer4 as unknown as string) === 'slightlyVolatile'
      ? t('Answer4.SlightlyVolatile')
      : (row.answer4 as unknown as string) === 'moderateVolatile'
      ? t('Answer4.ModerateVolatile')
      : (row.answer4 as unknown as string) === 'highlyVolatile'
      ? t('Answer4.HighlyVolatile')
      : null;

  const answer5 =
    (row.answer5 as unknown as string) === 'veryGood'
      ? t('Answer5.VeryGood')
      : (row.answer5 as unknown as string) === 'good'
      ? t('Answer5.Good')
      : (row.answer5 as unknown as string) === 'average'
      ? t('Answer5.Average')
      : (row.answer5 as unknown as string) === 'belowAverage'
      ? t('Answer5.BelowAverage')
      : (row.answer5 as unknown as string) === 'poor'
      ? t('Answer5.Poor')
      : (row.answer5 as unknown as string) === 'dontKnow'
      ? t('Answer5.DontKnow')
      : null;

  const rowAnswer6 = row.answer6 as unknown as string;
  const answer6 =
    rowAnswer6 === 'nonePresent'
      ? t('Answer6.NonePresent')
      : rowAnswer6 === 'continuosFullUsage'
      ? t('Answer6.ContinuosFullUsage')
      : rowAnswer6 === 'otherFinancialInstitutions'
      ? t('Answer6.OtherFinancialInstitutions')
      : rowAnswer6 === 'othersNamelyHigh'
      ? 'Прочее, а именно ….. (высокий риск)'
      : rowAnswer6 === 'othersNamelyAverage'
      ? 'Прочее, а именно ….. (средний риск)'
      : 'перевод отсутствует';

  const history = useHistory();
  const editHandler = () => {
    history.push(`/dictionaries/industries/${row.id}`);
  };

  return (
    <>
      <TableRow className={classes.row}>
        <TableCell size="medium" className={classes.fixed}>
          {row.name} (
          <Link to={`/counterparties?industryId=${row.id}`}>{row.counterpartiesCount ?? 0}</Link>)
        </TableCell>
        <TableCell size="medium" className={classes.value}>
          {row.parameterEstimation ? t('Yes') : t('No')}
        </TableCell>
        <TableCell size="medium" className={classes.value}>
          {row.raks ? t('Yes') : t('No')}
        </TableCell>
        <TableCell size="medium" className={classes.value}>
          {answer1}
        </TableCell>
        <TableCell size="medium" className={classes.value}>
          {answer2}
        </TableCell>
        <TableCell size="medium" className={classes.value}>
          {answer3}
        </TableCell>
        <TableCell size="medium" className={classes.value}>
          {answer4}
        </TableCell>
        <TableCell size="medium" className={classes.value}>
          {answer5}
        </TableCell>
        <TableCell size="medium" className={classes.value}>
          {answer6}
        </TableCell>
        <Role role={[ApplicationRole.RiskManager, ApplicationRole.Admin]}>
          <TableCell size="medium" className={classes.edit}>
            <div className={classes.editBtn}>
              <EditButton onClick={editHandler} />
            </div>
          </TableCell>
        </Role>
      </TableRow>
    </>
  );
};

export const IndustriesTable = (props: IndustriesTableProps) => {
  const classes = useStyles();
  const { data, sorting } = props;
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell size="medium" className={classes.fixed}>
              <TableSortLabel columnName="name" sorting={sorting}>
                {t('Industry/Branch')}
              </TableSortLabel>
            </TableCell>
            <TableCell size="medium" className={classes.value}>
              <TableSortLabel columnName="parameterEstimation" sorting={sorting}>
                {t('Estimate')}
              </TableSortLabel>
            </TableCell>
            <TableCell size="medium" className={classes.value}>
              <TableSortLabel columnName="raks" sorting={sorting}>
                {t('Raks')}
              </TableSortLabel>
            </TableCell>
            <TableCell size="medium" className={classes.value}>
              {t('Concentration')}
            </TableCell>
            <TableCell size="medium" className={classes.value}>
              {t('Development impact')}
            </TableCell>
            <TableCell size="medium" className={classes.value}>
              {t('Comparison with competitors')}
            </TableCell>
            <TableCell size="medium" className={classes.value}>
              {t('Volatility')}
            </TableCell>
            <TableCell size="medium" className={classes.value}>
              {t('Quality of management')}
            </TableCell>
            <TableCell size="medium" className={classes.value}>
              {t('Signals')}
            </TableCell>
            <Role role={[ApplicationRole.RiskManager, ApplicationRole.Admin]}>
              <TableCell size="medium" className={classes.edit} />
            </Role>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <TableItem key={item.name} row={item} />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
