import { useCallback, useEffect } from 'react';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import { useNewsBackendQuery, useNewsReadMutation } from 'services/api';
import { ApplicationRole, NewsData } from 'schema/serverTypes';
import { Box } from '@material-ui/core';
import { AddButton } from '../dictionaries/AddButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { NewsFeedItem } from './NewsFeedItem';
import { ModalForm, useModalForm } from '../Modal';
import { NewsCreateForm } from './NewsCreateForm';
import { Empty } from '../Empty';
import { Role } from '../authentication';

dayjs.extend(isToday);
dayjs.extend(isYesterday);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'white',
      padding: theme.spacing(2, 1.5),
    },
  })
);

export const NewsFeed = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { onOpen, onClose, open } = useModalForm();

  const handleOnOpen = useCallback(() => {
    onOpen();
  }, [onOpen]);

  const handleOnClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const { data } = useNewsBackendQuery<NewsData>({
    relativeUrl: '',
    queryKey: 'newsFeed',
    options: {
      retry: true,
      retryDelay: 60 * 1000,
      cacheTime: 60 * 1000,
    },
  });

  const { mutateAsync } = useNewsReadMutation();

  useEffect(() => {
    mutateAsync({ date: dayjs().toISOString() });
  }, [mutateAsync]);

  const news = data?.data ?? [];

  return (
    <>
      <Box display="flex" justifyContent="flex-end" mb={2.5} alignItems="flex-end">
        <Box>
          <Role role={['admin', 'super_sales_manager', ApplicationRole.RiskManager]}>
            <AddButton onClick={handleOnOpen}>{t('Add news')}</AddButton>
          </Role>
        </Box>
      </Box>
      <Box className={classes.root}>
        {news.length === 0 && <Empty>{t("You don't have any news yet")}</Empty>}
        {news.map((item) => {
          return <NewsFeedItem key={item.id} {...item} />;
        })}
      </Box>
      <ModalForm open={open} onClose={handleOnClose}>
        <NewsCreateForm onSuccess={handleOnClose} />
      </ModalForm>
    </>
  );
};
