const initialData = {
  tasks: {
    'i-1': { id: 'i-1', content: 'Комбайн' },
    'i-2': { id: 'i-2', content: 'Телескопический погрузчик' },
    'i-3': { id: 'i-3', content: 'Опрыскиватель самох.' },
    'i-4': { id: 'i-4', content: 'Комбайн 276 л.с.' },
    'i-5': { id: 'i-5', content: 'Трактор' },
    'i-6': {
      id: 'i-6',
      content: 'Прицепной высокопроизводительный распределитель минеральных удобрений',
    },
    'i-7': { id: 'i-7', content: 'Борона дисковая прицепная' },
  },
  columns: {
    to: {
      id: 'to',
      title: 'Сельхоз техника',
      taskIds: [],
    },
    from: {
      id: 'from',
      title: 'Не сортированные',
      taskIds: ['i-1', 'i-2', 'i-3', 'i-4', 'i-5', 'i-6', 'i-7'],
    },
  },
  // Facilitate reordering of the columns
  columnOrder: ['to', 'from'],
};

export default initialData;
