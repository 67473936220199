import { Dispatch, SetStateAction, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  RatingInput,
  RatingViewModel,
  RatingQuestionType,
  RatingAnswer6,
} from 'schema/serverTypes';
import { RatingHeader } from './RatingHeader';
import { RatingInputForm } from './RatingInputForm';
import { RatingCompareOutput } from './RatingCompareOutput';
import { useStyles } from './useStyles';
import { RatingInputValues } from './types';
import clsx from 'clsx';
import { formatNumber } from '../../utils';

export type RatingEditFormProps = {
  rating: RatingViewModel;
  compareIds: number[];
  setCompareIds: Dispatch<SetStateAction<number[]>>;
  setOptional: Dispatch<SetStateAction<boolean>>;
  optional: boolean;
};

const useDefaultValues = (input: RatingInput, fromDate?: string, toDate?: string) => {
  return useMemo(() => {
    const { answers, ...rest } = input;

    const formattedValues = Object.entries(rest).map((i) => {
      return [i[0], typeof i[1] === 'number' ? formatNumber(i[1], 0) : i[1]];
    });

    const values: RatingInputValues = {
      ...Object.fromEntries(formattedValues),
      fromDate,
      toDate,
      question1: answers.find((t) => t.questionId === RatingQuestionType.Question1)?.value,
      question2: answers.find((t) => t.questionId === RatingQuestionType.Question2)?.value,
      question3: answers.find((t) => t.questionId === RatingQuestionType.Question3)?.value,
      question4: answers.find((t) => t.questionId === RatingQuestionType.Question4)?.value,
      question5: answers.find((t) => t.questionId === RatingQuestionType.Question5)?.value,
      question6: answers
        .filter((t) => t.questionId === RatingQuestionType.Question6)
        .map((t) => t.value as RatingAnswer6),
      question7: answers.find((t) => t.questionId === RatingQuestionType.Question7)?.value,
      question8: answers.find((t) => t.questionId === RatingQuestionType.Question8)?.value,
      question9: answers.find((t) => t.questionId === RatingQuestionType.Question9)?.value,
      question10: answers.find((t) => t.questionId === RatingQuestionType.Question10)?.value,
      question11: answers.find((t) => t.questionId === RatingQuestionType.Question11)?.value,
      question12: answers.find((t) => t.questionId === RatingQuestionType.Question12)?.value,
      question13: answers.find((t) => t.questionId === RatingQuestionType.Question13)?.value,
      question14: answers.find((t) => t.questionId === RatingQuestionType.Question14)?.value,
      question15: answers.find((t) => t.questionId === RatingQuestionType.Question15)?.value,
      question16: answers.find((t) => t.questionId === RatingQuestionType.Question16)?.value,
      question18: answers.find((t) => t.questionId === RatingQuestionType.Question17)?.value,
      question17: answers.find((t) => t.questionId === RatingQuestionType.Question18)?.value,
    };

    return values;
  }, [input, fromDate, toDate]);
};

export const RatingEditForm = (props: RatingEditFormProps) => {
  const { rating, compareIds, setCompareIds, optional, setOptional } = props;
  const { input, fromDate, toDate } = rating;
  const defaultValues = useDefaultValues(input, fromDate, toDate);

  const classes = useStyles();

  const { control } = useForm<RatingInputValues & { quarter: string; year: string }>({
    mode: 'onBlur',
    defaultValues,
  });

  const ratingClass = clsx(classes.root, classes.noEditMode);

  return (
    <>
      <div className={ratingClass}>
        <RatingHeader
          fromDate={rating.fromDate}
          updatedDate={rating.updatedDate}
          user={rating.user.name}
        />
        <RatingCompareOutput
          rating={rating}
          compareIds={compareIds}
          setCompareIds={setCompareIds}
        />
        <RatingInputForm
          control={control}
          classes={classes}
          optional={optional}
          setOptional={setOptional}
        />
      </div>
    </>
  );
};
