import { Control, useController, useWatch } from 'react-hook-form';
import { CounterpartyFilterFormValues } from '../types';
import { useIndustryPagedListQuery } from 'services/api/useIndustryBackend';
import { Autocomplete } from 'components/Autocomplete';
import { IndustryViewModel } from 'schema/serverTypes';
import { ChangeEvent, useCallback, useState } from 'react';
import { useDebounce } from 'use-debounce';
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
} from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';

const getOptionLabel = (option: IndustryViewModel | null) => {
  if (option === null) {
    return '';
  }
  return option.name;
};

const getOptionSelected = (option: IndustryViewModel | null, value: IndustryViewModel | null) => {
  if (option === null || value === null) {
    return false;
  }
  return option.id === value.id;
};

export type IndustryAutocompleteProps = {
  control: Control<CounterpartyFilterFormValues, any>;
};

export const IndustryAutocomplete = (props: IndustryAutocompleteProps) => {
  const { control } = props;

  const {
    field: { onChange },
  } = useController({ control, name: 'industries' }) ?? [];

  const industries = useWatch({ control, name: 'industries' }) ?? [];

  const industryName = industries.length > 0 ? industries[0].name : '';

  const [input, setInput] = useState(industryName);
  const [inputName] = useDebounce(input, 500);

  const { data } = useIndustryPagedListQuery({ page: 1, pageSize: 20, name: inputName });
  const options = data?.data ?? [];

  const handleOnInputChange = useCallback(
    (_ev: ChangeEvent<{}>, value: string, _reason: AutocompleteInputChangeReason) => {
      setInput(value);
    },
    [setInput]
  );

  const handleOnChange = useCallback(
    (
      _e: ChangeEvent<{}>,
      value: IndustryViewModel | null,
      _reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<IndustryViewModel | null>
    ) => {
      if (value === null) {
        onChange(undefined);
      } else {
        onChange([value]);
      }
    },
    [onChange]
  );

  const selectedOption = options.find((t) => industries.find((i) => i.id === t.id)) ?? null;

  const { t } = useTranslation();

  return (
    <Autocomplete<IndustryViewModel | null>
      label={`${t('Industry/Branch')}`}
      options={options}
      inputValue={input || ''}
      onInputChange={handleOnInputChange}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      onChange={handleOnChange}
      variant={'standard'}
      value={selectedOption}
      popupIcon={<KeyboardArrowDownRoundedIcon color="inherit" fontSize="medium" />}
      openText={t('Open')}
      closeText={t('Close')}
      clearText={t('Clear')}
      autoHighlight={true}
      noOptionsText={t('no results')}
    />
  );
};
