import { Input } from '../../../form';
import { useTranslation } from 'react-i18next';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { CalculatorFormValues } from '../types';
import { useEffect, useRef } from 'react';

type VatInputProps = {
  control: Control<CalculatorFormValues, any>;
  watch: UseFormWatch<CalculatorFormValues>;
  setValue: UseFormSetValue<CalculatorFormValues>;
};

export const VatInput = (props: VatInputProps) => {
  const { t } = useTranslation();

  const { control, watch, setValue } = props;
  const vatEnabled = watch('vatEnabled');
  const vatPercents = watch('vatPercents');
  const vatPercentsValue = useRef(watch('vatPercents'));

  const disabled = !vatEnabled;

  useEffect(() => {
    if (vatPercents && vatPercents > 0) {
      vatPercentsValue.current = vatPercents;
    }
    setValue('vatPercents', vatEnabled ? vatPercentsValue.current : 0);
  }, [vatEnabled, vatPercents, setValue]);

  return (
    <Input
      name="vatPercents"
      label={t('Vat')}
      control={control}
      disabled={disabled}
      rules={{
        maxLength: {
          value: 2,
          message: t('LessThan100ErrorMessage'),
        },
      }}
    />
  );
};
