import { RoleName } from 'schema';
import { useUserAuth, useUserRole } from 'services';

export type RoleProps = {
  role?: RoleName | RoleName[];
  children?: React.ReactNode;
};

export const Role = (props: RoleProps) => {
  const { role, children } = props;

  const { user, isLoading } = useUserAuth();
  const { hasRole } = useUserRole();

  if (user === null || isLoading) {
    return null;
  }

  const userRole: RoleName | undefined = user.profile.role as RoleName;
  if (!hasRole) {
    return null;
  }

  if (Array.isArray(role) && !role.includes(userRole)) {
    return null;
  }
  if (role !== undefined && !Array.isArray(role) && role !== userRole) {
    return null;
  }

  return <>{children}</>;
};
