import { Box, FormControl, MenuItem } from '@material-ui/core';
import { QuotaFilterFormProps } from './types';
import { Tabs } from 'components/Tabs';
import { useController, useWatch } from 'react-hook-form';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { Grid } from 'components/Grid';
import {
  ApplicationUserTab,
  CounterpartyType,
  QuotaStatus,
  ScoringModelType,
  TemplateKind,
} from 'schema';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Input, Button, IconSprite, Select, DownloadTemplateList } from 'components';
import { useTranslation } from 'react-i18next';
import { themeOrange as theme } from 'theme';
import { QuotaOwnerAutocomplete } from './QuotaOwnerAutocomplete';
import { QuotaChips } from './QuotaChips';
import { CounterpartyAutocomplete } from './CounterpartyAutocomplete';
import { QuotaFilterFormContextProvider } from './QuotaFilterFormContextProvider';
import { QuotaLeasingProductAutocomplete } from './QuotaLeasingProductAutocomplete';
import { FormSelect } from 'components/form/FormSelect';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '100%',
    },
    buttonReset: {
      display: 'flex',
      alignItems: 'flex-end',
      marginBottom: theme.spacing(0.2),
    },
    noIcon: {
      '& .MuiTextField-root': {
        '& svg': {
          display: 'none',
        },
      },
    },
    item: {
      marginRight: theme.spacing(2.5),
    },
    actions: {
      '& > *': {
        marginLeft: theme.spacing(1.5),
      },
    },
  })
);

export const QuotaFilterForm = (props: QuotaFilterFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { tabs, control, onReset, setValue } = props;
  const { onChangeTab: onTabIdChange, userTabs = [], ...tabProps } = tabs;

  const {
    field: { onChange, value: tabId = ApplicationUserTab.mine },
  } = useController({ control, name: 'tabId' });

  const onChangeTab = useCallback(
    (e: any, index: number) => {
      onTabIdChange(e, index);
      const tab = userTabs[index];
      if (tab) {
        onChange(tab.id);
      }
    },
    [onTabIdChange, onChange, userTabs]
  );

  const hasExpectedShipmentDate = useWatch({ control, name: 'hasExpectedShipmentDate' }) ?? false;
  const hasShipmentDate = useWatch({ control, name: 'hasShipmentDate' }) ?? false;

  const value = useMemo(() => {
    return hasExpectedShipmentDate && hasShipmentDate
      ? ['hasExpectedShipmentDate', 'hasShipmentDate']
      : hasExpectedShipmentDate
      ? ['hasExpectedShipmentDate']
      : hasShipmentDate
      ? ['hasShipmentDate']
      : [];
  }, [hasExpectedShipmentDate, hasShipmentDate]);

  const onDateChanged = useCallback(
    (
      event: ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>
    ) => {
      const flags = event.target.value as string[] | undefined;
      setValue('hasExpectedShipmentDate', flags?.includes('hasExpectedShipmentDate'));
      setValue('hasShipmentDate', flags?.includes('hasShipmentDate'));
    },
    [setValue]
  );

  return (
    <>
      <form>
        <Box mb={2.5} display="flex" justifyContent="space-between" alignItems="flex-end">
          <Box>
            <Tabs {...tabProps} onChangeTab={onChangeTab} />
          </Box>
          <Box className={classes.actions}>
            <Button variant="contained" to="/quotas/calculator">
              {t('Buttons.AddQuota')}
            </Button>
            <DownloadTemplateList kind={TemplateKind.Quotas} />
          </Box>
        </Box>
        <QuotaFilterFormContextProvider>
          <Box display="flex" justifyContent="space-between" mb={2.5} alignItems="flex-end">
            <Box flex={1}>
              <Grid container columnSpacing={2} rowSpacing={2.5} className={classes.root}>
                <Grid md={3} xs={24} item className={classes.item}>
                  <CounterpartyAutocomplete control={control} type={CounterpartyType.lessee} />
                </Grid>
                <Grid md={3} xs={24} item className={classes.item}>
                  <CounterpartyAutocomplete control={control} type={CounterpartyType.dealer} />
                </Grid>
                <Grid md={3} xs={24} item className={classes.item}>
                  <QuotaLeasingProductAutocomplete control={control} />
                </Grid>
                {tabId !== ApplicationUserTab.mine && (
                  <Grid item md={3} xs={24} xl={4} className={classes.item}>
                    <QuotaOwnerAutocomplete control={control} />
                  </Grid>
                )}
                <Grid md={3} xs={24} item className={classes.item}>
                  <FormSelect
                    label={t('Other')}
                    variant="standard"
                    onChange={onDateChanged}
                    value={value}
                    multiple
                  >
                    <MenuItem key="other1" value="hasExpectedShipmentDate">
                      {t('There is an expected shipment date')}
                    </MenuItem>
                    <MenuItem key="other2" value="hasShipmentDate">
                      {t('There is a shipment date')}
                    </MenuItem>
                  </FormSelect>
                </Grid>
                <Grid md={3} xs={24} item className={classes.item}>
                  <Select
                    label={t('Status')}
                    name="status"
                    control={control}
                    variant="standard"
                    multiple
                  >
                    <MenuItem key={QuotaStatus.Active} value={QuotaStatus.Active}>
                      {t('QuotaStatus.Active')}
                    </MenuItem>
                    <MenuItem key={QuotaStatus.Lost} value={QuotaStatus.Lost}>
                      {t('QuotaStatus.Lost')}
                    </MenuItem>
                    <MenuItem key={QuotaStatus.Archived} value={QuotaStatus.Archived}>
                      {t('QuotaStatus.Archived')}
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid md={3} xs={24} item className={classes.item}>
                  <Select
                    label={t('ScoringModel')}
                    name="scoringModelType"
                    control={control}
                    variant="standard"
                    multiple
                  >
                    <MenuItem key={ScoringModelType.Retail} value={ScoringModelType.Retail}>
                      {t('ScoringModelType.Retail')}
                    </MenuItem>
                    <MenuItem
                      key={ScoringModelType.SevenMillion}
                      value={ScoringModelType.SevenMillion}
                    >
                      {t('ScoringModelType.SevenMillion')}
                    </MenuItem>
                    <MenuItem key={ScoringModelType.Excel} value={ScoringModelType.Excel}>
                      {t('ScoringModelType.Excel')}
                    </MenuItem>
                    <MenuItem key={ScoringModelType.Manual} value={ScoringModelType.Manual}>
                      {t('ScoringModelType.Manual')}
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid md={3} xs={24} item className={classes.item}>
                  <Input
                    label={`${t('Search')}`}
                    control={control}
                    name="search"
                    variant="standard"
                    InputProps={{
                      endAdornment: (
                        <IconSprite width="16px" color={theme.palette.text.primary} icon="search" />
                      ),
                    }}
                  />
                </Grid>
                <Grid md={3} xs={24} item className={classes.buttonReset}>
                  <FormControl>
                    <Button variant="text" onClick={onReset}>
                      {t('Reset')}
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box mb={1}>
            <QuotaChips control={control} setValue={setValue} />
          </Box>
        </QuotaFilterFormContextProvider>
      </form>
    </>
  );
};
