import { Control, UseFormSetValue } from 'react-hook-form';
import {
  CounterpartyOption,
  QuotaCalculationResult,
  QuotaListRequest,
  UserViewModel,
} from 'schema/serverTypes';
import { useQuotaList } from './useQuotaList';
import { createContext } from 'react';

export type QuotaFilterFormProps = {
  control: Control<QuotaListRequest, object>;
  onReset: () => void;
  setValue: UseFormSetValue<QuotaListRequest>;
} & Pick<ReturnType<typeof useQuotaList>, 'tabs'>;

export type QuotaAction = 'changeOwner' | 'viewHistory';

export type QuotaChipsProps = {
  control: Control<QuotaListRequest, object>;
  setValue: UseFormSetValue<QuotaListRequest>;
};

export type QuotaFilterFormContextProps = {
  users: UserViewModel[];
  counterparties: CounterpartyOption[];
  addUser: (user: UserViewModel) => void;
  addCounterparty: (counterparty: CounterpartyOption) => void;
};

export const QuotaFilterFormContext = createContext<QuotaFilterFormContextProps>({
  users: [],
  counterparties: [],
  addUser: (user: UserViewModel) => {},
  addCounterparty: (counterparty: CounterpartyOption) => {},
});

export type QuotaProps = {
  quota: QuotaCalculationResult;
};
