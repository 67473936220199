import { CreateFormFieldValues } from './types';

export const getFormData = (values: CreateFormFieldValues) => {
  const formData = new FormData();
  formData.append('issueType', values.issueType);
  formData.append('issueSource', values.issueSource);
  if (values.title !== '') {
    formData.append('title', values.title);
  }
  if (values.description !== '') {
    formData.append('description', values.description);
  }
  if (values.file !== null) {
    formData.append('file', values.file);
  }
  if (values.quotaId) {
    formData.append('quotaId', values.quotaId.toString());
  }
  if (values.link.url !== '') {
    formData.append('link.url', values.link.url);
  }
  if (values.link.title !== '') {
    formData.append('link.title', values.link.title);
  }
  return formData;
};
