import { RatingOutput as Output } from 'schema/serverTypes';
import { formatNumber, Grid } from 'components';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { getRatingString } from './getRatingString';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(2),
      '& > div': {
        padding: theme.spacing(2, 1.5, 1),
        margin: theme.spacing(1.5),
      },
    },
    label: {
      fontSize: 9,
      whiteSpace: 'nowrap',
      color: theme.palette.text.secondary,
    },
    value: {
      fontSize: 12,
      color: theme.palette.common.black,
    },
    gradeWrapper: {
      '&&': {
        backgroundColor: theme.palette.secondary.light,
        paddingTop: theme.spacing(1),
      },
    },
    gradeLabel: {
      whiteSpace: 'nowrap',
      display: 'inline-block',
      fontSize: 12,
      color: theme.palette.text.primary,
      marginBottom: theme.spacing(1),
    },
    gradeValue: {
      fontSize: 24,
      fontWeight: 700,
      color: theme.palette.common.black,
      '&$gradeRed': {
        color: theme.palette.error.main,
      },
    },
    gradeRed: {},
    colorValueRed: {
      color: theme.palette.error.main,
    },
    colorValueGreen: {
      color: theme.palette.green.main,
    },
  })
);

type RatingOutputProps = {
  output?: Output;
};

export const RatingOutput = (props: RatingOutputProps) => {
  const classes = useStyles(props);

  const { output } = props;

  const ratingLabel = getRatingString(output?.ratingGrade, '-');

  return (
    <>
      <Grid container columnSpacing={0} rowSpacing={2} className={classes.container}>
        <Grid item xs={'auto'} className={classes.gradeWrapper}>
          <span className={classes.gradeLabel}>Итоговый рейтинг</span>
          <br />
          <span className={clsx(classes.gradeValue, classes.gradeRed)}>{ratingLabel}</span>
        </Grid>
        <Grid item xs={'auto'}>
          <span className={classes.label}>Расчетный рейтинг</span>
          <br />
          <span className={classes.value}>{getRatingString(output?.ratingOriginal, '-')}</span>
        </Grid>
        <Grid item xs={'auto'}>
          <span className={classes.label}>Возраст</span>
          <br />
          <span
            className={clsx(
              classes.value,
              (output?.ageCorrection ?? 0) < 0
                ? classes.colorValueGreen
                : (output?.ageCorrection ?? 0) > 0
                ? classes.colorValueRed
                : null
            )}
          >
            {(output?.ageCorrection ?? 0) > 0 ? '+' : ''}
            {output?.ageCorrection === 0 ? '-' : output?.ageCorrection}
          </span>
        </Grid>
        <Grid item xs={'auto'}>
          <span className={classes.label}>Корректировка</span>
          <br />
          <span
            className={clsx(
              classes.value,
              (output?.manualCorrection ?? 0) < 0
                ? classes.colorValueGreen
                : (output?.manualCorrection ?? 0) > 0
                ? classes.colorValueRed
                : null
            )}
          >
            {output?.manualCorrection === 0 ? '-' : output?.manualCorrection}
          </span>
        </Grid>
        <Grid item xs={'auto'}>
          <span className={classes.label}>Total Score</span>
          <br />
          <span className={classes.value}>{output ? output?.totalScore : '-'}</span>
        </Grid>
        <Grid item xs={'auto'}>
          <span className={classes.label}>Intermediate Rating</span>
          <br />
          <span className={classes.value}>
            {output ? formatNumber(output?.intermediateRating, 2) : '-'}
          </span>
        </Grid>
        <Grid item xs={'auto'}>
          <span className={classes.label}>CDR Impact</span>
          <br />
          <span className={classes.value}>{output ? formatNumber(output?.cdrImpact, 2) : '-'}</span>
        </Grid>
        <Grid item xs={'auto'}>
          <span className={classes.label}>Calculated PD (bp)</span>
          <br />
          <span className={classes.value}>
            {output ? `${formatNumber(output?.calculatedPD, 6)} %` : '-'}
          </span>
        </Grid>
      </Grid>
    </>
  );
};
