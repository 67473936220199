import { useMemo, useContext } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FormHelperText, MenuItem, Typography } from '@material-ui/core';
import { Button, Grid, Input, Select } from 'components';
import { useIssuesFileUploadMutation } from '../../services/api';
import { IssueType, IssueViewModel } from 'schema/serverTypes';
import { CreateFormFieldValues } from './types';
import { getFormData } from './getFormData';
import { FileUpload } from './FileUpload';
import { ErrorContext } from './ErrorContext';
import { WysiwygEditor } from './WysiwygEditor';
import { useQueryClient } from 'react-query';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.common.black,
    },
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(5.5),
    },
    button: {
      display: 'block',
      margin: '4px auto 0',
    },
    required: {
      marginTop: theme.spacing(-1.5),
      color: theme.palette.text.primary,
    },
    actions: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    actionButton: {
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
  })
);

export type ErrorFormProps = {
  onSuccess: () => void;
};

export const ErrorForm = ({ onSuccess }: ErrorFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { link, source, quotaId } = useContext(ErrorContext);

  const { control, handleSubmit, setValue } = useForm<CreateFormFieldValues>({
    mode: 'all',
    defaultValues: {
      issueType: IssueType.Error,
      issueSource: source,
      title: '',
      description: '',
      file: null,
      fileName: '',
      quotaId,
      link,
    },
  });

  const { mutateAsync } = useIssuesFileUploadMutation<CreateFormFieldValues, IssueViewModel>(
    '',
    getFormData
  );

  const queryClient = useQueryClient();

  const onSubmit = useMemo(() => {
    const submit = async (values: CreateFormFieldValues) => {
      const issue = await mutateAsync(values);
      if (issue.id) {
        onSuccess();
        await queryClient.invalidateQueries({
          predicate: (query) => {
            return typeof query.queryKey === 'string' && query.queryKey.indexOf(`issues`) >= 0;
          },
        });
      }
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync, onSuccess, queryClient]);

  const message = t('Required');

  const formId = `error-form`;

  return (
    <form id={formId} onSubmit={onSubmit} className={classes.root}>
      <Typography variant={'h2'} className={classes.title}>
        {t('Report a bug')}
      </Typography>
      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item xs={24}>
          <Select
            control={control}
            name="issueType"
            rules={{
              required: {
                value: true,
                message,
              },
            }}
          >
            <MenuItem value={IssueType.Error}>{t('IssueType.Error')}</MenuItem>
            <MenuItem value={IssueType.Rework}>{t('IssueType.Rework')}</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={24}>
          <Input
            label={t('Subject')}
            control={control}
            name="title"
            rules={{
              required: {
                value: true,
                message,
              },
            }}
          />
        </Grid>
        <Grid item md={24} xs={24}>
          <Controller
            name="description"
            control={control}
            render={({ field, fieldState }) => {
              const helperText =
                fieldState.error !== undefined
                  ? (fieldState.error as FieldError).message
                  : undefined;

              return (
                <>
                  <WysiwygEditor value={field.value} onChange={field.onChange} />
                  {helperText && <FormHelperText error>{helperText}</FormHelperText>}
                </>
              );
            }}
            rules={{
              required: true,
              maxLength: {
                value: 10000,
                message: t('Maximum number of characters is', { count: 10000 }),
              },
            }}
          />
        </Grid>
        <Grid item md={24} xs={24}>
          <FileUpload control={control} setValue={setValue} />
        </Grid>
        <Grid item md={24} xs={24}>
          <div className={classes.actions}>
            <div className={classes.actionButton}>
              <Button
                className={classes.button}
                color="primary"
                size="medium"
                type="submit"
                variant="contained"
              >
                {t('Send')}
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};
