import { useBackendQuery } from './useBackend';
import { ApplicationUsersTab } from 'schema/serverTypes';
import { createUrlFromParts } from './types';

export const useCurrentUserTabsQuery = () => {
  return useBackendQuery<ApplicationUsersTab[]>(
    createUrlFromParts('users/application/tabs'),
    'tabs'
  );
};
