import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { ApplicationRole, NewsItemViewModel } from 'schema/serverTypes';
import clsx from 'clsx';
import { ListItemIcon, Menu, MenuItem, Typography } from '@material-ui/core';
import { IconSprite } from '../icons';
import { themeOrange as theme } from 'theme';
import { useCallback, useState } from 'react';
import { useNewsEditMutation } from '../../services/api';
import { ModalForm, useModalForm } from '../Modal';
import { Button } from '../Button';
import { NewsCreateForm } from './NewsCreateForm';
import { useLanguage } from '../utils/useLanguage';
import { Role } from '../authentication';

dayjs.extend(isToday);
dayjs.extend(isYesterday);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.primary,
      display: 'flex',
      justifyContent: 'flex-between',
      fontSize: 14,
      '&:last-child $divider': {
        display: 'none',
      },
      '&:last-child $textWrapper:before': {
        display: 'none',
      },
    },
    date: {
      width: 108,
      fontSize: 12,
      paddingRight: theme.spacing(2),
      textAlign: 'right',
    },
    textWrapper: {
      position: 'relative',
      width: '100%',
      padding: theme.spacing(0, 0, 0, 4),
      '&:after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'block',
        backgroundColor: 'white',
        borderRadius: '50%',
        width: 15,
        height: 15,
        border: '3px solid #cccccc',
        boxSizing: 'border-box',
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 7,
        display: 'block',
        backgroundColor: theme.palette.secondary.main,
        width: 1,
        height: '100%',
      },
    },
    title: {
      fontSize: 14,
      marginBottom: theme.spacing(1.5),
    },
    newTitle: {
      fontWeight: 'bold',
    },
    text: {
      fontSize: 12,
    },
    new: {
      fontWeight: 'bold',
      fontSize: 12,
      color: theme.palette.error.main,
    },
    action: {
      position: 'absolute',
      right: 0,
      top: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      '&.MuiCardActions-spacing > :not(:first-child)': {
        marginLeft: 0,
        marginRight: '-2px',
      },
    },
    actionButton: {
      padding: '0 !important',
    },
    menuItem: {
      padding: 0,
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
    menuIcon: {
      minWidth: 23,
    },
    menuLink: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
      padding: '3px 9px',
      marginBottom: 4,
      color: theme.palette.common.black,
    },
    divider: {
      height: 1,
      backgroundColor: theme.palette.background.default,
      margin: theme.spacing(2.5, 0, 1.5),
    },
    critical: {
      '& $title': {
        color: theme.palette.error.main,
      },
      '&:after': {
        borderColor: theme.palette.error.main,
      },
    },
    general: {
      '&:after': {
        borderColor: theme.palette.green.main,
      },
    },
    modalRoot: {
      textAlign: 'center',
    },
    modalTitle: {
      textAlign: 'center',
      marginBottom: theme.spacing(3),
    },
    modalText: {
      color: theme.palette.text.primary,
    },
    modalButton: {
      marginTop: theme.spacing(2),
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
  })
);

export const NewsFeedItem = (props: NewsItemViewModel) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const lang = useLanguage();
  const { createdDate, title, text, category, id, isViewed } = props;
  const date = dayjs(createdDate).locale(lang);
  const { onOpen: onDeleteOpen, onClose: onDeleteClose, open: isDeleteModalOpen } = useModalForm();
  const { onOpen: onEditOpen, onClose: onEditClose, open: isEditModalOpen } = useModalForm();
  const [isDeleted, setDeleted] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleMenuClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const { mutateAsync } = useNewsEditMutation(`${id}`, {
    method: 'DELETE',
    onSuccess: () => {
      setDeleted(true);
    },
  });

  const handleDelete = useCallback(() => {
    onDeleteOpen();
    setAnchorEl(null);
  }, [onDeleteOpen, setAnchorEl]);

  const handleConfirmDelete = useCallback(() => {
    mutateAsync(null);
    onDeleteClose();
  }, [onDeleteClose, mutateAsync]);

  const handleEdit = useCallback(() => {
    onEditOpen();
    setAnchorEl(null);
  }, [onEditOpen, setAnchorEl]);

  const handleEditOnClose = useCallback(() => {
    onEditClose();
  }, [onEditClose]);

  if (isDeleted) {
    return null;
  }

  return (
    <>
      <div className={classes.root}>
        <div className={clsx(classes.date, classes.newTitle)}>
          {date.isToday() ? 'Сегодня' : date.isYesterday() ? 'Вчера' : date.format('DD MMM')}
          {', ' + date.format('HH:mm')}
          <br />
          {!isViewed && <span className={classes.new}>NEW</span>}
        </div>
        <div className={clsx(classes.textWrapper, classes[category])}>
          <div className={classes.title}>{title}</div>
          <div className={classes.text}>{text}</div>
          <Role role={['admin', 'super_sales_manager', ApplicationRole.RiskManager]}>
            <div className={classes.action}>
              <Button
                onClick={handleMenuClick}
                variant="iconButton"
                className={classes.actionButton}
                endIcon={
                  <IconSprite
                    icon="vertical-menu"
                    width="24px"
                    color={theme.palette.text.primary}
                  />
                }
              />
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                onClose={handleMenuClose}
              >
                <MenuItem
                  classes={{
                    root: classes.menuItem,
                  }}
                  onClick={handleEdit}
                >
                  <div className={classes.menuLink}>
                    <ListItemIcon
                      classes={{
                        root: classes.menuIcon,
                      }}
                    >
                      <IconSprite
                        icon="edit"
                        width="14px"
                        height="15px"
                        color={theme.palette.text.primary}
                        hoverColor={theme.palette.primary.main}
                      />
                    </ListItemIcon>
                    {t('Edit')}
                  </div>
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.menuItem,
                  }}
                  onClick={handleDelete}
                >
                  <div className={classes.menuLink}>
                    <ListItemIcon
                      classes={{
                        root: classes.menuIcon,
                      }}
                    >
                      <IconSprite
                        icon="delete"
                        width="14px"
                        height="15px"
                        color={theme.palette.text.primary}
                        hoverColor={theme.palette.primary.main}
                      />
                    </ListItemIcon>
                    {t('Delete')}
                  </div>
                </MenuItem>
              </Menu>
            </div>
          </Role>
          <div className={classes.divider} />
        </div>
      </div>
      <ModalForm open={isDeleteModalOpen} onClose={onDeleteClose}>
        <div className={classes.modalRoot}>
          <Typography variant={'h2'} className={classes.modalTitle}>
            {t('Delete News')}
          </Typography>
          <Typography variant="subtitle2" className={classes.modalText}>
            {t('Are you sure you want to delete the news')}
          </Typography>
          <Typography variant="body2" className={classes.modalText}>
            {title}?
          </Typography>
          <div>
            <Button
              variant="contained"
              className={classes.modalButton}
              onClick={handleConfirmDelete}
            >
              {t('Yes')}
            </Button>
            <Button variant="outlined" onClick={onDeleteClose} className={classes.modalButton}>
              {t('No')}
            </Button>
          </div>
        </div>
      </ModalForm>

      <ModalForm open={isEditModalOpen} onClose={handleEditOnClose}>
        <Role role={['admin', 'super_sales_manager', ApplicationRole.RiskManager]}>
          <NewsCreateForm onSuccess={handleEditOnClose} newsItem={props} />
        </Role>
      </ModalForm>
    </>
  );
};
