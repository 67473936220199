import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';
import { MissingHistoryCountGroupTab, MissingHistoryCountGroupTabId } from 'schema/serverTypes';
import { useTranslation } from 'react-i18next';
import { MissingItem } from '../MissingItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    head: {
      fontSize: 10,
      fontWeight: 600,
      padding: 6,
      '&.MuiTableCell-root': {
        color: theme.palette.greyBlue1.main,
      },
      '&.MuiTableCell-root:first-child': {
        padding: 6,
      },
      '&.MuiTableCell-root:last-child': {
        padding: 6,
      },
    },
    title: {
      fontWeight: 700,
      fontSize: 14,
      color: theme.palette.primary.main,
      padding: theme.spacing(0, 2, 1.25),
      margin: theme.spacing(0, -2, 1.25),
      borderBottom: '2px solid #E3E6F3',
    },
    border: {
      '&.MuiTable-root': {
        borderSpacing: '4px',
        borderCollapse: 'separate',
        boxShadow: 'none',
      },
    },
    cardsContainer: {
      display: 'flex',
      justifyItems: 'space-between',
    },
    card: {
      backgroundColor: 'white',
      padding: theme.spacing(2),
      boxShadow: '3px 3px 5px rgba(56, 60, 97, 0.1)',
      marginBottom: theme.spacing(1),
      '&+$card': {
        marginLeft: theme.spacing(2.5),
      },
    },
  })
);

export type MissingCardsProps = {
  groups: MissingHistoryCountGroupTab[];
  isLoading: boolean;
};

export const MissingCards = (props: MissingCardsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { groups } = props;

  const groupByKey = (list: any, key: string) =>
    list.reduce(
      (hash: any, obj: any) => ({
        ...hash,
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      }),
      {}
    );

  const counterpartiesData = groups.find(
    (t) => t.id === MissingHistoryCountGroupTabId.Counterparties
  );
  const rows = groupByKey(counterpartiesData?.data, 'source');
  const documentsData = groups.find((t) => t.id === MissingHistoryCountGroupTabId.Documents);
  const managersData = groups.find((t) => t.id === MissingHistoryCountGroupTabId.Managers);

  return (
    <div className={classes.cardsContainer}>
      <TableContainer className={classes.card}>
        <div className={classes.title}>{t('Counterparty_plural')}</div>
        {rows.dealer && (
          <TableBlock
            title={t('Dealer_plural')}
            rows={rows.dealer}
            currentFilter={'counterparties'}
          />
        )}
        {rows.lessee && (
          <TableBlock
            title={t('Lessee_plural')}
            rows={rows.lessee}
            currentFilter={'counterparties'}
          />
        )}
      </TableContainer>
      <TableContainer className={classes.card}>
        <div className={classes.title}>{t('Documents')}</div>
        <TableBlock rows={documentsData?.data} currentFilter={'documents'} />
      </TableContainer>
      <TableContainer className={classes.card}>
        <div className={classes.title}>{t('Manager_plural')}</div>
        <TableBlock rows={managersData?.data} currentFilter={'managers'} />
      </TableContainer>
    </div>
  );
};

const TableBlock = ({
  title,
  rows,
  currentFilter,
}: {
  title?: string;
  rows: any;
  currentFilter: string;
}) => {
  const classes = useStyles();

  return (
    <Table className={classes.border}>
      {title && (
        <TableHead>
          <TableRow>
            <TableCell className={classes.head}>{title}</TableCell>
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {rows.map((item: any, index: number) => (
          <MissingItem
            key={`${currentFilter}-${item.text}-${index}`}
            currentFilter={currentFilter}
            {...item}
          />
        ))}
      </TableBody>
    </Table>
  );
};
