import { useTranslation } from 'react-i18next';
import { TableCell, TableRow } from '@material-ui/core';
import clsx from 'clsx';
import { formatNumber } from 'components/utils';
import { useStyles } from './useStyles';

type PipelineTotalProps = {
  totalAmount: number;
  totalCount: number;
};

export const PipelineTotal = (props: PipelineTotalProps) => {
  const classes = useStyles(props);
  const { t } = useTranslation();

  const { totalAmount, totalCount } = props;

  return (
    <TableRow className={clsx(classes.root, classes.total)}>
      <TableCell className={classes.name} align="right">
        <div className={classes.fill}>{t('Total')}</div>
      </TableCell>
      <TableCell className={classes.shipment} align="center">
        <strong>{totalCount}</strong>
      </TableCell>
      <TableCell align="right" className={classes.value}></TableCell>
      <TableCell align="right" className={classes.value}>
        <strong>{formatNumber(totalAmount)}</strong>
      </TableCell>
    </TableRow>
  );
};
