import { MenuItem, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { FileUpload } from '../FileUpload';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import {
  useCancelScoringApprovalIssueMutation,
  useCloseScoringApprovalIssueMutation,
  useOpenAgainScoringApprovalIssueMutation,
  useReturnScoringApprovalIssueMutation,
} from '../../../services';
import { Button, Grid, Select, useToast, Input } from 'components';
import { CreditDecisionStatus } from '../../../schema';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalRoot: {
      textAlign: 'center',
    },
    modalTitle: {
      marginBottom: theme.spacing(3),
    },
    left: {
      textAlign: 'left',
    },
    modalButton: {
      marginTop: theme.spacing(2),
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
      '&$success': {
        border: `1px solid ${theme.palette.success.main}`,
        backgroundColor: theme.palette.success.main,
      },
    },
    success: {},
  })
);

type ApprovalPopupProps = {
  issueId: number;
  onClose: () => void;
};

const useApprovalForms = (issueId: number, onClose: () => void, mutationFn: any) => {
  const classes = useStyles();
  const toast = useToast();
  const { t } = useTranslation();

  const { control, handleSubmit, setValue } = useForm({
    mode: 'all',
    defaultValues: {
      status: CreditDecisionStatus.Yes,
      description: '',
    },
  });

  const queryClient = useQueryClient();

  const { mutateAsync } = mutationFn(issueId, {
    onSuccess: (result: any) => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey.indexOf(`/issues/${issueId}`) > -1;
        },
      });
      onClose();
    },
    onError: () => {
      toast(t('Could not update task'), 'error');
    },
  });

  const onSubmit = useMemo(() => {
    const submit = async (form: any) => {
      await mutateAsync(form);
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync]);

  return { classes, control, onSubmit, setValue };
};

export const ApprovalConfirmPopup = (props: ApprovalPopupProps) => {
  const { t } = useTranslation();
  const { issueId, onClose } = props;
  const { classes, control, onSubmit, setValue } = useApprovalForms(
    issueId,
    onClose,
    useCloseScoringApprovalIssueMutation
  );

  return (
    <div className={classes.modalRoot}>
      <form onSubmit={onSubmit}>
        <Typography variant="h2" className={classes.modalTitle}>
          {t('TaskComplete')}
        </Typography>
        <Grid container columnSpacing={2} rowSpacing={2.5}>
          <Grid item xs={24} className={classes.left}>
            <Select label="Статус кредитного решения (1С)" name="status" control={control}>
              <MenuItem value={CreditDecisionStatus.Yes}>ДА</MenuItem>
              <MenuItem value={CreditDecisionStatus.YesAutoApproved}>ДА (AutoApproved)</MenuItem>
              <MenuItem value={CreditDecisionStatus.YesBut}>ДА, НО</MenuItem>
              {/*temporary DLLCRMV2-171*/}
              {/*<MenuItem value={CreditDecisionStatus.No}>НЕТ</MenuItem>*/}
            </Select>
          </Grid>
          <Grid item xs={24}>
            <Input label={t('Additional info')} name="description" control={control} multiline />
          </Grid>
          <Grid item xs={24} className={classes.left}>
            <FileUpload control={control} setValue={setValue} required />
          </Grid>
          <Grid item xs={24}>
            <Button
              variant="contained"
              className={clsx(classes.modalButton, classes.success)}
              type="submit"
            >
              {t('Approve')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export const ApprovalRejectPopup = (props: ApprovalPopupProps) => {
  const { t } = useTranslation();
  const { issueId, onClose } = props;
  const { classes, control, onSubmit, setValue } = useApprovalForms(
    issueId,
    onClose,
    useCancelScoringApprovalIssueMutation
  );

  return (
    <div className={classes.modalRoot}>
      <form onSubmit={onSubmit}>
        <Typography variant="h2" className={classes.modalTitle}>
          {t('TaskComplete')}
        </Typography>
        <Grid container columnSpacing={2} rowSpacing={2.5}>
          <Grid item xs={24}>
            <Input label={t('Additional info')} name="description" control={control} multiline />
          </Grid>
          <Grid item xs={24} className={classes.left}>
            <FileUpload control={control} setValue={setValue} />
          </Grid>
          <Grid item xs={24}>
            <Button variant="contained" className={classes.modalButton} type="submit">
              {t('Reject')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export const ApprovalReturnPopup = (props: ApprovalPopupProps) => {
  const { t } = useTranslation();
  const { issueId, onClose } = props;
  const { classes, control, onSubmit, setValue } = useApprovalForms(
    issueId,
    onClose,
    useReturnScoringApprovalIssueMutation
  );

  return (
    <div className={classes.modalRoot}>
      <form onSubmit={onSubmit}>
        <Typography variant="h2" className={classes.modalTitle}>
          {t('Вернуть на доработку')}
        </Typography>
        <Grid container columnSpacing={2} rowSpacing={2.5}>
          <Grid item xs={24}>
            <Input label={t('Additional info')} name="description" control={control} multiline />
          </Grid>
          <Grid item xs={24} className={classes.left}>
            <FileUpload control={control} setValue={setValue} />
          </Grid>
          <Grid item xs={24}>
            <Button variant="contained" className={classes.modalButton} type="submit">
              {t('Return')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export const ApprovalOpenAgainPopup = (props: ApprovalPopupProps) => {
  const { t } = useTranslation();
  const { issueId, onClose } = props;
  const { classes, control, onSubmit, setValue } = useApprovalForms(
    issueId,
    onClose,
    useOpenAgainScoringApprovalIssueMutation
  );

  return (
    <div className={classes.modalRoot}>
      <form onSubmit={onSubmit}>
        <Typography variant="h2" className={classes.modalTitle}>
          {t('ApprovalRequest')}
        </Typography>
        <Grid container columnSpacing={2} rowSpacing={2.5}>
          <Grid item xs={24}>
            <Input label={t('Additional info')} name="description" control={control} multiline />
          </Grid>
          <Grid item xs={24} className={classes.left}>
            <FileUpload control={control} setValue={setValue} />
          </Grid>
          <Grid item xs={24}>
            <Button variant="contained" className={classes.modalButton} type="submit">
              {t('RequestAgain')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
