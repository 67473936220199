import { Typography } from '@material-ui/core';
import { Breadcrumbs, Button, Grid, Input, Switch } from 'components';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { LeasingProductItemViewModel, UpdateLeasingProductItemRequest } from 'schema/serverTypes';
import { useDictionaryBackendMutation } from 'services';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useLeasingProductBrandsQuery } from '../../../services/api/useLeasingProductBrandsBackend';
import { BrandAutocomplete } from './BrandAutocomplete';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(5.5),
    },
    required: {
      marginTop: theme.spacing(-1.5),
      color: theme.palette.text.primary,
    },
    form: {
      width: '100%',
    },
    actions: {
      marginTop: theme.spacing(3),
      display: 'flex',
    },
    button: {
      display: 'block',
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
  }),
);

export type LeasingProductFormProps = {
  onSuccess: () => void;
};

const useLeasingProductForm = (
  message: string,
  onSuccess: () => void,
  item?: LeasingProductItemViewModel,
) => {
  const { handleSubmit, control, formState, setError, clearErrors, reset, setValue } =
    useForm<UpdateLeasingProductItemRequest>({
      mode: 'onBlur',
      defaultValues: {
        name: item?.name ?? '',
        id: item?.id ?? 0,
        brands: item?.brands ?? [],
        isVendor: item?.isVendor ?? false,
      },
    });

  const queryClient = useQueryClient();

  const { mutateAsync } = useDictionaryBackendMutation<
    UpdateLeasingProductItemRequest,
    UpdateLeasingProductItemRequest
  >('leasingProducts', {
    method: 'PUT',
    onSuccess: (_result) => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            typeof query.queryKey === 'string' &&
            (query.queryKey as string).startsWith('leasingProducts')
          );
        },
      });
    },
  });

  const onSubmit = useMemo(() => {
    const submit = async (form: UpdateLeasingProductItemRequest) => {
      await mutateAsync(form);
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync]);

  return {
    control,
    onSubmit,
    reset,
    setError,
    clearErrors,
    setValue,
    ...formState,
  };
};

export const LeasingProductForm = (props: LeasingProductFormProps) => {
  const classes = useStyles();
  const { onSuccess } = props;
  const { state } = useLocation<{ leasingProductItem: LeasingProductItemViewModel }>();
  const item = state ? state.leasingProductItem : undefined;
  const { t } = useTranslation();
  const message = t('Could not create');
  const { onSubmit, isSubmitting, control, setValue } = useLeasingProductForm(
    message,
    onSuccess,
    item,
  );
  const { data: brands = [] } = useLeasingProductBrandsQuery({});

  const history = useHistory();
  const cancelHandler = () => {
    history.push('/dictionaries/leasingProducts');
  };

  return (
    <>
      <Breadcrumbs link={'/dictionaries/leasingProducts'} text={t('Sales channel')} />
      <Accordion defaultExpanded={true}>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="subtitle1">{item ? t('Edit note') : t('Add note')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <form className={classes.form}>
            <Grid container columnSpacing={2} rowSpacing={2.5}>
              <Grid item md={24} xs={24}>
                <Input
                  label={t('Name')}
                  control={control}
                  name="name"
                  rules={{
                    required: {
                      value: true,
                      message: t('Required'),
                    },
                  }}
                />
                <Switch
                  label={'Вендорный'}
                  name="isVendor"
                  control={control}
                  labelOn={''}
                  labelOff={''}
                />
              </Grid>
              <Grid item md={24} xs={24}>
                <BrandAutocomplete
                  control={control}
                  setValue={setValue}
                  leasingProductBrands={brands}
                />
              </Grid>
            </Grid>
          </form>
        </AccordionDetails>
      </Accordion>
      <div className={classes.actions}>
        <Button
          className={classes.button}
          color="primary"
          size="medium"
          type="submit"
          variant="contained"
          disabled={isSubmitting}
          onClick={onSubmit}
        >
          {t('Save')}
        </Button>
        <Button
          className={classes.button}
          color="primary"
          size="medium"
          type="button"
          variant="outlined"
          disabled={isSubmitting}
          onClick={cancelHandler}
        >
          {t('Cancel')}
        </Button>
      </div>
    </>
  );
};
