import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import {
  Amount,
  AmountType,
  CalculationMethod,
  IssueStatus,
  QuotaNomenclatureViewModel,
} from 'schema';
import clsx from 'clsx';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Currency } from 'schema';
import { formatCurrency, formatPercents } from 'components/utils';
import { NomenclatureName } from './NomenclatureName';
import { IconSprite } from '../../icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      lineHeight: 2,
    },
    name: {
      fontWeight: 500,
      fontSize: '14px',
      color: theme.palette.common.black,
      lineHeight: 1,
    },
    assets: {
      position: 'absolute',
      bottom: '16px',
      display: 'flex',
      flexWrap: 'nowrap',
      whiteSpace: 'nowrap',
      zIndex: 10,
    },
    subjectProp: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(0.6),
      maxHeight: 14,
      fontSize: 12,
      lineHeight: '1.1',
      color: theme.palette.text.primary,
      display: 'flex',
      alignItems: 'center',
      borderLeft: '1px solid ' + theme.palette.secondary.main,
      flexShrink: 0,
    },
    itemLink: {
      width: 312,
    },
    opened: {
      color: theme.palette.attention.main,
    },
    inProgress: {
      color: theme.palette.attention.main,
    },
    verification: {
      color: theme.palette.darkAttention.main,
    },
    rework: {
      color: theme.palette.attention.main,
    },
    returned: {
      color: theme.palette.error.main,
    },
    closed: {
      color: theme.palette.green.main,
    },
    inQueue: {},
    postponed: {},
    forReview: {},
    currency: {
      textTransform: 'uppercase',
    },
    leasedItemsLink: {
      position: 'relative',
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      width: 312,
    },
    nomenclaturesOpen: {
      position: 'absolute',
      right: theme.spacing(1.5),
      fill: theme.palette.primaryGradient[600],
      top: '50%',
      transformOrigin: 'top',
      transform: 'rotate(180deg) translateY(-50%)',
    },
    nomenclaturesClosed: {
      position: 'absolute',
      right: theme.spacing(1.5),
      fill: theme.palette.primaryGradient[600],
      top: '50%',
      transformOrigin: 'top',
      transform: 'translateY(-50%)',
    },
    orange: {
      color: theme.palette.primary.main,
    },
  })
);

const getValue = (value: Amount | undefined) => {
  if (value === undefined) {
    return null;
  }
  if (value.type === AmountType.Percents) {
    return `${value.value}%`;
  }
  return `${value.value}`;
};

export type QuotaAssetProps = {
  currency: string;
  issueStatus?: IssueStatus;
  telematicsIssueId?: number;
  telematicsIssueStatus?: IssueStatus;
  shipmentIssueId?: number;
  shipmentIssueStatus?: IssueStatus;
  returnReason?: string;
  contractNumber?: string;
  contractStatus?: string;
  nomenclatures?: QuotaNomenclatureViewModel[];
  asset: {
    name?: string;
    numberOfItems: number;
    prepayment?: Amount;
    margin?: number;
    numberOfMonths: number;
    calculationMethod: CalculationMethod;
  };
  setShow: Dispatch<SetStateAction<boolean>>;
  show: boolean;
  cofChangedDate?: string;
  cof: number;
};

export const QuotaAsset = (props: QuotaAssetProps) => {
  const {
    asset: { numberOfItems, prepayment, numberOfMonths, calculationMethod, margin },
    currency,
    issueStatus,
    returnReason,
    contractNumber,
    contractStatus,
    setShow,
    show,
    nomenclatures = [],
    cofChangedDate,
    cof,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const getCalculationMethod = useCallback(
    (method: CalculationMethod | undefined) => {
      if (method === undefined) {
        return null;
      }
      if (method === CalculationMethod.Annuity) {
        return t('CalculationMethodType.AnnuityShort');
      }
      if (method === CalculationMethod.StraightLine) {
        return t('CalculationMethodType.StraightLineShort');
      }
      if (method === CalculationMethod.Seasonal) {
        return t('CalculationMethodType.SeasonalShort');
      }
      if (method === CalculationMethod.SuperSeasonal) {
        return t('CalculationMethodType.SuperSeasonal');
      }
      return null;
    },
    [t]
  );

  const showNomenclaturesHandler = (ev: React.MouseEvent<HTMLSpanElement>) => {
    ev.stopPropagation();
    ev.preventDefault();
    setShow((prev) => !prev);
  };

  const openIconClassName = show ? classes.nomenclaturesOpen : classes.nomenclaturesClosed;
  const contractStatusString = contractStatus ? `(${contractStatus})` : '';

  return (
    <Grid className={classes.root} container direction="column" wrap="nowrap">
      <Grid className={classes.assets} item container direction="row">
        <Grid item className={clsx(classes.subjectProp, classes.itemLink)}>
          {t('NumberOfItems')}: {numberOfItems}&nbsp;
          {nomenclatures.length > 1 ? (
            <span
              className={classes.leasedItemsLink}
              onClick={(ev) => showNomenclaturesHandler(ev)}
            >
              {t('List of leased items')}
              <IconSprite icon={'openArrow'} width={8} height={7} className={openIconClassName} />
            </span>
          ) : (
            <>
              |&nbsp;
              <NomenclatureName name={nomenclatures[0] ? nomenclatures[0].name : ''} />
            </>
          )}
        </Grid>
        <Grid item className={clsx(classes.subjectProp, classes.currency)}>
          дл: {formatCurrency(currency as Currency)}
        </Grid>
        <Grid item className={classes.subjectProp}>
          {t('Prepayment')}: {getValue(prepayment)}
        </Grid>
        <Grid item className={classes.subjectProp}>
          {t('Margin')}: {formatPercents(margin)}
        </Grid>
        <Grid item className={clsx(classes.subjectProp, cofChangedDate ? classes.orange : '')}>
          {t('Cof')}: {formatPercents(cof)}
        </Grid>
        <Grid item className={classes.subjectProp}>
          {t('MonthWithCount', { count: numberOfMonths })}
        </Grid>
        <Grid item className={classes.subjectProp}>
          {t('Type')}: {getCalculationMethod(calculationMethod)}
        </Grid>
        {issueStatus && (
          <Grid item className={clsx(classes[issueStatus], classes.subjectProp)}>
            {issueStatus === IssueStatus.Opened || issueStatus === IssueStatus.InProgress
              ? `${t('Execution of the contract')} №${contractNumber}`
              : issueStatus === IssueStatus.Returned
              ? `${t('Contract')} №${contractNumber}${contractStatusString} ${t('Returned')}. ${
                  returnReason ? returnReason : ''
                }`
              : issueStatus === IssueStatus.Verification
              ? `${t('Verification of the contract')} №${contractNumber}`
              : issueStatus === IssueStatus.Closed
              ? `${t('Contract')} №${contractNumber}`
              : null}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
