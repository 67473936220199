import { useEffect, useState } from 'react';
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import bold from './icons/B.svg';
import underline from './icons/U.svg';
import ul from './icons/ul.svg';
import ol from './icons/ol.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(0),
      backgroundColor: 'white',
      border: '1px solid rgb(215, 215, 215)',
      '& .rdw-editor-main': {
        padding: theme.spacing(0, 2.5),
      },
    },
    editor: {
      backgroundColor: 'white',
    },
    iconWrapper: {
      marginBottom: 0,
    },
    toolbar: {
      backgroundColor: theme.palette.bgGray.main,
      marginBottom: 0,
      padding: 0,
      border: 'none',
      margin: 5,
    },
    icon: {
      borderRadius: 0,
      border: 'none',
      padding: 0,
      width: 32,
      height: 30,
      boxShadow: 'none',
      backgroundColor: theme.palette.bgGray.main,
      '&:hover': {
        boxShadow: 'none',
      },
      '&.rdw-option-active': {
        boxShadow: 'none',
        backgroundColor: theme.palette.secondary.main,
      },
    },
  })
);

export type WysiwygEditorProps = {
  value?: string;
  onChange: (value: string) => void;
};

export const WysiwygEditor = (props: WysiwygEditorProps) => {
  const classes = useStyles();

  const { value = '', onChange } = props;

  const [editorState, setEditorState] = useState(() => {
    const blocksFromHTML = convertFromHTML(value);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );

    return EditorState.createWithContent(contentState);
  });

  useEffect(() => {
    if (value === '') {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(value).contentBlocks,
            convertFromHTML(value).entityMap
          )
        )
      );
    }
  }, [value]);

  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState);
    return onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  return (
    <>
      <div className={classes.root}>
        <Editor
          editorState={editorState}
          wrapperClassName="wrapper-class"
          editorClassName={classes.editor}
          toolbarClassName={classes.toolbar}
          onEditorStateChange={onEditorStateChange}
          handlePastedText={() => false}
          toolbar={{
            className: classes.toolbar,
            options: ['inline', 'list'],
            inline: {
              className: classes.iconWrapper,
              inDropdown: false,
              options: ['bold', 'underline'],
              bold: {
                className: classes.icon,
                icon: bold,
              },
              underline: {
                className: classes.icon,
                icon: underline,
              },
            },
            list: {
              className: classes.iconWrapper,
              inDropdown: false,
              options: ['unordered', 'ordered'],
              unordered: {
                className: classes.icon,
                icon: ul,
              },
              ordered: {
                className: classes.icon,
                icon: ol,
              },
            },
          }}
        />
      </div>
    </>
  );
};
