import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { Button, Grid, IconSprite, Select, useToast } from 'components';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IssueNotificationSettingViewModel, IssueType } from 'schema/serverTypes';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useUpdateIssueNotificationSettingsMutation } from 'services';
import React, { useCallback, useMemo } from 'react';
import { Divider, MenuItem } from '@material-ui/core';
import { EmailsInput } from './EmailsInput';
import { themeOrange as theme } from 'theme';
import { useStyles } from './useStyles';

type NotificationSettingsTaskFormProps = {
  defaultValues: IssueNotificationSettingViewModel[];
};

export const NotificationSettingsTaskForm = (props: NotificationSettingsTaskFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const toast = useToast();

  const { control, handleSubmit, setError, clearErrors } = useForm<{
    root: IssueNotificationSettingViewModel[];
  }>({
    mode: 'onBlur',
    defaultValues: { root: props.defaultValues },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'root',
  });

  const { mutateAsync, isLoading } = useUpdateIssueNotificationSettingsMutation({
    onSuccess: () => {
      toast(t('SuccessMessage'), 'success');
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const onSubmit = useMemo(() => {
    const submit = async (form: { root: IssueNotificationSettingViewModel[] }) => {
      await mutateAsync(form.root);
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync]);

  const onAdd = useCallback(() => {
    append({
      issueType: IssueType.Error,
      emails: [],
    });
  }, [append]);

  return (
    <form onSubmit={onSubmit} className={classes.root}>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="subtitle1">{t('Email Notification settings for tasks')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container columnSpacing={2} rowSpacing={2.5}>
            {fields.map((item, index) => {
              return (
                <Grid item xs={24} key={item.id} className={classes.paper}>
                  <Divider light className={classes.divider} />
                  <Grid container columnSpacing={2}>
                    <Grid item md={7} xs={24}>
                      <Select
                        label={t('Type')}
                        control={control}
                        name={`root.${index}.issueType`}
                        rules={{
                          required: {
                            value: true,
                            message: t('Required'),
                          },
                        }}
                      >
                        <MenuItem value={IssueType.Error}>{t('IssueType.Error')}</MenuItem>
                        <MenuItem value={IssueType.Contract}>{t('IssueType.Contract')}</MenuItem>
                        <MenuItem value={IssueType.Telematics}>
                          {t('IssueType.Telematics')}
                        </MenuItem>
                        <MenuItem value={IssueType.Shipment}>{t('IssueType.Shipment')}</MenuItem>
                        <MenuItem value={IssueType.Payment}>{t('IssueType.Payment')}</MenuItem>
                        <MenuItem value={IssueType.Rework}>{t('IssueType.Rework')}</MenuItem>
                        <MenuItem value={IssueType.Verification}>
                          {t('IssueType.Verification')}
                        </MenuItem>
                        <MenuItem value={IssueType.ShipmentReport}>
                          {t('IssueType.ShipmentReport')}
                        </MenuItem>
                        <MenuItem value={IssueType.Nomenclature}>
                          {t('IssueType.Nomenclature')}
                        </MenuItem>
                        <MenuItem value={IssueType.Approval}>{t('IssueType.Approval')}</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item md={9} xs={24}>
                      <Select
                        name={`root.${index}.notificationType`}
                        label={t('Type of notification')}
                        control={control}
                        disabled
                      >
                        <MenuItem value="Notifications about new tasks" selected={true}>
                          {t('Notifications about new tasks')}
                        </MenuItem>
                      </Select>
                    </Grid>
                    <Grid item md={7} xs={24}>
                      <EmailsInput
                        name={`root.${index}.emails`}
                        control={control}
                        setError={setError}
                        clearErrors={clearErrors}
                        rules={{
                          required: true,
                        }}
                        label={t('Email')}
                      />
                    </Grid>
                    <Grid item mdStart={24} md={1} xs={24}>
                      <Button
                        variant="iconButton"
                        endIcon={
                          <IconSprite
                            icon={'delete'}
                            color={theme.palette.secondary.dark}
                            width={'14px'}
                          />
                        }
                        onClick={() => remove(index)}
                        className={classes.delete}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
            <Grid item xs={24}>
              <Button variant="text" className={classes.add} onClick={onAdd}>
                <Typography variant="subtitle1" color="primary">
                  + {t('Add')}
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={24}>
              <Button
                className={classes.button}
                color="primary"
                size="medium"
                type="submit"
                variant="contained"
                disabled={isLoading}
              >
                {t('Save')}
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </form>
  );
};
