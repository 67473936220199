import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { WikiCreateForm } from './WikiCreateForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.common.black,
      fontSize: 14,
    },
    article: {
      background: 'white',
      marginBottom: theme.spacing(4),
    },
  })
);

export const WikiCreateArticle = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <div className={classes.article}>
          <WikiCreateForm />
        </div>
      </div>
    </>
  );
};
