import { createStyles, makeStyles, Theme, Tooltip, TooltipProps } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 312,
      position: 'relative',
    },
  })
);

const useTooltipStyles = makeStyles((theme: Theme) =>
  createStyles({
    arrow: {
      color: theme.palette.primary.light,
    },
    tooltip: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.text.primary,
      fontWeight: 400,
    },
  })
);

type NomenclatureNameProps = {
  name: string;
};

const getName = (name: string) => (name.length >= 30 ? `${name.substr(0, 28)}...` : name);

export const NomenclatureName = ({ name }: NomenclatureNameProps) => {
  const classes = useStyles();
  return (
    <StyledTooltip title={name} placement="top">
      <span className={classes.root}>{getName(name)}</span>
    </StyledTooltip>
  );
};

function StyledTooltip(props: TooltipProps) {
  const classes = useTooltipStyles();

  return <Tooltip arrow classes={classes} {...props} />;
}
