import { Grid } from 'components';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useStyles } from './useStyles';
import clsx from 'clsx';
import { RatingFinancialField } from './RatingFinancialField';
import { RatingInput } from '../../../schema';
import { useWatch } from 'react-hook-form';

export type RatingCreateFormProps = {
  inn: string;
  editId: number | null;
  setEdit: Dispatch<SetStateAction<number | null>>;
  control: any;
  setValue?: any;
  konturValues?: RatingInput | null;
};

export const RatingFinancialFields = (props: RatingCreateFormProps) => {
  const classes = useStyles();
  const { editId, control, setValue, konturValues } = props;

  const ratingClass = !editId ? classes.root : clsx(classes.root, classes.noEditMode);

  const salesProfit = useWatch({ control, name: 'salesProfit' });
  const amortization = useWatch({ control, name: 'amortization' });
  const expensesOnOffBalance = useWatch({ control, name: 'expensesOnOffBalance' });

  useEffect(() => {
    const calculateSustainableEBITDA = (
      salesProfit = 0,
      amortization = 0,
      expensesOnOffBalance = 0
    ) => {
      return salesProfit + amortization + expensesOnOffBalance;
    };

    setValue(
      'sustainableEBITDA',
      calculateSustainableEBITDA(
        parseInt(salesProfit.toString().replace(/\s/g, ''), 10) || 0,
        parseInt(amortization.toString().replace(/\s/g, ''), 10) || 0,
        parseInt(expensesOnOffBalance.toString().replace(/\s/g, ''), 10) || 0
      )
    );
  }, [salesProfit, amortization, expensesOnOffBalance, setValue]);

  return (
    <>
      <div className={ratingClass}>
        <Grid container rowSpacing={2}>
          <Grid item xs={24}>
            <RatingFinancialField
              name="totalAssets"
              control={control}
              setValue={setValue}
              konturValues={konturValues}
              rules={{
                required: true,
                pattern: /^[0-9,\-,\s]+$/i,
              }}
            />
          </Grid>
          <Grid item xs={24}>
            <RatingFinancialField
              name="netIntangibleAssets"
              konturValues={konturValues}
              control={control}
              setValue={setValue}
              rules={{
                required: true,
                pattern: /^[0-9,\-,\s]+$/i,
              }}
            />
          </Grid>
          <Grid item xs={24}>
            <RatingFinancialField
              name="intragroupLoansOlderOneYear"
              konturValues={konturValues}
              control={control}
              setValue={setValue}
              rules={{
                required: true,
                pattern: /^[0-9,\-,\s]+$/i,
              }}
            />
          </Grid>
          <Grid item xs={24}>
            <RatingFinancialField
              name="intragroupLoansNewerOneYear"
              konturValues={konturValues}
              control={control}
              setValue={setValue}
              rules={{
                required: true,
                pattern: /^[0-9,\-,\s]+$/i,
              }}
            />
          </Grid>
          <Grid item xs={24}>
            <RatingFinancialField
              name="intragroupDZ"
              konturValues={konturValues}
              control={control}
              setValue={setValue}
              rules={{
                required: true,
                pattern: /^[0-9,\-,\s]+$/i,
              }}
            />
          </Grid>
          <Grid item xs={24}>
            <RatingFinancialField
              name="accountCashierAndMoney"
              konturValues={konturValues}
              control={control}
              setValue={setValue}
              rules={{
                required: true,
                pattern: /^[0-9,\-,\s]+$/i,
              }}
            />
          </Grid>
          <Grid item xs={24}>
            <RatingFinancialField
              name="totalCashAndInvestments"
              konturValues={konturValues}
              control={control}
              setValue={setValue}
              rules={{
                required: true,
                pattern: /^[0-9,\-,\s]+$/i,
              }}
            />
          </Grid>
          <Grid item xs={24}>
            <RatingFinancialField
              name="capitalAndReserves"
              konturValues={konturValues}
              control={control}
              setValue={setValue}
              rules={{
                required: true,
                pattern: /^[0-9,\-,\s]+$/i,
              }}
            />
          </Grid>
          <Grid item xs={24}>
            <RatingFinancialField
              name="longTermDuties"
              konturValues={konturValues}
              control={control}
              setValue={setValue}
              rules={{
                required: true,
                pattern: /^[0-9,\-,\s]+$/i,
              }}
            />
          </Grid>
          <Grid item xs={24}>
            <RatingFinancialField
              name="otherLongTermDuties"
              konturValues={konturValues}
              control={control}
              setValue={setValue}
              rules={{
                required: true,
                pattern: /^[0-9,\-,\s]+$/i,
              }}
            />
          </Grid>
          <Grid item xs={24}>
            <RatingFinancialField
              name="shortTermBorrowedFunds"
              konturValues={konturValues}
              control={control}
              setValue={setValue}
              rules={{
                required: true,
                pattern: /^[0-9,\-,\s]+$/i,
              }}
            />
          </Grid>
          <Grid item xs={24}>
            <RatingFinancialField
              name="otherShortTermBorrowedFunds"
              konturValues={konturValues}
              control={control}
              setValue={setValue}
              rules={{
                required: true,
                pattern: /^[0-9,\-,\s]+$/i,
              }}
            />
          </Grid>
          <Grid item xs={24}>
            <RatingFinancialField
              name="partOfSubordinatedDebtPayableIn12Months"
              konturValues={konturValues}
              control={control}
              setValue={setValue}
              rules={{
                required: true,
                pattern: /^[0-9,\-,\s]+$/i,
              }}
            />
          </Grid>
          <Grid item xs={24}>
            <RatingFinancialField
              name="cashInParticipantsAccounts"
              konturValues={konturValues}
              control={control}
              setValue={setValue}
              rules={{
                required: true,
                pattern: /^[0-9,\-,\s]+$/i,
              }}
            />
          </Grid>
          <Grid item xs={24}>
            <RatingFinancialField
              name="leasingObligations"
              konturValues={konturValues}
              control={control}
              setValue={setValue}
              rules={{
                required: true,
                pattern: /^[0-9,\-,\s]+$/i,
              }}
            />
          </Grid>
          <Grid item xs={24}>
            <RatingFinancialField
              name="operationalLeasing"
              konturValues={konturValues}
              control={control}
              setValue={setValue}
              rules={{
                required: true,
                pattern: /^[0-9,\-,\s]+$/i,
              }}
            />
          </Grid>
          <Grid item xs={24}>
            <RatingFinancialField
              name="revenue"
              konturValues={konturValues}
              control={control}
              setValue={setValue}
              rules={{
                required: true,
                pattern: /^[0-9,\-,\s]+$/i,
              }}
            />
          </Grid>
          <Grid item xs={24}>
            <RatingFinancialField
              name="salesProfit"
              konturValues={konturValues}
              control={control}
              setValue={setValue}
              rules={{
                required: true,
                pattern: /^[0-9,\-,\s]+$/i,
              }}
            />
          </Grid>
          <Grid item xs={24}>
            <RatingFinancialField
              name="sustainableEBITDA"
              konturValues={konturValues}
              control={control}
              disabled={true}
              setValue={setValue}
              rules={{
                required: true,
                pattern: /^[0-9,\-,\s]+$/i,
              }}
            />
          </Grid>
          <Grid item xs={24}>
            <RatingFinancialField
              name="operatingResultOfActivitiesToBeDiscontinued"
              konturValues={konturValues}
              control={control}
              setValue={setValue}
              rules={{
                required: true,
                pattern: /^[0-9,\-,\s]+$/i,
              }}
            />
          </Grid>
          <Grid item xs={24}>
            <RatingFinancialField
              name="profitBeforeTax"
              konturValues={konturValues}
              control={control}
              setValue={setValue}
              rules={{
                required: true,
                pattern: /^[0-9,\-,\s]+$/i,
              }}
            />
          </Grid>
          <Grid item xs={24}>
            <RatingFinancialField
              name="amortization"
              konturValues={konturValues}
              control={control}
              setValue={setValue}
              rules={{
                pattern: /^[0-9,\-,\s]+$/i,
              }}
            />
          </Grid>
          <Grid item xs={24} className={classes.spacer}>
            <RatingFinancialField
              name="expensesOnOffBalance"
              konturValues={konturValues}
              control={control}
              setValue={setValue}
              rules={{
                pattern: /^[0-9,\-,\s]+$/i,
              }}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
