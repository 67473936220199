import { makeStyles, createStyles, Theme, Paper, Portal } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';

import { Button, Role } from 'components';
import { useEffect } from 'react';
import { ApplicationRoles } from 'services';
import { GeneralInformationIndividualFields } from './GeneralInformationIndividualFields';
import { useCounterpartyIndividualEditForm } from './useCounterpartyIndividualEditForm';
import { AdditionalDataContractsFields } from './AdditionalDataContractsFields';
import { useWatch } from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    panels: {
      marginBottom: '75px',
    },
    actions: {
      [theme.breakpoints.down(1200)]: {
        width: 'calc(100% - 85px)',
        left: 63,
      },
      display: 'flex',
      justifyContent: 'flex-start',
      width: 'calc(100% - 238px)',
      padding: theme.spacing('20px', '20px'),
      bottom: 0,
      position: 'fixed',
      zIndex: 1,
      left: 216,
    },
    actionButton: {
      marginRight: theme.spacing(2.5),
    },
  })
);

type CounterpartyIndividualEditFormProps = {
  setCounterpartyName: (counterpartyName: string) => void;
};

export const CounterpartyIndividualEditForm = ({
  setCounterpartyName,
}: CounterpartyIndividualEditFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { onSubmit, control, setValue, getValues, clearErrors, reset, ...rest } =
    useCounterpartyIndividualEditForm();
  const { isSubmitting, isValid } = rest;
  const counterpartyName = getValues('inn');

  useEffect(() => {
    setCounterpartyName(counterpartyName);
  }, [setCounterpartyName, counterpartyName]);

  const contracts = useWatch({ control, name: 'contracts' });

  return (
    <form className={classes.root}>
      <div className={classes.panels}>
        <Accordion defaultExpanded={true} disabled>
          <AccordionDetails>
            <GeneralInformationIndividualFields
              control={control}
              clearErrors={clearErrors}
              isValid={isValid}
              reset={reset}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography variant="subtitle1">{t('Additional data')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AdditionalDataContractsFields contracts={contracts} />
          </AccordionDetails>
        </Accordion>
      </div>
      <Portal container={document.body}>
        <Paper square className={classes.actions}>
          <Role role={ApplicationRoles.allExceptAssetManager}>
            <div className={classes.actionButton}>
              <Button
                color="primary"
                size="medium"
                type="submit"
                variant="contained"
                disabled={isSubmitting}
                onClick={onSubmit}
              >
                {t('Save')}
              </Button>
            </div>
          </Role>
        </Paper>
      </Portal>
    </form>
  );
};
