import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import dayjs from 'dayjs';
import { MissingHistoryTimeGroups } from 'schema/serverTypes';
import { useMemo } from 'react';
import { htmlLegendPlugin } from './htmlLegendPlugin';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { themeOrange as theme } from 'theme';

ChartJS.register(CategoryScale, LinearScale, BarElement, Legend, htmlLegendPlugin, ChartDataLabels);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    legend: {
      position: 'absolute',
      top: 14,
      left: 45,
      backgroundColor: theme.palette.background.default,
      padding: 6,
      display: 'inline-block',
      maxWidth: 480,
    },
  })
);

const labels = ['31-90', '91-360', '>361', 'Total dox'];

const emptyData = {
  labels,
  datasets: [
    {
      label: '28.01.2022',
      data: [],
      backgroundColor: theme.palette.primaryGradient[200],
    },

    {
      label: '25.02.2022',
      data: [],
      backgroundColor: theme.palette.primaryGradient[500],
    },
    {
      label: '25.03.2022',
      data: [],
      backgroundColor: theme.palette.primaryGradient[700],
    },
    {
      label: '25.04.2022',
      data: [],
      backgroundColor: theme.palette.primaryGradient[900],
    },
  ],
};

type Dataset = {
  label: string;
  data: number[];
  backgroundColor: string;
};

const backgroundColor = [
  theme.palette.primaryGradient[200],
  theme.palette.primaryGradient[500],
  theme.palette.primaryGradient[700],
  theme.palette.primaryGradient[900],
];

export type MissingChartsProps = {
  chartId: number;
  isLoading: boolean;
  groups: MissingHistoryTimeGroups;
  isFullScreen?: boolean;
};

export const MissingCharts = (props: MissingChartsProps) => {
  const classes = useStyles();
  const { isLoading, groups, chartId, isFullScreen } = props;

  const group31 = groups?.group31 ?? { min: 31, max: 90, dates: [] };
  const group91 = groups?.group91 ?? { min: 91, max: 360, dates: [] };
  const group361 = groups?.group361 ?? { min: 361, dates: [] };
  const total = groups?.total ?? { min: 0, dates: [] };

  const dates = group31.dates.map((t) => dayjs(t.date).format('DD.MM.YYYY'));

  const datasets: Dataset[] = dates.map((date, index) => {
    const count31 = group31.dates
      .filter((t) => dayjs(t.date).format('DD.MM.YYYY') === date)
      .map((t) => t.count);
    const count91 = group91.dates
      .filter((t) => dayjs(t.date).format('DD.MM.YYYY') === date)
      .map((t) => t.count);
    const count361 = group361.dates
      .filter((t) => dayjs(t.date).format('DD.MM.YYYY') === date)
      .map((t) => t.count);
    const countTotal = total.dates
      .filter((t) => dayjs(t.date).format('DD.MM.YYYY') === date)
      .map((t) => t.count);

    const count = count31;
    count91.forEach((t) => count.push(t));
    count361.forEach((t) => count.push(t));
    countTotal.forEach((t) => count.push(t));

    return {
      label: date,
      data: count,
      backgroundColor: backgroundColor[index],
    };
  });

  const data = {
    labels,
    datasets,
  };

  let max = 0;
  const NUMBER_OF_STEPS = 6;
  const counts = total.dates.map((t) => t.count);
  if (counts.length > 0) {
    max = Math.ceil(Math.max.apply(null, counts)) * 1.04;
  }

  const stepSize = Math.ceil(max / NUMBER_OF_STEPS);
  const exp = Math.pow(10, stepSize.toString().length - 1) / 2;
  const roundedStepSize = stepSize > 0 ? Math.ceil(max / NUMBER_OF_STEPS / exp) * exp : 1;

  const options = useMemo(() => {
    return {
      responsive: true,
      aspectRatio: isFullScreen ? 3.7 : 1.8,
      layout: {
        padding: {
          top: 56,
        },
      },
      scales: {
        y: {
          display: true,
          max: roundedStepSize * 6,
          ticks: {
            stepSize: roundedStepSize,
          },
        },
      },
      plugins: {
        htmlLegend: {
          // ID of the container to put the legend in
          containerID: `legend-container-${chartId}`,
        },
        legend: {
          display: false,
        },
        datalabels: {
          display: true,
          color: 'black',
          anchor: 'end',
          align: 'end',
          offset: -1,
        },
      },
    };
  }, [roundedStepSize, chartId, isFullScreen]);

  return (
    <>
      <div id={`legend-container-${chartId}`} className={classes.legend} />
      <Bar options={options as any} data={isLoading ? emptyData : data} />
    </>
  );
};
