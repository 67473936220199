import { useTranslation } from 'react-i18next';
import { Table, TableCell, TableHead, TableRow, TableBody } from '@material-ui/core';
import { usePDCData } from './usePDCData';
import { NomenclatureScheduleViewModel } from 'schema';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: 'auto',
      maxWidth: 'calc(100vw - 190px - 48px - var(--scrollbar-width)) ',
      '& .MuiTableBody-root .MuiTableRow-root:hover .MuiTableCell-root': {
        backgroundColor: theme.palette.background.light,
      },
    },
    wrapper: {
      position: 'relative',
      overflow: 'auto',
      border: '1px solid black',
      whiteSpace: 'nowrap',
    },
    fixed: {
      position: 'sticky',
      backgroundColor: '#FFFFFF',
      zIndex: 1,
      '&:nth-child(1)': {
        minWidth: 100,
        width: 100,
        left: 0,
      },
      '&:nth-child(2)': {
        width: 100,
        minWidth: 100,
        left: 100,
      },
      '&:nth-child(3)': {
        minWidth: 420,
        width: 420,
        left: 200,
      },
      '&:nth-child(4)': {
        minWidth: 60,
        width: 60,
        left: 620,
        paddingRight: 12,
        '&:after': {
          content: "''",
          top: 0,
          right: '0',
          position: 'absolute',
          display: 'block',
          width: 2,
          height: '100%',
          backgroundColor: theme.palette.mobileGray.main,
          boxShadow: '5px 0px 8px 3px #0000001A',
        },
      },
    },
    chart: {
      position: 'absolute',
      minWidth: 100,
      left: 1080,
    },
    value: {
      '&:nth-child(5)': {
        padding: '16px 6px 16px 20px',
      },
    },
  })
);

export type PDCTableProps = Omit<ReturnType<typeof usePDCData>, 'paging' | 'filter'>;

type TableItemProps = {
  row: NomenclatureScheduleViewModel;
};

const TableItem = (props: TableItemProps) => {
  const classes = useStyles();
  const { row } = props;

  return (
    <>
      <TableRow>
        <TableCell size="medium" className={classes.fixed}>
          {row.code}
        </TableCell>
        <TableCell size="medium" className={classes.fixed}>
          {dayjs(row.date).format('DD.MM.YYYY')}
        </TableCell>
        <TableCell size="medium" className={classes.fixed}>
          {row.category}
        </TableCell>
        <TableCell size="medium" className={classes.fixed}>
          {row.categoryCode}
        </TableCell>
        {row.items.map((item) => (
          <TableCell className={classes.value} size="medium" key={item.monthNumber}>
            {item.value}
          </TableCell>
        ))}
      </TableRow>
    </>
  );
};

export const PDCTable = (props: PDCTableProps) => {
  const classes = useStyles();
  const { data } = props;
  const { t } = useTranslation();

  const items = data[0]?.items ?? [];
  const chartColumns = items.length ?? 1;

  return (
    <div className={classes.root}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell size="medium" rowSpan={2} className={classes.fixed}>
              {t('Сhart code')}
            </TableCell>
            <TableCell size="medium" rowSpan={2} className={classes.fixed}>
              {t('Chart date')}
            </TableCell>
            <TableCell size="medium" rowSpan={2} className={classes.fixed}>
              {t('Category')}
            </TableCell>
            <TableCell size="medium" rowSpan={2} className={classes.fixed}>
              {t('CategoryCode')}
            </TableCell>
            <TableCell size="medium" colSpan={chartColumns} className={clsx(classes.chart)}>
              {t('PDC chart / %')}
            </TableCell>
          </TableRow>
          <TableRow>
            {items.map((item, index) => (
              <TableCell size="medium" key={item.monthNumber}>
                <br />
                {index}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <TableItem key={item.code} row={item} />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
