import { useForm, useWatch } from 'react-hook-form';
import { useCallback, useEffect, useMemo } from 'react';
import { usePaging } from 'components/paging';
import { useSorting } from 'components/sorting/useSorting';
import {
  ShipmentListRequestFilter,
  ShipmentListRequestSortBy,
  SortOrder,
} from 'schema/serverTypes';
import { useShipmentsListQuery } from 'services/api/useQuotasBackend';
import { startOfMonth, endOfMonth } from 'components/Home/Shipment/dates';

export const useShipmentsData = () => {
  const { page, pageSize, onPageChanged, onPageSizeChanged, onReset } = usePaging();

  const { sortBy, order, setOrder, setSortBy } = useSorting<ShipmentListRequestSortBy>({
    sortBy: ShipmentListRequestSortBy.Id,
    order: SortOrder.desc,
  });

  const handleSortBy = useCallback(
    (sortBy: ShipmentListRequestSortBy) => {
      setSortBy(sortBy);
    },
    [setSortBy]
  );

  const handleSortOrder = useCallback(
    (order: SortOrder) => {
      setOrder(order);
    },
    [setOrder]
  );

  const sorting = useMemo(() => {
    return {
      sortBy,
      order,
      setSortBy: handleSortBy,
      setOrder: handleSortOrder,
    };
  }, [sortBy, order, handleSortBy, handleSortOrder]);

  const { control, reset } = useForm<ShipmentListRequestFilter>({
    mode: 'onBlur',
    defaultValues: { from: startOfMonth, to: endOfMonth },
  });

  const handleOnReset = useCallback(() => {
    reset({ from: startOfMonth, to: endOfMonth });
  }, [reset]);

  const from = useWatch({ control, name: 'from' });
  const to = useWatch({ control, name: 'to' });

  const { data, isLoading } = useShipmentsListQuery({
    from,
    to,
    page,
    pageSize: pageSize,
    sortBy,
    order,
  });

  const filter = useMemo(() => {
    return {
      control,
      handleOnReset,
    };
  }, [control, handleOnReset]);

  const rows = data?.data ?? [];
  const pageCount = data?.pageCount ?? 0;
  const totalCount = data?.totalCount ?? 0;
  const length = rows.length;

  const paging = useMemo(() => {
    return {
      pageCount,
      totalCount,
      page,
      pageSize,
      dataCount: length,
      onPageChanged,
      onPageSizeChanged,
      onReset,
    };
  }, [pageCount, totalCount, page, pageSize, length, onPageChanged, onPageSizeChanged, onReset]);

  useEffect(() => {
    onPageChanged(1);
  }, [from, to, onPageChanged]);

  return {
    paging,
    filter,
    sorting,
    isLoading,
    data: data?.data ?? [],
  };
};
