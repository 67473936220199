import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { CommentWikiViewModel } from 'schema/serverTypes';
import { Button } from '../Button';
import { Grid } from '../Grid';
import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { WysiwygEditor } from '../tasks/WysiwygEditor';
import { FileUpload } from './FileUpload';
import { useAddWikiCommentMutation } from '../../services/api/useWikiBackend';
import { FormHelperText } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.bgGray.main,
      padding: theme.spacing(0),
      marginBottom: theme.spacing(2.5),
      position: 'relative',
    },
    button: {
      display: 'block',
      backgroundColor: 'white',
    },
    description: {
      position: 'relative',
    },
  })
);

export const AddComment = (props: { articleId: number }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { control, handleSubmit, setValue, formState } = useForm<CommentWikiViewModel>({
    mode: 'onChange',
    defaultValues: {
      text: '',
      files: [],
    },
  });
  const queryClient = useQueryClient();

  const { mutateAsync } = useAddWikiCommentMutation(props.articleId);

  const onSubmit = useMemo(() => {
    const submit = async (values: CommentWikiViewModel) => {
      const comment = await mutateAsync(values);
      if (comment) {
        setValue('text', '');
        setValue('files', []);
        await queryClient.invalidateQueries({
          predicate: (query) => {
            return query.queryKey.indexOf('wiki') > -1;
          },
        });
      }
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync, queryClient, setValue]);

  return (
    <form className={classes.root} onSubmit={onSubmit}>
      <Grid container columnSpacing={2} rowSpacing={1.5}>
        <Grid item xs={24} className={classes.description}>
          <Controller
            name="text"
            control={control}
            render={({ field, fieldState }) => {
              const helperText =
                fieldState.error !== undefined
                  ? (fieldState.error as FieldError).message
                  : undefined;

              return (
                <>
                  <WysiwygEditor value={field.value} onChange={field.onChange} />
                  {helperText && <FormHelperText error>{helperText}</FormHelperText>}
                </>
              );
            }}
            rules={{
              required: true,
              maxLength: {
                value: 10000,
                message: t('Maximum number of characters is', { count: 10000 }),
              },
            }}
          />
        </Grid>
        <Grid item xs={24}>
          <FileUpload control={control} setValue={setValue} />
        </Grid>
        <Grid item xs={24}>
          <Button
            className={classes.button}
            color="primary"
            size="medium"
            type="submit"
            variant="outlined"
            disabled={!formState.isValid}
          >
            {t('Add comment')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
