import { useCallback, useMemo, useState } from 'react';
import { QuotaFilterFormContext } from './types';
import { CounterpartyOption, UserViewModel } from 'schema';

export type QuotaFilterFormContextProviderProps = {
  children: React.ReactNode;
};

export const QuotaFilterFormContextProvider = (props: QuotaFilterFormContextProviderProps) => {
  const { children } = props;

  const [users, setUsers] = useState<UserViewModel[]>([]);
  const [counterparties, setCounterpaties] = useState<CounterpartyOption[]>([]);

  const addUser = useCallback(
    (user: UserViewModel) => {
      if (users.find((t) => t.id === user.id) === undefined) {
        setUsers([...users, user]);
        sessionStorage.setItem('quotaFilterUsers', JSON.stringify([...users, user]));
      }
    },
    [users, setUsers]
  );

  const addCounterparty = useCallback(
    (counterparty: CounterpartyOption) => {
      if (counterparties.find((t) => t.inn === counterparty.inn) === undefined) {
        setCounterpaties([...counterparties, counterparty]);
        sessionStorage.setItem(
          'quotaFilterCounterparties',
          JSON.stringify([...counterparties, counterparty])
        );
      }
    },
    [counterparties, setCounterpaties]
  );

  const value = useMemo(() => {
    return {
      users,
      counterparties,
      addUser,
      addCounterparty,
    };
  }, [users, counterparties, addUser, addCounterparty]);

  return (
    <QuotaFilterFormContext.Provider value={value}>{children}</QuotaFilterFormContext.Provider>
  );
};
