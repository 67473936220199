import { createStyles, makeStyles } from '@material-ui/core/styles';
import { formatNumber } from './formatNumber';

const useStyles = makeStyles((theme) =>
  createStyles({
    alert: {
      color: theme.palette.error.main,
    },
    warning: {
      color: theme.palette.darkAttention.main,
    },
    ok: {
      color: theme.palette.green.main,
    },
    normal: {
      color: theme.palette.common.black,
    },
  })
);

type ColorizeProps = {
  value: number;
  unit?: string;
  limits?: number[];
  isFormatMoney?: boolean;
};

export const Colorize = ({
  value,
  unit,
  limits = [90, 30],
  isFormatMoney = false,
}: ColorizeProps) => {
  const classes = useStyles();
  let colorClass = classes.normal;
  if (value > limits[0]) {
    colorClass = classes.alert;
  } else if (value > limits[1]) {
    colorClass = classes.warning;
  }

  return (
    <span className={colorClass}>
      {isFormatMoney ? formatNumber(value) : value}
      {unit ? ' ' + unit : ''}
    </span>
  );
};
