import { useDebounce } from 'use-debounce';
import { useNomenclatureListSearchQuery } from 'services/api/useNomenclaturesBackend';

export const useNomenclatureQuery = (inputValue?: string) => {
  const [input] = useDebounce(inputValue, 300);

  const { data, isLoading } = useNomenclatureListSearchQuery({
    search: input,
  });

  const options = data?.data ?? [];

  return { options, isLoading };
};
