import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Input, useToast } from 'components';
import { Control, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CalcMarginSettingViewModel, RatingBonusSettingViewModel } from 'schema/serverTypes';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useUpdateCalculationMarginSettingsMutation } from 'services';
import { useMemo } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // marginTop: theme.spacing(2.5),
      // marginBottom: theme.spacing(2),
    },
    number: {
      '& input': {
        textAlign: 'right',
      },
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',
    },
    button: {
      display: 'block',
    },
  })
);

type SettingsFormInnerProps = {
  defaultValues: CalcMarginSettingViewModel;
};

export const ModelSettingsForm = (props: SettingsFormInnerProps) => {
  const classes = useStyles();
  const toast = useToast();

  const { control, handleSubmit } = useForm<CalcMarginSettingViewModel>({
    mode: 'onBlur',
    defaultValues: props.defaultValues,
  });

  const {
    defaultValues: { ratingBonusSetting },
  } = props;

  const { mutateAsync, isLoading } = useUpdateCalculationMarginSettingsMutation({
    onSuccess: () => {
      toast(t('SuccessMessage'), 'success');
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const onSubmit = useMemo(() => {
    const submit = async (form: CalcMarginSettingViewModel) => {
      await mutateAsync(form);
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync]);

  const { t } = useTranslation();

  return (
    <form onSubmit={onSubmit} className={classes.root}>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="subtitle1">Общее</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Grid container columnSpacing={2} rowSpacing={2.5}>
              <Grid item md={4} xs={24}>
                <Input
                  name="baseMarginSetting.operatingExpenses"
                  control={control}
                  className={classes.number}
                  rules={{
                    required: true,
                    pattern: /^[0-9.\-\s]+$/i,
                  }}
                  label={`Операционные расходы, % `}
                />
              </Grid>
              <Grid item md={6} xs={24}>
                <Input
                  name="baseMarginSetting.riskPremiumCalculationCoef"
                  control={control}
                  className={classes.number}
                  rules={{
                    required: true,
                    pattern: /^[0-9.\-\s]+$/i,
                  }}
                  label={`Коэффициент расчета премии за риск`}
                />
              </Grid>
              <Grid item md={4} xs={24}>
                <Input
                  name="baseMarginSetting.minimumIncome"
                  control={control}
                  className={classes.number}
                  rules={{
                    required: true,
                    pattern: /^[0-9.\-\s]+$/i,
                  }}
                  label={`Минимальный доход, % `}
                />
              </Grid>
              <Grid item md={'auto'} xs={24} className={classes.label}>
                Границы расчетной маржи
              </Grid>
              <Grid item md={3} xs={24}>
                <Input
                  name="baseMarginSetting.borderFrom"
                  control={control}
                  className={classes.number}
                  rules={{
                    required: true,
                    pattern: /^[0-9.\-\s]+$/i,
                  }}
                  label={`От, %`}
                />
              </Grid>
              <Grid item md={3} xs={24}>
                <Input
                  name="baseMarginSetting.borderTo"
                  control={control}
                  className={classes.number}
                  rules={{
                    required: true,
                    pattern: /^[0-9.\-\s]+$/i,
                  }}
                  label={`До, %`}
                />
              </Grid>
              <Grid item xs={24}>
                <Typography variant="subtitle1">Надбавка за рейтинг</Typography>
              </Grid>
            </Grid>
            <Grid container columnSpacing={2} rowSpacing={2.5}>
              {ratingBonusSetting.map((item) => (
                <RatingItem control={control} item={item} key={item.name} />
              ))}
            </Grid>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="subtitle1">Стартап</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Grid container columnSpacing={2} rowSpacing={2.5}>
              <Grid item md={6} xs={24}>
                <Input
                  name="retailMarginSetting.riskPremiumCalculationCoef"
                  control={control}
                  className={classes.number}
                  rules={{
                    required: true,
                    pattern: /^[0-9.\-\s]+$/i,
                  }}
                  label={`Коэффициент расчета премии за риск`}
                />
              </Grid>
              <Grid item md={4} xs={24}>
                <Input
                  name="retailMarginSetting.minimumIncome"
                  control={control}
                  className={classes.number}
                  rules={{
                    required: true,
                    pattern: /^[0-9.\-\s]+$/i,
                  }}
                  label={`Минимальный доход, % `}
                />
              </Grid>
              <Grid item md={'auto'} xs={24} className={classes.label}>
                Границы расчетной маржи
              </Grid>
              <Grid item md={3} xs={24}>
                <Input
                  name="retailMarginSetting.borderFrom"
                  control={control}
                  className={classes.number}
                  rules={{
                    required: true,
                    pattern: /^[0-9.\-\s]+$/i,
                  }}
                  label={`От, %`}
                />
              </Grid>
              <Grid item md={3} xs={24}>
                <Input
                  name="retailMarginSetting.borderTo"
                  control={control}
                  className={classes.number}
                  rules={{
                    required: true,
                    pattern: /^[0-9.\-\s]+$/i,
                  }}
                  label={`До, %`}
                />
              </Grid>
            </Grid>
          </div>
        </AccordionDetails>
      </Accordion>
      {/*<Accordion defaultExpanded={true}>*/}
      {/*  <AccordionSummary*/}
      {/*    expandIcon={<ExpandMoreIcon />}*/}
      {/*    aria-controls="panel1a-content"*/}
      {/*    id="panel1a-header"*/}
      {/*  >*/}
      {/*    <Typography variant="subtitle1">Модель Семь миллионов</Typography>*/}
      {/*  </AccordionSummary>*/}
      {/*  <AccordionDetails>*/}
      {/*    <div>*/}
      {/*      <Grid container columnSpacing={2} rowSpacing={2.5}>*/}
      {/*        <Grid item md={6} xs={24}>*/}
      {/*          <Input*/}
      {/*            name="boxMarginSetting.riskPremiumCalculationCoef"*/}
      {/*            control={control}*/}
      {/*            className={classes.number}*/}
      {/*            rules={{*/}
      {/*              required: true,*/}
      {/*              pattern: /^[0-9.\-\s]+$/i,*/}
      {/*            }}*/}
      {/*            label={`Коэффициент расчета премии за риск`}*/}
      {/*          />*/}
      {/*        </Grid>*/}
      {/*        <Grid item md={4} xs={24}>*/}
      {/*          <Input*/}
      {/*            name="boxMarginSetting.minimumIncome"*/}
      {/*            control={control}*/}
      {/*            className={classes.number}*/}
      {/*            rules={{*/}
      {/*              required: true,*/}
      {/*              pattern: /^[0-9.\-\s]+$/i,*/}
      {/*            }}*/}
      {/*            label={`Минимальный доход, % `}*/}
      {/*          />*/}
      {/*        </Grid>*/}
      {/*        <Grid item md={'auto'} xs={24} className={classes.label}>*/}
      {/*          Границы расчетной маржи*/}
      {/*        </Grid>*/}
      {/*        <Grid item md={3} xs={24}>*/}
      {/*          <Input*/}
      {/*            name="boxMarginSetting.borderFrom"*/}
      {/*            control={control}*/}
      {/*            className={classes.number}*/}
      {/*            rules={{*/}
      {/*              required: true,*/}
      {/*              pattern: /^[0-9.\-\s]+$/i,*/}
      {/*            }}*/}
      {/*            label={`От, %`}*/}
      {/*          />*/}
      {/*        </Grid>*/}
      {/*        <Grid item md={3} xs={24}>*/}
      {/*          <Input*/}
      {/*            name="boxMarginSetting.borderTo"*/}
      {/*            control={control}*/}
      {/*            className={classes.number}*/}
      {/*            rules={{*/}
      {/*              required: true,*/}
      {/*              pattern: /^[0-9.\-\s]+$/i,*/}
      {/*            }}*/}
      {/*            label={`До, %`}*/}
      {/*          />*/}
      {/*        </Grid>*/}
      {/*      </Grid>*/}
      {/*    </div>*/}
      {/*  </AccordionDetails>*/}
      {/*</Accordion>*/}
      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item md={24} xs={24}>
          <Button
            className={classes.button}
            color="primary"
            size="medium"
            type="submit"
            variant="contained"
            disabled={isLoading}
          >
            {t('Save')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const RatingItem = (props: {
  item: RatingBonusSettingViewModel;
  control: Control<CalcMarginSettingViewModel, any>;
}) => {
  const classes = useStyles();
  const {
    control,
    item: { name, ratingIndex },
  } = props;

  return (
    <>
      <Grid item md={1} mdStart={ratingIndex % 2 ? 1 : 11} xs={24} className={classes.label}>
        {name}
      </Grid>
      <Grid item md={4} xs={24}>
        <Input
          name={`ratingBonusSetting.${ratingIndex - 1}.pd`}
          control={control}
          className={classes.number}
          rules={{
            required: true,
            pattern: /^[0-9.\-\s]+$/i,
          }}
          label={`PD, %`}
        />
      </Grid>
      <Grid item md={4} xs={24}>
        <Input
          name={`ratingBonusSetting.${ratingIndex - 1}.risk`}
          control={control}
          className={classes.number}
          rules={{
            required: true,
            pattern: /^[0-9.\-\s]+$/i,
          }}
          label={`Риск за рейтинг, %`}
        />
      </Grid>
    </>
  );
};
