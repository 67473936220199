import { useParams } from 'react-router-dom';
import { useUserQuery } from './useUserQuery';
import { UserEditFormSkeleton } from './UserEditForm1';
import { Sidebar } from 'components/Sidebar';
import { DealerEditForm } from './DealerEditForm';
import { ApplicationRole } from 'schema/serverTypes';
import { UserEditForm1 } from './UserEditForm1';

export const UserEditFormPanel = ({ id }: { id: string }) => {
  const { id: userId } = useParams<{ id: string }>();
  const { user, isLoading } = useUserQuery(id || userId);

  return (
    <Sidebar url="/users">
      {isLoading || user === undefined ? (
        <UserEditFormSkeleton />
      ) : user.role === ApplicationRole.Dealer ? (
        <DealerEditForm user={user} />
      ) : (
        <UserEditForm1 user={user} />
      )}
    </Sidebar>
  );
};
