import { Autocomplete } from 'components';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import { WikiTagListRequest, WikiTagSortBy, SortOrder } from 'schema/serverTypes';
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteChangeDetails,
} from '@material-ui/lab/useAutocomplete';
import { useWikiTagListQuery } from '../../services/api/useWikiBackend';

const getOptionLabel = (option: string | null) => {
  return option ?? '';
};

const getOptionSelected = (option: string | null, value: string | null) => {
  return option === value;
};

export type WikiTagAutocompleteProps = {
  count?: number;
  tags?: string[];
  onChange: (tags: string[]) => void;
  freeSolo?: boolean;
};

export const WikiTagAutocomplete = (props: WikiTagAutocompleteProps) => {
  const { tags = [], onChange, count = 20, freeSolo = false } = props;

  const { t } = useTranslation();

  const [tag, setTag] = useState('');
  const [search] = useDebounce(tag, 300);

  const request = useMemo<WikiTagListRequest>(() => {
    return {
      search,
      count,
      sortBy: WikiTagSortBy.Text,
      order: SortOrder.asc,
    };
  }, [search, count]);

  const { data, isLoading } = useWikiTagListQuery(request);

  let options = (data?.data ?? []).map((t) => t.text);

  const handleOnInputChange = useCallback(
    (_ev: React.ChangeEvent<{}>, value: string, _reason: AutocompleteInputChangeReason) => {
      setTag(value);
    },
    [setTag]
  );

  const handleOnChange = useCallback(
    (
      _e: React.ChangeEvent<{}>,
      value: string | null,
      _reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<string | null>
    ) => {
      if (value === null) {
        onChange([]);
        setTag('');
      } else {
        onChange(tags ? [...tags.filter((v) => v !== value), value] : [value]);
        setTag('');
      }
    },
    [onChange, tags]
  );

  if (tag && freeSolo) {
    const existing = options.find((t) => t === tag);
    if (existing === undefined) {
      options = [tag, ...options];
    }
  }

  return (
    <Autocomplete<string | null, false, false, boolean>
      label={t('Choose tag')}
      options={options}
      inputValue={tag}
      onInputChange={handleOnInputChange}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      onChange={handleOnChange}
      value={null}
      loading={isLoading}
      variant="standard"
      freeSolo={freeSolo}
    />
  );
};
