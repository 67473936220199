import MuiRadio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import { createStyles, withStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { useController, FieldPath, FieldValues, FieldError } from 'react-hook-form';
import { GenericWithStyles } from './GenericWithStyles';
import { RadioProps } from './Radio';
import clsx from 'clsx';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: '-6px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& .MuiFormControlLabel-root': {
        marginRight: theme.spacing(1),
      },
      '& .MuiButtonBase-root': {
        padding: theme.spacing(1),
      },
      '& .MuiFormControl-root': {
        marginTop: theme.spacing(1),
      },
      '& .MuiFormLabel-root': {
        fontSize: '12px',
      },
    },
    label: {
      marginRight: theme.spacing(4),
    },
    group: {},
    option: {
      '&:hover': {
        '& svg': {
          fill: theme.palette.primary.main,
        },
      },
    },
    error: {},
  });

function StyledRadio<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(props: RadioProps<TFieldValues, TName>) {
  const {
    defaultValue = '',
    name,
    classes,
    label,
    options = [],
    control,
    shouldUnregister,
    rules,
  } = props;

  const {
    field: { ref, value = defaultValue, ...inputProps },
    fieldState: { invalid, error },
  } = useController<TFieldValues, TName>({
    control,
    name,
    shouldUnregister,
    rules,
  });

  const helperText = error !== undefined ? (error as FieldError).message : undefined;

  return (
    <FormControl component="fieldset" error={invalid} className={classes?.root} size="small">
      <FormLabel component="div" className={classes?.label}>
        {label}:
      </FormLabel>
      <RadioGroup row aria-label={name} className={classes?.group} value={value} {...inputProps}>
        {options.map(({ label, value }) => {
          return (
            <FormControlLabel
              key={value}
              className={classes?.option}
              value={value}
              control={
                <MuiRadio
                  disableRipple
                  checkedIcon={<CCheckedIcon color={value} />}
                  icon={<CIcon color={value} />}
                />
              }
              label={label}
            />
          );
        })}
      </RadioGroup>
      <FormHelperText className={classes?.error}>{helperText}</FormHelperText>
    </FormControl>
  );
}

export const ColorRadio = withStyles(styles, { withTheme: true })(StyledRadio) as <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: GenericWithStyles<RadioProps<TFieldValues, TName>>
) => React.ReactElement;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      borderRadius: '50%',
      width: 20,
      height: 20,
      background: (props: { color: string }) => {
        return props.color;
      },
      '&$checked:after': {
        content: "''",
        display: 'block',
        width: 28,
        height: 28,
        border: '1px solid #ABC3EB',
        position: 'relative',
        top: -4,
        left: -4,
        borderRadius: '50%',
      },
    },
    checked: {},
  })
);

const CIcon = (props: { color: string }) => {
  const classes = useStyles(props);

  return <span className={classes.icon} />;
};

const CCheckedIcon = (props: { color: string }) => {
  const classes = useStyles(props);

  return <span className={clsx(classes.icon, classes.checked)} />;
};
