import { usePaging } from 'components/paging';
import { useWikiTagListQuery } from 'services/api/useWikiBackend';
import { useForm, useWatch } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { SortOrder, WikiTagSortBy } from 'schema/serverTypes';

export type SearchFilterFormValues = {
  code?: string;
  search?: string;
};

export type WikiTagsSorting = Pick<ReturnType<typeof useWikiTagsData>, 'sorting'>['sorting'];

export const useWikiTagsData = () => {
  const { page, pageSize, onPageChanged, onPageSizeChanged } = usePaging();

  const { control, reset } = useForm<SearchFilterFormValues>({
    mode: 'onBlur',
    defaultValues: { search: '' },
  });

  const handleOnReset = useCallback(() => {
    reset({ search: undefined });
  }, [reset]);

  const searchValue = useWatch({ control, name: 'search' });
  const [search] = useDebounce(searchValue ?? '', 500);

  const [sortBy, setSortBy] = useState<WikiTagSortBy>(WikiTagSortBy.Text);
  const [order, setOrder] = useState(SortOrder.desc);

  const handleSortBy = useCallback(
    (sortBy: WikiTagSortBy) => {
      setSortBy(sortBy);
    },
    [setSortBy]
  );

  const handleSortOrder = useCallback(
    (order: SortOrder) => {
      setOrder(order);
    },
    [setOrder]
  );

  const { data, isLoading } = useWikiTagListQuery({
    page,
    pageSize,
    search,
    sortBy,
    order,
  });

  const rows = data?.data ?? [];

  useEffect(() => {
    onPageChanged(1);
  }, [search, onPageChanged]);

  return {
    filter: {
      control,
      handleOnReset,
    },
    paging: {
      pageCount: data?.pageCount ?? 0,
      totalCount: data?.totalCount ?? 0,
      page,
      pageSize,
      dataCount: rows.length,
      onPageChanged,
      onPageSizeChanged,
    },
    sorting: {
      sortBy,
      order,
      setSortBy: handleSortBy,
      setOrder: handleSortOrder,
    },
    rows,
    isLoading,
  };
};
