import { useCallback, useEffect, useMemo } from 'react';
import {
  IndustrySpecializationFilter,
  IndustrySpecializationSortBy,
  SortOrder,
} from 'schema/serverTypes';
import { useForm, useWatch } from 'react-hook-form';
import { usePaging } from 'components/paging/usePaging';
import { useSorting } from 'components/sorting/useSorting';
import { useIndustrySpecializationListQuery } from 'services/api/useIndustrySpecializationBackend';

export const useIndustrySpecializationData = () => {
  const { page, pageSize, onPageChanged, onPageSizeChanged, onReset } = usePaging();

  const { sortBy, order, setOrder, setSortBy } = useSorting<IndustrySpecializationSortBy>({
    sortBy: IndustrySpecializationSortBy.Id,
    order: SortOrder.asc,
  });

  const handleSortBy = useCallback(
    (sortBy: IndustrySpecializationSortBy) => {
      setSortBy(sortBy);
    },
    [setSortBy]
  );

  const handleSortOrder = useCallback(
    (order: SortOrder) => {
      setOrder(order);
    },
    [setOrder]
  );

  const sorting = useMemo(() => {
    return {
      sortBy,
      order,
      setSortBy: handleSortBy,
      setOrder: handleSortOrder,
    };
  }, [sortBy, order, handleSortBy, handleSortOrder]);

  const { control, reset } = useForm<IndustrySpecializationFilter>({
    mode: 'onBlur',
  });

  const handleOnReset = useCallback(() => {
    reset();
  }, [reset]);

  const industryName = useWatch({ control, name: 'industryName' });
  const code = useWatch({ control, name: 'code' });

  const { data, isLoading } = useIndustrySpecializationListQuery({
    industryName,
    code,
    page,
    pageSize: pageSize,
    sortBy,
    order,
  });

  const filter = useMemo(() => {
    return {
      control,
      handleOnReset,
    };
  }, [control, handleOnReset]);

  const rows = data?.data ?? [];
  const pageCount = data?.pageCount ?? 0;
  const totalCount = data?.totalCount ?? 0;
  const length = rows.length;

  const paging = useMemo(() => {
    return {
      pageCount,
      totalCount,
      page,
      pageSize,
      dataCount: length,
      onPageChanged,
      onPageSizeChanged,
      onReset,
    };
  }, [pageCount, totalCount, page, pageSize, length, onPageChanged, onPageSizeChanged, onReset]);

  useEffect(() => {
    onPageChanged(1);
  }, [industryName, code, onPageChanged]);

  return {
    paging,
    filter,
    sorting,
    isLoading,
    data: data?.data ?? [],
    debtDate: data?.debtDate,
  };
};
