import { Link, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Avatar, LogoutButton, UserEditFormPanel } from 'components';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { ComponentProps, useCallback } from 'react';
import { useUsersBackendQuery1 } from 'services/api';
import { UserShortViewModel } from 'schema/serverTypes';
import { useUserRole } from 'services/authentication/useUserRole';
import { RoleName } from 'schema';
import { ApplicationRoles } from 'services';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  info: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  title: {
    color: theme.palette.background.paper,
    marginRight: theme.spacing(2),
  },
}));

export const Header = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { userManager } = useUserRole();

  const onSuccess = useCallback(
    async (data: UserShortViewModel) => {
      const user = await userManager.getUser();

      if (user === null) {
        //window.location.href = '/login'; DLLCRMV2-1704
        return;
      }

      const role = user.profile.role as RoleName;

      if (role === ApplicationRoles.dealer && data.activeToDate === undefined) {
        try {
          await userManager.clearStaleState();
          await userManager.revokeAccessToken();
          await userManager.removeUser();
        } catch (exc) {
          console.log(exc);
        }

        return;
      }

      if (data.role !== role) {
        try {
          await userManager.revokeAccessToken();
          await userManager.removeUser();
          await userManager.signinSilent();
        } catch (exc) {
          console.log(exc);
        }
      }
    },
    [userManager]
  );

  const { data } = useUsersBackendQuery1<UserShortViewModel>({
    relativeUrl: 'me',
    queryKey: 'me',
    options: {
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      onSuccess,
    },
  });

  if (!data) {
    return null;
  }

  const profile = {
    id: data.id,
    name: data.displayName,
    role: data.role,
    email: data.email,
  };

  return (
    <div className={classes.root}>
      <Link className={classes.info} to={{ hash: '#me' }}>
        <Typography variant="subtitle2" className={classes.title}>
          {t('Welcome', { profile })}
        </Typography>
        <Avatar imageSrc={'/img/avatar.png'} size="small" />
      </Link>
      <LogoutButton />
      <Route
        component={({ location }: ComponentProps<any>) =>
          location.hash === '#me' ? <UserEditFormPanel id={profile.id} /> : null
        }
      />
    </div>
  );
};
