import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableCell, TableHead, TableRow, TableBody } from '@material-ui/core';
import {
  DealQualificationGroup,
  DealQualificationRuleViewModel,
  DealQualificationType,
} from 'schema/serverTypes';
import { EditButton } from '../EditButton';
import { useTableStyles } from '../useTableStyles';
import { TableSortLabel } from 'components/data-grid/TableSortLabel';
import { QualificationsSorting } from './useQualificationsData';

export type QualificationsTableProps = {
  rows: DealQualificationRuleViewModel[];
  loading: boolean;
  onOpen: (item: DealQualificationRuleViewModel) => void;
  sorting: QualificationsSorting;
};

type TableItemProps = {
  item: DealQualificationRuleViewModel;
  onOpen: (item: DealQualificationRuleViewModel) => void;
};

export const typeMap = {
  [DealQualificationType.Manual]: 'Ручное',
  [DealQualificationType.NoLimit]: 'Без ограничений',
  [DealQualificationType.Rule]: 'Авто',
};

export const groupMap = {
  [DealQualificationGroup.General]: 'Общие',
  [DealQualificationGroup.Counterparties]: 'Контрагенты',
  [DealQualificationGroup.ContractBase]: 'Договорная база',
  [DealQualificationGroup.LeaseCalculation]: 'Лизинговый расчет',
  [DealQualificationGroup.PaymentAndDeliveryOrder]: 'Порядок оплаты и поставки',
};

const TableItem = (props: TableItemProps) => {
  const classes = useTableStyles();
  const { item, onOpen } = props;

  const handleOpen = useCallback(() => {
    onOpen(item);
  }, [item, onOpen]);

  return (
    <>
      <TableRow>
        <TableCell size="medium">{groupMap[item.group]}</TableCell>
        <TableCell size="medium">{item.name}</TableCell>
        <TableCell size="medium">{typeMap[item.type]}</TableCell>
        <TableCell size="medium">{item.rule ? item.rule.displayName : 'Нет'}</TableCell>
        <TableCell size="medium" className={classes.actions}>
          <EditButton onClick={handleOpen} />
        </TableCell>
      </TableRow>
    </>
  );
};

export const QualificationsTable = (props: QualificationsTableProps) => {
  const classes = useTableStyles();
  const { rows, onOpen, sorting } = props;
  const { t } = useTranslation();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell size="medium">
            <TableSortLabel columnName={'group'} sorting={sorting}>
              {t('Group of parameters')}
            </TableSortLabel>
          </TableCell>
          <TableCell size="medium">
            <TableSortLabel columnName={'name'} sorting={sorting}>
              {t('Parameter')}
            </TableSortLabel>
          </TableCell>
          <TableCell size="medium">
            <TableSortLabel columnName={'type'} sorting={sorting}>
              {t('Type of qualification')}
            </TableSortLabel>
          </TableCell>
          <TableCell size="medium">
            <TableSortLabel columnName={'rule'} sorting={sorting}>
              {t('Relation to the rule of the KR model')}
            </TableSortLabel>
          </TableCell>
          <TableCell className={classes.change} size="medium"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((item: DealQualificationRuleViewModel, index: number) => (
          <TableItem key={index} item={item} onOpen={onOpen} />
        ))}
      </TableBody>
    </Table>
  );
};
