import React, { useMemo, useState } from 'react';
import type { ReactNode } from 'react';
import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import type { Active, UniqueIdentifier } from '@dnd-kit/core';
import { SortableContext, arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { SortableOverlay } from './SortableOverlay';
import { DragHandle, SortableItem } from './SortableItem';

export interface BaseItem {
  id: UniqueIdentifier;
}

interface Props<T extends BaseItem> {
  items: T[];
  onChange(items: T[]): void;
  children: ReactNode;
}

export function SortableList<T extends BaseItem>({ items, onChange, children }: Props<T>) {
  const [active, setActive] = useState<Active | null>(null);
  const activeItem = useMemo(() => items.find((item) => item.id === active?.id), [active, items]);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <DndContext
      sensors={sensors}
      onDragStart={({ active }) => {
        setActive(active);
      }}
      onDragEnd={({ active, over }) => {
        if (over && active.id !== over?.id) {
          const activeIndex = items.findIndex(({ id }) => id === active.id);
          const overIndex = items.findIndex(({ id }) => id === over.id);

          onChange(arrayMove(items, activeIndex, overIndex));
        }
        setActive(null);
      }}
      onDragCancel={() => {
        setActive(null);
      }}
    >
      <SortableContext items={items}>
        {items.map((item) => {
          return (
            <React.Fragment key={item.id}>
              {
                <SortableList.Item id={item.id}>
                  {(children as any).filter((el: any) => {
                    return el.props.children.props.id === item.id;
                  })}
                </SortableList.Item>
              }
            </React.Fragment>
          );
        })}
      </SortableContext>
      <SortableOverlay>
        {activeItem ? (
          <SortableList.Item id={activeItem.id}>
            {(children as any).filter((el: any) => {
              return el.props.children.props.id === activeItem.id;
            })}
          </SortableList.Item>
        ) : null}
      </SortableOverlay>
    </DndContext>
  );
}

SortableList.Item = SortableItem;
SortableList.DragHandle = DragHandle;
