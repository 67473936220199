import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { QuotaHistoryWithAvailableOwners, QuotaCalculationResult, User } from 'schema';
import { useQuotasBackendMutation } from 'services';
import { useGoBack } from 'hooks';
import { useForm } from 'react-hook-form';

type ChangeOwnerFormValues = {
  ownerId: string;
};

export const useChangeOwnerForm = (quota: QuotaHistoryWithAvailableOwners) => {
  const { availableOwners, owner } = quota;

  const goBack = useGoBack();

  const queryClient = useQueryClient();

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isDirty, isSubmitted, isSubmitSuccessful },
  } = useForm<ChangeOwnerFormValues>({
    defaultValues: {
      ownerId: owner.id,
    },
    mode: 'onBlur',
  });

  const { mutateAsync, isLoading, isError } = useQuotasBackendMutation<
    User,
    QuotaCalculationResult
  >(`${quota.id}/owner`, {
    method: 'PUT',
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          if (Array.isArray(query.queryKey)) {
            const key = query.queryKey as Array<string>;
            if (key[0] === 'quotas') {
              return true;
            }
          } else if (typeof query.queryKey === 'string') {
            return (query.queryKey as string).toLowerCase().startsWith('contracts');
          }
          return false;
        },
      });
    },
  });

  const onSubmit = useMemo(() => {
    const submit = async (values: ChangeOwnerFormValues) => {
      var { ownerId } = values;
      const owner = availableOwners.find((t) => t.id === ownerId);
      if (owner) {
        await mutateAsync(owner);
        goBack();
      }
    };

    return handleSubmit(submit);
  }, [mutateAsync, availableOwners, goBack, handleSubmit]);

  return {
    control,
    onSubmit,
    initialValues: {
      ownerId: owner.id,
    },
    disabled: isSubmitting || isLoading || availableOwners.length === 0,
    isLoading,
    isDirty,
    isError: isError || (isSubmitted && !isSubmitSuccessful),
  };
};
