import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IconSprite } from '../icons';
import clsx from 'clsx';
import { themeOrange as theme } from 'theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: 56,
      height: 24,
      background: 'rgba(234, 234, 240, 0.6)',
      boxShadow:
        'inset 0px 0.545045px 1.09009px rgba(97, 97, 97, 0.2), inset 0px 1.09009px 2.18018px rgba(97, 97, 97, 0.2)',
      borderRadius: 6,
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
      padding: 2,
      userSelect: 'none',
    },
    btn: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0',
      width: 24,
      height: 20,
      position: 'relative',
      zIndex: 0,
    },
    active: {
      '&:before': {
        content: "''",
        position: 'absolute',
        top: 0,
        left: 0,
        width: 24,
        height: 20,
        zIndex: '-1',
        background: 'white',
        boxShadow:
          '0px 0.545045px 1.09009px rgba(97, 97, 97, 0.2), 0px 1.09009px 2.18018px rgba(97, 97, 97, 0.2)',
        borderRadius: 4,
      },
    },
  })
);

export const IssueListSwitcher = (props: any) => {
  const classes = useStyles();
  const { isGrouped, setGrouped } = props;

  const switchHandler = () => {
    setGrouped(!isGrouped);
  };

  return (
    <div className={classes.root} onClick={switchHandler}>
      <div className={clsx(classes.btn, !isGrouped ? classes.active : '')}>
        <IconSprite
          icon={'switcher-list'}
          color={!isGrouped ? theme.palette.text.secondary : theme.palette.text.primary}
          width={'12px'}
          height={'12px'}
        />
      </div>
      <div className={clsx(classes.btn, isGrouped ? classes.active : '')}>
        <IconSprite
          icon={'switcher-grouped'}
          color={isGrouped ? theme.palette.text.secondary : theme.palette.text.primary}
          width={'12px'}
          height={'12px'}
        />
      </div>
    </div>
  );
};
