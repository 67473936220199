import { FutureShipmentListRequest, FutureShipmentModelPagedList } from 'schema/serverTypes';
import { createUrlFromParts, createOptions, BackendQueryOptions } from './types';
import { useBackendQuery } from './useBackend';

const useFutureShipmentBackendQuery = <TResponse>(options: BackendQueryOptions<TResponse>) =>
  useBackendQuery(
    createUrlFromParts('futureShipments', options.relativeUrl, options.searchParams),
    options.relativeUrl === ''
      ? `FutureShipment-${options.searchParams}`
      : `FutureShipment-${options.relativeUrl}-${options.searchParams}`,
    createOptions(options.options)
  );

export const useFutureShipmentListRequest = (request: FutureShipmentListRequest) => {
  const {
    contractNumber,
    quotaNumber,
    lesseeInn,
    dealerInn,
    sortBy,
    order,
    page,
    pageSize,
    tabId = 0,
    quotaOwnerId,
    isInWork,
  } = request;

  const searchParams = new URLSearchParams();
  searchParams.set('tabId', tabId.toString());

  if (contractNumber !== undefined && contractNumber !== '') {
    searchParams.set('contractNumber', contractNumber);
  }
  if (quotaNumber !== undefined && quotaNumber !== '') {
    searchParams.set('quotaNumber', quotaNumber);
  }
  if (lesseeInn && lesseeInn.length > 0) {
    lesseeInn.forEach((t) => searchParams.append('lesseeInn', t));
  }
  if (dealerInn && dealerInn.length > 0) {
    dealerInn.forEach((t) => searchParams.append('dealerInn', t));
  }

  if (quotaOwnerId && quotaOwnerId.length > 0) {
    quotaOwnerId.forEach((t) => searchParams.append('quotaOwnerId', t));
  }
  if (isInWork !== undefined) {
    searchParams.set('isInWork', isInWork ? 'true' : 'false');
  }

  if (sortBy) {
    searchParams.set('sortBy', sortBy);
  }
  if (order) {
    searchParams.set('order', order);
  }
  if (page) {
    searchParams.set('page', page.toString());
  }
  if (pageSize) {
    searchParams.set('pageSize', pageSize.toString());
  }

  return useFutureShipmentBackendQuery<FutureShipmentModelPagedList>({
    relativeUrl: '',
    searchParams,
    options: {
      refetchOnWindowFocus: true,
    },
  });
};
