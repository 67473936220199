import { Grid } from 'components/Grid';
import { Critical } from 'components';
import { useTranslation } from 'react-i18next';
import { useErrorContext } from 'components/tasks';
import { IssueSource } from 'schema/serverTypes';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing(16),
    },
    title: {
      color: theme.palette.primary.main,
      fontSize: 20,
      textAlign: 'center',
    },
    description: {
      color: theme.palette.common.black,
      fontSize: 16,
      textAlign: 'center',
      maxWidth: 437,
    },
  })
);

export const EmptyPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  useErrorContext({
    title: t('Home'),
    source: IssueSource.home,
  });

  return (
    <>
      <Critical />
      <Grid container spacing={2.5}>
        <Grid item xs={24}>
          <div className={classes.container}>
            <h2 className={classes.title}>{t('Welcome to Bumblebee!')}</h2>
            <div className={classes.description}>{t('24hours')}</div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
