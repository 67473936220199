import { useLocation, useParams } from 'react-router-dom';
import { Sidebar } from 'components/Sidebar';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Button, Input } from 'components';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { QuotaNoteViewModel, QuotaStatus } from 'schema/serverTypes';
import { useMemo } from 'react';
import { useAddQuotaNoteMutation, useQuotaNotesQuery } from 'services/api';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '440px',
      minWidth: '440px',
      border: 'none',
      boxShadow: 'none',
      height: '100%',
      display: 'grid',
      gridTemplateRows: 'auto auto 1fr auto',
    },
    headerWrapper: {
      marginBottom: theme.spacing(0.5),
    },
    header: {
      paddingTop: theme.spacing(5.5),
      fontWeight: 'bolder',
      textAlign: 'center',
      paddingRight: 40,
      paddingLeft: 40,
      paddingBottom: 20,
    },
    titleWrapper: {
      paddingRight: 40,
      paddingLeft: 40,
      textAlign: 'center',
      margin: theme.spacing(2, 0, 0.5),
    },
    title: {
      fontSize: 14,
      fontWeight: 500,
      color: theme.palette.text.secondary,
      margin: theme.spacing(0, 0, 1),
      display: 'inline-block',
      '&+ $title': {
        marginLeft: theme.spacing(2),
      },
    },
    planDate: {
      fontSize: 12,
      fontWeight: 400,
      color: theme.palette.common.black,
      margin: theme.spacing(1, 0, 0),
    },
    cardContent: {
      paddingTop: 20,
      paddingRight: 40,
      paddingBottom: 20,
      paddingLeft: 40,
      overflowY: 'auto',
      marginBottom: theme.spacing(3),
    },
    date: {
      color: theme.palette.secondary.dark,
      fontSize: 12,
      fontWeight: 400,
    },
    status: {
      marginBottom: theme.spacing(3),
    },
    statusText: {
      color: theme.palette.text.secondary,
      fontSize: 14,
      fontWeight: 400,
      '&:before': {
        content: "' | '",
      },
    },
    statusOk: {
      color: theme.palette.green.main,
    },
    message: {
      marginBottom: theme.spacing(3),
    },
    messageText: {
      color: theme.palette.common.black,
      fontSize: 14,
      fontWeight: 400,
      marginTop: theme.spacing(0.75),
      '&$active': {
        fontWeight: 700,
        color: theme.palette.green.main,
      },
      '&$lost': {
        fontWeight: 700,
        color: theme.palette.attention.main,
      },
      '&$archived': {
        fontWeight: 700,
        color: theme.palette.text.secondary,
      },
    },
    active: {},
    lost: {},
    archived: {},
    actions: {
      display: 'block',
      boxShadow: '3px -3px 15px rgba(56, 60, 97, 0.1)',
      padding: theme.spacing(4, 5),
    },
    input: {
      marginBottom: theme.spacing(2),
    },
    closeButton: {
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
      '& img': {
        maxWidth: '10px',
      },
    },
  })
);

export const QuotaCommentFormPanel = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const lessee = query.get('lessee') ?? '';
  const name = query.get('name') ?? '';
  const quotaId = parseInt(id, 10);
  const { data: notes } = useQuotaNotesQuery(quotaId);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    mode: 'onBlur',
  });

  const { mutateAsync } = useAddQuotaNoteMutation(quotaId, {
    onSuccess: () => setValue('text', ''),
  });

  const onSubmit = useMemo(() => {
    const submit = async (form: any) => {
      try {
        await mutateAsync(form);
      } catch (error) {
        console.log(error);
      }
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync]);

  if (!notes) return null;

  const comments = notes ?? [];

  return (
    <Sidebar url="/calculator" width={440}>
      <Card className={classes.root}>
        <Box className={classes.headerWrapper}>
          <CardHeader className={classes.header} title={`${quotaId}/${name}/${lessee}`} />
        </Box>
        <CardContent className={classes.cardContent}>
          {comments.map((c: QuotaNoteViewModel) => {
            return c.status ? (
              <Status
                createdDate={dayjs(c.createdDate).format('DD.MM.YYYY HH:mm')}
                text={c.text}
                status={c.status}
                statusComment={c.statusComment}
                author={c.author}
                key={c.createdDate}
              />
            ) : (
              <Message
                createdDate={dayjs(c.createdDate).format('DD.MM.YYYY HH:mm')}
                text={c.text}
                author={c.author}
                key={c.createdDate}
              />
            );
          })}
        </CardContent>
        <CardActions className={classes.actions}>
          <form onSubmit={onSubmit}>
            <Input
              label={t('Comment')}
              name={'text'}
              control={control}
              fullWidth
              rules={{
                required: {
                  value: true,
                  message: t('Required'),
                },
              }}
              className={classes.input}
            />
            <Button
              variant={'contained'}
              disabled={isSubmitting}
              type={'submit'}
              //onClick={onSubmit}
            >
              {t('Add')}
            </Button>
          </form>
        </CardActions>
      </Card>
    </Sidebar>
  );
};

const Message = ({ createdDate, text, author }: QuotaNoteViewModel) => {
  const classes = useStyles();

  return (
    <div className={classes.message}>
      <span className={classes.date}>{createdDate}</span>
      <span className={classes.statusText}>{author.name}</span>
      <div className={classes.messageText}>{text}</div>
    </div>
  );
};

const Status = ({ createdDate, text, author, status, statusComment }: QuotaNoteViewModel) => {
  const classes = useStyles();

  return (
    <div className={classes.message}>
      <span className={classes.date}>{createdDate}</span>
      <span className={classes.statusText}>{author.name}</span>
      {status === QuotaStatus.Active ? (
        <div className={clsx(classes.messageText, classes.active)}>Активен</div>
      ) : status === QuotaStatus.Lost ? (
        <div className={clsx(classes.messageText, classes.lost)}>Потерян</div>
      ) : (
        <div className={clsx(classes.messageText, classes.archived)}>Архив</div>
      )}

      {text && status === QuotaStatus.Lost && (
        <div className={classes.messageText}>Причина: {text}</div>
      )}
      {statusComment && <div className={classes.messageText}>Комментарий: {statusComment}</div>}
    </div>
  );
};
