import {
  getBussinessAnswers,
  RatingAnswer,
  RatingCalculationContext,
  RatingInput,
} from 'schema/serverTypes';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { formatNumber } from 'components/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      display: 'grid',
      gridTemplateColumns: '4fr 1fr 1fr 1fr 1fr',
      boxSizing: 'border-box',
      gridGap: 1,
      fontSize: 12,
    },
    th: {
      backgroundColor: theme.palette.secondary.light,
      boxShadow: '0 0 0 1px' + theme.palette.secondary.light,
      padding: theme.spacing(1.5, 1),
      borderTop: '4px solid white',
      marginBottom: -1,
      zIndex: 1,
      '&:first-child ': {
        borderLeft: '4px solid' + theme.palette.common.white,
      },
      '&$last': {
        borderRight: '4px solid' + theme.palette.common.white,
      },
    },
    last: {},
    td: {
      backgroundColor: theme.palette.common.white,
      boxShadow: '0 0 0 1px' + theme.palette.secondary.light,
      padding: theme.spacing(0.75, 1),
    },
    totalLabel: {
      fontWeight: 600,
      gridColumn: '1/5',
    },
    totalValue: {
      fontWeight: 600,
    },
    right: {
      textAlign: 'right',
    },
  })
);

type RatingParamsProps = {
  input?: RatingInput;
  context: RatingCalculationContext;
};

export const RatingBusinessParams = (props: RatingParamsProps) => {
  const classes = useStyles();

  const { input, context } = props;

  return (
    <div className={classes.table}>
      <div className={classes.th}>Бизнес-параметры</div>
      <div className={clsx(classes.th)} />
      <div className={clsx(classes.th, classes.right)}>Оценка показателя</div>
      <div className={clsx(classes.th, classes.right)}>Вес</div>
      <div className={clsx(classes.th, classes.right, classes.last)}>
        Взвешенная оценка значения
      </div>

      {input &&
        getBussinessAnswers(input).map((answer) => {
          return <QuestionItem key={`${answer.questionId}-${answer.value}`} answer={answer} />;
        })}

      <div className={clsx(classes.td, classes.totalLabel)}>
        Общий балл по колличественным показателям
      </div>
      <div className={clsx(classes.td, classes.right, classes.totalValue)}>
        {formatNumber(context.totalQual)}
      </div>
    </div>
  );
};

const QuestionItem = ({ answer }: { answer: RatingAnswer }) => {
  const classes = useStyles();
  const ratingQuestion = [
    {
      id: 'question1',
      label: 'Концентрация клиентов и/или поставщиков',
      answers: ['Высокая', 'Средняя', 'Низкая'],
    },
    {
      id: 'question2',
      label: 'Как прогноз развития отрасли повлияет на компанию?1',
      answers: ['Положительно', 'Нейтрально', 'Отрицательно'],
    },
    {
      id: 'question3',
      label:
        'По сравнению с конкурентами, как бы вы охарактеризовали деятельность компании в своем отраслевом сегменте?',
      answers: [
        'Превосходит конкурентов',
        'На одном уровне с конкурентами',
        'Не знаю',
        'Часто ниже конкурентов',
      ],
    },
    {
      id: 'question4',
      label: 'Как бы вы описали волатильность ключевого отраслевого сегмента клиентов?',
      answers: ['Стабильная', 'Низко волатильная', 'Умеренно волатильная', 'Высоко волатильная'],
    },
    {
      id: 'question5',
      label: 'Как бы вы оценили качество управления?',
      answers: ['Отлично', 'Хорошо', 'Обычный', 'Ниже среднего', 'Плохой', 'Не знаю'],
    },
    {
      id: 'question6',
      label: 'Имеются ли какие-либо предупредительные сигналы?',
      answers: [
        'Не представлены',
        'Постоянное полное использование возобновляемой кредитной линии или частые просрочки/превышение лимита (высокий риск)',
        'Другие финансовые учреждения сокращают, ограничивают (например, устанавливают более строгие условия по краткосрочным кредитам) или прекращают доступность кредита для компании по кредитным причинам.(высокий риск)',
        'Запрос на отсрочку платежа по кредитным причинам (высокий риск)',
        'Неожиданное ухудшение финансовых показателей (высокий риск)',
        'Прочее, а именно ….. (высокий риск)',
        'Внешние сигналы(например, Кредитное агентство или сообщения СМИ) (средний риск)',
        'Частая смена аудиторов (средний риск)',
        'Ожидается существенное расследование регулирующих органов или судебный иск против компании (средний риск)',
        'Запрос на изменение или удаление ковенант (средний риск)',
        'Внезапная смена руководства (средний риск)',
        'По запросу финансовая отчетность была получена с опозданием по сравнению с обычной практикой для клиентского сегмента (средний риск)',
        'Прочее, а именно ….. (средний риск)',
      ],
    },
  ];

  const question = ratingQuestion.find((item) => item.id === answer.questionId);

  return (
    <>
      <div className={classes.td}>{question?.label}</div>
      <div className={clsx(classes.td, classes.right)}>{question?.answers[answer.value]}</div>
      <div className={clsx(classes.td, classes.right)}>{formatNumber(answer.factor)}</div>
      <div className={clsx(classes.td, classes.right)}>{formatNumber(answer.weight)}%</div>
      <div className={clsx(classes.td, classes.right)}>{formatNumber(answer.score)}</div>
    </>
  );
};
