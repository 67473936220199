import { animated, useSpring } from 'react-spring';
import { memo } from 'react';
import { formatMoney } from 'components/utils/formatMoney';

export type AnimatedValueProps = {
  value: number;
  money?: boolean;
};

export const AnimatedValue = memo(({ value: amount, money = false }: AnimatedValueProps) => {
  const style = useSpring({
    from: { transform: `scale(1.3)`, opacity: 0.5 },
    to: { transform: `scale(1)`, opacity: 1 },
    config: {
      duration: 500,
    },
  });

  const value = money ? formatMoney({ amount, fractionDigits: 0 }) : amount;

  return <animated.div style={{ ...style }}>{value}</animated.div>;
});
