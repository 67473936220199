import {
  RatingAnswer1,
  RatingAnswer2,
  RatingAnswer3,
  RatingAnswer4,
  RatingAnswer5,
  RatingAnswer6,
  RatingAnswer7,
  RatingAnswer8,
  RatingAnswer9,
  RatingAnswer10,
  RatingAnswer11,
  RatingAnswer12,
  RatingAnswer13,
  RatingAnswer14,
  RatingAnswer15,
  RatingAnswer16,
  RatingAnswer17,
  RatingAnswer18,
  RatingAnswer,
  RatingQuestionType,
  RatingInputViewModel,
  UserViewModel,
} from 'schema/serverTypes';
import { Control, UseFormSetValue } from 'react-hook-form';

export type RatingInputValues = Omit<RatingInputViewModel, 'answers'> & {
  question1?: RatingAnswer1;
  question2?: RatingAnswer2;
  question3?: RatingAnswer3;
  question4?: RatingAnswer4;
  question5?: RatingAnswer5;
  question6: RatingAnswer6[];
  question7?: RatingAnswer7;
  question8?: RatingAnswer8;
  question9?: RatingAnswer9;
  question10?: RatingAnswer10;
  question11?: RatingAnswer11;
  question12?: RatingAnswer12;
  question13?: RatingAnswer13;
  question14?: RatingAnswer14;
  question15?: RatingAnswer15;
  question16?: RatingAnswer16;
  question18?: RatingAnswer18;
  question17?: RatingAnswer17;
};

const add = (answers: RatingAnswer[], questionId: RatingQuestionType, value?: number | null) => {
  if (value !== undefined && value !== null) {
    answers.push({
      questionId,
      value,
    });
  }
};

const add6 = (answers: RatingAnswer[], values: RatingAnswer6[]) => {
  values.forEach((value) =>
    answers.push({
      questionId: RatingQuestionType.Question6,
      value,
    })
  );
};

export const getRatingInput = (values: RatingInputValues & { quarter: string; year: string }) => {
  const {
    question1,
    question2,
    question3,
    question4,
    question5,
    question6,
    question7,
    question8,
    question9,
    question10,
    question11,
    question12,
    question13,
    question14,
    question15,
    question16,
    question17,
    question18,
    toDate,
    fromDate,
    validFromDate,
    validToDate,
    quarter,
    year,
    ...rest
  } = values;

  const answers: RatingAnswer[] = [];
  add(answers, RatingQuestionType.Question1, question1);
  add(answers, RatingQuestionType.Question2, question2);
  add(answers, RatingQuestionType.Question3, question3);
  add(answers, RatingQuestionType.Question4, question4);
  add(answers, RatingQuestionType.Question5, question5);
  add6(answers, question6);
  add(answers, RatingQuestionType.Question7, question7);
  add(answers, RatingQuestionType.Question8, question8);
  add(answers, RatingQuestionType.Question9, question9);
  add(answers, RatingQuestionType.Question10, question10);
  add(answers, RatingQuestionType.Question11, question11);
  add(answers, RatingQuestionType.Question12, question12);
  add(answers, RatingQuestionType.Question13, question13);
  add(answers, RatingQuestionType.Question14, question14);
  add(answers, RatingQuestionType.Question15, question15);
  add(answers, RatingQuestionType.Question16, question16);
  add(answers, RatingQuestionType.Question17, question17);
  add(answers, RatingQuestionType.Question18, question18);

  //trim all whitespace form numberinput
  const newValues = Object.fromEntries(
    Object.entries(rest).map(([k, v]) => [k, parseInt(v.toString().replaceAll(' ', ''), 10)])
  );

  const input: any = {
    ...newValues,
    quarter,
    year,
    toDate: toDate !== '' ? toDate : undefined,
    fromDate: fromDate !== '' ? fromDate : undefined,
    validFromDate: validFromDate !== '' ? validFromDate : undefined,
    validToDate: validToDate !== '' ? validToDate : undefined,
    answers,
  };
  return input;
};

export type RatingFilterFormValues = {
  userId?: UserViewModel[];
};

export type RatingFilterFormProps = {
  control: Control<RatingFilterFormValues, object>;
  onReset: () => void;
  setValue: UseFormSetValue<RatingFilterFormValues>;
};
