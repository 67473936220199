import { QueryKey } from 'react-query';
import { useBackendQuery, QueryOptions } from '.';
import { IDENTITY_CONFIG } from '../authentication/AuthenticationConfig';
import { calculationUrl } from 'services/urls';

const identityUrl = IDENTITY_CONFIG.authority;

const createUrlFromParts = (relativeUrl?: string, searchParams?: URLSearchParams) => {
  const urlParts = relativeUrl ? relativeUrl.trim().split('/') : [];
  const parts = [identityUrl, 'api', 'v1', 'users', ...urlParts].filter((t) => t !== '');
  const url = parts.join('/');
  return searchParams ? `${url}?${searchParams}` : url;
};

const createUrlFromParts1 = (relativeUrl?: string, searchParams?: URLSearchParams) => {
  const urlParts = relativeUrl ? relativeUrl.trim().split('/') : [];
  const parts = [calculationUrl, 'api', 'v1', 'users', ...urlParts].filter((t) => t !== '');
  const url = parts.join('/');
  return searchParams ? `${url}?${searchParams}` : url;
};

const createOptions = <TResponse, TQueryKey extends QueryKey = QueryKey>(
  options: QueryOptions<TResponse, TQueryKey> | undefined = {}
) => {
  const defaultOptions: QueryOptions<TResponse, TQueryKey> = {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: false,
  };
  return {
    ...defaultOptions,
    ...options,
  } as QueryOptions<TResponse, TQueryKey>;
};

export type UsersBackendQueryOptions<TResponse, TQueryKey extends QueryKey = QueryKey> = {
  relativeUrl?: string;
  searchParams?: URLSearchParams;
  queryKey: TQueryKey;
  options?: QueryOptions<TResponse, TQueryKey>;
};

export const useUsersBackendQuery = <TResponse, TQueryKey extends QueryKey = QueryKey>(
  options: UsersBackendQueryOptions<TResponse, TQueryKey>
) =>
  useBackendQuery(
    createUrlFromParts(options.relativeUrl, options.searchParams),
    options.queryKey,
    createOptions(options.options)
  );

export const useUsersBackendQuery1 = <TResponse, TQueryKey extends QueryKey = QueryKey>(
  options: UsersBackendQueryOptions<TResponse, TQueryKey>
) =>
  useBackendQuery(
    createUrlFromParts1(options.relativeUrl, options.searchParams),
    options.queryKey,
    createOptions(options.options)
  );
