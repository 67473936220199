//@ts-nocheck
import { useParams } from 'react-router-dom';
import { Sidebar } from 'components/Sidebar';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Button } from '../Button';
import { useTranslation } from 'react-i18next';
import { Input } from '../form';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { MissingDebtStatus, UpdateNomenclatureItemRequest } from 'schema/serverTypes';
import { useQueryClient } from 'react-query';
import { useCallback, useMemo } from 'react';
import {
  useMissingCommentsBackendMutation,
  useMissingSetDeliveryNotFoundStatusRequest,
  useMissingSetDeliveryStatusRequest,
} from '../../services/api';
import { Colorize } from 'components';
import { useMissingRequest } from 'services/api';
import { IconSprite } from '../icons';
import { themeOrange as theme } from 'theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '440px',
      minWidth: '440px',
      border: 'none',
      boxShadow: 'none',
      height: '100%',
      display: 'grid',
      gridTemplateRows: 'auto auto 1fr auto',
    },
    headerWrapper: {
      marginBottom: theme.spacing(0.5),
    },
    header: {
      paddingTop: theme.spacing(5.5),
      fontWeight: 'bolder',
      textAlign: 'center',
      paddingRight: 40,
      paddingLeft: 40,
      paddingBottom: 20,
    },
    titleWrapper: {
      paddingRight: 40,
      paddingLeft: 40,
      textAlign: 'center',
      margin: theme.spacing(2, 0, 0.5),
    },
    title: {
      fontSize: 14,
      fontWeight: 500,
      color: theme.palette.text.secondary,
      margin: theme.spacing(0, 0, 1),
    },
    planDate: {
      fontSize: 12,
      fontWeight: 400,
      color: theme.palette.common.black,
      margin: theme.spacing(1, 0, 0),
    },
    cardContent: {
      paddingTop: 20,
      paddingRight: 40,
      paddingBottom: 20,
      paddingLeft: 40,
      overflowY: 'auto',
      marginBottom: theme.spacing(3),
    },
    date: {
      color: theme.palette.secondary.dark,
      fontSize: 12,
      fontWeight: 400,
    },
    status: {
      marginBottom: theme.spacing(3),
    },
    statusText: {
      color: theme.palette.text.secondary,
      fontSize: 14,
      fontWeight: 400,
      '&:before': {
        content: "' | '",
      },
    },
    message: {
      marginBottom: theme.spacing(3),
    },
    messageText: {
      color: theme.palette.common.black,
      fontSize: 14,
      fontWeight: 400,
      marginTop: theme.spacing(0.75),
    },
    analytics: {
      fontSize: 10,
      color: theme.palette.secondary.dark,
      marginTop: theme.spacing(2),
      textAlign: 'left',
    },

    actions: {
      display: 'block',
      boxShadow: '3px -3px 15px rgba(56, 60, 97, 0.1)',
      padding: theme.spacing(4, 5),
    },
    input: {
      marginBottom: theme.spacing(2),
    },
    closeButton: {
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
      '& img': {
        maxWidth: '10px',
      },
    },
    actionButtons: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    star: {
      marginRight: 10,
    },
  })
);

export const MissingCommentFormPanel = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { id: missingId } = useParams<{ id: string }>();
  const { data: missing, isLoading } = useMissingRequest(missingId);
  const comments = missing?.comments ?? [];

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    mode: 'onBlur',
  });

  const queryClient = useQueryClient();

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({
      predicate: (query) => {
        return (
          typeof query.queryKey === 'string' && (query.queryKey as string).startsWith('Missings')
        );
      },
    });
  }, [queryClient]);

  const { mutateAsync } = useMissingCommentsBackendMutation(`${missingId}/comments`, {
    method: 'POST',
    onSuccess: (_result) => {
      setValue('text', '');
      invalidateQuery();
    },
  });

  const onSubmit = useMemo(() => {
    const submit = async (form: UpdateNomenclatureItemRequest) => {
      try {
        await mutateAsync(form);
      } catch (error) {
        console.log(error);
      }
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync]);

  const debtDays = dayjs().diff(dayjs(missing?.plannedDeliveryDate), 'day');

  const { mutateAsync: mutateAsyncDelivered } = useMissingSetDeliveryStatusRequest(
    parseInt(missingId, 10),
    invalidateQuery
  );
  const { mutateAsync: mutateAsyncNotFound } = useMissingSetDeliveryNotFoundStatusRequest(
    parseInt(missingId, 10),
    invalidateQuery
  );

  return (
    <Sidebar url="/missing" width={440}>
      <Card className={classes.root}>
        <Box className={classes.headerWrapper}>
          <CardHeader className={classes.header} title={`${missing?.documentType}`} />
        </Box>
        <Box className={classes.titleWrapper}>
          <h3 className={classes.title}>{missing?.lesseeName}</h3>
          <h3 className={classes.title}>{missing?.contractNumber}</h3>

          <Box className={classes.planDate}>
            {t('Plan date')} {dayjs(missing?.plannedDeliveryDate).format('DD.MM.YYYY')}
            {missing?.missingDebtStatus === MissingDebtStatus.DaysCalculation ? (
              <>
                {' '}
                | <Colorize value={debtDays} unit="дн" />
              </>
            ) : (
              ''
            )}
          </Box>
          {missing?.analytics && (
            <Box className={classes.analytics}>
              <div>{t('Analytics')}</div>
              <div className={classes.messageText}>{missing?.analytics}</div>
            </Box>
          )}
        </Box>
        <CardContent className={classes.cardContent}>
          {comments.map((c) => {
            return c.author ? (
              <Message
                date={dayjs(c.createdDate).format('DD.MM.YYYY HH:mm')}
                text={c.text}
                author={c.author.name}
                key={c.id}
                deliveryStatus={c.deliveryStatus}
              />
            ) : (
              <Status key={c.id} date={dayjs(c.statusDate).format('DD.MM.YYYY')} text={c.text} />
            );
          })}
        </CardContent>
        <CardActions className={classes.actions}>
          <form onSubmit={onSubmit}>
            <Input
              label={t('Comment')}
              name={'text'}
              control={control}
              fullWidth
              rules={{
                required: {
                  value: true,
                  message: t('Required'),
                },
              }}
              className={classes.input}
            />
            <div className={classes.actionButtons}>
              <Button variant={'contained'} disabled={isSubmitting || isLoading} type={'submit'}>
                {t('Add')}
              </Button>
              {missing?.canBeDelivered ? (
                <Button
                  variant={'contained'}
                  disabled={isSubmitting || isLoading}
                  type={'button'}
                  onClick={() => mutateAsyncDelivered(null)}
                >
                  {t('Delivered to the office')}
                </Button>
              ) : null}
              {missing?.canBeNotFound ? (
                <Button
                  variant={'contained'}
                  disabled={isSubmitting || isLoading}
                  type={'button'}
                  onClick={() => mutateAsyncNotFound(null)}
                >
                  {t('Document not found')}
                </Button>
              ) : null}
            </div>
          </form>
        </CardActions>
      </Card>
    </Sidebar>
  );
};

const Status = ({ date, text }: { date: string; text: string }) => {
  const classes = useStyles();

  return (
    <div className={classes.status}>
      <span className={classes.date}>{date}</span>
      <span className={classes.statusText}>{text}</span>
    </div>
  );
};

const Message = ({
  date,
  text,
  author,
  deliveryStatus,
}: {
  date: string;
  text: string;
  author?: string;
  deliveryStatus?: 'notFound' | 'delivered';
}) => {
  const classes = useStyles();

  return (
    <div className={classes.message}>
      <span className={classes.date}>{date}</span>
      <span className={classes.statusText}>{author}</span>
      <div className={classes.messageText}>
        {deliveryStatus === 'delivered' ? (
          <>
            <IconSprite
              width="10px"
              color={theme.palette.attention.main}
              icon="star"
              className={classes.star}
            />
            {text}
          </>
        ) : (
          text
        )}
      </div>
    </div>
  );
};
