import {
  UpdateShipmentItemsExpectedDateRequest,
  ShipmentViewModel,
  UpdateShipmentItemsShipmentDateRequest,
  ShipShipmentItemsRequest,
  ReportShipmentItemsRequest,
  OrderShipmentTelematicsRequest,
} from 'schema/serverTypes';
import { MutationOptions, useBackendMutation } from '.';
import { calculationUrl } from 'services/urls';

const createUrl = (quotaId: number, relativeUrl: string) => {
  return `${calculationUrl}/api/v1/quotas/${quotaId}/shipment/items/${relativeUrl}`;
};

export const useShipmentItemsBackendMutation = <TRequest, TResponse, TContext = unknown>(
  quotaId: number,
  relativeUrl: string,
  options: MutationOptions<TRequest, TResponse, TContext> | undefined
) => useBackendMutation(createUrl(quotaId, relativeUrl), options);

export const useSetExpectedShipmentDateMutation = (
  quotaId: number,
  options: MutationOptions<any, any>
) =>
  useShipmentItemsBackendMutation<UpdateShipmentItemsExpectedDateRequest, ShipmentViewModel>(
    quotaId,
    'expectedDate',
    {
      method: 'PUT',
      ...options,
    }
  );

export const useSetShipmentDateMutation = (quotaId: number) =>
  useShipmentItemsBackendMutation<UpdateShipmentItemsShipmentDateRequest, ShipmentViewModel>(
    quotaId,
    'shipmentDate',
    {
      method: 'PUT',
    }
  );

export const useShipItemsMutation = (quotaId: number, options: MutationOptions<any, any>) =>
  useShipmentItemsBackendMutation<ShipShipmentItemsRequest, ShipmentViewModel>(quotaId, 'ship', {
    method: 'PUT',
    ...options,
  });

export const useReportItemsMutation = (quotaId: number, options: MutationOptions<any, any>) =>
  useShipmentItemsBackendMutation<ReportShipmentItemsRequest, ShipmentViewModel>(
    quotaId,
    'report',
    {
      method: 'PUT',
      ...options,
    }
  );

export const useOrderTelematicsMutation = (quotaId: number, options: MutationOptions<any, any>) =>
  useShipmentItemsBackendMutation<OrderShipmentTelematicsRequest, ShipmentViewModel>(
    quotaId,
    'telematics',
    {
      method: 'PUT',
      ...options,
    }
  );
