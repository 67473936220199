import { createStyles, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      height: 36,
      borderRadius: 0,
      padding: theme.spacing(0, 2.5),
    },
  })
);

export const ClosedItem = ({ title }: { title: string }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Typography variant="subtitle1">{title}</Typography>
    </Paper>
  );
};
