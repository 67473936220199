import { useCallback } from 'react';
import { useWatch, Control, UseFormSetValue } from 'react-hook-form';
import { RatingChip } from './RatingChip';
import { RatingFilterFormValues } from './types';

export type RatingChipsProps = {
  control: Control<RatingFilterFormValues, object>;
  setValue: UseFormSetValue<RatingFilterFormValues>;
};

export const RatingChips = (props: RatingChipsProps) => {
  const { control, setValue } = props;

  const userIds = useWatch({ control, name: 'userId' });

  const onUserIdRemove = useCallback(
    (id: string) => {
      const users = userIds?.filter((v) => v.id !== id);
      setValue('userId', users);
    },
    [userIds, setValue]
  );

  const userChips = userIds?.map((value) => {
    const { id, name } = value;
    return <RatingChip label={name} id={id} key={`userId-${id}`} onRemove={onUserIdRemove} />;
  });

  return <>{userChips}</>;
};
