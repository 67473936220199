import { useMemo } from 'react';
import { createUrlFromParts } from './types';
import { useBackendQuery } from './useBackend';
import { LeasingProductItemViewModel } from 'schema/serverTypes';

export type UseLeasingProductBrandsQueryProps = {
  search?: string;
};

export type useLeasingProductByBrandQueryProps = {
  brand?: string;
};

const entity = 'dictionaries/leasingProducts/brands';

export const useLeasingProductBrandsQuery = (props: UseLeasingProductBrandsQueryProps) => {
  const { search = '' } = props;

  const searchParams = useMemo(() => {
    const searchParams = new URLSearchParams();
    if (search !== '') {
      searchParams.set('search', search);
    }
    return searchParams;
  }, [search]);

  const url = createUrlFromParts(entity, undefined, searchParams);

  return useBackendQuery<string[]>(url, url);
};

export const useLeasingProductByBrandQuery = (props: useLeasingProductByBrandQueryProps) => {
  const { brand = '' } = props;

  const searchParams = useMemo(() => {
    const searchParams = new URLSearchParams();
    if (brand !== '') {
      searchParams.set('brand', brand);
    }
    return searchParams;
  }, [brand]);

  const url = createUrlFromParts(entity, 'searchLeasingProduct', searchParams);

  return useBackendQuery<LeasingProductItemViewModel>(url, url);
};
