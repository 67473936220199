import { useCallback } from 'react';
import { useWatch, Control, UseFormSetValue } from 'react-hook-form';
import { ExternalQuotaFilter } from '../../schema';
import { MissingChip } from '../missing';
import { IssueChip } from '../tasks/IssueChip';

export type MissingChipsProps = {
  control: Control<ExternalQuotaFilter, object>;
  setValue: UseFormSetValue<ExternalQuotaFilter>;
};

export const RequestsChips = (props: MissingChipsProps) => {
  const { control, setValue } = props;

  const region = useWatch({ control, name: 'region' });
  const onRegionRemove = useCallback(
    (label: string) => {
      const regions = region?.filter((v) => v !== label);
      setValue('region', regions);
    },
    [region, setValue]
  );
  const regionChips = region?.map((value) => {
    return <MissingChip label={value} key={`region-${value}`} onRemove={onRegionRemove} />;
  });

  const userId = useWatch({ control, name: 'userId' });
  const onUserRemove = useCallback(
    (id: string) => {
      const userIds = userId?.filter((v) => v.id !== id);
      setValue('userId', userIds);
    },
    [userId, setValue]
  );
  const userIdChips = userId?.map((value) => {
    return (
      <IssueChip
        label={value.name}
        id={value.id}
        key={`author-${value.id}`}
        onRemove={onUserRemove}
      />
    );
  });

  return (
    <>
      {regionChips}
      {userIdChips}
    </>
  );
};
