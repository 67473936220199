import { Select, MenuItem } from '@material-ui/core';
import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { IconSprite, usePagingStyles } from 'components';

export const SelectPagination = () => {
  const classes = usePagingStyles();
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const [value, setValue] = useState<number>(20);
  const num = searchParams.get('pageSize') ?? value;

  const handleChange = useCallback(
    (e) => {
      history.push(`${location.pathname}?pageSize=${e.target.value}`);
      setValue(e.target.value);
    },
    [history, location.pathname]
  );

  return (
    <Select
      value={num}
      onChange={handleChange}
      className={classes.select}
      IconComponent={() => <IconSprite className={classes.icon} icon="dropdown" width="8px" />}
      MenuProps={{
        classes: {
          paper: classes?.paper,
          list: classes?.list,
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
    >
      <MenuItem value={20}> 20 </MenuItem>
      <MenuItem value={50}> 50 </MenuItem>
      <MenuItem value={100}> 100 </MenuItem>
    </Select>
  );
};
