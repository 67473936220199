import { Route, Switch } from 'react-router-dom';
import { Task, IssueList, IssueListError, useErrorContext } from 'components/tasks';
import { Critical } from 'components';
import { IssueSource } from 'schema/serverTypes';
import { useTranslation } from 'react-i18next';

export const TasksPage = () => {
  const { t } = useTranslation();

  useErrorContext({
    title: t('Task_plural'),
    source: IssueSource.issues,
  });

  return (
    <>
      <Critical />
      <Switch>
        <Route path="/tasks" exact component={IssueList} />
        <Route path="/tasks/:id" exact component={Task} />
        <Route path="/puls" exact component={IssueListError} />
        <Route path="/puls/:id" exact component={Task} />
      </Switch>
    </>
  );
};
