import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Input, useToast } from 'components';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { useQueryClient } from 'react-query';
import { useRequestCounterpartyRatingRiskMutation } from 'services';
import { QuotaCounterpartyInnViewModel } from 'schema';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(3),
    },
    label: {
      color: theme.palette.common.black,
      marginRight: theme.spacing(1.5),
    },
    control: {
      width: '100%',
    },
    field: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(4),
    },
    actions: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    actionButton: {
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
    button: {},
  })
);

type IssueContractIncidentFormProps = {
  quotaId: number;
  inn: string;
  onClose: () => void;
};

export const RequestRatingRiskForm = ({
  inn,
  onClose,
  quotaId,
}: IssueContractIncidentFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync } = useRequestCounterpartyRatingRiskMutation(quotaId, {
    onSuccess: () => {
      toast(t('SuccessMessage'), 'success');
      queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey.indexOf('counterparties') > -1;
        },
      });
      onClose();
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
      onClose();
    },
  });

  const { handleSubmit, control } = useForm<QuotaCounterpartyInnViewModel>({
    mode: 'onBlur',
    defaultValues: {
      inn: inn,
    },
  });

  const onSubmit = useMemo(() => {
    const submit = async (form: QuotaCounterpartyInnViewModel) => {
      await mutateAsync(form);
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync]);

  return (
    <div className={classes.root}>
      <Typography variant={'h2'} className={classes.title}>
        Все необходимые документы для проведения оценки предоставлены?
      </Typography>
      <form onSubmit={onSubmit}>
        <div className={classes.field}>
          <div className={classes.control}>
            <Input
              label="Путь к файлам контрагента"
              name="documentUrl"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: '',
                },
              }}
            />
          </div>
        </div>
        <div className={classes.actions}>
          <Button variant="contained" className={classes.actionButton} type={'submit'}>
            {t('Send')}
          </Button>
          <Button variant="outlined" className={classes.actionButton} onClick={onClose}>
            {t('Cancel2')}
          </Button>
        </div>
      </form>
    </div>
  );
};
