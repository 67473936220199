import { useCallback } from 'react';
import {
  createStyles,
  Theme,
  makeStyles,
  InputAdornment,
  Select,
  MenuItem,
} from '@material-ui/core';
import { AmountType } from 'schema/serverTypes';
import { formatCurrency } from 'components/utils';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import { AmountTypeFields } from './types';
import { useCalculationFormContext } from '../types';
import { useController, useWatch } from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      minWidth: '52px',
      height: '30px',
      border: '3px solid transparent',
      backgroundColor: theme.palette.secondary.light,
      position: 'absolute',
      right: 3,
      borderRadius: 2,
      '& .MuiSelect-root': {
        paddingLeft: theme.spacing(1),
        height: '100%',
        padding: '0',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
      },
      '& .MuiSelect-icon': {
        color: theme.palette.text.secondary,
        pointerEvents: 'none',
      },
    },
    paper: {
      borderRadius: 0,
      boxShadow: '0px 5px 10px 0px ' + theme.palette.grey5.main,
      border: '1px solid ' + theme.palette.secondary.dark,
      maxHeight: 185,
    },
    list: {
      paddingTop: 0,
      paddingBottom: 0,
      '& .MuiListItem-button': {
        fontFamily: 'Roboto, Helvetica, Arial, san-serif',
        paddingLeft: theme.spacing(1),
        '&:hover': {
          backgroundColor: theme.palette.secondary.light,
        },
      },
      '& .MuiListItem-button.Mui-selected': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
  })
);

export type AmountTypeButtonProps<T extends AmountTypeFields> = {
  name: T;
  disabled?: boolean;
  useSaleCurrency?: boolean;
  resetValue: () => void;
};

export function AmountTypeButton<T extends AmountTypeFields>(props: AmountTypeButtonProps<T>) {
  const classes = useStyles();

  const { disabled, name, useSaleCurrency = false, resetValue } = props;
  const { control } = useCalculationFormContext();
  const {
    field: { value, onChange },
  } = useController({ control, name });
  const amountType = (value as AmountType | undefined) ?? AmountType.Percents;

  const resetAmountValue = useCallback(() => {
    resetValue();
  }, [resetValue]);

  const handleClickChangeAmountType = useCallback(() => {
    onChange(amountType === AmountType.Percents ? AmountType.Money : AmountType.Percents);
    resetAmountValue();
  }, [amountType, onChange, resetAmountValue]);

  const currencyLease = useWatch({
    control,
    name: 'currencyLease',
  });

  const currencySale = useWatch({
    control,
    name: 'currencySale',
  });

  const label = formatCurrency(useSaleCurrency ? currencySale : currencyLease);
  const selectValue = amountType === AmountType.Percents ? AmountType.Percents : AmountType.Money;

  return (
    <>
      <InputAdornment position="start">
        <Select
          className={classes?.select}
          disableUnderline={true}
          disabled={disabled}
          onChange={handleClickChangeAmountType}
          value={selectValue}
          MenuProps={{
            classes: {
              paper: classes?.paper,
              list: classes?.list,
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          IconComponent={KeyboardArrowDownRoundedIcon}
        >
          <MenuItem value={AmountType.Percents}>%</MenuItem>
          <MenuItem value={AmountType.Money}>{label}</MenuItem>
        </Select>
      </InputAdornment>
    </>
  );
}
