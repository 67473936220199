import { DatePicker } from 'components';
import { useCalculationFormContext } from '../types';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { CalculationMethod } from 'schema/serverTypes';
import dayjs from 'dayjs';

export type SeasonalPaymentOptionsDatePickerProps = {
  calculationMethod: CalculationMethod;
  numberOfMonths: number;
  date?: string;
};

export const SeasonalPaymentOptionsDatePicker = (props: SeasonalPaymentOptionsDatePickerProps) => {
  const { calculationMethod, date, numberOfMonths } = props;

  const { control } = useCalculationFormContext();

  const { t } = useTranslation();
  const invalidLastPaymentMessage = t('Last billing month');

  const { minDate, maxDate } = useMemo(() => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const actualDate =
      date !== undefined && date !== '' ? new Date(date) : new Date(year, month, 1);
    const minDate = actualDate < now ? actualDate : now;
    const maxDate = new Date(minDate.getFullYear() + 1, minDate.getMonth(), minDate.getDate());
    return {
      minDate,
      maxDate,
    };
  }, [date]);

  const lastPayment = useCallback(
    (value: string | undefined) => {
      if (calculationMethod === CalculationMethod.Seasonal) {
        return undefined;
      }

      const startMonth = dayjs(value).month();
      if (value === undefined || value === null) {
        return invalidLastPaymentMessage;
      }
      const lastPaymentMonth = (startMonth + numberOfMonths) % 12;
      if (typeof value === 'string' && lastPaymentMonth < 8) {
        dayjs.locale('ru');
        return (
          invalidLastPaymentMessage + ' ' + dayjs(dayjs().month(lastPaymentMonth)).format('MMMM')
        );
      }
    },
    [invalidLastPaymentMessage, numberOfMonths, calculationMethod]
  );

  return (
    <DatePicker
      control={control}
      name="seasonalPaymentOptions.date"
      label={t('StartDate')}
      minDate={minDate}
      maxDate={maxDate}
      views={['year', 'month']}
      format="MMMM.yyyy"
      clearable={false}
      rules={{
        validate: {
          lastPayment,
        },
      }}
    />
  );
};
