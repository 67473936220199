import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableCell, TableHead, TableRow, TableBody, Typography } from '@material-ui/core';
import { WikiTagSortBy, WikiTagViewModel } from 'schema/serverTypes';
import { EditButton } from '../EditButton';
import { useTableStyles } from '../useTableStyles';
import { DeleteButton } from '../DeleteButton';
import { useDeleteWikiTagMutation } from '../../../services/api/useWikiBackend';
import { useToast } from '../../Toast';
import { useQueryClient } from 'react-query';
import { ModalForm, useModalForm } from '../../Modal';
import { Button } from '../../Button';
import { TableSortLabel } from 'components/data-grid/TableSortLabel';
import { WikiTagsSorting } from './useWikiTagsData';

export type WikiTagsTableProps = {
  rows: WikiTagViewModel[];
  loading: boolean;
  onOpen: (item: WikiTagViewModel) => void;
  sorting: WikiTagsSorting;
};

type TableItemProps = {
  item: WikiTagViewModel;
  onOpen: (item: WikiTagViewModel) => void;
};

const TableItem = (props: TableItemProps) => {
  const classes = useTableStyles();
  const { item, onOpen } = props;
  const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { onOpen: onDeleteOpen, onClose, open } = useModalForm();

  const handleOpen = useCallback(() => {
    onOpen(item);
  }, [item, onOpen]);

  const handleDeleteOpen = useCallback(() => {
    onDeleteOpen();
  }, [onDeleteOpen]);

  const { mutateAsync: deleteAsync } = useDeleteWikiTagMutation(item.id, {
    onSuccess: () => {
      toast(t('WikiTagSuccessMessage'), 'success');
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            typeof query.queryKey === 'string' && (query.queryKey as string).indexOf('wiki') >= 0
          );
        },
      });
      onClose();
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const handleDelete = useCallback(() => {
    deleteAsync(null);
  }, [deleteAsync]);

  return (
    <>
      <TableRow>
        <TableCell size="medium">{item.text}</TableCell>
        <TableCell size="medium" className={classes.actions}>
          <EditButton onClick={handleOpen} />
          <DeleteButton onClick={handleDeleteOpen} />
        </TableCell>
      </TableRow>
      <ModalForm open={open} onClose={onClose}>
        <div className={classes.modalRoot}>
          <Typography variant={'h2'} className={classes.modalTitle}>
            {t('Delete Tag')}
          </Typography>
          <Typography variant="subtitle2" className={classes.modalText}>
            {`${t('Are you sure you want to delete the hashtag')} ${item.text}`}
          </Typography>
          <div>
            <Button variant="contained" className={classes.modalButton} onClick={handleDelete}>
              {t('Yes')}
            </Button>
            <Button variant="outlined" onClick={onClose} className={classes.modalButton}>
              {t('No')}
            </Button>
          </div>
        </div>
      </ModalForm>
    </>
  );
};

export const WikiTagsTable = (props: WikiTagsTableProps) => {
  const classes = useTableStyles();
  const { rows, onOpen, sorting } = props;
  const { t } = useTranslation();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell size="medium">
            <TableSortLabel columnName={WikiTagSortBy.Text} sorting={sorting}>
              {t('Name')}
            </TableSortLabel>
          </TableCell>
          <TableCell className={classes.change} size="medium"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((item: WikiTagViewModel, index: number) => (
          <TableItem key={index} item={item} onOpen={onOpen} />
        ))}
      </TableBody>
    </Table>
  );
};
