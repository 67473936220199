import { Control } from 'react-hook-form';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid } from 'components';
import FormControl from '@material-ui/core/FormControl';
import { IconSprite, Input, Button } from 'components';
import { useTranslation } from 'react-i18next';
import { themeOrange as theme } from 'theme';
import { TagSearchFormValues } from './useTagSearchForm';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '100%',
      padding: theme.spacing(2.5),
      backgroundColor: theme.palette.lightBlue3.main,
    },
    buttonReset: {
      display: 'flex',
      alignItems: 'flex-end',
      marginBottom: theme.spacing(0.2),
    },
    title: {
      alignSelf: 'center',
    },
    righ: {
      justifySelf: 'flex-end',
    },
  })
);

export type TagSearchFormProps = {
  control: Control<TagSearchFormValues, object>;
  onReset: () => void;
};

const downloadHandler = () => {};

export const TagSearchForm = (props: TagSearchFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { control, onReset } = props;

  return (
    <div>
      <form>
        <Grid container columnSpacing={2} rowSpacing={2.5} className={classes.root}>
          <Grid item xs={20} className={classes.title}>
            <Typography variant="subtitle1">{t('Bookmark reference')}</Typography>
          </Grid>
          <Grid item xs={4} className={classes.righ}>
            <div onClick={downloadHandler}>
              <Button
                variant="iconButton"
                endIcon={
                  <IconSprite
                    width="16px"
                    height="15px"
                    color={theme.palette.text.primary}
                    icon="download2"
                  />
                }
              />
            </div>
          </Grid>
          <Grid xs={21} item>
            <Input
              label={`${t('Search')}`}
              control={control}
              name="search"
              variant="standard"
              InputProps={{
                endAdornment: (
                  <IconSprite width="16px" color={theme.palette.text.primary} icon="search" />
                ),
              }}
            />
          </Grid>
          <Grid xs={3} item className={classes.buttonReset}>
            <FormControl>
              <Button variant="text" onClick={onReset}>
                {t('Reset')}
              </Button>
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
