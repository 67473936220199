import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { QuotaCounterpartyViewModel, RatingRiskType } from 'schema';
import { Button } from '../../../Button';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { alpha } from '@material-ui/core/styles';
import { ModalForm, useModalForm } from '../../../Modal';
import { RequestRatingRiskForm } from './RequestRatingRiskForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      display: 'grid',
      gridTemplateColumns: '50% 1fr 1fr',
      gridGap: theme.spacing(0, 2),
      fontSize: 14,
      fontWeight: 600,
      padding: theme.spacing(0),
      color: theme.palette.text.primary,
      alignItems: 'center',
      '&$high': {
        backgroundColor: alpha(theme.palette.error.main, 0.08),
      },
    },
    name: {
      padding: theme.spacing(2, 0, 2, 2),
      lineHeight: '20px',
      color: theme.palette.text.primary,
      borderLeft: `4px solid ${theme.palette.text.secondary}`,
      '&$high': {
        borderLeft: `4px solid ${theme.palette.error.main}`,
        color: theme.palette.text.primary,
      },
      '&$medium': {
        borderLeft: `4px solid ${theme.palette.attention.main}`,
        color: theme.palette.attention.main,
      },
      '&$low': {
        borderLeft: `4px solid ${theme.palette.success.main}`,
        color: theme.palette.text.primary,
      },
    },
    link: {
      color: theme.palette.text.primary,
    },
    risk: {
      fontSize: 12,
      fontWeight: 500,
    },
    high: {
      color: theme.palette.error.main,
    },
    unacceptable: {
      color: theme.palette.error.main,
    },
    medium: {
      color: theme.palette.attention.main,
    },
    low: {
      color: theme.palette.success.main,
    },
    none: {},
    subTitle: {
      fontSize: 12,
      color: theme.palette.text.secondary,
    },
    action: {
      textAlign: 'right',
      paddingRight: theme.spacing(2.5),
    },
  })
);

type RisksProps = {
  risks: QuotaCounterpartyViewModel[];
  quotaId: number;
};

export const Risks = (props: RisksProps) => {
  const { risks, quotaId } = props;
  return (
    <div>
      {risks.map((risk) => (
        <Risk {...risk} quotaId={quotaId} key={risk.inn} />
      ))}
    </div>
  );
};

export const Risk = (props: QuotaCounterpartyViewModel & { quotaId: number }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { inn, name, canRequestRatingRisk, counterpartyType, totalRisk, quotaId, ratingRiskIssue } =
    props;
  const { onOpen, onClose, open } = useModalForm();

  return (
    <>
      <div className={clsx(classes.row, classes[totalRisk])}>
        <div className={clsx(classes.name, classes[totalRisk])}>
          <Link to={`/counterparties/${inn}`} className={classes.link}>
            {name}{' '}
            <Typography variant={'body2'} component={'span'} className={classes.subTitle}>
              ({inn})
            </Typography>
          </Link>
          <br />
          <Typography variant={'body2'} className={classes.subTitle}>
            {counterpartyType === 'lessee' ? t('Lessee') : t('Dealer')}
          </Typography>
        </div>
        <div>
          <Typography variant={'body2'} component={'span'} className={classes.subTitle}>
            Уровень риска:
          </Typography>
          <Typography variant={'body1'} component={'span'} className={classes.risk}>
            <RatingTypeColored ratingType={totalRisk} />
          </Typography>
        </div>
        <div className={classes.action}>
          {canRequestRatingRisk ? (
            <Button variant="contained" onClick={onOpen}>
              Запросить оценку
            </Button>
          ) : null}
          {ratingRiskIssue && ratingRiskIssue.isActive && (
            <Button variant="contained" disabled={true}>
              Обрабатывается
            </Button>
          )}
        </div>
      </div>
      <ModalForm open={open} onClose={onClose} width={500}>
        <RequestRatingRiskForm inn={inn} quotaId={quotaId} onClose={onClose} />
      </ModalForm>
    </>
  );
};

export const RatingTypeColored = ({ ratingType }: { ratingType: RatingRiskType }) => {
  const classes = useStyles();
  return ratingType === RatingRiskType.none ? (
    <span className={classes.risk}> —</span>
  ) : ratingType === RatingRiskType.low ? (
    <span className={classes.low}> Низкий</span>
  ) : ratingType === RatingRiskType.medium ? (
    <span className={classes.medium}> Средний</span>
  ) : ratingType === RatingRiskType.high ? (
    <span className={classes.high}> Высокий</span>
  ) : ratingType === RatingRiskType.unacceptable ? (
    <span className={classes.high}> Неприемлимый</span>
  ) : (
    <span />
  );
};
