import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import sprite from '../../img/svg-sprite.svg';
import clsx from 'clsx';
import { useState } from 'react';
import { animated, useSpring, useSpringRef } from 'react-spring';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      marginRight: theme.spacing(-3),
      backgroundColor: 'white',
      boxShadow: '0px 0px 5px rgba(56, 60, 97, 0.1)',
      minHeight: 40,
      marginBottom: theme.spacing(1),
    },
    title: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      color: theme.palette.common.black,
      fontWeight: 600,
      fontSize: 12,
      padding: theme.spacing(1.5, 2.5),
    },
    button: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: theme.spacing(1.25),
      right: theme.spacing(1.25),
      width: 20,
      height: 20,
      border: '1px solid #D9E7FF',
      boxSizing: 'border-box',
      borderRadius: 3,
      cursor: 'pointer',
      '&$open': {
        border: '1px solid #FFFFFF',
        '& > $cross': {
          transform: 'rotate(0)',
        },
      },
      '& > $cross': {
        width: 8,
        height: 8,
        fill: theme.palette.text.primary,
        transform: 'rotate(-225deg)',
      },
    },
    cross: {
      width: 8,
      height: 8,
      fill: theme.palette.text.primary,
      transition: '0.8s',
    },
    content: {
      display: 'none',
      padding: theme.spacing(6, 2.5, 2.5),
      '&$open': {
        display: 'block',
      },
    },
    open: {},
  })
);

type PlusProps = {
  children?: React.ReactNode;
};

export const Plus = ({ children }: PlusProps) => {
  const classes = useStyles();
  const [isOpen, setOpen] = useState<boolean>(false);
  const buttonClass = isOpen ? clsx(classes.button, classes.open) : classes.button;
  const contentClass = isOpen ? clsx(classes.content, classes.open) : classes.content;

  const springRef = useSpringRef();
  const { x } = useSpring({
    ref: springRef,
    x: isOpen ? '100%' : '0%',
    from: {
      x: '0%',
    },
  });

  const onToggle = () => {
    setOpen((prev) => !prev);
  };

  return (
    <div className={classes.root}>
      <animated.div className={classes.title} style={{ x }}>
        Заголовок ползающий
      </animated.div>
      <div className={buttonClass} onClick={onToggle}>
        <svg className={classes.cross}>
          <use xlinkHref={sprite + '#cross-small'} />
        </svg>
      </div>
      <div className={contentClass}>{children}</div>
    </div>
  );
};
