import { useDebounce } from 'use-debounce';
import { CounterpartyOption, CounterpartyType } from 'schema';
import { useDictionaryBackendQuery } from 'services';

const Counterparties = 'counterparties';

export const useLessorsQuery = (inputValue?: string) => {
  const [input] = useDebounce(inputValue, 500);

  var requestUrl = Counterparties;
  const searchParams = new URLSearchParams();

  searchParams.set('type', CounterpartyType.lessor);

  if (input) {
    searchParams.set('name', input);
  }
  requestUrl += `?${searchParams}`;

  const { data: options = [], refetch } =
    useDictionaryBackendQuery<CounterpartyOption[]>(requestUrl);

  return {
    options,
    refetch,
  };
};
