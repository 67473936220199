import { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { TableCell, TableRow } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { UserShipmentViewModel } from 'schema/serverTypes';
import { formatNumber } from 'components/utils';
import { UserViewModel } from '../../../users/types';
import { themeOrange as theme } from 'theme';
import { useStyles } from './useStyles';
import clsx from 'clsx';

type PipelineItemProps = UserShipmentViewModel & {
  max: number;
};

export const PipelineItem = (props: PipelineItemProps) => {
  const classes = useStyles(props);
  const { id, name, isCurrent = false, max, amount, count, margin } = props;

  const color = isCurrent ? theme.palette.secondary.main : theme.palette.secondary.light;

  const [proc, setProc] = useState<number>(0);
  const anim = useSpring({
    background: `linear-gradient(to right, ${color} ${proc}%, ${theme.palette.background.default} 0)`,
    config: { duration: 500 },
  });

  useEffect(() => {
    const value = max > 0 ? (100 * amount) / max : 0;
    setProc(value);
  }, [amount, max]);

  const { push } = useHistory();

  const quotaLinkHandler = (ev: React.MouseEvent<HTMLDivElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    const usersFromSession: UserViewModel[] = sessionStorage.quotaFilterUsers
      ? JSON.parse(sessionStorage.quotaFilterUsers)
      : [];
    sessionStorage.setItem('quotaFilterUsers', JSON.stringify([...usersFromSession, { id, name }]));
    push(`/quotas?ownerId=${id}&hasExpectedShipmentDate=true#all`);
  };

  return (
    <TableRow className={classes.root}>
      <TableCell className={classes.name}>
        <animated.div style={anim} className={classes.fill}>
          <div onClick={quotaLinkHandler} className={classes.link}>
            {name}
          </div>
        </animated.div>
      </TableCell>
      <TableCell align="right" className={classes.shipment}>
        <div onClick={quotaLinkHandler} className={classes.link}>
          {count}
        </div>
      </TableCell>
      <TableCell align="right" className={classes.value}>
        {formatNumber(margin)}%
      </TableCell>
      <TableCell align="right" className={clsx(classes.amount, classes.value)}>
        {formatNumber(amount)}
      </TableCell>
    </TableRow>
  );
};
