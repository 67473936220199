import { makeStyles, createStyles, Theme, Paper } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { Button } from 'components';
import { GeneralInformationIndividualFields } from './GeneralInformationIndividualFields';
import { useCounterpartyIndividualCreateForm } from './useCounterpartyIndividualCreateForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    panels: {
      marginBottom: '75px',
    },
    actions: {
      [theme.breakpoints.down(1200)]: {
        width: 'calc(100% - 85px)',
      },
      display: 'flex',
      justifyContent: 'flex-start',
      width: 'calc(100% - 238px)',
      padding: theme.spacing('20px', '20px'),
      bottom: 0,
      position: 'fixed',
      zIndex: 1,
    },
    actionButton: {
      marginRight: theme.spacing(2.5),
    },
  })
);

export const CounterpartyIndividualCreateForm = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { onSubmit, control, setValue, clearErrors, reset, ...rest } =
    useCounterpartyIndividualCreateForm();
  const { isSubmitting, isValid } = rest;

  return (
    <form className={classes.root}>
      <div className={classes.panels}>
        <Accordion defaultExpanded={true} disabled>
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <Typography variant="subtitle1">{t('GeneralInfo')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <GeneralInformationIndividualFields
              control={control}
              reset={reset}
              clearErrors={clearErrors}
              isValid={isValid}
              setValue={setValue}
            />
          </AccordionDetails>
        </Accordion>
      </div>
      <Paper square className={classes.actions}>
        <div className={classes.actionButton}>
          <Button
            color="primary"
            size="medium"
            type="submit"
            variant="contained"
            disabled={isSubmitting}
            onClick={onSubmit}
          >
            {t('Save')}
          </Button>
        </div>
      </Paper>
    </form>
  );
};
