import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { MenuItem, Typography } from '@material-ui/core';
import { Button, Grid, Input, Select } from 'components';
import { useOrderPaymentMutation } from '../../services/api';
import { useToast } from '../Toast/useToast';
import { PaymentType } from 'schema/serverTypes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(5.5),
    },
    button: {
      display: 'block',
      margin: '4px auto 0',
    },
    required: {
      marginTop: theme.spacing(-1.5),
      color: theme.palette.text.primary,
    },
    actions: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    actionButton: {
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
  })
);

export type OrderPaymentFormProps = {
  leaseSubject?: string;
  quotaId?: number;
  allowedPaymentTypes: PaymentType[];
  onSuccess: () => void;
};

export const OrderPaymentForm = (props: OrderPaymentFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { quotaId = 0, allowedPaymentTypes } = props;

  const defaultValues = {
    paymentType: '',
    comment: '',
  };

  const { control, handleSubmit } = useForm({
    defaultValues,
    mode: 'all',
  });
  const toast = useToast();
  const history = useHistory();

  const { mutateAsync } = useOrderPaymentMutation(quotaId, {
    onSuccess: () => {
      toast(t('OrderPaymentSuccessMessage'), 'success');
      history.push(`/tasks`);
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const onSubmit = useMemo(() => {
    const submit = async (form: any) => {
      await mutateAsync(form);
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync]);

  const message = t('Required');

  return (
    <form id="newTask-form" onSubmit={onSubmit}>
      <Typography variant={'h2'} className={classes.title}>
        {t('Order payment')}
      </Typography>
      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item md={24} xs={24}>
          <Select
            label={t('Payment choice')}
            control={control}
            name="paymentType"
            rules={{
              required: {
                value: true,
                message,
              },
            }}
          >
            {allowedPaymentTypes.map((value) => {
              const label =
                value === PaymentType.AdvancePayment
                  ? t('Advance payment')
                  : value === PaymentType.Prepayment
                  ? t('Prepayment')
                  : t('Postpaid');
              return <MenuItem value={value}>{label}</MenuItem>;
            })}
          </Select>
        </Grid>
        <Grid item md={24} xs={24}>
          <Input label={t('Comment')} control={control} name="comment" multiline={true} />
        </Grid>
        <Grid item md={24} xs={24}>
          <div className={classes.actions}>
            <div className={classes.actionButton}>
              <Button
                className={classes.button}
                color="primary"
                size="medium"
                type="submit"
                variant="contained"
              >
                {t('Order')}
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};
