import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useTableStyles = makeStyles((theme) =>
  createStyles({
    change: {
      width: '41px',
    },
    actions: {
      display: 'flex',
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
    modalRoot: {
      textAlign: 'center',
    },
    modalTitle: {
      textAlign: 'center',
      marginBottom: theme.spacing(3),
    },
    modalText: {
      color: theme.palette.text.primary,
    },
    modalButton: {
      marginTop: theme.spacing(2),
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
  })
);
