import { createContext, useEffect } from 'react';
import { PageLayoutProps } from './types';
import { AppBar, Toolbar, CssBaseline } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import 'dayjs/locale/ru';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'components/Toast/toast.css';
import { ReactComponent as Logo } from '../../img/FKM_logo.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '98vh',
  },
  appBar: {
    zIndex: theme.zIndex.appBar,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  logo: {
    display: 'flex',
    alignItems: 'baseline',
    textDecoration: 'none',
  },
}));

export const DrawerContext = createContext({
  isDrawerOpen: false,
  setDrawerOpen: (b: boolean) => {},
});

export const PageLayout = (props: PageLayoutProps) => {
  const classes = useStyles();
  const { headerContent, pageContent } = props;

  useEffect(() => {
    // Scroll to top of page during navigation
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <CssBaseline />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className={classes.root}>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <a className={classes.logo} href="/">
              <Logo />
            </a>
            {headerContent}
          </Toolbar>
        </AppBar>
        {pageContent}
      </div>
    </>
  );
};
