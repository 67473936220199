import { Control, UseFormSetValue } from 'react-hook-form';
import {
  SendQuotaToExecutionViewModel as ContractFormValues,
  CounterpartyOption as Option,
  CounterpartyType,
} from 'schema/serverTypes';
import { Dispatch, SetStateAction, useMemo } from 'react';

export type CounterpartyOption = Omit<Option, 'heads'>;

export const useRequestUrl = (input: string) => {
  return useMemo(() => {
    let requestUrl = 'counterparties';
    const searchParams = new URLSearchParams();

    searchParams.set('type', CounterpartyType.lessee);

    if (input) {
      searchParams.set('name', input);
    }
    requestUrl += `?${searchParams}`;

    return requestUrl;
  }, [input]);
};

export const getOptionSelected = (
  option: CounterpartyOption | null,
  value: CounterpartyOption | null
) => {
  if (option === null || value === null) {
    return false;
  }
  return option.inn === value.inn;
};

export const getNameOptionLabel = (option: CounterpartyOption | null) => {
  if (option === null) {
    return '';
  }
  return `${option.inn} (${option.name})`;
};

export const getOptionLabel = (option: CounterpartyOption | null) => {
  if (option === null) {
    return '';
  }
  return `${option.inn} (${option.name})`;
};

export type LesseeAutocompleteProps = {
  control: Control<ContractFormValues, object>;
  setValue: UseFormSetValue<ContractFormValues>;
};

export type LesseeInputProps = LesseeAutocompleteProps & {
  inn: string;
  setInn: Dispatch<SetStateAction<string>>;
  name: string;
  setName: Dispatch<SetStateAction<string>>;
};
