import { useEffect, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { MenuItem, Typography } from '@material-ui/core';
import { Button, DatePicker, Grid, Select } from 'components';
import { ConfirmRatingViewModel } from 'schema/serverTypes';
import { useToast } from '../../Toast';
import { useConfirmRatingMutation } from '../../../services/api/useRatingsBackend';
import { useQueryClient } from 'react-query';
import dayjs from 'dayjs';
import { getRatingString } from './getRatingString';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(3),
    },
    subtitle: {
      textAlign: 'center',
      color: theme.palette.common.black,
      marginBottom: theme.spacing(3.5),
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 10,
      color: theme.palette.common.black,
    },
    button: {
      display: 'block',
      margin: '4px auto 0',
    },
    required: {
      marginTop: theme.spacing(-1.5),
      color: theme.palette.text.primary,
    },
    actions: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    actionButton: {
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
  })
);

export type RatingConfirmFormProps = {
  inn: string;
  id: number;
  rating: number;
  validFrom?: string;
  validTo?: string;
  onClose: (...event: any[]) => void;
};

export const RatingConfirmForm = (props: RatingConfirmFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    inn,
    id,
    rating,
    validFrom = dayjs().format('YYYY-MM-DDTHH:mm:ssZZ'),
    validTo = dayjs().add(1, 'year').format('YYYY-MM-DDTHH:mm:ssZZ'),
    onClose,
  } = props;
  const queryClient = useQueryClient();

  const { control, handleSubmit, setValue } = useForm({
    mode: 'all',
    defaultValues: {
      validFrom: validFrom,
      validTo: validTo,
      grade: rating,
    },
  });
  const toast = useToast();
  const curGrade = useWatch({ control, name: 'grade' });

  const from = useWatch({ control, name: 'validFrom' });

  useEffect(() => {
    setValue('validTo', dayjs(from).add(1, 'year').format('YYYY-MM-DDTHH:mm:ssZZ'));
  }, [from, setValue]);

  const { mutateAsync } = useConfirmRatingMutation(inn, id, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            typeof query.queryKey === 'string' && (query.queryKey as string).indexOf('ratings') >= 0
          );
        },
      });
      toast(t('ConfirmSuccessMessage'), 'success');
      onClose();
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const onSubmit = useMemo(() => {
    const submit = async (form: ConfirmRatingViewModel) => {
      const { validFrom, validTo } = form;
      const values = {
        ...form,
        validFrom: dayjs(validFrom).format('MM.DD.YYYY'),
        validTo: dayjs(validTo).format('MM.DD.YYYY'),
      };
      await mutateAsync(values);
    };
    return handleSubmit(submit as any);
  }, [handleSubmit, mutateAsync]);

  const message = t('Required');

  return (
    <form id="rating-form" onSubmit={onSubmit}>
      <Typography variant={'h2'} className={classes.title}>
        {t('Confirmation')}
      </Typography>
      <Typography variant="subtitle2" className={classes.subtitle}>
        {`${t('Are you sure you want to approve')} Rating Grade ${curGrade}`}
      </Typography>
      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item md={8} xs={24} className={classes.label}>
          {t('Validity period')}
        </Grid>
        <Grid item md={8} xs={24}>
          <DatePicker
            label={`${t('From')}`}
            name="validFrom"
            control={control}
            rules={{
              required: {
                value: true,
                message,
              },
            }}
          />
        </Grid>
        <Grid item md={8} xs={24}>
          <DatePicker
            label={`${t('To')}`}
            name="validTo"
            control={control}
            rules={{
              required: {
                value: true,
                message,
              },
            }}
          />
        </Grid>
        <Grid item md={24} xs={24}>
          <Select label={t('Change Rating Grade')} name="grade" control={control}>
            {Array.from(Array(20)).map((_, index) => {
              const key = index + 1;
              const ratingString = getRatingString(key);
              const label =
                key === rating
                  ? `${ratingString} (Текущее значение)`
                  : key > rating
                  ? `${ratingString} (Ухудшение на ${key - rating})`
                  : `${ratingString} (Улучшение на ${rating - key})`;

              if (Math.abs(key - rating) > 5) return null; //+- 5 from current

              return (
                <MenuItem value={key} key={key}>
                  {label}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid item md={24} xs={24}>
          <div className={classes.actions}>
            <div className={classes.actionButton}>
              <Button
                className={classes.button}
                color="primary"
                size="medium"
                type="submit"
                variant="contained"
              >
                {t('Confirm')}
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};
