import { useTranslation } from 'react-i18next';
import { PropsWithChildren } from 'react';
import { Button } from 'components';

export type AddButtonProps = {
  onClick?: (...event: any[]) => void;
  to?: string;
};

export const AddButton = (props: PropsWithChildren<AddButtonProps>) => {
  const { onClick, children, to } = props;
  const { t } = useTranslation();

  return (
    <Button variant="outlined" onClick={onClick} to={to}>
      {children !== undefined ? children : t('Add')}
    </Button>
  );
};
