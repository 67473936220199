import { useCallback, useState } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IconCheckbox } from '../../../icons';
import { Autocomplete, Chip } from 'components';
import { useMissingDocumentTypeListRequest } from 'services/api/useMissingBackend';
import { MissingLesseeListRequest } from 'schema/serverTypes';
import { AutocompleteChangeDetails } from '@material-ui/lab';
import { MissingSettingsFormProps } from '../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    valueList: {
      padding: theme.spacing(1.5, 1, 0, 0),
    },
    option: {
      marginTop: theme.spacing(-1),
      marginLeft: theme.spacing(-2),
      marginBottom: theme.spacing(-1),
    },
    error: {
      color: theme.palette.chartPurple.main,
      fontSize: 9,
      margin: '4px 0 0',
    },
  })
);

const request: MissingLesseeListRequest = { count: 100 };

const getOptionLabel = (option: string | null) => {
  if (option === null) {
    return '';
  }
  return option;
};

const getOptionSelected = (option: string | null, value: string | null) => {
  if (option === null || value === null) {
    return false;
  }
  return option === value;
};

export const DocumentTypeSelectField = (props: MissingSettingsFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { control, required = false } = props;

  const {
    field: { value, onChange },
    fieldState: { invalid },
  } = useController({
    control,
    name: 'documentType',
    rules: required
      ? {
          required: {
            value: true,
            message: t('Required'),
          },
        }
      : undefined,
  });

  const helperText = invalid ? t('Required') : undefined;
  const [inputValue, setInputValue] = useState('');

  const { data = [] } = useMissingDocumentTypeListRequest(request);
  const options = data.map((t) => t);

  const handleOnInputChange = useCallback(
    (_ev: React.ChangeEvent<{}>, value: string) => {
      setInputValue(value);
    },
    [setInputValue]
  );

  const documentTypes = value;

  const handleOnChange = useCallback(
    (
      _e: React.ChangeEvent<{}>,
      values: string[],
      reason,
      details?: AutocompleteChangeDetails<string>
    ) => {
      if (reason === 'remove-option') {
        const value = details?.option;
        if (value) {
          const newValues = documentTypes.filter((t) => t !== value);
          onChange(newValues);
        }
      } else {
        onChange(values);
      }
    },
    [onChange, documentTypes]
  );

  const handleOnRemove = useCallback(
    (docType: string) => {
      const newValue = value.filter((t) => t !== docType);
      onChange(newValue);
    },
    [value, onChange]
  );

  return (
    <>
      <Autocomplete<string, true>
        error={invalid}
        helperText={helperText}
        multiple
        label={`${t('Document type')}`}
        options={options}
        inputValue={inputValue}
        onInputChange={handleOnInputChange}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        onChange={handleOnChange}
        value={value}
        disableCloseOnSelect={true}
        renderTags={() => null}
        renderOption={(option: string, { selected }) => {
          return (
            <div key={option} className={classes.option}>
              <Checkbox
                icon={<IconCheckbox checked={false} />}
                checkedIcon={<IconCheckbox checked={true} />}
                checked={selected}
              />
              {option}
            </div>
          );
        }}
      />
      <div className={classes.valueList}>
        {value?.map((docType: string) => {
          return <Chip label={docType} id={docType} key={docType} onRemove={handleOnRemove} />;
        })}
      </div>
    </>
  );
};
