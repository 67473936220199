import { Currency } from 'schema/serverTypes';

export const formatCurrency = (currency?: string) => {
  switch (currency) {
    case Currency.Ruble:
      return '₽';
    case Currency.Dollar:
      return '$';
    case Currency.Euro:
      return '€';
    case Currency.Yuan:
      return '¥';
    case 'RUB':
      return '₽';
    case 'EUR':
      return '€';
    case 'USD':
      return '$';
    case 'CNY':
      return '¥';
    default:
      return undefined;
  }
};
