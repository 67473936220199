import { Grid } from '@material-ui/core';
import { Paging, Role } from 'components';
import { TaskItem } from '../TaskItem';
import { useIssuesData } from './useIssuesData';
import { useTranslation } from 'react-i18next';
import { Empty } from '../../Empty';
import { TaskListProps } from './types';
import { ApplicationRole } from 'schema/serverTypes';

export const IssueListFlat = (props: TaskListProps) => {
  const { paging, issues } = useIssuesData(props);
  const { t } = useTranslation();

  return (
    <>
      <Grid item>
        {issues.length === 0 && <Empty>{t("You don't have any tasks yet")}</Empty>}
        <Role
          role={[
            ApplicationRole.Admin,
            ApplicationRole.SalesManager,
            ApplicationRole.SalesSupport,
            ApplicationRole.SuperSalesManager,
            ApplicationRole.RiskManager,
            ApplicationRole.AssetManager,
            ApplicationRole.InsideSales,
          ]}
        >
          {issues.map((issue) => (
            <TaskItem key={issue.id} {...issue} />
          ))}
        </Role>
      </Grid>
      {issues.length > 0 && (
        <Grid item>
          <Paging {...paging} />
        </Grid>
      )}
    </>
  );
};
