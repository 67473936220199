import { IconSprite } from '../icons';
import { themeOrange as theme } from 'theme';

export const CloseButton = ({ closeToast }: any) => {
  return (
    <div onClick={closeToast}>
      <IconSprite
        icon={'cross-small'}
        width="10px"
        height="10px"
        color={theme.palette.secondary.main}
      />
    </div>
  );
};
