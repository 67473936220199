import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Input, useToast } from 'components';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { IssueContractIncidentRequest } from '../../schema';
import { useNotifyIncidentMutation } from '../../services';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(3),
    },
    label: {
      color: theme.palette.common.black,
      marginRight: theme.spacing(1.5),
    },
    control: {
      width: '100%',
    },
    field: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(4),
    },
    action: {
      textAlign: 'center',
    },
    button: {},
  })
);

type IssueContractIncidentFormProps = {
  issueId: number;
  onClose: () => void;
};

export const IssueContractIncidentForm = ({ issueId, onClose }: IssueContractIncidentFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const toast = useToast();

  const { handleSubmit, control } = useForm({
    mode: 'onBlur',
    defaultValues: {
      issueId,
      incidentText: '',
    },
  });

  const { mutateAsync } = useNotifyIncidentMutation({
    method: 'PUT',
    onSuccess: () => {
      toast(t('NotificationSuccessMessage'), 'success');
      onClose();
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const onSubmit = useMemo(() => {
    const submit = async (form: IssueContractIncidentRequest) => {
      await mutateAsync(form);
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync]);

  return (
    <div className={classes.root}>
      <Typography variant={'h2'} className={classes.title}>
        Сообщить об инциденте
      </Typography>
      <form onSubmit={onSubmit}>
        <div className={classes.field}>
          <div className={classes.control}>
            <Input
              label="Текст"
              name="incidentText"
              control={control}
              multiline={true}
              rules={{
                required: {
                  value: true,
                  message: '',
                },
              }}
            />
          </div>
        </div>
        <div className={classes.action}>
          <Button variant="contained" className={classes.button} type={'submit'}>
            {t('Ok')}
          </Button>
        </div>
      </form>
    </div>
  );
};
