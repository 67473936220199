import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IconSprite } from '../icons';
import { useCallback, useState } from 'react';
import { Button } from '../Button';
import { ModalForm, useModalForm } from '../Modal';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { WikiEditForm } from './WikiEditForm';
import { CommentList } from './CommentList';
import { AddComment } from './AddComment';
import { useDeleteWikiMutation } from '../../services/api/useWikiBackend';
import { ApplicationRole, WikiItemViewModel } from '../../schema';
import { useQueryClient } from 'react-query';
import { useToast } from '../Toast';
import dayjs from 'dayjs';
import { useLanguage } from '../utils/useLanguage';
import { Role } from '../authentication';
import { FileList } from './FileUpload';
import { Chip } from '../Chips';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.common.black,
      fontSize: 14,
    },
    article: {
      background: 'white',
      marginBottom: theme.spacing(4),
      position: 'relative',
    },
    author: {
      position: 'absolute',
      right: 0,
      top: -37,
      fontSize: 14,
      color: theme.palette.common.black,
    },
    title: {
      borderBottom: '2px solid #E3E6F3',
      padding: theme.spacing(2, 2),
      color: theme.palette.primary.main,
      fontWeight: 700,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    edit: {
      marginLeft: theme.spacing(1),
      whiteSpace: 'nowrap',
    },
    action: {
      '& + &': {
        marginLeft: theme.spacing(0.5),
      },
    },
    description: {
      padding: theme.spacing(1.5, 2),
      '& ol': {
        paddingLeft: theme.spacing(2),
        '& > li': {
          listStyleType: 'decimal',
          marginBottom: theme.spacing(1),
        },
      },
      '& ul': {
        paddingLeft: theme.spacing(2),
        '& > li': {
          listStyleType: 'initial',
          marginBottom: theme.spacing(1),
        },
      },
    },
    link: {
      padding: theme.spacing(0, 2, 2),
      fontFamily: "'Halvar Breit', Helvetica, Arial, sans-serif",
      fontSize: 12,
      fontWeight: 500,
      display: 'inline-block',
      color: theme.palette.secondary.dark,
      '& a': {
        color: theme.palette.secondary.dark,
        textDecoration: 'none',
      },
    },
    actions: {
      position: 'absolute',
      right: 1,
      top: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      '& *': {
        marginRight: 9,
        cursor: 'pointer',
      },
    },
    file: {
      cursor: 'pointer',
      position: 'relative',
      margin: theme.spacing(0, 1.5, 1.5),
      padding: theme.spacing(1.5, 1.25, 1),
      backgroundColor: theme.palette.lightBlue3.main,
      color: theme.palette.primary.main,
    },
    modalRoot: {
      textAlign: 'center',
    },
    modalTitle: {
      textAlign: 'center',
      marginBottom: theme.spacing(3),
    },
    modalText: {
      color: theme.palette.text.primary,
    },
    modalButton: {
      marginTop: theme.spacing(2),
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
    tags: {
      padding: theme.spacing(1.5, 0),
      backgroundColor: theme.palette.lightBlue3.main,
    },
    chip: {
      display: 'inline-block',
      background: 'white',
      boxShadow: '1px 1px 4px rgba(56, 60, 97, 0.08)',
      borderRadius: 8,
      fontWeight: 500,
      fontSize: 12,
      color: theme.palette.secondary.main,
      padding: theme.spacing(0.5, 2.3, 0, 1),
      margin: theme.spacing(0, 1.5, 1, 0),
      position: 'relative',
    },
    delete: {
      width: 6,
      height: 6,
      '&:after, &:before': {
        content: '""',
        position: 'absolute',
        display: 'block',
        backgroundColor: theme.palette.text.secondary,
        width: 8,
        height: 1,
        right: 8,
        top: 11,
        transform: 'rotate(45deg)',
      },
      '&:before': {
        transform: 'rotate(-45deg)',
      },
    },
  })
);

export const WikiArticle = (props: { article: WikiItemViewModel }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const lang = useLanguage();
  const [isEditMode, setEditMode] = useState<boolean>(false);
  const { title, text = '', files, id, tags, createdDate, author } = props.article;
  const { onOpen, onClose, open } = useModalForm();
  const queryClient = useQueryClient();
  const toast = useToast();

  const editModeHandler = () => {
    setEditMode((prev) => !prev);
  };

  const deleteHandler = useCallback(
    (id) => {
      onOpen();
    },
    [onOpen]
  );

  const { push } = useHistory();

  const { mutateAsync: deleteAsync } = useDeleteWikiMutation(id, {
    onSuccess: () => {
      toast(t('RatingSuccessMessage'), 'success');
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            typeof query.queryKey === 'string' && (query.queryKey as string).indexOf('wiki') >= 0
          );
        },
      });
      push('/wiki');
      onClose();
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const confirmDeleteHandler = useCallback(() => {
    deleteAsync(null);
  }, [deleteAsync]);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.article}>
          <div className={classes.author}>
            <Typography component="span" variant={'body2'}>
              {author.name}
            </Typography>{' '}
            | {dayjs(createdDate).locale(lang).format('DD MMM YYYY HH:mm')}
          </div>
          {!isEditMode ? (
            <>
              <div className={classes.title}>
                <Typography variant={'h4'} color="primary">
                  {title}
                </Typography>
                <Role
                  role={[
                    ApplicationRole.Admin,
                    ApplicationRole.SuperSalesManager,
                    ApplicationRole.RiskManager,
                  ]}
                >
                  <div className={classes.edit}>
                    <Button
                      variant="iconButton"
                      endIcon={<IconSprite icon={'edit'} width={'14px'} />}
                      onClick={editModeHandler}
                      className={classes.action}
                    />
                    <Button
                      variant="iconButton"
                      endIcon={<IconSprite icon={'delete'} width={'14px'} />}
                      onClick={deleteHandler}
                      className={classes.action}
                    />
                  </div>
                </Role>
              </div>
              <div className={classes.description} dangerouslySetInnerHTML={{ __html: text }} />
              <FileList files={files} />
              <div className={classes.tags}>
                <Box mb={1} mt={2}>
                  {tags &&
                    tags.map((label: string) => (
                      <Chip label={label} id={label} key={`wiki-${label}`} disabled />
                    ))}
                </Box>
              </div>
            </>
          ) : (
            <WikiEditForm article={props.article} editModeHandler={editModeHandler} />
          )}
        </div>
        <AddComment articleId={props.article.id} />
        {!!props.article.comments?.length && (
          <CommentList comments={props.article.comments} articleId={props.article.id} />
        )}
      </div>
      <ModalForm open={open} onClose={onClose} width={500}>
        <div className={classes.modalRoot}>
          <Typography variant={'h2'} className={classes.modalTitle}>
            {t('Delete article')}
          </Typography>
          <Typography variant="subtitle2" className={classes.modalText}>
            {t('Are you sure you want to delete the article')}
          </Typography>
          <Typography variant="body2" className={classes.modalText}>
            {props.article.title}?
          </Typography>
          <div>
            <Button
              variant="contained"
              className={classes.modalButton}
              onClick={confirmDeleteHandler}
            >
              {t('Yes')}
            </Button>
            <Button variant="outlined" onClick={onClose} className={classes.modalButton}>
              {t('No')}
            </Button>
          </div>
        </div>
      </ModalForm>
    </>
  );
};
