import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import { useEffect, useState } from 'react';

export const useHub = (hubConnection?: HubConnection) => {
  const [hubConnectionState, setHubConnectionState] = useState<HubConnectionState>(
    hubConnection?.state ?? HubConnectionState.Disconnected
  );
  const [error, setError] = useState();

  useEffect(() => {
    setError(undefined);

    if (!hubConnection) {
      setHubConnectionState(HubConnectionState.Disconnected);
      return;
    }

    if (hubConnection.state !== hubConnectionState) {
      setHubConnectionState(hubConnection.state);
    }

    let isMounted = true;
    const onStateUpdatedCallback = () => {
      if (isMounted) {
        setHubConnectionState(hubConnection?.state);
      }
    };
    hubConnection.onclose(onStateUpdatedCallback);
    hubConnection.onreconnected(onStateUpdatedCallback);
    hubConnection.onreconnecting(onStateUpdatedCallback);

    if (hubConnection.state === HubConnectionState.Disconnected) {
      hubConnection
        .start()
        .then(onStateUpdatedCallback)
        .catch((reason) => setError(reason));

      onStateUpdatedCallback();

      return () => {
        isMounted = false;
      };
    }
  }, [hubConnection, hubConnectionState]);

  return { hubConnectionState, error };
};
