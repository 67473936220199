import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { Input, Button, IconSprite } from 'components';
import { Grid } from 'components/Grid';
import { useTranslation } from 'react-i18next';
import { WikiChips } from './WikiChips';
import { WikiFilterFormProps } from './types';
import { themeOrange as theme } from 'theme';
import { WikiTagAutocomplete } from './WikiTagAutocomplete';
import { useWatch } from 'react-hook-form';
import { useCallback } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '100%',
    },
    filterContainer: {
      width: 'calc(100vw - 260px)',
      overflow: 'hidden',
    },
    buttonReset: {
      display: 'flex',
      alignItems: 'flex-end',
      marginBottom: theme.spacing(0.2),
    },
  })
);

export const WikiFilterForm = (props: WikiFilterFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { control, onReset, setValue } = props;

  var tags = useWatch({ control, name: 'tags' });

  const onTagsChanged = useCallback(
    (values: string[]) => {
      setValue('tags', values);
    },
    [setValue]
  );

  return (
    <form>
      <Box display="flex" justifyContent="space-between" mb={2.5} alignItems="flex-end">
        <Box flex={1}>
          <Grid container columnSpacing={2} rowSpacing={2.5} className={classes.root}>
            <Grid md={5} xs={24} item>
              <WikiTagAutocomplete tags={tags} onChange={onTagsChanged} />
            </Grid>
            <Grid md={5} xs={24} item>
              <Input
                label={`${t('Search')}`}
                control={control}
                name="search"
                variant="standard"
                InputProps={{
                  endAdornment: (
                    <IconSprite width="16px" color={theme.palette.text.primary} icon="search" />
                  ),
                }}
              />
            </Grid>
            <Grid md={3} xs={24} item className={classes.buttonReset}>
              <FormControl>
                <Button variant="text" onClick={onReset}>
                  {t('Reset')}
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box mb={1}>
        <WikiChips tags={tags} onChange={onTagsChanged} />
      </Box>
    </form>
  );
};
