import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import {
  Input,
  Button,
  OverdueStatusAutocomplete,
  OverdueManagerAutocomplete,
  OverdueLesseeAutocomplete,
  OverdueChips,
} from 'components';
import { Grid } from 'components/Grid';
import { useTranslation } from 'react-i18next';
import { MissingOverdueFilterFormProps } from './types';
import { Tabs, useTabs } from '../Tabs';
import { useController, useWatch } from 'react-hook-form';
import { useCallback } from 'react';
import { useCurrentUserTabsQuery } from 'services/api';
import { ApplicationUserTab } from '../../schema';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '100%',
    },
    buttonReset: {
      display: 'flex',
      alignItems: 'flex-end',
      marginBottom: theme.spacing(0.2),
    },
  })
);

export const OverdueFilterForm = (props: MissingOverdueFilterFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { control, onReset, setValue } = props;
  const tabId = useWatch({ control, name: 'tabId' }) ?? 0;

  const {
    field: { onChange: onTabIdChange },
  } = useController({ control, name: 'tabId' });
  const { data: userTabs = [] } = useCurrentUserTabsQuery();
  const tabsProps = useTabs(
    userTabs.map((t) => t.name),
    tabId === ApplicationUserTab.all ? 1 : 0
  );
  const { tabIndex, onChangeTab, tabs } = tabsProps;

  const onMineChanged = useCallback(
    (e: any, index: number) => {
      onChangeTab(e, index);
      const tab = userTabs.find((t) => t.name === tabs[index]);
      if (tab) {
        onTabIdChange(tab.id);
      }
    },
    [onChangeTab, onTabIdChange, userTabs, tabs]
  );

  return (
    <form>
      <Box
        mb={2.5}
        display="flex"
        justifyContent="space-between"
        alignItems="flex-end"
        overflow="hidden"
      >
        <Box>
          <Tabs {...tabsProps} value={tabIndex} onChangeTab={onMineChanged} />
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mb={2.5} alignItems="flex-end">
        <Box flex={1}>
          <Grid container columnSpacing={2} rowSpacing={2.5} className={classes.root}>
            {tabId !== 0 && (
              <Grid md={4} xs={24} item>
                <OverdueManagerAutocomplete control={control} />
              </Grid>
            )}
            <Grid md={4} xs={24} item>
              <OverdueLesseeAutocomplete control={control} />
            </Grid>
            <Grid md={4} xs={24} item>
              <Input label={`Договор`} control={control} name="contractNumber" variant="standard" />
            </Grid>
            <Grid md={5} xs={24} item>
              <OverdueStatusAutocomplete control={control} />
            </Grid>
            <Grid md={3} xs={24} item className={classes.buttonReset}>
              <FormControl>
                <Button variant="text" onClick={onReset}>
                  {t('Reset')}
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box mb={1}>
        <OverdueChips control={control} setValue={setValue} hideManagers={tabId === 0} />
      </Box>
    </form>
  );
};
