import {
  FormControlLabel,
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
  FormControl,
  Box,
} from '@material-ui/core';
import { createStyles, withStyles, Theme, WithStyles, alpha } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useController, FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';
import { GenericWithStyles } from './GenericWithStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      minHeight: 37,
      alignItems: 'center',
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      marginRight: 10,
      lineHeight: '20px',
    },
    nowrap: {
      whiteSpace: 'nowrap',
    },
    content: {
      paddingTop: theme.spacing(1),
    },
    valueWrapper: {
      position: 'relative',
    },
    val: {
      fontFamily: 'Roboto, Helvetica, Arial, san-serif',
      position: 'absolute',
      left: 0,
      lineHeight: '20px',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    hidden: {
      //hidden span hack for dynamic width
      display: 'block',
      height: '14px',
      overflow: 'hidden',
      left: 0,
      opacity: 0,
    },
    switchRoot: {
      height: 20,
      width: 48,
      padding: '0 8px',
    },
    switchBase: {
      opacity: 1,
      padding: '0 8px',
      '&&.Mui-disabled+ .MuiSwitch-track': {
        backgroundColor: alpha(theme.palette.background.dark, 0.7),
        border: `1px solid ${theme.palette.background.dark}`,
        opacity: 1,
      },
      '&&.Mui-disabled  .MuiSwitch-thumb': {
        color: alpha(theme.palette.text.primary, 0.7),
        opacity: 1,
      },
      '&.Mui-checked': {
        transform: 'translateX(13px)',
        '&+ .MuiSwitch-track': {
          backgroundColor: theme.palette.primary.main,
          border: `1px solid ${theme.palette.primary.main}`,
          opacity: 1,
        },
        '& .MuiSwitch-thumb': {
          width: 15,
          height: 15,
          color: theme.palette.background.paper,
          margin: 2.5,
          boxShadow: 'none',
        },
      },
      '&&:hover': {
        backgroundColor: 'transparent',
      },
    },
    switchTrack: {
      backgroundColor: theme.palette.background.dark,
      borderRadius: 62,
      border: `1px solid ${theme.palette.text.primary}`,
      opacity: 1,
      '& .MuiSwitch-thumb': {
        width: 15,
        height: 15,
        color: theme.palette.background.paper,
        margin: 2,
      },
    },
    switchThumb: {
      color: theme.palette.text.primary,
      width: 10,
      height: 10,
      margin: 5,
    },
  });

export type SwitchProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Omit<UseControllerProps<TFieldValues, TName>, 'defaultValue'> &
  MuiSwitchProps &
  WithStyles<typeof styles, true> & {
    label?: string;
    labelOn?: string;
    labelOff?: string;
    titleWrap?: boolean;
    defaultValue?: boolean;
  };

function StyledSwitch<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(props: SwitchProps<TFieldValues, TName>) {
  const { t } = useTranslation();
  const {
    defaultValue,
    name,
    control,
    shouldUnregister,
    rules,
    classes,
    label,
    labelOn = t('Yes'),
    labelOff = t('No'),
    color,
    titleWrap = true,
    ...rest
  } = props;

  const {
    field: { ref, value = defaultValue, ...inputProps },
  } = useController<TFieldValues, TName>({
    control,
    name,
    shouldUnregister,
    rules,
  });

  const noWrapClass = titleWrap ? '' : classes?.nowrap;
  const checked = value === true;

  return (
    <FormControl className={classes?.root} size="small">
      {label && <Box className={`${classes?.label} ${noWrapClass}`}>{label}</Box>}
      <Box>
        <FormControlLabel
          control={
            <MuiSwitch
              checked={checked}
              {...inputProps}
              {...rest}
              classes={{
                root: classes?.switchRoot,
                switchBase: classes?.switchBase,
                track: classes?.switchTrack,
                thumb: classes?.switchThumb,
              }}
            />
          }
          label={
            <div className={classes?.valueWrapper}>
              <span className={classes?.val}>{checked ? labelOn : labelOff}</span>
              <span className={classes?.hidden}>
                {labelOn}
                <br />
                {labelOff}
              </span>
            </div>
          }
        />
      </Box>
    </FormControl>
  );
}

export const Switch = withStyles(styles, { withTheme: true })(StyledSwitch) as <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: GenericWithStyles<SwitchProps<TFieldValues, TName>>
) => React.ReactElement;
