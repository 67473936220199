import { Grid } from 'components/Grid';
import { useTranslation } from 'react-i18next';
import { StaticInput } from 'components/form';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CounterpartyContractType, CounterpartyExternalContractViewModel } from 'schema';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      marginBottom: theme.spacing(2.5),
      width: '100%',
    },
  })
);

export const AdditionalDataContractsFields = ({
  contracts,
}: {
  contracts: CounterpartyExternalContractViewModel[];
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const getType = ({ isMain, isFramework }: CounterpartyExternalContractViewModel) => {
    const types = [];
    if (isMain) types.push('основной');
    if (isFramework) types.push('рамочный');
    return types.length ? `${types.join(', ')[0].toUpperCase()}${types.join(', ').slice(1)}` : '';
  };

  return (
    <div className={classes.wrapper}>
      {contracts?.length > 0 &&
        contracts.map((contract, index) => (
          <Grid
            container
            columnSpacing={2}
            rowSpacing={2.5}
            key={contract.name + contract.externalId}
          >
            <Grid item xl={3} md={12} xs={24}>
              <StaticInput label={'Наименование'} value={contract.name} />
            </Grid>
            <Grid item xl={2} md={4} xs={24}>
              <StaticInput
                label={t('Contract type')}
                value={
                  contract.type === CounterpartyContractType.Agent
                    ? 'Агентский'
                    : contract.type === CounterpartyContractType.Subsidy
                    ? 'Дог. субсидирования'
                    : ''
                }
              />
            </Grid>
            <Grid item xl={2} md={4} xs={24}>
              <StaticInput label={t('Type')} value={getType(contract)} />
            </Grid>
            <Grid item xl={2} md={4} xs={24}>
              <StaticInput label={t('Код 1С')} value={contract.externalId} />
            </Grid>
          </Grid>
        ))}
    </div>
  );
};
