import { useQueryClient } from 'react-query';
import {
  AddRegionTierRequest,
  PageOptions,
  RegionTierFilter,
  RegionTierPagedList,
  RegionTierSorting,
  RegionTierViewModel,
  RegionViewModel,
  UpdateRegionTierViewModel,
} from 'schema/serverTypes';
import { BackendQueryOptions, createOptions, createUrlFromParts } from './types';
import { useBackendQuery, useBackendMutation, MutationOptions } from './useBackend';

const entity = 'regionTiers';

const useRegionTiersBackendQuery = <TResponse>(options: BackendQueryOptions<TResponse>) => {
  let queryKey = entity;
  if (options.relativeUrl && options.relativeUrl !== '') {
    queryKey = `${queryKey}-${options.relativeUrl}`;
  }
  if (options.searchParams) {
    queryKey = `${queryKey}-${options.searchParams}`;
  }
  return useBackendQuery(
    createUrlFromParts(entity, options.relativeUrl, options.searchParams),
    queryKey,
    createOptions(options.options)
  );
};

export type UseRegionTiersQueryProps = RegionTierFilter & Partial<RegionTierSorting> & PageOptions;

export const useRegionTierListQuery = (props: UseRegionTiersQueryProps) => {
  const { search, tier, order, sortBy, page = 1, pageSize = 20 } = props;
  let searchParams = new URLSearchParams();

  if (search !== undefined && search !== '') {
    searchParams.append('search', search);
  }

  if (tier) {
    searchParams.append('tier', tier);
  }

  if (sortBy) {
    searchParams.append('sortBy', sortBy);
  }

  if (order) {
    searchParams.append('order', order);
  }

  searchParams.append('page', page.toString());
  searchParams.append('pageSize', pageSize.toString());

  return useRegionTiersBackendQuery<RegionTierPagedList>({
    searchParams,
    options: {
      refetchOnMount: true,
    },
  });
};

export const useRegionTierQuery = (regionCode: string) =>
  useRegionTiersBackendQuery<RegionTierViewModel>({
    relativeUrl: regionCode,
  });

export const useRegionSearchQuery = (name?: string) => {
  let searchParams = new URLSearchParams();

  if (name !== undefined && name !== '') {
    searchParams.append('name', name);
  }

  return useRegionTiersBackendQuery<RegionViewModel[]>({
    searchParams,
    relativeUrl: 'regions',
    options: {
      refetchOnMount: true,
      enabled: (name?.length ?? 0) > 0,
    },
  });
};

export const useAddRegionTierMutation = (
  options: MutationOptions<AddRegionTierRequest, RegionTierViewModel> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const onSuccess = options?.onSuccess;

  return useBackendMutation(createUrlFromParts(entity), {
    ...options,
    onSuccess: (data, variables, context) => {
      if (onSuccess) {
        onSuccess(data, variables, context);
      }
      queryClient.invalidateQueries({
        predicate: (query) => {
          if (Array.isArray(query.queryKey)) {
            const key = query.queryKey as Array<string>;
            if (key[0] === entity) {
              return true;
            }
          }
          return (
            typeof query.queryKey === 'string' && (query.queryKey as string)?.indexOf(entity) > -1
          );
        },
      });
    },
    method: 'POST',
  });
};

export const useUpdateRegionTierMutation = (
  regionCode: string,
  options: MutationOptions<UpdateRegionTierViewModel, RegionTierViewModel> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const onSuccess = options?.onSuccess;

  return useBackendMutation(createUrlFromParts(entity, regionCode), {
    ...options,
    onSuccess: (data, variables, context) => {
      if (onSuccess) {
        onSuccess(data, variables, context);
      }
      queryClient.invalidateQueries({
        predicate: (query) => {
          if (Array.isArray(query.queryKey)) {
            const key = query.queryKey as Array<string>;
            if (key[0] === entity) {
              return true;
            }
          }
          return (
            typeof query.queryKey === 'string' && (query.queryKey as string)?.indexOf(entity) > -1
          );
        },
      });
    },
    method: 'PUT',
  });
};
