//@ts-nocheck
import { useLocation } from 'react-router-dom';
import { Sidebar } from 'components/Sidebar';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Button } from '../Button';
import { useTranslation } from 'react-i18next';
import { Input } from '../form';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { MissingOverdueCommentViewModel, UpdateNomenclatureItemRequest } from 'schema/serverTypes';
import { useQueryClient } from 'react-query';
import { useMemo } from 'react';
import { useMissingCommentsBackendMutation, useMissingOverdueRequest } from '../../services/api';
import { Colorize } from 'components';
import { formatCurrency } from 'components/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '440px',
      minWidth: '440px',
      border: 'none',
      boxShadow: 'none',
      height: '100%',
      display: 'grid',
      gridTemplateRows: 'auto auto 1fr auto',
    },
    headerWrapper: {
      marginBottom: theme.spacing(0.5),
    },
    header: {
      paddingTop: theme.spacing(5.5),
      fontWeight: 'bolder',
      textAlign: 'center',
      paddingRight: 40,
      paddingLeft: 40,
      paddingBottom: 20,
    },
    titleWrapper: {
      paddingRight: 40,
      paddingLeft: 40,
      textAlign: 'center',
      margin: theme.spacing(2, 0, 0.5),
    },
    title: {
      fontSize: 14,
      fontWeight: 500,
      color: theme.palette.text.secondary,
      margin: theme.spacing(0, 0, 1),
      display: 'inline-block',
      '&+ $title': {
        marginLeft: theme.spacing(2),
      },
    },
    planDate: {
      fontSize: 12,
      fontWeight: 400,
      color: theme.palette.common.black,
      margin: theme.spacing(1, 0, 0),
    },
    cardContent: {
      paddingTop: 20,
      paddingRight: 40,
      paddingBottom: 20,
      paddingLeft: 40,
      overflowY: 'auto',
      marginBottom: theme.spacing(3),
    },
    date: {
      color: theme.palette.secondary.dark,
      fontSize: 12,
      fontWeight: 400,
    },
    status: {
      marginBottom: theme.spacing(3),
    },
    statusText: {
      color: theme.palette.text.secondary,
      fontSize: 14,
      fontWeight: 400,
      '&:before': {
        content: "' | '",
      },
    },
    statusOk: {
      color: theme.palette.green.main,
    },
    message: {
      marginBottom: theme.spacing(3),
    },
    messageText: {
      color: theme.palette.common.black,
      fontSize: 14,
      fontWeight: 400,
      marginTop: theme.spacing(0.75),
    },
    actions: {
      display: 'block',
      boxShadow: '3px -3px 15px rgba(56, 60, 97, 0.1)',
      padding: theme.spacing(4, 5),
    },
    input: {
      marginBottom: theme.spacing(2),
    },
    closeButton: {
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
      '& img': {
        maxWidth: '10px',
      },
    },
  })
);

export const MissingCommentOverdueFormPanel = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const contractNumber = query.get('contractNumber') ?? '';
  const dealerName = query.get('dealerName') ?? '';
  const { data: overdue, isLoading } = useMissingOverdueRequest(contractNumber);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    mode: 'onBlur',
  });

  const queryClient = useQueryClient();

  const { mutateAsync } = useMissingCommentsBackendMutation(
    `/overdue/comments?contractNumber=${contractNumber}`,
    {
      method: 'POST',
      onSuccess: (_result) => {
        setValue('text', '');
        queryClient.invalidateQueries({
          predicate: (query) => {
            return (
              typeof query.queryKey === 'string' &&
              (query.queryKey as string).startsWith('Missings')
            );
          },
        });
      },
    }
  );

  const onSubmit = useMemo(() => {
    const submit = async (form: UpdateNomenclatureItemRequest) => {
      try {
        await mutateAsync(form);
      } catch (error) {
        console.log(error);
      }
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync]);

  if (!overdue) return null;

  const { dpdCounter = 0, penaltyCharge = 0, currency } = overdue;
  const comments = overdue?.comments ?? [];

  return (
    <Sidebar url="/missing" width={440}>
      <Card className={classes.root}>
        <Box className={classes.headerWrapper}>
          <CardHeader className={classes.header} title={`${dealerName} | ${contractNumber}`} />
        </Box>
        <Box className={classes.titleWrapper}>
          <span className={classes.title}>
            DPD: <Colorize value={dpdCounter} unit={t('day')} />
          </span>
          <span className={classes.title}>
            {t('Penalty charge')}:{' '}
            <Colorize
              limits={[0, 0]}
              value={penaltyCharge}
              isFormatMoney
              unit={formatCurrency(currency)}
            />
          </span>
        </Box>
        <CardContent className={classes.cardContent}>
          {comments.map((c: MissingOverdueCommentViewModel) => {
            return c.text ? (
              <Message
                date={dayjs(c.createdDate).format('DD.MM.YYYY HH:mm')}
                text={c.text}
                key={c.id}
                author={c.author?.name}
              />
            ) : (
              <Status
                key={c.id}
                date={dayjs(c.createdDate).format('DD.MM.YYYY')}
                debtDepth={c.debtDepth ?? 0}
                overdueAmount={c.overdueAmount ?? 0}
                currency={c.currency}
              />
            );
          })}
        </CardContent>
        <CardActions className={classes.actions}>
          <form onSubmit={onSubmit}>
            <Input
              label={t('Comment')}
              name={'text'}
              control={control}
              fullWidth
              rules={{
                required: {
                  value: true,
                  message: t('Required'),
                },
              }}
              className={classes.input}
            />
            <Button
              variant={'contained'}
              disabled={isSubmitting || isLoading}
              type={'submit'}
              //onClick={onSubmit}
            >
              {t('Add')}
            </Button>
          </form>
        </CardActions>
      </Card>
    </Sidebar>
  );
};

const Status = ({
  date,
  overdueAmount,
  debtDepth,
  currency,
}: {
  date: string;
  overdueAmount: number;
  debtDepth: number;
  currency: string;
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.status}>
      <span className={classes.date}>{date}</span>
      <span className={classes.statusText} />
      {overdueAmount === 0 ? (
        <span className={classes.statusOk}>OK</span>
      ) : (
        <>
          <Colorize
            limits={[0, 0]}
            value={overdueAmount}
            isFormatMoney
            unit={formatCurrency(currency)}
          />
          <span className={classes.statusText} />
          <Colorize limits={[90, 30]} value={debtDepth} unit={t('day')} />
        </>
      )}
    </div>
  );
};

const Message = ({ date, text, author }: { date: string; text: string; author?: string }) => {
  const classes = useStyles();

  return (
    <div className={classes.message}>
      <div className={classes.date}>
        {date}
        {author && <span className={classes.statusText}>{author}</span>}
      </div>
      <div className={classes.messageText}>{text}</div>
    </div>
  );
};
