import { ContractCounterpartyRequisiteViewModel } from 'schema';
import { Grid } from 'components/Grid';
import { Checkbox } from 'components/form';
import { ContractFormValues } from './types';
import { Control, UseFormTrigger } from 'react-hook-form';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { UseFormClearErrors, UseFormSetError } from 'react-hook-form/dist/types/form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      marginBottom: theme.spacing(2.5),
    },
    title: {
      fontWeight: 700,
      marginBottom: theme.spacing(1.5),
    },
    label: {
      color: theme.palette.text.secondary,
      fontSize: 12,
    },
    value: {
      color: theme.palette.text.primary,
      fontSize: 12,
      paddingRight: theme.spacing(4),
    },
  })
);

type RequisiteListProps = {
  requisites: ContractCounterpartyRequisiteViewModel[];
  control: Control<ContractFormValues>;
  name: 'lessorRequisites' | 'dealerRequisites' | 'lesseeRequisites';
  setError: UseFormSetError<ContractFormValues>;
  clearErrors: UseFormClearErrors<ContractFormValues>;
  trigger?: UseFormTrigger<ContractFormValues>;
};

export const RequisiteList = ({
  requisites,
  control,
  name,
  setError,
  clearErrors,
}: RequisiteListProps) => {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const validateAtLeastOne = () => {
      const isAnySelected = requisites.some((requisite) => requisite.isSelected);
      if (!isAnySelected) {
        setError(name, { type: 'manual' });
        setErrorMessage('Необходимо выбрать хотя бы один счет.');
      } else {
        clearErrors(name);
        setErrorMessage(null);
      }
    };

    validateAtLeastOne();
  }, [clearErrors, setError, name, requisites, ...requisites.map((r) => r.isSelected)]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const requisitesIndex = requisites.map((requisite, index) => {
    return {
      ...requisite,
      index,
      name,
    };
  });

  const mainRequisite = requisitesIndex.filter((item) => item.isMain);
  const anotherRequisite = requisitesIndex.filter((item) => !item.isMain);

  return (
    <Grid md={24} xs={24} item>
      <div className={classes.wrapper}>
        <Typography variant={'body2'} className={classes.title}>
          Основной счёт
        </Typography>
        {mainRequisite.map((requisite) => {
          return <RequisiteItem requisite={requisite} control={control} key={requisite.id} />;
        })}
      </div>
      <div className={classes.wrapper}>
        <Typography variant={'body2'} className={classes.title}>
          Прочие счета
        </Typography>
        {anotherRequisite.map((requisite) => {
          return <RequisiteItem requisite={requisite} control={control} key={requisite.id} />;
        })}
      </div>
      {errorMessage && (
        <Typography variant="body2" color="error" className={classes.wrapper}>
          {errorMessage}
        </Typography>
      )}
    </Grid>
  );
};

type RequisiteItemProps = {
  requisite: ContractCounterpartyRequisiteViewModel & {
    index: number;
    name: 'lessorRequisites' | 'dealerRequisites' | 'lesseeRequisites';
  };
  control: Control<ContractFormValues>;
};

const RequisiteItem = ({ requisite, control }: RequisiteItemProps) => {
  const classes = useStyles();

  return (
    <Checkbox
      checked={requisite.isSelected}
      name={`${requisite.name}.${requisite.index}.isSelected`}
      label={
        <>
          <span className={classes.label}>р/с: </span>
          <span className={classes.value}>{requisite.account}</span>

          <span className={classes.label}>БИК: </span>
          <span className={classes.value}>{requisite.bic}</span>

          <span className={classes.label}>к/с: </span>
          <span className={classes.value}>{requisite.correspondentAccount}</span>

          <span className={classes.label}>Банк: </span>
          <span className={classes.value}>{requisite.bank}</span>
        </>
      }
      control={control}
    />
  );
};
