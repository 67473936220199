import palette from '../paletteOrange';

const style = {
  root: {
    borderBottom: '1px solid' + palette.divider,
    minHeight: '30px',
    '& .MuiTabs-scroller': {
      width: 'auto',
      minWidth: 'auto',
      borderBottom: '1px solid' + palette.text.secondary,
      flex: 'none',
    },
    '& span.MuiTabs-indicator': {
      height: '3px',
      backgroundColor: palette.secondary.dark,
    },
    '& .MuiTab-root:not(.Mui-selected):hover': {
      borderBottom: '3px solid transparent',
      color: palette.text.primary,
    },
  },
};

export default style;
