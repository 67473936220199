import { Route, Switch } from 'react-router-dom';
import { TemplatesDataGrid, TemplateCreateForm, TemplateEditForm, Critical } from 'components';
import { useErrorContext } from 'components/tasks';
import { useTranslation } from 'react-i18next';
import { IssueSource } from 'schema/serverTypes';

export const TemplatesPage = () => {
  const { t } = useTranslation();

  useErrorContext({
    title: t('Templates'),
    source: IssueSource.template,
  });

  return (
    <>
      <Critical />
      <Switch>
        <Route path="/templates/create" exact component={TemplateCreateForm} />
        <Route path="/templates/:id" exact component={TemplateEditForm} />
        <Route path="/templates" exact component={TemplatesDataGrid} />
      </Switch>
    </>
  );
};
