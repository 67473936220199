import { useCallback, useState } from 'react';
import { QueryKey, useMutation } from 'react-query';
import {
  TemplateDataFilter,
  TemplateKind,
  TemplatePrintViewModel,
  ValidationProblemDetails,
} from 'schema/serverTypes';
import {
  useBackendQuery,
  QueryOptions,
  MutationOptions,
  useBackendFetch,
  useBackendMutation,
} from '.';
import { calculationUrl } from 'services/urls';
import { EmptyResponse } from './types';

const createUrlFromParts = (relativeUrl?: string, searchParams?: URLSearchParams) => {
  const urlParts = relativeUrl ? relativeUrl.trim().split('/') : [];
  const parts = [calculationUrl, 'api', 'v1', 'templates', ...urlParts].filter((t) => t !== '');
  const url = parts.join('/');
  return searchParams ? `${url}?${searchParams}` : url;
};

const createOptions = <TResponse, TQueryKey extends QueryKey = QueryKey>(
  options: QueryOptions<TResponse, TQueryKey> | undefined = {}
) => {
  const defaultOptions: QueryOptions<TResponse, TQueryKey> = {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: false,
  };
  return {
    ...defaultOptions,
    ...options,
  } as QueryOptions<TResponse, TQueryKey>;
};

export type TemplatesBackendQueryOptions<TResponse, TQueryKey extends QueryKey = QueryKey> = {
  relativeUrl?: string;
  searchParams?: URLSearchParams;
  queryKey: TQueryKey;
  options?: QueryOptions<TResponse, TQueryKey>;
};

export const useTemplatesBackendQuery = <TResponse, TQueryKey extends QueryKey = QueryKey>(
  options: TemplatesBackendQueryOptions<TResponse, TQueryKey>
) =>
  useBackendQuery(
    createUrlFromParts(options.relativeUrl, options.searchParams),
    options.queryKey,
    createOptions(options.options)
  );

export const useTemplatesFileUploadMutation = <TRequest, TResponse, TContext = unknown>(
  relativeUrl: string,
  getFormData: (request: TRequest) => FormData,
  options: MutationOptions<TRequest, TResponse, TContext> | undefined = { method: 'POST' }
) => {
  const fetchBackend = useBackendFetch(false);
  const requestUrl = createUrlFromParts(relativeUrl);

  const { method = 'POST', ...rest } = options;

  const mutationFn = useCallback(
    async (form: TRequest): Promise<TResponse> => {
      const requestMethod =
        method === undefined ? 'POST' : typeof method === 'string' ? method : method(form);
      const response = await fetchBackend(requestUrl, {
        method: requestMethod,
        body: getFormData(form),
      });

      if (response.status === 400) {
        const validationErrors: ValidationProblemDetails | undefined = await response.json();
        throw validationErrors ?? { errors: { '': ['Ошибка'] } };
      }
      if (response.status === 500) {
        const error: ValidationProblemDetails = { errors: { '': ['Ошибка'] } };
        throw error;
      }

      const result: TResponse = await response.json();
      return result;
    },
    [fetchBackend, method, requestUrl, getFormData]
  );

  return useMutation(mutationFn, rest);
};

export const useTemplatesMutation = <TRequest, TContext = unknown>(
  templateId: string | ((form: TRequest) => string),
  url: string,
  options: MutationOptions<TRequest, EmptyResponse, TContext> | undefined = { method: 'POST' }
) => useBackendMutation(`${calculationUrl}/api/v1/templates/${templateId}${url}`, options);

export type UseTemplatesQueryProps = {
  quotaId?: number;
  kind: TemplateKind;
  shipmentItemId?: number;
};

export const useTemplatesQuery = (props: UseTemplatesQueryProps) => {
  const { kind, quotaId, shipmentItemId } = props;

  let queryKey = ['templates', kind, quotaId];
  let relativeUrl = '';
  if (kind === TemplateKind.Pipeline) {
    relativeUrl += '/pipeline';
  } else if (kind === TemplateKind.Quotas) {
    relativeUrl += '/quotas';
  } else {
    relativeUrl += `/${kind}/quotas/${quotaId}`;
    if (shipmentItemId) {
      queryKey.push(shipmentItemId);
      relativeUrl += `/shipment/${shipmentItemId}`;
    }
  }

  return useTemplatesBackendQuery<TemplatePrintViewModel[]>({
    relativeUrl,
    queryKey,
  });
};

export type UsePrintTemplateProps = {
  template: TemplatePrintViewModel;
  onClose?: () => void;
};

export const useDownloadReport = <TRequest = TemplateDataFilter>(props: UsePrintTemplateProps) => {
  const { template, onClose } = props;
  const { fileName, printUrl } = template;

  const fetchBackend = useBackendFetch();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const downloadAsync = useCallback(
    async (values?: TRequest) => {
      setIsLoading(true);
      try {
        const response = await fetchBackend(`${calculationUrl}${printUrl}`, {
          method: 'POST',
          body: values ? JSON.stringify(values) : undefined,
        });

        if (response.status !== 200) {
          setIsError(true);
          if (onClose) {
            onClose();
          }
          return;
        }

        let realFileName = fileName;
        const header = response.headers.get('content-disposition');
        if (header !== null) {
          realFileName =
            header
              .split(';')
              .find((n) => n.includes("filename*=UTF-8''"))
              ?.replace("filename*=UTF-8''", '')
              .trim() ?? fileName;

          realFileName = decodeURIComponent(decodeURI(realFileName));
        }
        const result = await response.blob();
        const blob = new Blob([result], {
          type: result.type,
        });
        const newUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = newUrl;
        a.download = realFileName;
        document.body.appendChild(a);
        a.click();
      } catch (e) {
        console.log(e);
        setIsError(true);
      } finally {
        if (onClose) {
          onClose();
        }
        setIsLoading(false);
      }
    },
    [fileName, printUrl, fetchBackend, onClose]
  );

  return { isLoading, isError, downloadAsync };
};

export const useSendTemplateByEmailMutation = <TRequest, TContext = unknown>(
  templateId: string,
  quotaId: string,
  options: MutationOptions<TRequest, EmptyResponse, TContext> | undefined = { method: 'POST' }
) =>
  useBackendMutation(
    `${calculationUrl}/api/v1/templates/${templateId}/quota/${quotaId}/sendMailToClient`,
    options
  );
