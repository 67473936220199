import { LeasingProductItemViewModel } from 'schema';
import { useDictionaryBackendQuery } from 'services';

const LeasingProducts = 'leasingProducts';

export const useLeasingProductQuery = () => {
  let requestUrl = LeasingProducts;

  const { data: options = [] } =
    useDictionaryBackendQuery<LeasingProductItemViewModel[]>(requestUrl);

  return {
    options: options.map((t) => t.name),
  };
};
