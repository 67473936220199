import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useController, useWatch, FieldError, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { WikiFileViewModel } from 'schema';
import { FileList } from './FileList';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      padding: theme.spacing(1.5, 1.25, 1),
    },
    empty: {
      display: 'none',
    },
    full: {
      backgroundColor: theme.palette.lightBlue3.main,
      color: theme.palette.primary.main,
      width: '100%',
      display: 'block',
      marginBottom: theme.spacing(1),
    },
    actions: {
      position: 'absolute',
      right: 1,
      top: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      '& *': {
        marginRight: 9,
        cursor: 'pointer',
      },
    },
    error: {
      color: theme.palette.chartPurple.main,
      fontSize: 9,
      margin: '4px 0 0',
    },
    errorField: {
      border: `1px solid ${theme.palette.chartPurple.main}`,
    },
    choose: {
      marginRight: 0,
    },
    addFile: {
      display: 'block',
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(1.5),
      color: theme.palette.primary.main,
      fontWeight: 600,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    hidden: {
      backgroundColor: 'red',
      margin: 0,
      padding: 0,
      opacity: 0,
      height: 0,
      width: 0,
      overflow: 'hidden',
    },
  })
);

export type FileUploadFormValues = {
  files: File[];
};

export type FileUploadProps = {
  control: any;
  setValue: UseFormSetValue<any>;
  required?: boolean;
  oldFiles?: WikiFileViewModel[];
};

export const FileUpload2 = (props: FileUploadProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { control, setValue, required = false, oldFiles = [] } = props;

  const files = useWatch({
    control,
    name: 'files',
  });

  const attachments =
    useWatch({
      control,
      name: 'attachments',
    }) ?? [];

  const {
    field: { onChange },
    fieldState: { error },
  } = useController({
    control,
    name: 'files',
    rules:
      required && files?.length === 0
        ? {
            required: {
              value: true,
              message: t('Required'),
            },
          }
        : undefined,
  });

  const [errors, setErrors] = useState('');

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => {
      let newFiles: File[] = files;
      acceptedFiles.forEach((file) => {
        newFiles = [...newFiles, file];
      });
      onChange(newFiles);
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === 'file-too-large') {
            setErrors(`Максимально допустимый размер 10 Мб.`);
          }
          if (err.code === 'file-invalid-type') {
            setErrors(`Недопустимый тип файла.`);
          }
        });
      });
    },
    accept: [
      'image/jpeg',
      'image/pjpeg',
      'image/gif',
      'image/png',
      'image/vnd.wap.wbmp',
      '.bmp',
      'application/pdf',
      '.doc',
      '.docx',
      'video/mp4',
      'video/quicktime',
      'video/mpeg',
      'video/x-msvideo',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      '.xlsx',
    ],
    multiple: true,
    maxSize: 10485760,
  });

  const isEmpty = files?.length === 0 || !files;
  const modeClass = isEmpty ? classes.empty : '';
  const requiredError = error !== undefined ? (error as FieldError).message : undefined;

  const [wikiFiles, setWikiFiles] = useState([...oldFiles]);
  const [deletedWikiFiles, setDeletedWikiFiles] = useState<number[]>([]);

  const deleteFileHandler = (fileName: string, ev: React.MouseEvent<HTMLDivElement>) => {
    ev.stopPropagation();
    const newFiles = files.filter((f: any) => f.name !== fileName);
    onChange(newFiles);
    const deletedWikiFile = wikiFiles.find((f: WikiFileViewModel) => f.fileName === fileName);
    if (deletedWikiFile) {
      setDeletedWikiFiles([...deletedWikiFiles, deletedWikiFile.id]);
      setValue('attachments', [...attachments, deletedWikiFile.id]);
    }
    setWikiFiles(wikiFiles.filter((f: any) => f.fileName !== fileName));
  };

  const combinedFiles = [...wikiFiles, ...files];

  return (
    <>
      <FileList files={combinedFiles} editMode onDelete={deleteFileHandler} />
      <div {...getRootProps()} className={clsx(classes.root, modeClass)}>
        <input {...getInputProps()} />
        {/*{files.map((file: any) =>*/}
        {/*  <div className={clsx(classes.root, classes.full, errorClass)} key={file.name}>*/}
        {/*    <label>{file.name}</label>*/}
        {/*    <div className={classes.actions}>*/}
        {/*      <div onClick={(ev: React.MouseEvent<HTMLDivElement>) => deleteFileHandler(file.name, ev)}>*/}
        {/*        <IconSprite*/}
        {/*          icon="delete2"*/}
        {/*          width="14px"*/}
        {/*          height="14px"*/}
        {/*          color={theme.palette.text.primary}*/}
        {/*          hoverColor={theme.palette.primary.main}*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>

      <p className={classes.error}>
        {requiredError}
        {errors}
      </p>

      <div onClick={open} className={classes.addFile}>
        + {t('Attach a file')}
      </div>
    </>
  );
};
