import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { AddIssueCommentModel } from 'schema/serverTypes';
import { Button } from '../Button';
import { useIssuesFileUploadMutation } from '../../services/api';
import { AddCommentFieldValues } from './types';
import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { createQuotasBackendUrl } from '../../services/api/createQuotasBackendUrl';
import { WysiwygEditor } from './WysiwygEditor';
import clsx from 'clsx';
import { FormHelperText } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.bgGray.main,
      padding: theme.spacing(0),
    },
    actions: {
      backgroundColor: theme.palette.bgGray.main,
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%',
      padding: theme.spacing(1, 2, 0, 0),
    },
    actionButton: {
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
    button: {
      display: 'block',
      margin: '4px auto 0',
    },
    save: {
      backgroundColor: 'white',
    },
    cancel: {
      color: theme.palette.primary.main,
      fontSize: 12,
      fontWeight: 600,
      textDecoration: 'none',
      display: 'inline-flex',
      alignItems: 'center',
      height: '100%',
    },
  })
);

export type CommentEditFormProps = {
  issueId: number;
  commentId: number;
  text: string;
  onSuccess?: () => void;
  editModeHandler: () => void;
};

export const CommentEditForm = (props: CommentEditFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { editModeHandler, issueId, commentId, text } = props;
  const { control, handleSubmit } = useForm<AddCommentFieldValues>({
    mode: 'all',
    defaultValues: {
      comment: text,
      file: null,
    },
  });
  const queryClient = useQueryClient();

  const getFormData = (values: AddCommentFieldValues) => {
    const formData = new FormData();
    if (values.comment !== '') {
      formData.append('text', values.comment);
    }
    if (values.file !== null) {
      formData.append('file', values.file);
    }
    return formData;
  };

  const { mutateAsync } = useIssuesFileUploadMutation<AddCommentFieldValues, AddIssueCommentModel>(
    `${issueId}/comments/${commentId}`,
    getFormData,
    {
      method: 'PUT',
      onSuccess: () => {
        const queryKey = createQuotasBackendUrl(`/${issueId}`);
        queryClient.invalidateQueries(queryKey);
        editModeHandler();
      },
    }
  );

  const onSubmit = useMemo(() => {
    const submit = async (values: AddCommentFieldValues) => {
      const comment = await mutateAsync(values);
      const queryKey = createQuotasBackendUrl(`/${props.issueId}`);
      if (comment) {
        await queryClient.invalidateQueries(queryKey);
      }
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync, props.issueId, queryClient]);

  return (
    <form className={classes.root} onSubmit={onSubmit}>
      <Controller
        name="comment"
        control={control}
        render={({ field, fieldState }) => {
          const helperText =
            fieldState.error !== undefined ? (fieldState.error as FieldError).message : undefined;

          return (
            <>
              <WysiwygEditor value={field.value} onChange={field.onChange} />
              {helperText && <FormHelperText error>{helperText}</FormHelperText>}
            </>
          );
        }}
        rules={{
          required: true,
          maxLength: {
            value: 10000,
            message: t('Maximum number of characters is', { count: 10000 }),
          },
        }}
      />
      <div className={classes.actions}>
        <div className={classes.actionButton}>
          <Button
            className={clsx(classes.button, classes.save)}
            color="primary"
            size="medium"
            type="submit"
            variant="outlined"
          >
            {t('Save')}
          </Button>
        </div>

        <div className={classes.actionButton}>
          <Button
            className={clsx(classes.button, classes.cancel)}
            color="primary"
            size="medium"
            type="button"
            variant="text"
            onClick={editModeHandler}
          >
            {t('Cancel2')}
          </Button>
        </div>
      </div>
    </form>
  );
};
