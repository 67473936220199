import { createTheme } from '@material-ui/core';
import paletteOrange from './paletteOrange';
import typography from './typography';
import overrides from './overrides';

export const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 1200,
    lg: 1440,
    xl: 1920,
  },
};

export const themeOrange = createTheme({
  palette: paletteOrange,
  typography,
  overrides,
  zIndex: {
    appBar: 1201,
    drawer: 1100,
  },
  breakpoints,
});
