import React, { useCallback, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Breadcrumbs, Paging } from 'components';
import { ModalForm, useModalForm } from '../../Modal/ModalForm';
import { AddButton } from '../AddButton';
import { IssueControl } from 'schema/serverTypes';
import { useIssueControlData } from './useIssueControlData';
import { IssueControlsFilterForm } from './IssueControlsFilterForm';
import { IssueControlsTable } from './IssueControlsTable';
import { EditIssueControlForm } from './EditIssueControlForm';
import { AddIssueControlForm } from './AddIssueControlForm';
import { useTranslation } from 'react-i18next';
import { PageTitle } from 'components/utils/pageTitle';

export const IssueControlsDataGrid = () => {
  const { t } = useTranslation();
  const { filter, paging, sorting, ...dataProps } = useIssueControlData();
  const { onOpen, onClose, open } = useModalForm();
  const [issueControl, setIssueControl] = useState<IssueControl>();

  const pageTitle = t('Dictionaries.IssueControls') + ' - Bumblebee';
  PageTitle(pageTitle);

  const handleOnOpen = useCallback(
    (item: IssueControl) => {
      setIssueControl(item);
      onOpen();
    },
    [onOpen],
  );

  const handleOnClose = useCallback(() => {
    setIssueControl(undefined);
    onClose();
  }, [onClose]);

  return (
    <>
      <Breadcrumbs link={'/dictionaries'} text={t('Section.dictionaries')} />
      <Box display="flex" justifyContent="space-between" mb={2.5} alignItems="flex-end">
        <Box>
          <IssueControlsFilterForm {...filter} />
        </Box>
        <Box>
          <AddButton onClick={onOpen} />
        </Box>
      </Box>
      <Grid container item direction="column">
        <Grid item>
          <IssueControlsTable {...dataProps} sorting={sorting} onOpen={handleOnOpen} />
        </Grid>
        <Grid item>
          <Paging {...paging} />
        </Grid>
      </Grid>
      <ModalForm open={open} onClose={handleOnClose}>
        {issueControl && (
          <EditIssueControlForm onSuccess={handleOnClose} issueControl={issueControl} />
        )}
        {issueControl === undefined && <AddIssueControlForm onSuccess={handleOnClose} />}
      </ModalForm>
    </>
  );
};
