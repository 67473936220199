import { MenuItem } from '@material-ui/core';
import { Grid } from 'components/Grid';
import { useTranslation } from 'react-i18next';
import { Input, Select, DatePicker } from 'components/form';
import { Head } from 'schema';
import { useWatch } from 'react-hook-form';

export type SignerProps = {
  item?: Head;
  control: any;
  type: 'dealer' | 'lessee';
};

export const Signer = (props: SignerProps) => {
  const { t } = useTranslation();
  const { control, type } = props;

  const reason = useWatch({ control, name: `${type}.head.reason` });
  const isStatute = reason === 'Statute';
  return (
    <>
      <Grid item md={7} xs={24}>
        <Input
          label={t('LastName')}
          name={`${type}.head.lastName` as const}
          control={control}
          rules={{
            required: {
              value: true,
              message: t('Required'),
            },
          }}
        />
      </Grid>
      <Grid item md={7} xs={24}>
        <Input
          label={t('FirstName')}
          name={`${type}.head.firstName` as const}
          control={control}
          rules={{
            required: {
              value: true,
              message: t('Required'),
            },
          }}
        />
      </Grid>
      <Grid item md={7} xs={24}>
        <Input
          label={t('MiddleName')}
          name={`${type}.head.middleName` as const}
          control={control}
          rules={{
            required: {
              value: true,
              message: t('Required'),
            },
          }}
        />
      </Grid>
      <Grid item md={3} xs={24}>
        <Input
          label={t('Initials')}
          name={`${type}.head.initials` as const}
          control={control}
          rules={{
            required: {
              value: true,
              message: t('Required'),
            },
          }}
        />
      </Grid>
      <Grid item md={8} xs={24}>
        <Input
          label={t('Position')}
          name={`${type}.head.position` as const}
          control={control}
          rules={{
            required: {
              value: true,
              message: t('Required'),
            },
          }}
        />
      </Grid>
      <Grid item md={8} xs={24}>
        <Select
          label={t('Reason')}
          name={`${type}.head.reason` as const}
          control={control}
          rules={{
            required: {
              value: true,
              message: t('Required'),
            },
          }}
        >
          <MenuItem value={'Statute'}>{t('Statute')}</MenuItem>
          <MenuItem value={'Power of attorney'}>{t('Power of attorney')}</MenuItem>
        </Select>
      </Grid>
      {!isStatute && (
        <>
          <Grid item md={4} xs={24}>
            <Input
              label={t('Number')}
              name={`${type}.head.number` as const}
              control={control}
              rules={{
                required: {
                  value: !isStatute,
                  message: t('Required'),
                },
              }}
            />
          </Grid>
          <Grid item md={4} xs={24}>
            <DatePicker
              label={t('Date')}
              name={`${type}.head.date` as const}
              control={control}
              rules={{
                required: {
                  value: !isStatute,
                  message: t('Required'),
                },
              }}
            />
          </Grid>
        </>
      )}
    </>
  );
};
