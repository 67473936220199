import { useTranslation } from 'react-i18next';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Button } from 'components/Button';
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import dayjs from 'dayjs';
import { ModalForm, useModalForm } from '../../Modal';
import { ShipmentRangeFilterForm } from './ShipmentRangeFilterForm';
import { useLanguage } from '../../utils/useLanguage';
import {
  endOfMonth,
  endOfNextMonth,
  endOfYear,
  startOfMonth,
  startOfNextMonth,
  startOfYear,
} from './dates';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: theme.spacing(1.5, 2.5),
    },
    active: {
      backgroundColor: theme.palette.primary.light,
      pointerEvents: 'none',
      color: theme.palette.secondary.darker,
      '&$calendar': {
        pointerEvents: 'initial',
        '&:hover': {
          color: theme.palette.primary.main,
        },
      },
    },
    calendar: {},
    nextMonth: {
      textTransform: 'capitalize',
    },
  })
);

export interface ShipmentFilterProps {
  setStartDate: (date: string | undefined) => void;
  setEndDate: (date: string | undefined) => void;
  setExpiredOnly: (expiredOnly: boolean) => void;
}

export const ShipmentFilter = (props: ShipmentFilterProps) => {
  const classes = useStyles();
  const [currentFilter, setCurrentFilter] = useState<string | null>('thisMonth');
  const { t } = useTranslation();
  const lang = useLanguage();

  const [from, setFrom] = useState(startOfYear);
  const [to, setTo] = useState(endOfYear);
  const activeClass = classes.active;
  const { setStartDate, setEndDate, setExpiredOnly } = props;
  const { onOpen, onClose, open } = useModalForm();

  const thisMonthHandler = useCallback(() => {
    setStartDate(startOfMonth);
    setEndDate(endOfMonth);
    setExpiredOnly(false);
    setCurrentFilter('thisMonth');
  }, [setStartDate, setEndDate, setExpiredOnly, setCurrentFilter]);

  const nextMonthHandler = useCallback(() => {
    setStartDate(startOfNextMonth);
    setEndDate(endOfNextMonth);
    setExpiredOnly(false);
    setCurrentFilter('nextMonth');
  }, [setStartDate, setEndDate, setExpiredOnly, setCurrentFilter]);

  const customRangeHandler = useCallback(
    (from, to) => {
      setStartDate(dayjs(from).toISOString());
      setEndDate(dayjs(to).toISOString());
      setFrom(from);
      setTo(to);
      setExpiredOnly(false);
      setCurrentFilter('customRange');
    },
    [setStartDate, setEndDate, setExpiredOnly, setCurrentFilter]
  );

  const thisExpiredOnlyHandler = useCallback(() => {
    setExpiredOnly(true);
    setStartDate(undefined);
    setEndDate(undefined);
    setCurrentFilter('expiredOnly');
  }, [setExpiredOnly, setStartDate, setEndDate, setCurrentFilter]);

  const thisMonthClass = clsx(
    currentFilter === null || currentFilter === 'thisMonth' ? activeClass : ''
  );
  const nextMonthClass = clsx(classes.nextMonth, currentFilter === 'nextMonth' ? activeClass : '');
  const customRangeClass = clsx(
    classes.calendar,
    currentFilter === 'customRange' ? activeClass : ''
  );
  const expiredOnlyClass = clsx(currentFilter === 'expiredOnly' ? activeClass : '');
  return (
    <>
      <Box my={2}>
        <Button variant={'chip'} className={thisMonthClass} onClick={thisMonthHandler}>
          {t('This month')}
        </Button>
        <Button variant={'chip'} className={nextMonthClass} onClick={nextMonthHandler}>
          {dayjs().add(1, 'month').locale(lang).format('MMMM')}
        </Button>
        <Button variant={'chip'} className={customRangeClass} onClick={onOpen}>
          {dayjs(from).locale(lang).format('DD MMM YYYY')} -{' '}
          {dayjs(to).locale(lang).format('DD MMM YYYY')}
        </Button>
        <Button variant={'chip'} className={expiredOnlyClass} onClick={thisExpiredOnlyHandler}>
          {t('Expired')}
        </Button>
      </Box>
      <ModalForm open={open} onClose={onClose} width={980}>
        <ShipmentRangeFilterForm
          from={from}
          to={to}
          customRangeHandler={customRangeHandler}
          onClose={onClose}
        />
      </ModalForm>
    </>
  );
};
