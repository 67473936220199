import { createStyles, makeStyles, TableCell, TableRow, Theme } from '@material-ui/core';
import { IssueControlDashboardItem, ScoringIssueDashboardItem } from 'schema/serverTypes';
import { IconSprite } from '../../icons';
import { Link, useHistory } from 'react-router-dom';
import { themeOrange as theme } from 'theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      cursor: 'pointer',
      position: 'relative',
      marginBottom: theme.spacing(1),
      '&& td:first-child': {
        borderRadius: '4px 0 0 4px',
      },
      '&& td:last-child': {
        borderRadius: '0 4px 4px 0',
      },
      '&& td': {
        padding: theme.spacing(1),
        verticalAlign: 'middle',
        fontSize: 12,
        background: (props: { issueControls: IssueControlDashboardItem[] }) => {
          return props.issueControls.reduce((sum, current) => sum && current.value, true)
            ? '#EEFAE9'
            : theme.palette.background.default;
        },
        '& svg': {
          verticalAlign: 'middle',
        },
      },
      '&& a': {
        color: theme.palette.primary.main,
      },
    },
    radio: {
      textAlign: 'center',
    },
  })
);

export const InsideSalesItem = (props: ScoringIssueDashboardItem) => {
  const classes = useStyles(props);
  const { id, assignName, authorName, leaseeName, issueControls } = props;

  const isSuccess = issueControls.reduce((sum, current) => sum && current.value, true);

  const issueControlsValues = issueControls.map((item) => {
    return (
      <TableCell className={classes.radio} key={item.text}>
        {item.value ? (
          <IconSprite
            width={16}
            icon={'plus'}
            color={isSuccess ? theme.palette.success.main : theme.palette.primary.main}
          />
        ) : (
          <IconSprite width={16} icon={'minus'} color={theme.palette.text.secondary} />
        )}
      </TableCell>
    );
  });

  const { push } = useHistory();
  const clickHandler = () => {
    push(`/tasks/${id}`);
  };

  return (
    <TableRow className={classes.root} onClick={clickHandler}>
      <TableCell>
        <Link to={`/tasks/${id}`}>{id}</Link>
      </TableCell>
      <TableCell>{assignName}</TableCell>
      <TableCell>{authorName}</TableCell>
      <TableCell>{leaseeName}</TableCell>
      {issueControlsValues}
    </TableRow>
  );
};
