import {
  PageOptions,
  CountryRiskFilter,
  CountryRiskSorting,
  CountryRiskPagedList,
  AddCountryRiskRequest,
  CountryRiskViewModel,
  EditCountryRiskRequest,
} from 'schema/serverTypes';
import { BackendQueryOptions, createOptions, createUrlFromParts } from './types';
import { useBackendQuery, useBackendMutation, MutationOptions } from './useBackend';
import { useQueryClient } from 'react-query';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../components';
import { useHistory } from 'react-router';

const entity = 'dictionaries/countryRisks';

const useCountryRiskQuery = <TResponse>(options: BackendQueryOptions<TResponse>) => {
  let queryKey = entity;
  if (options.relativeUrl && options.relativeUrl !== '') {
    queryKey = `${queryKey}-${options.relativeUrl}`;
  }
  if (options.searchParams) {
    queryKey = `${queryKey}-${options.searchParams}`;
  }
  return useBackendQuery(
    createUrlFromParts(entity, options.relativeUrl, options.searchParams),
    queryKey,
    createOptions(options.options)
  );
};

type UseCountryRiskMutationProps<TRequest, TResponse> = MutationOptions<TRequest, TResponse> & {
  relativeUrl?: string;
};

const useCountryRiskMutation = <TRequest, TResponse>(
  options: UseCountryRiskMutationProps<TRequest, TResponse> | undefined = undefined
) => {
  const relativeUrl = options?.relativeUrl;

  return useBackendMutation(createUrlFromParts(entity, relativeUrl), {
    method: 'POST',
    ...options,
  });
};

export type UseCountryRiskPagedListQueryProps = CountryRiskFilter &
  Partial<CountryRiskSorting> &
  PageOptions;

export const useCountryRiskPagedListQuery = (props: UseCountryRiskPagedListQueryProps) => {
  const { name = '', order, sortBy, page = 1, pageSize = 20 } = props;
  let searchParams = new URLSearchParams();

  if (name !== '') {
    searchParams.append('name', name);
  }

  if (sortBy) {
    searchParams.append('sortBy', sortBy);
  }

  if (order) {
    searchParams.append('order', order);
  }

  searchParams.append('page', page.toString());
  searchParams.append('pageSize', pageSize.toString());

  return useCountryRiskQuery<CountryRiskPagedList>({
    searchParams,
    options: {
      refetchOnMount: true,
    },
  });
};

const useInvalidateQuery = () => {
  const queryClient = useQueryClient();

  return useCallback(() => {
    queryClient.invalidateQueries({
      predicate: (query) => {
        return query.queryKey.indexOf(entity) > -1;
      },
    });
  }, [queryClient]);
};

export const useAddCountryRiskMutation = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const invalidate = useInvalidateQuery();
  const history = useHistory();

  return useCountryRiskMutation<AddCountryRiskRequest, CountryRiskViewModel>({
    onSuccess: (response) => {
      invalidate();
      toast(t('SuccessMessage'), 'success');
      history.push('/dictionaries/countryRisks');
    },
    onError: (_response) => {
      toast(t('ErrorMessage'), 'error');
    },
  });
};

export const useEditCountryRiskMutation = (id: number) => {
  const { t } = useTranslation();
  const toast = useToast();
  const invalidate = useInvalidateQuery();

  return useCountryRiskMutation<EditCountryRiskRequest, CountryRiskViewModel>({
    relativeUrl: id.toString(),
    method: 'PUT',
    onSuccess: (response) => {
      invalidate();
      toast(t('SuccessMessage'), 'success');
    },
    onError: (_response) => {
      toast(t('ErrorMessage'), 'error');
    },
  });
};

export const useCountryRiskByIdQuery = (id: number) => {
  return useCountryRiskQuery<CountryRiskViewModel>({
    relativeUrl: id.toString(),
  });
};
