import { createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { formatNumber } from '../../../utils';
import { themeOrange as theme } from 'theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      width: 8,
      height: 20,
      top: -2,
      left: (props: any) => {
        return `calc(${(props.value / props.max) * 100}% - 4px)`;
      },
      color: theme.palette.text.primary,
      fontSize: 12,
    },
    label: {
      position: 'absolute',
      transform: 'translateX(-50%)',
      top: 33,
      left: 4,
      fontSize: 8,
      whiteSpace: 'nowrap',
      '&$right': {
        transform: 'translateX(0)',
      },
      '&$left': {
        transform: 'translateX(-100%)',
      },
    },
    value: {
      position: 'absolute',
      transform: 'translateX(-50%)',
      top: 22,
      left: 4,
      '&$right': {
        transform: 'translateX(0)',
      },
      '&$left': {
        transform: 'translateX(-100%)',
      },
    },
    right: {},
    left: {},
    top: {},
    bottom: {},
  })
);

type RangeMarkProps = {
  max: number;
  value: number;
  position: 'top' | 'bottom' | 'right' | 'left';
  label: string;
};

export const RangeMark = (props: RangeMarkProps) => {
  const classes = useStyles(props);
  const { value, position, label } = props;

  return (
    <div className={classes.root}>
      <div className={clsx(classes.value, classes[position])}>{formatNumber(value, 2, true)}%</div>
      <div className={clsx(classes.label, classes[position])}>{label}</div>
      <svg width="8" height="20" viewBox="0 0 8 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="2" y="2" width="4" height="18" fill="white" />
        <path d="M3 0H5V17L8 20H6L4 18L2 20H0L3 17V0Z" fill={theme.palette.text.primary} />
      </svg>
    </div>
  );
};
