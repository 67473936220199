import { ApplicationRole } from 'schema';
import { RoleName } from 'schema/RoleName';
import { useUserAuth } from './useUserAuth';

export const useUserRole = () => {
  const { user, userManager } = useUserAuth();

  if (user === null) {
    return {
      role: undefined,
      isAdmin: false,
      isSuperSalesManager: false,
      isSalesManager: false,
      isSalesSupport: false,
      isRiskManager: false,
      isAssetManager: false,
      isInsideSales: false,
      hasRole: false,
      userManager,
      id: null,
    };
  }

  const role = user.profile.role as RoleName;

  return {
    role,
    isAdmin: role === 'admin',
    isSuperSalesManager: role === 'super_sales_manager',
    isSalesManager: role === 'sales_manager',
    isSalesSupport: role === 'sales_support',
    isRiskManager: role === ApplicationRole.RiskManager,
    isAssetManager: role === ApplicationRole.AssetManager,
    isInsideSales: role === ApplicationRole.InsideSales,
    hasRole: !(role === undefined || (role as string) === ''),
    userManager,
    id: user.profile.sub,
  };
};
