import { IssueControl, IssueType } from 'schema/serverTypes';
import { MenuItem, Typography } from '@material-ui/core';
import { Button, Grid, Input, Select } from 'components';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useUpdateIssueControlMutation } from 'services/api/useIssueControlBackend';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(5.5),
    },
    button: {
      display: 'block',
      margin: '4px auto 0',
    },
    required: {
      marginTop: theme.spacing(-1.5),
      color: theme.palette.text.primary,
    },
  })
);

export type EditIssueControlFormProps = {
  issueControl: IssueControl;
  onSuccess: () => void;
};

const useIssueControlForm = (
  issueControl: IssueControl,
  message: string,
  onSuccess: () => void
) => {
  const { handleSubmit, control, formState, setError, clearErrors, reset, setValue } =
    useForm<IssueControl>({
      mode: 'onBlur',
      defaultValues: issueControl,
    });

  const { mutateAsync } = useUpdateIssueControlMutation(issueControl.id);

  const onSubmit = useMemo(() => {
    const submit = async (form: IssueControl) => {
      try {
        await mutateAsync({
          text: form.text,
          issueType: form.issueType,
          sortOrderNumber: form.sortOrderNumber,
        });
        onSuccess();
      } catch (error) {
        setError('text', { message }, { shouldFocus: true });
      }
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync, setError, message, onSuccess]);

  return {
    control,
    onSubmit,
    reset,
    setValue,
    setError,
    clearErrors,
    ...formState,
  };
};

export const EditIssueControlForm = (props: EditIssueControlFormProps) => {
  const classes = useStyles();
  const { issueControl, onSuccess } = props;
  const { t } = useTranslation();
  const message = t('Could not update');
  const { onSubmit, isSubmitting, control } = useIssueControlForm(issueControl, message, onSuccess);

  return (
    <form>
      <Typography variant={'h2'} className={classes.title}>
        {t('Edit')}
      </Typography>
      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item md={24} xs={24}>
          <Select name="issueType" label={t('Type')} variant="outlined" control={control}>
            <MenuItem value={IssueType.Error}>{t('IssueType.Error')}</MenuItem>
            <MenuItem value={IssueType.Rework}>{t('IssueType.Rework')}</MenuItem>
            <MenuItem value={IssueType.Contract}>{t('IssueType.Contract')}</MenuItem>
            <MenuItem value={IssueType.Telematics}>{t('IssueType.Telematics')}</MenuItem>
            <MenuItem value={IssueType.Shipment}>{t('IssueType.Shipment')}</MenuItem>
            <MenuItem value={IssueType.Payment}>{t('IssueType.Payment')}</MenuItem>
            <MenuItem value={IssueType.Verification}>{t('IssueType.Verification')}</MenuItem>
            <MenuItem value={IssueType.ShipmentReport}>{t('IssueType.ShipmentReport')}</MenuItem>
            <MenuItem value={IssueType.Nomenclature}>{t('IssueType.Nomenclature')}</MenuItem>
            <MenuItem value={IssueType.Scoring}>{t('IssueType.Scoring')}</MenuItem>
          </Select>
        </Grid>
        <Grid item md={24} xs={24}>
          <Input label={t('Text')} control={control} name="text" />
        </Grid>
        <Grid item md={24} xs={24}>
          <Input label={t('Sort order')} control={control} name="sortOrderNumber" />
        </Grid>
        <Grid item md={24} xs={24}>
          <Button
            className={classes.button}
            color="primary"
            size="medium"
            type="submit"
            variant="contained"
            disabled={isSubmitting}
            onClick={onSubmit}
          >
            {t('Save')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
