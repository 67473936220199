import { useContractListRequest } from 'services/api';
import { ContractListRequest, ContractSortBy, SortOrder } from 'schema/serverTypes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ContractFilterFormProps, ContractFilterFormValues } from './types';
import { useForm, useWatch } from 'react-hook-form';
import { usePaging } from 'components/paging';

export const useContractsListData = (defaultValues?: ContractFilterFormValues) => {
  const filterParams = sessionStorage.contractsFilterValues
    ? JSON.parse(sessionStorage.contractsFilterValues)
    : null;

  const { control, reset, setValue } = useForm<ContractFilterFormValues>({
    mode: 'all',
    defaultValues: { ...defaultValues, ...filterParams },
  });

  const [sortBy, setSortBy] = useState<ContractSortBy>(ContractSortBy.Date);
  const [order, setOrder] = useState(SortOrder.desc);

  const onReset = useCallback(() => {
    reset({});
  }, [reset]);

  const { page, pageSize, onPageChanged, onPageSizeChanged } = usePaging();
  const lesseeInn = useWatch({ control, name: 'lesseeInn' });
  const dealerInn = useWatch({ control, name: 'dealerInn' });
  const contractNumber = useWatch({ control, name: 'contractNumber' });
  const status = useWatch({ control, name: 'status' });
  const tabId = useWatch({ control, name: 'tabId' });

  const params = useMemo(() => {
    return {
      lesseeInn,
      dealerInn,
      contractNumber,
      status,
      tabId,
      pageSize,
      page,
    };
  }, [lesseeInn, dealerInn, contractNumber, status, tabId, pageSize, page]);

  useEffect(() => {
    onPageChanged(1);
  }, [lesseeInn, dealerInn, contractNumber, status, tabId, onPageChanged]);

  useEffect(() => {
    sessionStorage.setItem('contractsFilterValues', JSON.stringify(params));
  }, [params]);

  const lesseeInns = useMemo(() => {
    return lesseeInn?.map((t) => t.lesseeInn);
  }, [lesseeInn]);

  const dealerInns = useMemo(() => {
    return dealerInn?.map((t) => t.dealerInn);
  }, [dealerInn]);

  const request: ContractListRequest = useMemo(() => {
    return {
      tabId,
      contractNumber,
      lesseeInn: lesseeInns,
      dealerInn: dealerInns,
      page,
      pageSize,
      sortBy,
      order,
      status,
    };
  }, [tabId, contractNumber, lesseeInns, dealerInns, page, pageSize, sortBy, order, status]);

  const { isLoading, data: list } = useContractListRequest(request);

  const handleSortBy = useCallback(
    (sortBy: ContractSortBy) => {
      setSortBy(sortBy);
    },
    [setSortBy]
  );

  const handleSortOrder = useCallback(
    (order: SortOrder) => {
      setOrder(order);
    },
    [setOrder]
  );

  return useMemo(() => {
    const rows = list?.data ?? [];
    const pageCount = list?.pageCount ?? 0;
    const totalCount = list?.totalCount ?? 0;

    return {
      isLoading,
      rows,
      sorting: {
        sortBy,
        order,
        setSortBy: handleSortBy,
        setOrder: handleSortOrder,
      },
      paging: {
        pageCount,
        totalCount,
        page,
        pageSize,
        dataCount: rows.length,
        onPageChanged,
        onPageSizeChanged,
      },
      filter: {
        control,
        onReset,
        setValue,
      } as ContractFilterFormProps,
    };
  }, [
    isLoading,
    list,
    page,
    pageSize,
    control,
    sortBy,
    order,
    onReset,
    setValue,
    handleSortBy,
    handleSortOrder,
    onPageChanged,
    onPageSizeChanged,
  ]);
};
