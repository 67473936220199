import { Grid } from 'components';
import { LesseeAutocompleteProps } from './types';
import { LesseeNameAutocomplete } from './LesseeNameAutocomplete';
import { LesseeInnAutocomplete } from './LesseeInnAutocomplete';
import { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';

export const LesseeAutocomplete = (props: LesseeAutocompleteProps) => {
  const { control, setValue } = props;

  const lessee = useWatch({
    control,
    name: 'lessee',
  });

  const [inn, setInn] = useState(lessee.inn);
  const [name, setName] = useState(lessee.fullName);

  useEffect(() => {
    if (inn === '' && lessee.inn !== '') {
      setInn(lessee.inn);
    }
    if (name === '' && lessee.fullName !== '') {
      setName(lessee.fullName);
    }
  }, [inn, name, lessee]);

  return (
    <>
      <Grid item md={9} xs={24}>
        <LesseeNameAutocomplete
          control={control}
          setValue={setValue}
          inn={inn}
          setInn={setInn}
          name={name}
          setName={setName}
        />
      </Grid>
      <Grid item md={9} xs={24}>
        <LesseeInnAutocomplete
          control={control}
          setValue={setValue}
          inn={inn}
          setInn={setInn}
          name={name}
          setName={setName}
        />
      </Grid>
    </>
  );
};
