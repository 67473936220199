import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import {
  Input,
  Select,
  Button,
  useTabs,
  Tabs,
  Checkbox,
  IssueListSwitcher,
  ModalForm,
  useModalForm,
  Role,
} from 'components';
import { Grid } from 'components/Grid';
import { useTranslation } from 'react-i18next';
import { IssuesFilterFormProps } from './types';
import { Box, MenuItem } from '@material-ui/core';
import { IssueFilterTabs, IssueStatus, IssueType } from 'schema/serverTypes';
import { AssigneeAutocomplete } from './AssigneeAutocomplete';
import { useCallback } from 'react';
import { useController } from 'react-hook-form';
import { IssueChips } from './IssueChips';
import { AuthorAutocomplete } from './AuthorAutocomplete';
import { IssueRangeFilterForm } from './IssueList/IssueRangeFilterForm';
import { ApplicationRoles, useUserRole } from 'services';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '100%',
    },
    buttonReset: {
      display: 'flex',
      alignItems: 'flex-end',
      marginBottom: theme.spacing(0.2),
    },
    hide: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
    },
    filterContainer: {
      width: 'calc(100vw - 260px)',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'space-between',
    },
    dateRange: {
      marginTop: theme.spacing(2),
      color: theme.palette.text.primary,
      borderBottom: '2px solid #D1D7E4',
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      cursor: 'pointer',
      '&:hover': {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
      },
    },
  })
);

export const IssuesFilterForm = (props: IssuesFilterFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { control, handleOnReset, setValue } = props;
  const { onOpen, onClose, open } = useModalForm();
  const { isGrouped, setGrouped } = props;

  const mineLabel = t('Mine');
  const incomingLabel = t('Incoming');
  const allLabel = t('All');
  const { field } = useController({ control, name: 'group' });
  const { onChange } = field;
  const initialTab = field.value ? ['mine', 'incoming', 'all'].indexOf(field.value) : 0;
  const tabsProps = useTabs([mineLabel, incomingLabel, allLabel], initialTab);
  const { tabIndex, onChangeTab } = tabsProps;

  const onMineChanged = useCallback(
    (e: any, index: number) => {
      onChangeTab(e, index);
      if (index === 0) {
        onChange(IssueFilterTabs.Mine);
      } else if (index === 1) {
        onChange(IssueFilterTabs.Incoming);
      } else {
        onChange(IssueFilterTabs.All);
      }
    },
    [onChange, onChangeTab]
  );

  const { isSalesSupport } = useUserRole();

  return (
    <form>
      <Box mb={2.5} className={classes.filterContainer}>
        <Box>
          <Tabs {...tabsProps} value={tabIndex} onChangeTab={onMineChanged} />
        </Box>
        <Box>
          <IssueListSwitcher isGrouped={isGrouped} setGrouped={setGrouped} />
        </Box>
      </Box>
      <Grid container columnSpacing={2} rowSpacing={2.5} className={classes.root}>
        <Grid md={3} xs={24} item>
          <Input label={t('Calculation')} control={control} name="quotaIds" variant="standard" />
        </Grid>
        <Grid md={3} xs={24} item>
          <Input label={t('Task number')} control={control} name="issueIds" variant="standard" />
        </Grid>
        <Role role={ApplicationRoles.allExceptAssetManager}>
          <Grid md={3} xs={24} item>
            <Select name="type" multiple label={t('Type')} variant="standard" control={control}>
              <MenuItem value={IssueType.Contract}>{t('IssueType.Contract')}</MenuItem>
              <MenuItem value={IssueType.Telematics}>{t('IssueType.Telematics')}</MenuItem>
              <MenuItem value={IssueType.Shipment}>{t('IssueType.Shipment')}</MenuItem>
              <MenuItem value={IssueType.Payment}>{t('IssueType.Payment')}</MenuItem>
              <MenuItem value={IssueType.Verification}>{t('IssueType.Verification')}</MenuItem>
              <MenuItem value={IssueType.ShipmentReport}>{t('IssueType.ShipmentReport')}</MenuItem>
              <MenuItem value={IssueType.Nomenclature}>{t('IssueType.Nomenclature')}</MenuItem>
              <MenuItem value={IssueType.Approval}>{t('IssueType.Approval')}</MenuItem>
              {!isSalesSupport && (
                <MenuItem value={IssueType.Scoring}>{t('IssueType.Scoring')}</MenuItem>
              )}
              <MenuItem value={IssueType.Counterparty}>{t('IssueType.Counterparty')}</MenuItem>
            </Select>
          </Grid>
        </Role>
        <Grid md={4} xs={24} item>
          <Select name="status" multiple label={t('Status')} variant="standard" control={control}>
            <MenuItem value={IssueStatus.Opened}>{t('IssueStatus.Opened')}</MenuItem>
            <MenuItem value={IssueStatus.InProgress}>{t('IssueStatus.InProgress')}</MenuItem>
            <MenuItem value={IssueStatus.ForReview}>{t('IssueStatus.ForReview')}</MenuItem>
            <MenuItem value={IssueStatus.Closed}>{t('IssueStatus.Closed')}</MenuItem>
            <MenuItem value={IssueStatus.Postponed}>{t('IssueStatus.Postponed')}</MenuItem>
            <MenuItem value={IssueStatus.Rework}>{t('IssueStatus.Rework')}</MenuItem>
            <MenuItem value={IssueStatus.Verification}>{t('IssueStatus.Verification')}</MenuItem>
            <MenuItem value={IssueStatus.Returned}>{t('IssueStatus.Returned')}</MenuItem>
          </Select>
        </Grid>
        <Grid md={4} xs={24} item>
          <AssigneeAutocomplete control={control} />
        </Grid>
        <Grid md={4} xs={24} item>
          <AuthorAutocomplete control={control} />
        </Grid>
        <Grid md={4} xs={24} item>
          <div className={classes.dateRange} onClick={onOpen}>
            {t('Date')}
          </div>
        </Grid>
        <Grid md={4} xs={24} item>
          <Input label={t('Contract')} control={control} name="contractNumber" variant="standard" />
        </Grid>
        <Grid md={4} xs={24} item className={classes.hide}>
          <Checkbox control={control} name="hideCompleted" label={t('Hide completed')} />
        </Grid>
        <Grid md={3} xs={24} item className={classes.buttonReset}>
          <FormControl>
            <Button variant="text" onClick={handleOnReset}>
              {t('Reset')}
            </Button>
          </FormControl>
        </Grid>
      </Grid>
      <Box mb={1}>
        <IssueChips control={control} setValue={setValue} />
      </Box>
      <ModalForm open={open} onClose={onClose} width={980}>
        <IssueRangeFilterForm onClose={onClose} control={control} />
      </ModalForm>
    </form>
  );
};
