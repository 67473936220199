import React, { useCallback, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Breadcrumbs, ModalForm, Paging, useModalForm } from 'components';
import { AddButton } from '../AddButton';
import { DealQualificationRuleViewModel } from 'schema/serverTypes';
import { QualificationsTable } from './QualificationsTable';
import { QualificationsEditForm } from './QualificationsEditForm';
import { useQualificationsData } from './useQualificationsData';
import { QualificationsFilterForm } from './QualificationsFilterForm';
import { QualificationsAddForm } from './QualificationsAddForm';
import { useTranslation } from 'react-i18next';
import { PageTitle } from 'components/utils/pageTitle';

export const QualificationsDataGrid = () => {
  const { t } = useTranslation();
  const { filter, paging, rows, isLoading, sorting } = useQualificationsData();
  const { onOpen, onClose, open } = useModalForm();

  const [item, setItem] = useState<DealQualificationRuleViewModel>();

  const pageTitle = t('Qualifications') + ' - Bumblebee';
  PageTitle(pageTitle);

  const handleOnOpen = useCallback(
    (item: DealQualificationRuleViewModel) => {
      setItem(item);
      onOpen();
    },
    [onOpen]
  );

  const handleOnClose = useCallback(() => {
    setItem(undefined);
    onClose();
  }, [onClose]);

  return (
    <>
      <Breadcrumbs link={'/dictionaries'} text={t('Section.dictionaries')} />
      <Box display="flex" justifyContent="space-between" mb={2.5} alignItems="flex-end">
        <Box>
          <QualificationsFilterForm {...filter} />
        </Box>
        <Box>
          <AddButton onClick={onOpen} />
        </Box>
      </Box>
      <Grid container item direction="column">
        <Grid item>
          <QualificationsTable
            rows={rows}
            onOpen={handleOnOpen}
            loading={isLoading}
            sorting={sorting}
          />
        </Grid>
        <Grid item>
          <Paging {...paging} />
        </Grid>
      </Grid>
      <ModalForm open={open} onClose={handleOnClose}>
        {item && <QualificationsEditForm onSuccess={handleOnClose} item={item} />}
        {item === undefined && <QualificationsAddForm onSuccess={handleOnClose} />}
      </ModalForm>
    </>
  );
};
