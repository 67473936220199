import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { useCounterpartyIndustryQuery } from 'services/api/useCounterpartiesBackend';

export const useCounterpartyProperties = () => {
  const { inn } = useParams<{ inn: string }>();

  const { data, isLoading } = useCounterpartyIndustryQuery(inn);

  const isFinanced = data?.isFinanced;
  const isEstimated = data?.isEstimated;

  return useMemo(
    () => ({ isLoading, isFinanced, isEstimated }),
    [isLoading, isFinanced, isEstimated]
  );
};
