import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { UseFormReturn } from 'react-hook-form';
import { Checkbox, Input, Switch } from 'components/form';
import { CounterpartyIndividualFormValues } from '../types';
import { Grid } from 'components/Grid';
import { CounterpartyIndividualFormProps } from '../CounterpartyCreateForm/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    noWrap: {
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      minHeight: 37,
    },
    groupsHead: {
      color: theme.palette.secondary.main,
      display: 'inline-block',
      marginRight: 20,
    },
  })
);

export type GeneralInformationIndividualFieldsProps = CounterpartyIndividualFormProps & {
  clearErrors: UseFormReturn<CounterpartyIndividualFormValues>['clearErrors'];
  isValid: boolean;
};

export const GeneralInformationIndividualFields = (
  props: GeneralInformationIndividualFieldsProps
) => {
  const classes = useStyles();
  const { control } = props;
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item xs={5} className={classes.noWrap}>
          <Checkbox name="isAgent" label={t('Agent')} control={control} />
        </Grid>
        <Grid md={6} xs={24} item mdStart={1}>
          <Input label={`${t('Inn')}`} name="inn" control={control} disabled />
        </Grid>
        <Grid md={6} xs={24} item>
          <Input label={`${t('opfShort')}`} name="opf" control={control} disabled />
        </Grid>
        <Grid md={5} xs={24} item>
          <Switch
            label={t('vatPayer')}
            name="isVatPayer"
            control={control}
            labelOn={''}
            labelOff={''}
          />
        </Grid>
      </Grid>
    </div>
  );
};
