import { useTranslation } from 'react-i18next';
import { IconSprite } from '../icons';
import { Grid } from '@material-ui/core';
import { PagingProps } from './types';
import { PagingLink } from './PagingLink';
import { SelectPaging } from './SelectPaging';
import { usePagingStyles } from './usePagingStyles';

export const Paging = (props: PagingProps) => {
  const classes = usePagingStyles();

  const {
    showFirst = true,
    showLast = true,
    showNext = true,
    showPrev = true,
    pagesCount = 6,
    page,
    pageSize,
    pageCount,
    totalCount,
    dataCount,
    onPageChanged,
    onPageSizeChanged,
  } = props;

  let from = page - pagesCount / 2;
  if (from < 1) {
    from = 1;
  }

  const to = from + (pagesCount - 1 > totalCount ? totalCount : pagesCount - 1);
  const pages: number[] = [];

  for (let page = from; page < to && page <= pageCount; page += 1) {
    pages.push(page);
  }

  const prevPage = page - 1 < 1 ? 1 : page - 1;
  const nextPage = page + 1 > pageCount ? pageCount : page + 1;

  const shownFrom = page;
  const shownTo = page + dataCount < totalCount ? page + pageSize - 1 : totalCount;

  const { t } = useTranslation();

  return (
    <Grid container className={classes.blockPages}>
      <Grid item>
        {showFirst && (
          <PagingLink
            page={1}
            disabled={page === 1}
            onPageChanged={onPageChanged}
            currentPage={page}
          >
            <IconSprite icon={'page-first'} width="8px" />
          </PagingLink>
        )}
        {showPrev && (
          <PagingLink
            page={prevPage}
            disabled={page === prevPage}
            onPageChanged={onPageChanged}
            currentPage={page}
          >
            <IconSprite icon={'page-prev'} width="5px" height="8px" />
          </PagingLink>
        )}
        {pages.map((pageNumber) => {
          return (
            <PagingLink
              key={pageNumber}
              page={pageNumber}
              onPageChanged={onPageChanged}
              currentPage={page}
            />
          );
        })}
        {pages[pages.length - 1] < pageCount && (
          <span>
            {pageCount <= 6 ? '' : '... '}
            <PagingLink page={pageCount} onPageChanged={onPageChanged} currentPage={page} />
          </span>
        )}
        {showNext && (
          <PagingLink
            page={nextPage}
            disabled={page === nextPage}
            onPageChanged={onPageChanged}
            currentPage={page}
          >
            <IconSprite icon={'page-next'} width="5px" height="8px" />
          </PagingLink>
        )}
        {showLast && (
          <PagingLink
            page={pageCount}
            disabled={page === pageCount}
            onPageChanged={onPageChanged}
            currentPage={page}
          >
            <IconSprite icon={'page-last'} width="8px" />
          </PagingLink>
        )}
      </Grid>
      <Grid item className={classes.blockControlItem}>
        <SelectPaging pageSize={pageSize} onPageSizeChanged={onPageSizeChanged} />
        <Grid item>
          {page === 1 && (
            <>
              {t('Pagination.Shown')}
              <span>&nbsp;{dataCount}&nbsp;</span>
              {t('Pagination.From')}
              <span>&nbsp;{totalCount}&nbsp;</span>
            </>
          )}
          {page !== 1 && (
            <>
              {t('Pagination.Shown')}
              <span>
                &nbsp;{shownFrom} - {shownTo}&nbsp;
              </span>
              {t('Pagination.From')}
              <span>&nbsp;{totalCount}&nbsp;</span>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
