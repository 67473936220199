import { useMissingHistoryRequest } from 'services/api';
import { MissingHistoryRequest, MissingHistoryTime } from 'schema/serverTypes';
import { useMemo, useState, useContext } from 'react';
import { MissingFilterContext } from './MissingFilterContext';

export const useMissingFilterForm = () => {
  const [time, setTime] = useState<MissingHistoryTime>(MissingHistoryTime.Weeks);
  const { documentType, setDocumentTypes, managerId, setManagerIds } =
    useContext(MissingFilterContext);

  const request: MissingHistoryRequest = useMemo(() => {
    return { time, documentType, managerId };
  }, [time, documentType, managerId]);

  const { tabs: historyTabs = [], isLoading } = useMissingHistoryRequest(request);

  const groups = historyTabs.map((t) => {
    return {
      name: t.name,
      id: t.id,
    };
  });

  const tabs = groups.map((group) => group.name);

  return {
    groups,
    historyTabs,
    isLoading,
    tabs,
    setTime,
    time: request.time,
    setDocumentTypes,
    setManagerIds,
    documentType,
    managerId,
  };
};
