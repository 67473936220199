import { useTranslation } from 'react-i18next';
import { AmountInput } from '../AmountInput';
import { useCalculationFormContext } from '../types';
import { useWatch } from 'react-hook-form';

export const ResidualValueInput = () => {
  const { t } = useTranslation();

  const { control } = useCalculationFormContext();
  const hasResidualValue = useWatch({ control, name: 'hasResidualValue' }) ?? false;

  return (
    <AmountInput
      name="residualValue"
      label={t('ResidualValuePerUnit')}
      disabled={!hasResidualValue}
    />
  );
};
