import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { CalculationMethod, SeasonalPaymentType } from 'schema/serverTypes';
import { getNumber } from 'components/calculator/utils';
import { MenuItem } from '@material-ui/core';
import { Grid } from 'components/Grid';
import { Select } from 'components/form';
import { PaymentOptionList } from './PaymentOptionList';
import { useTranslation } from 'react-i18next';
import 'dayjs/locale/ru';
import { useCalculationFormContext } from '../types';
import { useWatch } from 'react-hook-form';
import { SeasonalPaymentOptionsDatePicker } from './SeasonalPaymentOptionsDatePicker';
import { CyclicityCheckbox } from './CyclicityCheckbox';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: '100%',
    },
    hidden: {
      display: 'none',
    },
    visible: {
      display: 'block',
    },
    payment: {
      marginTop: theme.spacing(1),
    },
  })
);

export type PaymentScheduleAccordionProps = {
  quotaId?: number;
  calculationMethodChange: number;
};

export const PaymentScheduleAccordion = (props: PaymentScheduleAccordionProps) => {
  const { quotaId, calculationMethodChange } = props;
  const { control } = useCalculationFormContext();
  const calculationMethod = useWatch({ control, name: 'calculationMethod' });
  const numberOfMonthsValue = useWatch({ control, name: 'numberOfMonths' });
  const numberOfMonths = getNumber(numberOfMonthsValue);
  const date = useWatch({ control, name: 'seasonalPaymentOptions.date' });

  const classes = useStyles();

  const rootClassName =
    calculationMethod === CalculationMethod.Seasonal ||
    calculationMethod === CalculationMethod.SuperSeasonal
      ? classes.visible
      : classes.hidden;

  const { t } = useTranslation();

  if (
    calculationMethod !== CalculationMethod.Seasonal &&
    calculationMethod !== CalculationMethod.SuperSeasonal
  ) {
    return null;
  }

  return (
    <Accordion defaultExpanded={true} className={rootClassName}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel4a-content"
        id="panel4a-header"
      >
        <Typography variant="subtitle1">{t('PaymentSchedule')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className={classes.wrapper}>
          <Grid container columnSpacing={2} rowSpacing={2.5}>
            <Grid item xl={4} lg={6} md={8} xs={24}>
              <Select
                name="seasonalPaymentOptions.paymentType"
                control={control}
                label={t('SeasonalPaymentType')}
                required={
                  calculationMethod === CalculationMethod.Seasonal ||
                  calculationMethod === CalculationMethod.SuperSeasonal
                }
              >
                <MenuItem value={SeasonalPaymentType.MonthlyPayment}>
                  {t('ToMonthlyPayments')}
                </MenuItem>
                <MenuItem value={SeasonalPaymentType.MainDebt}>{t('ToMainDebt')}</MenuItem>
              </Select>
            </Grid>
            <Grid item xl={3} lg={4} md={5} xs={24}>
              <SeasonalPaymentOptionsDatePicker
                calculationMethod={calculationMethod}
                numberOfMonths={numberOfMonths}
                date={date}
              />
            </Grid>
            <Grid item md="auto" xs={24}>
              <CyclicityCheckbox />
            </Grid>
          </Grid>
          <PaymentOptionList
            calculationMethod={calculationMethod}
            numberOfMonths={numberOfMonths}
            date={date}
            quotaId={quotaId}
            calculationMethodChange={calculationMethodChange}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
