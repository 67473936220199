import { Sidebar } from 'components/Sidebar';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Button } from 'components/Button';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMemo } from 'react';
import { DocumentTypeSelectField } from './DocumentTypeSelectField';
import { MissingSettingsFormValues } from './types';
import { ManagerSelectField } from './ManagerSelectField';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '440px',
      minWidth: '440px',
      border: 'none',
      boxShadow: 'none',
      height: '100%',
      display: 'grid',
      gridTemplateRows: 'auto 1fr auto',
    },
    form: {
      height: '100%',
    },
    headerWrapper: {
      marginBottom: theme.spacing(0.5),
    },
    header: {
      paddingTop: theme.spacing(5.5),
      fontWeight: 'bolder',
      textAlign: 'center',
      paddingRight: 40,
      paddingLeft: 40,
      paddingBottom: 20,
    },
    title: {
      fontSize: 14,
      fontWeight: 500,
      color: theme.palette.text.secondary,
      margin: theme.spacing(0, 0, 1),
    },
    cardContent: {
      paddingTop: 20,
      paddingRight: 40,
      paddingBottom: 20,
      paddingLeft: 40,
      overflowY: 'auto',
      marginBottom: theme.spacing(3),
    },
    message: {
      marginBottom: theme.spacing(3),
    },
    actions: {
      display: 'block',
      boxShadow: '3px -3px 15px rgba(56, 60, 97, 0.1)',
      padding: theme.spacing(4, 5),
    },
    button: {
      marginRight: theme.spacing(2),
      borderRadius: 5,
      padding: theme.spacing(0.5, 1),
      fontWeight: 400,
      border: `1px solid ${theme.palette.secondary.main}`,
      '&:hover': {
        border: `1px solid ${theme.palette.secondary.main}`,
      },
    },
    active: {
      backgroundColor: theme.palette.primary.light,
      pointerEvents: 'none',
      color: theme.palette.secondary.darker,
    },
  })
);

export type MissingSettingsFormPanelProps = {
  onClose?: () => void;
  setDocumentTypes: (documentType: string[]) => void;
  documentType: string[];
  setManagerIds: (ids: string[]) => void;
  managerId: string[];
  tabId: number;
};

export const MissingSettingsFormPanel = (props: MissingSettingsFormPanelProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { documentType, managerId, setDocumentTypes, setManagerIds, onClose, tabId } = props;

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    getValues,
  } = useForm<MissingSettingsFormValues>({
    mode: 'onBlur',
    defaultValues: {
      documentType,
      managerId,
    },
  });

  const onSubmit = useMemo(() => {
    const submit = () => {
      const documentTypes = getValues('documentType');
      const managerIds = getValues('managerId');

      setDocumentTypes(documentTypes);
      setManagerIds(managerIds);

      if (onClose) {
        onClose();
      }
    };
    return handleSubmit(submit);
  }, [handleSubmit, onClose, getValues, setDocumentTypes, setManagerIds]);

  return (
    <Sidebar width={440} onClose={onClose}>
      <form onSubmit={onSubmit} className={classes.form}>
        <Card className={classes.root}>
          <Box className={classes.headerWrapper}>
            <CardHeader className={classes.header} title={t('Schedule setting')} />
          </Box>
          <CardContent className={classes.cardContent}>
            <DocumentTypeSelectField control={control} />
            <ManagerSelectField control={control} tabId={tabId} />
          </CardContent>
          <CardActions className={classes.actions}>
            <Button variant={'contained'} disabled={isSubmitting} type={'submit'}>
              {t('Show')}
            </Button>
          </CardActions>
        </Card>
      </form>
    </Sidebar>
  );
};
