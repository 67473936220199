import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, createStyles, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { themeOrange as theme } from 'theme';
import { TabPanel, Tabs, useTabs } from 'components/Tabs';
import SwipeableViews from 'react-swipeable-views';
import { Empty } from '../../../Empty';
import { MissingCharts } from '../MissingCharts';
import { Grid } from '../../../Grid';
import { IconSprite } from '../../../icons';
import { MissingHistoryTab, MissingHistoryTime } from 'schema/serverTypes';
import { MissingChartDateFilter } from '../MissingChartDateFilter';
import { useMissingFilterForm } from '../useMissingFilterForm';
import { MissingSettingsFormPanel } from '../MissingSettingsFormPanel';
import { MissingCards } from './MissingCards';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: theme.spacing(2, 2.5),
      minHeight: 388,
      height: '100%',
      position: 'relative',
      backgroundColor: theme.palette.bgGray.main,
      boxShadow: 'none',
      borderRadius: 0,
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
    },
    tabs: {
      marginBottom: -2,
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr 64px 96px auto',
      alignItems: 'center',
      '& > div:first-of-type': {
        gridColumn: 2,
      },
    },
    fullContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    fullscreen: {
      cursor: 'pointer',
      padding: theme.spacing(0, 1.5),
    },
    chartContainer: {
      position: 'relative',
    },
  })
);

export const MissingContainerFS = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const missingWidget = useRef<HTMLElement>();

  const {
    tabs,
    groups,
    historyTabs,
    isLoading,
    time,
    documentType,
    managerId,
    setTime,
    setDocumentTypes,
    setManagerIds,
  } = useMissingFilterForm();

  const tabsProps = useTabs(tabs);
  const { tabIndex, onChangeTabIndex, onChangeTab } = tabsProps;

  return (
    <Paper className={classes.root} ref={missingWidget}>
      <Box className={classes.header}>
        <div className={classes.fullContainer}>
          <Typography variant="subtitle1">{t('Missing progress')}</Typography>
          <Link to={'/'}>
            <IconSprite
              icon={'fullScreen'}
              width={'14px'}
              height={'14px'}
              color={theme.palette.greyBlue1.main}
            />
          </Link>
        </div>
        {tabs.length !== 0 && (
          <Tabs
            {...tabsProps}
            value={tabIndex}
            onChangeTab={onChangeTab}
            className={classes.tabs}
          />
        )}
      </Box>
      {isLoading ? (
        <Empty>{t('Please wait while the data is loading')}</Empty>
      ) : (
        <>
          <SwipeableViews
            containerStyle={{
              transition: 'transform 0.6s ease-out 0s',
            }}
            springConfig={{
              duration: '0.6s',
              easeFunction: 'transform 0.6s ease-out 0s',
              delay: '0s',
            }}
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={tabIndex}
            onChangeIndex={onChangeTabIndex}
          >
            {groups.map((group, i) => {
              var tab = historyTabs.find((t) => t.id === group.id);
              if (tab === undefined) {
                return null;
              }
              return (
                <TabContent
                  key={group.id}
                  tab={tab}
                  group={group}
                  tabIndex={tabIndex}
                  index={i}
                  isLoading={isLoading}
                  time={time}
                  documentType={documentType}
                  setTime={setTime}
                  setDocumentTypes={setDocumentTypes}
                  managerId={managerId}
                  setManagerIds={setManagerIds}
                />
              );
            })}
          </SwipeableViews>
        </>
      )}
    </Paper>
  );
};

type TabContentProps = {
  group: { name: string; id: number };
  tabIndex: number;
  index: number;
  tab: MissingHistoryTab;
  isLoading: boolean;
  time: MissingHistoryTime;
  documentType: string[];
  managerId: string[];
  setTime: (value: MissingHistoryTime) => void;
  setDocumentTypes: (value: string[]) => void;
  setManagerIds: (value: string[]) => void;
};

const TabContent = (props: TabContentProps) => {
  const classes = useStyles();
  const {
    index,
    tabIndex,
    tab: { timeGroups, countGroups, id: tabId },
    isLoading,
    time,
    documentType,
    setTime,
    setDocumentTypes,
    managerId,
    setManagerIds,
  } = props;

  const [isOpenSidePanel, setOpenSidePanel] = useState<boolean>(false);

  const openSidePanel = useCallback(() => {
    setOpenSidePanel(true);
  }, [setOpenSidePanel]);

  const closeSidePanel = useCallback(() => {
    setOpenSidePanel(false);
  }, [setOpenSidePanel]);

  return (
    <TabPanel value={tabIndex} index={index} dir={theme.direction}>
      <Grid container columnSpacing={2} rowSpacing={1}>
        <Grid item xs={24}>
          <div className={classes.chartContainer}>
            <MissingChartDateFilter value={time} setValue={setTime} openSidePanel={openSidePanel} />
            {isOpenSidePanel && (
              <MissingSettingsFormPanel
                onClose={closeSidePanel}
                documentType={documentType}
                setDocumentTypes={setDocumentTypes}
                managerId={managerId}
                setManagerIds={setManagerIds}
                tabId={tabId}
              />
            )}
            <MissingCharts chartId={index} groups={timeGroups} isLoading={isLoading} isFullScreen />
          </div>
        </Grid>
        <Grid item xs={24}>
          <MissingCards groups={countGroups} isLoading={isLoading} />
        </Grid>
      </Grid>
    </TabPanel>
  );
};
