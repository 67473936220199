import { Droppable } from 'react-beautiful-dnd';
import { Item } from './Item';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    container: {
      margin: 0,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    title: {
      margin: 0,
      padding: theme.spacing(1.5, 2.5),
      backgroundColor: '#FFFFFF',
      color: theme.palette.text.secondary,
      borderBottom: `1px solid ${theme.palette.lightBlue.main}`,
    },
    taskList: {
      padding: theme.spacing(2),
      transition: 'background-color 0.2s ease',
      backgroundColor: '#FFF',
      flexGrow: 1,
      height: '100%',
    },
  })
);

type ColumnProps = {
  column: any;
  tasks: any;
};

export const Column = ({ column, tasks }: ColumnProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant={'subtitle1'} className={classes.title}>
        {column.title}
      </Typography>

      <Droppable droppableId={column.id}>
        {(provided: any, snapshot: any) => (
          <div
            className={classes.taskList}
            ref={provided.innerRef}
            //style={{backgroundColor: snapshot.isDraggingOver ? '#E9F1FE': '#ffffff'}}
            {...provided.droppableProps}
          >
            {tasks.map((task: any, index: any) => (
              <Item key={task.id} task={task} index={index} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};
