import { useDebounce } from 'use-debounce';
import { CounterpartyOption, CounterpartyType } from 'schema';
import { useDictionaryBackendQuery } from 'services';
import { useMemo } from 'react';

export const useCounterpartyQuery = (
  type: CounterpartyType,
  inputValue?: string,
  value?: string
) => {
  const [input] = useDebounce(inputValue, 500);

  let requestUrl = useMemo(() => {
    let url = 'counterparties';
    const searchParams = new URLSearchParams();

    searchParams.set('type', type);

    if (input) {
      searchParams.set('name', input);
    }
    if (value) {
      searchParams.set('inn', value);
    }
    url += `?${searchParams}`;

    return url;
  }, [type, input, value]);

  return useDictionaryBackendQuery<CounterpartyOption[]>(requestUrl);
};
