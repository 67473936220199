import { createStyles, makeStyles } from '@material-ui/core/styles';
import { MenuItem, Select } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'inline-flex',
      background: theme.palette.background.paper,
      border: '1px solid ' + theme.palette.primaryGradient[200],
      borderRadius: 5,
      fontWeight: 400,
      fontSize: 12,
      color: theme.palette.text.primary,
      padding: 0,
      margin: theme.spacing(0, 0, 0, 1.5),
      position: 'relative',
      cursor: 'pointer',
      '&:before': {
        display: 'none',
      },
      '& .MuiInputBase-input': {
        padding: theme.spacing(0.5, 3.2, 0.5, 1),
        lineHeight: 1.2,
      },
      '&:after': {
        display: 'none',
      },
    },
  })
);

type ChipProps = {
  label: string;
  options: string[];
  onChange: (value: string) => void;
};

export const ChipSelect = (props: ChipProps) => {
  const { label, options, onChange } = props;
  const classes = useStyles();

  const changeHandler = (event: any) => {
    onChange(event.target.value);
  };

  return (
    <Select defaultValue={''} onChange={changeHandler} className={classes.root} displayEmpty={true}>
      <MenuItem value={''} key={label}>
        {label}
      </MenuItem>
      {options.map((option) => (
        <MenuItem value={option} key={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
};
