import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useUserAuth } from 'services';

export const LoginButton = () => {
  const history = useHistory();
  const { signIn } = useUserAuth();

  useEffect(() => {
    const handleLogin = async () => {
      await signIn({
        returnUrl: history.location.pathname,
        localLogin: false,
      });
    };
    let timer = setTimeout(() => handleLogin().catch(console.error), 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [signIn, history]);
  return <></>;
};
