import { useCallback, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useDebounce } from 'use-debounce';
import { useTemplatesBackendQuery } from 'services/api';
import { TagMeta } from 'schema/serverTypes';

export type TagSearchFormValues = {
  search?: string;
};

const useSearchParams = (searchParams: TagSearchFormValues) => {
  const { search } = searchParams;

  return useMemo(() => {
    const searchParams = new URLSearchParams();

    if (search && search !== '') {
      searchParams.set('search', search);
    }

    return searchParams;
  }, [search]);
};

export const useTagSearchForm = () => {
  const { control, reset } = useForm<TagSearchFormValues>({
    mode: 'onBlur',
    defaultValues: { search: '' },
  });

  const onReset = useCallback(() => {
    reset({ search: '' });
  }, [reset]);

  const searchValue = useWatch({ control, name: 'search' });
  const [search] = useDebounce(searchValue ?? '', 500);

  const searchParams = useSearchParams({ search });
  const { data: tags = [] } = useTemplatesBackendQuery<TagMeta[]>({
    relativeUrl: 'meta',
    queryKey: ['templates', 'meta', search],
    searchParams,
  });

  return {
    tags: Object.values(tags),
    control,
    onReset,
  };
};
