import React, { useState } from 'react';
import { PipelineContainer as PipelineContainer2 } from './Pipeline2';
import { PipelineContainer } from './Pipeline';
import { IconSprite } from '../../icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { themeOrange as theme } from 'theme';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toggle: {
      zIndex: 1000,
      position: 'absolute',
      right: 82,
      top: 6,
      display: 'flex',
    },
    btn: {
      width: 24,
      height: 24,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      '& + &': {
        marginLeft: theme.spacing(1),
      },
      '&$active': {
        border: '1px solid' + theme.palette.text.secondary,
        borderRadius: 2,
      },
    },
    active: {},
  })
);

export const PipelineToggle = () => {
  const classes = useStyles();
  const [old, setOld] = useState<boolean>(false);

  return (
    <div>
      <div className={classes.toggle}>
        <div
          onClick={() => setOld(false)}
          className={clsx(classes.btn, !old ? classes.active : null)}
        >
          <IconSprite icon={'switcher-list'} width={12} color={theme.palette.secondary.dark} />
        </div>
        <div
          onClick={() => setOld(true)}
          className={clsx(classes.btn, old ? classes.active : null)}
        >
          <IconSprite icon={'menu-groups'} width={16} color={theme.palette.secondary.dark} />
        </div>
      </div>
      {old ? <PipelineContainer /> : <PipelineContainer2 />}
    </div>
  );
};
