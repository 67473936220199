import { useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import { MenuItem } from '@material-ui/core';
import { IssueStatus, IssueType, IssueViewModel } from 'schema/serverTypes';
import { Select } from '../form';
import { createQuotasBackendUrl } from 'services/api/createQuotasBackendUrl';
import { useUpdateIssueStatusMutation } from 'services/api/useIssuesBackend';
import { useStatusSelectString, useIssueTypeString } from './useStatusString';
import { ChangeAssigneeAutocomplete } from './ChangeAssigneeAutocomplete';
import { useLanguage } from '../utils/useLanguage';
import { useUserRole } from 'services/authentication';
import { IssueActions } from './IssueActions';
import { User } from '../users';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: 'white',
      padding: theme.spacing(2.5),
      display: 'flex',
      flexWrap: 'wrap',
      color: theme.palette.common.black,
      fontSize: 12,
      alignItems: 'center',
    },
    form: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      width: '100%',
    },
    paramtitle: {
      width: '40%',
      textAlign: 'right',
      paddingRight: 6,
      color: theme.palette.text.secondary,
      marginBottom: theme.spacing(1.5),
    },
    param: {
      fontSize: 14,
      width: '60%',
      paddingLeft: 6,
      marginBottom: theme.spacing(1.5),
      '&$link': {
        color: theme.palette.secondary.dark,
      },
    },
    paramSelect: {
      width: 140,
      '& .MuiSelect-filled.MuiFilledInput-inputMarginDense ': {
        paddingTop: 0,
      },
    },
    link: {},
    dot: {
      display: 'inline-block',
      width: 8,
      height: 8,
      borderRadius: '50%',
      marginRight: theme.spacing(1),
    },
    dotOpened: {
      backgroundColor: theme.palette.primary.main,
    },
    dotInProgress: {
      backgroundColor: theme.palette.attention.main,
    },
    dotForReview: {
      backgroundColor: theme.palette.secondary.main,
    },
    dotPostponed: {
      backgroundColor: theme.palette.error.main,
    },
    dotClosed: {
      backgroundColor: theme.palette.green.main,
    },
    dotReturned: {
      backgroundColor: theme.palette.error.main,
    },
    actions: {
      marginTop: theme.spacing(-6),
      marginBottom: theme.spacing(1),
      display: 'flex',
      justifyContent: 'flex-end',
    },
    actionButton: {
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
    modalRoot: {
      textAlign: 'center',
    },
    modalTitle: {
      marginBottom: theme.spacing(3),
    },
    modalSelect: {
      textAlign: 'left',
    },
    modalButton: {
      marginTop: theme.spacing(2),
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
  })
);

export const IssueParam = (props: { issue: IssueViewModel }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const lang = useLanguage();

  const { createdDate, updatedDate = null, issueType, author, assignee, status, id } = props.issue;

  const typeString = useIssueTypeString(issueType);

  const queryKey = createQuotasBackendUrl(`/${id}`);
  const queryClient = useQueryClient();

  const invalidateQuery = useCallback(() => {
    return queryClient.invalidateQueries(queryKey);
  }, [queryClient, queryKey]);

  const opened = t('IssueStatus.Opened');
  const inProgress = t('IssueStatus.InProgress');
  const postponed = t('IssueStatus.Postponed');
  const forReview = t('IssueStatus.ForReview');
  const closed = t('IssueStatus.Closed');
  const returned = t('IssueStatus.Returned');
  const rework = t('IssueStatus.Rework');
  const inQueue = t('IssueStatus.InQueue');

  const updatedFromButtons = useRef(false);

  const { control, setValue } = useForm({
    mode: 'onBlur',
    defaultValues: {
      status,
      issueType,
    },
  });

  const { mutateAsync: updateStatusAsync } = useUpdateIssueStatusMutation(id);

  const handleOnStatusChanged = useCallback(
    async (event: any) => {
      updatedFromButtons.current = false;
      const issueStatus = event as IssueStatus;
      const issue = await updateStatusAsync({
        status: issueStatus,
      });
      if (issue) {
        await invalidateQuery();
      }
    },
    [updateStatusAsync, invalidateQuery]
  );

  const handleOnTypeChanged = useCallback(
    async (event: any) => {
      updatedFromButtons.current = false;
      const issueType = event as IssueType;
      const issue = await updateStatusAsync({
        status,
        issueType: issueType,
      });
      if (issue) {
        await invalidateQuery();
      }
    },
    [status, updateStatusAsync, invalidateQuery]
  );

  const { hasRole } = useUserRole();

  const isPulse = issueType === IssueType.Error || issueType === IssueType.Rework;
  const statusDisplayName = useStatusSelectString(status);

  return (
    <>
      {hasRole && (
        <IssueActions
          issue={props.issue}
          updatedFromButtons={updatedFromButtons}
          setValue={setValue}
        />
      )}
      <div className={classes.root}>
        <div className={classes.paramtitle}>{t('Delivered')}</div>
        <div className={classes.param}>
          {dayjs(createdDate).locale(lang).format('DD MMM YYYY HH:mm')}
        </div>
        {updatedDate && status === 'closed' && (
          <>
            <div className={classes.paramtitle}>{t('IssueStatus.Closed')}</div>
            <div className={classes.param}>
              {dayjs(updatedDate).locale(lang).format('DD MMM YYYY HH:mm')}
            </div>
          </>
        )}
        <form className={classes.form}>
          <div className={classes.paramtitle}>{t('Status')}</div>
          <div className={clsx(classes.param, classes.paramSelect)}>
            {hasRole && isPulse ? (
              <Select
                name="status"
                variant="filled"
                control={control}
                onChangeCallback={handleOnStatusChanged}
              >
                <MenuItem value={IssueStatus.Opened}>
                  <div className={clsx(classes.dot, classes.dotOpened)} />
                  {opened}
                </MenuItem>
                <MenuItem value={IssueStatus.InProgress}>
                  <div className={clsx(classes.dot, classes.dotInProgress)} />
                  {inProgress}
                </MenuItem>
                <MenuItem value={IssueStatus.ForReview}>
                  <div className={clsx(classes.dot, classes.dotForReview)} />
                  {forReview}
                </MenuItem>
                <MenuItem value={IssueStatus.Postponed}>
                  <div className={clsx(classes.dot, classes.dotPostponed)} />
                  {postponed}
                </MenuItem>
                <MenuItem value={IssueStatus.Returned}>
                  <div className={clsx(classes.dot, classes.dotReturned)} />
                  {returned}
                </MenuItem>
                <MenuItem value={IssueStatus.Closed}>
                  <div className={clsx(classes.dot, classes.dotClosed)} />
                  {closed}
                </MenuItem>
                <MenuItem value={IssueStatus.Rework}>
                  <div className={clsx(classes.dot, classes.dotClosed)} />
                  {rework}
                </MenuItem>
                {isPulse && (
                  <MenuItem value={IssueStatus.InQueue}>
                    <div className={clsx(classes.dot, classes.dotClosed)} />
                    {inQueue}
                  </MenuItem>
                )}
              </Select>
            ) : (
              statusDisplayName
            )}
          </div>
          <div className={classes.paramtitle}>{t('Type')}</div>
          <div className={clsx(classes.param, classes.paramSelect)}>
            {hasRole && isPulse ? (
              <Select
                name="issueType"
                variant="filled"
                control={control}
                onChangeCallback={handleOnTypeChanged}
                disabled={status === 'closed'}
              >
                <MenuItem value={IssueType.Error}>{t('IssueType.Error')}</MenuItem>
                <MenuItem value={IssueType.Rework}>{t('IssueType.Rework')}</MenuItem>
              </Select>
            ) : (
              typeString
            )}
          </div>
        </form>
        <div className={classes.paramtitle}>{t('Author')}</div>
        <Link to={`/users/view/${author.id}`} className={clsx(classes.param, classes.link)}>
          <User name={author.name} isActive={author.isActive} />
        </Link>
        <div className={classes.paramtitle}>{t('Assignee')}</div>
        <div className={classes.param}>
          {hasRole && isPulse ? (
            <ChangeAssigneeAutocomplete issueType={issueType} assignee={assignee} issueId={id} />
          ) : assignee ? (
            <User name={assignee.name} isActive={assignee.isActive} />
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};
