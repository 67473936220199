import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { AddIssueCommentModel } from 'schema/serverTypes';
import { Button } from '../Button';
import { Grid } from '../Grid';
import { useIssuesFileUploadMutation } from '../../services/api';
import { AddCommentFieldValues } from './types';
import { useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { createQuotasBackendUrl } from '../../services/api/createQuotasBackendUrl';
import { WysiwygEditor } from './WysiwygEditor';
import { FileUpload } from './FileUpload';
import { FormHelperText } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.bgGray.main,
      padding: theme.spacing(0),
      marginBottom: theme.spacing(2.5),
      position: 'relative',
    },
    button: {
      display: 'block',
      backgroundColor: 'white',
    },
    addFile: {
      display: 'block',
      position: 'absolute',
      right: 0,
      top: 10,
    },
  })
);

export const AddComment = (props: { issueId: number }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isHidden, setHidden] = useState<boolean>(false);
  const { control, handleSubmit, setValue, formState } = useForm<AddCommentFieldValues>({
    mode: 'onChange',
    defaultValues: {
      comment: '',
      file: null,
    },
  });
  const queryClient = useQueryClient();

  const getFormData = (values: AddCommentFieldValues) => {
    const formData = new FormData();
    if (values.comment !== '') {
      formData.append('text', values.comment);
    }
    if (values.file !== null) {
      formData.append('file', values.file);
    }
    return formData;
  };

  const { mutateAsync } = useIssuesFileUploadMutation<AddCommentFieldValues, AddIssueCommentModel>(
    `${props.issueId}/comments`,
    getFormData
  );

  const onSubmit = useMemo(() => {
    const submit = async (values: AddCommentFieldValues) => {
      const comment = await mutateAsync(values);
      const queryKey = createQuotasBackendUrl(` /${props.issueId}`);
      if (comment) {
        setValue('comment', '');
        await queryClient.invalidateQueries(queryKey);
      }
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync, props.issueId, queryClient, setValue]);

  return (
    <form className={classes.root} onSubmit={onSubmit}>
      {isHidden && (
        <FileUpload
          control={control}
          setValue={setValue}
          isAddFileButton={true}
          setHidden={setHidden}
        />
      )}
      <Grid container columnSpacing={2} rowSpacing={1.5}>
        <Grid item xs={24}>
          <Controller
            name="comment"
            control={control}
            render={({ field, fieldState }) => {
              const helperText =
                fieldState.error !== undefined
                  ? (fieldState.error as FieldError).message
                  : undefined;

              return (
                <>
                  <WysiwygEditor value={field.value} onChange={field.onChange} />
                  {helperText && <FormHelperText error>{helperText}</FormHelperText>}
                </>
              );
            }}
            rules={{
              required: true,
              maxLength: {
                value: 10000,
                message: t('Maximum number of characters is', { count: 10000 }),
              },
            }}
          />
        </Grid>
        {!isHidden && (
          <Grid item xs={24}>
            <FileUpload
              control={control}
              setValue={setValue}
              isAddFileButton={true}
              setHidden={setHidden}
            />
          </Grid>
        )}
        <Grid item xs={24}>
          <Button
            className={classes.button}
            color="primary"
            size="medium"
            type="submit"
            variant="outlined"
            disabled={!formState.isValid}
          >
            {t('Add comment')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
