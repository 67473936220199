import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { Input, Button } from 'components';
import { Grid } from 'components/Grid';
import { useTranslation } from 'react-i18next';
import { MissingDocumentTypeAutocomplete } from './MissingDocumentTypeAutocomplete';
import { MissingStatusAutocomplete } from './MissingStatusAutocomplete';
import { MissingFilterFormProps } from './types';
import { Switch } from 'components/form';
import { MissingChips } from './MissingChips';
import { useWatch } from 'react-hook-form';
import { MissingManagerAutocomplete } from './MissingManagerAutocomplete';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '100%',
    },
    buttonReset: {
      display: 'flex',
      alignItems: 'flex-end',
      marginBottom: theme.spacing(0.2),
    },
    switch: {
      marginBottom: -8,
      alignSelf: 'end',
      justifySelf: 'end',
    },
    number: {
      '& input': {
        textAlign: 'right',
      },
    },
  })
);

export const MissingFilterCounterpartyForm = (props: MissingFilterFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { control, onReset, setValue } = props;

  const tabId = useWatch({ control, name: 'tabId' }) ?? 0;
  const debt = useWatch({ control, name: 'debt' });

  return (
    <form>
      <Box display="flex" justifyContent="space-between" mb={2.5} alignItems="flex-end">
        <Box flex={1}>
          <Grid container columnSpacing={2} rowSpacing={2.5} className={classes.root}>
            {tabId !== 0 && (
              <Grid md={4} xs={24} item>
                <MissingManagerAutocomplete control={control} />
              </Grid>
            )}
            <Grid md={3} xs={24} item>
              <Input
                label={t('Contract')}
                control={control}
                name="contractNumber"
                variant="standard"
              />
            </Grid>
            <Grid md={5} xs={24} item>
              <MissingDocumentTypeAutocomplete control={control} />
            </Grid>
            <Grid md={5} xs={24} item>
              <MissingStatusAutocomplete control={control} />
            </Grid>
            <Grid md={2} xs={24} item className={classes.switch}>
              <Switch
                label={`${t('Debt')}`}
                name="debt"
                control={control}
                labelOn={''}
                labelOff={''}
              />
            </Grid>
            {debt === true && (
              <Grid md={2} xs={24} item>
                <Input
                  label={t('Debt days')}
                  control={control}
                  name="debtDays"
                  variant="standard"
                  className={classes.number}
                  rules={{
                    required: true,
                    pattern: /^[0-9,-]+$/i,
                  }}
                />
              </Grid>
            )}
            <Grid md={3} xs={24} item className={classes.buttonReset}>
              <FormControl>
                <Button variant="text" onClick={onReset}>
                  {t('Reset')}
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box mb={1}>
        <MissingChips
          control={control}
          setValue={setValue}
          hideManagers={tabId === 0}
          hideCounterparties={true}
        />
      </Box>
    </form>
  );
};
