import { useCalculationFormContext } from '../types';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteChangeDetails,
} from '@material-ui/lab/useAutocomplete';
import { Autocomplete, PagedList } from 'components';
import { FieldError, useController } from 'react-hook-form';
import { useDictionaryBackendQuery } from 'services';
import { useRequiredOnSave } from '../useRequiredOnSave';
import { LeasingProductItemViewModel } from 'schema';
import { LeasingProductBrandsContext } from '../LeasingProductBrandsContext';
import { useLeasingProductByNomenclature } from '../useLeasingProductByNomenclature';

const useLeasingProductQuery = () => {
  const { data } = useDictionaryBackendQuery<PagedList<LeasingProductItemViewModel>>(
    'leasingProducts?page=1&pageSize=9999'
  );
  const options = data?.data ?? [];
  return {
    options,
  };
};

const getOptionLabel = (option: LeasingProductItemViewModel | null) => {
  if (option === null) {
    return '';
  }
  return option.name;
};

const getOptionSelected = (
  option: LeasingProductItemViewModel | null,
  value: LeasingProductItemViewModel | null
) => {
  if (option === null || value === null) {
    return false;
  }
  return option.name === value.name;
};

export const LeaseProductAutocomplete = () => {
  const { t } = useTranslation();

  const { setBrands, clearBrands, brands } = useContext(LeasingProductBrandsContext);

  const { control } = useCalculationFormContext();
  const rules = useRequiredOnSave({ name: 'leaseProduct' });

  const { leasingProductByNomenclature, vendor } = useLeasingProductByNomenclature(control);

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    control,
    name: 'leaseProduct',
    rules,
  });

  const helperText =
    error !== undefined
      ? (error as FieldError).message
      : !brands.includes(vendor) &&
        !!leasingProductByNomenclature?.name &&
        value !== leasingProductByNomenclature?.name
      ? `ПЛ соответсвует каналу продаж: ${leasingProductByNomenclature?.name}`
      : undefined;

  const [input, setInput] = useState('');
  const { options = [] } = useLeasingProductQuery();

  const handleOnInputChange = useCallback(
    (_ev: React.ChangeEvent<{}>, value: string, _reason: AutocompleteInputChangeReason) => {
      setInput(value);
    },
    [setInput]
  );

  const handleOnChange = useCallback(
    (
      _e: React.ChangeEvent<{}>,
      value: LeasingProductItemViewModel | null,
      _reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<LeasingProductItemViewModel | null>
    ) => {
      if (value === null) {
        clearBrands();
        onChange('');
      } else {
        setBrands(value.brands);
        onChange(value.name);
      }
    },
    [onChange, clearBrands, setBrands]
  );

  const selectedOption = options.find((t) => t.name === value) ?? null;

  return (
    <Autocomplete<LeasingProductItemViewModel | null>
      label={`${t('Sales channel')}`}
      options={options}
      inputValue={input}
      onInputChange={handleOnInputChange}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      onChange={handleOnChange}
      variant="outlined"
      value={selectedOption}
      error={
        error !== undefined ||
        (!brands.includes(vendor) &&
          !!leasingProductByNomenclature?.name &&
          value !== leasingProductByNomenclature?.name)
      }
      helperText={helperText}
    />
  );
};
