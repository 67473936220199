import { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteChangeDetails,
} from '@material-ui/lab/useAutocomplete';
import { Autocomplete } from 'components';
import { Control, FieldError, useController, useWatch } from 'react-hook-form';
import { IndustryViewModel } from 'schema/serverTypes';
import { useIndustryPagedListQuery } from 'services/api/useIndustryBackend';
import { useDebounce } from 'use-debounce';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';

const getOptionLabel = (option: IndustryViewModel | null) => {
  if (option === null) {
    return '';
  }
  return option.name;
};

const getOptionSelected = (option: IndustryViewModel | null, value: IndustryViewModel | null) => {
  if (option === null || value === null) {
    return false;
  }
  return option.id === value.id;
};

export type IndustryAutocompleteProps = {
  control: Control<any>;
  name?: string;
};

export const IndustryAutocomplete = (props: IndustryAutocompleteProps) => {
  const { t } = useTranslation();

  const { control, name = 'industryId' } = props;
  const isFilter = name === 'industryName' || name === 'name';

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    control,
    name,
    rules: { required: { value: true, message: t('Required') } },
  });

  const industryName = useWatch({ control, name: 'industryName' });

  const helperText = error !== undefined ? (error as FieldError).message : undefined;

  const [input, setInput] = useState(industryName);
  const [inputName] = useDebounce(input, 500);

  const { data } = useIndustryPagedListQuery({ page: 1, pageSize: 20, name: inputName });
  const options = data?.data ?? [];

  const handleOnInputChange = useCallback(
    (_ev: ChangeEvent<{}>, value: string, _reason: AutocompleteInputChangeReason) => {
      setInput(value);
    },
    [setInput]
  );

  const handleOnChange = useCallback(
    (
      _e: ChangeEvent<{}>,
      value: IndustryViewModel | null,
      _reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<IndustryViewModel | null>
    ) => {
      if (value === null) {
        onChange(null);
      } else {
        onChange(isFilter ? value.name : value.id);
      }
    },
    [onChange, isFilter]
  );

  const selectedOption = options.find((t) => t.id === value) ?? null;

  return (
    <Autocomplete<IndustryViewModel | null>
      label={`${t('Industry/Branch')}`}
      options={options}
      inputValue={input || ''}
      onInputChange={handleOnInputChange}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      onChange={handleOnChange}
      variant={isFilter ? 'standard' : 'outlined'}
      value={selectedOption}
      error={error !== undefined}
      helperText={helperText}
      popupIcon={<KeyboardArrowDownRoundedIcon color="inherit" fontSize="medium" />}
      openText={t('Open')}
      closeText={t('Close')}
      clearText={t('Clear')}
      autoHighlight={true}
      noOptionsText={t('no results')}
    />
  );
};
