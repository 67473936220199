import { useCallback } from 'react';
import { MissingChip } from '../missing';

export type WikiChipsProps = {
  tags?: string[];
  onChange: (tags: string[]) => void;
};

export const WikiChips = (props: WikiChipsProps) => {
  const { tags = [], onChange } = props;

  const onHashTagRemove = useCallback(
    (id: string) => {
      const newTags = tags.filter((v) => v !== id);
      onChange(newTags);
    },
    [tags, onChange]
  );

  const hashTagChips = tags?.map((value) => {
    return (
      <MissingChip label={value} id={value} key={`hashTag-${value}`} onRemove={onHashTagRemove} />
    );
  });

  return <>{hashTagChips}</>;
};
