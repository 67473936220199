import { useCallback, useEffect, useState } from 'react';
import { Button, Header, ModalForm, useToast } from 'components';
import { PageLayoutProps } from './types';
import { PageLayout } from './PageLayout';
import { Role } from 'components/authentication';
import { useTranslation } from 'react-i18next';
import { IconSprite } from 'components/icons';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import menuArrow from 'img/icons/menuArrow.svg';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  useMediaQuery,
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { ErrorForm } from '../tasks/ErrorForm';
import { ApplicationRole } from 'schema/serverTypes';

const drawerWidth = 190;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.appBar,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    backgroundColor: 'white',
    borderRight: 0,
    width: drawerWidth,
  },
  drawerClose: {
    backgroundColor: 'white',
    borderRight: 0,
    overflowX: 'hidden',
    width: 40,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    backgroundColor: theme.palette.bgGray.main,
    flexGrow: 1,
    padding: theme.spacing(3, 3, 3, 3),
  },
  menuItem: {
    fontFamily: "'Halvar Breit', sans-serif",
    fontSize: '14px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    borderLeft: `4px solid transparent`,
    position: 'relative',
    '& > .MuiListItemText-root': {
      color: theme.palette.text.primary,
    },
    '&:hover, &.active': {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
      '& .MuiListItemText-root': {
        color: theme.palette.primary.main,
      },
      '&:after': {
        content: `""`,
        mask: `url(${menuArrow}) no-repeat`,
        maskSize: 'cover',
        backgroundColor: theme.palette.primary.main,
        position: 'absolute',
        display: 'block',
        width: 6,
        height: 11,
        right: 11,
      },
    },
  },
  menuIcon: {
    minWidth: 32,
  },
  calculator: {
    width: 142,
    position: 'absolute',
    bottom: theme.spacing(2.5),
    left: '50%',
    transform: 'translateX(-50%)',
    '&$small': {
      background: theme.palette.error.light,
      border: 'none',
      '&:hover': {
        background: theme.palette.error.light,
        border: 'none',
      },
    },
  },
  small: {},
  critical: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 500,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(-3, -3, 1.5),
  },
  error: {
    width: 142,
    position: 'absolute',
    bottom: theme.spacing(8.5),
    left: '50%',
    transform: 'translateX(-50%)',
    '&&': {
      color: theme.palette.error.main,
      '&:hover': {
        textDecoration: 'none',
        color: theme.palette.error.main,
      },
      '&:focus': {
        textDecoration: 'underline',
        color: theme.palette.error.main,
      },
    },
  },
}));

export const PageAuthenticatedLayout = (props: PageLayoutProps) => {
  const { pageContent, headerContent, ...rest } = props;
  const [openErrorForm, setOpenErrorForm] = useState<boolean>(false);
  //const { onOpen, onClose, open } = useModalForm();
  const classes = useStyles();
  const theme = useTheme();
  const isOpen = useMediaQuery(theme.breakpoints.up('md'));
  const toast = useToast();

  useEffect(() => {
    // Scroll to top of page during navigation
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();

  const postErrorSuccess = useCallback(() => {
    setOpenErrorForm(false);
    toast(t('ErrorTaskSuccessMessage'), 'success');
  }, [toast, t]);

  const content = (
    <>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: isOpen,
          [classes.drawerClose]: !isOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: isOpen,
            [classes.drawerClose]: !isOpen,
          }),
        }}
      >
        <Toolbar />

        <List component="nav">
          <ListItem className={classes.menuItem} component={NavLink} to="/" exact>
            <ListItemIcon className={classes.menuIcon}>
              <IconSprite width="16px" color={theme.palette.primary.main} icon="menu-home" />
            </ListItemIcon>
            <ListItemText disableTypography>{t('Main')}</ListItemText>
          </ListItem>
          <Role role={['admin']}>
            <ListItem className={classes.menuItem} component={NavLink} to="/users">
              <ListItemIcon className={classes.menuIcon}>
                <IconSprite width={16} color={theme.palette.primary.main} icon="users" />
              </ListItemIcon>
              <ListItemText disableTypography primary={t('User_plural')} />
            </ListItem>
          </Role>
          <ListItem
            className={classes.menuItem}
            component={NavLink}
            to="/quotas"
            isActive={(match: any, location: any) => {
              return location.pathname.indexOf('/quotas') === 0;
            }}
          >
            <ListItemIcon className={classes.menuIcon}>
              <IconSprite width={16} height={16} color={theme.palette.primary.main} icon="quotas" />
            </ListItemIcon>
            <ListItemText disableTypography primary={t('Calculation_plural')} />
          </ListItem>
          <Role role={['admin']}>
            <ListItem
              className={classes.menuItem}
              component={NavLink}
              to="/shipments"
              isActive={(match: any, location: any) => {
                return location.pathname.indexOf('/shipments') === 0;
              }}
            >
              <ListItemIcon className={classes.menuIcon}>
                <IconSprite
                  width="18px"
                  height="14px"
                  color={theme.palette.primary.main}
                  icon="calculate"
                />
              </ListItemIcon>
              <ListItemText disableTypography primary={t('Shipment_plural')} />
            </ListItem>
          </Role>
          <Role
            role={[
              ApplicationRole.Admin,
              ApplicationRole.SuperSalesManager,
              ApplicationRole.SalesManager,
              ApplicationRole.SalesSupport,
              ApplicationRole.RiskManager,
              ApplicationRole.Compliance,
              ApplicationRole.InsideSales,
            ]}
          >
            <ListItem className={classes.menuItem} component={NavLink} to="/counterparties">
              <ListItemIcon className={classes.menuIcon}>
                <IconSprite width={16} color={theme.palette.primary.main} icon="counterparty" />
              </ListItemIcon>
              <ListItemText disableTypography primary={t('Counterparty_plural')} />
            </ListItem>
          </Role>
          <Role
            role={[
              ApplicationRole.Admin,
              ApplicationRole.SuperSalesManager,
              ApplicationRole.SalesManager,
              ApplicationRole.RiskManager,
              ApplicationRole.AssetManager,
              ApplicationRole.InsideSales,
            ]}
          >
            <ListItem className={classes.menuItem} component={NavLink} to="/dictionaries">
              <ListItemIcon className={classes.menuIcon}>
                <IconSprite width={16} color={theme.palette.primary.main} icon="manual" />
              </ListItemIcon>
              <ListItemText disableTypography primary={t('Reference books')} />
            </ListItem>
          </Role>
          <Role
            role={[
              ApplicationRole.Admin,
              ApplicationRole.SuperSalesManager,
              ApplicationRole.RiskManager,
            ]}
          >
            <ListItem className={classes.menuItem} component={NavLink} to="/templates">
              <ListItemIcon className={classes.menuIcon}>
                <IconSprite width={16} color={theme.palette.primary.main} icon="templates" />
              </ListItemIcon>
              <ListItemText disableTypography primary={t('Templates')} />
            </ListItem>
          </Role>
          <ListItem className={classes.menuItem} component={NavLink} to="/news">
            <ListItemIcon className={classes.menuIcon}>
              <IconSprite width={16} color={theme.palette.primary.main} icon="menu-catalogs" />
            </ListItemIcon>
            <ListItemText disableTypography primary={t('News')} />
          </ListItem>
          <ListItem className={classes.menuItem} component={NavLink} to="/tasks">
            <ListItemIcon className={classes.menuIcon}>
              <IconSprite width={16} color={theme.palette.primary.main} icon="issues" />
            </ListItemIcon>
            <ListItemText disableTypography primary={t('Task_plural')} />
          </ListItem>
          <Role>
            <ListItem className={classes.menuItem} component={NavLink} to="/overdue">
              <ListItemIcon className={classes.menuIcon}>
                <IconSprite
                  width="18px"
                  height="18px"
                  color={theme.palette.primary.main}
                  icon="overdue"
                />
              </ListItemIcon>
              <ListItemText disableTypography primary={t('Overdue')} />
            </ListItem>
          </Role>
          <Role
            role={[
              ApplicationRole.Admin,
              ApplicationRole.SuperSalesManager,
              ApplicationRole.SalesManager,
              ApplicationRole.SalesSupport,
              ApplicationRole.Dealer,
              ApplicationRole.RiskManager,
              ApplicationRole.Vendor,
              ApplicationRole.InsideSales,
            ]}
          >
            <ListItem className={classes.menuItem} component={NavLink} to="/missing">
              <ListItemIcon className={classes.menuIcon}>
                <IconSprite width={16} color={theme.palette.primary.main} icon="clock" />
              </ListItemIcon>
              <ListItemText disableTypography primary={t('Missing')} />
            </ListItem>
          </Role>
          <Role
            role={[
              ApplicationRole.Admin,
              ApplicationRole.SuperSalesManager,
              ApplicationRole.SalesManager,
              ApplicationRole.RiskManager,
              ApplicationRole.SalesSupport,
              ApplicationRole.InsideSales,
            ]}
          >
            <ListItem className={classes.menuItem} component={NavLink} to="/contracts">
              <ListItemIcon className={classes.menuIcon}>
                <IconSprite
                  width="14px"
                  height="14px"
                  color={theme.palette.primary.main}
                  icon="contracts"
                />
              </ListItemIcon>
              <ListItemText disableTypography primary={t('Contracts')} />
            </ListItem>
          </Role>
          <Role
            role={[
              ApplicationRole.Admin,
              ApplicationRole.SuperSalesManager,
              ApplicationRole.SalesManager,
              ApplicationRole.SalesSupport,
              ApplicationRole.Dealer,
              ApplicationRole.RiskManager,
              ApplicationRole.Vendor,
              ApplicationRole.InsideSales,
            ]}
          >
            <ListItem className={classes.menuItem} component={NavLink} to="/wiki">
              <ListItemIcon className={classes.menuIcon}>
                <IconSprite
                  width="16px"
                  height="12px"
                  color={theme.palette.primary.main}
                  icon="wiki"
                />
              </ListItemIcon>
              <ListItemText disableTypography primary={t('Wiki')} />
            </ListItem>
          </Role>
          <ListItem className={classes.menuItem} component={NavLink} to="/puls">
            <ListItemIcon className={classes.menuIcon}>
              <IconSprite
                width="15px"
                height="16px"
                color={theme.palette.primary.main}
                icon="menu-tasks"
              />
            </ListItemIcon>
            <ListItemText disableTypography primary={t('Puls')} />
          </ListItem>
          <Role role={ApplicationRole.Admin}>
            <ListItem className={classes.menuItem} component={NavLink} to="/settings">
              <ListItemIcon className={classes.menuIcon}>
                <IconSprite
                  width="14px"
                  height="14px"
                  color={theme.palette.primary.main}
                  icon="menu-settings"
                />
              </ListItemIcon>
              <ListItemText disableTypography primary={t('Settings')} />
            </ListItem>
          </Role>
          {/*<Role role={ApplicationRole.Admin}>*/}
          {/*  <ListItem className={classes.menuItem} component={NavLink} to="/requests">*/}
          {/*    <ListItemIcon className={classes.menuIcon}>*/}
          {/*      <IconSprite width={16} color={theme.palette.primary.main} icon="menu-edit" />*/}
          {/*    </ListItemIcon>*/}
          {/*    <ListItemText disableTypography primary={'Заявки'} />*/}
          {/*  </ListItem>*/}
          {/*</Role>*/}
          <ListItem className={classes.menuItem} component={NavLink} to="/futureshipments">
            <ListItemIcon className={classes.menuIcon}>
              <IconSprite width={16} color={theme.palette.primary.main} icon="futureShipments" />
            </ListItemIcon>
            <ListItemText disableTypography primary={t('FutureShipments')} />
          </ListItem>
        </List>
        <Role>
          <Button variant="text" className={classes.error} onClick={() => setOpenErrorForm(true)}>
            {isOpen ? (
              t('Buttons.AboutError')
            ) : (
              <IconSprite
                icon={'warning'}
                width={14}
                height={12}
                color={theme.palette.primary.main}
              />
            )}
          </Button>
        </Role>
        <ModalForm open={openErrorForm} onClose={() => setOpenErrorForm(false)} width={500}>
          <ErrorForm onSuccess={postErrorSuccess} />
        </ModalForm>
        <Button
          variant="contained"
          to="/quotas/calculator"
          className={isOpen ? classes.calculator : clsx(classes.calculator, classes.small)}
        >
          {isOpen ? (
            t('Buttons.Calculator')
          ) : (
            <IconSprite
              icon={'menu-calculations'}
              width={16}
              color={theme.palette.secondary.dark}
            />
          )}
        </Button>
      </Drawer>
      <section className={classes.content}>
        <Toolbar />
        {pageContent}
      </section>
    </>
  );

  const header = headerContent ?? <Header />;

  return <PageLayout pageContent={content} headerContent={header} {...rest} />;
};
