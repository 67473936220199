import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { WikiItemCommentViewModel } from 'schema/serverTypes';
import { Comment } from './Comment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: 'white',
      padding: theme.spacing(2.5, 2.5),
    },
  })
);

type CommentListProps = {
  comments: WikiItemCommentViewModel[];
  articleId: number;
};

export const CommentList = (props: CommentListProps) => {
  const classes = useStyles();
  const { comments, articleId } = props;
  const newComments = comments.map((comment) => {
    return { ...comment, date: comment.createdDate };
  });

  const historyList: (WikiItemCommentViewModel & { date: string })[] = [...newComments];
  historyList.sort((a, b) => b.date.localeCompare(a.date));

  return (
    <div className={classes.root}>
      {historyList?.map((item, index) => (
        <Comment
          id={item.id}
          text={item.text}
          author={item.author}
          createdDate={item.createdDate}
          files={item.files}
          index={index}
          articleId={articleId}
          key={`${item.createdDate}comment`}
        />
      ))}
    </div>
  );
};
