import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useRouteMatch } from 'react-router-dom';
import { useNewsBackendQuery } from '../../../services/api';
import { NewsData, NewsItemViewModel } from 'schema/serverTypes';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { themeOrange as theme } from 'theme';

dayjs.extend(isBetween);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: 'white',
      textAlign: 'center',
      fontSize: 14,
      fontWeight: 500,
      margin: theme.spacing(-3, -3, 1.5),
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 40,
    },
  })
);

export const Critical = () => {
  const classes = useStyles();
  const { path } = useRouteMatch();
  let section = path.split('/')[1];

  switch (section) {
    case '':
      section = 'home';
      break;
    case 'calculator':
      section = 'quotas';
      break;
    case 'tasks':
      section = 'issues';
      break;
    default:
      break;
  }

  const searchParams = new URLSearchParams();
  searchParams.set('section', section);
  searchParams.set('category', 'critical');
  const { data } = useNewsBackendQuery<NewsData>({
    relativeUrl: '',
    queryKey: `criticalNews${section}`,
    searchParams,
    options: {
      refetchOnMount: true,
    },
  });

  let news = data?.data ?? [];
  news = news.filter((n) => {
    return dayjs(dayjs().format('YYYY-MM-DD')).isBetween(
      dayjs(n.from).format('YYYY-MM-DD'),
      dayjs(n.to).format('YYYY-MM-DD'),
      null,
      '[]'
    );
  });

  return (
    <div className={classes.root}>
      {news.map((item: NewsItemViewModel) => (
        <div
          className={classes.item}
          style={{ backgroundColor: item.color ?? theme.palette.error.main }}
          key={item.id}
        >
          {item.title}
        </div>
      ))}
    </div>
  );
};
