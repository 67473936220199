import { Autocomplete } from 'components';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import { CounterpartyOption, CounterpartyType } from 'schema/serverTypes';
import { QuotaFilterFormContext, QuotaFilterFormProps } from './types';
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteChangeDetails,
} from '@material-ui/lab/useAutocomplete';
import { useController } from 'react-hook-form';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import { useCounterpartyQuery } from './useCounterpartyQuery';

export const getOptionLabel = (option: CounterpartyOption) => {
  if (option.inn === option.name) {
    return option.name;
  }
  if (option.id === 0) {
    return option.name;
  }
  return `${option.inn} (${option.name})`;
};

const getOptionSelected = (option: CounterpartyOption, value: CounterpartyOption) => {
  return option.inn === value.inn;
};

export type CounterpartyAutocompleteProps = Pick<QuotaFilterFormProps, 'control'> & {
  type: CounterpartyType;
};

export const CounterpartyAutocomplete = (props: CounterpartyAutocompleteProps) => {
  const { control, type } = props;

  const { t } = useTranslation();

  const {
    field: { onChange, value: inns = [] },
  } = useController({
    control,
    name: type === CounterpartyType.dealer ? 'dealerInn' : 'lesseeInn',
    defaultValue: [],
  });

  const [counterparty, setCounterparty] = useState('');
  const [search] = useDebounce(counterparty, 500);

  const { data = [], isLoading } = useCounterpartyQuery(type, search);

  const options = data.filter((t) => {
    if (inns.length === 0) {
      return true;
    }
    const counterparty = inns.find((m) => m === t.inn);
    return counterparty === undefined;
  });

  const handleOnInputChange = useCallback(
    (_ev: React.ChangeEvent<{}>, value: string, _reason: AutocompleteInputChangeReason) => {
      setCounterparty(value);
    },
    [setCounterparty]
  );

  const { addCounterparty } = useContext(QuotaFilterFormContext);

  const handleOnChange = useCallback(
    (
      _e: React.ChangeEvent<{}>,
      value: CounterpartyOption | null,
      _reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<CounterpartyOption | null>
    ) => {
      if (value === null) {
        onChange([]);
        setCounterparty('');
      } else {
        const inn = value.inn;
        const values = inns.length === 0 ? [inn] : [inn, ...inns.filter((t) => t !== inn)];
        onChange(values);
        setCounterparty('');
        addCounterparty(value);
      }
    },
    [onChange, inns, addCounterparty]
  );

  const label = type === CounterpartyType.dealer ? t('Dealer') : t('Lessee');

  return (
    <Autocomplete<CounterpartyOption>
      label={label}
      options={options}
      value={null}
      inputValue={counterparty}
      onChange={handleOnChange}
      onInputChange={handleOnInputChange}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      loading={isLoading}
      variant="standard"
      popupIcon={<KeyboardArrowDownRoundedIcon color="inherit" fontSize="medium" />}
      openText={t('Open')}
      closeText={t('Close')}
      clearText={t('Clear')}
      autoHighlight={true}
      noOptionsText={t('no results')}
    />
  );
};
