import { Autocomplete } from 'components';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import { ContractLesseesRequest, ContractLesseeViewModel } from 'schema/serverTypes';
import { useContractLesseesRequest } from 'services/api';
import { FutureShipmentFilterFormProps } from './types';
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteChangeDetails,
} from '@material-ui/lab/useAutocomplete';
import { useController } from 'react-hook-form';

const getOptionLabel = (option: ContractLesseeViewModel | null) => {
  return option?.name ?? '';
};

const getOptionSelected = (
  option: ContractLesseeViewModel | null,
  value: ContractLesseeViewModel | null
) => {
  return option?.lesseeInn === value?.lesseeInn;
};

export type FutureShipmentLesseeAutocompleteProps = Pick<
  FutureShipmentFilterFormProps,
  'control'
> & {
  count?: number;
};

export const FutureShipmentLesseeAutocomplete = (props: FutureShipmentLesseeAutocompleteProps) => {
  const { control, count = 20 } = props;

  const { t } = useTranslation();

  const {
    field: { onChange, value: inns },
  } = useController({
    control,
    name: 'lesseeInn',
    defaultValue: [],
  });

  const [lesseeInn, setInn] = useState('');
  const [search] = useDebounce(lesseeInn, 500);

  const request = useMemo<ContractLesseesRequest>(() => {
    return {
      search,
      count,
    };
  }, [search, count]);

  const { data = [], isLoading } = useContractLesseesRequest(request);
  const options = data
    .filter((t) => {
      if (inns === undefined) {
        return true;
      }
      const counterpartyByInn = inns.find((m) => m.lesseeInn === t.inn);
      return counterpartyByInn === undefined;
    })
    .map((item) => ({ ...item, lesseeInn: item.inn }));

  const handleOnInputChange = useCallback(
    (_ev: React.ChangeEvent<{}>, value: string, _reason: AutocompleteInputChangeReason) => {
      setInn(value);
    },
    [setInn]
  );

  const handleOnChange = useCallback(
    (
      _e: React.ChangeEvent<{}>,
      value: ContractLesseeViewModel | null,
      _reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<ContractLesseeViewModel | null>
    ) => {
      if (value === null) {
        onChange([]);
        setInn('');
      } else {
        onChange(inns ? [...inns.filter((v) => v.lesseeInn !== value.lesseeInn), value] : [value]);
        setInn('');
      }
    },
    [onChange, inns]
  );

  return (
    <Autocomplete<ContractLesseeViewModel | null>
      label={t('Lessee')}
      options={options}
      inputValue={lesseeInn}
      onInputChange={handleOnInputChange}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      onChange={handleOnChange}
      value={null}
      loading={isLoading}
      variant="standard"
    />
  );
};
