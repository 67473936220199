import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IssueCommentViewModel, IssueStatusHistoryViewModel } from 'schema/serverTypes';
import { Comment } from './Comment';
import { Status } from './Status';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: 'white',
      padding: theme.spacing(2.5, 2.5),
      marginBottom: theme.spacing(1.5),
    },
  })
);

type CommentListProps = {
  comments: IssueCommentViewModel[];
  statusHistory: IssueStatusHistoryViewModel[];
  issueId: number;
};

export const CommentList = (props: CommentListProps) => {
  const classes = useStyles();
  const { comments, statusHistory, issueId } = props;
  const newComments = comments.map((comment) => {
    return { ...comment, date: comment.createdDate };
  });

  const historyList: any[] = [...newComments, ...statusHistory];
  historyList.sort((a, b) => b.date.localeCompare(a.date));

  return (
    <div className={classes.root}>
      {historyList?.map((item, index) =>
        item.status ? (
          <Status date={item.date} owner={item.user} title={item.status} key={`${item.id}status`} />
        ) : item.isSystemMessage ? (
          <Status
            date={item.date}
            owner={item.author}
            title={item.text}
            key={`${item.id}comment`}
          />
        ) : (
          <Comment {...item} index={index} issueId={issueId} key={`${item.id}comment`} />
        )
      )}
    </div>
  );
};
