import { makeStyles, Tooltip as MuiTooltip, TooltipProps } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.white,
    '&:before': {
      border: `1px solid ${theme.palette.secondary.main}`,
    },
  },
  tooltip: {
    margin: theme.spacing(0.5, 0),
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.secondary,
    fontSize: 10,
    border: `1px solid ${theme.palette.secondary.main}`,
  },
}));

export const Tooltip = (props: TooltipProps) => {
  const classes = useStyles();
  const { children } = props;

  return (
    <MuiTooltip classes={classes} {...props}>
      {children}
    </MuiTooltip>
  );
};
