import { Control, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { DatePicker, Grid } from 'components';
import { Button as MuiButton } from '@material-ui/core';
import dayjs from 'dayjs';
import { SearchFilterFormValues } from '../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    button: {
      display: 'block',
      margin: '4px auto 0',
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
    },
    actionButton: {
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
  })
);

export type IssueRangeFilterFormProps = {
  onClose: () => void;
  control: Control<SearchFilterFormValues, object>;
};

export const IssueRangeFilterForm = (props: IssueRangeFilterFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { onClose, control } = props;

  const fromVal = useWatch({ control, name: 'fromDate' });
  const toVal = useWatch({ control, name: 'toDate' });

  return (
    <Grid container columnSpacing={0} rowSpacing={0}>
      <Grid item md={12} xs={24}>
        <DatePicker
          label={`${t('From')}`}
          name="fromDate"
          variant={'static'}
          control={control}
          maxDate={dayjs(toVal).toISOString()}
        />
      </Grid>
      <Grid item md={12} xs={24}>
        <DatePicker
          label={`${t('To')}`}
          name="toDate"
          variant={'static'}
          control={control}
          minDate={dayjs(fromVal).toISOString()}
        />
      </Grid>
      <Grid item md={24} xs={24}>
        <div className={classes.actions}>
          <div className={classes.actionButton}>
            <MuiButton color="primary" size="medium" type="button" variant="text" onClick={onClose}>
              {t('Ok')}
            </MuiButton>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
