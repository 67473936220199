import { useTranslation } from 'react-i18next';
import { FixedAmountInput } from '../AmountInput';
import { AmountType } from 'schema/serverTypes';
import { useCalculationFormContext } from '../types';
import { useWatch } from 'react-hook-form';
import { useEffect } from 'react';
import { calculateGeneralRatePercents } from '../calculations';

export const GeneraleRateInput = () => {
  const { t } = useTranslation();

  const { control, getValues, setValue } = useCalculationFormContext();

  const cofPercents = useWatch({
    control,
    name: 'cofPercents',
  });

  const cofAddPercents = useWatch({
    control,
    name: 'cofAddPercents',
  });

  const marginPercents = useWatch({
    control,
    name: 'marginPercents',
  });

  useEffect(() => {
    const generalRatePercents = calculateGeneralRatePercents(
      (cofPercents ?? 0) + (cofAddPercents ?? 0),
      marginPercents
    );
    const currentGeneralRatePercents = getValues('generalRatePercents') ?? 0;

    if (generalRatePercents !== currentGeneralRatePercents) {
      setValue('generalRatePercents', generalRatePercents);
    }
  }, [cofPercents, cofAddPercents, marginPercents, getValues, setValue]);

  return (
    <FixedAmountInput
      name="generalRatePercents"
      label={`${t('GeneralRate')}, %`}
      amountMode={AmountType.Percents}
      disabled
      fractionDigits={4}
    />
  );
};
