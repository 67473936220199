import { Autocomplete } from 'components';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import { MissingManagerListRequest, MissingManagerViewModel } from 'schema/serverTypes';
import { useMissingManagerListRequest } from 'services/api';
import { MissingFilterFormProps } from './types';
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteChangeDetails,
} from '@material-ui/lab/useAutocomplete';
import { useController, useWatch } from 'react-hook-form';

const getOptionLabel = (option: MissingManagerViewModel | null) => {
  return option?.name ?? '';
};

const getOptionSelected = (
  option: MissingManagerViewModel | null,
  value: MissingManagerViewModel | null
) => {
  return option?.id === value?.id;
};

export type MissingManagerAutocompleteProps = Pick<MissingFilterFormProps, 'control'> & {
  count?: number;
};

export const MissingManagerAutocomplete = (props: MissingManagerAutocompleteProps) => {
  const { control, count = 20 } = props;

  const { t } = useTranslation();

  const {
    field: { onChange, value: managers },
  } = useController({
    control,
    name: 'managerId',
    defaultValue: [],
  });

  const [manager, setManager] = useState('');
  const [search] = useDebounce(manager, 500);
  const tabId = useWatch({ control, name: 'tabId' });

  const request = useMemo<MissingManagerListRequest>(() => {
    return {
      tabId,
      search,
      count,
    };
  }, [search, count, tabId]);

  const { data = [], isLoading } = useMissingManagerListRequest(request);
  const options = data.filter((t) => {
    if (managers === undefined) {
      return true;
    }
    const managerById = managers.find((m) => m.id === t.id);
    return managerById === undefined;
  });

  const handleOnInputChange = useCallback(
    (_ev: React.ChangeEvent<{}>, value: string, _reason: AutocompleteInputChangeReason) => {
      setManager(value);
    },
    [setManager]
  );

  const handleOnChange = useCallback(
    (
      _e: React.ChangeEvent<{}>,
      value: MissingManagerViewModel | null,
      _reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<MissingManagerViewModel | null>
    ) => {
      if (value === null) {
        onChange([]);
        setManager('');
      } else {
        onChange(managers ? [...managers.filter((v) => v.id !== value.id), value] : [value]);
        setManager('');
      }
    },
    [onChange, managers]
  );

  return (
    <Autocomplete<MissingManagerViewModel | null>
      label={t('Manager')}
      options={options}
      inputValue={manager}
      onInputChange={handleOnInputChange}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      onChange={handleOnChange}
      value={null}
      loading={isLoading}
      variant="standard"
    />
  );
};
