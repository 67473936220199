import { useController, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from 'components';
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteChangeDetails,
} from '@material-ui/lab/useAutocomplete';
import { useCallback, useMemo, useRef } from 'react';
import { useDebounce } from 'use-debounce';
import { useDictionaryBackendQuery } from 'services/api';
import {
  CounterpartyOption,
  getNameOptionLabel,
  getOptionSelected,
  LesseeInputProps,
  useRequestUrl,
} from './types';

export const LesseeNameAutocomplete = (props: LesseeInputProps) => {
  const { control, setValue, name: inputValue, setName: setInputValue, setInn, inn } = props;

  const { t } = useTranslation();

  const {
    field: { onChange, value = null },
    fieldState: { invalid, error },
  } = useController({
    control,
    name: 'lessee.fullName',
    rules: {
      required: {
        value: true,
        message: t('Required'),
      },
    },
  });
  const helperText = error !== undefined ? (error as FieldError).message : undefined;

  const nameInputValue = inn && inn !== '' ? inputValue : '';

  const [input] = useDebounce(nameInputValue, 500);
  const requestUrl = useRequestUrl(input);

  const { data: options = [], refetch } =
    useDictionaryBackendQuery<CounterpartyOption[]>(requestUrl);

  const initialSet = useRef(false);

  const handleOnInputChange = useCallback(
    (_ev: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
      if (reason !== 'reset') {
        setInputValue(value);
      } else if (!initialSet.current) {
        initialSet.current = true;
        setInputValue(value);
      }
    },
    [setInputValue]
  );

  const handleOnChange = useCallback(
    (
      _e: React.ChangeEvent<{}>,
      value: CounterpartyOption | null,
      reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<CounterpartyOption | null>
    ) => {
      if (reason === 'clear') {
        onChange('');
        setValue('lessee.inn', '', { shouldValidate: false });
        setInputValue('');
        setInn('');
        refetch();
      } else {
        if (value !== null) {
          onChange(value.name);
          setInputValue(value.name);
          setValue('lessee.inn', value.inn, { shouldValidate: false });
          setInn(value.inn);
        } else {
          onChange('');
          setInputValue('');
          setValue('lessee.inn', '', { shouldValidate: false });
          setInn('');
          refetch();
        }
      }
    },
    [onChange, setValue, refetch, setInputValue, setInn]
  );

  const selectedOption = useMemo(() => {
    if (value === undefined || value === '') {
      return null;
    }
    return options.find((t) => t.name === value) || null;
  }, [value, options]);

  return (
    <Autocomplete<CounterpartyOption | null>
      label={t('Full name')}
      options={options}
      inputValue={nameInputValue}
      onInputChange={handleOnInputChange}
      getOptionLabel={getNameOptionLabel}
      getOptionSelected={getOptionSelected}
      onChange={handleOnChange}
      error={invalid}
      helperText={helperText}
      value={selectedOption}
      disabled={true}
    />
  );
};
