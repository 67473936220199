import {
  CalculationAgreement,
  CalculationInput,
  QuotaNomenclatureViewModel,
  ScoringModelContextFieldValidationError,
  ScoringModelRuleResultViewModel,
} from 'schema/serverTypes';
import { useCalculatorForm } from '../CreateQuotaForm/useCalculatorForm';
import { Control, FieldPath, PathValue, UseControllerProps, useFormContext } from 'react-hook-form';

export const TelematicsValue = {
  caesar: {
    monthly: 534.0,
    oneTime: 15763.0,
  },
  xPro: {
    monthly: 0,
    oneTime: 16140.0,
  },
};

export type CalculatorFormValues = CalculationInput &
  CalculationAgreement & {
    generalRatePercents?: number;
    tradeFeeAmount?: number;
    prepaymentAmount?: number;
    fundingAmount?: number;
    save?: boolean;
    copy?: boolean;
    dealer?: string;
    dealerName?: string;
    lessee?: string;
    lesseeName?: string;
    insuranceCompany?: string;
    insuranceCompanyName?: string;
    industryLeasingProductId?: number;
    vatEnabled?: boolean;
  };

export type UseCalculatorFormProps = {
  defaultValues: CalculatorFormValues;
};

export type UseCalculatorFormReturn = ReturnType<typeof useCalculatorForm>;

export type CalculationFormFieldPath = FieldPath<CalculatorFormValues>;

export type CalculationFormPathValue = PathValue<CalculatorFormValues, CalculationFormFieldPath>;

export type CalculationFormProps = Pick<
  UseCalculatorFormReturn,
  'onSubmit' | 'defaultValues' | 'error' | 'isLoading' | 'calculationResult' | 'form'
> & {
  isTask?: boolean;
  isLocked?: boolean;
  quotaId?: number;
  copyEnabled?: boolean;
  drawUpEnabled?: boolean;
  isContractExecutionNotified?: boolean;
  sendToIsEnabled?: boolean;
  approvalEnabled?: boolean;
  nomenclatures?: QuotaNomenclatureViewModel[];
  scoringApprovalIssueId?: number;
  issueId?: number;
  approvalErrorPopup?: {
    openApprovalError: boolean;
    onCloseApprovalError: (ev?: any) => void;
    invalidScoringRules?: ScoringModelRuleResultViewModel[];
    corridorErrors?: ScoringModelContextFieldValidationError[];
  };
  scoringIssueId?: number;
};

export type CalculationFormControl = Control<CalculatorFormValues>;

export const useCalculationFormContext = useFormContext<CalculatorFormValues>;

export type RulesType<T extends CalculationFormFieldPath> = UseControllerProps<
  CalculatorFormValues,
  T
>['rules'];
