import { useIssueBackendQuery } from 'services/api/useIssuesBackend';
import { IssueViewModel } from 'schema/serverTypes';
import { useLocation } from 'react-router-dom';

export const useIssueData = () => {
  const { pathname } = useLocation();
  const { data: issue, isLoading: loading } = useIssueBackendQuery<IssueViewModel>({
    relativeUrl: `/${pathname.split('/').pop()}`,
  });

  return {
    issue,
    loading,
  };
};
