import { Control, useController, useWatch } from 'react-hook-form';
import { CounterpartyFilterFormValues } from '../types';
import { Autocomplete } from 'components/Autocomplete';
import { CounterpartyActivity } from 'schema/serverTypes';
import { ChangeEvent, useCallback, useState } from 'react';
import { useDebounce } from 'use-debounce';
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
} from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import { useIndustrySpecializationListQuery } from '../../../services/api/useIndustrySpecializationBackend';

const getOptionLabel = (option: CounterpartyActivity | null) => {
  if (option === null) {
    return '';
  }
  return option.code;
};

const getOptionSelected = (
  option: CounterpartyActivity | null,
  value: CounterpartyActivity | null
) => {
  if (option === null || value === null) {
    return false;
  }
  return option.code === value.code;
};

export type CounterpartyActivityProps = {
  control: Control<CounterpartyFilterFormValues, any>;
};

export const ActivityCodeAutocomplete = (props: CounterpartyActivityProps) => {
  const { control } = props;

  const {
    field: { onChange },
  } = useController({ control, name: 'activityCode' }) ?? [];

  const activityCode = useWatch({ control, name: 'activityCode' }) ?? [];

  const activityCodeName = activityCode.length > 0 ? activityCode[0].code : '';

  const [input, setInput] = useState(activityCodeName);
  const [inputName] = useDebounce(input, 300);

  const { data } = useIndustrySpecializationListQuery({ page: 1, pageSize: 20, code: inputName });
  const options = data?.data ?? [];
  const activityCodeOptions = options.map((option) => {
    return {
      code: option.code,
      text: option.description,
    };
  });

  const handleOnInputChange = useCallback(
    (_ev: ChangeEvent<{}>, value: string, _reason: AutocompleteInputChangeReason) => {
      setInput(value);
    },
    [setInput]
  );

  const handleOnChange = useCallback(
    (
      _e: ChangeEvent<{}>,
      value: CounterpartyActivity | null,
      _reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<CounterpartyActivity | null>
    ) => {
      if (value === null) {
        onChange(undefined);
      } else {
        onChange([value]);
      }
    },
    [onChange]
  );

  const selectedOption =
    activityCodeOptions.find((t) => activityCode.find((i) => i.code === t.code)) ?? null;

  const { t } = useTranslation();

  return (
    <Autocomplete<CounterpartyActivity | null>
      label={`${t('Specialization code')}`}
      options={activityCodeOptions}
      inputValue={input || ''}
      onInputChange={handleOnInputChange}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      onChange={handleOnChange}
      variant={'standard'}
      value={selectedOption}
      popupIcon={<KeyboardArrowDownRoundedIcon color="inherit" fontSize="medium" />}
      openText={t('Open')}
      closeText={t('Close')}
      clearText={t('Clear')}
      autoHighlight={true}
      noOptionsText={t('no results')}
    />
  );
};
