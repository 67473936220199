import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Button } from 'components';
import { ModalForm } from 'components/Modal';
import { RatingStyles } from './useStyles';

export type RatingModalFormProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  classes: RatingStyles;
};

export const RatingModalForm = (props: RatingModalFormProps) => {
  const { open, classes, onClose, onConfirm } = props;
  const { t } = useTranslation();

  return (
    <ModalForm open={open} onClose={onClose} width={500}>
      <div className={classes.modalRoot}>
        <Typography variant={'h2'} className={classes.modalTitle}>
          {t('Delete Rating')}
        </Typography>
        <Typography variant="subtitle2" className={classes.modalText}>
          {t('Are you sure you want to delete the rating')}
        </Typography>
        <div>
          <Button variant="contained" className={classes.modalButton} onClick={onConfirm}>
            {t('Yes')}
          </Button>
          <Button variant="outlined" onClick={onClose} className={classes.modalButton}>
            {t('No')}
          </Button>
        </div>
      </div>
    </ModalForm>
  );
};
