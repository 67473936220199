import React, { useMemo } from 'react';
import { themeOrange as theme } from 'theme';
import {
  makeStyles,
  createStyles,
  Theme,
  Typography,
  MenuItem,
  Portal,
  Paper,
} from '@material-ui/core';
import {
  ContractType,
  ContractViewModel,
  CounterpartyContractViewModel,
  QuotaCalculationResult,
  SendQuotaToExecutionViewModel as ContractFormValues,
} from 'schema/serverTypes';
import { useIssueContractMutation } from 'services';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useToast, Grid, Input, Select, Button } from 'components';
import { useHistory } from 'react-router';
import { LesseeAutocomplete } from '../../../tasks/LesseeAutocomplete';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(5.5),
    },
    required: {
      marginTop: theme.spacing(-1.5),
      color: theme.palette.text.primary,
    },
    panels: {
      marginBottom: 75,
    },
    link: {
      color: theme.palette.primary.main,
    },
    actions: {
      [theme.breakpoints.down(1200)]: {
        width: 'calc(100% - 85px)',
        left: 63,
      },
      display: 'flex',
      justifyContent: 'flex-start',
      width: 'calc(100% - 238px)',
      padding: theme.spacing('20px', '20px'),
      bottom: 0,
      left: 216,
      position: 'fixed',
      zIndex: 1,
    },
    actionsTask: {
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%',
      padding: theme.spacing('20px', '20px'),
      bottom: 0,
      position: 'static',
      zIndex: 1,
      marginBottom: theme.spacing(2),
    },
    actionButton: {
      marginRight: theme.spacing(2.5),
    },
  })
);

export type SimpleFormProps = {
  quota: QuotaCalculationResult;
  contract: ContractViewModel;
};

export const SimpleForm = (props: SimpleFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { quotaId = 0, lessee: lesseeInn = '', lesseeName: lesseeFullName = '' } = props.quota;
  const { leaseSubject = '' } = props.contract;
  const title = leaseSubject !== '' ? `№${quotaId} | ${leaseSubject}` : `№${quotaId}`;

  const defaultValues: ContractFormValues = useMemo(() => {
    const values = {
      lessee: {
        fullName: lesseeFullName,
        inn: lesseeInn,
      } as CounterpartyContractViewModel,
      documentUrl: '',
      description: '',
      title,
      link: {
        url: `/quotas/calculator/${quotaId}`,
        title: `№${quotaId}`,
      },
      contractType: ContractType.Lease,
    };

    return values;
  }, [lesseeFullName, lesseeInn, quotaId, title]);

  const { control, handleSubmit, setValue } = useForm({
    mode: 'all',
    defaultValues,
  });
  const toast = useToast();

  const { mutateAsync } = useIssueContractMutation<ContractFormValues, any>(quotaId, {
    method: 'PUT',
    onSuccess: (result) => {
      toast(
        <>
          Создана задача №
          <Link
            to={`/tasks/${result.issueId}`}
            style={{ color: theme.palette.primary.main }}
            className={classes.link}
          >
            {result.issueId}
          </Link>
        </>,
        'success'
      );
      history.push(`/quotas/calculator/${result.quotaId}`);
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const onSubmit = useMemo(() => {
    const submit = async (form: ContractFormValues) => {
      await mutateAsync(form);
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync]);

  const message = t('Required');
  const history = useHistory();

  return (
    <form id="newTask-form" onSubmit={onSubmit}>
      <Accordion defaultExpanded={true}>
        <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
          <Typography variant="subtitle1">Основные</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container columnSpacing={2} rowSpacing={2.5}>
            <Grid item md={6} xs={24}>
              <Select
                name="contractType"
                control={control}
                label={t('Contract type')}
                rules={{
                  required: {
                    value: true,
                    message,
                  },
                }}
              >
                <MenuItem value={ContractType.Lease}>{t('ContractType.Lease')}</MenuItem>
                <MenuItem value={ContractType.Loan}>{t('ContractType.Loan')}</MenuItem>
                <MenuItem value={ContractType.LeaseNoVAT}>{t('ContractType.LeaseNoVAT')}</MenuItem>
              </Select>
            </Grid>
            <LesseeAutocomplete control={control} setValue={setValue} />
            <Grid item md={24} xs={24}>
              <Input
                label={t('Link to documents')}
                control={control}
                name="documentUrl"
                rules={{
                  required: {
                    value: true,
                    message,
                  },
                }}
              />
            </Grid>
            <Grid item md={24} xs={24}>
              <Input
                label={t('Description')}
                control={control}
                name="description"
                multiline={true}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Portal container={document.body}>
        <Paper square className={classes.actions}>
          <div className={classes.actionButton}>
            <Button
              color="primary"
              size="medium"
              type="submit"
              variant="contained"
              onClick={onSubmit}
            >
              {t('Create task')}
            </Button>
          </div>
          <div className={classes.actionButton}>
            <Button
              onClick={() => history.goBack()}
              color="primary"
              size="medium"
              variant="outlined"
            >
              {t('Cancel')}
            </Button>
          </div>
        </Paper>
      </Portal>
    </form>
  );
};
