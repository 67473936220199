import { animated, useSpring } from 'react-spring';
import { memo } from 'react';
import { formatMoney } from 'components/utils/formatMoney';

export type AnimatedMoneyProps = {
  value: number;
};

export const AnimatedMoney = memo(({ value }: AnimatedMoneyProps) => {
  const { number } = useSpring({
    number: value,
  });
  return (
    <animated.div>
      {number.to((value) => formatMoney({ amount: value, fractionDigits: 0 }))}
    </animated.div>
  );
});
