import { Grid } from '@material-ui/core';
import { CountryRisksTable } from './CountryRisksTable';
import { Breadcrumbs, Paging, Role } from 'components';
import { useCountryRisksData } from './useCountryRisksData';
import { IndustryLeasingProductFilterForm } from './CountryRiskFilterForm';
import { useTranslation } from 'react-i18next';
import { AddButton } from '../AddButton';
import React from 'react';
import { useHistory } from 'react-router';
import { ApplicationRole } from '../../../schema';
import { PageTitle } from 'components/utils/pageTitle';

export const CountryRisksDataGrid = () => {
  const { t } = useTranslation();
  const { filter, paging, ...dataProps } = useCountryRisksData();
  const history = useHistory();

  const pageTitle = t('Dictionaries.CountryRisks') + ' - Bumblebee';
  PageTitle(pageTitle);

  const addHandler = () => {
    history.push('/dictionaries/countryRisks/add');
  };

  return (
    <>
      <Breadcrumbs link={'/dictionaries'} text={t('Section.dictionaries')} />
      <Grid container spacing={2} direction="column">
        <Grid container item justifyContent="space-between" alignItems="center">
          <Grid item>
            <IndustryLeasingProductFilterForm {...filter} />
          </Grid>
          <Role role={[ApplicationRole.Admin, ApplicationRole.RiskManager]}>
            <Grid item>
              <AddButton onClick={addHandler} />
            </Grid>
          </Role>
        </Grid>
        <Grid container item direction="column">
          <Grid item>
            <CountryRisksTable {...dataProps} />
          </Grid>
          <Grid item>
            <Paging {...paging} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
