import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useCallback, useEffect, useState } from 'react';
import { DragDropContext, PreDragActions, SensorAPI, SnapDragActions } from 'react-beautiful-dnd';
import initialData from './initial-data';
import { Column } from './Column';
import { Grid } from 'components';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    container: {
      height: 'calc(100vh - 128px)',
      overflow: 'hidden',
    },
    dnd: {
      height: '100%',
    },
  })
);

const useClickSensor = (api: SensorAPI) => {
  const start = useCallback(
    function start(event) {
      const currentColumn = event.target.parentNode.dataset.rbdDroppableId;
      const targetId = api.findClosestDraggableId(event) ?? '';

      const preDrag: PreDragActions | null = api.tryGetLock(targetId);
      if (!preDrag) {
        return;
      }

      const drag: SnapDragActions = preDrag.snapLift();
      currentColumn === 'from' ? drag.moveLeft() : drag.moveRight();
      setTimeout(() => drag.drop(), 300);
    },
    [api]
  );

  useEffect(() => {
    window.addEventListener('click', start);

    return () => {
      window.removeEventListener('click', start);
    };
  }, [start]);
};

export const Dnd = () => {
  const classes = useStyles();
  const [state, setState] = useState<any>(initialData);

  const onDragEnd = (result: any) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId) {
      return;
    }

    const start = state.columns[source.droppableId];
    const finish = state.columns[destination.droppableId];

    // Moving from one list to another
    const startTaskIds = Array.from(start.taskIds);
    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      taskIds: startTaskIds,
    };

    const finishTaskIds: string[] = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);
    if (finish.id === 'to') {
      finishTaskIds.sort((a: string, b: string) => {
        return state.tasks[a].content > state.tasks[b].content ? 1 : -1;
      });
    }
    const newFinish = {
      ...finish,
      taskIds: finishTaskIds,
    };

    const newState = {
      ...state,
      columns: {
        ...state.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };

    setState(newState);
  };

  return (
    <Grid container columnSpacing={1.5} rowSpacing={2.5} className={classes.container}>
      <Grid item md={8} xs={24}>
        menu
      </Grid>
      <Grid item md={16} xs={24}>
        <DragDropContext sensors={[useClickSensor]} onDragEnd={onDragEnd}>
          <Grid container columnSpacing={1.5} rowSpacing={2.5} className={classes.dnd}>
            {state.columnOrder.map((columnId: any) => {
              const column = state.columns[columnId];
              const tasks = column.taskIds.map((taskId: any) => state.tasks[taskId]);

              return (
                <Grid item md={12} xs={12} key={column.id}>
                  <Column column={column} tasks={tasks} />
                </Grid>
              );
            })}
          </Grid>
        </DragDropContext>
      </Grid>
    </Grid>
  );
};
