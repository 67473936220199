import { InputBaseClassKey, StyleRules } from '@material-ui/core';
import palette from '../paletteOrange';

const style: Partial<StyleRules<InputBaseClassKey>> = {
  root: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: 14,
    borderRadius: 4,
    '&:hover': {
      '& fieldset': {
        border: `1px solid ${palette.text.secondary} !important`,
        // TODO DELETE important
      },
    },
    '&.Mui-focused': {
      '& fieldset': {
        border: `1px solid ${palette.text.primary} !important`,
        // TODO DELETE important
      },
    },
    '&.Mui-disabled': {
      background: 'linear-gradient(180deg, #FFFFFF 17.11%, #FAF7F4 100%)',
      '-webkit-text-fill-color': palette.text.disabled,
      opacity: 1,
      '&:hover': {
        '& fieldset': {
          boxShadow: 'none',
        },
      },
    },
    '& fieldset': {
      border: `1px solid ${palette.text.secondary}`,
      '& legend': {
        fontSize: '0.75em',
        marginLeft: '-5px',
      },
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    '&.MuiOutlinedInput-multiline': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '&.MuiFilledInput-root': {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&.Mui-focused': {
        backgroundColor: 'transparent',
      },
    },
    '&.MuiFilledInput-underline': {
      '&:after': {
        borderBottom: 0,
      },
      '&:hover:before': {
        borderBottom: 0,
      },
      '&:before': {
        borderBottom: 0,
      },
    },
  },
};

export default style;
