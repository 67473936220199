import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteChangeDetails,
} from '@material-ui/lab/useAutocomplete';
import { Autocomplete } from 'components';
import { UserViewModel } from 'schema/serverTypes';
import { useUsersBackendQuery } from 'services/api';
import { UserListResult } from 'components/users/types';
import { Control, useController } from 'react-hook-form';
import { SearchFilterFormValues } from './types';

const getOptionLabel = (option: UserViewModel | null) => {
  if (option === null) {
    return '';
  }
  return option.name;
};

const getOptionSelected = (option: UserViewModel | null, value: UserViewModel | null) => {
  if (option === null || value === null) {
    return false;
  }
  return option.id === value.id;
};

const useSearchParams = (search?: string) => {
  return useMemo(() => {
    const searchParams = new URLSearchParams();
    searchParams.set('page', '1');
    searchParams.set('pageSize', '20');

    if (search && search !== '') {
      searchParams.set('search', search);
    }

    return searchParams;
  }, [search]);
};

export type UserAutocompleteProps = {
  control: Control<SearchFilterFormValues, object>;
};

export const UserAutocomplete = (props: UserAutocompleteProps) => {
  const { control } = props;
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState('');
  const [input] = useDebounce(inputValue, 500);
  const searchParams = useSearchParams(input);
  const { data } = useUsersBackendQuery<UserListResult>({
    searchParams,
    queryKey: ['users', input],
  });
  const options: UserViewModel[] =
    data?.data.map((t) => {
      return {
        id: t.id,
        name: t.name,
      };
    }) ?? [];

  const handleOnInputChange = useCallback(
    (_ev: React.ChangeEvent<{}>, value: string, _reason: AutocompleteInputChangeReason) => {
      setInputValue(value);
    },
    [setInputValue]
  );

  const {
    field: { value: user = null, onChange },
  } = useController({
    control,
    name: 'users',
  });

  const handleOnChange = useCallback(
    (
      _e: React.ChangeEvent<{}>,
      value: UserViewModel | null,
      _reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<UserViewModel | null>
    ) => {
      onChange(value);
    },
    [onChange]
  );

  return (
    <Autocomplete<UserViewModel | null>
      label={`${t('Search by user')}`}
      options={options}
      inputValue={inputValue}
      onInputChange={handleOnInputChange}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      onChange={handleOnChange}
      variant="standard"
      value={user}
    />
  );
};
