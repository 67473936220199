import { PageAuthenticatedLayout } from 'components';
import { HomePage as HomePageContent } from './HomePage';
import { EmptyPage as EmptyPageContent } from './EmptyPage';
export { FullScreenPage } from './FullScreenPage';

export const HomePage = () => {
  return <PageAuthenticatedLayout pageContent={<HomePageContent />} />;
};

export const EmptyPage = () => {
  return <PageAuthenticatedLayout pageContent={<EmptyPageContent />} />;
};
