import { OutlinedInputProps, TextField as MuiTextField } from '@material-ui/core';

type StaticInputProp = {
  label: string;
  value?: string;
  InputProps?: Partial<OutlinedInputProps>;
  disabled?: boolean;
};

export function StaticInput(props: StaticInputProp) {
  const { label, value, ...rest } = props;

  return (
    <MuiTextField
      label={label}
      value={value}
      variant="outlined"
      fullWidth={true}
      size="small"
      disabled
      {...rest}
    />
  );
}
