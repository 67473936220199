import { useTranslation } from 'react-i18next';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Button } from 'components/Button';
import { useCallback } from 'react';
import { IconSprite } from '../../icons';
import { themeOrange as theme } from 'theme';
import { MissingHistoryTime } from 'schema/serverTypes';

const times = [MissingHistoryTime.Months, MissingHistoryTime.Weeks];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      right: 0,
    },
    active: {
      backgroundColor: theme.palette.primary.light,
      pointerEvents: 'none',
      color: theme.palette.secondary.darker,
    },
    settings: {
      display: 'inline-block',
      cursor: 'pointer',
      marginLeft: theme.spacing(1.5),
    },
  })
);

export type MissingChartDateFilterProps = {
  value: MissingHistoryTime;
  setValue: (value: MissingHistoryTime) => void;
  openSidePanel: () => void;
};

export const MissingChartDateFilter = (props: MissingChartDateFilterProps) => {
  const classes = useStyles();
  const { value, setValue, openSidePanel } = props;

  return (
    <>
      <Box mt={2} mb={1.25} className={classes.root}>
        {times.map((item) => {
          return (
            <FilterItem currentFilter={value} setCurrentFilter={setValue} value={item} key={item} />
          );
        })}
        <div onClick={openSidePanel} className={classes.settings}>
          <IconSprite
            icon={'menu-settings'}
            width={'16px'}
            height={'16px'}
            color={theme.palette.primary.main}
          />
        </div>
      </Box>
    </>
  );
};

type FilteItemProps = {
  value: MissingHistoryTime;
  setCurrentFilter: (value: MissingHistoryTime) => void;
  currentFilter: MissingHistoryTime;
};

const FilterItem = ({ value, setCurrentFilter, currentFilter }: FilteItemProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    setCurrentFilter(value);
  }, [setCurrentFilter, value]);

  const buttonClass = currentFilter === value ? classes.active : '';

  let label = '';
  switch (value) {
    case MissingHistoryTime.Months:
      label = t('Quarter');
      break;
    case MissingHistoryTime.Weeks:
      label = t('Month');
      break;
  }

  return (
    <Button variant={'chip'} className={buttonClass} onClick={handleClick}>
      {label}
    </Button>
  );
};
