import { Autocomplete } from 'components';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteChangeDetails,
} from '@material-ui/lab/useAutocomplete';
import { useController } from 'react-hook-form';

export type RequestsRegionAutocompleteProps = Pick<any, 'control'>;

export const RequestsRegionAutocomplete = (props: RequestsRegionAutocompleteProps) => {
  const { control } = props;

  const { t } = useTranslation();

  const {
    field: { onChange, value: regions },
  } = useController({
    control,
    name: 'region',
    defaultValue: [],
  });

  const [region, setRegion] = useState('');

  useEffect(() => {
    if (regions === undefined && region !== undefined && region !== '') {
      setRegion('');
    }
  }, [region, regions, setRegion]);

  // const { data = [], isLoading } = useMissingStatusListRequest(request);
  const options = ['Москва', 'Самара'].filter((t) => !(regions ?? []).includes(t));

  const handleOnInputChange = useCallback(
    (_ev: React.ChangeEvent<{}>, value: string, _reason: AutocompleteInputChangeReason) => {
      setRegion(value);
    },
    [setRegion]
  );

  const handleOnChange = useCallback(
    (
      _e: React.ChangeEvent<{}>,
      value: string | null,
      _reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<string | null>
    ) => {
      if (value === null) {
        onChange([]);
        setRegion('');
      } else {
        onChange(regions ? [...regions.filter((v: any) => v !== value), value] : [value]);
        setRegion('');
      }
    },
    [onChange, regions]
  );

  return (
    <Autocomplete<string | null>
      label={t('Region')}
      options={options}
      inputValue={region}
      onInputChange={handleOnInputChange}
      onChange={handleOnChange}
      value={null}
      variant="standard"
    />
  );
};
