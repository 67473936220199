import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import { useStatusHistoryString } from './useStatusString';
import { IssueStatus } from 'schema/serverTypes';
import { Link } from 'react-router-dom';
import { useLanguage } from '../utils/useLanguage';
import clsx from 'clsx';
import { useUserQuery } from '../users/useUserQuery';
import { User } from '../users';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: 'white',
      margin: theme.spacing(2, 0),
      fontSize: 12,
      fontWeight: 300,
    },
    date: {
      color: theme.palette.text.primary,
    },
    owner: {
      color: theme.palette.common.black,
      fontWeight: 400,
    },
    title: {
      color: theme.palette.text.primary,
    },
  })
);

type StatusProps = {
  owner: { id: string; name: string; isActive: boolean };
  date: string;
  title: IssueStatus;
};

export const Status = (props: StatusProps) => {
  const classes = useStyles();
  const lang = useLanguage();
  const { owner, date, title } = props;
  const statusHistoryString = useStatusHistoryString(title);

  const ownerObj = useUserQuery(owner.id);
  const ownerIsActive = ownerObj.user?.isActive ?? true;

  return (
    <div className={classes.root}>
      <span className={classes.date}>
        {dayjs(date).locale(lang).format('DD MMM YYYY в HH:mm')}{' '}
      </span>
      <Link
        to={`/users/view/${owner.id}`}
        className={ownerIsActive ? classes.owner : clsx(classes.owner)}
      >
        <User name={owner.name} isActive={owner.isActive} />
      </Link>
      <span className={classes.title}> {statusHistoryString}</span>
    </div>
  );
};
