import { useQuotaCounterpatiesQuery } from 'services';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Risks } from './Risks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordionDetails: {
      padding: theme.spacing(0),
      flexDirection: 'column',
    },
    title: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: theme.palette.background.paper,
      padding: theme.spacing(1.5, 0),
    },
  })
);

export const RisksGrid = (props: { quotaId: number }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { quotaId } = props;

  const { data } = useQuotaCounterpatiesQuery(quotaId);

  return (
    <Accordion expanded={true}>
      <AccordionSummary aria-controls="panel3a-content" id="panel3a-header">
        <div className={classes.title}>
          <Typography variant="subtitle1">{t('POD FT')}</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {data && <Risks risks={data} quotaId={quotaId} />}
      </AccordionDetails>
    </Accordion>
  );
};
