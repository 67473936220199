import { MenuItem, makeStyles, createStyles, Theme, Divider } from '@material-ui/core';
import { Grid } from 'components/Grid';
import { Field, useFormState } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import {
  SelectField,
  SwitchField,
  RadioField,
  AmountField,
  RadioFieldOption,
  useRequired,
  Button,
  IconSprite,
} from 'components';
import {
  LeaseProductAutocomplete,
  ModelAutocompleteField,
  CounterpartyAutocompleteField,
} from '../DictionaryFields';
import { Currency, AmountType, CounterpartyType } from 'schema';
import { FormFieldsProps } from './types';
import { getNumber } from '../../utils';
import { useRequiredOnSave } from '../requiredOnSave';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { useDebounce } from 'use-debounce';
import { VatSwitch } from './VatSwitch';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: '100%',
    },
    divider: {
      marginBottom: theme.spacing(2.5),
    },
    commissionLabel: {
      textAlign: 'right',
      '& .MuiGrid-root': {
        flexWrap: 'nowrap',
      },
      '& .MuiFormControlLabel-root': {
        marginRigh: 0,
      },
    },
    nomenclatureOrderLink: {
      fontSize: 12,
      color: theme.palette.primary.main,
      marginLeft: theme.spacing(1),
    },
    add: {
      fontFamily: "'Halvar Breit', sans-serif",
      color: theme.palette.primary.main,
      textDecoration: 'none',
      fontWeight: 700,
    },
  })
);

export const SaleContractFields = (
  props: FormFieldsProps & { quotaId?: number; onOpenNomenclatures: () => void }
) => {
  const classes = useStyles();
  const {
    form,
    form: {
      mutators: { push },
    },
    quotaId,
    onOpenNomenclatures,
  } = props;
  const year = new Date().getFullYear() + 1;

  const { t } = useTranslation();
  const { requiredOnSave } = useRequiredOnSave();
  const { required } = useRequired();
  const {
    values: { nomenclatures },
  } = useFormState({
    subscription: {
      values: true,
    },
  });

  const [input] = useDebounce(nomenclatures, 300);

  const getNomenclaturesPrice = useCallback(() => {
    const result =
      input && input !== ''
        ? input.reduce(function (sum: number, current: any) {
            return (
              sum + (current?.price ? current.price : 0) * (current?.count ? current.count : 0)
            );
          }, 0)
        : 0;
    return result;
  }, [input]);

  const { change } = form;
  const setLesseeName = useCallback(
    (name?: string) => {
      change('lesseeName', name);
    },
    [change]
  );

  const setLesseeInn = useCallback(
    (inn?: string) => {
      change('lessee', inn);
    },
    [change]
  );

  const setNomenclatureCode = useCallback(
    (name: string, inn?: string) => {
      change(name as any, inn);
    },
    [change]
  );

  return (
    <div className={classes.wrapper}>
      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item xl={4} md={6} xs={24}>
          <Field
            name="leaseProduct"
            label={t('Sales channel')}
            component={LeaseProductAutocomplete}
            validate={requiredOnSave}
          />
        </Grid>
        <Grid item xl={6} md={9} xs={24}>
          <CounterpartyAutocompleteField
            label={t('Dealer')}
            counterpartyType={CounterpartyType.dealer}
            validate={requiredOnSave}
          />
        </Grid>
        <Grid item xl={6} md={9} xs={24}>
          <Field name="lessee">
            {({ input }) => {
              const { value: lesseeInn } = input;
              return (
                <Field name="lesseeName">
                  {({ input }) => {
                    const { value } = input;
                    return (
                      <CounterpartyAutocompleteField
                        label={t('Lessee')}
                        counterpartyType={CounterpartyType.lessee}
                        setLesseeName={setLesseeName}
                        lesseeName={value}
                        lesseeInn={lesseeInn}
                        setLesseeInn={setLesseeInn}
                      />
                    );
                  }}
                </Field>
              );
            }}
          </Field>
        </Grid>

        <FieldArray name="nomenclatures">
          {({ fields }) =>
            fields.map((name, index) => (
              <Grid item xs={24} key={name}>
                <Grid container columnSpacing={2} rowSpacing={2.5}>
                  <Grid item xl={10} md={11} xs={24}>
                    <Field name={`${name}.code`}>
                      {({ input }) => {
                        const { value } = input;
                        return (
                          <ModelAutocompleteField
                            name={`${name}`}
                            quotaId={quotaId}
                            code={value}
                            setNomenclatureCode={setNomenclatureCode}
                            onOpenNomenclatures={onOpenNomenclatures}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xl={3} md={4} xs={24}>
                    <AmountField
                      name={`${name}.price`}
                      label={t('ItemPrice')}
                      amountMode={AmountType.Money}
                      required
                    />
                  </Grid>
                  <Grid item xl={2} md={3} xs={24}>
                    <Field
                      name={`${name}.count`}
                      label={t('NumberOfItems')}
                      component={SelectField}
                      validate={required}
                    >
                      {Array.from(Array(100)).map((_, i) => {
                        return (
                          <MenuItem key={i + 1} value={i + 1}>
                            {i + 1}
                          </MenuItem>
                        );
                      })}
                    </Field>
                  </Grid>
                  <Grid item xl={2} md={3} xs={24}>
                    <Field name={`${name}.year`} label={t('Year')} component={SelectField}>
                      {Array.from(Array(12)).map((_, i) => {
                        return (
                          <MenuItem key={year - i} value={year - i}>
                            {year - i}
                          </MenuItem>
                        );
                      })}
                    </Field>
                    <OnChange name={`${name}.year`}>
                      {(value) => {
                        const selectedYear = getNumber(value);
                        const currentYear = new Date().getFullYear();
                        form.change(`${name}.isSecondHand` as any, selectedYear < currentYear);
                      }}
                    </OnChange>
                  </Grid>
                  <Grid item xl={2} md={2} xs={24}>
                    <Field
                      name={`${name}.isSecondHand`}
                      labelOn={t('SecondHand')}
                      labelOff={t('New')}
                      type="checkbox"
                      component={SwitchField}
                    />
                  </Grid>
                  <Grid item xl={2} md={1} xs={24}>
                    <Button
                      variant="iconButton"
                      endIcon={<IconSprite icon={'delete'} width={'14px'} />}
                      onClick={() => fields.remove(index)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))
          }
        </FieldArray>
        <Button
          variant="text"
          className={classes.add}
          onClick={() =>
            push('nomenclatures', {
              count: 1,
              year: new Date().getFullYear(),
            })
          }
        >
          + {t('Add')}
        </Button>
      </Grid>

      <Divider light className={classes.divider} />

      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item xl={2} lg={2} md={2} xs={24}>
          <VatSwitch form={form} />
        </Grid>
        <Grid item xl={2} lg={2} md={2} xs={24}>
          <Field<boolean> name="vatEnabled">
            {({ input }) => {
              const disabled = input.value === false;
              return (
                <AmountField
                  name="vatPercents"
                  label={t('Vat')}
                  amountMode={AmountType.Percents}
                  disabled={disabled}
                />
              );
            }}
          </Field>
        </Grid>
        <Grid item xl={4} lg={6} md={8} xs={24}>
          <AmountField
            name="itemPrice"
            label={t('Общая стоимость по ДКП')}
            amountMode={AmountType.Money}
            disabled
            inputProps={{ value: getNomenclaturesPrice() }}
          />
        </Grid>
        <Grid item md={'auto'} xs={24}>
          <Field
            name="currencySale"
            label={t('Currencies.Sale')}
            component={RadioField}
            type="text"
            options={[
              { label: '₽', value: Currency.Ruble } as RadioFieldOption,
              { label: '$', value: Currency.Dollar } as RadioFieldOption,
              { label: '€', value: Currency.Euro } as RadioFieldOption,
              { label: '¥', value: Currency.Yuan } as RadioFieldOption,
            ]}
          />
        </Grid>
        <Grid item xl={3} lg={4} md={7} xs={24}>
          <AmountField
            name="currencyRate"
            label={t('Currencies.Rate')}
            amountMode={AmountType.Money}
            fractionDigits={4}
          />
        </Grid>
        <Grid item md={'auto'} xs={24}>
          <Field
            name="currencyLease"
            label={t('Currencies.Lease')}
            component={RadioField}
            type="text"
            options={[
              { label: '₽', value: Currency.Ruble } as RadioFieldOption,
              { label: '$', value: Currency.Dollar, disabled: true } as RadioFieldOption,
              { label: '€', value: Currency.Euro, disabled: true } as RadioFieldOption,
              { label: '¥', value: Currency.Yuan, disabled: true } as RadioFieldOption,
            ]}
          />
        </Grid>
        <Grid item md={'auto'} xs={24}>
          <Field
            name="currencyCommission"
            label={t('Currencies.ConversionFee')}
            labelOn={t('Yes')}
            labelOff={t('No')}
            type="checkbox"
            classes={{
              root: classes.commissionLabel,
            }}
            component={SwitchField}
          />
        </Grid>
        <Grid item xl={4} lg={7} md={7} xs={24}>
          <AmountField
            name="leaseItemCost"
            label={t('LeaseItemCost')}
            amountMode={AmountType.Money}
          />
        </Grid>
      </Grid>
    </div>
  );
};
