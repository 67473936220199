import { Button, IconSprite } from 'components';
import { useCallback } from 'react';
import { FieldArrayMethodProps } from 'react-hook-form/dist/types/fieldArray';
import { QuotaNomenclatureViewModel } from '../../../../../schema';

export type RemoveButtonProps = {
  index: number;
  onRemove: (index: number) => void;
  append: (
    value: QuotaNomenclatureViewModel | QuotaNomenclatureViewModel[],
    options?: FieldArrayMethodProps | undefined
  ) => void;
};

export const RemoveButton = (props: RemoveButtonProps) => {
  const { index, onRemove, append } = props;

  const handleOnRemove = useCallback(() => {
    if (index === 0) {
      append({
        count: 1,
        year: new Date().getFullYear(),
        name: '',
        isSecondHand: false,
        price: 0,
      });
    }
    onRemove(index);
  }, [index, onRemove, append]);

  return (
    <Button
      variant="iconButton"
      endIcon={<IconSprite icon={'delete'} width={'14px'} />}
      onClick={handleOnRemove}
    />
  );
};
