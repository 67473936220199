import { useCallback, useEffect, useMemo } from 'react';
import {
  NomenclatureCategoryScheduleFilter,
  NomenclatureCategoryScheduleSortBy,
  SortOrder,
} from 'schema/serverTypes';
import { useForm, useWatch } from 'react-hook-form';
import { useDebounce } from 'use-debounce';
import { usePaging } from 'components/paging/usePaging';
import { useSorting } from 'components/sorting/useSorting';
import { useNomenclatureCategoryScheduleListQuery } from 'services/api/useNomenclatureCategoryScheduleBackend';

export const usePDCData = () => {
  const { page, pageSize, onPageChanged, onPageSizeChanged, onReset } = usePaging();

  const { sortBy, order, setOrder, setSortBy } = useSorting<NomenclatureCategoryScheduleSortBy>({
    sortBy: NomenclatureCategoryScheduleSortBy.Code,
    order: SortOrder.asc,
  });

  const handleSortBy = useCallback(
    (sortBy: NomenclatureCategoryScheduleSortBy) => {
      setSortBy(sortBy);
    },
    [setSortBy]
  );

  const handleSortOrder = useCallback(
    (order: SortOrder) => {
      setOrder(order);
    },
    [setOrder]
  );

  const sorting = useMemo(() => {
    return {
      sortBy,
      order,
      setSortBy: handleSortBy,
      setOrder: handleSortOrder,
    };
  }, [sortBy, order, handleSortBy, handleSortOrder]);

  const { control, reset } = useForm<NomenclatureCategoryScheduleFilter>({
    mode: 'onBlur',
  });

  const handleOnReset = useCallback(() => {
    reset();
  }, [reset]);

  const code = useWatch({ control, name: 'code' });
  const categoryNameValue = useWatch({ control, name: 'categoryName' });
  const categoryCodeValue = useWatch({ control, name: 'categoryCode' });

  const [categoryName] = useDebounce(categoryNameValue, 500);
  const [categoryCode] = useDebounce(categoryCodeValue, 500);

  const { data, isLoading } = useNomenclatureCategoryScheduleListQuery({
    code,
    categoryName,
    categoryCode,
    page,
    pageSize: pageSize,
    sortBy,
    order,
  });

  const filter = useMemo(() => {
    return {
      control,
      handleOnReset,
    };
  }, [control, handleOnReset]);

  const rows = data?.data ?? [];
  const pageCount = data?.pageCount ?? 0;
  const totalCount = data?.totalCount ?? 0;
  const length = rows.length;

  const paging = useMemo(() => {
    return {
      pageCount,
      totalCount,
      page,
      pageSize,
      dataCount: length,
      onPageChanged,
      onPageSizeChanged,
      onReset,
    };
  }, [pageCount, totalCount, page, pageSize, length, onPageChanged, onPageSizeChanged, onReset]);

  useEffect(() => {
    onPageChanged(1);
  }, [code, categoryName, categoryCode, onPageChanged]);

  return {
    paging,
    filter,
    sorting,
    isLoading,
    data: data?.data ?? [],
  };
};
