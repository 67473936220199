import { Grid, Box } from '@material-ui/core';
import { Paging, TaskItemError } from 'components/index';
import { useTranslation } from 'react-i18next';
import { Empty } from '../../Empty';
import { TaskViewModel } from 'schema/serverTypes';
import { IssuesFilterFormError } from '../IssuesFilterFormError';
import { useErrorsData, useIssuesFilter } from './useErrorsData';
import { PageTitle } from 'components/utils/pageTitle';

export const IssueListError = () => {
  const { t } = useTranslation();
  const filter = useIssuesFilter(true);
  const { paging, issues } = useErrorsData({ control: filter.control });

  const pageTitle = t('Puls') + ' - Bumblebee';
  PageTitle(pageTitle);

  return (
    <>
      <Box display="flex" justifyContent="space-between" mb={2.5} alignItems="flex-end">
        <Box flex={1}>
          <IssuesFilterFormError {...filter} />
        </Box>
      </Box>
      <Grid container direction="column">
        <Grid container item direction="column">
          <Grid item>
            {issues.length === 0 && <Empty>{t("You don't have any tasks yet")}</Empty>}
            {issues.map((issue: TaskViewModel) => {
              return <TaskItemError key={issue.id} {...issue} />;
            })}
          </Grid>
          {issues.length > 0 && (
            <Grid item>
              <Paging {...paging} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};
