import { Route, Switch } from 'react-router-dom';
import { Critical, WikiEdit, WikiDataGrid, WikiCreate, PrivateRoute } from 'components';
import { useErrorContext } from 'components/tasks';
import { useTranslation } from 'react-i18next';
import { ApplicationRole, IssueSource } from 'schema/serverTypes';

export const WikiPage = () => {
  const { t } = useTranslation();

  useErrorContext({
    title: t('Wiki'),
    source: IssueSource.wiki,
  });

  return (
    <>
      <Critical />
      <Switch>
        <PrivateRoute
          path="/wiki/create"
          exact
          component={WikiCreate}
          role={[ApplicationRole.Admin]}
        />
        <Route path="/wiki/:id" exact component={WikiEdit} />
        <Route path="/wiki" exact component={WikiDataGrid} />
      </Switch>
    </>
  );
};
