import { useCounterpartyRisksQuery } from '../../../services/api/useCounterpartiesRiskBackend';
import { useParams } from 'react-router-dom';
import { RatingRiskType, RiskTypeViewModel } from 'schema';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { formatDate } from '../../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(0),
    },
    risk: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(0.5),
      witch: '100%',
    },
    label: {
      fontSize: 12,
      color: theme.palette.text.secondary,
    },
    value: {
      fontWeight: 500,
      fontSize: 12,
      paddingRight: theme.spacing(6),
    },
    low: {
      color: theme.palette.success.main,
    },
    medium: {
      color: theme.palette.primary.main,
    },
    high: {
      color: theme.palette.error.main,
    },
  })
);

export const CounterpartyRisks = () => {
  const classes = useStyles();

  const { inn } = useParams<{ inn: string }>();
  const { data: risk, isLoading } = useCounterpartyRisksQuery(inn);

  if (isLoading || risk === undefined) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Risk {...risk} key={risk.id} />
    </div>
  );
};

const Risk = ({ name, ratingType, isTotal, date }: RiskTypeViewModel) => {
  const classes = useStyles(isTotal);

  return (
    <>
      <div className={classes.risk}>
        <div>
          <Typography variant={'body2'} className={classes.label}>
            Уровень риска:
          </Typography>
        </div>
        <div>
          <Typography variant={'body2'} className={classes.value}>
            <RatingTypeColored ratingType={ratingType} />
          </Typography>
        </div>
        {date && (
          <>
            <div>
              <Typography variant={'body2'} className={classes.label}>
                Дата присвоения:
              </Typography>
            </div>
            <div>
              <Typography variant={'body2'} className={classes.value}>
                {formatDate(date)}
              </Typography>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export const RatingTypeColored = ({ ratingType }: { ratingType: RatingRiskType }) => {
  const classes = useStyles();
  return ratingType === RatingRiskType.none ? (
    <span>Не установлен</span>
  ) : ratingType === RatingRiskType.low ? (
    <span className={classes.low}>Низкий</span>
  ) : ratingType === RatingRiskType.medium ? (
    <span className={classes.medium}>Средний</span>
  ) : ratingType === RatingRiskType.high ? (
    <span className={classes.high}>Высокий</span>
  ) : ratingType === RatingRiskType.unacceptable ? (
    <span className={classes.high}>Неприемлимый</span>
  ) : (
    <span />
  );
};
