import React, { useCallback, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { IndustryLeasingProductsTable } from './IndustryLeasingProductsTable';
import { Breadcrumbs, Pagination } from 'components';
import { useIndustryLeasingProductsData } from './useIndustryLeasingProductsData';
import { ModalForm, useModalForm } from '../../Modal/ModalForm';
import { AddButton } from '../AddButton';
import { AddIndustryLeasingProductForm } from './AddIndustryLeasingProductForm';
import { IndustryLeasingProductViewModel } from 'schema/serverTypes';
import { IndustryLeasingProductFilterForm } from './IndustryLeasingProductFilterForm';
import { EditIndustryLeasingProductForm } from './EditIndustryLeasingProductForm';
import { useTranslation } from 'react-i18next';
import { PageTitle } from 'components/utils/pageTitle';

export const IndustryLeasingProductsDataGrid = () => {
  const { t } = useTranslation();
  const { filter, paging, ...dataProps } = useIndustryLeasingProductsData();
  const { onOpen, onClose, open } = useModalForm();

  const [item, setItem] = useState<IndustryLeasingProductViewModel>();

  const pageTitle = t('Dictionaries.IndustryLeasingProducts') + ' - Bumblebee';
  PageTitle(pageTitle);

  const handleOnOpen = useCallback(
    (item: IndustryLeasingProductViewModel) => {
      setItem(item);
      onOpen();
    },
    [onOpen],
  );

  const handleOnClose = useCallback(() => {
    setItem(undefined);
    onClose();
  }, [onClose]);

  return (
    <>
      <Breadcrumbs link={'/dictionaries'} text={t('Section.dictionaries')} />
      <Box display="flex" justifyContent="space-between" mb={2.5} alignItems="flex-end">
        <Box>
          <IndustryLeasingProductFilterForm {...filter} />
        </Box>
        <Box>
          <AddButton onClick={onOpen} />
        </Box>
      </Box>
      <Grid container item direction="column">
        <Grid item>
          <IndustryLeasingProductsTable {...dataProps} onOpen={handleOnOpen} />
        </Grid>
        <Grid item>
          <Pagination {...paging} />
        </Grid>
      </Grid>
      <ModalForm open={open} onClose={handleOnClose}>
        {item === undefined && <AddIndustryLeasingProductForm onSuccess={handleOnClose} />}
        {item && <EditIndustryLeasingProductForm onSuccess={handleOnClose} item={item} />}
      </ModalForm>
    </>
  );
};
