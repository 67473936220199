import { CreateFormFieldValues } from './types';

export const getFormData = (values: CreateFormFieldValues) => {
  const formData = new FormData();
  formData.append('kind', values.kind);
  if (values.title !== '') {
    formData.append('title', values.title);
  }
  if (values.description !== '') {
    formData.append('description', values.description);
  }
  if (values.file !== null) {
    formData.append('file', values.file);
  }
  if (values.users.length > 0) {
    const users = values.users.length > 0 ? values.users.map((t) => t.id).join(',') : '';
    formData.append('users', users);
  }
  if (values.lessor) {
    formData.append('lessor', values.lessor.inn);
  }
  if (values.dealers.length > 0) {
    const dealers = values.dealers.length > 0 ? values.dealers.map((t) => t.inn).join(',') : '';
    formData.append('dealers', dealers);
  }
  formData.append(
    'isAvailabelForAllUsers',
    values.isAvailabelForAllUsers === true ? 'true' : 'false'
  );
  formData.append(
    'isAvailableSendingToClient',
    values.isAvailableSendingToClient === true ? 'true' : 'false'
  );
  return formData;
};
