import { Control, useWatch } from 'react-hook-form';
import { TemplateKind } from 'schema/serverTypes';
import { CreateFormFieldValues } from './types';

export const useTemplateKind = (control: Control<CreateFormFieldValues, object>) => {
  const kind = useWatch({ control, name: 'kind' });
  const isCalculationKind = kind === TemplateKind.Calculation;
  const isContractKind = kind === TemplateKind.Contract;

  return {
    isCalculationKind,
    isContractKind,
  };
};
