import {
  DealQualificationRuleViewModel,
  AddDealQualificationRuleRequest,
  DealQualificationRulePagedList,
  UpdateDealQualificationRuleRequest,
  DealQualificationAvailableRuleViewModel,
  PageOptions,
  SortOrder,
} from 'schema/serverTypes';
import { useDictionaryBackendQuery, useDictionaryBackendMutation } from './useQuotasBackend';
import { MutationOptions } from './useBackend';

type QualificationsRequestType = PageOptions & {
  sortBy: any;
  order: SortOrder;
} & {
  search?: string;
};

export const useDealQualificationsQuery = (request: QualificationsRequestType) => {
  const { page, pageSize, sortBy, order, search } = request;
  let searchParams = new URLSearchParams();

  searchParams.append('sortBy', sortBy);
  searchParams.append('orderBy', order);

  if (page) {
    searchParams.set('page', page.toString());
  }
  if (pageSize) {
    searchParams.set('pageSize', pageSize.toString());
  }
  if (search && search !== '') {
    searchParams.set('search', search);
  }
  return useDictionaryBackendQuery<DealQualificationRulePagedList>(
    `dealqualifications?${searchParams}`,
    {}
  );
};

export const useAddDealQualificationMutation = (
  options?: MutationOptions<AddDealQualificationRuleRequest, DealQualificationRuleViewModel>
) =>
  useDictionaryBackendMutation<AddDealQualificationRuleRequest, DealQualificationRuleViewModel>(
    'dealqualifications',
    {
      method: 'POST',
      ...options,
    }
  );

export const useUpdateDealQualificationMutation = (
  id: number,
  options?: MutationOptions<UpdateDealQualificationRuleRequest, DealQualificationRuleViewModel>
) =>
  useDictionaryBackendMutation<UpdateDealQualificationRuleRequest, DealQualificationRuleViewModel>(
    `dealqualifications/${id.toString()}`,
    { method: 'PUT', ...options }
  );

export const useDealQualificationsAvailableQuery = () =>
  useDictionaryBackendQuery<DealQualificationAvailableRuleViewModel[]>(
    'dealqualifications/available'
  );
