import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import { themeOrange as theme } from 'theme';
import { TabPanel } from 'components/Tabs';
import { ScoringIssueDashboardItem } from 'schema/serverTypes';
import { InsideSalesItem } from './InsideSalesItem';
import { useScoringIssueDashboardRequest } from '../../../services';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { InsideSalesTotal } from './InsideSalesTotal';
import { Empty } from '../../Empty';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'white',
      paddingTop: theme.spacing(1.5),
      height: '100%',
    },
    head: {
      fontSize: 12,
      fontWeight: 400,
      padding: 6,
      verticalAlign: 'bottom',
      '&.MuiTableCell-root': {
        color: theme.palette.text.secondary,
      },
      '&.MuiTableCell-root:first-child': {
        padding: 6,
      },
      '&.MuiTableCell-root:last-child': {
        padding: 6,
      },
    },
    id: {
      width: 100,
    },
    lessee: {
      width: '30%',
    },
    vertical: {
      position: 'relative',
      width: 36,
      height: 92,
      '& span': {
        display: 'flex',
        left: '50%',
        bottom: -12,
        position: 'absolute',
        alignItems: 'center',
        width: 92,
        height: 36,
        lineHeight: 1,
        transform: 'rotate( -90deg )',
        transformOrigin: 'center left',
      },
    },
    border: {
      '&.MuiTable-root': {
        borderSpacing: '0 4px',
        borderCollapse: 'separate',
        boxShadow: 'none',
      },
      '& + $border': {
        marginLeft: theme.spacing(0.75),
      },
    },
    noshadow: {
      boxShadow: 'none',
    },
    empty: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 48,
      fontWeight: 500,
      color: theme.palette.text.secondary,
      height: '100%',
    },
  })
);

export type InsideSalesTabProps = {
  id: number;
  tabIndex: number;
  index: number;
};

export const InsideSalesTab = (props: InsideSalesTabProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { id, tabIndex, index } = props;

  const { data, isLoading } = useScoringIssueDashboardRequest(id.toString());
  const items = data?.items ?? [];
  const unsolvedScoringIssuesCount = data?.unsolvedScoringIssuesCount ?? [0, 0];

  const issueControlsHeaders = items[0]?.issueControls.map((item) => (
    <TableCell key={item.text} className={clsx(classes.head, classes.vertical)}>
      <span>{item.text}</span>
    </TableCell>
  ));

  const EmptyTab = () => (
    <Typography component={'div'} className={classes.empty}>
      Нет задач в работе
    </Typography>
  );

  return (
    <TabPanel value={tabIndex} index={index} dir={theme.direction}>
      <TableContainer className={classes.root}>
        {items.length ? (
          <>
            <Table className={classes.border}>
              <TableHead>
                <TableRow>
                  <TableCell className={clsx(classes.head, classes.id)}>
                    {t('Task number')}
                  </TableCell>
                  <TableCell className={classes.head}>{t('Assignee')}</TableCell>
                  <TableCell className={classes.head}>{t('Author')}</TableCell>
                  <TableCell className={clsx(classes.head, classes.lessee)}>
                    {t('Lessee')}
                  </TableCell>
                  {issueControlsHeaders}
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item: ScoringIssueDashboardItem) => (
                  <InsideSalesItem key={item.id} {...item} />
                ))}
              </TableBody>
            </Table>
            <Table className={classes.noshadow}>
              <TableBody>
                <InsideSalesTotal unsolvedScoringIssuesCount={unsolvedScoringIssuesCount} />
              </TableBody>
            </Table>
          </>
        ) : isLoading ? (
          <Empty>{t('Please wait while the data is loading')}</Empty>
        ) : (
          <EmptyTab />
        )}
      </TableContainer>
    </TabPanel>
  );
};
