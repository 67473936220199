import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, createStyles, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { themeOrange as theme } from 'theme';
import { Tabs, useTabs } from 'components/Tabs';
import SwipeableViews from 'react-swipeable-views';
import dayjs from 'dayjs';
import { Empty } from '../../Empty';
import { useCurrentUserTabsQuery, useMissingLastImportDateRequest } from 'services/api';
import { ApplicationUserTab } from 'schema/serverTypes';
import { OverdueTab } from './OverdueTab';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: theme.spacing(1.5, 2.5),
      minHeight: 388,
      height: '100%',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
      paddingRight: theme.spacing(9),
    },
    tabs: {
      marginBottom: -2,
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr 64px 96px auto',
      alignItems: 'center',
      '& > div:first-of-type': {
        gridColumn: 2,
      },
    },
    managers: {},
    head: {
      fontSize: 10,
      fontWeight: 400,
      padding: 6,
      '&$managers': {
        fontSize: 12,
        fontWeight: 600,
        textAlign: 'left',
      },
      '&$shipment': {
        textAlign: 'center',
      },
      '&.MuiTableCell-root:first-child': {
        padding: 6,
      },
      '&.MuiTableCell-root:last-child': {
        padding: 6,
      },
    },
    name: {
      fontWeight: 600,
      fontSize: 12,
      padding: theme.spacing(0.75),
      marginRight: theme.spacing(2),
      '&': {
        paddingLeft: 6,
      },
    },
    shipment: {
      textAlign: 'right',
      marginRight: theme.spacing(2),
      '&': {
        paddingLeft: 6,
      },
    },
    nbv: {
      textAlign: 'right',
      paddingRight: 6,
    },
    update: {
      fontSize: 12,
      color: theme.palette.text.secondary,
    },
  })
);

export const OverdueContainer = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { data = [], isLoading } = useCurrentUserTabsQuery();
  const groups = data.filter((t) => t.id !== ApplicationUserTab.mine);

  const tabs = useMemo(() => {
    return groups
      .filter((t) => t.id !== ApplicationUserTab.mine)
      .map((group) => {
        return group.name.length >= 15 ? `${group.name.substr(0, 11)}...` : group.name;
      });
  }, [groups]);

  const tabsProps = useTabs(tabs);
  const { tabIndex, onChangeTabIndex, onChangeTab } = tabsProps;
  const { data: importDates } = useMissingLastImportDateRequest();

  return (
    <Paper className={classes.root}>
      <Box className={classes.header}>
        <Typography variant="subtitle1">
          {t('Overdue')}{' '}
          <Typography component="span" variant="body2" className={classes.update}>
            {importDates?.overdues && (
              <>
                {t('Update')} {dayjs(importDates?.overdues).format('DD.MM.YY')}
              </>
            )}
          </Typography>
        </Typography>
        {tabs.length !== 0 && (
          <Tabs
            {...tabsProps}
            value={tabIndex}
            onChangeTab={onChangeTab}
            className={classes.tabs}
          />
        )}
      </Box>
      {isLoading ? (
        <Empty>{t('Please wait while the data is loading.')}</Empty>
      ) : (
        <>
          <SwipeableViews
            containerStyle={{
              transition: 'transform 0.6s ease-out 0s',
            }}
            springConfig={{
              duration: '0.6s',
              easeFunction: 'transform 0.6s ease-out 0s',
              delay: '0s',
            }}
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={tabIndex}
            onChangeIndex={onChangeTabIndex}
          >
            {groups.map((tab, i) => (
              <OverdueTab key={tab.id} id={tab.id} tabIndex={tabIndex} index={i} />
            ))}
          </SwipeableViews>
        </>
      )}
    </Paper>
  );
};
