import { TableBodyClassKey, StyleRules } from '@material-ui/core';
import palette from '../paletteOrange';

type MuiTableCellStyles = Partial<StyleRules<TableBodyClassKey>>;

const style: MuiTableCellStyles = {
  root: {
    '& .MuiTableCell-body': {
      verticalAlign: 'top',
      '& a': {
        color: palette.primary.main,
        borderBottom: 'none',
        textDecoration: 'underline',
        fontWeight: 400,
        '&:hover': {
          textDecoration: 'none',
        },
        '&[href^="mailto:"]': {
          color: palette.common.black,
          fontSize: '12px',
          textDecoration: 'underline',
          '&:hover': {
            textDecoration: 'none',
          },
        },
      },
    },
    '& .MuiTableRow-root': {
      '&:hover .MuiTableCell-root': {
        backgroundColor: palette.background.light,
      },
    },
  },
};

export default style;
