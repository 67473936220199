import { AccordionSummaryClassKey, StyleRules } from '@material-ui/core';
import palette from '../paletteOrange';

const style: Partial<StyleRules<AccordionSummaryClassKey>> = {
  root: {
    backgroundColor: palette.background.paper,
    marginBottom: -1,
    padding: '0 20px',
    minHeight: 26,
    '&$expanded': {
      minHeight: 26,
      borderBottom: `1px solid ${palette.background.dark}`,
    },
    '&$disabled': {
      opacity: 1,
      backgroundColor: palette.background.paper,
      borderBottom: '1px solid' + palette.secondary.main,
    },
  },
  content: {
    alignItems: 'flex-end',
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
    '& .MuiTypography-root': {
      lineHeight: 1.2,
    },
  },
  expanded: {},
};

export default style;
