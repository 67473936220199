import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { IssueViewModel, ScoringApprovalViewModel, ScoringRouteAction } from 'schema';
import { StatusItem } from './StatusItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      lineHeight: '16px',
    },
  })
);

export type StatusTableProps = {
  rows: ScoringApprovalViewModel[];
  isLocked: boolean;
  quotaId: number;
  selectedScoringModelId?: number;
  availableAction?: ScoringRouteAction;
  scoringIssue?: IssueViewModel;
  contractIssue?: IssueViewModel;
  scoringApprovalIssue?: IssueViewModel;
  hasUnapprovedNomenclature?: boolean;
};

export const StatusTable = (props: StatusTableProps) => {
  const classes = useStyles();
  const {
    rows,
    quotaId,
    isLocked,
    selectedScoringModelId,
    availableAction,
    contractIssue,
    scoringIssue,
    scoringApprovalIssue,
    hasUnapprovedNomenclature,
  } = props;

  return (
    <div className={classes.root}>
      {rows.map((row, index) => (
        <StatusItem
          row={row}
          index={index}
          key={row.id + row.name}
          quotaId={quotaId}
          isLocked={isLocked}
          selectedScoringModelId={selectedScoringModelId}
          availableAction={availableAction}
          contractIssue={contractIssue}
          scoringIssue={scoringIssue}
          scoringApprovalIssue={scoringApprovalIssue}
          hasUnapprovedNomenclature={hasUnapprovedNomenclature}
        />
      ))}
    </div>
  );
};
