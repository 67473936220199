import { MenuItem } from '@material-ui/core';
import { Control, useController } from 'react-hook-form';
import {
  RatingAnswer1,
  RatingAnswer2,
  RatingAnswer3,
  RatingAnswer4,
  RatingAnswer5,
  RatingAnswer6,
  RatingAnswer7,
  RatingAnswer8,
  RatingAnswer9,
  RatingAnswer10,
  RatingAnswer11,
  RatingAnswer12,
  RatingAnswer13,
  RatingAnswer14,
  RatingAnswer15,
  RatingAnswer16,
  RatingAnswer17,
  RatingAnswer18,
} from 'schema/serverTypes';
import { RatingInputValues } from './types';
import { Select, MultiSelect } from 'components';
import { ChangeEvent, useState } from 'react';

export type RatingQuestionProps = {
  control: Control<RatingInputValues & { quarter: string; year: string }, any>;
};

export const RatingQuestion1 = (props: RatingQuestionProps) => {
  const { control } = props;
  return (
    <Select
      name="question1"
      control={control}
      rules={{
        required: {
          value: true,
          message: '',
        },
      }}
    >
      <MenuItem value={undefined}></MenuItem>
      <MenuItem value={RatingAnswer1.High}>Высокая</MenuItem>
      <MenuItem value={RatingAnswer1.Medium}>Средняя</MenuItem>
      <MenuItem value={RatingAnswer1.Low}>Низкая</MenuItem>
    </Select>
  );
};

export const RatingQuestion2 = (props: RatingQuestionProps) => {
  const { control } = props;
  return (
    <Select
      name="question2"
      control={control}
      rules={{
        required: {
          value: true,
          message: '',
        },
      }}
    >
      <MenuItem value={RatingAnswer2.Negatively}>Отрицательно</MenuItem>
      <MenuItem value={RatingAnswer2.Neutrally}>Нейтрально</MenuItem>
      <MenuItem value={RatingAnswer2.Positively}>Положительно</MenuItem>
    </Select>
  );
};

export const RatingQuestion3 = (props: RatingQuestionProps) => {
  const { control } = props;
  return (
    <Select
      name="question3"
      control={control}
      rules={{
        required: {
          value: true,
          message: '',
        },
      }}
    >
      <MenuItem value={RatingAnswer3.DontKnow}>Не знаю</MenuItem>
      <MenuItem value={RatingAnswer3.OftenBelowCompetition}>Часто ниже конкурентов</MenuItem>
      <MenuItem value={RatingAnswer3.OutperformsCompetition}>Превосходит конкурентов</MenuItem>
      <MenuItem value={RatingAnswer3.RoughtlyInLineWithCompetition}>
        На одном уровне с конкурентами
      </MenuItem>
    </Select>
  );
};

export const RatingQuestion4 = (props: RatingQuestionProps) => {
  const { control } = props;
  return (
    <Select
      name="question4"
      control={control}
      rules={{
        required: {
          value: true,
          message: '',
        },
      }}
    >
      <MenuItem value={RatingAnswer4.Stable}>Стабильная</MenuItem>
      <MenuItem value={RatingAnswer4.SlightlyVolatile}>Низко волатильная</MenuItem>
      <MenuItem value={RatingAnswer4.ModerateVolatile}>Умеренно волатильная</MenuItem>
      <MenuItem value={RatingAnswer4.HighlyVolatile}>Высоко волатильная</MenuItem>
    </Select>
  );
};

export const RatingQuestion5 = (props: RatingQuestionProps) => {
  const { control } = props;
  return (
    <Select
      name="question5"
      control={control}
      rules={{
        required: {
          value: true,
          message: '',
        },
      }}
    >
      <MenuItem value={RatingAnswer5.Average}>Обычный</MenuItem>
      <MenuItem value={RatingAnswer5.BelowAverage}>Ниже среднего</MenuItem>
      <MenuItem value={RatingAnswer5.DontKnow}>Не знаю</MenuItem>
      <MenuItem value={RatingAnswer5.Good}>Хорошо</MenuItem>
      <MenuItem value={RatingAnswer5.Poor}>Плохой</MenuItem>
      <MenuItem value={RatingAnswer5.VeryGood}>Отлично</MenuItem>
    </Select>
  );
};

export const RatingQuestion6 = (props: RatingQuestionProps) => {
  const { control } = props;
  const {
    field: { onChange },
  } = useController({ control, name: 'question6' });

  const [prevValue, setPrevValue] = useState<number[]>();

  const onChangeHandler = (ev: ChangeEvent<{ name: string; value: number[] }>) => {
    const targetValue = ev.target.value;
    const diff = targetValue.filter((x: number) => !prevValue?.includes(x));

    if (diff[0] === 0) {
      setPrevValue([0]);
      onChange([0]);
    } else {
      const filteredTargetValue = targetValue.filter((x) => x !== 0);
      setPrevValue(filteredTargetValue);
      onChange(filteredTargetValue);
    }
  };

  return (
    <MultiSelect
      name="question6"
      control={control}
      onChange={onChangeHandler}
      rules={{
        required: {
          value: true,
          message: '',
        },
      }}
    >
      <MenuItem value={RatingAnswer6.NonePresent}>Не представлены</MenuItem>
      <MenuItem value={RatingAnswer6.ContinuosFullUsage}>
        Постоянное полное использование возобновляемой кредитной линии или частые
        просрочки/превышение лимита (высокий риск)
      </MenuItem>
      <MenuItem value={RatingAnswer6.OtherFinancialInstitutions}>
        Другие финансовые учреждения сокращают, ограничивают (например, устанавливают более строгие
        условия по краткосрочным кредитам) или прекращают доступность кредита для компании по
        кредитным причинам.(высокий риск)
      </MenuItem>
      <MenuItem value={RatingAnswer6.RequestForDelayInPayment}>
        Запрос на отсрочку платежа по кредитным причинам (высокий риск)
      </MenuItem>
      <MenuItem value={RatingAnswer6.UnexpectedWorsening}>
        Неожиданное ухудшение финансовых показателей (высокий риск)
      </MenuItem>
      <MenuItem value={RatingAnswer6.OthersNamelyHigh}>
        Прочее, а именно ….. (высокий риск)
      </MenuItem>
      <MenuItem value={RatingAnswer6.ExternalSignals}>
        Внешние сигналы(например, Кредитное агентство или сообщения СМИ) (средний риск)
      </MenuItem>
      <MenuItem value={RatingAnswer6.FrequentChangesInAuditors}>
        Частая смена аудиторов (средний риск)
      </MenuItem>
      <MenuItem value={RatingAnswer6.PandingMaterialRegulatryInvestigations}>
        Ожидается существенное расследование регулирующих органов или судебный иск против компании
        (средний риск)
      </MenuItem>
      <MenuItem value={RatingAnswer6.RequestForCovenantAmendment}>
        Запрос на изменение или удаление ковенант (средний риск)
      </MenuItem>
      <MenuItem value={RatingAnswer6.SuddenChangeInManagement}>
        Внезапная смена руководства (средний риск)
      </MenuItem>
      <MenuItem value={RatingAnswer6.FinancialStatementsReceivedLate}>
        По запросу финансовая отчетность была получена с опозданием по сравнению с обычной практикой
        для клиентского сегмента (средний риск)
      </MenuItem>
      <MenuItem value={RatingAnswer6.OthersNamelyAverage}>
        Прочее, а именно ….. (средний риск)
      </MenuItem>
    </MultiSelect>
  );
};

export const RatingQuestion7 = (props: RatingQuestionProps) => {
  const { control } = props;
  return (
    <Select name="question7" control={control}>
      <MenuItem value={RatingAnswer7.DontKnow}>Не знаю</MenuItem>
      <MenuItem value={RatingAnswer7.Effectively}>Эффективно</MenuItem>
      <MenuItem value={RatingAnswer7.NotApplicable}>Не применимо</MenuItem>
      <MenuItem value={RatingAnswer7.NotEffectively}>Неэффективно</MenuItem>
      <MenuItem value={RatingAnswer7.ReasonablyEffectively}>Приемлимо</MenuItem>
    </Select>
  );
};

export const RatingQuestion8 = (props: RatingQuestionProps) => {
  const { control } = props;
  return (
    <Select name="question8" control={control}>
      <MenuItem value={RatingAnswer8.SignificantlyAbovePlan}>Значительно выше плана</MenuItem>
      <MenuItem value={RatingAnswer8.SlightlyAbovePlan}>Слегка выше плана</MenuItem>
      <MenuItem value={RatingAnswer8.InLineWithPlan}>В соответствии с планом</MenuItem>
      <MenuItem value={RatingAnswer8.SlightlyBelowPlan}>Слегка ниже плана</MenuItem>
      <MenuItem value={RatingAnswer8.SignificantlyBelowPlan}>Значительно ниже плана</MenuItem>
      <MenuItem value={RatingAnswer8.DoesntApplyNoPlanRequested}>
        Не применимо/план не запрашивался
      </MenuItem>
      <MenuItem value={RatingAnswer8.DontKnow}>Не знаю</MenuItem>
    </Select>
  );
};

export const RatingQuestion9 = (props: RatingQuestionProps) => {
  const { control } = props;
  return (
    <Select name="question9" control={control}>
      <MenuItem value={RatingAnswer9.LimitedAccess}>Ограниченный доступ</MenuItem>
      <MenuItem value={RatingAnswer9.No}>Нет</MenuItem>
      <MenuItem value={RatingAnswer9.Yes}>Да</MenuItem>
    </Select>
  );
};

export const RatingQuestion10 = (props: RatingQuestionProps) => {
  const { control } = props;
  return (
    <Select name="question10" control={control}>
      <MenuItem value={RatingAnswer10.No}>Нет</MenuItem>
      <MenuItem value={RatingAnswer10.Yes}>Да</MenuItem>
    </Select>
  );
};

export const RatingQuestion11 = (props: RatingQuestionProps) => {
  const { control } = props;
  return (
    <Select name="question11" control={control}>
      <MenuItem value={RatingAnswer11.Consolidating}>Стадия Зарождения</MenuItem>
      <MenuItem value={RatingAnswer11.Declining}>Спад</MenuItem>
      <MenuItem value={RatingAnswer11.Growing}>Ростущий</MenuItem>
      <MenuItem value={RatingAnswer11.MaturedOrStable}>Зрелый</MenuItem>
    </Select>
  );
};

export const RatingQuestion12 = (props: RatingQuestionProps) => {
  const { control } = props;
  return (
    <Select name="question12" control={control}>
      <MenuItem value={RatingAnswer12.Years1Less}>меньше года или отсутсвует</MenuItem>
      <MenuItem value={RatingAnswer12.Years1To3}>1-3 года</MenuItem>
      <MenuItem value={RatingAnswer12.Years3To5}>3-5 лет</MenuItem>
      <MenuItem value={RatingAnswer12.Years5More}>больше 5 лет</MenuItem>
    </Select>
  );
};

export const RatingQuestion13 = (props: RatingQuestionProps) => {
  const { control } = props;
  return (
    <Select name="question13" control={control}>
      <MenuItem value={RatingAnswer13.AboveAverage}>Выше среднего</MenuItem>
      <MenuItem value={RatingAnswer13.Average}>Средне</MenuItem>
      <MenuItem value={RatingAnswer13.BelowAverage}>Ниже среднего</MenuItem>
      <MenuItem value={RatingAnswer13.DontKnow}>Не знаю</MenuItem>
      <MenuItem value={RatingAnswer13.Good}>Хорошо</MenuItem>
      <MenuItem value={RatingAnswer13.Poor}>Слабо</MenuItem>
    </Select>
  );
};

export const RatingQuestion14 = (props: RatingQuestionProps) => {
  const { control } = props;
  return (
    <Select name="question14" control={control}>
      <MenuItem value={RatingAnswer14.AboveAverage}>Выше среднего</MenuItem>
      <MenuItem value={RatingAnswer14.Average}>Средне</MenuItem>
      <MenuItem value={RatingAnswer14.BelowAverage}>Ниже среднего</MenuItem>
      <MenuItem value={RatingAnswer14.DontKnow}>Не знаю</MenuItem>
      <MenuItem value={RatingAnswer14.Good}>Хорошо</MenuItem>
      <MenuItem value={RatingAnswer14.Poor}>Слабо</MenuItem>
    </Select>
  );
};

export const RatingQuestion15 = (props: RatingQuestionProps) => {
  const { control } = props;
  return (
    <Select name="question15" control={control}>
      <MenuItem value={RatingAnswer15.DontKnow}>Не знаю</MenuItem>
      <MenuItem value={RatingAnswer15.Moderately}>Умеренно</MenuItem>
      <MenuItem value={RatingAnswer15.No}>Нет</MenuItem>
      <MenuItem value={RatingAnswer15.Yes}>Да</MenuItem>
    </Select>
  );
};

export const RatingQuestion16 = (props: RatingQuestionProps) => {
  const { control } = props;
  return (
    <Select name="question16" control={control}>
      <MenuItem value={RatingAnswer16.LocalPlayer}>Отсутсвует</MenuItem>
      <MenuItem value={RatingAnswer16.ManyInternationalOperations}>Много</MenuItem>
      <MenuItem value={RatingAnswer16.PrimaryInternationalOperations}>В основном</MenuItem>
      <MenuItem value={RatingAnswer16.SomeInternationalOperations}>Несколько</MenuItem>
      <MenuItem value={RatingAnswer16.DontKnow}>Не знаю</MenuItem>
    </Select>
  );
};

export const RatingQuestion17 = (props: RatingQuestionProps) => {
  const { control } = props;
  return (
    <Select name="question17" control={control}>
      <MenuItem value={RatingAnswer17.Agriculture}>Сельское хозяйство</MenuItem>
      <MenuItem value={RatingAnswer17.BusinessProducts}>Бизнес-продукты</MenuItem>
      <MenuItem value={RatingAnswer17.ComunicationsOrHiTech}>Связь/Высокие технологии</MenuItem>
      <MenuItem value={RatingAnswer17.Construction}>Строительство</MenuItem>
      <MenuItem value={RatingAnswer17.ConsumerProducts}>
        Производство потребительской продукции
      </MenuItem>
      <MenuItem value={RatingAnswer17.MiningTransportationUtilities}>
        Горнодобывающая промышленность, транспорт, коммунальные услуги и природные ресурсы
      </MenuItem>
      <MenuItem value={RatingAnswer17.Services}>Услуги</MenuItem>
      <MenuItem value={RatingAnswer17.Trade}>Торговля</MenuItem>
    </Select>
  );
};

export const RatingQuestion18 = (props: RatingQuestionProps) => {
  const { control } = props;
  return (
    <Select name="question18" control={control}>
      <MenuItem value={RatingAnswer18.Years10}>Более 10 лет</MenuItem>
      <MenuItem value={RatingAnswer18.Years5To10}>5-10 лет</MenuItem>
      <MenuItem value={RatingAnswer18.Years2To5}>2-5 лет</MenuItem>
      <MenuItem value={RatingAnswer18.Years0To2}>менее 2 лет</MenuItem>
      <MenuItem value={RatingAnswer18.DontKnow}>Не знаю</MenuItem>
    </Select>
  );
};
