import { Route } from 'react-router-dom';
import { Critical, MissingCommentOverdueFormPanel } from 'components';
import { useErrorContext } from 'components/tasks';
import { useTranslation } from 'react-i18next';
import { IssueSource } from 'schema/serverTypes';
import { OverdueDataGrid } from 'components';

export const OverduePage = () => {
  const { t } = useTranslation();

  useErrorContext({
    title: t('Overdue'),
    source: IssueSource.overdue,
  });

  return (
    <>
      <Critical />
      <Route path="/overdue">
        <Route path="/overdue" component={OverdueDataGrid} />
        <Route path="/overdue/comments" component={MissingCommentOverdueFormPanel} exact />
      </Route>
    </>
  );
};
