import { useCallback } from 'react';
import { FieldArrayRenderProps } from 'react-final-form-arrays';
import { OnChange } from 'react-final-form-listeners';
import { CalculationMethod, MonthPaymentOption } from 'schema/serverTypes';
import { isPreferentialMonth } from './isPreferentialMonth';

export type OnSeasonalDateChangedProps = Pick<
  FieldArrayRenderProps<MonthPaymentOption, HTMLDivElement>,
  'fields'
> & {
  calculationMethod: CalculationMethod;
};

export const OnSeasonalDateChanged = (props: OnSeasonalDateChangedProps) => {
  const { fields, calculationMethod } = props;

  const { value, forEach, update } = fields;

  const onChange = useCallback(
    (date?: string) => {
      if (calculationMethod === CalculationMethod.SuperSeasonal) {
        forEach((_name, index) => {
          if (index > 11) {
            return;
          }

          const { number } = value[index];
          update(index, {
            number,
            value: isPreferentialMonth(number, date) ? 0 : 1,
            isPayment: true,
            isPreferential: isPreferentialMonth(number, date),
          });
        });
      } else if (calculationMethod === CalculationMethod.Seasonal) {
        forEach((_name, index) => {
          const { number } = value[index];

          update(index, {
            number: number,
            value: 1,
            isPayment: true,
            isPreferential: false,
          });
        });
      } else {
        return;
      }
    },
    [value, calculationMethod, forEach, update]
  );

  return <OnChange name="seasonalPaymentOptions.date">{onChange}</OnChange>;
};
