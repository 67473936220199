import { useQueryClient } from 'react-query';
import { QuotaNoteViewModel, AddQuotaNoteViewModel } from 'schema/serverTypes';
import { createUrlFromParts } from './types';
import { useBackendQuery, useBackendMutation, MutationOptions } from './useBackend';

const getEntity = (quotaId: number) => `quotas/${quotaId}/notes`;

export const useQuotaNotesQuery = (quotaId: number) => {
  const url = getEntity(quotaId);
  let queryKey = `${url}`;

  return useBackendQuery<QuotaNoteViewModel[]>(createUrlFromParts(url), queryKey);
};

export const useAddQuotaNoteMutation = (
  quotaId: number,
  options?: MutationOptions<AddQuotaNoteViewModel, QuotaNoteViewModel[]>
) => {
  const queryClient = useQueryClient();
  const onSuccess = options?.onSuccess;

  return useBackendMutation(createUrlFromParts(getEntity(quotaId)), {
    ...options,
    onSuccess: (data, variables, context) => {
      if (onSuccess) {
        onSuccess(data, variables, context);
      }
      queryClient.invalidateQueries({
        predicate: (query) => {
          if (Array.isArray(query.queryKey)) {
            const key = query.queryKey as Array<string>;
            if (key[0] === 'quotas') {
              return true;
            }
          }
          return (
            typeof query.queryKey === 'string' && (query.queryKey as string)?.indexOf('quotas') > -1
          );
        },
      });
    },
    method: 'POST',
  });
};
