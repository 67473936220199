import { Checkbox } from 'components/form';
import { useCalculationFormContext } from '../types';
import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { CalculationMethod } from 'schema';

export type IsPaymentCheckBoxProps = {
  index: number;
  numberOfMonths: number;
  isSuperSeasonal: boolean;
  calculationMethodChange: number;
};

export const IsPaymentCheckBox = (props: IsPaymentCheckBoxProps) => {
  const { index, numberOfMonths, isSuperSeasonal, calculationMethodChange } = props;
  const isLastMonth = index === numberOfMonths - 1;
  const disabled = isSuperSeasonal || isLastMonth;

  const { control, getValues, setValue } = useCalculationFormContext();

  const calculationMethod = useWatch({ control, name: 'calculationMethod' });

  useEffect(() => {
    const isPayment = getValues(`paymentOptions.${index}.isPayment`);
    const number = getValues(`paymentOptions.${index}.number`);
    if (
      (calculationMethod === CalculationMethod.Seasonal ||
        calculationMethod === CalculationMethod.SuperSeasonal) &&
      !isPayment &&
      calculationMethodChange
    ) {
      if (calculationMethod === CalculationMethod.SuperSeasonal && number > 11) {
        return;
      }
      setValue(`paymentOptions.${index}.isPayment`, true);
    }
  }, [calculationMethod, calculationMethodChange, index, getValues, setValue]);

  const isPayment = useWatch({ control, name: `paymentOptions.${index}.isPayment` });

  useEffect(() => {
    const isPreferential = getValues(`paymentOptions.${index}.isPreferential`);
    const value = getValues(`paymentOptions.${index}.value`);

    if (!isPayment && (isPreferential || value !== 0)) {
      setValue(`paymentOptions.${index}.value`, 0);
      setValue(`paymentOptions.${index}.isPreferential`, false);
    }
  }, [isPayment, index, setValue, getValues]);

  return (
    <Checkbox
      control={control}
      name={`paymentOptions.${index}.isPayment`}
      disabled={disabled}
      isCircle={false}
    />
  );
};
