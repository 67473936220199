import { Amount, AmountType } from 'schema/serverTypes';

const round4 = (value: number | string | undefined) => {
  if (value === undefined || typeof value === 'string' || isNaN(value)) {
    return undefined;
  }

  return parseFloat(value.toFixed(4));
};

const calculateLeaseItemCost = (
  leaseItemCost: number | undefined,
  discount: Amount | undefined
) => {
  const discountType = discount?.type ?? AmountType.Percents;
  const discountValue = discount?.value ?? 0;
  const leaseItemCostValue = leaseItemCost ?? 0;

  if (discountType === AmountType.Money) {
    return leaseItemCostValue - discountValue;
  } else {
    return leaseItemCostValue - (leaseItemCostValue * discountValue) / 100.0;
  }
};

export const calculatePrepaymentAmount = (
  leaseItemCost: number | undefined,
  prepayment: Amount | undefined,
  discount: Amount | undefined
) => {
  const itemCost = calculateLeaseItemCost(leaseItemCost, discount);
  const prepaymentType = prepayment?.type ?? AmountType.Percents;
  const prepaymentValue = prepayment?.value ?? 0;
  const isMoney = prepaymentType === AmountType.Money;
  return isMoney ? prepaymentValue : (itemCost * prepaymentValue) / 100.0;
};

export const calculateTradeFeeAmount = (
  leaseItemCost: number | undefined,
  tradeFeePercents: number | undefined,
  discount: Amount | undefined
) => {
  const itemCost = calculateLeaseItemCost(leaseItemCost, discount);
  return (itemCost * (tradeFeePercents ?? 0)) / 100.0;
};

export const calculateFundingAmount = (
  leaseItemCost: number | undefined,
  prepaymentAmount: number | undefined,
  discount: Amount | undefined
) => {
  const itemCost = calculateLeaseItemCost(leaseItemCost, discount);
  return itemCost - (prepaymentAmount ?? 0);
};

export const calculateGeneralRatePercents = (
  cof: number | undefined,
  margin: number | undefined
) => {
  const cofValue = round4(cof);
  const marginValue = round4(margin);
  if (cofValue !== undefined || marginValue !== undefined) {
    return round4((cofValue ?? 0) + (marginValue ?? 0));
  }
  return undefined;
};
