import React from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { IconSprite } from '../../icons';
import { themeOrange as theme } from 'theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toggle: {
      position: 'absolute',
      top: theme.spacing(0.75),
      right: theme.spacing(2.5),
      cursor: 'pointer',
      zIndex: 999,
      border: 'none',
      backgroundColor: theme.palette.background.default,
      width: 24,
      height: 24,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 2,
    },
    closed: {
      transform: 'rotate(180deg)',
    },
  })
);

type ToggleItemHandleProps = {
  toggleOpen: () => void;
  open: boolean;
};

export function ToggleItemHandle(props: ToggleItemHandleProps) {
  const classes = useStyles();
  const { toggleOpen, open } = props;

  return (
    <button className={classes.toggle} onClick={toggleOpen}>
      <IconSprite
        className={!open ? classes.closed : ''}
        icon={'dropdown2'}
        width={10}
        height={6}
        color={theme.palette.text.secondary}
      />
    </button>
  );
}
