import { Grid } from 'components/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FieldArrayWithId, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CounterpartyViewModel } from 'schema/serverTypes';
import { Input } from 'components/form';
import { CounterpartyFormProps } from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '16px 20px',
      width: 'calc(100% + 40px)',
      margin: '0 -20px',
    },
    main: {
      backgroundColor: theme.palette.secondary.light,
    },
    head: {
      color: theme.palette.secondary.main,
    },
    delete: {
      textAlign: 'right',
      '& > *': {
        minWidth: 0,
        color: theme.palette.primary.light,
      },
    },
    checkBox: {
      margin: theme.spacing(-2, 0),
    },
    wrapper: {
      width: '100%',
    },
  })
);

export type RequisiteProps = CounterpartyFormProps & {
  item: FieldArrayWithId<CounterpartyViewModel, 'requisites', 'id'>;
  index: number;
};

export const Requisite = (props: RequisiteProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { index, control } = props;

  const requisites = useWatch({
    control,
    name: 'requisites',
  });

  const checkedIndex = requisites.findIndex((t) => t.isMain);
  const mainClass = checkedIndex === index ? classes.main : '';

  return (
    <div className={`${classes.root} ${mainClass}`}>
      <Grid container item spacing={2}>
        <Grid item xs={24}>
          {checkedIndex === index && t('Master account')}
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item md={6} xs={24}>
          <Input
            label={t('BIK')}
            name={`requisites.${index}.bic` as const}
            control={control}
            disabled
          />
        </Grid>
        <Grid item md={6} xs={24}>
          <Input
            label={t('Bank')}
            name={`requisites.${index}.bank` as const}
            control={control}
            disabled
          />
        </Grid>
        <Grid item md={6} xs={24}>
          <Input
            label={t('Сorrespondent account')}
            name={`requisites.${index}.correspondentAccount` as const}
            control={control}
            disabled
          />
        </Grid>
        <Grid item md={6} xs={24}>
          <Input
            label={t('Number of account')}
            name={`requisites.${index}.account` as const}
            control={control}
            disabled
          />
        </Grid>
      </Grid>
    </div>
  );
};
