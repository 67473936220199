import { IssueViewModel } from 'schema';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useIssueTypeString, useStatusSelectString } from '../../tasks/useStatusString';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      fontSize: 12,
      color: theme.palette.text.primary,
      fontWeight: 400,
    },
    title: {
      color: theme.palette.text.secondary,
    },
    issue: {
      textDecoration: 'none',
      color: theme.palette.secondary.dark,
      padding: theme.spacing(0, 0.5),
      borderRight: `1px solid ${theme.palette.text.secondary}`,
    },
    assignee: {
      padding: theme.spacing(0, 0.5),
      borderRight: `1px solid ${theme.palette.text.secondary}`,
    },
    status: {
      padding: theme.spacing(0, 0.5),
    },
  })
);

type TaskProps = {
  issue: IssueViewModel;
};

export const Task = (props: TaskProps) => {
  const classes = useStyles();
  const { issue } = props;
  const status = useStatusSelectString(issue.status);
  const title = useIssueTypeString(issue.issueType);

  return (
    <div className={classes.root}>
      <div className={classes.title}>{title}:</div>
      {issue.assignee && <div className={classes.assignee}>{issue.assignee.name}</div>}
      <Link to={`/tasks/${issue.id}`} className={classes.issue}>{`№ ${issue.id}`}</Link>
      <div className={classes.status}>{status}</div>
    </div>
  );
};
