import { Grid, IconSprite, StaticInput } from 'components';
import { Select } from 'components/form';
import { Box, InputAdornment, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { CalculatorFormValues } from '../../types';
import { SubsidyDealerInput } from './SubsidyDealerInput';
import { AmountInput } from '../../AmountInput';
import { CounterpartyAutocomplete } from '../AgentAutocomplete';
import { useCounterpartyDocumentsQuery } from '../../../../../services/api/useCounterpartiesDocumentsBackend';
import { CounterpartyContractType } from '../../../../../schema';
import React, { useEffect, useMemo } from 'react';
import { themeOrange as theme } from '../../../../../theme';

type SubsidyFieldsProps = {
  control: Control<CalculatorFormValues, any>;
  setValue: UseFormSetValue<CalculatorFormValues>;
};

export const SubsidyFields = (props: SubsidyFieldsProps) => {
  const { t } = useTranslation();
  const { control, setValue } = props;

  const dealerInn = useWatch({ control, name: 'dealer' });
  const dealerName = useWatch({ control, name: 'dealerName' });
  const dealer = dealerInn && dealerName ? `${dealerInn} (${dealerName})` : '';

  const vendorInn = useWatch({ control, name: 'vendor' });

  const iconHoverColor = dealerInn
    ? theme.palette.primaryGradient[700]
    : theme.palette.text.primary;
  const iconView = (
    <Box pt={1.5} pr={0} pb={1} pl={0} ml={-0.75}>
      <IconSprite
        icon={'view'}
        width={15}
        height={10}
        color={theme.palette.text.primary}
        hoverColor={iconHoverColor}
      />
    </Box>
  );
  const counterpartyLink = dealerInn ? `/counterparties/${dealerInn}` : undefined;

  return (
    <Grid container columnSpacing={2} rowSpacing={2.5}>
      <Grid item md={6} xs={24}>
        <SubsidyDealerInput />
      </Grid>
      <Grid item md={9} xs={24}>
        <StaticInput
          label={t('Dealer')}
          value={dealer}
          InputProps={{
            startAdornment: counterpartyLink ? (
              <>
                <InputAdornment position="start">
                  <a href={counterpartyLink} target="_blank" rel="noreferrer">
                    {iconView}
                  </a>
                </InputAdornment>
              </>
            ) : null,
          }}
        />
      </Grid>
      <Grid item md={9} xs={24}>
        {dealerInn ? (
          <ContractField
            name={'dealerContractId'}
            label={t('Contract')}
            inn={dealerInn}
            control={control}
            setValue={setValue}
          />
        ) : (
          <EmptyContractField name={'dealerContractId'} label={t('Contract')} />
        )}
      </Grid>
      <Grid item md={6} xs={24}>
        <AmountInput name="subsidyVendor" label={t('VendorSubsidy')} useSaleCurrency={true} />
      </Grid>
      <Grid item md={9} xs={24}>
        <CounterpartyAutocomplete name="vendor" label={t('Vendor')} type="isDealer" />
      </Grid>
      <Grid item md={9} xs={24}>
        {vendorInn ? (
          <ContractField
            name={'vendorContractId'}
            label={t('Contract')}
            inn={vendorInn}
            control={control}
            setValue={setValue}
          />
        ) : (
          <EmptyContractField name={'vendorContractId'} label={t('Contract')} />
        )}
      </Grid>
    </Grid>
  );
};

const ContractField = ({
  inn,
  control,
  name,
  label,
  setValue,
}: {
  inn: string;
  control: Control<CalculatorFormValues, any>;
  name: any;
  label: string;
  setValue: UseFormSetValue<CalculatorFormValues>;
}) => {
  const { data: documentDealerQuery } = useCounterpartyDocumentsQuery(inn ?? '');

  const documents = useMemo(() => {
    return inn
      ? documentDealerQuery?.filter((doc) => doc.type === CounterpartyContractType.Subsidy)
      : [];
  }, [inn, documentDealerQuery]);

  useEffect(() => {
    const defaultDocumentId =
      documents?.find((document) => document.isMain && document.isFramework)?.id.toString() || '';
    setValue(name, defaultDocumentId);
  }, [inn, documents, name, setValue]);

  return (
    <Select displayEmpty name={name} label={label} control={control}>
      {documents?.map((document) => (
        <MenuItem value={document.id} key={document.id}>
          {document.name}
        </MenuItem>
      ))}
    </Select>
  );
};

const EmptyContractField = ({ name, label }: { name: string; label: string }) => {
  return <Select displayEmpty name={name} label={label} />;
};
