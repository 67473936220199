import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { CounterpartyPhysicViewModel } from 'schema/serverTypes';
import { useCounterpartiesBackendMutation } from 'services/api';
import { CounterpartyIndividualFormValues } from '../types';

export const useCounterpartyIndividualCreateForm = () => {
  const { handleSubmit, control, reset, setError, clearErrors, formState, ...rest } =
    useForm<CounterpartyIndividualFormValues>({
      mode: 'onBlur',
      defaultValues: {
        opf: 'Физическое лицо',
      },
    });

  const history = useHistory();
  const { mutateAsync } = useCounterpartiesBackendMutation<
    CounterpartyPhysicViewModel,
    CounterpartyPhysicViewModel
  >('/physic', {
    method: 'POST',
    onSuccess: (party) => {
      history.push(`/counterparties`, { isNew: true });
    },
  });

  const onSubmit = useMemo(() => {
    const submit = async ({ ...form }: CounterpartyIndividualFormValues) => {
      try {
        await mutateAsync({
          ...form,
        });
      } catch (error) {
        setError('inn', { message: 'Не удалось обновить' }, { shouldFocus: true });
      }
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync, setError]);

  return {
    control,
    onSubmit,
    clearErrors,
    reset,
    ...formState,
    ...rest,
  };
};
