import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notActive: {
      '&&&': {
        textDecoration: 'line-through',
        display: 'inline-block',
        position: 'relative',
        paddingLeft: '1.4em',
        '&:before': {
          position: 'absolute',
          left: 0,
          top: '0.1em',
          content: "'❌ '",
          zIndex: 2,
          fontSize: '0.85em',
        },
      },
    },
  })
);

type UserProps = {
  name: string;
  isActive?: boolean;
};

export const User = (props: UserProps) => {
  const classes = useStyles();
  const { name, isActive = true } = props;

  return <span className={isActive ? '' : classes.notActive}>{name}</span>;
};
