import { useTranslation } from 'react-i18next';
import { Table, TableCell, TableHead, TableRow, TableBody } from '@material-ui/core';
import { ApplicationRole, CountryRiskLevel, CountryRiskViewModel } from 'schema/serverTypes';
import { useCountryRisksData } from './useCountryRisksData';
import { Role } from '../../authentication';
import { EditButton } from '../EditButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      '& td': {
        width: 85,
        '&$name': {
          width: 'auto',
        },
        '&$edit': {
          width: 41,
        },
      },
      '&:hover $edit': {},
    },
    name: {},
    edit: {
      position: 'sticky',
      right: 0,
    },
    high: {
      color: theme.palette.error.main,
    },
    medium: {
      color: theme.palette.warning.main,
    },
  })
);

export type CountryRisksTableProps = Omit<
  ReturnType<typeof useCountryRisksData>,
  'filter' | 'paging'
>;

type TableItemProps = {
  item: CountryRiskViewModel;
};

const TableItem = (props: TableItemProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { item } = props;

  const history = useHistory();
  const editHandler = () => {
    history.push(`/dictionaries/countryRisks/${item.id}`);
  };

  const getColorClass = (riskLevel: CountryRiskLevel) =>
    riskLevel === CountryRiskLevel.High
      ? classes.high
      : riskLevel === CountryRiskLevel.Medium
      ? classes.medium
      : '';

  const getName = (riskLevel: CountryRiskLevel) =>
    riskLevel === CountryRiskLevel.High
      ? t('RiskLevel.High')
      : riskLevel === CountryRiskLevel.Medium
      ? t('RiskLevel.Medium')
      : t('RiskLevel.Low');

  const RiskLevelValue = ({ riskLevel }: { riskLevel: CountryRiskLevel }) => {
    return (
      <TableCell size="medium" className={getColorClass(riskLevel)}>
        {getName(riskLevel)}
      </TableCell>
    );
  };

  return (
    <TableRow className={classes.row}>
      <TableCell size="medium" className={classes.name}>
        {item.name}
      </TableCell>
      <RiskLevelValue riskLevel={item.maxRiskLevel} />
      <RiskLevelValue riskLevel={item.blackListRiskLevel} />
      <RiskLevelValue riskLevel={item.greyListRiskLevel} />
      <RiskLevelValue riskLevel={item.sanctionsRiskLevel} />
      <RiskLevelValue riskLevel={item.corruptionRiskLevel} />
      <RiskLevelValue riskLevel={item.drugsRiskLevel} />
      <RiskLevelValue riskLevel={item.terrorRiskLevel} />
      <RiskLevelValue riskLevel={item.terrorCountryRiskLevel} />
      <RiskLevelValue riskLevel={item.preferentialRiskLevel} />
      <RiskLevelValue riskLevel={item.specialEconomicMeasuresRiskLevel} />
      <Role role={[ApplicationRole.RiskManager, ApplicationRole.Admin]}>
        <TableCell size="medium" className={classes.edit}>
          <EditButton onClick={editHandler} />
        </TableCell>
      </Role>
    </TableRow>
  );
};

export const CountryRisksTable = (props: CountryRisksTableProps) => {
  const { rows } = props;
  const { t } = useTranslation();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell size="medium">{t('Country')}</TableCell>
          <TableCell size="medium">{t('RiskLevel.Total')}</TableCell>
          <TableCell size="medium">{t('RiskLevel.blackList')}</TableCell>
          <TableCell size="medium">{t('RiskLevel.greyList')}</TableCell>
          <TableCell size="medium">{t('RiskLevel.sanctions')}</TableCell>
          <TableCell size="medium">{t('RiskLevel.corruption')}</TableCell>
          <TableCell size="medium">{t('RiskLevel.drugs')}</TableCell>
          <TableCell size="medium">{t('RiskLevel.terror')}</TableCell>
          <TableCell size="medium">{t('RiskLevel.terrorCountry')}</TableCell>
          <TableCell size="medium">{t('RiskLevel.preferential')}</TableCell>
          <TableCell size="medium">{t('RiskLevel.specialEconomicMeasures')}</TableCell>
          <Role role={[ApplicationRole.Admin, ApplicationRole.RiskManager]}>
            <TableCell size="medium" />
          </Role>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((item: CountryRiskViewModel, index: number) => (
          <TableItem key={index} item={item} />
        ))}
      </TableBody>
    </Table>
  );
};
