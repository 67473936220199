import { useCallback, useEffect, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import {
  Card,
  CardHeader,
  CardContent,
  makeStyles,
  createStyles,
  CardActions,
  Theme,
  Typography,
  Box,
} from '@material-ui/core';
import clsx from 'clsx';
import { Grid } from 'components/Grid';
import { Skeleton } from '@material-ui/lab';
import { DatePicker, Input, Switch } from '../form';
import { UserViewModel } from './types';
import { useTranslation } from 'react-i18next';
import { Avatar } from '../avatar';
import { Button } from 'components';
import { useUserRole } from 'services/authentication/useUserRole';
import { useUpdateUserMutation } from './useEditForm';
import dayjs from 'dayjs';

export interface UserEditFormProps {
  user: UserViewModel;
}

export const UserEditFormSkeleton = () => {
  return <Skeleton></Skeleton>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '400px',
      minWidth: '400px',
      border: 'none',
      boxShadow: 'none',
      paddingBottom: theme.spacing(1.2),
    },
    cardContent: {
      paddingTop: 20,
      paddingRight: 20,
      paddingBottom: 20,
      paddingLeft: 20,
    },
    headerWrapper: {
      marginBottom: theme.spacing(0.5),
    },
    header: {
      paddingTop: theme.spacing(5.5),
      fontWeight: 'bolder',
      textAlign: 'center',
      paddingRight: 20,
      paddingBottom: 20,
      paddingLeft: 20,
    },
    loginTime: {
      textAlign: 'center',
      fontSize: 10,
      color: theme.palette.text.primary,
    },
    actions: {
      justifyContent: 'flex-start',
    },
    cancelButton: {
      color: theme.palette.error.main,
    },
    item: {
      justifyContent: 'center',
    },
    membershipTitle: {
      marginBottom: 6,
    },
    membershipItem: {
      marginBottom: 4,
    },
    GridCloseButton: {
      backgroundColor: theme.palette.lightBlue3.main,
    },
    closeButton: {
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
      '& img': {
        maxWidth: '10px',
      },
    },
  })
);

const useDefaultValues = (user: UserViewModel) => {
  return useMemo(() => {
    const model: UserViewModel = {
      ...user,
      isActive: user.activeToDate !== undefined,
    };

    return model;
  }, [user]);
};

export const DealerEditForm = (props: UserEditFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    user: { id, name, userName, leasingPrograms, dealerName, lastLoginTime: lastLoginDate },
  } = props;
  const title = name ?? userName;
  const { isAdmin } = useUserRole();
  const disabled = !isAdmin;

  const defaultValues = useDefaultValues(props.user);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    getValues,
    setValue,
  } = useForm<UserViewModel>({
    mode: 'onBlur',
    defaultValues,
  });

  const isActive = useWatch({ control, name: 'isActive' });

  useEffect(() => {
    if (!isActive) {
      const date = getValues('activeToDate');
      if (date !== undefined) {
        setValue('activeToDate', undefined);
      }
    }
  }, [isActive, getValues, setValue]);

  const { mutateAsync, isLoading } = useUpdateUserMutation(id);

  const onSubmit = useCallback(
    async (values: UserViewModel) => {
      await mutateAsync(values);
    },
    [mutateAsync]
  );

  const lastLoginTime =
    lastLoginDate !== undefined
      ? t('LastLoginTime', {
          lastLoginDate: dayjs(lastLoginDate).format('DD.MM.YYYY'),
          lastLoginTime: dayjs(lastLoginDate).format('HH:mm'),
        })
      : undefined;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card className={classes.root}>
        <Box display="flex" justifyContent="flex-end" className={classes.GridCloseButton} />
        <Box className={classes.headerWrapper}>
          <CardHeader className={classes.header} title={title} />
        </Box>
        <CardContent className={classes.cardContent}>
          <Grid container rowSpacing={2.5} columnSpacing={0}>
            <Grid item xs={24} className={classes.item}>
              <Box className={classes.item}>
                <Avatar size="large" />
              </Box>
            </Grid>
            {lastLoginTime && (
              <Grid item xs={24} className={classes.item}>
                <Box className={classes.loginTime}>{lastLoginTime}</Box>
              </Grid>
            )}
            <Grid item xs={24} className={clsx(classes.item)}>
              <Box display={'flex'} justifyContent={'center'}>
                <Box width={116}>
                  <Switch
                    label={`${t('IsActive')}`}
                    name="isActive"
                    control={control}
                    labelOn={''}
                    labelOff={''}
                  />
                </Box>
                <DatePicker
                  label={`${t('Valid until')}`}
                  name="activeToDate"
                  control={control}
                  disabled={!isActive}
                  rules={{
                    required: {
                      value: isActive,
                      message: t('Required'),
                    },
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={24} className={classes.item}>
              <Input label={`${t('LastName')}`} name="lastName" control={control} required={true} />
            </Grid>
            <Grid item xs={24} className={classes.item}>
              <Input
                label={`${t('FirstName')}`}
                name="firstName"
                control={control}
                required={true}
              />
            </Grid>
            <Grid item xs={24} className={classes.item}>
              <Input
                label={`${t('MiddleName')}`}
                name="middleName"
                control={control}
                required={true}
              />
            </Grid>
            <Grid item xs={24} className={classes.item}>
              <Input
                label={`${t('Email')}`}
                name="email"
                control={control}
                required={true}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={24} className={classes.item}>
              <Input label={`${t('PhoneNumber')}`} name="phoneNumber" control={control} />
            </Grid>
            <Grid item xs={24} className={classes.item}>
              <Typography variant="body2" className={classes.membershipTitle}>
                {t('Dealer')}
              </Typography>
              <ul>
                <li className={classes.membershipItem}>
                  <Typography variant="subtitle2" color="secondary">
                    {dealerName}
                  </Typography>
                </li>
              </ul>
            </Grid>
            {leasingPrograms.length > 0 ? (
              <Grid item xs={24} className={classes.item}>
                <Typography variant="body2" className={classes.membershipTitle}>
                  {t('Leasing programs')}
                </Typography>
                <ul>
                  {leasingPrograms.map((leasingProgram) => (
                    <li key={leasingProgram} className={classes.membershipItem}>
                      <Typography variant="subtitle2" color="secondary">
                        {leasingProgram}
                      </Typography>
                    </li>
                  ))}
                </ul>
              </Grid>
            ) : null}
          </Grid>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button
            color="primary"
            disabled={isSubmitting || isLoading}
            size="medium"
            type="submit"
            variant="contained"
          >
            {t('Save')}
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};
