import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  FormControlProps,
} from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import { styles } from './Select';

export type FormSelectProps = MuiSelectProps &
  WithStyles<typeof styles, true> &
  Pick<FormControlProps, 'size'> & {
    lowercase?: boolean;
    invalid?: boolean;
    error?: string;
  };

const StyledSelect = (props: FormSelectProps) => {
  const {
    name,
    children,
    classes,
    label,
    variant = 'outlined',
    size = 'small',
    lowercase = false,
    invalid,
    error,
    ...rest
  } = props;

  const labelId = `label-${name}`;
  const selectId = `select-${name}`;

  return (
    <FormControl variant={variant} className={classes?.root} error={invalid} size={size}>
      {label !== undefined ? <InputLabel id={labelId}>{label}</InputLabel> : null}
      <MuiSelect
        {...rest}
        labelId={labelId}
        id={selectId}
        label={label}
        className={classes?.root}
        IconComponent={(props) => {
          return <KeyboardArrowDownRoundedIcon {...props} color="primary" />;
        }}
        MenuProps={{
          classes: {
            paper: classes?.paper,
            list: lowercase ? classes?.lowercaseUl : classes?.list,
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {children}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export const FormSelect = withStyles(styles, { withTheme: true })(StyledSelect);
