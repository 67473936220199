import { Autocomplete } from 'components';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import { ContractDealersRequest, ContractDealerViewModel } from 'schema/serverTypes';
import { useContractDealersRequest } from 'services/api';
import { ContractFilterFormProps } from './types';
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteChangeDetails,
} from '@material-ui/lab/useAutocomplete';
import { useController } from 'react-hook-form';

const getOptionLabel = (option: ContractDealerViewModel | null) => {
  return option?.name ?? '';
};

const getOptionSelected = (
  option: ContractDealerViewModel | null,
  value: ContractDealerViewModel | null
) => {
  return option?.dealerInn === value?.dealerInn;
};

export type ContractDealerAutocompleteProps = Pick<ContractFilterFormProps, 'control'> & {
  count?: number;
};

export const ContractDealerAutocomplete = (props: ContractDealerAutocompleteProps) => {
  const { control, count = 20 } = props;

  const { t } = useTranslation();

  const {
    field: { onChange, value: inns },
  } = useController({
    control,
    name: 'dealerInn',
    defaultValue: [],
  });

  const [dealerInn, setInn] = useState('');
  const [search] = useDebounce(dealerInn, 500);

  const request = useMemo<ContractDealersRequest>(() => {
    return {
      search,
      count,
    };
  }, [search, count]);

  const { data = [], isLoading } = useContractDealersRequest(request);
  const options = data
    .filter((t) => {
      if (inns === undefined) {
        return true;
      }
      const counterpartyByInn = inns.find((m) => m.dealerInn === t.inn);
      return counterpartyByInn === undefined;
    })
    .map((item) => ({ ...item, dealerInn: item.inn }));

  const handleOnInputChange = useCallback(
    (_ev: React.ChangeEvent<{}>, value: string, _reason: AutocompleteInputChangeReason) => {
      setInn(value);
    },
    [setInn]
  );

  const handleOnChange = useCallback(
    (
      _e: React.ChangeEvent<{}>,
      value: ContractDealerViewModel | null,
      _reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<ContractDealerViewModel | null>
    ) => {
      if (value === null) {
        onChange([]);
        setInn('');
      } else {
        onChange(inns ? [...inns.filter((v) => v.dealerInn !== value.dealerInn), value] : [value]);
        setInn('');
      }
    },
    [onChange, inns]
  );

  return (
    <Autocomplete<ContractDealerViewModel | null>
      label={t('Dealer')}
      options={options}
      inputValue={dealerInn}
      onInputChange={handleOnInputChange}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      onChange={handleOnChange}
      value={null}
      loading={isLoading}
      variant="standard"
    />
  );
};
