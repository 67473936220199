import React from 'react';
import { Button, IconBackTo } from 'components';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    back: {
      marginBottom: theme.spacing(1.5),
    },
    span: {
      fontSize: 12,
      color: theme.palette.text.primary,
      marginLeft: theme.spacing(1.6),
    },
  })
);

type BreadcrumbsProps = {
  link: string;
  text?: string;
  tab?: string;
};

export const Breadcrumbs = (props: BreadcrumbsProps) => {
  const classes = useStyles();
  const { link, text, tab } = props;
  const history = useHistory();
  const backHandler = () => {
    history.push({
      pathname: link,
      state: { tab },
    });
  };

  return (
    <div className={classes.back}>
      {tab ? (
        <Button variant="iconButton" endIcon={<IconBackTo />} onClick={backHandler} />
      ) : (
        <Button variant="iconButton" endIcon={<IconBackTo />} to={link} />
      )}
      <span className={classes.span}>{text}</span>
    </div>
  );
};
