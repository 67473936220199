import { useEffect } from 'react';
import { LoginButton } from 'components';
import { useGoBack } from 'hooks';
import { useUserAuth } from 'services';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';

const spaEnv = process.env.REACT_APP_SPA_ENV as string;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      top: 40,
      bottom: 0,
      left: 0,
      right: 0,
      width: '100%',
      overflowY: 'auto',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      minHeight: '100%',
    },
    blockForm: {
      textAlign: 'center',
      minHeight: '100%',
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      justifyContent: 'center',
    },
    blockBackground: {
      minHeight: '100%',
      flex: 1,
      background: 'url(/img/dll-auth-big-img.png) no-repeat center',
      backgroundSize: 'cover',
      overflow: 'hidden',
      zIndex: -1,
      position: 'relative',
    },
    title: {
      fontSize: '36px',
      fontWeight: 700,
      color: theme.palette.primary.main,
      margin: '0 16px 21px',
    },
    description: {
      marginBottom: theme.spacing(4),
    },
    formWrap: {
      width: '100%',
      maxWidth: '448px',
    },
    loader: {
      display: 'flex',
      position: 'absolute',
      left: 0,
      top: 0,
      height: '100%',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: -4,
      transition: 'all .3s ease',
    },
  })
);

export const LoginPage = () => {
  const classes = useStyles();
  const { user } = useUserAuth();
  const goBack = useGoBack();

  useEffect(() => {
    if (user) {
      goBack();
    }
  }, [user, goBack]);

  return (
    <Box className={classes.root}>
      <LoginButton />
      <div id="spa-env" style={{ display: 'none' }}>
        {spaEnv}
      </div>
    </Box>
  );
};
