import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { IconSprite, Input, Select, Button, useTabs, Tabs, Checkbox } from 'components';
import { Grid } from 'components/Grid';
import { useTranslation } from 'react-i18next';
import { IssuesFilterFormProps } from './types';
import { themeOrange as theme } from 'theme';
import { Box, MenuItem } from '@material-ui/core';
import { IssueFilterTabs, IssueStatus, IssueType } from 'schema/serverTypes';
import { AssigneeAutocomplete } from './AssigneeAutocomplete';
import { useCallback } from 'react';
import { useController } from 'react-hook-form';
import { IssueChips } from './IssueChips';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '100%',
    },
    buttonReset: {
      display: 'flex',
      alignItems: 'flex-end',
      marginBottom: theme.spacing(0.2),
    },
    filterContainer: {
      width: 'calc(100vw - 260px)',
      overflow: 'hidden',
    },
    hide: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
    },
  })
);

export const IssuesFilterFormError = (props: IssuesFilterFormProps) => {
  const classes = useStyles();
  const { control, handleOnReset, setValue } = props;
  const { t } = useTranslation();

  const mineLabel = t('Mine');
  const incomingLabel = t('Incoming');
  const allLabel = t('All');
  const { field } = useController({ control, name: 'group' });
  const { onChange } = field;
  const initialTab = field.value ? ['mine', 'incoming', 'all'].indexOf(field.value) : 0;
  const tabsProps = useTabs([mineLabel, incomingLabel, allLabel], initialTab);
  const { tabIndex, onChangeTab } = tabsProps;

  const onMineChanged = useCallback(
    (e: any, index: number) => {
      onChangeTab(e, index);
      if (index === 0) {
        onChange(IssueFilterTabs.Mine);
      } else if (index === 1) {
        onChange(IssueFilterTabs.Incoming);
      } else {
        onChange(IssueFilterTabs.All);
      }
    },
    [onChange, onChangeTab]
  );

  return (
    <form>
      <Box mb={2.5} className={classes.filterContainer}>
        <Box>
          <Tabs {...tabsProps} value={tabIndex} onChangeTab={onMineChanged} />
        </Box>
      </Box>
      <Grid container columnSpacing={2} rowSpacing={2.5} className={classes.root}>
        <Grid md={4} xs={24} item>
          <AssigneeAutocomplete control={control} />
        </Grid>
        <Grid md={5} xs={24} item>
          <Select name="type" multiple label={t('Issue type')} variant="standard" control={control}>
            <MenuItem value={IssueType.Error}>{t('IssueType.Error')}</MenuItem>
            <MenuItem value={IssueType.Rework}>{t('IssueType.Rework')}</MenuItem>
          </Select>
        </Grid>
        <Grid md={4} xs={24} item>
          <Select name="status" multiple label={t('Status')} variant="standard" control={control}>
            <MenuItem value={IssueStatus.Opened}>{t('IssueStatus.Opened')}</MenuItem>
            <MenuItem value={IssueStatus.InProgress}>{t('IssueStatus.InProgress')}</MenuItem>
            <MenuItem value={IssueStatus.ForReview}>{t('IssueStatus.ForReview')}</MenuItem>
            <MenuItem value={IssueStatus.Closed}>{t('IssueStatus.Closed')}</MenuItem>
            <MenuItem value={IssueStatus.Postponed}>{t('IssueStatus.Postponed')}</MenuItem>
            <MenuItem value={IssueStatus.InQueue}>{t('IssueStatus.InQueue')}</MenuItem>
          </Select>
        </Grid>
        <Grid md={4} xs={24} item>
          <Input
            label={`${t('Search')}`}
            control={control}
            name="search"
            variant="standard"
            InputProps={{
              endAdornment: (
                <IconSprite width="16px" color={theme.palette.text.primary} icon="search" />
              ),
            }}
          />
        </Grid>
        <Grid md={4} xs={24} item className={classes.buttonReset}>
          <FormControl>
            <Button variant="text" onClick={handleOnReset}>
              {t('Reset')}
            </Button>
          </FormControl>
        </Grid>
        <Grid md={3} xs={24} item className={classes.hide}>
          <Checkbox control={control} name="hideCompleted" label={t('Hide completed')} />
        </Grid>
      </Grid>
      <Box mb={1}>
        <IssueChips control={control} setValue={setValue} />
      </Box>
    </form>
  );
};
