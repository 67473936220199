import { FormHelperTextClassKey, StyleRules } from '@material-ui/core';

const style: Partial<StyleRules<FormHelperTextClassKey>> = {
  root: {
    '&$error': {
      fontSize: 9,
      marginLeft: 0,
    },
  },
};

export default style;
