import { useTranslation } from 'react-i18next';
import { Box, createStyles, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { NewsData } from 'schema/serverTypes';
import { useNewsBackendQuery } from 'services/api';
import dayjs from 'dayjs';
import { Grid } from '../../Grid';
import { Empty } from '../../Empty';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: theme.spacing(1.5, 2.5),
      minHeight: 557,
      maxHeight: 388,
      overflowY: 'scroll',
      height: '100%',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      height: 30.5,
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
      marginBottom: theme.spacing(2.5),
    },
    newsContainer: {
      position: 'relative',
      '&:before': {
        content: "''",
        display: 'block',
        position: 'absolute',
        width: 2,
        height: 'calc(100% - 50px)',
        minHeight: 244,
        backgroundColor: '#E3E6F3',
        left: 18,
        top: 44,
      },
    },
    newsItemDate: {
      marginBottom: theme.spacing(1),
      fontSize: 12,
      position: 'relative',
      backgroundColor: 'white',
    },
    newsItem: {
      position: 'relative',
      marginBottom: theme.spacing(1.5),
      fontSize: 12,
      '& a': {
        color: theme.palette.text.primary,
        display: 'block',
        padding: theme.spacing(1.5, 0, 1.5, 3.5),
        '&:hover': {
          backgroundColor: theme.palette.background.default,
        },
      },
      '&:before': {
        content: "''",
        display: 'block',
        position: 'absolute',
        top: 14,
        marginLeft: 14,
        marginRight: 14,
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: '50%',
        backgroundColor: 'white',
        width: 10,
        height: 10,
      },
    },
    critical: {
      '&$newsItem:before': {
        border: `2px solid ${theme.palette.primary.main}`,
      },
      '&$newsItem a': {
        color: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: theme.palette.primary.light,
        },
      },
    },
    general: {},
  })
);

export const NewsContainer = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { data } = useNewsBackendQuery<NewsData>({
    relativeUrl: '',
    queryKey: 'newsFeed',
    options: {
      retry: true,
      retryDelay: 60 * 1000,
      cacheTime: 60 * 1000,
    },
  });

  const news = data?.data ?? [];

  const newsByDate = news.reduce(function (r, a) {
    r[a.createdDate.substring(0, 10)] = r[a.createdDate.substring(0, 10)] || [];
    r[a.createdDate.substring(0, 10)].push(a);
    return r;
  }, Object.create(null));

  return (
    <Paper className={classes.root}>
      <Box className={classes.header}>
        <Typography variant="subtitle1">{t('Newsfeed')}</Typography>
      </Box>
      {Object.entries(newsByDate).length === 0 && <Empty>{t("You don't have any news yet")}</Empty>}
      <div className={classes.newsContainer}>
        {Object.entries(newsByDate).map((item: any) => {
          const date = dayjs(item[0]);

          return (
            <Grid key={item[0]} item xs={24}>
              <Typography variant="subtitle1" className={classes.newsItemDate}>
                {date.isToday()
                  ? 'Сегодня'
                  : date.isYesterday()
                  ? 'Вчера'
                  : date.format('DD.MM.YYYY')}
              </Typography>
              <ul>
                {item[1].map(
                  (n: { title: string; id: string; category: 'general' | 'critical' }) => {
                    return (
                      <li className={clsx(classes.newsItem, classes[n.category])} key={n.id}>
                        <Link to={'/news'}>{n.title}</Link>
                      </li>
                    );
                  }
                )}
              </ul>
            </Grid>
          );
        })}
      </div>
    </Paper>
  );
};
