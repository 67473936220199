import { Checkbox } from 'components/form';
import { useCalculationFormContext } from '../types';
import { useWatch } from 'react-hook-form';
import { useEffect } from 'react';
import { CalculationMethod } from 'schema/serverTypes';
import { isPreferentialMonth } from './isPreferentialMonth';

export type IsPreferentialCheckBoxProps = {
  index: number;
  numberOfMonths: number;
  isSuperSeasonal: boolean;
  calculationMethodChange: number;
};

export const IsPreferentialCheckBox = (props: IsPreferentialCheckBoxProps) => {
  const { index, numberOfMonths, isSuperSeasonal, calculationMethodChange } = props;
  const isLastMonth = index === numberOfMonths - 1;

  const { control, getValues, setValue } = useCalculationFormContext();

  const isPayment = useWatch({
    control,
    name: `paymentOptions.${index}.isPayment`,
  });

  const disabled = isSuperSeasonal || isLastMonth || !isPayment;

  const calculationMethod = useWatch({ control, name: 'calculationMethod' });
  const date = useWatch({ control, name: 'seasonalPaymentOptions.date' });

  useEffect(() => {
    const isPreferentialOld = getValues(`paymentOptions.${index}.isPreferential`);
    const number = getValues(`paymentOptions.${index}.number`);
    const isPreferential = isPreferentialMonth(number, date);

    if (calculationMethod === CalculationMethod.SuperSeasonal && calculationMethodChange) {
      if (isPreferentialOld !== isPreferential && number < 12) {
        setValue(`paymentOptions.${index}.isPreferential`, isPreferential);
      }
    } else if (calculationMethod === CalculationMethod.Seasonal && calculationMethodChange) {
      if (isPreferentialOld !== false) {
        setValue(`paymentOptions.${index}.isPreferential`, false);
      }
    }
  }, [calculationMethod, calculationMethodChange, index, date, getValues, setValue]);

  const isPreferential = useWatch({ control, name: `paymentOptions.${index}.isPreferential` });

  useEffect(() => {
    const isPayment = getValues(`paymentOptions.${index}.isPayment`);
    const value = getValues(`paymentOptions.${index}.value`);

    if (isPreferential && isPayment && value !== 0) {
      setValue(`paymentOptions.${index}.value`, 0);
    }
  }, [isPreferential, index, setValue, getValues]);

  return (
    <Checkbox
      control={control}
      name={`paymentOptions.${index}.isPreferential`}
      disabled={disabled}
      isCircle={false}
    />
  );
};
