import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { YearField } from './types';
import { useTranslation } from 'react-i18next';
import { MonthPaymentOption } from 'schema/serverTypes';
import { PaymentMonth } from './PaymentMonth';
import { IsPaymentCheckBox } from './IsPaymentCheckBox';
import { PaymentAmountInput } from './PaymentAmountInput/PaymentAmountInput';
import { IsPreferentialCheckBox } from './IsPreferentialCheckBox';
import dayjs from 'dayjs';
import { useLanguage } from '../../../utils/useLanguage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderCollapse: 'separate',
      borderSpacing: '0 4px',
      border: 'none',
      width: '100%',
      '& .MuiFormControlLabel-root': {
        margin: 0,
        '& .MuiButtonBase-root': {
          padding: 0,
        },
      },
      '& .MuiFormHelperText-root': {
        marginTop: 0,
      },
    },
    input: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      verticalAlign: 'middle',
      '& .MuiFormControl-root': {
        marginTop: 0,
        justifyContent: 'center',
        '& .MuiGrid-root': {
          justifyContent: 'center',
        },
      },
      '& .MuiInputBase-root': {
        backgroundColor: theme.palette.common.white,
      },
      '& .MuiOutlinedInput-inputMarginDense': {
        paddingTop: 4,
        paddingBottom: 4,
      },
    },
    thead: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.secondary,
      '& th': {
        fontWeight: 400,
        lineHeight: '24px',
        '&:first-child': {
          paddingLeft: theme.spacing(1),
        },
        '&:last-child': {
          paddingRight: theme.spacing(1),
        },
      },
    },
    lineTable: {
      height: 32,
      backgroundColor: theme.palette.background.default,
      marginBottom: 4,
      '& td': {
        padding: theme.spacing(0, 0.5),
        '&:first-child': {
          paddingLeft: theme.spacing(1),
        },
        '&:last-child': {
          paddingRight: theme.spacing(1),
        },
      },
    },
    lineEmpty: {
      height: 32,
      '& td': {
        border: '1px solid' + theme.palette.background.default,
        borderStyle: 'solid none',
      },
      '& td:first-child': {
        borderLeftStyle: 'solid',
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
      },
      '& td:last-child': {
        borderRightStyle: 'solid',
        borderBottomRightRadius: 4,
        borderTopRightRadius: 4,
      },
    },
    Payment: {
      textAlign: 'center',
    },
    Preferential: {
      textAlign: 'center',
    },
    Coefficient: {},
    tbody: {
      borderCollapse: 'separate',
      borderSpacing: '0 4px',
      '& td': {
        verticalAlign: 'middle',
      },
    },
    empty: {
      color: theme.palette.text.secondary,
    },
  })
);

export type PaymentYearTableProps = {
  fields: YearField[];
  numberOfMonths: number;
  year: number;
  update: (index: number, value: MonthPaymentOption) => void;
  date?: string;
  quotaId?: number;
  calculationMethodChange: number;
};

export const PaymentYearTable = (props: PaymentYearTableProps) => {
  const classes = useStyles();
  const { fields, year, numberOfMonths, date = '', quotaId, calculationMethodChange } = props;
  const { t } = useTranslation();
  const emptyMonth = Array(12 - fields.length).fill({ index: -1 });
  const fullFields =
    fields.length < 12 && fields[0].index === 0
      ? [...emptyMonth, ...fields]
      : [...fields, ...emptyMonth];

  return (
    <table className={classes.root}>
      <thead className={classes.thead}>
        <tr>
          <th align="left">№</th>
          <th align="left">{t('Month')}</th>
          <th>{t('IsPayment')}</th>
          <th>{t('IsPreferential')}</th>
          <th className={classes.Coefficient}>{t('Coefficient')}</th>
        </tr>
      </thead>
      <tbody className={classes.tbody}>
        {fullFields.map((field, i) => {
          const { item, index, disabled: isSuperSeasonal } = field;

          return index >= 0 ? (
            <tr className={classes.lineTable} key={item.id + date}>
              <PaymentMonth item={item} year={year} />
              <td className={classes.Payment}>
                <IsPaymentCheckBox
                  index={index}
                  isSuperSeasonal={isSuperSeasonal}
                  numberOfMonths={numberOfMonths}
                  calculationMethodChange={calculationMethodChange}
                />
              </td>
              <td className={classes.Preferential}>
                <IsPreferentialCheckBox
                  index={index}
                  isSuperSeasonal={isSuperSeasonal}
                  numberOfMonths={numberOfMonths}
                  calculationMethodChange={calculationMethodChange}
                />
              </td>
              <td className={classes.input}>
                <PaymentAmountInput
                  index={index}
                  isSuperSeasonal={isSuperSeasonal}
                  quotaId={quotaId}
                />
              </td>
            </tr>
          ) : (
            <EmptyMonth key={i + date} month={i} />
          );
        })}
      </tbody>
    </table>
  );
};

const EmptyMonth = (props: { month: number }) => {
  const classes = useStyles();
  const lang = useLanguage();
  const { month } = props;

  return (
    <tr className={classes.lineEmpty}>
      <td></td>
      <td className={classes.empty} colSpan={4}>
        {dayjs().month(month).locale(lang).format('MMM')}
      </td>
    </tr>
  );
};
