import { LogoutButton } from 'components';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 'calc(100vh - 20px) ',
    },
  })
);

export const LogoutPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LogoutButton />
    </div>
  );
};
