import { Control, useController } from 'react-hook-form';
import { ApplicationUserViewModel, TemplateDataFilter } from 'schema/serverTypes';
import { Autocomplete } from 'components';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
} from '@material-ui/lab/useAutocomplete/useAutocomplete';

export type QuotaOwnerAutocompleteProps = {
  control: Control<TemplateDataFilter, any>;
  tabUsers: ApplicationUserViewModel[];
};

const getOptionLabel = (option: ApplicationUserViewModel | null) => {
  if (option === null) {
    return '';
  }
  return option.firstName
    ? `${option.lastName} ${option.firstName} (${option.email})`
    : `${option.lastName} (${option.email})`;
};

const getOptionSelected = (
  option: ApplicationUserViewModel | null,
  value: ApplicationUserViewModel | null
) => {
  if (option === null || value === null) {
    return false;
  }
  return option.id === value.id;
};

export const QuotaOwnerAutocomplete = (props: QuotaOwnerAutocompleteProps) => {
  const { control, tabUsers } = props;
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState('');

  const {
    field: { onChange, value: users = [] },
  } = useController({
    control,
    name: 'users',
    defaultValue: [],
  });

  const options =
    inputValue.length > 0
      ? tabUsers.filter((user) => {
          const label = getOptionLabel(user).toLowerCase();
          const input = inputValue.toLowerCase();
          const hasInput = label.indexOf(input) > -1;
          return !users.includes(user.id) && hasInput;
        })
      : tabUsers;

  const handleOnInputChange = useCallback(
    (_ev: React.ChangeEvent<{}>, value: string, _reason: AutocompleteInputChangeReason) => {
      setInputValue(value);
    },
    [setInputValue]
  );

  const handleOnChange = useCallback(
    (
      _e: React.ChangeEvent<{}>,
      value: ApplicationUserViewModel | null,
      _reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<ApplicationUserViewModel | null>
    ) => {
      if (value === null) {
        onChange([]);
        setInputValue('');
      } else {
        onChange(users ? [...users.filter((userId) => userId !== value.id), value.id] : [value.id]);
        setInputValue('');
      }
    },
    [onChange, users]
  );

  return (
    <Autocomplete<ApplicationUserViewModel | null>
      label={t('Owner')}
      options={options}
      inputValue={inputValue}
      onInputChange={handleOnInputChange}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      onChange={handleOnChange}
      value={null}
      variant="outlined"
    />
  );
};
