import { makeStyles, Theme, createStyles, Tooltip } from '@material-ui/core';
import { Grid } from 'components';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: '0 1 auto',
      '& > div > div': {
        height: '52.63px',
        borderBottom: '1px solid #E3E6F3',
        display: 'flex',
        alignItems: 'center',
      },
    },
    label: {
      paddingRight: 34,
      position: 'relative',
      zIndex: 1,
      '&:hover': {
        '& $infoIcon': {
          display: 'inline-flex',
        },
      },
    },
    infoIcon: {
      display: 'none',
      position: 'absolute',
      justifyContent: 'center',
      alignItems: 'center',
      top: '50%',
      right: 12,
      transform: 'translateY(-50%)',
      color: theme.palette.common.black,
      border: `1px solid ${theme.palette.common.black}`,
      borderRadius: '50%',
      width: 16,
      height: 16,
      fontSize: 10,
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        '& $infoText': {
          display: 'inline-block',
        },
      },
    },
    infoText: {},
  })
);

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    position: 'relative',
    zIndex: 5,
    width: 'max-content',
    maxWidth: 440,
    backgroundColor: 'white',
    color: theme.palette.common.black,
    fontSize: 10,
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 4,
    padding: theme.spacing(1),
    '& ol': {
      paddingLeft: 12,
      '& > li': {
        listStyle: 'auto',
        marginBottom: 4,
      },
    },
  },
}))(Tooltip);

export const RatingBusinessLabels = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container rowSpacing={0}>
        <Grid item xs={24} className={classes.label}>
          Концентрация клиентов и/или поставщиков
          <HtmlTooltip
            title={
              <>
                <div className={clsx(classes.infoText)}>
                  <ol>
                    <li>
                      Низкая концентрация: на крупнейшего покупателя/поставщика приходится менее 5%
                      и/или на долю 5 крупнейших покупателей/поставщиков вместе взятых приходится
                      менее 25% от общего объема продаж/закупок группы или поставщиков легко
                      заменить: на рынке много поставщиков, сотрудничество с которыми не приведёт к
                      росту издержек. Кроме того, поставщики/заёмщики имеют лучшее качество, чем
                      средние поставщики/покупатели на рынке.
                    </li>
                    <li>
                      Средняя концентрация: на крупнейшего покупателя/поставщика приходится менее
                      20% и/или на долю 5 крупнейших покупателей/поставщиков вместе взятых
                      приходится менее 50% от общего объема продаж/закупок группы или поставщики
                      умеренно замещаемы: на рынке много поставщиков, сотрудничество с которыми
                      приведёт к небольшому росту издержек. Кроме того, поставщики/заёмщики имеют
                      такое же качество, как и средние поставщики/покупатели на рынке.
                    </li>
                    <li>
                      Высокая концентрация: на крупнейшего покупателя/поставщика приходится более
                      20% и/или на долю 5 крупнейших покупателей/поставщиков вместе взятых
                      приходится более 50% от общего объема продаж/закупок группы или поставщиков
                      трудно заменить: едва ли найдутся.
                    </li>
                  </ol>
                </div>
              </>
            }
          >
            <div className={classes.infoIcon}>
              <div>i</div>
            </div>
          </HtmlTooltip>
        </Grid>
        <Grid item xs={24} className={classes.label}>
          Как прогноз развития отрасли повлияет на компанию?
          <HtmlTooltip
            title={
              <div className={classes.infoText}>
                Оцените прогноз развития отрасли не только в краткосрочной, но и долгосрочной
                перспективе (до 2-х лет). <br />
                Во внимание следует принимать следующие аспекты:
                <br />
                - рост спроса и предложения
                <br />
                - избыток и недостаток производственных мощностей в отрасли
                <br />
                - затраты на сырье и оплату труда
                <br />
                - макроэкономическое развитие (например, процентные ставки)
                <br />
                - влияние Государства/налоговые изменения
                <br />- волатильность валюты
              </div>
            }
          >
            <div className={classes.infoIcon}>
              <div>i</div>
            </div>
          </HtmlTooltip>
        </Grid>
        <Grid item xs={24} className={classes.label}>
          По сравнению с конкурентами, как бы вы охарактеризовали деятельность компании в своем
          отраслевом сегменте?
        </Grid>
        <Grid item xs={24} className={classes.label}>
          Как бы вы описали волатильность ключевого отраслевого сегмента клиентов?
          <HtmlTooltip
            title={
              <div className={classes.infoText}>
                Волатильность в этом контексте относится к колебаниям в общих финансовых показателей
                основного отраслевого сегмента клиентов из-за внешних или внутренних факторов (не
                связанных с экономическим циклом). Например, телекоммуникации и интернет-индустрия
                могут считаться высоко волатильной. В отличие от цикличности, волатильность чаще
                связана с нерегулярными резкими скачками или падениями, которые могут происходить
                ежедневно, еженедельно или ежемесячно.
              </div>
            }
          >
            <div className={classes.infoIcon}>
              <div>i</div>
            </div>
          </HtmlTooltip>
        </Grid>
        <Grid item xs={24} className={classes.label}>
          Как бы вы оценили качество управления?
          <HtmlTooltip
            title={
              <div className={classes.infoText}>
                Учитывайте следующие аспекты при оценке способности управления:
                <br />
                - адекватность управления рисками по сравнению с профилем риска (разделение задач,
                наличие и применение политик, управленческие информационные системы (включая
                бухгалтерию и ИТ), структуры замещения кадров)
                <br />
                - управление товарно-сырьевым, валютным и процентным риском, где это применимо
                <br />
                - качество планов/прогнозов
                <br />
                - репутация в части реализации планов/стратегий
                <br />
                - опыт работы в индустрии/мнение коллег
                <br />
                - добросовестность
                <br />
                - управленческие ресурсы на случай непредвиденных событий(кризис, возможности)
                <br />
                - текучка кадров в результате неэффективной работы и/или внутренних конфликтов
                <br />
                - если отсутствует качественная информация; мало
                <br />
              </div>
            }
          >
            <div className={classes.infoIcon}>
              <div>i</div>
            </div>
          </HtmlTooltip>
        </Grid>
        <Grid item xs={24}>
          Имеются ли какие-либо предупредительные сигналы?
        </Grid>
      </Grid>
    </div>
  );
};
