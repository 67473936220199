import { useBackendQuery, QueryOptions } from '.';
import { CounterpartyExternalContractViewModel } from 'schema';
import { BackendQueryOptions, createOptions, createUrlFromParts } from './types';

const entity = 'counterparties/documents';

const useQuery = <TResponse>(options: BackendQueryOptions<TResponse>) => {
  let queryKey = entity;

  if (options.relativeUrl && options.relativeUrl !== '') {
    queryKey = `${queryKey}-${options.relativeUrl}`;
  }
  if (options.searchParams) {
    queryKey = `${queryKey}-${options.searchParams}`;
  }
  return useBackendQuery(
    createUrlFromParts(entity, options.relativeUrl, options.searchParams),
    queryKey,
    createOptions(options.options)
  );
};

export const useCounterpartyDocumentsQuery = (
  inn: string,
  options?: QueryOptions<CounterpartyExternalContractViewModel[], string>
) =>
  useQuery({
    relativeUrl: `${inn}`,
    options,
  });
