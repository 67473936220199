import { makeStyles, Theme, createStyles, Paper } from '@material-ui/core';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { RatingEditForm } from './RatingEditForm';
import { useRouteMatch } from 'react-router-dom';
import { IconSprite } from '../../icons';
import { useRatingCalculationsQuery } from '../../../services/api/useRatingsBackend';
import { RatingLabels } from './RatingLabels';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 'calc(100vw - 190px - 48px - var(--scrollbar-width)) ',
      display: 'flex',
      height: '100%',
      alignItems: 'stretch',
      position: 'relative',
    },
    labels: {
      display: 'flex',
      justifyContent: 'space-between',
      width: 400,
      flex: '0 0 auto',
      zIndex: 5,
      boxShadow: '0px 2px 20px rgba(56, 60, 97, 0.1)',
    },
    ratingArr: {
      display: 'flex',
      overflowX: 'scroll',
      overflowY: 'hidden',
      boxShadow: 'none',
    },
    scrollActions: {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'absolute',
      top: -24,
      right: 0,
      width: 36,
      '& div': {
        cursor: 'pointer',
      },
    },
    filter: {
      display: 'flex',
      width: '100%',
    },
  })
);

type RatingCompareProps = {
  setCompareIds: Dispatch<SetStateAction<number[]>>;
  compareIds: number[];
};

export const RatingCompare = (props: RatingCompareProps) => {
  const classes = useStyles();
  const { setCompareIds, compareIds } = props;
  const [optional, setOptional] = useState<boolean>(false);
  const {
    params: { inn },
  } = useRouteMatch<{ inn: string }>();
  const { data: ratings = [], isLoading } = useRatingCalculationsQuery(inn, compareIds);

  const scrollRef = useRef<HTMLDivElement | null>(null);

  const handlerScrollLeft = () => {
    const el = scrollRef.current as HTMLElement;
    el.scrollTo({
      left: el.scrollLeft - 328,
      behavior: 'smooth',
    });
  };

  const handlerScrollRight = () => {
    const el = scrollRef.current as HTMLElement;
    el.scrollTo({
      left: el.scrollLeft + 328,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <div className={classes.root}>
        <Paper square className={classes.labels}>
          <RatingLabels optional={optional} setOptional={setOptional} />
        </Paper>
        <Paper square className={classes.ratingArr} ref={scrollRef}>
          {ratings &&
            !isLoading &&
            ratings.map((rating) => {
              return (
                <RatingEditForm
                  key={rating.id}
                  rating={rating}
                  setCompareIds={setCompareIds}
                  compareIds={compareIds}
                  optional={optional}
                  setOptional={setOptional}
                />
              );
            })}
        </Paper>
        <div className={classes.scrollActions}>
          <div onClick={handlerScrollLeft}>
            <IconSprite icon={'arrow-left2'} width={'12px'} height={'8px'} />
          </div>
          <div onClick={handlerScrollRight}>
            <IconSprite icon={'arrow-right2'} width={'12px'} height={'8px'} />
          </div>
        </div>
      </div>
    </>
  );
};
