import { Grid } from 'components';
import { Dispatch, SetStateAction } from 'react';
import { useStyles } from './useStyles';
import clsx from 'clsx';
import {
  RatingQuestion1,
  RatingQuestion2,
  RatingQuestion3,
  RatingQuestion4,
  RatingQuestion5,
  RatingQuestion6,
} from './RatingQuestion';
import { RatingInputValues } from './types';
import { Control, UseFormSetValue } from 'react-hook-form';

export type RatingCreateFormProps = {
  inn: string;
  editId: number | null;
  setEdit: Dispatch<SetStateAction<number | null>>;
  control: Control<RatingInputValues & { quarter: string; year: string }, object>;
  setValue: UseFormSetValue<RatingInputValues & { quarter: string; year: string }>;
};

export const RatingBusinessFields = (props: RatingCreateFormProps) => {
  const { editId, control } = props;
  const classes = useStyles();

  const ratingClass = !editId ? classes.root : clsx(classes.root, classes.noEditMode);

  return (
    <>
      <div className={ratingClass}>
        <Grid container rowSpacing={2}>
          <Grid item xs={24}>
            <RatingQuestion1 control={control} />
          </Grid>
          <Grid item xs={24}>
            <RatingQuestion2 control={control} />
          </Grid>
          <Grid item xs={24}>
            <RatingQuestion3 control={control} />
          </Grid>
          <Grid item xs={24}>
            <RatingQuestion4 control={control} />
          </Grid>
          <Grid item xs={24}>
            <RatingQuestion5 control={control} />
          </Grid>
          <Grid item xs={24}>
            <RatingQuestion6 control={control} />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
