import { useState, useCallback, useEffect } from 'react';
import { TabProps } from './types';
import { getTabProps } from './Tabs';
import { useModalForm } from '../Modal';

const a11yProps = (index: number) => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
};

export const useTabs = (tabs: Array<string> | Array<TabProps>, initialTab: number = 0) => {
  const [tabIndex, setTabIndex] = useState(initialTab);
  useEffect(() => {
    setTabIndex(initialTab);
  }, [initialTab, setTabIndex]);
  const [nextTabIndex, setNextTabIndex] = useState(initialTab);
  const { onOpen, onClose, open } = useModalForm();
  const onChangeTab = useCallback(
    (_event: React.ChangeEvent<{}>, index: number) => {
      const { confirmation } = getTabProps(tabs[tabIndex]);
      if (confirmation) {
        onOpen();
        setNextTabIndex(index);
      } else {
        setTabIndex(index);
      }
    },
    [tabs, tabIndex, onOpen]
  );

  const onChangeTabIndex = useCallback((index: number) => {
    setTabIndex(index);
  }, []);

  const confirmLeaveHandler = useCallback(() => {
    setTabIndex(nextTabIndex);
    onClose();
  }, [onClose, nextTabIndex]);

  return {
    tabIndex,
    onChangeTab,
    onChangeTabIndex,
    a11yProps,
    tabs,
    onOpen,
    onClose,
    open,
    confirmLeaveHandler,
  } as const;
};
