import {
  makeStyles,
  createStyles,
  Theme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { usePriceDropsQuery } from 'services/api/useQuotasBackend';
import Typography from '@material-ui/core/Typography';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { formatMoney, formatNumber } from '../../utils';
import { ApprovalTable } from './ApprovalTable';
import { QuotaCalculationResult, QuotaNomenclaturePriceDrop } from 'schema/serverTypes';
import { ScoringList } from './ScoringList';
import { Margin } from './Margin';
import { RisksGrid } from './Risks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      //marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(2),
    },
    title: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: theme.palette.background.paper,
      padding: theme.spacing(1.5, 0),
    },
    accordionDetails: {
      padding: 0,
      flexDirection: 'column',
    },
    averagePriceDrop: {
      fontSize: 12,
    },
    averagePriceDropTitle: {
      display: 'inline-block',
      marginRight: theme.spacing(2),
      color: theme.palette.common.black,
    },
    averagePriceDropValue: {
      color: theme.palette.success.main,
    },
    header: {
      padding: theme.spacing(1, 2.5),
      backgroundColor: theme.palette.background.paper,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
    },
    name: {
      flex: '1 1 auto',
    },
    iconOpen: {
      marginLeft: theme.spacing(2),
      transform: 'rotate(180deg)',
    },
    icon: {
      marginLeft: theme.spacing(2),
    },
    category: {
      color: theme.palette.text.secondary,
      fontSize: 12,
    },
    details: {
      padding: 0,
    },
  })
);

export const Approval = (props: { quota: QuotaCalculationResult }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { quota } = props;
  const { data } = usePriceDropsQuery(quota.quotaId);
  let items = data?.drops ?? [];

  return (
    <>
      <div className={classes.root}>
        <RisksGrid quotaId={quota.quotaId} />
        <Margin quotaId={quota.quotaId} authorId={quota.user.id} />
        <ScoringList quotaId={quota.quotaId} />
        <Accordion defaultExpanded={false}>
          <AccordionSummary
            aria-controls="panel3a-content"
            id="panel3a-header"
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={classes.title}>
              <Typography variant="subtitle1">{t('GAP calculation')}</Typography>
              <div className={classes.averagePriceDrop}>
                <Typography
                  variant="subtitle1"
                  component="span"
                  className={classes.averagePriceDropTitle}
                >
                  {t('Average Price Drop')}
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="span"
                  className={classes.averagePriceDropValue}
                >
                  {formatNumber(data?.averagePriceDrop)}
                </Typography>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            {items.map((item) => (
              <Item {...item} key={item.price.monthNumber} />
            ))}
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};

const Item = (props: QuotaNomenclaturePriceDrop) => {
  const classes = useStyles();
  const {
    drops,
    price: { name, monthNumber, price, count, category },
  } = props;

  const [isOpen, setOpen] = useState<boolean>(false);

  return (
    <div className={classes.root}>
      <div className={classes.header} onClick={() => setOpen((prev) => !prev)}>
        <div className={classes.name}>
          <Typography variant="h4">
            {name}
            <Typography variant="body2" component={'span'} className={classes.category}>
              &nbsp;x&nbsp;{count}&nbsp;шт.
            </Typography>
          </Typography>
          <Typography variant="body2" className={classes.category}>
            {category}
          </Typography>
        </div>
        <Typography variant="h4">{formatMoney({ amount: price })}</Typography>
        <ExpandMoreIcon className={isOpen ? classes.iconOpen : classes.icon} />
      </div>
      <ApprovalTable drops={drops} monthNumber={monthNumber} isOpen={isOpen} />
    </div>
  );
};
