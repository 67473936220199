import { createStyles, makeStyles, MenuItem, Theme, Typography } from '@material-ui/core';
import { useRatingHistoryQuery } from '../../../services/api/useRatingsBackend';
import { Button } from '../../Button';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { Select } from '../../form';
import { useForm } from 'react-hook-form';
import { RatingInput } from '../../../schema';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(3),
    },
    label: {
      color: theme.palette.common.black,
      marginRight: theme.spacing(1.5),
    },
    control: {
      width: 100,
    },
    field: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(4),
    },
    action: {
      textAlign: 'center',
    },
    button: {},
  })
);

type RatingYearsModalProps = {
  inn: string;
  setKonturValues: Dispatch<SetStateAction<RatingInput | null>>;
  setKonturYear: Dispatch<SetStateAction<number | null>>;
  onClose: () => void;
};

export const RatingYearsModal = ({
  inn,
  setKonturValues,
  setKonturYear,
  onClose,
}: RatingYearsModalProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { data } = useRatingHistoryQuery(inn);

  const { handleSubmit, control, getValues } = useForm<{ year: string }>({
    mode: 'onBlur',
  });

  const yearOptions = useMemo(() => {
    return data?.map((item) => {
      return (
        <MenuItem key={'modal=' + item.year} value={item.year}>
          {item.year}
        </MenuItem>
      );
    });
  }, [data]);

  const onSubmit = useMemo(() => {
    const submit = () => {
      const curYear = getValues('year');
      const year = data?.find((item) => item.year === parseInt(curYear, 10));
      setKonturValues(year?.input ?? null);
      setKonturYear(year?.year ?? null);
      if (onClose) {
        onClose();
      }
    };
    return handleSubmit(submit);
  }, [handleSubmit, onClose, getValues, setKonturValues, setKonturYear, data]);

  return (
    <div className={classes.root}>
      <Typography variant={'h2'} className={classes.title}>
        Получить данные
      </Typography>
      <form onSubmit={onSubmit}>
        <div className={classes.field}>
          <div className={classes.label}>В Контур Фокус доступна отчетность за:</div>
          <div className={classes.control}>
            <Select
              label={t('Year')}
              name="year"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: '',
                },
              }}
            >
              <MenuItem value="">{t('Not set')}</MenuItem>
              {yearOptions}
            </Select>
          </div>
        </div>
        <div className={classes.action}>
          <Button variant="contained" className={classes.button} type={'submit'}>
            {t('Ok')}
          </Button>
        </div>
      </form>
    </div>
  );
};
