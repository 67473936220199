import { useCallback } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'inline-block',
      background: 'white',
      boxShadow: '1px 1px 4px rgba(56, 60, 97, 0.08)',
      borderRadius: 8,
      fontWeight: 500,
      fontSize: 12,
      color: theme.palette.secondary.main,
      padding: theme.spacing(0.5, 2.3, 0, 1),
      margin: theme.spacing(0, 1.5, 1, 0),
      position: 'relative',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.blueGrey.main,
        '& $delete': {
          '&:after, &:before': {
            backgroundColor: theme.palette.blueGrey.main,
          },
        },
      },
    },
    delete: {
      width: 6,
      height: 6,
      '&:after, &:before': {
        content: '""',
        position: 'absolute',
        display: 'block',
        backgroundColor: theme.palette.text.secondary,
        width: 8,
        height: 1,
        right: 8,
        top: 11,
        transform: 'rotate(45deg)',
      },
      '&:before': {
        transform: 'rotate(-45deg)',
      },
    },
  })
);

type RatingChipProps = {
  label: string;
  id?: string;
  onRemove: (label: string) => void;
};

export const RatingChip = (props: RatingChipProps) => {
  const { label, id, onRemove } = props;
  const classes = useStyles();

  const onRemoveHandler = useCallback(() => {
    if (id) {
      onRemove(id);
    } else {
      onRemove(label);
    }
  }, [label, id, onRemove]);

  return (
    <div className={classes.root} onClick={onRemoveHandler}>
      <div>{label}</div>
      <div className={classes.delete} />
    </div>
  );
};
