import { useWatch } from 'react-hook-form';
import { QuotaChipsProps } from './types';
import { useCallback } from 'react';
import { Chip } from 'components/Chips';
import { useTranslation } from 'react-i18next';

export const ExpectedShipmentDateChips = (props: QuotaChipsProps) => {
  const { control, setValue } = props;
  const hasExpectedShipmentDate = useWatch({ control, name: 'hasExpectedShipmentDate' });

  const onRemove = useCallback(
    (id: string) => {
      setValue('hasExpectedShipmentDate', undefined);
    },
    [setValue]
  );

  const { t } = useTranslation();

  if (hasExpectedShipmentDate === undefined || hasExpectedShipmentDate === false) {
    return null;
  }

  return (
    <Chip
      label={t('There is an expected shipment date')}
      id="hasExpectedShipmentDate"
      key="hasExpectedShipmentDate"
      onRemove={onRemove}
    />
  );
};
