import palette from '../paletteOrange';

const style = {
  root: {
    width: '100%',
  },
  light: {
    backgroundColor: palette.secondary.main,
  },
};

export default style;
