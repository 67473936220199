import { useCallback, useState } from 'react';
import { Control, UseFormSetValue, useController, useWatch } from 'react-hook-form';
import { useDebounce } from 'use-debounce';
import { useTranslation, Trans } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Autocomplete, Button, Chip, ModalForm, useModalForm } from 'components';
import { CreateFormFieldValues } from '../types';
import { useUsersBackendQuery } from 'services/api';
import { UserListViewModel } from 'components/users/types';
import { UserViewModel } from 'schema/serverTypes';
import { IconCheckbox } from '../../icons';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    valueList: {
      padding: theme.spacing(1.5, 1, 0, 0),
    },
    option: {
      marginTop: theme.spacing(-1),
      marginLeft: theme.spacing(-2),
      marginBottom: theme.spacing(-1),
    },
    error: {
      color: theme.palette.chartPurple.main,
      fontSize: 9,
      margin: '4px 0 0',
    },
    modalRoot: {
      textAlign: 'center',
    },
    modalTitle: {
      textAlign: 'center',
      marginBottom: theme.spacing(3),
    },
    modalText: {
      color: theme.palette.text.primary,
    },
    modalButton: {
      marginTop: theme.spacing(2),
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
  })
);

const getOptionLabel = (option: UserViewModel | null) => {
  if (option === null) {
    return '';
  }
  return option.name;
};

export type UserSelectFieldProps = {
  control: Control<CreateFormFieldValues, object>;
  required?: boolean;
  setValue: UseFormSetValue<CreateFormFieldValues>;
  disabled?: boolean;
};

export const UserSelectField = (props: UserSelectFieldProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { control, required = false, disabled } = props;
  const { onOpen, onClose, open: isModalOpen } = useModalForm();
  const [removedValues, setRemovedValues] = useState<UserViewModel[]>();
  const [user, setUser] = useState<string>();

  const getOptionSelected = useCallback(
    (option: UserViewModel | null, value: UserViewModel | null) => {
      if (option === null || value === null) {
        return false;
      }
      return option.id === value.id;
    },
    []
  );

  const {
    field: { value, onChange },
    fieldState: { invalid },
  } = useController({
    control,
    name: 'users',
    rules: required
      ? {
          required: {
            value: true,
            message: t('Required'),
          },
        }
      : undefined,
  });

  const helperText = invalid ? t('Required') : undefined;
  const title = useWatch({ control, name: 'title' });
  const [inputValue, setInputValue] = useState('');
  const [input] = useDebounce(inputValue, 500);

  const { data = [] } = useUsersBackendQuery<UserListViewModel[]>({
    relativeUrl: 'all',
    queryKey: ['users', 'all', input],
  });

  let options: UserViewModel[] = data.map((t) => {
    return {
      id: t.id,
      name: t.displayName,
    };
  });

  if (options.length === 0 && value.length > 0) {
    options = value;
  }

  options = [{ id: 'all', name: 'Все' }, ...options];

  const handleOnInputChange = useCallback(
    (_ev: React.ChangeEvent<{}>, value: string) => {
      setInputValue(value);
    },
    [setInputValue]
  );

  const handleOnChange = useCallback(
    (_e: React.ChangeEvent<{}>, values: UserViewModel[], reason) => {
      if (reason === 'remove-option') {
        setRemovedValues(values.filter((value) => value.name !== 'Все'));
        onOpen();
      } else if (values.find((value) => value.name === 'Все')) {
        onChange(options);
      } else {
        onChange(values);
      }
    },
    [onChange, onOpen, options]
  );

  const handleOnRemove = useCallback(
    (name) => {
      const newValue = value.filter((t) => t.name !== name);
      onOpen();
      setRemovedValues(newValue.filter((value) => value.name !== 'Все'));
      setUser(name);
    },
    [value, onOpen]
  );

  const handleConfirmDelete = useCallback(() => {
    onChange(removedValues);
    onClose();
  }, [onChange, onClose, removedValues]);

  return (
    <>
      <Autocomplete<UserViewModel, true>
        error={invalid}
        helperText={helperText}
        multiple
        label={`${t('Search by user')}`}
        options={options}
        inputValue={inputValue}
        onInputChange={handleOnInputChange}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        onChange={handleOnChange}
        value={value}
        disableCloseOnSelect={true}
        renderTags={() => null}
        disabled={disabled}
        renderOption={(option: UserViewModel, { selected }) => {
          return (
            <div key={option.id} className={classes.option}>
              <Checkbox
                icon={<IconCheckbox checked={false} />}
                checkedIcon={<IconCheckbox checked={true} />}
                checked={selected}
                onClick={() => {
                  setUser(option.name);
                }}
              />
              {option.name}
            </div>
          );
        }}
      />
      <div className={classes.valueList}>
        {value.map((user) => {
          return (
            user.id !== 'all' && <Chip label={user.name} key={user.id} onRemove={handleOnRemove} />
          );
        })}
      </div>

      <ModalForm open={isModalOpen} onClose={onClose}>
        <div className={classes.modalRoot}>
          <Typography variant={'h2'} className={classes.modalTitle}>
            {t('Delete user from list')}
          </Typography>
          <Typography variant="subtitle2" className={classes.modalText}>
            <Trans i18nKey="Are you sure you want to close access" values={{ user, title }}>
              Are you sure you want to close access <strong>{{ user }}</strong> к шаблону{' '}
              {{ title }}?
            </Trans>
          </Typography>
          <div>
            <Button
              variant="contained"
              className={classes.modalButton}
              onClick={handleConfirmDelete}
            >
              {t('Yes')}
            </Button>
            <Button variant="outlined" onClick={onClose} className={classes.modalButton}>
              {t('No')}
            </Button>
          </div>
        </div>
      </ModalForm>
    </>
  );
};
