import {
  Calculator,
  QuotaCommentFormPanel,
  Critical,
  QuotaCalculatorForm,
  QuotaOwnerFormPanel,
} from 'components';
import { QuotaDataGrid } from 'components/calculator/QuotaDataGrid';
import { CurrencyRatesContextProvider } from 'components/calculator';
import { Route, RouteComponentProps, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { IssueSource, QuotaCalculationResult } from 'schema';
import { useQuotasBackendQuery } from 'services';
import { useCurrencyRatesQuery } from 'components/calculator';
import { useCofsQuery } from 'components/calculator';
import { useErrorContext } from 'components/tasks';
import { useTranslation } from 'react-i18next';
import { QuotaCalculatorFormTask } from '../../components/calculator/QuotaCalculatorForm/QuotaCalculatorFormTask';
import { useDefaultCalculationValues } from 'components/calculator';

const QuotaListPage = (props: RouteComponentProps) => {
  const path = props.location.pathname;
  const changeOwnerPath = `/quotas/calculator/owner/:id(\\d+)`;
  const commentsPath = `/quotas/calculator/comments/:id(\\d+)`;

  return (
    <>
      <Critical />
      <Route path={path} component={QuotaDataGrid} />
      <Route exact path={commentsPath} component={QuotaCommentFormPanel} />
      <Route exact path={changeOwnerPath} component={QuotaOwnerFormPanel} />
    </>
  );
};

type QuotaCalculatorFormPageProps = {
  quotaId?: number;
  shipmentItemId?: number;
};

export const QuotaCalculatorFormPage = ({
  quotaId,
  shipmentItemId,
}: QuotaCalculatorFormPageProps) => {
  const { id: idString } = useParams<{ id: string }>();
  const id = quotaId || Number.parseInt(idString);
  const isTask = !!quotaId;
  const url = `${id}`;
  const { data: quota, isLoading } = useQuotasBackendQuery<QuotaCalculationResult>(
    url,
    ['quotas', id],
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  const { data: currencies, isLoading: isLoadingRates } = useCurrencyRatesQuery();
  const { data: cofs, isLoading: isLoadingCofs } = useCofsQuery();

  if (!quota || !currencies || isLoading || isLoadingRates || !cofs || isLoadingCofs) {
    return null;
  }

  return (
    <CurrencyRatesContextProvider currencies={currencies} cofs={cofs}>
      {isTask ? (
        <QuotaCalculatorFormTask quota={quota} shipmentItemId={shipmentItemId} />
      ) : (
        <QuotaCalculatorForm quota={quota} />
      )}
    </CurrencyRatesContextProvider>
  );
};

const CalculatorFormPage = () => {
  const { data: currencies, isLoading } = useCurrencyRatesQuery();
  const { data: cofs, isLoading: isLoadingCofs } = useCofsQuery();
  const defaultCalculationValues = useDefaultCalculationValues();

  const { t } = useTranslation();
  const title = t('Calculation');

  useErrorContext({
    title,
    source: IssueSource.quota,
  });

  if (!currencies || isLoading || !cofs || isLoadingCofs || !defaultCalculationValues) {
    return null;
  }

  return (
    <CurrencyRatesContextProvider currencies={currencies} cofs={cofs}>
      <Calculator defaultCalculationValues={defaultCalculationValues} />
    </CurrencyRatesContextProvider>
  );
};

export const CalculatorPage = () => {
  const { path } = useRouteMatch();
  const quotaResultPath = `${path}/results/:id(\\d+)`;
  const resultsPath = `${path}/results`;

  return (
    <Switch>
      <Route exact path={path} component={CalculatorFormPage} />
      <Route exact path={quotaResultPath} component={QuotaCalculatorFormPage} />
      <Route path={resultsPath} component={QuotaListPage} />
    </Switch>
  );
};
