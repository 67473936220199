import {
  IndustryFilter,
  IndustryPagedList,
  IndustrySorting,
  PageOptions,
  AddIndustryRequest,
  EditIndustryRequest,
  IndustryViewModel,
  EditIndustrySpecializationRequest,
  IndustrySpecializationViewModel,
  AddIndustrySpecializationRequest,
} from 'schema/serverTypes';
import { BackendQueryOptions, createOptions, createUrlFromParts } from './types';
import {
  useBackendQuery,
  useBackendMutation,
  MutationOptions,
  useBackendFetch,
} from './useBackend';
import { useQueryClient } from 'react-query';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from 'components';

const entity = '/dictionaries/industries';

const specEntity = 'specializations';

const useIndustryQuery = <TResponse>(options: BackendQueryOptions<TResponse>) => {
  let queryKey = entity;
  if (options.relativeUrl && options.relativeUrl !== '') {
    queryKey = `${queryKey}-${options.relativeUrl}`;
  }
  if (options.searchParams) {
    queryKey = `${queryKey}-${options.searchParams}`;
  }
  return useBackendQuery(
    createUrlFromParts(entity, options.relativeUrl, options.searchParams),
    queryKey,
    createOptions(options.options)
  );
};

type UseIndustryMutationProps<TRequest, TResponse> = MutationOptions<TRequest, TResponse> & {
  relativeUrl?: string;
};

const useIndustryMutation = <TRequest, TResponse>(
  options: UseIndustryMutationProps<TRequest, TResponse> | undefined = undefined
) => {
  const relativeUrl = options?.relativeUrl;

  return useBackendMutation(createUrlFromParts(entity, relativeUrl), {
    method: 'PUT',
    ...options,
  });
};

export type UseIndustryPagedListQueryProps = IndustryFilter &
  Partial<IndustrySorting> &
  PageOptions;

export const useIndustryPagedListQuery = (props: UseIndustryPagedListQueryProps) => {
  const { name = '', parameterEstimation, raks, order, sortBy, page = 1, pageSize = 20 } = props;
  let searchParams = new URLSearchParams();

  if (name !== '') {
    searchParams.append('name', name);
  }

  if (parameterEstimation !== undefined) {
    searchParams.append('parameterEstimation', parameterEstimation.toString());
  }

  if (raks !== undefined) {
    searchParams.append('raks', raks.toString());
  }

  if (sortBy) {
    searchParams.append('sortBy', sortBy);
  }

  if (order) {
    searchParams.append('order', order);
  }

  searchParams.append('page', page.toString());
  searchParams.append('pageSize', pageSize.toString());

  return useIndustryQuery<IndustryPagedList>({
    searchParams,
    options: {
      refetchOnMount: true,
    },
  });
};

export const useIndustryByIdQuery = (id: number) => {
  return useIndustryQuery<IndustryViewModel>({
    relativeUrl: id.toString(),
  });
};

export const useIndustrySpecializationByIdQuery = (id: number) => {
  return useIndustryQuery<IndustrySpecializationViewModel>({
    relativeUrl: `${specEntity}/${id}`,
  });
};

const useInvalidateQuery = () => {
  const queryClient = useQueryClient();

  return useCallback(() => {
    queryClient.invalidateQueries({
      predicate: (query) => {
        return query.queryKey.indexOf('industries') > -1;
      },
    });
  }, [queryClient]);
};

export const useAddIndustryMutation = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const invalidate = useInvalidateQuery();

  return useIndustryMutation<AddIndustryRequest, IndustryViewModel>({
    onSuccess: (response) => {
      invalidate();
      toast(t('SuccessMessage'), 'success');
    },
    onError: (_response) => {
      toast(t('ErrorMessage'), 'error');
    },
    method: 'POST',
  });
};

export const useEditIndustryMutation = (id: number) => {
  const { t } = useTranslation();
  const toast = useToast();
  const invalidate = useInvalidateQuery();

  return useIndustryMutation<EditIndustryRequest, IndustryViewModel>({
    relativeUrl: id.toString(),
    method: 'PUT',
    onSuccess: (_response) => {
      invalidate();
      toast(t('SuccessMessage'), 'success');
    },
    onError: (_response) => {
      toast(t('ErrorMessage'), 'error');
    },
  });
};

export const useAddIndustrySpecializationMutation = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const invalidate = useInvalidateQuery();

  return useIndustryMutation<AddIndustrySpecializationRequest, IndustrySpecializationViewModel>({
    onSuccess: (response) => {
      invalidate();
      toast(t('SuccessMessage'), 'success');
    },
    onError: (_response) => {
      toast(t('ErrorMessage'), 'error');
    },
    relativeUrl: specEntity,
    method: 'POST',
  });
};

export const useEditIndustrySpecializationMutation = (id: number) => {
  const { t } = useTranslation();
  const toast = useToast();
  const invalidate = useInvalidateQuery();

  return useIndustryMutation<EditIndustrySpecializationRequest, IndustrySpecializationViewModel>({
    relativeUrl: `${specEntity}/${id}`,
    onSuccess: (_response) => {
      invalidate();
      toast(t('SuccessMessage'), 'success');
    },
    onError: (_response) => {
      toast(t('ErrorMessage'), 'error');
    },
  });
};

export const useDownloadDictionary = <TRequest>() => {
  const fetchBackend = useBackendFetch();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const downloadAsync = useCallback(
    async (values?: TRequest) => {
      setIsLoading(true);
      try {
        const response = await fetchBackend(createUrlFromParts('dictionaries/industry/export'), {
          method: 'POST',
        });

        if (response.status !== 200) {
          setIsError(true);
          return;
        }

        let realFileName = 'dictionary';
        const header = response.headers.get('content-disposition');
        if (header !== null) {
          realFileName =
            header
              .split(';')
              .find((n) => n.includes("filename*=UTF-8''"))
              ?.replace("filename*=UTF-8''", '')
              .trim() ?? 'dictionary';

          realFileName = decodeURIComponent(decodeURI(realFileName));
        }
        const result = await response.blob();
        const blob = new Blob([result], {
          type: result.type,
        });
        const newUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = newUrl;
        a.download = realFileName;
        document.body.appendChild(a);
        a.click();
      } catch (e) {
        console.log(e);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [fetchBackend]
  );

  return { isLoading, isError, downloadAsync };
};
