import { PagingLinkProps } from './types';
import { useCallback } from 'react';
import { usePagingStyles } from './usePagingStyles';

export const PagingLink = (props: React.PropsWithChildren<PagingLinkProps>) => {
  const classes = usePagingStyles();
  const { page, currentPage, children, disabled = false, onPageChanged } = props;
  const active = page === currentPage;

  const className = disabled
    ? `${classes.paginationLink} ${classes.disabled}`
    : !children && active
    ? ` ${classes.active} ${classes.paginationLink}`
    : `${classes.paginationLink}`;

  const onClick = useCallback(() => {
    onPageChanged(page);
  }, [page, onPageChanged]);

  const onClickHandler = !disabled ? onClick : undefined;

  return (
    <button className={className} type="button" onClick={onClickHandler}>
      {children ? children : <>{page}</>}
    </button>
  );
};
