import { useCallback, useState } from 'react';
import { SortOrder, TelematicsDictionaryItemSortBy } from 'schema/serverTypes';
import { useTelematicsDictionaryQuery } from '../../../services/api/useTelematicsDictionaryBackend';

export type TelematicsSorting = Pick<ReturnType<typeof useTelematicsData>, 'sorting'>['sorting'];

export const useTelematicsData = () => {
  const [sortBy, setSortBy] = useState<TelematicsDictionaryItemSortBy>(
    TelematicsDictionaryItemSortBy.InstallationCost
  );
  const [order, setOrder] = useState(SortOrder.desc);

  const handleSortBy = useCallback(
    (sortBy: TelematicsDictionaryItemSortBy) => {
      setSortBy(sortBy);
    },
    [setSortBy]
  );

  const handleSortOrder = useCallback(
    (order: SortOrder) => {
      setOrder(order);
    },
    [setOrder]
  );

  const { data, isLoading } = useTelematicsDictionaryQuery({
    sortBy,
    order,
  });

  const rows = data ?? [];

  return {
    sorting: {
      sortBy,
      order,
      setSortBy: handleSortBy,
      setOrder: handleSortOrder,
    },
    rows,
    isLoading,
  };
};
