import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    container: {
      display: 'table',
      padding: theme.spacing(0.5, 1),
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.bgGray.main,
      marginBottom: theme.spacing(1.5),
    },
  })
);

export const Item = ({ task, index }: { task: any; index: number }) => {
  const classes = useStyles();
  return (
    <Draggable draggableId={task.id} index={index}>
      {(provided: any, snapshot: any) => (
        <div
          className={classes.container}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{ ...provided.draggableProps.style, position: 'static' }}
          ref={provided.innerRef}
        >
          {task.content}
        </div>
      )}
    </Draggable>
  );
};
