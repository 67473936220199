import Autocomplete from '@material-ui/lab/Autocomplete';
import { useLeasingProductQuery } from './useLeasingProductQuery';
import { useAutocompleteFieldProps } from '../useAutocompleteFieldProps';

export const LeaseProductAutocomplete = (props: any) => {
  const { resetInputValue, setInputValue, ...rest } = useAutocompleteFieldProps(props);
  const { options } = useLeasingProductQuery();

  return <Autocomplete<string | undefined, false, false, false> {...rest} options={options} />;
};
