import { createStyles, makeStyles, TableCell, TableRow, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { ApplicationUserTab, MissingHistoryCountLabel } from 'schema/serverTypes';
import { Link } from 'react-router-dom';
import { User } from '../../users';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 4,
      position: 'relative',
      '& td': {
        borderRadius: 4,
        background: theme.palette.background.default,
      },
      '&:first-child td': {
        background: theme.palette.primary.light,
      },
      '&& td a': {
        color: theme.palette.text.primary,
      },
      '&:first-child td a': {
        color: theme.palette.primary.main,
      },
    },
    container: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'start',
      padding: 0,
      background: 'transparent',
      textDecoration: 'none',
      '&&': {
        padding: 0,
      },
    },
    name: {
      fontSize: 12,
      padding: theme.spacing(0.75),
      marginRight: theme.spacing(2),
      '&&': {
        padding: theme.spacing(0.75),
      },
    },
    amount: {
      width: 88,
      fontSize: 12,
      fontWeight: 700,
      textAlign: 'right',
      padding: theme.spacing(0.75),
      '&&': {
        padding: theme.spacing(0.75),
      },
    },
  })
);

export type MissingItemProps = MissingHistoryCountLabel & { currentFilter: string };

export const MissingItem = (props: MissingItemProps) => {
  const classes = useStyles(props);
  const { text, isActive, count, currentFilter, inn, email } = props;
  const linkParam =
    currentFilter === 'documents'
      ? `documentType=${text}`
      : currentFilter === 'managers'
      ? `managerName=${text}&managerEmail=${email}`
      : currentFilter === 'counterparties'
      ? `counterpartyName=${text}&counterpartyInn=${inn}`
      : null;

  return (
    <TableRow className={clsx(classes.root)}>
      <TableCell className={classes.container}>
        <Link
          to={`/missing?${linkParam}&tabId=${ApplicationUserTab.all}`}
          className={classes.container}
        >
          <div className={classes.name}>
            {email ? <User name={text} isActive={isActive} /> : text}
          </div>
          <div className={classes.amount}>{count}</div>
        </Link>
      </TableCell>
    </TableRow>
  );
};
