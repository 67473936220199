import { useCallback, useContext } from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { AmountType } from 'schema';
import { AmountTypeContext } from './types';
import { getValueWithPrecision, withPercentsLimit, withValueLimit } from './utils';

export const NumberFormatCustom = (props: any) => {
  const {
    inputRef,
    onChange,
    value: inputValue,
    allowNegative = false,
    zeroOnEmptyString = false,
    ...other
  } = props;
  const { name } = props;

  const { amountType, fractionDigits = 2 } = useContext(AmountTypeContext);
  const value = getValueWithPrecision(inputValue, fractionDigits);

  const handleOnValueChange = useCallback(
    (values: NumberFormatValues) => {
      const { floatValue } = values;

      if (floatValue === undefined || floatValue === 0) {
        onChange({
          target: {
            name: name,
            value: 0,
          },
        });
        return;
      }

      const newValue = Number.parseFloat(floatValue.toFixed(fractionDigits));

      onChange({
        target: {
          name: name,
          value: newValue,
        },
      });
    },
    [onChange, name, fractionDigits]
  );

  const withLimit = useCallback(
    (values: NumberFormatValues) => {
      if (values.formattedValue) {
        var index = values.formattedValue.indexOf(',');
        if (index > -1) {
          var decimalPart = values.formattedValue.substring(index);
          if (decimalPart.length - 1 > fractionDigits) {
            return false;
          }
        }
      }
      if (amountType === AmountType.Percents) {
        return withPercentsLimit(values);
      }
      return withValueLimit(values);
    },
    [amountType, fractionDigits]
  );

  const value1 = !zeroOnEmptyString && value === 0 ? '' : value;

  return (
    <NumberFormat
      {...other}
      value={value1}
      allowNegative={allowNegative}
      isAllowed={withLimit}
      getInputRef={inputRef}
      onValueChange={handleOnValueChange}
      isNumericString={false}
      fixedDecimalScale={true}
      decimalSeparator={','}
      thousandSeparator={' '}
    />
  );
};
