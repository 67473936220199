import { TemplatePrintViewModel } from 'schema/serverTypes';
import { UseTemplatesQueryProps, useTemplatesQuery, useCurrentUserTabsQuery } from 'services';
import {
  Button,
  createStyles,
  Grow,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Theme,
} from '@material-ui/core';
import { useRef, useState } from 'react';
import { IconSprite } from '../../icons';
import { ModalForm, useModalForm, TemplateDataFilterForm } from 'components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    templatesButton: {
      marginLeft: theme.spacing(1),
      minWidth: 'auto',
      padding: '7px',
      boxShadow: 'none',
      backgroundColor: 'transparent',
      border: `1px solid ${theme.palette.secondary.main}`,
      '&:hover': {
        backgroundColor: theme.palette.background.light,
        '& svg': {
          fill: theme.palette.secondary.dark,
        },
      },
      '&:active:hover': {
        backgroundColor: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        boxShadow: 'none',
        '& svg': {
          fill: 'white',
        },
      },
      '& .MuiButton-endIcon': {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    popper: {
      zIndex: 1,
    },
    menuList: {
      '& li': {
        fontFamily: 'Roboto, Helvetica, Arial, san-serif',
        '&:hover': {
          backgroundColor: theme.palette.secondary.light,
        },
      },
    },
  })
);

export type PrintableTemplateListProps = UseTemplatesQueryProps & {
  expanded?: boolean;
};

export type PrintableTemplateProps = {
  template: TemplatePrintViewModel;
  closePopper: () => void;
};

const TemplateItem = ({ template, closePopper }: PrintableTemplateProps) => {
  const { onOpen, onClose, open } = useModalForm();

  const { data: tabs = [] } = useCurrentUserTabsQuery();

  return (
    <>
      <MenuItem onClick={onOpen}>{template.title}</MenuItem>
      <ModalForm open={open} onClose={onClose} width={500}>
        <TemplateDataFilterForm
          onClose={onClose}
          closePopper={closePopper}
          userTabs={tabs}
          template={template}
        />
      </ModalForm>
    </>
  );
};

export const DownloadTemplateList = (props: PrintableTemplateListProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { data: templates = [] } = useTemplatesQuery(props);

  return (
    <>
      <Button
        ref={anchorRef}
        endIcon={<IconSprite icon={'download'} width={'10px'} height={'10px'} />}
        className={classes.templatesButton}
        onClick={handleToggle}
      />
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-end"
        transition
        disablePortal
        className={classes.popper}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              {/*<ClickAwayListener onClickAway={handleClose as any}>*/}
              <MenuList
                autoFocusItem={open}
                id="composition-menu"
                aria-labelledby="composition-button"
                className={classes.menuList}
              >
                {templates.map((template) => {
                  return (
                    <TemplateItem key={template.id} template={template} closePopper={handleClose} />
                  );
                })}
              </MenuList>
              {/*</ClickAwayListener>*/}
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
