import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteChangeDetails,
} from '@material-ui/lab/useAutocomplete';
import { Autocomplete } from 'components';
import { UserViewModel } from 'schema/serverTypes';
import { useUsersBackendQuery } from 'services/api';
import { UserListResult } from 'components/users/types';
import { useController } from 'react-hook-form';

const getOptionLabel = (option: UserViewModel | null) => {
  if (option === null) {
    return '';
  }
  return option.name;
};

const getOptionSelected = (option: UserViewModel | null, value: UserViewModel | null) => {
  if (option === null || value === null) {
    return false;
  }
  return option.id === value.id;
};

const useSearchParams = (search?: string) => {
  return useMemo(() => {
    const searchParams = new URLSearchParams();
    searchParams.set('page', '1');
    searchParams.set('pageSize', '20');

    if (search && search !== '') {
      searchParams.set('search', search);
    }

    return searchParams;
  }, [search]);
};

export type SearchFilterFormValues = {
  user?: UserViewModel[];
};

export type UserAutocompleteProps = {
  control: any;
};

export const UserAutocomplete = (props: UserAutocompleteProps) => {
  const { control } = props;
  const { t } = useTranslation();

  const {
    field: { value: users = null, onChange },
  } = useController({
    control,
    name: 'userId',
  });

  const [user, setUser] = useState('');
  const [input] = useDebounce(user, 300);
  const searchParams = useSearchParams(input);
  const { data } = useUsersBackendQuery<UserListResult>({
    searchParams,
    queryKey: ['userId', input],
  });

  const options: UserViewModel[] =
    data?.data.filter((t) => {
      if (users === undefined) {
        return true;
      }
      const usersById = users?.find((m: any) => m.id === t.id);
      return usersById === undefined;
    }) ?? [];

  const handleOnInputChange = useCallback(
    (_ev: React.ChangeEvent<{}>, value: string, _reason: AutocompleteInputChangeReason) => {
      setUser(value);
    },
    [setUser]
  );

  const handleOnChange = useCallback(
    (
      _e: React.ChangeEvent<{}>,
      value: UserViewModel | null,
      _reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<UserViewModel | null>
    ) => {
      if (value === null) {
        onChange([]);
        setUser('');
      } else {
        onChange(users ? [...users.filter((v: any) => v.id !== value.id), value] : [value]);
        setUser('');
      }
    },
    [onChange, users]
  );

  return (
    <Autocomplete<UserViewModel | null>
      label={`${t('Manager')}`}
      options={options}
      inputValue={user}
      onInputChange={handleOnInputChange}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      onChange={handleOnChange}
      variant="standard"
      value={null}
    />
  );
};
