import clsx from 'clsx';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useCounterpartyMissingsQuery } from '../../services';
import { useTranslation } from 'react-i18next';
import { formatMoney } from '../utils';
import { Currency } from '../../schema';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    sep: {
      color: theme.palette.secondary.main,
      margin: theme.spacing(0, 0.5),
    },
    value: {
      fontSize: 12,
      textTransform: 'lowercase',
      display: 'inline-block',
      color: theme.palette.common.black,
      '&:first-letter': {
        textTransform: 'uppercase',
      },
    },
    orange: {
      color: theme.palette.warning.main,
    },
    green: {
      color: theme.palette.success.main,
    },
    red: {
      color: theme.palette.error.main,
    },
  })
);

type IssueMissingProps = {
  inn: string;
  counterpartyName: string;
};

export const IssueMissing = (props: IssueMissingProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { inn, counterpartyName } = props;
  const { data } = useCounterpartyMissingsQuery(inn);

  const isMissing = data?.missingsCount && data?.missingsCount > 0;
  const missingClass = isMissing
    ? clsx(classes.value, classes.red)
    : clsx(classes.value, classes.green);
  const isOverdue = data?.overdueAmount && data?.overdueAmount > 0;

  let overdueClass = '';
  switch (true) {
    case data?.debtDepth && data?.debtDepth > 90:
      overdueClass = clsx(classes.value, classes.red);
      break;
    case data?.debtDepth && data?.debtDepth > 30:
      overdueClass = clsx(classes.value, classes.orange);
      break;
    case data?.debtDepth && data?.debtDepth > 0:
      overdueClass = clsx(classes.value);
      break;
    default:
      overdueClass = clsx(classes.value, classes.green);
  }

  return (
    <>
      <span className={classes.sep}>|</span>
      <Link
        to={`/missing?counterpartyInn=${inn}&counterpartyName=${counterpartyName}#all`}
        className={missingClass}
        target={'_blank'}
      >
        {t('Missing')} {isMissing ? data?.missingsCount : t('Empty')}
      </Link>
      <span className={classes.sep}>|</span>
      <Link
        to={`/overdue?counterpartyInn=${inn}&counterpartyName=${counterpartyName}#all`}
        className={overdueClass}
        target={'_blank'}
      >
        {t('Overdue')}{' '}
        {isOverdue
          ? `${data?.debtDepth} ${t('day')} ${formatMoney({
              amount: data?.overdueAmount,
              currency: Currency.Ruble,
            })} `
          : t('Empty')}
      </Link>
    </>
  );
};
