import { useCallback, useMemo } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { CalculationMethod, MonthPaymentOption, SeasonalPaymentType } from 'schema/serverTypes';
import { getNumber } from 'components/calculator/utils';
import { MenuItem } from '@material-ui/core';
import { Grid } from 'components/Grid';
import { SelectField } from 'components/form/SelectField';
import { useRequired } from 'components/form';
import { DateField } from './DateField';
import { SwitchField } from 'components';
import { PaymentOptionList } from './PaymentOptionList';
import { useTranslation } from 'react-i18next';
import { OnMonthNumberChanged } from './OnMonthNumberChanged';
import { OnHasCyclicityChanged } from './OnHasCyclicityChanged';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: '100%',
    },
    hidden: {
      display: 'none',
    },
    visible: {
      display: 'block',
    },
    payment: {
      marginTop: theme.spacing(1),
    },
  })
);

type PaymentScheduleProps = {
  numberOfMonths: number;
  calculationMethod: CalculationMethod;
  date?: string;
};

const PaymentSchedule = (props: PaymentScheduleProps) => {
  const classes = useStyles();
  const { calculationMethod, date, numberOfMonths } = props;

  const rootClassName =
    calculationMethod === CalculationMethod.Seasonal ||
    calculationMethod === CalculationMethod.SuperSeasonal
      ? classes.visible
      : classes.hidden;

  const { minDate, maxDate } = useMemo(() => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const actualDate =
      date !== undefined && date !== '' ? new Date(date) : new Date(year, month, 1);
    const minDate = actualDate < now ? actualDate : now;
    const maxDate = new Date(minDate.getFullYear() + 1, minDate.getMonth(), minDate.getDate());
    return {
      minDate,
      maxDate,
    };
  }, [date]);

  const useLastPayment = (numberOfMonths: number) => {
    const { t } = useTranslation();
    const invalidLastPaymentMessage = t('Last billing month');
    const lastPayment = useCallback(
      (value: any) => {
        const startMonth = dayjs(value).month();
        if (value === undefined || value === null) {
          return invalidLastPaymentMessage;
        }
        const lastPaymentMonth = (startMonth + numberOfMonths) % 12;
        if (typeof value === 'string' && lastPaymentMonth < 8) {
          dayjs.locale('ru');
          return (
            invalidLastPaymentMessage + ' ' + dayjs(dayjs().month(lastPaymentMonth)).format('MMMM')
          );
        }
      },
      [invalidLastPaymentMessage, numberOfMonths]
    );

    return { lastPayment };
  };

  const { t } = useTranslation();
  const { required } = useRequired();
  const { lastPayment } = useLastPayment(numberOfMonths);

  return (
    <Accordion defaultExpanded={true} className={rootClassName}>
      <AccordionSummary aria-controls="panel4a-content" id="panel4a-header">
        <Typography variant="subtitle1">{t('PaymentSchedule')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className={classes.wrapper}>
          <Grid container columnSpacing={2} rowSpacing={2.5}>
            <Grid item xl={4} lg={6} md={8} xs={24}>
              <Field
                name="seasonalPaymentOptions.paymentType"
                label={t('SeasonalPaymentType')}
                component={SelectField}
                validate={
                  calculationMethod === CalculationMethod.Seasonal ||
                  calculationMethod === CalculationMethod.SuperSeasonal
                    ? required
                    : undefined
                }
              >
                <MenuItem value={SeasonalPaymentType.MonthlyPayment}>
                  {t('ToMonthlyPayments')}
                </MenuItem>
                <MenuItem value={SeasonalPaymentType.MainDebt}>{t('ToMainDebt')}</MenuItem>
              </Field>
            </Grid>
            <Grid item xl={3} lg={4} md={5} xs={24}>
              <Field
                name="seasonalPaymentOptions.date"
                label={t('StartDate')}
                component={DateField}
                minDate={minDate}
                maxDate={maxDate}
                validate={
                  calculationMethod === CalculationMethod.SuperSeasonal ? lastPayment : undefined
                }
              />
            </Grid>
            <Grid item md="auto" xs={24}>
              <Field
                name="seasonalPaymentOptions.hasCyclicity"
                label={t('Cyclicity')}
                type="checkbox"
                component={SwitchField}
              />
            </Grid>
          </Grid>
          <FieldArray<MonthPaymentOption> name="paymentOptions">
            {({ fields }) => {
              const { push, pop, value = [], update } = fields;
              const valuesCount = value.length;
              return (
                <>
                  <Field
                    name="numberOfMonths"
                    type="number"
                    valuesCount={valuesCount}
                    push={push}
                    pop={pop}
                    component={OnMonthNumberChanged}
                  />
                  <Field
                    name="seasonalPaymentOptions.hasCyclicity"
                    update={update}
                    options={value}
                    component={OnHasCyclicityChanged}
                  />
                </>
              );
            }}
          </FieldArray>
          <FieldArray<MonthPaymentOption> name="paymentOptions">
            {(arrayProps) => {
              return (
                <PaymentOptionList
                  {...arrayProps}
                  calculationMethod={calculationMethod}
                  numberOfMonths={numberOfMonths}
                  date={date}
                />
              );
            }}
          </FieldArray>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export const PaymentScheduleAccordion = () => {
  return (
    <Field name="calculationMethod">
      {({ input: calculationMethodInput }) => {
        const calculationMethod = calculationMethodInput.value as CalculationMethod;
        return (
          <Field name="numberOfMonths">
            {({ input: numberOfMonthsInput }) => {
              const numberOfMonths = getNumber(numberOfMonthsInput.value);
              return (
                <Field name="seasonalPaymentOptions.date">
                  {({ input: dateInput }) => {
                    const date = dateInput.value as string | undefined;
                    return (
                      <PaymentSchedule
                        numberOfMonths={numberOfMonths}
                        calculationMethod={calculationMethod}
                        date={date}
                      />
                    );
                  }}
                </Field>
              );
            }}
          </Field>
        );
      }}
    </Field>
  );
};
