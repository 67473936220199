import React, { useCallback, useState } from 'react';
import { Grid } from '@material-ui/core';
import { SalesPointsTable } from './SalesPointsTable';
import { Breadcrumbs, Pagination } from 'components';
import { useSalesPointsData } from './useSalesPointsData';
import { ModalForm, useModalForm } from 'components';
import { AddButton } from '../AddButton';
import { SalesPointForm } from './SalesPointForm';
import { SalesPoint } from 'schema/serverTypes';
import { useTranslation } from 'react-i18next';
import { SearchFilterForm } from './SearchFilterForm';
import { PageTitle } from 'components/utils/pageTitle';

export const SalesPointsDataGrid = () => {
  const { t } = useTranslation();
  const { filter, paging, ...dataProps } = useSalesPointsData();
  const { onOpen, onClose, open } = useModalForm();

  const [item, setItem] = useState<SalesPoint>();

  const pageTitle = t('Sales points') + ' - Bumblebee';
  PageTitle(pageTitle);

  const handleOnOpen = useCallback(
    (item: SalesPoint) => {
      setItem(item);
      onOpen();
    },
    [onOpen]
  );

  const handleOnClose = useCallback(() => {
    setItem(undefined);
    onClose();
  }, [onClose]);

  return (
    <>
      <Breadcrumbs link={'/dictionaries'} text={t('Section.dictionaries')} />
      <Grid container spacing={1} direction="column">
        <Grid container item justifyContent="space-between" alignItems="center">
          <Grid item>
            <SearchFilterForm {...filter} />
          </Grid>
          <Grid item>
            <AddButton onClick={onOpen} />
          </Grid>
        </Grid>
        <Grid container item direction="column">
          <Grid item>
            <SalesPointsTable {...dataProps} onOpen={handleOnOpen} />
          </Grid>
          <Grid item>
            <Pagination {...paging} />
          </Grid>
        </Grid>
      </Grid>
      <ModalForm open={open} onClose={handleOnClose}>
        <SalesPointForm onSuccess={handleOnClose} item={item} />
      </ModalForm>
    </>
  );
};
