import { useDebounce } from 'use-debounce';
import { useDictionaryBackendQuery } from 'services';
import { useMemo } from 'react';
import { LeasingProductItemViewModel } from '../../../schema';

export const useLeasingProductQuery = (inputValue?: string, value?: string) => {
  const [input] = useDebounce(inputValue, 300);

  let requestUrl = useMemo(() => {
    let url = 'leasingProducts';
    const searchParams = new URLSearchParams();

    if (input) {
      searchParams.set('name', input);
    }
    if (value) {
      searchParams.set('id', value);
    }
    url += `?${searchParams}`;

    return url;
  }, [input, value]);

  return useDictionaryBackendQuery<LeasingProductItemViewModel[]>(requestUrl);
};
