import { useCallback, useMemo, useState } from 'react';
import { SortOrder } from 'schema/serverTypes';

export type UseSortingProps<SortBy> = {
  sortBy: SortBy;
  order?: SortOrder;
};

export function useSorting<SortBy>(props: UseSortingProps<SortBy>) {
  const defaultSortBy = props.sortBy;
  const defaultOrder = props.order ?? SortOrder.asc;

  const [sortBy, setSortBy] = useState<SortBy>(defaultSortBy);
  const [order, setOrder] = useState<SortOrder>(defaultOrder);

  const handleSetSortBy = useCallback(
    (sortBy: SortBy) => {
      setSortBy(sortBy);
    },
    [setSortBy]
  );

  const handleSetOrder = useCallback(
    (order: SortOrder) => {
      setOrder(order);
    },
    [setOrder]
  );

  return useMemo(() => {
    return {
      sortBy,
      order,
      setSortBy: handleSetSortBy,
      setOrder: handleSetOrder,
    };
  }, [sortBy, order, handleSetSortBy, handleSetOrder]);
}
