import { Switch as MuiSwitch, Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      height: '100%',
      minHeight: 37,
      alignItems: 'center',
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '10px',
    },
    nowrap: {
      whiteSpace: 'nowrap',
    },
    content: {
      paddingTop: theme.spacing(1),
    },
    valueWrapper: {
      position: 'relative',
    },
    val: {
      fontFamily: 'Roboto, Helvetica, Arial, san-serif',
      position: 'absolute',
      left: 0,
    },
    hidden: {
      //hidden span hack for dynamic width
      display: 'block',
      height: '14px',
      overflow: 'hidden',
      left: 0,
      opacity: 0,
    },
    switchRoot: {
      height: 20,
      width: 48,
      padding: '0 8px',
    },
    switchBase: {
      padding: '0 8px',
      '&.Mui-checked': {
        transform: 'translateX(14px)',
        '&+ .MuiSwitch-track': {
          backgroundColor: theme.palette.primary.main,
          border: `1px solid ${theme.palette.primary.main}`,
          opacity: 1,
        },
        '& .MuiSwitch-thumb': {
          width: 15,
          height: 15,
          color: theme.palette.background.paper,
          margin: 2,
        },
      },
    },
    switchTrack: {
      backgroundColor: theme.palette.background.dark,
      borderRadius: 62,
      border: `1px solid ${theme.palette.text.primary}`,
      opacity: 1,
    },
    switchThumb: {
      color: theme.palette.text.primary,
      width: 10,
      height: 10,
      margin: 5,
    },
  })
);

type SwitchProps = {
  label?: string;
  disabled?: boolean;
  toggleSwitch: () => void;
};

export const SwitchWithoutForm = (props: SwitchProps) => {
  const classes = useStyles();
  const { disabled, toggleSwitch, label = '' } = props;

  return (
    <div className={classes?.root}>
      <Box>
        <Typography component={'span'}>{label}</Typography>
        <MuiSwitch
          checked={!disabled}
          onChange={toggleSwitch}
          color={'primary'}
          classes={{
            root: classes?.switchRoot,
            switchBase: classes?.switchBase,
            track: classes?.switchTrack,
            thumb: classes?.switchThumb,
          }}
        />
      </Box>
    </div>
  );
};
