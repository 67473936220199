import { Autocomplete } from 'components';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import { ContractStatusesRequest } from 'schema/serverTypes';
import { useContractStatusesRequest } from 'services/api';
import { ContractFilterFormProps } from './types';
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteChangeDetails,
} from '@material-ui/lab/useAutocomplete';
import { useController } from 'react-hook-form';

const useOptionLabel = (emptyLabel: string) => {
  return useCallback(
    (option: string) => {
      if (option === '') {
        return emptyLabel;
      }
      return option ?? emptyLabel;
    },
    [emptyLabel]
  );
};

const getOptionSelected = (option: string, value: string) => {
  return option === value;
};

export type ContractStatusAutocompleteProps = Pick<ContractFilterFormProps, 'control'> & {
  count?: number;
};

export const ContractStatusAutocomplete = (props: ContractStatusAutocompleteProps) => {
  const { control, count = 20 } = props;

  const { t } = useTranslation();
  const notSet = t('NotSet');

  const {
    field: { onChange, value: statuses },
  } = useController({
    control,
    name: 'status',
    defaultValue: [],
  });

  const [status, setStatus] = useState('');
  const [search] = useDebounce(status, 500);

  const request = useMemo<ContractStatusesRequest>(() => {
    return {
      search,
      count,
    };
  }, [search, count]);

  const { data: options = [], isLoading } = useContractStatusesRequest(request);

  const handleOnInputChange = useCallback(
    (_ev: React.ChangeEvent<{}>, value: string, _reason: AutocompleteInputChangeReason) => {
      setStatus(value);
    },
    [setStatus]
  );

  const handleOnChange = useCallback(
    (
      _e: React.ChangeEvent<{}>,
      value: string | null,
      _reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<string | null>
    ) => {
      if (value === null) {
        onChange([]);
        setStatus('');
      } else {
        onChange(statuses ? [...statuses.filter((v) => v !== value), value] : [value]);
        setStatus('');
      }
    },
    [onChange, statuses]
  );

  const getOptionLabel = useOptionLabel(notSet);

  return (
    <Autocomplete<string>
      label={t('Status')}
      options={options}
      inputValue={status}
      onInputChange={handleOnInputChange}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      onChange={handleOnChange}
      value={null}
      loading={isLoading}
      variant="standard"
    />
  );
};
