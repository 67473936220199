import { Box, Grid } from '@material-ui/core';
import { Paging } from 'components';
import { useCounterpartiesData } from './useCounterpartiesData';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CounterpartiesFilterForm } from './CounterpartiesFilterForm';
import { CounterpartiesTable } from './CounterpartiesTable';
import { Button } from 'components';
import { PageTitle } from 'components/utils/pageTitle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ok: {
      backgroundColor: theme.palette.green.main,
      width: '10px',
      height: '10px',
      borderRadius: '50px',
    },
    error: {
      backgroundColor: theme.palette.chartPurple.main,
      width: '10px',
      height: '10px',
      borderRadius: '50px',
    },
    wrapIndicators: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(2),
    },
    indicator: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: theme.spacing(4),
    },
    indicatorContent: {
      marginLeft: theme.spacing(1),
    },
    filter: {
      display: 'inline-block',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
      alignItems: 'flex-end',
    },
    actions: {
      whiteSpace: 'nowrap',
      '& a + a': {
        marginLeft: theme.spacing(1),
      },
    },
  })
);

export const CounterpartiesDataGrid = () => {
  const { filter, paging, ...dataProps } = useCounterpartiesData();
  const { t } = useTranslation();
  const classes = useStyles();

  const pageTitle = t('Section.counterparties') + ' - Bumblebee';
  PageTitle(pageTitle);

  return (
    <Grid container direction="column">
      <Box mb={2.5} className={classes.filter}>
        <CounterpartiesFilterForm {...filter} />
        <div className={classes.actions}>
          <Button variant="contained" to="/counterparties/create/legal">
            {t('Buttons.AddLegalCounterparty')}
          </Button>
          <Button variant="contained" to="/counterparties/create/individual">
            {t('Buttons.AddIndividualCounterparty')}
          </Button>
        </div>
      </Box>
      <Grid container item direction="column">
        <Grid item>
          <CounterpartiesTable {...dataProps} />
        </Grid>
        <Grid item>
          <Paging {...paging} />
        </Grid>
      </Grid>
    </Grid>
  );
};
