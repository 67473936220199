import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { QuotaPayment } from 'schema';
import { formatNumber } from 'components/utils';
import dayjs from 'dayjs';
import { useLanguage } from '../../../utils/useLanguage';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridGap: theme.spacing(2),
      fontSize: 14,
    },
    year: {
      fontFamily: "'Halvar Breit', sans-serif",
      fontSize: 12,
      fontWeight: 700,
      marginBottom: 0.5,
      color: theme.palette.secondary.dark,
      textAlign: 'center',
      lineHeight: '22px',
    },
    month: {
      padding: theme.spacing(0.5, 1),
      display: 'grid',
      gridGap: theme.spacing(1),
      lineHeight: '24px',
      gridTemplateColumns: '24px 32px 1fr',
      '&:nth-child(2N+1)': {
        backgroundColor: theme.palette.background.default,
      },
    },
    number: {
      textAlign: 'center',
    },
    total: {
      textAlign: 'right',
    },
    empty: {
      opacity: '50%',
    },
  })
);

export type SeasonalPaymentScheduleProps = {
  numberOfMonths: number;
  payments: QuotaPayment[];
};

const generateYearArray = (startDate: Date, numMonths: number) => {
  const dateArray = [];
  let currentDate = new Date(startDate);
  const endYear =
    currentDate.getFullYear() + Math.floor((currentDate.getMonth() + numMonths - 1) / 12);

  while (currentDate.getFullYear() <= endYear) {
    const year = currentDate.getFullYear();
    dateArray.push(year);
    currentDate.setFullYear(currentDate.getFullYear() + 1);
  }

  return dateArray;
};

export const SeasonalPaymentSchedule = (props: SeasonalPaymentScheduleProps) => {
  const classes = useStyles();
  const { payments } = props;
  const lang = useLanguage();

  const startDate = new Date(payments[0].date as string);
  const numMonths = payments.length;
  const yearArray = generateYearArray(startDate, numMonths);

  type MonthlyPayments = Record<string, QuotaPayment[]>;
  const monthlyPayments: MonthlyPayments = {};

  payments.forEach((payment: QuotaPayment) => {
    const monthYearKey = dayjs(payment.date).format('YYYY-MM');
    if (!monthlyPayments[monthYearKey]) {
      monthlyPayments[monthYearKey] = [];
    }
    monthlyPayments[monthYearKey].push(payment);
  });

  const renderMonthPayments = (year: number, monthIndex: number) => {
    const date = dayjs(`${year}-${monthIndex + 1}-01`);
    const monthYearKey = date.format('YYYY-MM');
    const paymentsForMonth = monthlyPayments[monthYearKey] || [];

    if (paymentsForMonth.length > 0) {
      return paymentsForMonth.map((payment: QuotaPayment) => (
        <div key={payment.date} className={classes.month}>
          <div className={classes.number}>{payment.number}</div>
          <div>{dayjs(payment.date).locale(lang).format('MMM')}</div>
          <div className={classes.total}>
            {payment.total > 0 ? formatNumber(payment.total) : '—'}
          </div>
        </div>
      ));
    }

    return (
      <div key={`${year}-${monthIndex}`} className={clsx(classes.month, classes.empty)}>
        <div className={classes.number}>—</div>
        <div>{date.locale(lang).format('MMM')}</div>
        <div className={classes.total}>—</div>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      {yearArray.map((year) => (
        <div key={year}>
          <div className={classes.year}>{year}</div>
          {Array.from({ length: 12 }).map((_, i) => renderMonthPayments(year, i))}
        </div>
      ))}
    </div>
  );
};
