import { useMemo, useState } from 'react';
import { MissingFilterContext } from './MissingFilterContext';
import { ApplicationUserTab } from 'schema';

export type MissingFilterContextProviderProps = {
  children: React.ReactNode;
};

export const MissingFilterContextProvider = (props: MissingFilterContextProviderProps) => {
  const { children } = props;
  const [documentType, setDocumentTypes] = useState<string[]>([]);
  const [managerId, setManagerIds] = useState<string[]>([]);
  const [tabId, setTabId] = useState<number>(ApplicationUserTab.mine);

  const value = useMemo(() => {
    return {
      documentType,
      managerId,
      tabId,
      setDocumentTypes,
      setManagerIds,
      setTabId,
    };
  }, [documentType, setDocumentTypes, managerId, setManagerIds, tabId, setTabId]);

  return <MissingFilterContext.Provider value={value}>{children}</MissingFilterContext.Provider>;
};
