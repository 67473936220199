import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Grid, IconSprite } from 'components';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { themeOrange as theme } from 'theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 400,
      paddingLeft: theme.spacing(1),
      flex: '0 0 auto',
      marginTop: 290,
      '& div div': {
        height: '52.63px',
        borderBottom: '1px solid #E3E6F3',
        display: 'flex',
        alignItems: 'center',
      },
      '& div $optional': {
        height: '30px',
        lineHeight: '30px',
        gridColumn: ' auto / span 24',
        borderBottom: 'none',
      },
    },
    spacer: {
      marginBottom: theme.spacing(6.5),
    },
    optional: {
      marginTop: theme.spacing(3.25),
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
      backgroundColor: theme.palette.secondary.light,
      padding: theme.spacing(0, 1.5),
      cursor: 'pointer',
      fontSize: 12,
      display: 'flex',
      justifyContent: 'space-between',
    },
    show: {},
    hide: {
      transform: 'rotate(180deg)',
    },
  })
);

type RatingLabelsProps = {
  optional: boolean;
  setOptional: Dispatch<SetStateAction<boolean>>;
};

export const RatingLabels = ({ setOptional, optional }: RatingLabelsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handlerOptional = useCallback(
    () => setOptional && setOptional(!optional),
    [setOptional, optional]
  );

  const optionIconClass = optional ? classes.show : classes.hide;

  return (
    <div className={classes.root}>
      <Grid container rowSpacing={0}>
        <Grid item xs={24}>
          {t('Assets total')}
        </Grid>
        <Grid item xs={24}>
          Чистые нематериальные активы
        </Grid>
        <Grid item xs={24}>
          Внутригрупповые займы &gt; 1 года
        </Grid>
        <Grid item xs={24}>
          Внутригрупповые займы &lt; 1 года
        </Grid>
        <Grid item xs={24}>
          Внутригрупповая ДЗ &lt; 1 года
        </Grid>
        <Grid item xs={24}>
          Касса и денежные средства на счёте (без ценных бумаг)
        </Grid>
        <Grid item xs={24}>
          Всего денежных средств и инвестиций
        </Grid>
        <Grid item xs={24}>
          Капитал и резервы
        </Grid>
        <Grid item xs={24}>
          Долгосрочные обязательства
        </Grid>
        <Grid item xs={24}>
          Прочие долгосрочные обязательства
        </Grid>
        <Grid item xs={24}>
          Заёмные средства (краткосрочные)
        </Grid>
        <Grid item xs={24}>
          Прочие краткосрочные обязательства
        </Grid>
        <Grid item xs={24}>
          Часть субординированного долга к оплате в ближ. 12 мес.
        </Grid>
        <Grid item xs={24}>
          Денежные средства на счетах участников, как часть капитала
        </Grid>
        <Grid item xs={24}>
          Лизинговые обязательства
        </Grid>
        <Grid item xs={24}>
          Опер. Лизинг
        </Grid>
        <Grid item xs={24}>
          Выручка
        </Grid>
        <Grid item xs={24}>
          Прибыль (убыток) от продажи
        </Grid>
        <Grid item xs={24}>
          Устойчивая EBITDA
        </Grid>
        <Grid item xs={24}>
          Операционный результат деятельности, подлежащей прекращению
        </Grid>
        <Grid item xs={24}>
          Прибыль (убыток) до налогообложения
        </Grid>
        <Grid item xs={24}>
          Амортизация
        </Grid>
        <Grid item xs={24} className={classes.spacer}>
          Расходы по забалансовому лизингу в себестоимости
        </Grid>
        <Grid item xs={24}>
          Концентрация клиентов и/или поставщиков
        </Grid>
        <Grid item xs={24}>
          Как прогноз развития отрасли повлияет на компанию?
        </Grid>
        <Grid item xs={24}>
          По сравнению с конкурентами, как бы вы охарактеризовали деятельность компании в своем
          отраслевом сегменте?
        </Grid>
        <Grid item xs={24}>
          Как бы вы описали волатильность ключевого отраслевого сегмента клиентов?
        </Grid>
        <Grid item xs={24}>
          Как бы вы оценили качество управления?
        </Grid>
        <Grid item xs={24}>
          Имеются ли какие-либо предупредительные сигналы?
        </Grid>

        <div className={classes.optional} onClick={handlerOptional}>
          <span>Опционально</span>
          <IconSprite
            className={optionIconClass}
            icon="spoiler-dropdown"
            width={'16px'}
            height={'9px'}
            color={theme.palette.primary.main}
          />
        </div>
        {optional && (
          <>
            <Grid item xs={24}>
              Как компания управляет сырьевым, валютным и процентным рисками?
            </Grid>
            <Grid item xs={24}>
              Как компания работает в соответствии с планом?
            </Grid>
            <Grid item xs={24}>
              Есть ли у компании доступ к другим источникам финансирования?
            </Grid>
            <Grid item xs={24}>
              Зависит ли компания от одного или нескольких ключевых лиц?
            </Grid>
            <Grid item xs={24}>
              Как бы вы охарактеризовали рынок для отрасли, в которой работает клиент?
            </Grid>
            <Grid item xs={24}>
              Сколько лет компания является вашим клиентом?
            </Grid>
            <Grid item xs={24}>
              Насколько диверсифицирована деятельность компании в части рынка, регионов и продукции?
            </Grid>
            <Grid item xs={24}>
              Как бы вы охарактеризовали информационные системы компании в части своевременного
              предоставления качественной информации?
            </Grid>
            <Grid item xs={24}>
              Могут ли забалансовые обязательства оказать существенное влияние на финансовое
              положение компании?
            </Grid>
            <Grid item xs={24}>
              Каков уровень международных операций?
            </Grid>
            <Grid item xs={24}>
              Какой вид основной деятельности?
            </Grid>
            <Grid item xs={24}>
              Какой возраст компании?
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};
