import { useTranslation } from 'react-i18next';
import { AmountInput } from '../AmountInput';
import { useCalculationFormContext } from '../types';
import { useWatch } from 'react-hook-form';

export const PrepaymentAmountInput = () => {
  const { t } = useTranslation();
  const { control } = useCalculationFormContext();

  const leaseItemCost = useWatch({
    control,
    name: 'leaseItemCost',
  });

  const MAX = 49;

  return (
    <AmountInput
      name="prepayment"
      label={t('Prepayment')}
      required
      allowZero
      max={((leaseItemCost ?? 0) * MAX) / 100}
      maxPercents={MAX}
    />
  );
};
