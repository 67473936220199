import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IssuePageLink, IssueSource } from 'schema/serverTypes';

type IssueContext = {
  link: IssuePageLink;
  source: IssueSource;
  quotaId?: number;
};

export type ErrorContextProps = IssueContext & {
  setErrorContext: (value: IssueContext) => void;
};

export const ErrorContext = createContext<ErrorContextProps>({
  link: {
    url: '/',
    title: 'Home',
  },
  source: IssueSource.home,
  setErrorContext: (_v) => {},
});

export type ErrorContextProviderProps = {
  children: React.ReactNode;
};

export const ErrorContextProvider = (props: ErrorContextProviderProps) => {
  const [issue, setIssue] = useState<IssueContext>({
    link: { url: '/', title: 'Home' },
    source: IssueSource.home,
  });

  const setErrorContext = useCallback(
    (value: IssueContext) => {
      setIssue(value);
    },
    [setIssue]
  );

  const value = useMemo(() => {
    return {
      ...issue,
      setErrorContext,
    };
  }, [issue, setErrorContext]);

  return <ErrorContext.Provider value={value}>{props.children}</ErrorContext.Provider>;
};

export type UserErrorContextProps = {
  title: string;
  source: IssueSource;
  quotaId?: number;
};

export const useErrorContext = (props: UserErrorContextProps) => {
  const { setErrorContext } = useContext(ErrorContext);

  const { pathname: url } = useLocation();

  const { title, source, quotaId } = props;

  useEffect(() => {
    setErrorContext({
      link: {
        url,
        title,
      },
      source,
      quotaId,
    });
  }, [url, title, source, quotaId, setErrorContext]);
};
