import { TextFieldClassKey, StyleRules } from '@material-ui/core';
import palette from '../paletteOrange';

const style: Partial<StyleRules<TextFieldClassKey>> = {
  root: {
    '& label.Mui-focused': {
      color: palette.divider,
    },
    '& .MuiInputBase-root': {
      color: palette.text.primary,
      '&.Mui-disabled': {
        '& fieldset': {},
      },
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: palette.text.secondary,
      },
      '&:hover fieldset': {
        borderColor: palette.divider,
      },
      '&.Mui-focused fieldset': {
        borderColor: palette.divider,
      },
      '& .MuiOutlinedInput-input': {
        padding: '12px 10px 8px',
      },
    },
  },
};

export default style;
