import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ReactNode } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'block',
      padding: '56px 12px',
      backgroundColor: 'white',
      color: theme.palette.text.primary,
      fontSize: 12,
      textAlign: 'center',
      minHseight: '100%',
    },
  })
);

interface EmptyProps {
  children?: ReactNode;
}

export const Empty = ({ children }: EmptyProps) => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};
