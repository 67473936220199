import { useCallback } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { Chip } from 'components/index';
import { CounterpartyOption, TemplateDataFilter } from 'schema/serverTypes';
import { getOptionLabel } from '../../calculator/QuotaDataGrid/CounterpartyAutocomplete';

export type LesseeChipsProps = {
  control: Control<TemplateDataFilter, any>;
  setValue: UseFormSetValue<TemplateDataFilter>;
  lesseesOptions?: CounterpartyOption[];
};

export const LesseeChips = (props: LesseeChipsProps) => {
  const { control, setValue, lesseesOptions } = props;

  const counterparties = useWatch({ control, name: 'lessees' });

  const onRemove = useCallback(
    (id: string) => {
      const values = counterparties?.filter((v) => v !== id);
      setValue('lessees', values);
    },
    [counterparties, setValue]
  );

  const counterpartyChips = counterparties?.map((value) => {
    const counterparty = lesseesOptions?.find((t) => t.inn === value);

    if (counterparty === undefined) {
      return <Chip label={value} id={value} key={`counterparties-${value}`} onRemove={onRemove} />;
    }

    const label = getOptionLabel(counterparty);

    return <Chip label={label} id={value} key={`counterparties-${value}`} onRemove={onRemove} />;
  });

  return <>{counterpartyChips}</>;
};
