import { Control } from 'react-hook-form';
import { CounterpartyFilterFormValues } from '../types';
import { useTranslation } from 'react-i18next';
import { Select } from 'components/form/Select';
import { MenuItem } from '@material-ui/core';
import { CounterpartyStatus } from 'schema/serverTypes';

export type CounterpartyStatusProps = {
  control: Control<CounterpartyFilterFormValues, any>;
};

export const CounterpartyStatusAutocomplete = (props: CounterpartyStatusProps) => {
  const { control } = props;

  const { t } = useTranslation();

  return (
    <Select
      control={control}
      name="status"
      label={`${t('Organization status')}`}
      variant="standard"
    >
      <MenuItem value={CounterpartyStatus.Active}>{t('CounterpartyStatus.Active')}</MenuItem>
      <MenuItem value={CounterpartyStatus.NotActive}>{t('CounterpartyStatus.NotActive')}</MenuItem>
      <MenuItem value={CounterpartyStatus.Bankruptcy}>
        {t('CounterpartyStatus.Bankruptcy')}
      </MenuItem>
      <MenuItem value={CounterpartyStatus.Liquidation}>
        {t('CounterpartyStatus.Liquidation')}
      </MenuItem>
      <MenuItem value={CounterpartyStatus.Reorganization}>
        {t('CounterpartyStatus.Reorganization')}
      </MenuItem>
      <MenuItem value={CounterpartyStatus.Uknown}>{t('CounterpartyStatus.Uknown')}</MenuItem>
    </Select>
  );
};
