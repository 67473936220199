import { useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { useWatch } from 'react-hook-form';
import { usePaging } from 'components/paging/usePaging';
import { useSorting } from 'components/sorting/useSorting';
import { useUserTabs } from 'components/userTabs';
import {
  ApplicationUserTab,
  QuotaListFilter,
  QuotaListRequest,
  QuotaSortBy,
  QuotaStatus,
  SortOrder,
} from 'schema/serverTypes';
import { useQuotaFilter } from './useQuotaFilter';
import { useQuotasListQuery, useUserAuth } from 'services';
import { QuotaAction } from './types';
import { useLocation } from 'react-router-dom';

export const useQuotaList = () => {
  const { sortBy, order, setOrder, setSortBy } = useSorting<QuotaSortBy>({
    sortBy: QuotaSortBy.id,
    order: SortOrder.desc,
  });
  const filterParams = sessionStorage.quotaFilterValues
    ? JSON.parse(sessionStorage.quotaFilterValues)
    : {};

  const handleSortBy = useCallback(
    (sortBy: QuotaSortBy) => {
      setSortBy(sortBy);
    },
    [setSortBy]
  );

  const handleSortOrder = useCallback(
    (order: SortOrder) => {
      setOrder(order);
    },
    [setOrder]
  );

  const sorting = useMemo(() => {
    return {
      sortBy,
      order,
      setSortBy: handleSortBy,
      setOrder: handleSortOrder,
    };
  }, [sortBy, order, handleSortBy, handleSortOrder]);

  const {
    isLoading: isTabsLoading,
    tabId: initialTabId,
    ...tabs
  } = useUserTabs(filterParams?.tabId);

  const { search: querySearch, hash } = useLocation();
  const query = new URLSearchParams(querySearch);

  const ownerIdInitial = query.get('ownerId');
  const hasExpectedShipmentDateInitial = query.get('hasExpectedShipmentDate') === 'true';
  const hasShipmentDateInitial = query.get('hasShipmentDateInitial') === 'true';
  const innInitial = query.get('inn');

  const defaultValues = useMemo(() => {
    let values: QuotaListFilter = {};

    if (hash === '#all') {
      values.tabId = ApplicationUserTab.all;
    } else if (initialTabId) {
      values.tabId = initialTabId;
    }
    if (ownerIdInitial !== null && ownerIdInitial !== '') {
      values.ownerId = [ownerIdInitial];
    }
    if (hasExpectedShipmentDateInitial) {
      values.hasExpectedShipmentDate = true;
    }
    if (hasShipmentDateInitial) {
      values.hasShipmentDate = true;
    }
    if (innInitial !== null && innInitial !== '') {
      values.inn = [innInitial];
      values.tabId = ApplicationUserTab.all;
    }

    values.status = filterParams.status ? filterParams.status : [QuotaStatus.Active];

    return values;
  }, [
    hasExpectedShipmentDateInitial,
    hasShipmentDateInitial,
    initialTabId,
    ownerIdInitial,
    hash,
    innInitial,
    filterParams.status,
  ]);

  const filter = useQuotaFilter({ ...filterParams, ...defaultValues });

  const { control } = filter;

  const defaultPageSize = useWatch({ control, name: 'pageSize' });
  const defaultPage = useWatch({ control, name: 'page' });

  const { page, pageSize, onPageChanged, onPageSizeChanged, onReset } = usePaging({
    pageSize: defaultPageSize,
    page: defaultPage,
  });

  const inn = useWatch({ control, name: 'inn' });
  const dealerInn = useWatch({ control, name: 'dealerInn' });
  const lesseeInn = useWatch({ control, name: 'lesseeInn' });
  const ownerId = useWatch({ control, name: 'ownerId' });
  const leasingProduct = useWatch({ control, name: 'leasingProduct' });
  const search = useWatch({ control, name: 'search' });
  const hasExpectedShipmentDate = useWatch({ control, name: 'hasExpectedShipmentDate' });
  const hasShipmentDate = useWatch({ control, name: 'hasShipmentDate' });
  const tabId = useWatch({ control, name: 'tabId' });
  const status = useWatch({ control, name: 'status' });
  const scoringModelType = useWatch({ control, name: 'scoringModelType' });
  const params = useMemo(() => {
    return {
      inn,
      dealerInn,
      lesseeInn,
      ownerId,
      leasingProduct,
      search,
      hasExpectedShipmentDate,
      hasShipmentDate,
      tabId,
      pageSize,
      page,
      status,
      scoringModelType,
    };
  }, [
    inn,
    dealerInn,
    lesseeInn,
    ownerId,
    leasingProduct,
    search,
    hasExpectedShipmentDate,
    hasShipmentDate,
    tabId,
    pageSize,
    page,
    status,
    scoringModelType,
  ]);

  useEffect(() => {
    sessionStorage.setItem('quotaFilterValues', JSON.stringify(params));
  }, [params]);

  const request: QuotaListRequest = useMemo(() => {
    return {
      inn,
      dealerInn,
      lesseeInn,
      ownerId,
      leasingProduct,
      search,
      hasExpectedShipmentDate,
      hasShipmentDate,
      tabId,
      page,
      pageSize,
      sortBy,
      order,
      status,
      scoringModelType,
    };
  }, [
    inn,
    dealerInn,
    lesseeInn,
    ownerId,
    leasingProduct,
    search,
    hasExpectedShipmentDate,
    hasShipmentDate,
    tabId,
    page,
    pageSize,
    sortBy,
    order,
    status,
    scoringModelType,
  ]);

  const { data, isLoading } = useQuotasListQuery(request);
  const quotas = data?.data ?? [];
  const { user: currentUser } = useUserAuth();
  const currentUserId = currentUser !== null ? currentUser.profile.sub : null;

  const rows = quotas.map(
    ({
      quotaId,
      leaseSubject,
      createdDate,
      user,
      numberOfItems = 1,
      prepayment,
      margin,
      numberOfMonths = 1,
      dealer,
      lessee,
      calculationMethod,
      fundingAmountNBV,
      currency,
      issueStatus,
      issueId,
      returnReason,
      returnText,
      telematicsIssueId,
      telematicsIssueStatus,
      shipmentIssueId,
      shipmentIssueStatus,
      contractNumber,
      hasComments,
      status,
      nomenclatures,
      scoringModelType,
      dealQualificationStatus,
      cof,
      cofChangedDate,
      isDealerSupportSupplier,
      isDealerVendorSupplier,
    }) => {
      return {
        id: quotaId,
        asset: {
          name: leaseSubject,
          numberOfItems,
          prepayment,
          numberOfMonths,
          calculationMethod,
          margin,
        },
        createdDate,
        user,
        dealer,
        isDealerSupportSupplier,
        isDealerVendorSupplier,
        lessee,
        currency,
        fundingAmountNBV,
        action: currentUserId === user.id ? ('changeOwner' as QuotaAction) : 'viewHistory',
        issueStatus,
        issueId,
        returnReason,
        returnText,
        telematicsIssueId,
        telematicsIssueStatus,
        shipmentIssueId,
        shipmentIssueStatus,
        contractNumber,
        hasComments,
        status,
        nomenclatures,
        scoringModelType,
        dealQualificationStatus,
        cof,
        cofChangedDate,
      };
    }
  );
  const pageCount = data?.pageCount ?? 0;
  const totalCount = data?.totalCount ?? 0;
  const length = rows.length;

  const paging = useMemo(() => {
    return {
      pageCount,
      totalCount,
      page,
      pageSize,
      dataCount: length,
      onPageChanged,
      onPageSizeChanged,
      onReset,
    };
  }, [pageCount, totalCount, page, pageSize, length, onPageChanged, onPageSizeChanged, onReset]);

  const onMount = useRef(false);

  useLayoutEffect(() => {
    if (onMount.current) {
      onPageChanged(1);
    } else {
      onMount.current = true;
    }
  }, [
    tabId,
    search,
    dealerInn,
    lesseeInn,
    search,
    ownerId,
    hasExpectedShipmentDate,
    hasShipmentDate,
    pageSize,
    inn,
    leasingProduct,
    status,
    onPageChanged,
  ]);

  return {
    rows,
    isLoading: isLoading || isTabsLoading,
    filter,
    paging,
    sorting,
    tabs,
  };
};
