import { useParams } from 'react-router-dom';
import { useIndustrySpecializationByIdQuery } from 'services/api/useIndustryBackend';
import { IndustrySpecializationEditForm } from './IndustrySpecializationEditForm';

type FormProps = {
  id: number;
};

const Form = (props: FormProps) => {
  const { data, isLoading } = useIndustrySpecializationByIdQuery(props.id);

  if (isLoading || data === undefined) {
    return null;
  }

  return <IndustrySpecializationEditForm industrySpecialization={data} />;
};

export const IndustrySpecializationEditFormPage = () => {
  const { id } = useParams<{ id: string }>();

  const specId = parseInt(id, 10);

  if (Number.isNaN(specId)) {
    return null;
  }

  return <Form id={specId} />;
};
