import { useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { CounterpartyPhysicViewModel } from 'schema/serverTypes';
import { useCounterpartiesBackendMutation, useCounterpartyQuery } from 'services/api';
import { CounterpartyIndividualFormValues } from '../types';
import { useToast } from '../../Toast';
import { useTranslation } from 'react-i18next';

export const useCounterpartyIndividualEditForm = () => {
  const { t } = useTranslation();
  const { inn } = useParams<{ inn: string }>();

  const { data } = useCounterpartyQuery(`physic/${inn}`, {
    refetchOnMount: true,
  });

  const defaultValues = useMemo(() => {
    const emptyContracts = [
      {
        id: 0,
      },
    ];
    return {
      ...data,
      contracts: data?.contracts.length === 0 ? emptyContracts : data?.contracts,
    };
  }, [data]);

  const { handleSubmit, control, reset, setError, clearErrors, formState, ...rest } =
    useForm<CounterpartyIndividualFormValues>({
      mode: 'onBlur',
      defaultValues: defaultValues,
    });

  const isFirstTimeRef = useRef(true);
  useEffect(() => {
    if (data && defaultValues && isFirstTimeRef.current) {
      reset(defaultValues);
      isFirstTimeRef.current = false;
    }
  }, [data, defaultValues, reset]);

  const queryClient = useQueryClient();
  const toast = useToast();
  const { mutateAsync } = useCounterpartiesBackendMutation<
    Omit<CounterpartyPhysicViewModel, 'inn'>,
    CounterpartyPhysicViewModel
  >(`physic`, {
    method: 'PUT',
    onSuccess: () => {
      toast(t('CounterpartyEditSuccessMessage'), 'success');
      queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey.indexOf('counterparties') >= 0;
        },
      });
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const onSubmit = useMemo(() => {
    const submit = async ({ ...form }: CounterpartyIndividualFormValues) => {
      try {
        await mutateAsync({ ...form });
      } catch (error) {
        setError('inn', { message: 'Не удалось обновить' }, { shouldFocus: true });
      }
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync, setError]);

  return {
    control,
    onSubmit,
    clearErrors,
    reset,
    ...formState,
    ...rest,
  };
};
