import { Route } from 'react-router-dom';
import { Critical } from 'components';
import { useErrorContext } from 'components/tasks';
import { useTranslation } from 'react-i18next';
import { IssueSource } from 'schema/serverTypes';
import { FutureShipmentDataGrid } from 'components';

export const FutureShipmentsPage = () => {
  const { t } = useTranslation();

  useErrorContext({
    title: t('Future shipments'),
    source: IssueSource.futureShipments,
  });

  return (
    <>
      <Critical />
      <Route path="/futureshipments" component={FutureShipmentDataGrid} />
    </>
  );
};
