import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { IconSprite, Input, Button, Select } from 'components';
import { Grid } from 'components/Grid';
import { useTranslation } from 'react-i18next';
import { TemplatesFilterFormProps } from './types';
import { themeOrange as theme } from 'theme';
import { MenuItem } from '@material-ui/core';
import { TemplateKind } from 'schema/serverTypes';
import { UserAutocomplete } from './UserAutocomplete';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '100%',
    },
    buttonReset: {
      display: 'flex',
      alignItems: 'flex-end',
      marginBottom: theme.spacing(0.2),
    },
  })
);

export const TemplatesFilterForm = (props: TemplatesFilterFormProps) => {
  const classes = useStyles();
  const { control, handleOnReset } = props;
  const { t } = useTranslation();

  return (
    <form>
      <Grid container columnSpacing={2} rowSpacing={2.5} className={classes.root}>
        <Grid md={7} xs={24} item>
          <Select name="kind" label={t('Type')} variant="standard" control={control}>
            <MenuItem value={TemplateKind.Calculation}>{t('Calculation')}</MenuItem>
            <MenuItem value={TemplateKind.External1C}>{t('Exchange with 1C')}</MenuItem>
            <MenuItem value={TemplateKind.Contract}>{t('Contract')}</MenuItem>
            <MenuItem value={TemplateKind.Shipment}>{t('Shipment')}</MenuItem>
            <MenuItem value={TemplateKind.Pipeline}>{t('Pipeline')}</MenuItem>
            <MenuItem value={TemplateKind.CalculationDetailed}>
              {t('Calculation detailed')}
            </MenuItem>
            <MenuItem value={TemplateKind.Quotas}>{t('Quotas')}</MenuItem>
            <MenuItem value={TemplateKind.Calculation2}>{t('Calculation')}&nbsp;2.0</MenuItem>
          </Select>
        </Grid>
        <Grid md={7} xs={24} item>
          <UserAutocomplete control={control} />
        </Grid>
        <Grid md={7} xs={24} item>
          <Input
            label={`${t('Search')}`}
            control={control}
            name="search"
            variant="standard"
            InputProps={{
              endAdornment: (
                <IconSprite width="16px" color={theme.palette.text.primary} icon="search" />
              ),
            }}
          />
        </Grid>
        <Grid md={3} xs={24} item className={classes.buttonReset}>
          <FormControl>
            <Button variant="text" onClick={handleOnReset}>
              {t('Reset')}
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
};
