import { FieldError, useController, useWatch } from 'react-hook-form';
import { useCalculationFormContext } from '../../types';
import {
  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { ActionButton } from './ActionButton';
import { NumberFormatCustom } from '../../AmountInput';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { isPreferentialMonth } from '../isPreferentialMonth';
import { CalculationMethod } from 'schema/serverTypes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 8,
      '& .MuiOutlinedInput-inputMarginDense': {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
      '& input': {
        fontSize: 12,
        fontWeight: 400,
        width: 28,
        textAlign: 'center',
        paddingLeft: 4,
        paddingRight: 4,
      },
    },
  })
);

export type PaymentAmountInputProps = {
  index: number;
  isSuperSeasonal: boolean;
  quotaId?: number;
};

export const PaymentAmountInput = (props: PaymentAmountInputProps) => {
  const classes = useStyles();
  const { index, isSuperSeasonal, quotaId } = props;

  const { control, setValue } = useCalculationFormContext();

  const isPayment = useWatch({
    control,
    name: `paymentOptions.${index}.isPayment`,
  });

  const isPreferential = useWatch({
    control,
    name: `paymentOptions.${index}.isPreferential`,
  });

  const calculationMethod = useWatch({ control, name: 'calculationMethod' });
  const date = useWatch({ control, name: 'seasonalPaymentOptions.date' });

  const disabled = !isPayment || isPreferential || isSuperSeasonal;

  const { t } = useTranslation();

  const { field, fieldState } = useController({
    control,
    name: `paymentOptions.${index}.value`,
    rules: {
      required: {
        value: true,
        message: t('Required'),
      },
    },
  });

  const { error } = fieldState;

  const helperText = error !== undefined ? (error as FieldError).message : undefined;

  const { ref, ...input } = field;

  useEffect(() => {
    const isPreferential = isPreferentialMonth(index + 1, date);

    if (calculationMethod === CalculationMethod.SuperSeasonal) {
      if (index > 11) {
        return;
      }

      setValue(`paymentOptions.${index}.value`, isPreferential ? 0 : 1);
      setValue(`paymentOptions.${index}.isPreferential`, isPreferential);
    } else if (calculationMethod === CalculationMethod.Seasonal && quotaId === undefined) {
      setValue(`paymentOptions.${index}.value`, 1);
      setValue(`paymentOptions.${index}.isPayment`, true);
      setValue(`paymentOptions.${index}.isPreferential`, false);
    }
  }, [quotaId, calculationMethod, index, date, setValue]);

  return (
    <FormControl
      ref={ref}
      className={classes.root}
      disabled={disabled}
      error={error !== undefined}
      fullWidth={true}
      required={true}
      variant="outlined"
      size="small"
    >
      <Grid container alignItems="center">
        <Grid item>
          <ActionButton action="decrease" index={index} disabled={disabled} />
        </Grid>
        <Grid item>
          <OutlinedInput
            fullWidth={true}
            id={`paymentOptions.${index}.isPreferential`}
            inputProps={{
              allowNegative: false,
              decimalScale: 1,
              allowLeadingZeros: false,
              zeroOnEmptyString: true,
            }}
            inputComponent={NumberFormatCustom}
            {...input}
          />
        </Grid>
        <Grid item>
          <ActionButton action="increase" index={index} disabled={disabled} />
        </Grid>
      </Grid>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};
