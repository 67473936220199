import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordionDetails: {
      padding: theme.spacing(2.5),
      flexDirection: 'column',
    },
    title: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: theme.palette.background.paper,
      padding: theme.spacing(1.5, 0),
    },
  })
);

export const NoMargin = () => {
  const classes = useStyles();

  return (
    <Accordion expanded={true}>
      <AccordionSummary aria-controls="panel3a-content" id="panel3a-header">
        <div className={classes.title}>
          <Typography variant="subtitle1">Маржа</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        Расчет маржи недоступен
      </AccordionDetails>
    </Accordion>
  );
};
