import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
} from '@material-ui/lab/useAutocomplete';
import { Autocomplete } from 'components/Autocomplete';
import { useCallback, useState } from 'react';
import { Control, useController, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLessorsQuery } from './useLessorsQuery';
import { CounterpartyOption } from 'schema/serverTypes';
import { CreateFormFieldValues } from '../types';
import { useDebounce } from 'use-debounce';

export type LessorSelectFieldProps = {
  control: Control<CreateFormFieldValues, object>;
  required?: boolean;
};

const getOptionLabel = (option: CounterpartyOption | null) => {
  if (option === null) {
    return '';
  }
  return `${option.inn} (${option.name})`;
};

const getOptionSelected = (option: CounterpartyOption | null, value: CounterpartyOption | null) => {
  if (option === null || value === null) {
    return false;
  }
  return option.inn === value.inn;
};

export const LessorSelectField = (props: LessorSelectFieldProps) => {
  const { control, required = false } = props;
  const { t } = useTranslation();

  const {
    field: { value, onChange },
    fieldState: { invalid, error },
  } = useController({
    control,
    name: 'lessor',
    rules: required
      ? {
          required: {
            value: true,
            message: t('Required'),
          },
        }
      : undefined,
  });
  const helperText = error !== undefined ? (error as FieldError).message : undefined;

  const [inputValue, setInputValue] = useState('');
  const [input] = useDebounce(inputValue, 500);

  let { options, refetch } = useLessorsQuery(input);

  if (options.length === 0 && value) {
    options.push(value);
  }

  const handleOnInputChange = useCallback(
    (_ev: React.ChangeEvent<{}>, value: string, _reason: AutocompleteInputChangeReason) => {
      setInputValue(value);
    },
    [setInputValue]
  );

  const handleOnChange = useCallback(
    (
      _e: React.ChangeEvent<{}>,
      value: CounterpartyOption | null,
      reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<CounterpartyOption | null>
    ) => {
      if (reason === 'clear') {
        onChange(null);
        refetch();
      } else {
        if (value !== null) {
          onChange(value);
        } else {
          onChange(null);
          refetch();
        }
      }
    },
    [onChange, refetch]
  );

  return (
    <Autocomplete<CounterpartyOption | null>
      label={t('Lessor')}
      options={options}
      inputValue={inputValue}
      onInputChange={handleOnInputChange}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      onChange={handleOnChange}
      error={invalid}
      helperText={helperText}
      value={value}
    />
  );
};
