import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IssueCommentViewModel } from 'schema/serverTypes';
import { Avatar } from '../avatar';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useIssueBackendMutation, useUserAuth } from 'services';
import { Button } from '../Button';
import { IconSprite } from '../icons';
import { useCallback, useState } from 'react';
import { ModalForm, useModalForm } from '../Modal';
import { Typography } from '@material-ui/core';
import { useQueryClient } from 'react-query';
import { createQuotasBackendUrl } from '../../services/api/createQuotasBackendUrl';
import { CommentEditForm } from './CommentEditForm';
import { themeOrange as theme } from 'theme';
import { useLanguage } from '../utils/useLanguage';
import useFileDownloader from '../utils/useFileDownloader';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: 'white',
      padding: theme.spacing(1.5, 1.5, 2),
      margin: theme.spacing(0, -1.5),
      '&$isCurrent': {
        textAlign: 'right',
        '&:hover': {
          backgroundColor: theme.palette.lightBlue3.main,
          '& $file': {
            backgroundColor: 'white',
          },
          '& $action': {
            display: 'inline-block',
          },
        },
      },
    },
    isCurrent: {},
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
      '&$isCurrent': {
        justifyContent: 'space-between',
      },
    },
    auhorWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    author: {
      marginLeft: 8,
      color: theme.palette.primary.main,
      '&:hover': {
        textDecoration: 'underline',
        color: theme.palette.secondary.dark,
      },
    },
    date: {
      display: 'inline-block',
      borderLeft: '1px solid #9EA5BF',
      paddingLeft: 8,
      marginLeft: 8,
    },
    avatar: {
      marginLeft: theme.spacing(1),
    },
    actions: {},
    action: {
      display: 'none',
      '& + &': {
        marginLeft: theme.spacing(0.5),
      },
    },
    text: {
      display: 'inline-block',
      textAlign: 'left',
      padding: theme.spacing(2.5, 1.5),
      boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.24), 0px 2px 4px rgba(0, 0, 0, 0.16)',
      borderRadius: '0px 8px 8px 8px',
      '&$isCurrent': {
        background: theme.palette.bgGray.main,
        borderRadius: '8px 0px 8px 8px',
      },
      '& p': {
        margin: 0,
        paddingBottom: theme.spacing(1.5),
        '&:last-child': {
          paddingBottom: theme.spacing(0),
        },
      },
      '& ol': {
        paddingLeft: theme.spacing(2),
        '& > li': {
          listStyleType: 'decimal',
          marginBottom: theme.spacing(1),
        },
      },
      '& ul': {
        paddingLeft: theme.spacing(2),
        '& > li': {
          listStyle: 'initial',
          marginBottom: theme.spacing(1),
        },
      },
    },
    file: {
      position: 'relative',
      display: 'block',
      textAlign: 'left',
      padding: theme.spacing(1.5, 1.25, 1),
      backgroundColor: theme.palette.lightBlue3.main,
      color: theme.palette.primary.main,
      marginTop: theme.spacing(1.25),
    },
    download: {
      position: 'absolute',
      right: 1,
      top: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      '& *': {
        marginRight: 9,
        cursor: 'pointer',
      },
    },
    modalRoot: {
      textAlign: 'center',
    },
    modalTitle: {
      textAlign: 'center',
      marginBottom: theme.spacing(3),
    },
    modalText: {
      color: theme.palette.text.primary,
    },
    modalButton: {
      marginTop: theme.spacing(2),
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
  })
);

export const Comment = (props: IssueCommentViewModel & { index: number; issueId: number }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const lang = useLanguage();
  const [isEditMode, setEditMode] = useState<boolean>(false);
  const { onOpen, onClose, open } = useModalForm();
  const { author, createdDate, text, issueId, id, file } = props;
  const { user } = useUserAuth();
  const currUserid = user?.profile.sub;
  const isCurrent = author.id === currUserid;

  const editModeHandler = () => {
    setEditMode((prev) => !prev);
  };

  const deleteHandler = useCallback(
    (id) => {
      onOpen();
    },
    [onOpen]
  );
  const queryClient = useQueryClient();

  const { mutateAsync } = useIssueBackendMutation(`${issueId}/comments/${props.id}`, {
    method: 'DELETE',
    onSuccess: () => {
      const queryKey = createQuotasBackendUrl(`/${issueId}`);
      queryClient.invalidateQueries(queryKey);
    },
  });

  const confirmDeleteHandler = useCallback(() => {
    mutateAsync(null);
    onClose();
  }, [onClose, mutateAsync]);

  const downloadFile = useFileDownloader();
  const getFile = useCallback(
    async (ev) => {
      await downloadFile(ev, file?.downloadUrl ?? '', file?.fileName ?? '');
    },
    [downloadFile, file?.downloadUrl, file?.fileName]
  );

  return (
    <>
      <div className={clsx(classes.root, isCurrent ? classes.isCurrent : '')}>
        <div className={clsx(classes.wrapper, isCurrent ? classes.isCurrent : '')}>
          {isCurrent && (
            <div className={classes.actions}>
              <Button
                variant="iconButton"
                endIcon={<IconSprite icon={'edit'} width={'14px'} />}
                onClick={editModeHandler}
                className={classes.action}
              />
              <Button
                variant="iconButton"
                endIcon={<IconSprite icon={'delete'} width={'14px'} />}
                onClick={deleteHandler}
                className={classes.action}
              />
            </div>
          )}
          <div className={classes.auhorWrapper}>
            {!isCurrent && <Avatar size={'medium'} />}
            <Link to={`/users/view/${author.id}`} className={classes.author}>
              {author.name}
            </Link>
            <div className={classes.date}>
              {dayjs(createdDate).locale(lang).format('DD MMM YYYY в HH:mm')}
            </div>
            {isCurrent && (
              <div className={classes.avatar}>
                <Avatar size={'medium'} />
              </div>
            )}
          </div>
        </div>
        {isEditMode ? (
          <CommentEditForm
            text={text}
            editModeHandler={editModeHandler}
            issueId={issueId}
            commentId={id}
          />
        ) : (
          <>
            <div
              className={clsx(classes.text, isCurrent ? classes.isCurrent : '')}
              dangerouslySetInnerHTML={{ __html: text }}
            />
            {file?.downloadUrl ? (
              <div onClick={getFile} className={classes.file}>
                <label>{file?.fileName}</label>
                <div className={classes.download}>
                  <div>
                    <IconSprite
                      icon="download"
                      width="14px"
                      height="13px"
                      color={theme.palette.text.primary}
                      hoverColor={theme.palette.primary.main}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </>
        )}
      </div>
      <ModalForm open={open} onClose={onClose} width={500}>
        <div className={classes.modalRoot}>
          <Typography variant={'h2'} className={classes.modalTitle}>
            {t('Delete comment')}
          </Typography>
          <Typography variant="subtitle2" className={classes.modalText}>
            {t('Are you sure you want to delete the comment')}
          </Typography>
          <Typography variant="body2" className={classes.modalText}>
            {t('on task')} №{props.issueId}?
          </Typography>
          <div>
            <Button
              variant="contained"
              className={classes.modalButton}
              onClick={confirmDeleteHandler}
            >
              {t('Yes')}
            </Button>
            <Button variant="outlined" onClick={onClose} className={classes.modalButton}>
              {t('No')}
            </Button>
          </div>
        </div>
      </ModalForm>
    </>
  );
};
