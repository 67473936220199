import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@material-ui/core';
import { Link as MuiLink } from 'react-router-dom';
import clsx from 'clsx';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      fontWeight: 500,
      fontSize: 12,
      lineHeight: 1.2,
      textTransform: 'inherit',
      padding: theme.spacing(1.5, 3),
      minWidth: 'max-content',
      borderRadius: 100,
    },
    contained: {
      fontFamily: "'Halvar Breit', Helvetica, Arial, sans-serif",
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      animationTimingFunction: 'cubic-bezier(0.2, 0, 0, 1)',
      animationDuration: '200ms',
      '&:hover': {
        border: `1px solid ${theme.palette.primaryGradient[800]}`,
        backgroundColor: theme.palette.primaryGradient[800],
        color: theme.palette.common.white,
        boxShadow: '0px 1px 4px 1px #0000001A',
      },
      '&:focus': {
        border: `1px solid ${theme.palette.primaryGradient[800]}`,
        backgroundColor: theme.palette.primaryGradient[800],
        color: theme.palette.common.white,
        boxShadow: 'none',
      },
      '&:pressed': {
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        boxShadow: 'none',
      },
    },
    outlined: {
      fontFamily: "'Halvar Breit', Helvetica, Arial, sans-serif",
      border: '1px solid' + theme.palette.secondary.light,
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.dark,
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid' + theme.palette.secondary.dark,
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.dark,
        boxShadow: '0px 1px 4px 1px #0000001A',
      },
      '&:focus': {
        border: '1px solid' + theme.palette.secondary.dark,
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.dark,
        boxShadow: 'none',
      },
      '&:pressed': {
        border: '1px solid' + theme.palette.secondary.light,
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.dark,
        boxShadow: 'none',
      },
    },
    chip: {
      borderRadius: 5,
      padding: theme.spacing(0.5, 1),
      fontWeight: 400,
      border: `1px solid ${theme.palette.secondary.main}`,
      '& + &': {
        marginLeft: theme.spacing(1.5),
      },
      '&:hover': {
        backgroundColor: theme.palette.background.light,
      },
    },
    text: {
      padding: 0,
      fontWeight: 400,
      '&:hover': {
        textDecoration: 'underline',
        backgroundColor: 'transparent',
      },
      '&:focus': {
        textDecoration: 'none',
      },
    },
    iconButton: {
      borderRadius: 4,
      padding: 7,
      boxShadow: 'none',
      backgroundColor: 'transparent',
      border: `1px solid ${theme.palette.primary.light}`,
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
        '& svg': {
          fill: theme.palette.primary.main,
        },
      },
      '&:active:hover': {
        backgroundColor: theme.palette.secondary.light,
        border: `1px solid ${theme.palette.background.dark}`,
        boxShadow: 'none',
        '& svg': {
          fill: theme.palette.background.paper,
        },
      },
    },
    inInput: {
      background: theme.palette.secondary.light,
      borderRadius: 2,
      cursor: 'pointer',
      flex: '1 1 auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.text.secondary,
      minWidth: 31,
      height: 31,
      marginRight: 2,
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
    disabled: {
      pointerEvents: 'none',
      boxShadow: 'none',
      borderColor: theme.palette.secondary.main,
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.background.paper,
      textDecoration: 'none',
      '& svg': {
        fill: theme.palette.secondary.main,
      },
    },
    label: {
      minWidth: 'max-content',
    },
    startIcon: {
      margin: theme.spacing(-1.25, 1, 0, 0),
      height: 11,
    },
    endIcon: {
      margin: 0,
    },
  })
);

type UseStylesReturn = Omit<
  ReturnType<typeof useStyles>,
  'root' | 'startIcon' | 'endIcon' | 'label' | 'disabled'
>;

type ButtonNoIconVariant = keyof Omit<UseStylesReturn, 'iconButton'>;

type ButtonIconProps = Pick<MuiButtonProps, 'endIcon' | 'startIcon'> & {
  variant: 'iconButton';
};

type ButtonNoIconProps = {
  variant: ButtonNoIconVariant;
  endIcon?: never;
};

type ButtonVariantProps = ButtonIconProps | ButtonNoIconProps;

export type ButtonProps = Omit<MuiButtonProps, 'variant' | 'component'> &
  ButtonVariantProps & {
    to?: string;
  };

export const Button = (props: ButtonProps) => {
  const { children, to, variant = 'text', className, ...rest } = props;
  const classes = useStyles();

  return (
    <MuiButton
      to={to}
      component={to ? (MuiLink as any) : undefined}
      className={clsx(classes?.[variant], className)}
      classes={{
        root: classes.root,
        startIcon: classes.startIcon,
        endIcon: classes.endIcon,
        label: classes.label,
        disabled: classes.disabled,
      }}
      {...rest}
    >
      {children}
    </MuiButton>
  );
};
