import { Control, useController, UseFormSetValue } from 'react-hook-form';
import { LeasingProductItemViewModel } from 'schema/serverTypes';
import { Autocomplete } from 'components';
import { useCallback, useState } from 'react';
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
} from '@material-ui/lab/useAutocomplete/useAutocomplete';
import { BrandChips } from './BrandChips';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2),
    },
  })
);

export type BrandAutocompleteProps = {
  control: Control<LeasingProductItemViewModel, any>;
  leasingProductBrands: string[];
  setValue: UseFormSetValue<LeasingProductItemViewModel>;
};

const getOptionLabel = (option: string | null) => {
  if (option === null) {
    return '';
  }
  return option;
};

const getOptionSelected = (option: string | null, value: string | null) => {
  if (option === null || value === null) {
    return false;
  }
  return option === value;
};

export const BrandAutocomplete = (props: BrandAutocompleteProps) => {
  const classes = useStyles();
  const { control, leasingProductBrands, setValue } = props;

  const [inputValue, setInputValue] = useState('');

  const {
    field: { onChange, value: brands = [] },
  } = useController({
    control,
    name: 'brands',
    defaultValue: [],
  });

  const options =
    inputValue.length > 0
      ? leasingProductBrands.filter((brand) => {
          const label = getOptionLabel(brand).toLowerCase();
          const input = inputValue.toLowerCase();
          const hasInput = label.indexOf(input) > -1;
          return !brands.includes(brand) && hasInput;
        })
      : leasingProductBrands;

  const handleOnInputChange = useCallback(
    (_ev: React.ChangeEvent<{}>, value: string, _reason: AutocompleteInputChangeReason) => {
      setInputValue(value);
    },
    [setInputValue]
  );

  const handleOnChange = useCallback(
    (
      _e: React.ChangeEvent<{}>,
      value: string | null,
      _reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<string | null>
    ) => {
      if (value === null) {
        onChange([]);
        setInputValue('');
      } else {
        onChange(brands ? [...brands.filter((brand) => brand !== value), value] : [value]);
        setInputValue('');
      }
    },
    [onChange, brands]
  );

  return (
    <>
      <Autocomplete<string | null>
        label="Марка (бренд)"
        options={options}
        inputValue={inputValue}
        onInputChange={handleOnInputChange}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        onChange={handleOnChange}
        value={null}
        variant="outlined"
        className={classes.root}
      />
      <BrandChips control={control} setValue={setValue} />
    </>
  );
};
