import { useMemo, useContext } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Input } from 'components';
import { useIssuesFileUploadMutation } from '../../services/api';
import { IssueType, IssueViewModel } from 'schema/serverTypes';
import { CreateFormFieldValues } from './types';
import { FileUpload } from './FileUpload';
import { ErrorContext } from './ErrorContext';
import { useQueryClient } from 'react-query';
import { WysiwygEditor } from './WysiwygEditor';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { FormHelperText } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.bgGray.main,
      overflow: 'hidden',
    },
    title: {
      height: 47,
      color: theme.palette.primary.main,
      fontWeight: 700,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& div': {
        backgroundColor: 'white',
      },
    },
    description: {
      marginBottom: theme.spacing(1.25),
    },
    file: {
      padding: theme.spacing(1.5, 2),
    },
    button: {
      display: 'block',
      margin: '4px auto 0',
    },
    required: {
      marginTop: theme.spacing(-1.5),
      color: theme.palette.text.primary,
    },
    link: {
      padding: theme.spacing(0, 2, 2),
      fontFamily: "'Halvar Breit', Helvetica, Arial, sans-serif",
      fontSize: 12,
      fontWeight: 500,
      display: 'inline-block',
      color: theme.palette.secondary.dark,
      '& a': {
        color: theme.palette.secondary.dark,
        textDecoration: 'none',
      },
    },
    actions: {
      backgroundColor: theme.palette.bgGray.main,
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%',
      padding: theme.spacing(1, 2, 1.5, 0),
    },
    actionButton: {
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
    save: {
      backgroundColor: 'white',
    },
    cancel: {
      color: theme.palette.primary.main,
      fontSize: 12,
      fontWeight: 600,
      textDecoration: 'none',
      display: 'inline-flex',
      alignItems: 'center',
      height: '100%',
    },
  })
);

export const getFormData = (values: CreateFormFieldValues) => {
  const formData = new FormData();
  if (values.title !== '') {
    formData.append('title', values.title);
  }
  if (values.description !== '') {
    formData.append('description', values.description);
  }
  if (values.file !== null) {
    formData.append('file', values.file);
  }
  return formData;
};

export type IssueEditFormProps = {
  issue: IssueViewModel;
  onSuccess?: () => void;
  editModeHandler: () => void;
};

export const IssueEditForm = (props: IssueEditFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { link, source, quotaId } = useContext(ErrorContext);
  const { issue, editModeHandler } = props;

  const queryClient = useQueryClient();

  const { control, handleSubmit, setValue } = useForm<CreateFormFieldValues>({
    mode: 'all',
    defaultValues: {
      issueType: IssueType.Error,
      issueSource: source,
      title: issue.title ?? '',
      description: issue.description ?? '',
      file: null,
      fileName: issue.file?.fileName ?? '',
      quotaId,
      link,
    },
  });

  const queryKey = `issues/${issue.id}`;
  const { mutateAsync } = useIssuesFileUploadMutation<
    CreateFormFieldValues,
    IssueViewModel | undefined
  >(`/${issue.id}`, getFormData, {
    method: 'PUT',
    onSuccess: (_result) => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            typeof query.queryKey === 'string' && (query.queryKey as string).indexOf(queryKey) > -1
          );
        },
      });
    },
  });

  const onSubmit = useMemo(() => {
    const submit = async (values: CreateFormFieldValues) => {
      const issue = await mutateAsync(values);
      if (issue?.id) {
        editModeHandler();
      }
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync, editModeHandler]);

  const formId = `error-form`;

  return (
    <form id={formId} onSubmit={onSubmit}>
      <div className={classes.container}>
        <div className={classes.title}>
          <Input label={t('Subject')} control={control} name="title" />
        </div>
        <div className={classes.description}>
          <Controller
            name="description"
            control={control}
            render={({ field, fieldState }) => {
              const helperText =
                fieldState.error !== undefined
                  ? (fieldState.error as FieldError).message
                  : undefined;

              return (
                <>
                  <WysiwygEditor value={field.value} onChange={field.onChange} />
                  {helperText && <FormHelperText error>{helperText}</FormHelperText>}
                </>
              );
            }}
            rules={{
              required: true,
              maxLength: {
                value: 10000,
                message: t('Maximum number of characters is', { count: 10000 }),
              },
            }}
          />
        </div>
      </div>
      <div className={classes.file}>
        <FileUpload control={control} setValue={setValue} />
      </div>
      {link && (
        <div className={classes.link}>
          <span>Страница инициализации задачи: </span>
          <Link to={link.url}>{link.title}</Link>
        </div>
      )}

      <div className={classes.actions}>
        <div className={classes.actionButton}>
          <Button
            className={clsx(classes.button, classes.save)}
            color="primary"
            size="medium"
            type="submit"
            variant="outlined"
          >
            {t('Save')}
          </Button>
        </div>

        <div className={classes.actionButton}>
          <Button
            className={clsx(classes.button, classes.cancel)}
            color="primary"
            size="medium"
            type="button"
            variant="text"
            onClick={editModeHandler}
          >
            {t('Cancel2')}
          </Button>
        </div>
      </div>
    </form>
  );
};
