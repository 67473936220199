import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableCell, TableHead, TableRow, TableBody } from '@material-ui/core';
import { ApplicationRole, TelematicsDictionaryItemViewModel } from 'schema/serverTypes';
import { EditButton } from '../EditButton';
import { useTableStyles } from '../useTableStyles';
import { TableSortLabel } from 'components/data-grid/TableSortLabel';
import { TelematicsSorting } from './useTelematicsData';
import { Role } from '../../authentication';

export type TelematicsTableProps = {
  rows: TelematicsDictionaryItemViewModel[];
  loading: boolean;
  onOpen: (item: TelematicsDictionaryItemViewModel) => void;
  sorting: TelematicsSorting;
};

type TableItemProps = {
  item: TelematicsDictionaryItemViewModel;
  onOpen: (item: TelematicsDictionaryItemViewModel) => void;
};

const TableItem = (props: TableItemProps) => {
  const classes = useTableStyles();
  const { item, onOpen } = props;

  const handleOpen = useCallback(() => {
    onOpen(item);
  }, [item, onOpen]);

  return (
    <>
      <TableRow>
        <TableCell size="medium">{item.name}</TableCell>
        <TableCell size="medium">{item.installationCost}</TableCell>
        <TableCell size="medium">{item.subscriptionFee}</TableCell>
        <TableCell size="medium">{item.enabled ? 'Включен' : 'Выключен'}</TableCell>
        <TableCell size="medium" className={classes.actions}>
          <Role role={ApplicationRole.Admin}>
            <EditButton onClick={handleOpen} />
          </Role>
        </TableCell>
      </TableRow>
    </>
  );
};

export const TelematicsTable = (props: TelematicsTableProps) => {
  const classes = useTableStyles();
  const { rows, onOpen, sorting } = props;
  const { t } = useTranslation();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell size="medium">
            <TableSortLabel columnName={'name'} sorting={sorting}>
              {t('Name')}
            </TableSortLabel>
          </TableCell>
          <TableCell size="medium">
            <TableSortLabel columnName={'installationCost'} sorting={sorting}>
              {t('Installation Cost')}
            </TableSortLabel>
          </TableCell>
          <TableCell size="medium">
            <TableSortLabel columnName={'subscriptionFee'} sorting={sorting}>
              {t('Subscription Fee')}
            </TableSortLabel>
          </TableCell>
          <TableCell size="medium">
            <TableSortLabel columnName={'enabled'} sorting={sorting}>
              {t('IsEnabled')}
            </TableSortLabel>
          </TableCell>
          <TableCell className={classes.change} size="medium" />
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((item: TelematicsDictionaryItemViewModel, index: number) => (
          <TableItem key={index} item={item} onOpen={onOpen} />
        ))}
      </TableBody>
    </Table>
  );
};
