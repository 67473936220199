import { MenuItem, Typography } from '@material-ui/core';
import { Grid, Input, Button, Select, useToast } from 'components';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { QuotaLostReasonViewModel, QuotaStatus, SetQuotaStatusRequest } from 'schema/serverTypes';
import { useQuotaLostReasonBackendQuery, useSetQuotaStatusMutation } from '../../../services';
import { useQueryClient } from 'react-query';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(5.5),
    },
    button: {
      display: 'block',
      margin: '4px auto 0',
    },
    required: {
      marginTop: theme.spacing(-1.5),
      color: theme.palette.text.primary,
    },
    period: {
      color: theme.palette.text.primary,
      display: 'flex',
      alignItems: 'center',
    },
  })
);

export type ChangeStatusFormProps = {
  onSuccess: () => void;
  quotaId?: number[];
  setSelectedQuotas?: Dispatch<SetStateAction<number[]>>;
};

export const ChangeStatusForm = (props: ChangeStatusFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const toast = useToast();
  const { onSuccess, quotaId, setSelectedQuotas } = props;

  const { control, handleSubmit } = useForm<SetQuotaStatusRequest>({
    mode: 'all',
    defaultValues: {
      quotaId,
      status: QuotaStatus.Lost,
    },
  });
  const queryClient = useQueryClient();

  const { mutateAsync } = useSetQuotaStatusMutation({
    onSuccess: () => {
      if (setSelectedQuotas) setSelectedQuotas([]);
      toast(t('Status changed'), 'success');
      queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey.indexOf('quotas') > -1;
        },
      });
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const onSubmit = useMemo(() => {
    const submit = async (form: SetQuotaStatusRequest) => {
      try {
        await mutateAsync({ ...form });
        onSuccess();
      } catch (error) {
        console.log(error);
      }
    };
    return handleSubmit(submit);
  }, [handleSubmit, onSuccess, mutateAsync]);

  const [isCommentRequired, setIsCommentRequired] = useState<boolean>(false);
  const { data, isLoading } = useQuotaLostReasonBackendQuery<QuotaLostReasonViewModel[]>({
    relativeUrl: '/all',
  });

  const reasons = data ?? [];

  const changeHandler = (value: string) => {
    const isCommentRequired = reasons.find((item) => item.text === value)?.isCommentRequired;
    setIsCommentRequired(isCommentRequired ?? false);
  };

  return (
    <form onSubmit={onSubmit}>
      <Typography variant={'h2'} className={classes.title}>
        {t('Lost Status')}
      </Typography>
      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item md={24} xs={24}>
          <Select
            label={t('Reason for loss of calculation')}
            name="reason"
            control={control}
            disabled={isLoading}
            onChangeCallback={(value) => changeHandler(value)}
          >
            {reasons.map((reason) => (
              <MenuItem value={reason.text}>{reason.text}</MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item md={24} xs={24}>
          <Input
            label={t('Comment')}
            control={control}
            name="comment"
            multiline={true}
            rules={{
              required: {
                value: isCommentRequired,
                message: t('Required'),
              },
            }}
          />
        </Grid>
        <Grid item md={24} xs={24}>
          <Button
            className={classes.button}
            color="primary"
            size="medium"
            type="submit"
            variant="contained"
          >
            {t('Save')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
