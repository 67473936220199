import { useWatch } from 'react-hook-form';
import { Radio, RadioOption } from 'components/form';
import { FieldsControlProps } from './types';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: '100%',
    },
  })
);

export const TelematicsDevices = (props: FieldsControlProps) => {
  const classes = useStyles();

  const { control } = props;

  const telematicsDevices = useWatch({ control, name: 'telematicsDevices' });
  const options = telematicsDevices.map(
    (device) => ({ label: device.name, value: device.id.toString() } as RadioOption)
  );

  return (
    <div className={classes.wrapper}>
      <Radio row={false} name="defaultTelematicsDeviceId" control={control} options={options} />
    </div>
  );
};
