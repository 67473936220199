import { Grid } from '@material-ui/core';
import { useMissingListData } from './useMissingListData';
import { MissingFilterForm } from './MissingFilterForm';
import { Paging } from '../paging';
import { MissingTable } from './MissingTable';
import { useLocation, useParams } from 'react-router-dom';
import { MissingDataGridProps, MissingFilterFormValues } from './types';
import { MissingFilterCounterpartyForm } from './MissingFilterCounterpartyForm';
import { useMissingLastImportDateRequest } from 'services/api/useMissingBackend';
import { useMemo } from 'react';
import { ApplicationUserTab } from '../../schema';

export const MissingDataGrid = (props: MissingDataGridProps) => {
  const { inn } = useParams<{ inn: string }>();

  const { search, hash } = useLocation();
  const query = new URLSearchParams(search);
  const managerId = query.get('managerId');
  const managerName = query.get('managerName');
  const managerEmail = query.get('managerEmail');
  const counterpartyInn = query.get('counterpartyInn');
  const counterpartyName = query.get('counterpartyName');
  const documentType = query.get('documentType');
  const tabId = query.get('tabId');

  const filterName = useMemo(() => {
    return inn ? `missingFilterValues${inn}` : 'missingFilterValues';
  }, [inn]);

  const { debt: debtFromProps, tabId: tabFromProps } = props;

  const defaultValues = useMemo(() => {
    let values: MissingFilterFormValues = {};

    if (managerId) {
      values.managerId = [{ id: managerId, name: managerId }];
    } else if (managerEmail !== null && managerName !== null) {
      values.managerId = [{ id: managerEmail, name: managerName }];
    }

    if (documentType !== null && documentType !== '') {
      values.documentType = [documentType];
    }

    if (inn) {
      values.inn = [{ inn, name: inn }];
    } else if (counterpartyInn && counterpartyName) {
      values.inn = [{ inn: counterpartyInn, name: counterpartyName }];
    } else if (counterpartyInn) {
      values.inn = [{ inn: counterpartyInn, name: counterpartyInn }];
    }

    if (inn || hash === '#all') {
      values.tabId = ApplicationUserTab.all;
    } else if (tabId) {
      values.tabId = parseInt(tabId);
    }

    return { ...values, debt: debtFromProps, tabId: tabFromProps ?? values.tabId };
  }, [
    inn,
    managerId,
    counterpartyInn,
    counterpartyName,
    documentType,
    managerEmail,
    managerName,
    tabId,
    debtFromProps,
    tabFromProps,
    hash,
  ]);

  const { rows, isLoading, paging, filter, sorting } = useMissingListData(
    filterName,
    defaultValues
  );
  const { data: importDates } = useMissingLastImportDateRequest();
  const { control, onReset, setValue } = filter;

  return (
    <>
      {inn ? (
        <MissingFilterCounterpartyForm control={control} onReset={onReset} setValue={setValue} />
      ) : (
        <MissingFilterForm control={control} onReset={onReset} setValue={setValue} />
      )}

      <Grid container item direction="column">
        <Grid item>
          <MissingTable
            rows={rows}
            importDates={importDates}
            isLoading={isLoading}
            sorting={sorting}
          />
        </Grid>
        <Grid item>
          <Paging {...paging} />
        </Grid>
      </Grid>
    </>
  );
};
