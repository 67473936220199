import { useTranslation } from 'react-i18next';
import { Table, TableCell, TableHead, TableRow, TableBody } from '@material-ui/core';
import { useNomenclaturesData } from './useNomenclaturesData';
import { NomenclatureViewModel } from 'schema';
import { TableSortLabel } from 'components/data-grid/TableSortLabel';

export type NomenclaturesTableProps = Omit<
  ReturnType<typeof useNomenclaturesData>,
  'paging' | 'filter'
>;

type TableItemProps = {
  item: NomenclatureViewModel;
};

const TableItem = (props: TableItemProps) => {
  const { item } = props;
  const { t } = useTranslation();

  const yes = t('Yes');
  const no = t('No');

  return (
    <TableRow>
      <TableCell size="medium">{item.code}</TableCell>
      <TableCell
        size="medium"
        dangerouslySetInnerHTML={{
          __html: item.model.replace(/(\w)/g, '<span style="color: red">$1</span>'),
        }}
      />
      <TableCell
        size="medium"
        dangerouslySetInnerHTML={{
          __html: item.vendor.replace(/(\w)/g, '<span style="color: red">$1</span>'),
        }}
      />
      <TableCell size="medium">{item.leaseSubject}</TableCell>
      <TableCell size="medium">{item.depreciationGroup}</TableCell>
      <TableCell
        size="medium"
        dangerouslySetInnerHTML={{
          __html: item.category
            ? item.category.replace(/(\w)/g, '<span style="color: red">$1</span>')
            : '',
        }}
      />
      <TableCell size="medium">{item.categoryCode}</TableCell>
      <TableCell size="medium">{item.liquidityClass}</TableCell>
      <TableCell
        size="medium"
        dangerouslySetInnerHTML={{
          __html: item.equipment
            ? item.equipment.replace(/(\w)/g, '<span style="color: red">$1</span>')
            : '',
        }}
      />
      <TableCell size="medium">{item.assetType}</TableCell>
      <TableCell size="medium">
        {item.isTelematicsEnabled === undefined ? null : item.isTelematicsEnabled ? yes : no}
      </TableCell>
      <TableCell size="medium">{item.isDeleted ? yes : null}</TableCell>
    </TableRow>
  );
};

export const NomenclaturesTable = (props: NomenclaturesTableProps) => {
  const { data, sorting } = props;
  const { t } = useTranslation();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell size="medium">
            <TableSortLabel columnName="code" sorting={sorting}>
              {t('Code')}
            </TableSortLabel>
          </TableCell>
          <TableCell size="medium">
            <TableSortLabel columnName="model" sorting={sorting}>
              {t('Model')}
            </TableSortLabel>
          </TableCell>
          <TableCell size="medium">
            <TableSortLabel columnName="vendor" sorting={sorting}>
              Марка (бренд)
            </TableSortLabel>
          </TableCell>
          <TableCell size="medium">
            <TableSortLabel columnName="leaseSubject" sorting={sorting}>
              {t('VehicleType')}
            </TableSortLabel>
          </TableCell>
          <TableCell size="medium">
            <TableSortLabel columnName="depreciationGroup" sorting={sorting}>
              {t('Depreciation group')}
            </TableSortLabel>
          </TableCell>
          <TableCell size="medium">
            <TableSortLabel columnName="category" sorting={sorting}>
              {t('Category')}
            </TableSortLabel>
          </TableCell>
          <TableCell size="medium">
            <TableSortLabel columnName="categoryCode" sorting={sorting}>
              {t('CategoryCode')}
            </TableSortLabel>
          </TableCell>
          <TableCell size="medium">
            <TableSortLabel columnName="liquidityClass" sorting={sorting}>
              {t('LiquidityClass')}
            </TableSortLabel>
          </TableCell>
          <TableCell size="medium">
            <TableSortLabel columnName="equipment" sorting={sorting}>
              {t('Equipment')}
            </TableSortLabel>
          </TableCell>
          <TableCell size="medium">
            <TableSortLabel columnName="assetType" sorting={sorting}>
              {t('Asset type')}
            </TableSortLabel>
          </TableCell>
          <TableCell size="medium">Уст. телематики</TableCell>
          <TableCell size="medium">{t('Deleted')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item, index) => (
          <TableItem key={index} item={item} />
        ))}
      </TableBody>
    </Table>
  );
};
