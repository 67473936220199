import { MutableRefObject, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { MenuItem, Typography } from '@material-ui/core';
import {
  CloseNomenclatureIssueViewModel,
  IssueStatus,
  IssueType,
  IssueViewModel,
  ReturnIssueModel,
} from 'schema/serverTypes';
import { Input, Select } from '../form';
import { createQuotasBackendUrl } from 'services/api/createQuotasBackendUrl';
import {
  useCloseNomenclatureIssueMutation,
  useIssueOpenAgainMutation,
  useIssueReturnMutation,
  useUpdateIssueStatusMutation,
} from 'services/api/useIssuesBackend';
import { Button } from '../Button';
import { ModalForm, useModalForm } from '../Modal';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useSendTo1CMutation } from 'services/api/useContractsBackend';
import { useToast } from '../Toast';
import { useIssueVerificateMutation } from 'services/api/useIssuesBackend';
import { SearchingNomenclature } from './SearchingNomeclature';
import {
  ApprovalConfirmPopup,
  ApprovalOpenAgainPopup,
  ApprovalRejectPopup,
  ApprovalReturnPopup,
} from './Approval/ApprovalPopups';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: 'white',
      padding: theme.spacing(2.5),
      display: 'flex',
      flexWrap: 'wrap',
      color: theme.palette.common.black,
      fontSize: 12,
      alignItems: 'center',
    },
    paramtitle: {
      width: '40%',
      textAlign: 'right',
      paddingRight: 6,
      color: theme.palette.text.secondary,
      marginBottom: theme.spacing(1.5),
    },
    param: {
      width: '60%',
      paddingLeft: 6,
      marginBottom: theme.spacing(1.5),
      '&$link': {
        color: theme.palette.secondary.dark,
      },
    },
    paramSelectStatus: {
      width: 140,
    },
    link: {},
    dot: {
      display: 'inline-block',
      width: 8,
      height: 8,
      borderRadius: '50%',
      marginRight: theme.spacing(1),
    },
    dotOpened: {
      backgroundColor: theme.palette.primary.main,
    },
    dotInProgress: {
      backgroundColor: theme.palette.attention.main,
    },
    dotForReview: {
      backgroundColor: theme.palette.secondary.main,
    },
    dotPostponed: {
      backgroundColor: theme.palette.error.main,
    },
    dotClosed: {
      backgroundColor: theme.palette.green.main,
    },
    dotReturned: {
      backgroundColor: theme.palette.error.main,
    },
    actions: {
      marginTop: theme.spacing(-6),
      marginBottom: theme.spacing(1),
      display: 'flex',
      justifyContent: 'flex-end',
    },
    actionButton: {
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
    modalRoot: {
      textAlign: 'center',
    },
    modalTitle: {
      marginBottom: theme.spacing(3),
    },
    modalSelect: {
      textAlign: 'left',
    },
    modalButton: {
      marginTop: theme.spacing(2),
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
  })
);

type IssueActionsProps = {
  issue: IssueViewModel;
  updatedFromButtons: MutableRefObject<boolean>;
  setValue: (...args: any) => void;
};

export const IssueActions = (props: IssueActionsProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { issue, updatedFromButtons, setValue } = props;
  const { id, returnReason = t('ReturnReason.NoDocuments') } = issue;

  const { onOpen, onClose, open } = useModalForm();

  const {
    onOpen: onOpenCodePopup,
    onClose: onCloseCodePopup,
    open: openCodePopup,
  } = useModalForm();

  const {
    onOpen: onOpenApprovalConfirmPopup,
    onClose: onCloseApprovalConfirmPopup,
    open: openApprovalConfirm,
  } = useModalForm();

  const {
    onOpen: onOpenApprovalRejectPopup,
    onClose: onCloseApprovalRejectPopup,
    open: openApprovalReject,
  } = useModalForm();

  const {
    onOpen: onOpenApprovalReturnPopup,
    onClose: onCloseApprovalReturnPopup,
    open: openApprovalReturn,
  } = useModalForm();

  const {
    onOpen: onOpenApprovalOpenAgainPopup,
    onClose: onCloseApprovalOpenAgainPopup,
    open: openApprovalOpenAgain,
  } = useModalForm();

  const queryKey = createQuotasBackendUrl(`/${id}`);

  const queryClient = useQueryClient();

  const { mutateAsync: updateStatusAsync } = useUpdateIssueStatusMutation(id);

  const handleExecute = useCallback(async () => {
    const issue = await updateStatusAsync({ status: IssueStatus.InProgress });
    if (issue) {
      await queryClient.invalidateQueries(queryKey);
      updatedFromButtons.current = true;
      setValue('status', issue.status);
    }
  }, [updateStatusAsync, queryKey, queryClient, setValue, updatedFromButtons]);

  const { control: returnControl, handleSubmit: handleReturnSubmit } = useForm<ReturnIssueModel>({
    mode: 'onBlur',
    defaultValues: {
      returnReason,
    },
  });

  const {
    control: sendCodeControl,
    handleSubmit: handleSendCodeSubmit,
    watch,
  } = useForm<CloseNomenclatureIssueViewModel>({
    mode: 'onBlur',
  });

  const code = watch('code');

  const handleReturn = useCallback(
    (_id) => {
      onOpen();
    },
    [onOpen]
  );

  const { mutateAsync: returnIssueAsync } = useIssueReturnMutation(props.issue.id);

  const handleConfirmReturn = useCallback(() => {
    const onSubmit = async (data: ReturnIssueModel) => {
      const issue = await returnIssueAsync(data);
      if (issue) {
        await queryClient.invalidateQueries(queryKey);
        updatedFromButtons.current = true;
        setValue('status', issue.status);
        onClose();
      }
    };
    handleReturnSubmit(onSubmit)();
  }, [
    returnIssueAsync,
    queryClient,
    handleReturnSubmit,
    onClose,
    queryKey,
    setValue,
    updatedFromButtons,
  ]);

  const history = useHistory();
  let { pathname } = useLocation();
  pathname = '/' + pathname.split('/')[1];

  const { mutateAsync: sendCodeAsync } = useCloseNomenclatureIssueMutation(props.issue.id, {
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const handleSendCode = useCallback(() => {
    const onSubmit = async (data: CloseNomenclatureIssueViewModel) => {
      const issue = await sendCodeAsync(data);
      if (issue) {
        await updateStatusAsync({ status: IssueStatus.Closed });
        queryClient.invalidateQueries({
          predicate: (query) => {
            return query.queryKey.indexOf('quotas') > -1;
          },
        });
        history.push(pathname);
      }
    };
    handleSendCodeSubmit(onSubmit)();
  }, [sendCodeAsync, handleSendCodeSubmit, updateStatusAsync, history, pathname, queryClient]);

  const handleClose = useCallback(async () => {
    const issue = await updateStatusAsync({ status: IssueStatus.Closed });
    if (issue) {
      history.push(pathname);
    }
  }, [updateStatusAsync, history, pathname]);

  const { mutateAsync: mutateAsyncOpenAgain } = useIssueOpenAgainMutation(props.issue.id);

  const handleOpenAgain = useCallback(async () => {
    const issue = await mutateAsyncOpenAgain({});
    if (issue) {
      await queryClient.invalidateQueries(queryKey);
      updatedFromButtons.current = true;
      setValue('status', issue.status);
    }
  }, [mutateAsyncOpenAgain, queryKey, queryClient, setValue, updatedFromButtons]);

  const toast = useToast();

  const { mutateAsync, isLoading: isSendingTo1C } = useSendTo1CMutation(issue.quotaId ?? 0);
  const errorMessage = t('ErrorMessage');
  const successMessage = t('Contract successfully has been sent to 1C');

  const handleSendTo1C = useCallback(async () => {
    const result = await mutateAsync(null);
    if (result.success) {
      toast(successMessage, 'success');
    } else {
      toast(errorMessage, 'error');
    }
  }, [mutateAsync, toast, successMessage, errorMessage]);

  const { mutateAsync: verificateAsync, isLoading: isVerificating } = useIssueVerificateMutation(
    issue.id
  );

  const verificationSuccessMessage = t('SaveSuccessMessage');

  const handleVerificate = useCallback(async () => {
    const result = await verificateAsync(null);
    if (result) {
      toast(verificationSuccessMessage, 'success');
      await queryClient.invalidateQueries(queryKey);
      updatedFromButtons.current = true;
    } else {
      toast(errorMessage, 'error');
    }
  }, [
    verificateAsync,
    toast,
    verificationSuccessMessage,
    errorMessage,
    queryClient,
    queryKey,
    updatedFromButtons,
  ]);

  return (
    <>
      <div className={classes.actions}>
        {issue.canBeSendTo1C && (
          <Button
            variant="contained"
            onClick={handleSendTo1C}
            className={classes.actionButton}
            disabled={isSendingTo1C}
          >
            {t('Send to 1C')}
          </Button>
        )}

        {issue.canBeVerificated && (
          <Button
            variant="contained"
            onClick={handleVerificate}
            className={classes.actionButton}
            disabled={isVerificating}
          >
            {t('Verificate')}
          </Button>
        )}

        {issue.canBeTaken && (
          <Button variant="contained" onClick={handleExecute} className={classes.actionButton}>
            {t('Execute')}
          </Button>
        )}

        {issue.canBeReturned && (
          <Button
            variant="outlined"
            onClick={
              issue.issueType === IssueType.Approval ? onOpenApprovalReturnPopup : handleReturn
            }
            className={classes.actionButton}
          >
            {t('Return')}
          </Button>
        )}

        {issue.canBeClosed &&
          (issue.issueType === IssueType.Nomenclature ? (
            <Button variant="contained" onClick={onOpenCodePopup} className={classes.actionButton}>
              {t('Closed')}
            </Button>
          ) : issue.issueType === IssueType.Approval ? (
            <>
              <Button
                variant="outlined"
                onClick={onOpenApprovalConfirmPopup}
                className={classes.actionButton}
              >
                {t('Confirm')}
              </Button>
              <Button
                variant="outlined"
                onClick={onOpenApprovalRejectPopup}
                className={classes.actionButton}
              >
                {t('Reject')}
              </Button>
            </>
          ) : (
            <Button variant="contained" onClick={handleClose} className={classes.actionButton}>
              {t('Closed')}
            </Button>
          ))}

        {issue.canBeOpenedAgain && (
          <Button
            variant="contained"
            onClick={
              issue.issueType === IssueType.Approval
                ? onOpenApprovalOpenAgainPopup
                : handleOpenAgain
            }
            className={classes.actionButton}
          >
            {t('OpenAgain')}
          </Button>
        )}
      </div>

      <ModalForm open={openCodePopup} onClose={onCloseCodePopup} width={500}>
        <div className={classes.modalRoot}>
          <Typography variant={'h2'} className={classes.modalTitle}>
            {t('TaskComplete')}
          </Typography>
          <form className={classes.modalSelect}>
            <Input
              label={t('Enter the code from 1-C')}
              name="code"
              control={sendCodeControl}
              rules={{
                required: {
                  value: true,
                  message: t('Required'),
                },
              }}
            />
            <SearchingNomenclature code={code} />
          </form>
          <div>
            <Button variant="contained" className={classes.modalButton} onClick={handleSendCode}>
              {t('Ok')}
            </Button>
            <Button variant="outlined" onClick={onCloseCodePopup} className={classes.modalButton}>
              {t('Cancel')}
            </Button>
          </div>
        </div>
      </ModalForm>

      <ModalForm open={open} onClose={onClose} width={500}>
        <div className={classes.modalRoot}>
          <Typography variant={'h2'} className={classes.modalTitle}>
            {t('Specify the reason for the return')}
          </Typography>
          <form className={classes.modalSelect}>
            <Select name="returnReason" control={returnControl}>
              <MenuItem value={t('ReturnReason.NoDocuments')}>
                {t('ReturnReason.NoDocuments')}
              </MenuItem>
              <MenuItem value={t('ReturnReason.PriceChange')}>
                {t('ReturnReason.PriceChange')}
              </MenuItem>
              <MenuItem value={t('ReturnReason.GraphChange')}>
                {t('ReturnReason.GraphChange')}
              </MenuItem>
              <MenuItem value={t('ReturnReason.TypeChange')}>
                {t('ReturnReason.TypeChange')}
              </MenuItem>
              <MenuItem value={t('ReturnReason.Another')}>{t('ReturnReason.Another')}</MenuItem>
            </Select>
          </form>
          <div>
            <Button
              variant="contained"
              className={classes.modalButton}
              onClick={handleConfirmReturn}
            >
              {t('Ok')}
            </Button>
            <Button variant="outlined" onClick={onClose} className={classes.modalButton}>
              {t('Cancel')}
            </Button>
          </div>
        </div>
      </ModalForm>

      <ModalForm open={openApprovalConfirm} onClose={onCloseApprovalConfirmPopup} width={500}>
        <ApprovalConfirmPopup issueId={issue.id} onClose={onCloseApprovalConfirmPopup} />
      </ModalForm>
      <ModalForm open={openApprovalReject} onClose={onCloseApprovalRejectPopup} width={500}>
        <ApprovalRejectPopup issueId={issue.id} onClose={onCloseApprovalRejectPopup} />
      </ModalForm>
      <ModalForm open={openApprovalReturn} onClose={onCloseApprovalReturnPopup} width={500}>
        <ApprovalReturnPopup issueId={issue.id} onClose={onCloseApprovalReturnPopup} />
      </ModalForm>
      <ModalForm open={openApprovalOpenAgain} onClose={onCloseApprovalOpenAgainPopup} width={500}>
        <ApprovalOpenAgainPopup issueId={issue.id} onClose={onCloseApprovalOpenAgainPopup} />
      </ModalForm>
    </>
  );
};
