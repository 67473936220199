import { useContractDocumentRequest } from 'services';
import { ContractDocument } from './ContractDocument';
import { Grid } from 'components/Grid';

type ContractDocumentListProps = {
  contractNumber: string;
};

export const ContractDocumentList = (props: ContractDocumentListProps) => {
  const { data } = useContractDocumentRequest(props.contractNumber);
  const documents = data ?? [];

  return (
    <Grid container columnSpacing={2} rowSpacing={2.5}>
      <Grid item>
        <ContractDocument
          document={documents.find((doc) => doc.documentType === 'Договор купли-продажи')}
          title="Договор купли-продажи"
        />
      </Grid>
      <Grid item>
        <ContractDocument
          document={documents.find((doc) => doc.documentType === 'Договор лизинга')}
          title="Договор лизинга"
        />
      </Grid>
    </Grid>
  );
};
