import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { Button } from 'components';
import { Grid } from 'components/Grid';
import { useTranslation } from 'react-i18next';
import { UserAutocomplete } from './UserAutocomplete';
import { RatingFilterFormProps } from './types';
import { RatingChips } from './RatingChips';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '100%',
    },
    buttonReset: {
      display: 'flex',
      alignItems: 'flex-end',
      marginBottom: theme.spacing(0.2),
    },
  })
);

export const RatingFilterForm = (props: RatingFilterFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { control, onReset, setValue } = props;

  return (
    <form>
      <Box display="flex" justifyContent="space-between" mb={2.5}>
        <Box flex={1}>
          <Grid container columnSpacing={2} rowSpacing={2.5} className={classes.root}>
            <Grid md={9} xs={24} item>
              <UserAutocomplete control={control} />
            </Grid>
            <Grid md={3} xs={24} item className={classes.buttonReset}>
              <FormControl>
                <Button variant="text" onClick={onReset}>
                  {t('Reset')}
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box mb={1}>
        <RatingChips control={control} setValue={setValue} />
      </Box>
    </form>
  );
};
