import { Grid } from 'components';
import { Dispatch, SetStateAction } from 'react';
import { useStyles } from './useStyles';
import clsx from 'clsx';
import {
  RatingQuestion10,
  RatingQuestion11,
  RatingQuestion12,
  RatingQuestion13,
  RatingQuestion14,
  RatingQuestion15,
  RatingQuestion16,
  RatingQuestion17,
  RatingQuestion18,
  RatingQuestion7,
  RatingQuestion8,
  RatingQuestion9,
} from './RatingQuestion';

export type RatingCreateFormProps = {
  inn: string;
  editId: number | null;
  setEdit: Dispatch<SetStateAction<number | null>>;
  control: any;
};

export const RatingOptionalFields = (props: RatingCreateFormProps) => {
  const { editId, control } = props;

  const classes = useStyles();

  const ratingClass = !editId ? classes.root : clsx(classes.root, classes.noEditMode);

  return (
    <>
      <div className={ratingClass}>
        <Grid container rowSpacing={2}>
          <Grid item xs={24}>
            <RatingQuestion7 control={control} />
          </Grid>
          <Grid item xs={24}>
            <RatingQuestion8 control={control} />
          </Grid>
          <Grid item xs={24}>
            <RatingQuestion9 control={control} />
          </Grid>
          <Grid item xs={24}>
            <RatingQuestion10 control={control} />
          </Grid>
          <Grid item xs={24}>
            <RatingQuestion11 control={control} />
          </Grid>
          <Grid item xs={24}>
            <RatingQuestion12 control={control} />
          </Grid>
          <Grid item xs={24}>
            <RatingQuestion13 control={control} />
          </Grid>
          <Grid item xs={24}>
            <RatingQuestion14 control={control} />
          </Grid>
          <Grid item xs={24}>
            <RatingQuestion15 control={control} />
          </Grid>
          <Grid item xs={24}>
            <RatingQuestion16 control={control} />
          </Grid>
          <Grid item xs={24}>
            <RatingQuestion17 control={control} />
          </Grid>
          <Grid item xs={24}>
            <RatingQuestion18 control={control} />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
