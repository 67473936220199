import { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import { QueryParamProvider } from 'use-query-params';
import { themeOrange } from 'theme';
import {
  LoadingLinearIndicator,
  PageLayout,
  PrivateRoute,
  SignalRContextProvider,
} from 'components';
import {
  CalculatorPage,
  CallbackPage,
  ContractsPage,
  CounterpartiesPage,
  FullScreenPage,
  GroupsPage,
  HomePage,
  InsurancePage,
  LoginPage,
  LogoutPage,
  MissingPage,
  NewsPage,
  OverduePage,
  QuotasPage,
  RoutesPage,
  SettingsPage,
  ShipmentsPage,
  SilentPage,
  TasksPage,
  TemplatesPage,
  TvPage,
  UsersPage,
  VendorPage,
  WikiPage,
  RequestsPage,
  FutureShipmentsPage,
} from './pages';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/ru';
import { ErrorContextProvider } from 'components/tasks';
import { ApiQueryProvider, ApplicationRoles, AuthenticationProvider } from 'services';
import { useTranslation } from 'react-i18next';
import { MissingFilterContextProvider } from 'components/Home/MissingProgress';
import { ApplicationRole } from 'schema';

const PagesWithLanguage = () => {
  const { i18n } = useTranslation();
  const { language } = i18n;

  useEffect(() => {
    moment.locale(language);
  }, [language]);

  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={language}>
      <MissingFilterContextProvider>
        <Switch>
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/logout" component={LogoutPage} />
          <Route exact path="/callback" component={CallbackPage} />
          <Route exact path="/silent" component={SilentPage} />
          <Route exact path="/tv" component={TvPage} />
          <PrivateRoute path="/vendordashboard" component={VendorPage} />
          <PrivateRoute path="/main" component={FullScreenPage} />
          <PrivateRoute path="/users" component={UsersPage} role={[ApplicationRole.Admin]} />
          <PrivateRoute path="/groups" component={GroupsPage} role={[ApplicationRole.Admin]} />
          <PrivateRoute path="/calculator" component={CalculatorPage} />
          <PrivateRoute path="/quotas" component={QuotasPage} />
          <PrivateRoute
            path="/shipments"
            component={ShipmentsPage}
            role={[ApplicationRole.Admin]}
          />
          <PrivateRoute
            path="/counterparties"
            component={CounterpartiesPage}
            role={[
              ApplicationRole.Admin,
              ApplicationRole.SuperSalesManager,
              ApplicationRole.SalesManager,
              ApplicationRole.RiskManager,
              ApplicationRole.AssetManager,
              ApplicationRole.SalesSupport,
              ApplicationRole.Compliance,
              ApplicationRole.InsideSales,
            ]}
          />
          <PrivateRoute
            path="/dictionaries"
            component={RoutesPage}
            role={[
              ApplicationRole.Admin,
              ApplicationRole.SuperSalesManager,
              ApplicationRole.SalesManager,
              ApplicationRole.RiskManager,
              ApplicationRole.AssetManager,
              ApplicationRole.InsideSales,
            ]}
          />
          <PrivateRoute
            path="/templates"
            component={TemplatesPage}
            role={[
              ApplicationRole.Admin,
              ApplicationRole.SuperSalesManager,
              ApplicationRole.RiskManager,
            ]}
          />
          <PrivateRoute path="/tasks" component={TasksPage} />
          <PrivateRoute path="/news" component={NewsPage} />
          <PrivateRoute
            path="/overdue"
            component={OverduePage}
            role={ApplicationRoles.allExceptAssetManager}
          />
          <PrivateRoute
            path="/missing"
            component={MissingPage}
            role={ApplicationRoles.allExceptAssetManager}
          />
          <PrivateRoute
            path="/wiki"
            component={WikiPage}
            role={ApplicationRoles.allExceptAssetManager}
          />
          <PrivateRoute
            path="/insurance"
            component={InsurancePage}
            role={ApplicationRoles.allExceptAssetManager}
          />
          <PrivateRoute
            path="/contracts"
            component={ContractsPage}
            role={[
              ApplicationRole.Admin,
              ApplicationRole.SuperSalesManager,
              ApplicationRole.SalesManager,
              ApplicationRole.RiskManager,
              ApplicationRole.SalesSupport,
              ApplicationRole.InsideSales,
            ]}
          />
          <PrivateRoute path="/settings" component={SettingsPage} role={ApplicationRole.Admin} />
          <PrivateRoute path="/puls" component={TasksPage} />
          <PrivateRoute path="/requests" component={RequestsPage} role={[ApplicationRole.Admin]} />
          <PrivateRoute path="/futureshipments" component={FutureShipmentsPage} />
          <PrivateRoute path="/" component={HomePage} />
        </Switch>
      </MissingFilterContextProvider>
    </MuiPickersUtilsProvider>
  );
};

const App = () => {
  return (
    <Suspense fallback={<PageLayout pageContent={<LoadingLinearIndicator />} />}>
      <ThemeProvider theme={themeOrange}>
        <Router>
          <QueryParamProvider ReactRouterRoute={Route}>
            <AuthenticationProvider>
              <SignalRContextProvider>
                <ApiQueryProvider>
                  <ErrorContextProvider>
                    <PagesWithLanguage />
                  </ErrorContextProvider>
                </ApiQueryProvider>
              </SignalRContextProvider>
            </AuthenticationProvider>
          </QueryParamProvider>
        </Router>
      </ThemeProvider>
    </Suspense>
  );
};

export default App;
