import { createStyles, makeStyles, TextField, Theme } from '@material-ui/core';
import { useToast } from '../Toast';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(1.5),
    },
    copy: {
      fontSize: 14,
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.default,
      lineHeight: '32px',
      padding: theme.spacing(0, 1.25),
      marginRight: 2,
      borderRadius: 2,
      cursor: 'pointer',
    },
  })
);

type DocumentInputProps = {
  label: string;
  value?: string;
};

export const DocumentInput = (props: DocumentInputProps) => {
  const classes = useStyles();
  const toast = useToast();

  const { label } = props;
  //const [value, setValue] = useState(props.value);

  const copyHandler = async () => {
    try {
      if (props.value !== undefined) {
        await navigator.clipboard.writeText(props.value);
        toast(`${label} скопирован в буфер обмена`, 'success');
      } else {
        toast(`Скопировать ${label.toLowerCase()}  не удалось`, 'error');
      }
    } catch (error) {
      toast(`Скопировать ${label.toLowerCase()} не удалось`, 'error');
      console.error('Скопировать не удалось: ', error);
    }
  };

  return (
    <TextField
      className={classes.root}
      fullWidth
      size="small"
      label={label}
      variant={'outlined'}
      value={props.value}
      // onChange={(e) => setValue(e.target.value)}
      InputProps={{
        endAdornment: props.value && (
          <div className={classes.copy} onClick={copyHandler}>
            Скопировать
          </div>
        ),
      }}
    />
  );
};
