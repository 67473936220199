import { createStyles, makeStyles } from '@material-ui/core/styles';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import { useTranslation } from 'react-i18next';
import { useIssueControlListQuery } from '../../services/api/useIssueControlBackend';
import { IssueControl as IssueControlType, IssueType } from 'schema';
import { useUpdateContolMutation } from '../../services';
import { IconCheckbox } from '../icons';
import { Checkbox as MuiCheckbox, FormControlLabel } from '@material-ui/core';
import { useState } from 'react';
import { useToast } from '../Toast';
import { useQueryClient } from 'react-query';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2.5),
    },
    container: {
      columnCount: 2,
      width: '100%',
    },
  })
);

type IssueControlProps = {
  issueType: IssueType;
  issueId: number;
  controls?: IssueControlType[];
};

export const IssueControl = (props: IssueControlProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { issueId, issueType, controls = [] } = props;

  const { data } = useIssueControlListQuery({
    types: [issueType],
  });

  const checkboxArray = data?.data.map((item) => {
    const isChecked =
      controls?.findIndex((checkbox: IssueControlType) => checkbox.id === item.id) >= 0;
    return (
      <IssueControlCheckbox control={item} issueId={issueId} isChecked={isChecked} key={item.id} />
    );
  });

  const length = data?.data.length ?? 0;

  if (length === 0) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="subtitle1">{t('Control')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <form className={classes.container}>
            <ul>{checkboxArray}</ul>
          </form>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

type IssueControlCheckboxProps = {
  control: IssueControlType;
  issueId: number;
  isChecked: boolean;
};

export const IssueControlCheckbox = (props: IssueControlCheckboxProps) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { issueId, control, isChecked } = props;
  const [checked, setChecked] = useState<boolean>(isChecked);
  const [disabled, setDisabled] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const { mutateAsync } = useUpdateContolMutation(issueId, control.id, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey.indexOf(issueId.toString()) > -1;
        },
      });
    },
  });

  const toggleCheckbox = async () => {
    setDisabled(true);
    try {
      await mutateAsync({
        isChecked: !checked,
      });
      setChecked(!checked);
    } catch (error) {
      setChecked(checked);
      toast(t('ErrorMessage'), 'error');
    }
    setDisabled(false);
  };

  return (
    <li>
      <FormControlLabel
        control={
          <MuiCheckbox
            disabled={disabled}
            checked={checked}
            name={control.id.toString()}
            checkedIcon={<IconCheckbox checked={true} />}
            icon={<IconCheckbox checked={false} />}
            onClick={toggleCheckbox}
          />
        }
        label={control.text}
      />
    </li>
  );
};
