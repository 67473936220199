import { ReactNode, useMemo, useState } from 'react';
import { LeasingProductBrandsContext } from './LeasingProductBrandsContext';

export type LeasingProductBrandsContextProviderProps = {
  children?: ReactNode;
};

export const LeasingProductBrandsContextProvider = (
  props: LeasingProductBrandsContextProviderProps
) => {
  const { children } = props;

  const [brands, setBrands] = useState<string[]>([]);

  const value = useMemo(() => {
    return { brands, setBrands, clearBrands: () => setBrands([]) };
  }, [brands, setBrands]);

  return (
    <LeasingProductBrandsContext.Provider value={value}>
      {children}
    </LeasingProductBrandsContext.Provider>
  );
};
