import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { ApplicationUserTab, QuotaListFilter } from 'schema/serverTypes';

export const useQuotaFilter = (props: QuotaListFilter) => {
  const { tabId = ApplicationUserTab.mine } = props;

  const { control, reset, setValue } = useForm<QuotaListFilter>({
    mode: 'all',
    defaultValues: {
      ...props,
    },
  });

  const onReset = useCallback(() => {
    reset({ tabId });
  }, [reset, tabId]);

  return useMemo(() => {
    return {
      control,
      onReset,
      setValue,
    };
  }, [control, onReset, setValue]);
};
