import { createStyles, makeStyles } from '@material-ui/core/styles';
import { FutureShipmentControlColor, FutureShipmentControlViewModel } from 'schema';
import { IconSprite } from '../icons';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0.25, 0.25, 0.25, 1),
      lineHeight: '16px',
      borderRadius: 10,
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: theme.palette.text.primary,
    },
    time: {
      minWidth: 16,
      minHeight: 16,
      borderRadius: 8,
      fontSize: 9,
      lineHeight: '13px',
      position: 'relative',
      padding: (props: FutureShipmentControlViewModel) => {
        return props.color === FutureShipmentControlColor.gray ||
          props.color === FutureShipmentControlColor.green
          ? 0
          : theme.spacing(0, 1);
      },
      border: '1.5px solid',
      backgroundColor: theme.palette.common.white,
      whiteSpace: 'nowrap',
      marginLeft: theme.spacing(0.5),
      borderColor: (props: FutureShipmentControlViewModel) => {
        return props.color === FutureShipmentControlColor.red
          ? theme.palette.error.main
          : props.color === FutureShipmentControlColor.yellow
          ? theme.palette.warning.light
          : props.color === FutureShipmentControlColor.green
          ? theme.palette.success.main
          : theme.palette.text.secondary;
      },
    },
    check: {
      position: 'absolute',
      top: -1.5,
      left: -1.5,
    },
  })
);

export const FutureShipmentControl = (props: FutureShipmentControlViewModel) => {
  const classes = useStyles(props);

  const { name, isComplite, timeString } = props;

  return (
    <div className={classes.root}>
      <div>{name}</div>
      <div className={classes.time}>
        {isComplite ? (
          <IconSprite width={16} icon={'checked-tick'} className={classes.check} />
        ) : (
          timeString
        )}
      </div>
    </div>
  );
};
