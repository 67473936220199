import { useTranslation } from 'react-i18next';
import { Table, TableCell, TableHead, TableRow, TableBody } from '@material-ui/core';
import {
  ShipmentListItemViewModel,
  ShipmentListRequestSortBy,
  SortOrder,
} from 'schema/serverTypes';
import { TableSortLabel } from 'components/data-grid/TableSortLabel';
import { Link } from 'react-router-dom';
import { formatDate } from 'components/utils/formatDate';
import { formatMoney } from 'components/utils';

export type ShipmentsTableProps = {
  isLoading: boolean;
  data: ShipmentListItemViewModel[];
  sorting: {
    sortBy: ShipmentListRequestSortBy;
    order: SortOrder;
    setSortBy: (sortBy: ShipmentListRequestSortBy) => void;
    setOrder: (order: SortOrder) => void;
  };
};

type TableItemProps = {
  item: ShipmentListItemViewModel;
};

const TableItem = (props: TableItemProps) => {
  const { item } = props;

  return (
    <TableRow>
      <TableCell size="medium">
        <Link to={'quotas/calculator/' + item.id}>{item.id}</Link>
      </TableCell>
      <TableCell size="medium">{item.userName}</TableCell>
      <TableCell size="medium">
        <Link to={'quotas/calculator/' + item.id}>{item.contractNumber}</Link>
      </TableCell>
      <TableCell size="medium">{formatDate(item.shipmentDate, true)}</TableCell>
      <TableCell size="medium">{formatMoney({ amount: item.nbv ?? 0 })}</TableCell>
    </TableRow>
  );
};

export const ShipmentsTable = (props: ShipmentsTableProps) => {
  const { data, sorting } = props;
  const { t } = useTranslation();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell size="medium">
            <TableSortLabel columnName="id" sorting={sorting}>
              {t('ID')}
            </TableSortLabel>
          </TableCell>
          <TableCell size="medium">
            <TableSortLabel columnName="userName" sorting={sorting}>
              {t('Owner')}
            </TableSortLabel>
          </TableCell>
          <TableCell size="medium">
            <TableSortLabel columnName="contractNumber" sorting={sorting}>
              {t('Contract number')}
            </TableSortLabel>
          </TableCell>
          <TableCell size="medium">
            <TableSortLabel columnName="shipmentDate" sorting={sorting}>
              {t('Shipping date')}
            </TableSortLabel>
          </TableCell>
          <TableCell size="medium">
            <TableSortLabel columnName="nbv" sorting={sorting}>
              {t('Nbv')}
            </TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item: ShipmentListItemViewModel, index: number) => (
          <TableItem key={index} item={item} />
        ))}
      </TableBody>
    </Table>
  );
};
