import { useEffect, useMemo } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { PaymentYearTable } from './PaymentYearTable';
import { CalculationMethod, MonthPaymentOption } from 'schema/serverTypes';
import { YearFields } from './types';
import Box from '@material-ui/core/Box';
import { useFieldArray } from 'react-hook-form';
import { useCalculationFormContext } from '../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridGap: theme.spacing(2),
    },
    table: {
      width: '100%',
    },
    header: {
      marginBottom: 0.5,
      lineHeight: 1.2,
    },
    year: {
      fontFamily: "'Halvar Breit', sans-serif",
      fontSize: 12,
      fontWeight: 700,
      color: theme.palette.secondary.dark,
      backgroundColor: theme.palette.primaryGradient[200],
      textAlign: 'center',
      lineHeight: '22px',
    },
  })
);

export type PaymentOptionListProps = {
  calculationMethod: CalculationMethod;
  numberOfMonths: number;
  date?: string;
  quotaId?: number;
  calculationMethodChange: number;
};

export const PaymentOptionList = (props: PaymentOptionListProps) => {
  const classes = useStyles();
  const { date, numberOfMonths, calculationMethod, calculationMethodChange, quotaId } = props;

  const { control, getValues, setValue } = useCalculationFormContext();

  const { fields, update } = useFieldArray({
    control: control,
    name: 'paymentOptions',
  });

  const disabled = calculationMethod === CalculationMethod.SuperSeasonal;

  const years = useMemo(() => {
    const years: YearFields[] = [];
    let currentDate = date ? new Date(date) : new Date();
    currentDate.setMonth(currentDate.getMonth() + 1);
    let current: YearFields | null = null;

    fields.map((item, index) => {
      const monthField = { item, index, disabled };

      if (disabled && index > 11) {
        return null;
      }

      if (current === null) {
        current = {
          year: currentDate.getFullYear(),
          fields: [monthField],
        };
        years.push(current);
      } else {
        const nextDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          currentDate.getDate()
        );

        if (nextDate.getFullYear() !== currentDate.getFullYear()) {
          current = {
            year: nextDate.getFullYear(),
            fields: [],
          };
          years.push(current);
        }
        current.fields.push(monthField);
        currentDate = nextDate;
      }

      return null;
    });
    return years;
  }, [disabled, fields, date]);

  useEffect(() => {
    if (
      calculationMethod !== CalculationMethod.Seasonal &&
      calculationMethod !== CalculationMethod.SuperSeasonal
    ) {
      return;
    }

    let options = getValues('paymentOptions') ?? [];
    const count = options.length;

    if (count === numberOfMonths) {
      return;
    }

    const newOptions: MonthPaymentOption[] = [];

    for (let i = 0; i < options.length; i++) {
      newOptions.push(options[i]);
    }
    if (count < numberOfMonths) {
      for (let i = count; i < numberOfMonths; i++) {
        newOptions.push({ number: i + 1, isPayment: true, isPreferential: false, value: 1.0 });
      }
    } else {
      for (let i = numberOfMonths; i < count; i++) {
        newOptions.pop();
      }
    }
    setValue('paymentOptions', newOptions);
  }, [numberOfMonths, calculationMethod, getValues, setValue]);

  return (
    <div className={classes.root}>
      {years.map((yearProps) => {
        const { year } = yearProps;
        const gridProps = {
          ...yearProps,
          numberOfMonths,
          update,
          date,
        };

        return (
          <div key={year} className={classes.table}>
            <div className={classes.header}>
              <Box className={classes.year}>{year}</Box>
            </div>
            <PaymentYearTable
              {...gridProps}
              quotaId={quotaId}
              calculationMethodChange={calculationMethodChange}
            />
          </div>
        );
      })}
    </div>
  );
};
