import { useBackendQuery, useBackendMutation, QueryOptions, MutationOptions } from '.';
import {
  CounterpartyIndustryViewModel,
  CounterpartyMissingsViewModel,
  CounterpartyPhysicViewModel,
  CounterpartyRatingAnswersViewModel,
  CounterpartyViewModel,
  DefaultTelematicsDeviceViewModel,
  TelematicsProviderViewModel,
} from 'schema';
import { BackendQueryOptions, createOptions, createUrlFromParts, EmptyResponse } from './types';

const entity = 'counterparties';

const useQuery = <TResponse>(options: BackendQueryOptions<TResponse>) => {
  let queryKey = entity;

  if (options.relativeUrl && options.relativeUrl !== '') {
    queryKey = `${queryKey}-${options.relativeUrl}`;
  }
  if (options.searchParams) {
    queryKey = `${queryKey}-${options.searchParams}`;
  }
  return useBackendQuery(
    createUrlFromParts(entity, options.relativeUrl, options.searchParams),
    queryKey,
    createOptions(options.options)
  );
};

const getMutationUrl = <TRequest>(relativeUrl: string | ((form: TRequest) => string)) => {
  if (typeof relativeUrl === 'string') {
    return createUrlFromParts(entity, relativeUrl);
  }
  return (form: TRequest) => createUrlFromParts(entity, relativeUrl(form));
};

export const useCounterpartiesBackendQuery = <TResponse>(
  relativeUrl: string,
  options?: QueryOptions<TResponse, string>
) => useQuery({ relativeUrl, options });

export const useCounterpartiesBackendMutation = <TRequest, TResponse, TContext = unknown>(
  relativeUrl: string | ((form: TRequest) => string),
  options: MutationOptions<TRequest, TResponse, TContext> | undefined = { method: 'POST' }
) => useBackendMutation(getMutationUrl(relativeUrl), options);

export const useCounterpartyQuery = (
  inn: string,
  options?: QueryOptions<CounterpartyViewModel, string>
) =>
  useQuery({
    relativeUrl: inn,
    options,
  });

export const useCounterpartyIndividualQuery = (
  inn: string,
  options?: QueryOptions<CounterpartyPhysicViewModel, string>
) =>
  useQuery({
    relativeUrl: inn,
    options,
  });

export const useCounterpartyMissingsQuery = (
  inn: string,
  options?: QueryOptions<CounterpartyMissingsViewModel, string>
) =>
  useQuery({
    relativeUrl: `${inn}/missings`,
    options,
  });

export const useRatingBackendMutation = <TRequest, TResponse, TContext = unknown>(
  relativeUrl: string | ((form: TRequest) => string),
  options: MutationOptions<TRequest, TResponse, TContext> | undefined
) => useBackendMutation(getMutationUrl(relativeUrl), options);

export const useCounterpartyRatingAnswersQuery = (
  inn: string,
  options?: QueryOptions<CounterpartyRatingAnswersViewModel, string>
) =>
  useQuery({
    relativeUrl: `${inn}/ratingAnswers`,
    options,
  });

export const useCounterpartyIndustryQuery = (
  inn: string,
  options?: QueryOptions<CounterpartyIndustryViewModel, string>
) =>
  useQuery({
    relativeUrl: `${inn}/industry`,
    options,
  });

export const useSetDefaultTelematicsDeviceMutation = (
  inn: string,
  options?: MutationOptions<DefaultTelematicsDeviceViewModel, EmptyResponse>
) =>
  useCounterpartiesBackendMutation<DefaultTelematicsDeviceViewModel, EmptyResponse>(
    `${inn}/telematics/default`,
    {
      ...options,
      method: 'PUT',
    }
  );

export const useTelematicsProvidersQuery = (
  options?: QueryOptions<TelematicsProviderViewModel[], string>
) => useQuery({ relativeUrl: 'telematics', options });
