import { useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { calculationUrl } from 'services/urls';
import { CounterpartyFilterFormValues, CounterpartyListResult } from './types';
import {
  CounterpartyActivity,
  CounterpartySortBy,
  CounterpartyStatus,
  CounterpartyType,
  CounterpartyDealerType,
  IndustryViewModel,
  RegionTierValue,
  SortOrder,
} from 'schema/serverTypes';
import { useBackendQuery } from 'services';
import { usePaging } from 'components/paging';
import { useForm, useWatch } from 'react-hook-form';
import { useSorting } from 'components/sorting/useSorting';
import { useDebounce } from 'use-debounce';
import { useLocation } from 'react-router-dom';

export type QuotaAction = 'changeOwner' | 'viewHistory';

type SearchUrlParams = {
  page: number;
  pageSize: number;
  search?: string;
  type?: CounterpartyType;
  sortBy?: CounterpartySortBy;
  order?: SortOrder;
  tier?: RegionTierValue;
  industries?: IndustryViewModel[];
  activityCode?: CounterpartyActivity[];
  status?: CounterpartyStatus;
  types?: CounterpartyDealerType[];
};

const getCounterpartyFilterName = (type: CounterpartyType) => {
  switch (type) {
    case CounterpartyType.dealer:
      return 'isDealer';
    case CounterpartyType.lessee:
      return 'isLessee';
    case CounterpartyType.lessor:
      return 'isLessor';
    case CounterpartyType.insuranceCompany:
      return 'isInsuranceCompany';
    case CounterpartyType.agent:
      return 'isAgent';
  }
};

const useSearchUrl = (searchParams: SearchUrlParams) => {
  const {
    page,
    pageSize,
    search,
    type,
    sortBy,
    order,
    tier,
    industries = [],
    activityCode = [],
    status,
    types = [],
  } = searchParams;

  return useMemo(() => {
    const searchParams = new URLSearchParams();
    searchParams.set('page', page.toString());
    searchParams.set('pageSize', pageSize.toString());

    if (search) {
      searchParams.set('search', search);
    }

    if (type) {
      const name = getCounterpartyFilterName(type);
      searchParams.set(name, 'true');
    }

    if (sortBy) {
      searchParams.set('sortBy', sortBy);
    }

    if (order) {
      searchParams.set('order', order);
    }

    if (tier) {
      searchParams.set('tier', tier);
    }

    industries.forEach((industry) => {
      searchParams.set('industryId', industry.id.toString());
    });

    activityCode.forEach((activityCode) => {
      searchParams.set('activityCode', activityCode.code.toString());
    });

    if (status) {
      searchParams.set('status', status?.toString());
    }

    types.forEach((type) => {
      searchParams.set('types', type.toString());
    });

    return `${calculationUrl}/api/v1/counterparties?${searchParams}`;
  }, [page, pageSize, search, type, order, sortBy, tier, industries, activityCode, status, types]);
};

export const useCounterpartiesData = () => {
  const { sortBy, order, setOrder, setSortBy } = useSorting({
    sortBy: CounterpartySortBy.id,
  });

  const { search: querySearch } = useLocation();
  const query = new URLSearchParams(querySearch);
  const activityCodeInitial = query.get('activityCode');
  const industryIdInitial = query.get('industryId');

  const filterParams = sessionStorage.counterpartiesFilterValues
    ? JSON.parse(sessionStorage.counterpartiesFilterValues)
    : null;

  const { control, reset } = useForm<CounterpartyFilterFormValues>({
    mode: 'all',
    defaultValues: activityCodeInitial
      ? { activityCode: [{ code: activityCodeInitial }] }
      : industryIdInitial
      ? { industries: [{ id: parseInt(industryIdInitial, 10) }] }
      : { ...filterParams },
  });

  const onReset = useCallback(() => {
    reset({
      search: undefined,
      tier: undefined,
      type: undefined,
      types: undefined,
      activityCode: undefined,
      industries: undefined,
      status: undefined,
    });
  }, [reset]);

  const searchValue = useWatch({ control, name: 'search' });
  const [search] = useDebounce(searchValue, 500);

  const tier = useWatch({ control, name: 'tier' });
  const type = useWatch({ control, name: 'type' });
  const types = useWatch({ control, name: 'types' });

  const defaultPageSize = useWatch({ control, name: 'pageSize' });
  const defaultPage = useWatch({ control, name: 'page' });
  const industries = useWatch({ control, name: 'industries' });
  const activityCode = useWatch({ control, name: 'activityCode' });
  const status = useWatch({ control, name: 'status' });

  const { page, pageSize, onPageChanged, onPageSizeChanged } = usePaging({
    pageSize: defaultPageSize,
    page: defaultPage,
  });

  const params = useMemo(() => {
    return {
      search,
      type,
      types,
      tier,
      page,
      pageSize,
      sortBy,
      order,
      industries,
      activityCode,
      status,
    };
  }, [search, type, types, tier, page, pageSize, sortBy, order, industries, activityCode, status]);

  const url = useSearchUrl(params);

  const { data, isLoading: loading } = useBackendQuery<CounterpartyListResult>(
    url,
    ['counteparties', url],
    {
      keepPreviousData: false,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    }
  );

  const rows = data?.data ?? [];

  const onMount = useRef(false);

  useLayoutEffect(() => {
    if (onMount.current) {
      onPageChanged(1);
    } else {
      onMount.current = true;
    }
  }, [search, tier, type, pageSize, onPageChanged]);

  useEffect(() => {
    sessionStorage.setItem('counterpartiesFilterValues', JSON.stringify(params));
  }, [params]);

  return {
    filter: {
      control,
      onReset,
    },
    sorting: {
      sortBy,
      order,
      setSortBy,
      setOrder,
    },
    paging: {
      pageCount: data?.pageCount ?? 0,
      totalCount: data?.totalCount ?? 0,
      page,
      pageSize,
      dataCount: rows.length,
      onPageChanged,
      onPageSizeChanged,
    },
    rows,
    loading,
  };
};
