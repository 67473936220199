import {
  FormControlLabel,
  Checkbox,
  CheckboxProps,
  makeStyles,
  Theme,
  createStyles,
  FormControlLabelProps,
} from '@material-ui/core';
import { FieldRenderProps } from 'react-final-form-hooks';
import { StyledComponentProps } from '@material-ui/core/styles';
import { IconCheckbox } from '../icons';
import { IconCheckboxCircyle } from '../icons';
import { IconCheckboxUncheckedCircyle } from '../icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '34px',
      '& .MuiFormControlLabel-label': {
        fontFamily: 'Roboto, Helvetica, Arial, san-serif',
      },
    },
  })
);

export type CheckboxFieldProps = CheckboxProps &
  Pick<FormControlLabelProps, 'labelPlacement'> &
  FieldRenderProps<boolean> &
  StyledComponentProps & {
    label?: string;
    isCircle?: boolean;
  };

export const CheckboxField = (props: CheckboxFieldProps) => {
  const classes = useStyles();
  const {
    input,
    color = 'primary',
    label,
    disabled = false,
    size = 'small',
    isCircle = false,
    meta,
    labelPlacement,
    ...rest
  } = props;

  return (
    <FormControlLabel
      className={classes.root}
      label={label}
      disabled={disabled}
      labelPlacement={labelPlacement}
      control={
        <Checkbox
          checkedIcon={
            isCircle ? (
              <IconCheckboxCircyle disabled={disabled} />
            ) : (
              <IconCheckbox checked={true} disabled={disabled} />
            )
          }
          icon={
            isCircle ? (
              <IconCheckboxUncheckedCircyle disabled={disabled} />
            ) : (
              <IconCheckbox checked={false} disabled={disabled} />
            )
          }
          {...rest}
          {...input}
          color={color}
          size={size}
        />
      }
    />
  );
};
