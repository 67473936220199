import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { useCallback } from 'react';
import { Chip } from 'components/Chips';
import { ApplicationUserViewModel, TemplateDataFilter } from 'schema/serverTypes';

export type OwnersChipsProps = {
  control: Control<TemplateDataFilter, any>;
  setValue: UseFormSetValue<TemplateDataFilter>;
  usersOptions?: ApplicationUserViewModel[];
};

export const UsersChips = (props: OwnersChipsProps) => {
  const { control, setValue, usersOptions } = props;
  const users = useWatch({ control, name: 'users' });

  const onRemove = useCallback(
    (id: string) => {
      const values = users?.filter((v) => v !== id);
      setValue('users', values);
    },
    [users, setValue]
  );

  const userChips = users?.map((value) => {
    const user = usersOptions?.find((t) => t.id === value);
    const label = `${user?.firstName ?? ''}  ${user?.lastName ?? ''}  (${user?.email})`;
    return <Chip label={label} id={value} key={`userId-${value}`} onRemove={onRemove} />;
  });

  return <>{userChips}</>;
};
