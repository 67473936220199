import { TableHeadClassKey, StyleRules } from '@material-ui/core';
import palette from '../paletteOrange';

type MuiTableCellStyles = Partial<StyleRules<TableHeadClassKey>>;

const style: MuiTableCellStyles = {
  root: {
    borderBottom: `2px solid ${palette.secondary.main}`,
    fontSize: 14,
    '& .MuiTableCell-root': {
      color: palette.text.secondary,
      textTransform: 'none',
      lineHeight: 1.2,
    },
  },
};

export default style;
