import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useIndustrySpecializationData } from './useIndustrySpecializationData';
import {
  ApplicationRole,
  IndustrySpecializationListItemViewModel,
  IndustrySpecializationSortBy,
} from 'schema/serverTypes';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TableSortLabel } from 'components/data-grid/TableSortLabel';
import { EditButton } from '../EditButton';
import { useHistory } from 'react-router';
import { Role } from '../../authentication';
import { Link } from 'react-router-dom';
import { formatNumber } from 'components/utils/formatNumber';
import { formatDate } from 'components/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: 'auto',
      maxWidth: 'calc(100vw - 190px - 48px - var(--scrollbar-width)) ',
      '& .MuiTableBody-root .MuiTableRow-root:hover .MuiTableCell-root': {
        backgroundColor: theme.palette.background.light,
      },
    },
    wrapper: {
      position: 'relative',
      overflow: 'auto',
      border: '1px solid black',
      whiteSpace: 'nowrap',
    },
    value: {
      '&:nth-child(1)': {
        minWidth: 240,
      },
      '&:nth-child(2)': {
        minWidth: 68,
      },
      '&:nth-child(3)': {
        minWidth: 240,
      },
      '&:nth-child(4)': {
        minWidth: 80,
      },
      '&:nth-child(5)': {
        minWidth: 100,
      },
      '&:nth-child(6)': {
        minWidth: 150,
      },
      '&:nth-child(7)': {
        minWidth: 80,
      },
      '&:nth-child(8)': {
        minWidth: 152,
      },
      '&:nth-child(9)': {
        minWidth: 136,
      },
    },
    actions: {
      display: 'flex',
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
  })
);

export type IndustrySpecializationTableProps = Omit<
  ReturnType<typeof useIndustrySpecializationData>,
  'paging' | 'filter'
>;

type TableItemProps = {
  row: IndustrySpecializationListItemViewModel;
};

const TableItem = (props: TableItemProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { row } = props;
  const history = useHistory();
  const editHandler = () => {
    history.push(`/dictionaries/specialization/${row.id}`);
  };

  return (
    <>
      <TableRow>
        <TableCell size="medium" className={classes.value}>
          {row.industryName}
        </TableCell>
        <TableCell size="medium" className={classes.value}>
          {row.code}
        </TableCell>
        <TableCell size="medium" className={classes.value}>
          {row.description}
        </TableCell>
        <TableCell size="medium" className={classes.value} align="right">
          <Link to={`/counterparties?activityCode=${row.code}`}>{row.count}</Link>
        </TableCell>
        <TableCell size="medium" className={classes.value} align="center">
          {row.isLicensed ? t('Yes') : t('No')}
        </TableCell>
        <TableCell size="medium" className={classes.value} align="center">
          {row.isFinanced ? t('Yes') : t('No')}
        </TableCell>
        <TableCell size="medium" className={classes.value} align="center">
          {row.isHighRisk ? t('Yes') : t('No')}
        </TableCell>
        <TableCell size="medium" className={classes.value} align="center">
          {formatNumber(row.debt ?? 0, 4)}
        </TableCell>
        <TableCell size="medium" className={classes.actions}>
          <Role role={[ApplicationRole.RiskManager, ApplicationRole.Admin]}>
            <EditButton onClick={editHandler} />
          </Role>
        </TableCell>
      </TableRow>
    </>
  );
};

export const IndustrySpecializationTable = (props: IndustrySpecializationTableProps) => {
  const classes = useStyles();
  const { data = [], sorting, debtDate = '' } = props;
  const { t } = useTranslation();

  let label = t('Industry delay on');

  if (debtDate !== '') {
    label += ` ${t('On')} ` + formatDate(debtDate);
  }

  return (
    <div className={classes.root}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell size="medium" className={classes.value}>
              <TableSortLabel
                columnName={IndustrySpecializationSortBy.IndustryName}
                sorting={sorting}
              >
                {t('Industry/Branch')}
              </TableSortLabel>
            </TableCell>
            <TableCell size="medium" className={classes.value}>
              <TableSortLabel columnName={IndustrySpecializationSortBy.Code} sorting={sorting}>
                {t('Code')}
              </TableSortLabel>
            </TableCell>
            <TableCell size="medium" className={classes.value}>
              <TableSortLabel
                columnName={IndustrySpecializationSortBy.Description}
                sorting={sorting}
              >
                {t('Description')}
              </TableSortLabel>
            </TableCell>
            <TableCell size="medium" className={classes.value} align="right">
              <TableSortLabel columnName={IndustrySpecializationSortBy.Count} sorting={sorting}>
                {t('Count of CPs')}
              </TableSortLabel>
            </TableCell>
            <TableCell size="medium" className={classes.value} align="center">
              <TableSortLabel
                columnName={IndustrySpecializationSortBy.IsLicensed}
                sorting={sorting}
              >
                {t('License')}
              </TableSortLabel>
            </TableCell>
            <TableCell size="medium" className={classes.value} align="center">
              <TableSortLabel
                columnName={IndustrySpecializationSortBy.IsFinanced}
                sorting={sorting}
              >
                {t('Finance')}
              </TableSortLabel>
            </TableCell>
            <TableCell size="medium" className={classes.value} align="center">
              <TableSortLabel columnName="isHighRisk" sorting={sorting}>
                {t('Risk')}
              </TableSortLabel>
            </TableCell>
            <TableCell size="medium" className={classes.value} align="center">
              <TableSortLabel columnName={IndustrySpecializationSortBy.Debt} sorting={sorting}>
                {label}
              </TableSortLabel>
            </TableCell>
            <TableCell size="medium" />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => (
            <TableItem key={item.code} row={item} />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
