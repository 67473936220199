import { TableClassKey, StyleRules } from '@material-ui/core';
import palette from '../paletteOrange';

const style: Partial<StyleRules<TableClassKey>> = {
  root: {
    boxShadow: `0px 0px 20px 0px rgba(56, 60, 97, 0.1);`,
    backgroundColor: palette.background.paper,
  },
};

export default style;
