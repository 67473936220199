import { ApprovalItem } from './ApprovalItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IssueCommentViewModel } from '../../../schema';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
  })
);

type ApprovalListProps = {
  scoringApprovalComments?: IssueCommentViewModel[];
};

export const ApprovalList = (props: ApprovalListProps) => {
  const classes = useStyles();
  const { scoringApprovalComments = [] } = props;

  return (
    <div className={classes.root}>
      {scoringApprovalComments.map((comment) => (
        <ApprovalItem key={`comment-${comment.id}`} {...comment} />
      ))}
    </div>
  );
};
