import {
  IndustryLeasingProductFilter,
  IndustryLeasingProductPagedList,
  IndustryLeasingProductSorting,
  PageOptions,
  AddIndustryLeasingProductRequest,
  EditIndustryLeasingProductRequest,
  IndustryLeasingProductViewModel,
} from 'schema/serverTypes';
import { BackendQueryOptions, createOptions, createUrlFromParts } from './types';
import { useBackendQuery, useBackendMutation, MutationOptions } from './useBackend';
import { useQueryClient } from 'react-query';
import { useCallback } from 'react';

const entity = 'dictionaries/industries/leasingProducts';

const useIndustryQuery = <TResponse>(options: BackendQueryOptions<TResponse>) => {
  let queryKey = entity;
  if (options.relativeUrl && options.relativeUrl !== '') {
    queryKey = `${queryKey}-${options.relativeUrl}`;
  }
  if (options.searchParams) {
    queryKey = `${queryKey}-${options.searchParams}`;
  }
  return useBackendQuery(
    createUrlFromParts(entity, options.relativeUrl, options.searchParams),
    queryKey,
    createOptions(options.options)
  );
};

type UseIndustryLeasingProductMutationProps<TRequest, TResponse> = MutationOptions<
  TRequest,
  TResponse
> & {
  relativeUrl?: string;
};

const useIndustryMutation = <TRequest, TResponse>(
  options: UseIndustryLeasingProductMutationProps<TRequest, TResponse> | undefined = undefined
) => {
  const relativeUrl = options?.relativeUrl;

  return useBackendMutation(createUrlFromParts(entity, relativeUrl), {
    method: 'POST',
    ...options,
  });
};

export type UseIndustryLeasingProductPagedListQueryProps = IndustryLeasingProductFilter &
  Partial<IndustryLeasingProductSorting> &
  PageOptions;

export const useIndustryLeasingProductPagedListQuery = (
  props: UseIndustryLeasingProductPagedListQueryProps
) => {
  const { name = '', order, sortBy, page = 1, pageSize = 20 } = props;
  let searchParams = new URLSearchParams();

  if (name !== '') {
    searchParams.append('name', name);
  }

  if (sortBy) {
    searchParams.append('sortBy', sortBy);
  }

  if (order) {
    searchParams.append('order', order);
  }

  searchParams.append('page', page.toString());
  searchParams.append('pageSize', pageSize.toString());

  return useIndustryQuery<IndustryLeasingProductPagedList>({
    searchParams,
    options: {
      refetchOnMount: true,
    },
  });
};

const useInvalidateQuery = () => {
  const queryClient = useQueryClient();

  return useCallback(() => {
    queryClient.invalidateQueries({
      predicate: (query) => {
        return query.queryKey.indexOf(entity) > -1;
      },
    });
  }, [queryClient]);
};

export const useAddIndustryLeasingProductMutation = () => {
  const invalidate = useInvalidateQuery();

  return useIndustryMutation<AddIndustryLeasingProductRequest, IndustryLeasingProductViewModel>({
    onSuccess: (response) => {
      invalidate();
    },
  });
};

export const useEditIndustryLeasingProductMutation = (id: number) => {
  const invalidate = useInvalidateQuery();

  return useIndustryMutation<EditIndustryLeasingProductRequest, IndustryLeasingProductViewModel>({
    relativeUrl: id.toString(),
    method: 'PUT',
    onSuccess: (_response) => {
      invalidate();
    },
  });
};
