import { Grid } from '@material-ui/core';
import { Paging } from '../paging';
import { useLocation, useParams } from 'react-router-dom';
import { useMissingLastImportDateRequest } from 'services/api/useMissingBackend';
import { useOverdueListData } from './useOverdueListData';
import { OverdueTable } from './OverdueTable';
import { OverdueFilterForm } from './OverdueFilterForm';
import { MissingOverdueDataGridProps } from './types';
import { ApplicationUserTab } from '../../schema';
import { PageTitle } from 'components/utils/pageTitle';
import { useTranslation } from 'react-i18next';

export const OverdueDataGrid = (props: MissingOverdueDataGridProps) => {
  const { inn } = useParams<{ inn: string }>();
  const { search, hash } = useLocation();
  const query = new URLSearchParams(search);
  const managerId = query.get('managerId');
  const managerName = query.get('managerName');
  const managerEmail = query.get('managerEmailx');
  const counterpartyInn = query.get('counterpartyInn');
  const counterpartyName = query.get('counterpartyName');

  const { t } = useTranslation();
  const pageTitle = t('Overdue') + ' - Bumblebee';
  PageTitle(pageTitle);

  const { tabId = hash === '#all' ? ApplicationUserTab.all : ApplicationUserTab.mine } = props;

  const { rows, isLoading, paging, filter, sorting } = useOverdueListData({
    managerId: managerId
      ? [{ id: managerId, name: managerId }]
      : managerEmail !== null && managerName !== null
        ? [{ id: managerEmail, name: managerName }]
        : [],
    inn: inn
      ? [{ inn, name: inn }]
      : counterpartyInn && counterpartyName
        ? [{ inn: counterpartyInn, name: counterpartyName }]
        : counterpartyInn
          ? [{ inn: counterpartyInn, name: counterpartyInn }]
          : [],
    ...props,
    tabId,
  });

  const { data: importDates } = useMissingLastImportDateRequest();
  const { control, onReset, setValue } = filter;

  return (
    <>
      <OverdueFilterForm control={control} onReset={onReset} setValue={setValue} />
      <Grid container item direction="column">
        <Grid item>
          <OverdueTable
            rows={rows}
            importDates={importDates}
            isLoading={isLoading}
            sorting={sorting}
          />
        </Grid>
        <Grid item>
          <Paging {...paging} />
        </Grid>
      </Grid>
    </>
  );
};
