import { Grid } from '@material-ui/core';
import { Paging } from 'components/paging';
import { QuotasTable } from './QuotasTable';
import { useQuotaList } from './useQuotaList';
import { QuotaFilterForm } from './QuotaFilterForm';
//import { QuotasPipeline } from '../../calculator2/QuotasPipeline';

export const QuotaDataGrid = () => {
  const { rows, isLoading, paging, sorting, filter, tabs } = useQuotaList();

  return (
    <>
      <QuotaFilterForm tabs={tabs} {...filter} />
      {/*<QuotasPipeline tabs={tabs} />*/}
      <Grid container item direction="column">
        <Grid item>
          <QuotasTable rows={rows} isLoading={isLoading} sorting={sorting} />
        </Grid>
        <Grid item>
          <Paging {...paging} />
        </Grid>
      </Grid>
    </>
  );
};
