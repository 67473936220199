import { ClickAwayListener, styled, Tooltip, TooltipProps } from '@material-ui/core';
import React, { useState } from 'react';
import { IconSprite } from '../icons';
import { themeOrange as theme } from 'theme';
import { Link } from 'react-router-dom';

export const WarningTooltip = styled(
  ({
    className,
    onOpenNomenclatures,
    taskIds,
    ...props
  }: Omit<TooltipProps, 'title' | 'children'> & {
    taskIds?: number[];
    onOpenNomenclatures: () => void;
  }) => {
    const [open, setOpen] = useState<boolean>(false);
    const toggleOpenTooltip = (ev: any) => {
      ev.stopPropagation();
      setOpen((prev) => !prev);
    };

    const handleTooltipClose = () => {
      setOpen(false);
    };

    const openModal = (ev: any) => {
      ev.stopPropagation();
      onOpenNomenclatures();
    };

    return (
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          {...props}
          title={
            taskIds && taskIds.length > 0 ? (
              <>
                <span>Номенклатура в процессе согласования.</span>
                <br />
                {taskIds.map((taskId: number, index) => {
                  return (
                    <Link key={taskId} to={`/tasks/${taskId}`}>
                      Задача №{taskId}
                      {index !== taskIds.length - 1 ? ', ' : ''}
                    </Link>
                  );
                })}
              </>
            ) : (
              <>
                <span>Неизвестная номенклатура, необходимо согласование.</span>
                <br />
                <div onClick={(ev) => openModal(ev)}>Подать заявку?</div>
              </>
            )
          }
          open={open}
          onClick={(ev) => toggleOpenTooltip(ev)}
          classes={{ popper: className }}
        >
          <div className={className}>
            <IconSprite icon="warning" width={14} color={theme.palette.primaryGradient[700]} />
          </div>
        </Tooltip>
      </ClickAwayListener>
    );
  }
)(({ theme }) => ({
  pointerEvents: 'auto',
  '& div, & a': {
    display: 'inline-block',
    marginTop: theme.spacing(0.5),
    color: theme.palette.primary.main,
  },
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: 'rgba(255, 245, 224, 1)',
    color: theme.palette.common.black,
    maxWidth: 204,
    fontSize: 12,
    padding: theme.spacing(1, 1.25),
    '& .MuiTooltip-arrow': {
      color: 'rgba(255, 245, 224, 1)',
    },
  },
}));
