import { useCallback, useMemo } from 'react';
import { Controller, FieldError, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Input } from 'components';
import { CreateFormFieldValues } from '../tasks/types';
import { useQueryClient } from 'react-query';
import { WysiwygEditor } from '../tasks/WysiwygEditor';
import clsx from 'clsx';
import { useUpdateWikiMutation } from '../../services/api/useWikiBackend';
import { UpdateWikiItemViewModel, WikiItemViewModel } from '../../schema';
import { WikiTagAutocomplete } from './WikiTagAutocomplete';
import { Box, FormHelperText } from '@material-ui/core';
import { WikiChips } from './WikiChips';
import { FileUpload2 } from './FileUpload/FileUpload2';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.bgGray.main,
      //overflow: 'hidden',
    },
    title: {
      //height: 47,
      marginBottom: theme.spacing(1),
      color: theme.palette.primary.main,
      fontWeight: 700,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& div': {
        backgroundColor: 'white',
      },
    },
    description: {
      marginBottom: theme.spacing(1.25),
      position: 'relative',
    },
    file: {
      padding: theme.spacing(1.5, 2),
      backgroundColor: 'white',
      marginBottom: theme.spacing(2),
    },
    tags: {
      padding: theme.spacing(1.5, 0),
    },
    tagsContainer: {
      display: 'inline-flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    tagsControl: {
      width: 310,
      marginRight: theme.spacing(2.5),
    },
    button: {
      display: 'block',
      margin: '4px auto 0',
    },
    required: {
      marginTop: theme.spacing(-1.5),
      color: theme.palette.text.primary,
    },
    link: {
      padding: theme.spacing(0, 2, 2),
      fontFamily: "'Halvar Breit', Helvetica, Arial, sans-serif",
      fontSize: 12,
      fontWeight: 500,
      display: 'inline-block',
      color: theme.palette.secondary.dark,
      '& a': {
        color: theme.palette.secondary.dark,
        textDecoration: 'none',
      },
    },
    actions: {
      backgroundColor: theme.palette.bgGray.main,
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%',
      padding: theme.spacing(1, 2, 1.5, 0),
    },
    actionButton: {
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
    save: {
      backgroundColor: 'white',
    },
    cancel: {
      color: theme.palette.primary.main,
      fontSize: 12,
      fontWeight: 600,
      textDecoration: 'none',
      display: 'inline-flex',
      alignItems: 'center',
      height: '100%',
    },
  })
);

export const getFormData = (values: CreateFormFieldValues) => {
  const formData = new FormData();
  if (values.title !== '') {
    formData.append('title', values.title);
  }
  if (values.description !== '') {
    formData.append('description', values.description);
  }
  if (values.file !== null) {
    formData.append('file', values.file);
  }
  return formData;
};

export type WikiEditFormProps = {
  article: WikiItemViewModel;
  editModeHandler: () => void;
};

export const WikiEditForm = (props: WikiEditFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { article, editModeHandler } = props;

  const queryClient = useQueryClient();

  const { control, handleSubmit, setValue } = useForm<UpdateWikiItemViewModel>({
    mode: 'all',
    defaultValues: {
      title: article.title ?? '',
      text: article.text ?? '',
      tags: article.tags ?? [],
      files: [],
    },
  });

  let tags = useWatch({ control, name: 'tags' });

  const onTagsChanged = useCallback(
    (values: string[]) => {
      setValue('tags', values);
    },
    [setValue]
  );

  const { mutateAsync } = useUpdateWikiMutation(article.id, {
    method: 'PUT',
    onSuccess: (_result) => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            typeof query.queryKey === 'string' && (query.queryKey as string).indexOf('wiki') > -1
          );
        },
      });
    },
  });

  const onSubmit = useMemo(() => {
    const submit = async (values: UpdateWikiItemViewModel) => {
      const article = await mutateAsync(values);
      if (article?.id) {
        editModeHandler();
      }
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync, editModeHandler]);

  const formId = `wiki-form`;

  return (
    <form id={formId} onSubmit={onSubmit}>
      <div className={classes.container}>
        <div className={classes.title}>
          <Input
            label={t('Article title')}
            control={control}
            name="title"
            rules={{
              required: {
                value: true,
                message: t('Required'),
              },
            }}
          />
        </div>
        <div style={{ position: 'relative' }}>
          <div className={classes.description}>
            <Controller
              name="text"
              control={control}
              rules={{
                maxLength: {
                  value: 10000,
                  message: t('Maximum number of characters is', { count: 10000 }),
                },
              }}
              render={({ field, fieldState }) => {
                const helperText =
                  fieldState.error !== undefined
                    ? (fieldState.error as FieldError).message
                    : undefined;

                return (
                  <>
                    <WysiwygEditor value={field.value} onChange={field.onChange} />
                    {helperText && <FormHelperText error>{helperText}</FormHelperText>}
                  </>
                );
              }}
            />
          </div>
          <div className={classes.file}>
            <FileUpload2 control={control} setValue={setValue} oldFiles={article.files} />
          </div>
        </div>
        <div className={classes.tags}>
          <div className={classes.tagsContainer}>
            <div className={classes.tagsControl}>
              <WikiTagAutocomplete tags={tags} onChange={onTagsChanged} freeSolo />
            </div>
          </div>
          <Box mb={1} mt={2}>
            <WikiChips tags={tags} onChange={onTagsChanged} />
          </Box>
        </div>
      </div>

      <div className={classes.actions}>
        <div className={classes.actionButton}>
          <Button
            className={clsx(classes.button, classes.save)}
            color="primary"
            size="medium"
            type="submit"
            variant="outlined"
          >
            {t('Save')}
          </Button>
        </div>

        <div className={classes.actionButton}>
          <Button
            className={clsx(classes.button, classes.cancel)}
            color="primary"
            size="medium"
            type="button"
            variant="text"
            onClick={editModeHandler}
          >
            {t('Cancel2')}
          </Button>
        </div>
      </div>
    </form>
  );
};
