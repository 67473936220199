import React, { createContext, ReactElement, useContext, useMemo } from 'react';
import type { CSSProperties, PropsWithChildren } from 'react';
import type { DraggableSyntheticListeners, UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Role } from '../../authentication';
import { IconSprite } from '../../icons';
import { themeOrange as theme } from 'theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dragHandler: {
      position: 'absolute',
      top: theme.spacing(0.75),
      right: theme.spacing(6.5),
      cursor: 'pointer',
      zIndex: 999,
      border: 'none',
      width: 24,
      height: 24,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 2,
      backgroundColor: theme.palette.background.default,
    },
  })
);

interface Props {
  id: UniqueIdentifier;
}

interface Context {
  attributes: Record<string, any>;
  listeners: DraggableSyntheticListeners;
  ref(node: HTMLElement | null): void;
}

const SortableItemContext = createContext<Context>({
  attributes: {},
  listeners: undefined,
  ref() {},
});

export function SortableItem({ children, id }: PropsWithChildren<Props>) {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ id });
  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef,
    }),
    [attributes, listeners, setActivatorNodeRef]
  );

  const style: CSSProperties = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
    gridColumn: `auto / span ${(children as any)[0].props.children.props.xs}`,
    position: 'relative',
  };

  return (
    <Role role={(children as ReactElement[])[0]?.props.role}>
      <SortableItemContext.Provider value={context}>
        <div ref={setNodeRef} style={style}>
          {children}
        </div>
      </SortableItemContext.Provider>
    </Role>
  );
}

export function DragHandle() {
  const classes = useStyles();
  const { attributes, listeners, ref } = useContext(SortableItemContext);

  return (
    <button className={classes.dragHandler} {...attributes} {...listeners} ref={ref}>
      <IconSprite
        icon={'dnd'}
        width={'16px'}
        height={'16px'}
        color={theme.palette.text.secondary}
      />
    </button>
  );
}
