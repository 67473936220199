import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Button, Grid } from 'components';
import { ConfirmRatingViewModel } from 'schema/serverTypes';
import { useToast } from '../../Toast';
import { useUnconfirmRatingMutation } from '../../../services/api/useRatingsBackend';
import { useQueryClient } from 'react-query';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(3),
    },
    subtitle: {
      textAlign: 'center',
      color: theme.palette.common.black,
      marginBottom: theme.spacing(3.5),
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 10,
      color: theme.palette.common.black,
    },
    button: {
      display: 'block',
      margin: '4px auto 0',
    },
    required: {
      marginTop: theme.spacing(-1.5),
      color: theme.palette.text.primary,
    },
    actions: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    actionButton: {
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
  })
);

type RatingUnConfirmFormProps = {
  inn: string;
  id: number;
  onClose: (...event: any[]) => void;
};

export const RatingUnConfirmForm = (props: RatingUnConfirmFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { inn, id, onClose } = props;
  const queryClient = useQueryClient();

  const { handleSubmit } = useForm({
    mode: 'all',
  });
  const toast = useToast();

  const { mutateAsync } = useUnconfirmRatingMutation(inn, id, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            typeof query.queryKey === 'string' && (query.queryKey as string).indexOf('ratings') >= 0
          );
        },
      });
      toast(t('ConfirmSuccessMessage'), 'success');
      onClose();
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const onSubmit = useMemo(() => {
    const submit = async (form: ConfirmRatingViewModel) => {
      await mutateAsync(form);
    };
    return handleSubmit(submit as any);
  }, [handleSubmit, mutateAsync]);

  return (
    <form id="rating-form" onSubmit={onSubmit}>
      <Typography variant={'h2'} className={classes.title}>
        {t('Unconfirmation')}
      </Typography>
      <Typography variant="subtitle2" className={classes.subtitle}>
        {`${t('Are you sure you want to remove rating approve?')}`}
      </Typography>
      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item md={24} xs={24}>
          <div className={classes.actions}>
            <div className={classes.actionButton}>
              <Button
                className={classes.button}
                color="primary"
                size="medium"
                type="submit"
                variant="contained"
              >
                {t('Remove approve')}
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};
