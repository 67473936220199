import { useTranslation } from 'react-i18next';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Button } from 'components/Button';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { useLanguage } from '../../../utils/useLanguage';
import { endOfMonth, startOfMonth } from '../../Shipment/dates';
import { useLeasingProductQuery } from '../../../calculator/QuotaDataGrid/useLeasingProductQuery';
import { ChipSelect } from '../../../Chips';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: theme.spacing(1.5, 2.5),
    },
    active: {
      backgroundColor: theme.palette.primary.light,
      pointerEvents: 'none',
      color: theme.palette.secondary.darker,
    },
    nextMonth: {
      textTransform: 'capitalize',
    },
  })
);

export interface PipelineFilterProps {
  setStartDate: (date: string | undefined) => void;
  setEndDate: (date: string | undefined) => void;
  setSalesChannel?: (date: string | undefined) => void;
}

export const PipelineFilter = (props: PipelineFilterProps) => {
  const classes = useStyles();
  const [currentFilter, setCurrentFilter] = useState<string | undefined>('thisMonth');
  const { t } = useTranslation();
  const lang = useLanguage();
  const activeClass = classes.active;
  const { setStartDate, setEndDate, setSalesChannel } = props;

  const thisMonthHandler = useCallback(() => {
    setStartDate(startOfMonth);
    setEndDate(endOfMonth);
    setCurrentFilter('thisMonth');
  }, [setStartDate, setEndDate, setCurrentFilter]);

  const nextMonthHandler = useCallback(() => {
    setStartDate(dayjs().add(1, 'month').startOf('month').toISOString());
    setEndDate(dayjs().add(1, 'month').endOf('month').toISOString());
    setCurrentFilter('nextMonth');
  }, [setStartDate, setEndDate, setCurrentFilter]);

  const thisYearHandler = useCallback(() => {
    setStartDate(dayjs().startOf('year').toISOString());
    setEndDate(dayjs().endOf('year').toISOString());
    setCurrentFilter('thisYear');
  }, [setStartDate, setEndDate, setCurrentFilter]);

  const thisMonthClass = currentFilter === null || currentFilter === 'thisMonth' ? activeClass : '';
  const nextMonthClass = clsx(classes.nextMonth, currentFilter === 'nextMonth' ? activeClass : '');
  const thisYearClass = currentFilter === 'thisYear' ? activeClass : '';

  const { data = [] } = useLeasingProductQuery();
  const options = data.map((item) => item.name);

  return (
    <Box mt={2} mb={1.25}>
      <Button variant={'chip'} className={thisMonthClass} onClick={thisMonthHandler}>
        {t('This month')}
      </Button>
      <Button variant={'chip'} className={nextMonthClass} onClick={nextMonthHandler}>
        {dayjs().add(1, 'month').locale(lang).format('MMMM')}
      </Button>
      <Button variant={'chip'} className={thisYearClass} onClick={thisYearHandler}>
        {t('This year')}
      </Button>
      {setSalesChannel && (
        <ChipSelect options={options} label={'Канал продаж'} onChange={setSalesChannel} />
      )}
    </Box>
  );
};
