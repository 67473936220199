import { Typography } from '@material-ui/core';
import { Button, Grid, Input, Switch } from 'components';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TelematicsDictionaryItemViewModel } from 'schema/serverTypes';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useQueryClient } from 'react-query';
import { useUpdateTelematicsDictionaryMutation } from '../../../services/api/useTelematicsDictionaryBackend';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(5.5),
    },
    button: {
      display: 'block',
      margin: '4px auto 0',
    },
    required: {
      marginTop: theme.spacing(-1.5),
      color: theme.palette.text.primary,
    },
  })
);

export type TelematicsEditFormProps = {
  onSuccess: () => void;
  item: TelematicsDictionaryItemViewModel;
};

const useTelematicsForm = (
  message: string,
  onSuccess: () => void,
  item: TelematicsDictionaryItemViewModel
) => {
  const { handleSubmit, control, formState, setError, clearErrors, reset, watch, setValue } =
    useForm<TelematicsDictionaryItemViewModel>({
      mode: 'onBlur',
      defaultValues: {
        ...item,
      },
    });

  const queryClient = useQueryClient();

  const { mutateAsync } = useUpdateTelematicsDictionaryMutation(item.id, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey.indexOf('telematics') > -1;
        },
      });
      onSuccess();
    },
    onError: () => {
      setError('name', { message }, { shouldFocus: true });
    },
  });

  const onSubmit = useMemo(() => {
    const submit = async (form: TelematicsDictionaryItemViewModel) => {
      await mutateAsync(form);
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync]);

  return {
    control,
    onSubmit,
    reset,
    setError,
    clearErrors,
    watch,
    setValue,
    ...formState,
  };
};

export const TelematicsEditForm = (props: TelematicsEditFormProps) => {
  const classes = useStyles();
  const { onSuccess } = props;
  const { t } = useTranslation();
  const message = t('Duplicate name');
  const { item } = props;

  const { onSubmit, isSubmitting, control } = useTelematicsForm(message, onSuccess, item);

  return (
    <form>
      <Typography variant={'h2'} className={classes.title}>
        {t('Edit')}
      </Typography>
      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item md={24} xs={24}>
          <Input
            label={t('Name')}
            control={control}
            name="name"
            rules={{
              required: {
                value: true,
                message: t('Required'),
              },
            }}
          />
        </Grid>
        <Grid item md={24} xs={24}>
          <Input
            label={t('Installation Cost')}
            control={control}
            name="installationCost"
            rules={{
              required: {
                value: true,
                message: t('Required'),
              },
            }}
          />
        </Grid>
        <Grid item md={24} xs={24}>
          <Input
            label={t('Subscription Fee')}
            control={control}
            name="subscriptionFee"
            rules={{
              required: {
                value: true,
                message: t('Required'),
              },
            }}
          />
        </Grid>
        <Grid item md={24} xs={24}>
          <Switch
            label={''}
            name="enabled"
            control={control}
            labelOn={'Включен'}
            labelOff={'Выключен'}
          />
        </Grid>
        <Grid item md={24} xs={24}>
          <Button
            className={classes.button}
            color="primary"
            size="medium"
            type="submit"
            variant="contained"
            disabled={isSubmitting}
            onClick={onSubmit}
          >
            {t('Save')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
