import { useCallback } from 'react';
import { useState } from 'react';
import { useMemo } from 'react';
import { Cof, Currency, CurrencyDictionaryViewModel } from 'schema';
import { CurrencyRatesContext } from './CurrencyRatesContext';

export const CurrencyRatesContextProvider = (props: {
  currencies: CurrencyDictionaryViewModel;
  cofs: Cof[];
  children: React.ReactNode;
}) => {
  const { children, currencies, cofs } = props;

  const [currencyLease, setCurrencyLease] = useState<string>(Currency.Ruble);
  const [currencySale, setCurrencySale] = useState<string>(Currency.Ruble);

  const setLeaseCurrency = useCallback(
    (value: string) => {
      setCurrencyLease(value);
    },
    [setCurrencyLease]
  );

  const setSaleCurrency = useCallback(
    (value: string) => {
      setCurrencySale(value);
    },
    [setCurrencySale]
  );

  const value = useMemo(() => {
    return {
      data: currencies,
      exchangeRates: currencies?.exchangeRates ?? [],
      currencyLease,
      currencySale,
      cofs,
      setLeaseCurrency,
      setSaleCurrency,
    };
  }, [currencyLease, currencySale, setLeaseCurrency, setSaleCurrency, currencies, cofs]);

  return <CurrencyRatesContext.Provider value={value}>{children}</CurrencyRatesContext.Provider>;
};
