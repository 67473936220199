import { SwitchField } from 'components/form/SwitchField';
import { Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useTranslation } from 'react-i18next';
import { FormFieldsProps } from './types';
import { useRef } from 'react';

export type VatSwitchProps = FormFieldsProps;

export const VatSwitch = (props: VatSwitchProps) => {
  const { t } = useTranslation();

  const { form } = props;

  const vatValue = useRef(form.getFieldState('vatPercents')?.value ?? 20);

  return (
    <>
      <OnChange name="vatEnabled">
        {(value: boolean) => {
          const vat = form.getFieldState('vatPercents')?.value ?? 20;

          if (vat !== vatValue.current && vat > 0) {
            vatValue.current = vat;
          }

          if (!value) {
            form.change('vatPercents', 0);
          } else {
            form.change('vatPercents', vatValue.current);
          }
        }}
      </OnChange>
      <Field
        name="vatEnabled"
        label={t('Vat')}
        type="checkbox"
        component={SwitchField}
        labelOn=""
        labelOff=""
      />
    </>
  );
};
