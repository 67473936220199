import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { Grid } from 'components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: '0 1 auto',
      '& > div > div': {
        height: '52.63px',
        borderBottom: '1px solid #E3E6F3',
        display: 'flex',
        alignItems: 'center',
      },
    },
    label: {
      paddingRight: 34,
      position: 'relative',
      zIndex: 1,
      '&:hover': {
        '& $infoIcon': {
          display: 'inline-flex',
        },
      },
    },
    infoIcon: {
      display: 'none',
      position: 'absolute',
      justifyContent: 'center',
      alignItems: 'center',
      top: '50%',
      right: 12,
      transform: 'translateY(-50%)',
      color: theme.palette.common.black,
      border: `1px solid ${theme.palette.common.black}`,
      borderRadius: '50%',
      width: 16,
      height: 16,
      fontSize: 10,
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        '& $infoText': {
          display: 'inline-block',
        },
      },
    },
    infoText: {
      display: 'none',
      position: 'absolute',
      bottom: 22,
      zIndex: 5,
      width: 'max-content',
      maxWidth: 440,
      backgroundColor: 'white',
      color: theme.palette.common.black,
      fontSize: 10,
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: 4,
      padding: theme.spacing(1),
      '& > ol': {
        paddingLeft: 12,
        '& > li': {
          listStyle: 'auto',
          marginBottom: 4,
        },
      },
      '&:after': {
        content: "''",
        display: 'block',
        position: 'absolute',
        width: 8,
        height: 8,
        bottom: -5,
        left: 'calc(50% - 4px)',
        backgroundColor: 'white',
        borderRight: `1px solid ${theme.palette.secondary.main}`,
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
        transform: 'rotate(45deg)',
      },
    },
  })
);

export const RatingOptionalLabels = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container rowSpacing={0}>
        <Grid item xs={24} className={classes.label}>
          Как компания управляет сырьевым, валютным и процентным рисками?
        </Grid>
        <Grid item xs={24} className={classes.label}>
          Как компания работает в соответствии с планом?
        </Grid>
        <Grid item xs={24} className={classes.label}>
          Есть ли у компании доступ к другим источникам финансирования?
        </Grid>
        <Grid item xs={24} className={classes.label}>
          Зависит ли компания от одного или нескольких ключевых лиц?
        </Grid>
        <Grid item xs={24} className={classes.label}>
          Как бы вы охарактеризовали рынок для отрасли, в которой работает клиент?
        </Grid>
        <Grid item xs={24} className={classes.label}>
          Сколько лет компания является вашим клиентом?
        </Grid>
        <Grid item xs={24} className={classes.label}>
          Насколько диверсифицирована деятельность компании в части рынка, регионов и продукции?
        </Grid>
        <Grid item xs={24} className={classes.label}>
          Как бы вы охарактеризовали информационные системы компании в части своевременного
          предоставления качественной информации?
        </Grid>
        <Grid item xs={24} className={classes.label}>
          Могут ли забалансовые обязательства оказать существенное влияние на финансовое положение
          компании?
        </Grid>
        <Grid item xs={24} className={classes.label}>
          Каков уровень международных операций?
        </Grid>
        <Grid item xs={24} className={classes.label}>
          Какой вид основной деятельности?
          <div className={classes.infoIcon}>
            <div>i</div>
            <div className={classes.infoText}>
              Список будет изменён в дальнейшем в соответствии с группировками "отрасль клиента" в
              карточке контрагента
            </div>
          </div>
        </Grid>
        <Grid item xs={24} className={classes.label}>
          Какой возраст компании?
        </Grid>
      </Grid>
    </div>
  );
};
