import { Typography } from '@material-ui/core';
import { Button, Grid, Input } from 'components';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { WikiTagViewModel } from 'schema/serverTypes';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useUpdateWikiTagMutation } from 'services/api/useWikiBackend';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(5.5),
    },
    button: {
      display: 'block',
      margin: '4px auto 0',
    },
    required: {
      marginTop: theme.spacing(-1.5),
      color: theme.palette.text.primary,
    },
  })
);

export type WikiTagsFormProps = {
  onSuccess: () => void;
  item: WikiTagViewModel;
};

const useWikiItemForm = (message: string, onSuccess: () => void, item: WikiTagViewModel) => {
  const { handleSubmit, control, formState, setError, clearErrors, reset } =
    useForm<WikiTagViewModel>({
      mode: 'onBlur',
      defaultValues: item,
    });

  const { mutateAsync } = useUpdateWikiTagMutation(item.id);

  const onSubmit = useMemo(() => {
    const submit = async (form: WikiTagViewModel) => {
      try {
        await mutateAsync(form);
        onSuccess();
      } catch (error) {
        setError('text', { message }, { shouldFocus: true });
      }
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync, setError, message, onSuccess]);

  return {
    control,
    onSubmit,
    reset,
    setError,
    clearErrors,
    ...formState,
  };
};

export const EditWikiTagForm = (props: WikiTagsFormProps) => {
  const classes = useStyles();
  const { onSuccess, item } = props;
  const { t } = useTranslation();
  const message = t('Could not update');
  const { onSubmit, isSubmitting, control } = useWikiItemForm(message, onSuccess, item);

  return (
    <form>
      <Typography variant={'h2'} className={classes.title}>
        {t('Update note')}
      </Typography>
      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item md={24} xs={24}>
          <Input
            label={t('Name')}
            control={control}
            name="text"
            rules={{
              required: {
                value: true,
                message: t('Required'),
              },
            }}
          />
        </Grid>
        <Grid item md={24} xs={24}>
          <Button
            className={classes.button}
            color="primary"
            size="medium"
            type="submit"
            variant="contained"
            disabled={isSubmitting}
            onClick={onSubmit}
          >
            {t('Save')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
