import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { CounterpartyDealerType, CounterpartyType, RegionTierValue } from 'schema/serverTypes';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@material-ui/core';
import { Input, Select } from 'components/form';
import { Grid, IconSprite, Button } from 'components';
import { themeOrange as theme } from 'theme';
import { useCounterpartiesData } from '../useCounterpartiesData';
import { IndustryAutocomplete } from './IndustryAutocomplete';
import { ActivityCodeAutocomplete } from './ActivityCodeAutocomplete';
import { CounterpartyStatusAutocomplete } from './CounterpartyStatusAutocomplete';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    item: {
      marginRight: theme.spacing(2.5),
    },
    buttonReset: {
      display: 'flex',
      alignItems: 'flex-end',
      marginBottom: theme.spacing(0.2),
    },
  })
);

export type CounterpartiesFilterFormProps = ReturnType<typeof useCounterpartiesData>['filter'];

export const CounterpartiesFilterForm = (props: CounterpartiesFilterFormProps) => {
  const { control, onReset } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <form className={classes.root}>
      <Grid xs={24} container>
        <Grid md={6} xs={24} lg={5} item className={classes.item}>
          <Select label={t('Type')} name="type" control={control} variant="standard">
            <MenuItem value={CounterpartyType.dealer}>{t('Dealer')}</MenuItem>
            <MenuItem value={CounterpartyType.lessee}>{t('Lessee')}</MenuItem>
            <MenuItem value={CounterpartyType.lessor}>{t('Lessor')}</MenuItem>
            <MenuItem value={CounterpartyType.insuranceCompany}>{t('InsuranceCompany')}</MenuItem>
            <MenuItem value={CounterpartyType.agent}>{t('Agent')}</MenuItem>
          </Select>
        </Grid>
        <Grid md={6} xs={24} lg={5} item className={classes.item}>
          <Select
            label={t('CounterpartyType')}
            name="types"
            control={control}
            variant="standard"
            multiple={true}
          >
            <MenuItem value={CounterpartyDealerType.IsVendorSupplier}>
              {t('IsVendorSupplier')}
            </MenuItem>
            <MenuItem value={CounterpartyDealerType.IsSupportSupplier}>
              {t('IsSupportSupplier')}
            </MenuItem>
          </Select>
        </Grid>
        <Grid md={7} xs={24} lg={6} item className={classes.item}>
          <IndustryAutocomplete control={control} />
        </Grid>
        <Grid md={4} xs={24} lg={3} item className={classes.item}>
          <Select label={t('Tier')} name="tier" control={control} variant="standard">
            <MenuItem value={RegionTierValue.tier1}>{RegionTierValue.tier1.toUpperCase()}</MenuItem>
            <MenuItem value={RegionTierValue.tier2}>{RegionTierValue.tier2.toUpperCase()}</MenuItem>
            <MenuItem value={RegionTierValue.tier3}>{RegionTierValue.tier3.toUpperCase()}</MenuItem>
          </Select>
        </Grid>
        <Grid md={6} xs={24} lg={5} item className={classes.item}>
          <ActivityCodeAutocomplete control={control} />
        </Grid>
        <Grid md={6} xs={24} lg={5} item className={classes.item}>
          <CounterpartyStatusAutocomplete control={control} />
        </Grid>
        <Grid md={6} xs={24} lg={6} item className={classes.item}>
          <Input
            label={`${t('Search')}`}
            control={control}
            name="search"
            variant="standard"
            InputProps={{
              endAdornment: (
                <IconSprite width="16px" color={theme.palette.text.primary} icon="search" />
              ),
            }}
          />
        </Grid>
        <Grid md={5} xs={24} lg={3} className={classes.buttonReset} item>
          <FormControl>
            <Button variant="text" onClick={onReset}>
              {t('Reset')}
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
};
