import { useTranslation } from 'react-i18next';
import { CalculatorFormValues, useCalculationFormContext } from '../types';
import { useCounterpartiesBackendQuery } from 'services';
import { CounterpartyPhysicViewModel, CounterpartyViewModel } from 'schema';
import { Autocomplete, IconSprite, PagedList } from 'components';
import React, { useCallback, useState } from 'react';
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
} from '@material-ui/lab/useAutocomplete';
import { useController } from 'react-hook-form';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import { useDebounce } from 'use-debounce';
import { Box, InputAdornment } from '@material-ui/core';
import { themeOrange as theme } from '../../../../theme';

export const useCounterpartySearchQuery = (input: string, type?: string) => {
  const [search] = useDebounce(input ?? '', 500);

  const searchParams = new URLSearchParams();
  if (search.length > 0) {
    searchParams.set('search', search);
  }
  if (type) {
    searchParams.set(type, 'true');
  }
  const requestUrl = `?${searchParams}`;

  return useCounterpartiesBackendQuery<PagedList<CounterpartyViewModel>>(requestUrl);
};

const getOptionLabel = (option: CounterpartyViewModel | CounterpartyPhysicViewModel | null) => {
  if (option === null) {
    return '';
  }
  return option.name ? `${option.inn} (${option.name})` : `${option.inn} (физлицо)`;
};

const getOptionSelected = (
  option: CounterpartyViewModel | CounterpartyPhysicViewModel | null,
  value: CounterpartyViewModel | CounterpartyPhysicViewModel | null
) => {
  if (option === null || value === null) {
    return false;
  }
  return option.inn === value.inn;
};

export const CounterpartyAutocomplete = ({
  name,
  type,
  label,
}: {
  type?: string;
  name: string;
  label?: string;
}) => {
  const { t } = useTranslation();
  const { control } = useCalculationFormContext();
  const [input, setInput] = useState('');
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController<CalculatorFormValues, any>({
    control,
    name,
  });
  const { data } = useCounterpartySearchQuery(input, type);

  const options = data?.data ?? [];

  const handleOnInputChange = useCallback(
    (_ev: React.ChangeEvent<{}>, value: string, _reason: AutocompleteInputChangeReason) => {
      setInput(value);
    },
    [setInput]
  );

  const handleOnChange = useCallback(
    (
      _e: React.ChangeEvent<{}>,
      value: CounterpartyViewModel | CounterpartyPhysicViewModel | null,
      _reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<
        CounterpartyViewModel | CounterpartyPhysicViewModel | null
      >
    ) => {
      if (value === null) {
        onChange('');
      } else {
        onChange(value.inn);
      }
    },
    [onChange]
  );

  const selectedOption = options.find((t) => t.inn === value) ?? null;

  const iconHoverColor = value ? theme.palette.primaryGradient[700] : theme.palette.text.primary;
  const iconView = (
    <Box p={1} pt={1.4} pr={0}>
      <IconSprite
        icon={'view'}
        width={15}
        height={10}
        color={theme.palette.text.primary}
        hoverColor={iconHoverColor}
      />
    </Box>
  );
  const counterpartyLink = value ? `/counterparties/${value}` : undefined;

  return (
    <Autocomplete<CounterpartyViewModel | CounterpartyPhysicViewModel | null>
      label={label ? label : t('CommissionRecipient')}
      options={options}
      inputValue={input}
      onInputChange={handleOnInputChange}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      onChange={handleOnChange}
      variant="outlined"
      value={selectedOption}
      error={error !== undefined}
      popupIcon={<KeyboardArrowDownRoundedIcon color="inherit" fontSize="medium" />}
      InputProps={{
        startAdornment: counterpartyLink ? (
          <>
            <InputAdornment position="start">
              <a href={counterpartyLink} target="_blank" rel="noreferrer">
                {iconView}
              </a>
            </InputAdornment>
          </>
        ) : null,
      }}
    />
  );
};
