import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import { ContractListViewModel, ContractSortBy } from 'schema/serverTypes';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { IconSprite } from '../icons';
import { themeOrange as theme } from 'theme';
import { TableSortLabel } from '../data-grid/TableSortLabel';
import { User } from '../users';

const useStyles = makeStyles((theme) =>
  createStyles({
    table: {
      backgroundColor: theme.palette.common.white,
      fontSize: 12,
      color: theme.palette.common.black,
      display: 'grid',
      gridTemplateColumns: '116px 90px 5fr 4fr 3fr 26px 40px',
    },
    th: {
      fontSize: 12,
      color: theme.palette.text.secondary,
      fontWeight: 700,
      display: 'flex',
      alignItems: 'center',
      paddingTop: 16,
      paddingBottom: 16,
      paddingLeft: 6,
      paddingRight: 6,
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
    },
    right: {
      textAlign: 'right',
      justifyContent: 'flex-end',
    },
    td: {
      paddingLeft: 6,
      paddingRight: 6,
      '& > a': {
        color: theme.palette.secondary.dark,
        '&:hover': {
          textDecoration: 'underline',
          color: theme.palette.primary.main,
        },
      },
    },
    rowWrapper: {
      display: 'contents',
      backgroundColor: 'green',
      '&:hover > div': {
        backgroundColor: theme.palette.background.light,
      },
      '& > div': {
        paddingTop: 16,
        paddingBottom: 16,
        borderBottom: `2px solid ${theme.palette.background.default}`,
      },
    },
    contractNumber: {
      paddingLeft: 20,
      paddingRight: 6,
      '& > a': {
        color: theme.palette.secondary.dark,
        '&:hover': {
          textDecoration: 'underline',
          color: theme.palette.primary.main,
        },
      },
    },
    contractStatus: {
      color: theme.palette.text.secondary,
      fontSize: '11px',
    },
    debt: {
      textAlign: 'center',
    },
    comments: {
      paddingRight: 20,
      paddingLeft: 6,
      position: 'relative',
      '&:after': {
        content: '""',
        pointerEvents: 'none',
        display: 'block',
        position: 'absolute',
        left: 6,
        top: 0,
        borderRadius: '50%',
        backgroundColor: 'transparent',
        width: 6,
        height: 6,
      },
      '&$notCommented:after': {
        backgroundColor: theme.palette.error.main,
      },
      '&$commented:after': {
        backgroundColor: theme.palette.green.main,
      },
    },
    notCommented: {},
    commented: {},
    received: {},
    updated: {
      textAlign: 'right',
      fontSize: 10,
      color: theme.palette.text.secondary,
      margin: theme.spacing(2, 0, 0),
    },
    ok: {
      color: theme.palette.green.main,
    },
    star: {
      marginLeft: -23,
      marginRight: 10,
    },
    statusOk: {
      color: theme.palette.green.main,
    },
    managers: {
      display: 'flex',
      marginTop: theme.spacing(1.5),
    },
    managerName: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(0.6),
      maxHeight: 14,
      fontSize: 12,
      lineHeight: 1.1,
      color: theme.palette.text.primary,
      display: 'flex',
      alignItems: 'center',
      borderLeft: '1px solid ' + theme.palette.secondary.main,
      flexShrink: 0,
    },
  })
);

type ContractsTableProps = {
  rows: ContractListViewModel[];
  isLoading: boolean;
  sorting: any;
};

export const ContractsTable = (props: ContractsTableProps) => {
  const { rows, sorting } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <div className={classes.table}>
        <div className={clsx(classes.th, classes.right)}>{t('Contract')}</div>
        <div className={clsx(classes.th, classes.right)}>
          <TableSortLabel columnName={ContractSortBy.Date} sorting={sorting}>
            {t('From date')}
          </TableSortLabel>
        </div>
        <div className={clsx(classes.th)}>{t('Lessee')}</div>
        <div className={clsx(classes.th)}>{t('Dealer')}</div>
        <div className={clsx(classes.th)}>{t('Status')}</div>
        <div className={classes.th} />
        <div className={classes.th} />
        {rows.map((row: ContractListViewModel) => {
          return <TableItem key={row.contractNumber} values={row} />;
        })}
      </div>
    </>
  );
};

const TableItem = ({ values }: { values: ContractListViewModel }) => {
  const classes = useStyles();
  const {
    contractNumber,
    date,
    lessee,
    dealer,
    quotaId,
    user,
    assignee,
    status = '—',
    contractStatus,
    taskId,
  } = values;
  const { pathname } = useLocation();
  const statusClassName =
    contractStatus === 'commented'
      ? classes.commented
      : contractStatus === 'notCommented'
      ? classes.notCommented
      : classes.received;

  return (
    <div className={classes.rowWrapper}>
      <div className={clsx(classes.contractNumber, classes.right)}>
        {quotaId && (
          <Link to={`/quotas/calculator/${quotaId}`} target="_blank" rel="noopener">
            {contractNumber}
          </Link>
        )}
        {quotaId === undefined && <>{contractNumber}</>}
      </div>
      <div className={clsx(classes.td, classes.right)}>{dayjs(date).format('DD.MM.YYYY')}</div>
      <div className={classes.td}>
        {lessee && (
          <>
            <Link to={`/counterparties/${lessee.inn}`} target="_blank" rel="noopener">
              {lessee.name}
            </Link>
            <div className={classes.managers}>
              <Typography component="span" variant={'body2'} className={classes.managerName}>
                <User name={user.name} isActive={user.isActive} />
              </Typography>
              {assignee && (
                <Typography component="span" variant={'body2'} className={classes.managerName}>
                  {assignee.name}
                </Typography>
              )}
            </div>
          </>
        )}
      </div>
      <div className={clsx(classes.td)}>
        {dealer && (
          <>
            <Link to={`/counterparties/${dealer.inn}`} target="_blank" rel="noopener">
              {dealer.name}
            </Link>
            <br />
            <br />
          </>
        )}
      </div>
      <div className={clsx(classes.td)}>{status}</div>
      <div className={clsx(classes.td)}>
        {taskId && (
          <Link to={`/tasks/${taskId}`}>
            <IconSprite icon="tasks" width="14px" color={theme.palette.text.secondary} />
          </Link>
        )}
      </div>
      <div className={clsx(classes.comments, statusClassName)}>
        <Link
          to={`${pathname}/comments?quotaId=${quotaId}&dealerName=${lessee?.name}&contractNumber=${contractNumber}`}
        >
          <IconSprite icon="chat" width="14px" color={theme.palette.text.secondary} />
        </Link>
      </div>
    </div>
  );
};
