import { useCallback } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'inline-block',
      background: theme.palette.background.paper,
      border: '1px solid ' + theme.palette.primaryGradient[200],
      borderRadius: 5,
      fontWeight: 400,
      fontSize: 12,
      color: theme.palette.text.primary,
      padding: theme.spacing(0.5, 3.2, 0.5, 1),
      margin: theme.spacing(0, 1.5, 1, 0),
      position: 'relative',
      cursor: 'pointer',
      '&$disabled': {
        cursor: 'default',
        color: theme.palette.text.disabled,
        border: '1px solid ' + theme.palette.text.disabled,
        '&:hover': {
          backgroundColor: theme.palette.background.paper,
          '& $delete': {
            '&:after, &:before': {
              backgroundColor: theme.palette.text.secondary,
            },
          },
        },
      },
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
        '& $delete': {
          '&:after, &:before': {
            backgroundColor: theme.palette.text.primary,
          },
        },
      },
    },
    disabled: {},
    delete: {
      '&:after, &:before': {
        content: '""',
        position: 'absolute',
        display: 'block',
        backgroundColor: theme.palette.text.secondary,
        width: 10,
        height: 1,
        right: 8,
        top: 11,
        transform: 'rotate(45deg)',
      },
      '&:before': {
        transform: 'rotate(-45deg)',
      },
    },
  })
);

type ChipProps = {
  label: string;
  id?: string;
  onRemove?: (label: string) => void;
  disabled?: boolean;
};

export const Chip = (props: ChipProps) => {
  const { label, id, onRemove, disabled = false } = props;
  const classes = useStyles();

  const onRemoveHandler = useCallback(() => {
    if (!onRemove) return false;

    if (id) {
      onRemove(id);
    } else {
      onRemove(label);
    }
  }, [label, id, onRemove]);

  return (
    <div
      className={clsx(classes.root, disabled ? classes.disabled : null)}
      onClick={onRemoveHandler}
    >
      <div>{label}</div>
      <div className={classes.delete} />
    </div>
  );
};
