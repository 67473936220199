import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Button, DatePicker, Grid, Input } from 'components/index';
import { useOrderTelematicsMutation } from '../../../services/api';
import { OrderTelematicsViewModel } from 'schema/serverTypes';
import { useToast } from 'components/index';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(5.5),
    },
    button: {
      display: 'block',
      margin: '4px auto 0',
    },
    required: {
      marginTop: theme.spacing(-1.5),
      color: theme.palette.text.primary,
    },
    actions: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    actionButton: {
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
  })
);

export type TelematicsOrderFormProps = {
  quotaId: number;
  shipmentId: number;
  onSuccess: () => void;
};

export const TelematicsOrderForm = (props: TelematicsOrderFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { quotaId = 0, shipmentId = 0 } = props;

  const defaultValues: OrderTelematicsViewModel = {
    address: '',
    contactPerson: '',
    installationDate: '',
    contactPhone: '',
    comment: '',
    link: {
      url: `/quotas/calculator/${quotaId}`,
      title: `№${quotaId}`,
    },
  };

  const { control, handleSubmit } = useForm({
    defaultValues,
    mode: 'all',
  });
  const toast = useToast();
  const { push } = useHistory();

  const { mutateAsync } = useOrderTelematicsMutation(quotaId, shipmentId, {
    onSuccess: () => {
      toast(t('TelematicsSuccessMessage'), 'success');
      push(`/tasks`);
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const onSubmit = useMemo(() => {
    const submit = async (form: OrderTelematicsViewModel) => {
      await mutateAsync(form);
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync]);

  const message = t('Required');

  return (
    <form id="telematics-form" onSubmit={onSubmit}>
      <Typography variant={'h2'} className={classes.title}>
        {t('Order telematics')}
      </Typography>
      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item md={24} xs={24}>
          <Input
            label={t('Parking address')}
            control={control}
            name="address"
            rules={{
              required: {
                value: true,
                message,
              },
            }}
          />
        </Grid>
        <Grid item md={24} xs={24}>
          <Input
            label={t('Contact person at the parking')}
            control={control}
            name="contactPerson"
            rules={{
              required: {
                value: true,
                message,
              },
            }}
          />
        </Grid>
        <Grid item md={12} xs={24}>
          <DatePicker
            label={`${t('Installation date')}`}
            name="installationDate"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('Required'),
              },
            }}
          />
        </Grid>
        <Grid item md={12} xs={24}>
          <Input
            label={t('Phone')}
            control={control}
            name="contactPhone"
            rules={{
              required: {
                value: true,
                message,
              },
            }}
          />
        </Grid>
        <Grid item md={24} xs={24}>
          <Input label={t('Comment')} control={control} name="comment" multiline={true} />
        </Grid>
        <Grid item md={24} xs={24}>
          <div className={classes.actions}>
            <div className={classes.actionButton}>
              <Button
                className={classes.button}
                color="primary"
                size="medium"
                type="submit"
                variant="contained"
              >
                {t('Order')}
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};
