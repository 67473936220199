import { Breadcrumbs, Button, Grid, Input, Radio, Select } from 'components';
import { useForm } from 'react-hook-form';
import { IndustryViewModel } from 'schema';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useEditIndustryMutation } from '../../../services/api/useIndustryBackend';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import { useHistory } from 'react-router';
import { FormValues } from './types';
import { MenuItem } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    back: {
      marginBottom: theme.spacing(1.5),
    },
    span: {
      fontSize: 12,
      color: theme.palette.text.primary,
      marginLeft: theme.spacing(1.6),
    },
    form: {
      width: '100%',
    },
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(5.5),
    },
    required: {
      marginTop: theme.spacing(-1.5),
      color: theme.palette.text.primary,
    },
    horizontalRadio: {
      display: 'flex',
      alignItems: 'center',
      '& $legend': {
        marginTop: -6,
        fontSize: 14,
        color: theme.palette.text.primary,
      },
    },
    legend: {
      fontSize: 12,
      color: theme.palette.text.secondary,
      padding: '0 0 4px',
    },
    actions: {
      marginTop: theme.spacing(3),
      display: 'flex',
    },
    button: {
      display: 'block',
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
  })
);

export type IndustryEditFormProps = {
  industry: IndustryViewModel;
};

export const IndustryEditForm = (props: IndustryEditFormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { industry } = props;
  const { parameterEstimation, raks, ...rest } = industry;

  const { handleSubmit, control, formState } = useForm<FormValues>({
    mode: 'onBlur',
    defaultValues: {
      ...rest,
      parameterEstimation: parameterEstimation ? 'true' : 'false',
      raks: raks ? 'true' : 'false',
    },
  });

  const { mutateAsync } = useEditIndustryMutation(industry.id);

  const onSubmit = useMemo(() => {
    const submit = async (form: FormValues) => {
      const { parameterEstimation, raks, ...request } = form;
      await mutateAsync({
        ...request,
        parameterEstimation: parameterEstimation === 'true',
        raks: raks === 'true',
      });
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync]);

  const history = useHistory();
  const cancelHandler = () => {
    history.push('/dictionaries/industries');
  };

  return (
    <>
      <Breadcrumbs link={'/dictionaries/industries'} text={t('Industries')} />
      <Accordion defaultExpanded={true}>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="subtitle1">{t('Edit note')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <form className={classes.form}>
            <Grid container columnSpacing={2} rowSpacing={2.5}>
              <Grid item md={24} xs={24}>
                <Input
                  label={t('Industry/Branch')}
                  control={control}
                  name="name"
                  rules={{
                    required: {
                      value: true,
                      message: t('Required'),
                    },
                  }}
                />
              </Grid>
              <Grid item md={24} xs={24} className={classes.horizontalRadio}>
                <legend className={classes.legend}>
                  {t('An assessment of the parameters for the industry is required')}
                </legend>
                <Radio
                  name={'parameterEstimation'}
                  control={control}
                  options={[
                    { label: t('Yes'), value: 'true' },
                    { label: t('No'), value: 'false' },
                  ]}
                />
              </Grid>
              <Grid item md={24} xs={24} className={classes.horizontalRadio}>
                <legend className={classes.legend}>{t('Raks')}</legend>
                <Radio
                  name={'raks'}
                  control={control}
                  options={[
                    { label: t('Yes'), value: 'true' },
                    { label: t('No'), value: 'false' },
                  ]}
                />
              </Grid>
              <Grid item md={12} xs={24}>
                <Select name="answer1" control={control} label={t('Concentration')}>
                  <MenuItem value={''}>&nbsp;</MenuItem>
                  <MenuItem value={'high'}>{t('Answer1.High')}</MenuItem>
                  <MenuItem value={'medium'}>{t('Answer1.Medium')}</MenuItem>
                  <MenuItem value={'low'}>{t('Answer1.Low')}</MenuItem>
                </Select>
              </Grid>
              <Grid item md={12} xs={24}>
                <Select name="answer2" control={control} label={t('Development impact')}>
                  <MenuItem value={''}>&nbsp;</MenuItem>
                  <MenuItem value={'positively'}>{t('Answer2.Positively')}</MenuItem>
                  <MenuItem value={'neutrally'}>{t('Answer2.Neutrally')}</MenuItem>
                  <MenuItem value={'negatively'}>{t('Answer2.Negatively')}</MenuItem>
                </Select>
              </Grid>
              <Grid item md={12} xs={24}>
                <Select name="answer3" control={control} label={t('Comparison with competitors')}>
                  <MenuItem value={''}>&nbsp;</MenuItem>
                  <MenuItem value={'outperformsCompetition'}>
                    {t('Answer3.OutperformsCompetition')}
                  </MenuItem>
                  <MenuItem value={'roughtlyInLineWithCompetition'}>
                    {t('Answer3.RoughtlyInLineWithCompetition')}
                  </MenuItem>
                  <MenuItem value={'dontKnow'}>{t('Answer3.DontKnow')}</MenuItem>
                  <MenuItem value={'oftenBelowCompetition'}>
                    {t('Answer3.OftenBelowCompetition')}
                  </MenuItem>
                </Select>
              </Grid>
              <Grid item md={12} xs={24}>
                <Select name="answer4" control={control} label={t('Volatility')}>
                  <MenuItem value={''}>&nbsp;</MenuItem>
                  <MenuItem value={'stable'}>{t('Answer4.Stable')}</MenuItem>
                  <MenuItem value={'slightlyVolatile'}>{t('Answer4.SlightlyVolatile')}</MenuItem>
                  <MenuItem value={'moderateVolatile'}>{t('Answer4.ModerateVolatile')}</MenuItem>
                  <MenuItem value={'highlyVolatile'}>{t('Answer4.HighlyVolatile')}</MenuItem>
                </Select>
              </Grid>
              <Grid item md={12} xs={24}>
                <Select name="answer5" control={control} label={t('Quality of management')}>
                  <MenuItem value={''}>&nbsp;</MenuItem>
                  <MenuItem value={'veryGood'}>{t('Answer5.VeryGood')}</MenuItem>
                  <MenuItem value={'good'}>{t('Answer5.Good')}</MenuItem>
                  <MenuItem value={'average'}>{t('Answer5.Average')}</MenuItem>
                  <MenuItem value={'belowAverage'}>{t('Answer5.BelowAverage')}</MenuItem>
                  <MenuItem value={'poor'}>{t('Answer5.Poor')}</MenuItem>
                  <MenuItem value={'dontKnow'}>{t('Answer5.DontKnow')}</MenuItem>
                </Select>
              </Grid>
              <Grid item md={12} xs={24}>
                <Select name="answer6" control={control} label={t('Signals')}>
                  <MenuItem value={''}>&nbsp;</MenuItem>
                  <MenuItem value={'nonePresent'}>Не представлены</MenuItem>
                  <MenuItem value={'continuosFullUsage'}>
                    Постоянное полное использование возобновляемой кредитной линии или частые
                    просрочки/превышение лимита (высокий риск)
                  </MenuItem>
                  <MenuItem value={'otherFinancialInstitutions'}>
                    Другие финансовые учреждения сокращают, ограничивают (например, устанавливают
                    более строгие условия по краткосрочным кредитам) или прекращают доступность
                    кредита для компании по кредитным причинам.(высокий риск)
                  </MenuItem>
                  <MenuItem value={'requestForDelayInPayment'}>
                    Запрос на отсрочку платежа по кредитным причинам (высокий риск)
                  </MenuItem>
                  <MenuItem value={'unexpectedWorsening'}>
                    Неожиданное ухудшение финансовых показателей (высокий риск)
                  </MenuItem>
                  <MenuItem value={'othersNamelyHigh'}>
                    Прочее, а именно ….. (высокий риск)
                  </MenuItem>
                  <MenuItem value={'externalSignals'}>
                    Внешние сигналы(например, Кредитное агентство или сообщения СМИ) (средний риск)
                  </MenuItem>
                  <MenuItem value={'frequentChangesInAuditors'}>
                    Частая смена аудиторов (средний риск)
                  </MenuItem>
                  <MenuItem value={'pandingMaterialRegulatryInvestigations'}>
                    Ожидается существенное расследование регулирующих органов или судебный иск
                    против компании (средний риск)
                  </MenuItem>
                  <MenuItem value={'requestForCovenantAmendment'}>
                    Запрос на изменение или удаление ковенант (средний риск)
                  </MenuItem>
                  <MenuItem value={'suddenChangeInManagement'}>
                    Внезапная смена руководства (средний риск)
                  </MenuItem>
                  <MenuItem value={'financialStatementsReceivedLate'}>
                    По запросу финансовая отчетность была получена с опозданием по сравнению с
                    обычной практикой для клиентского сегмента (средний риск)
                  </MenuItem>
                  <MenuItem value={'othersNamelyAverage'}>
                    Прочее, а именно ….. (средний риск)
                  </MenuItem>
                </Select>
              </Grid>
            </Grid>
          </form>
        </AccordionDetails>
      </Accordion>
      <div className={classes.actions}>
        <Button
          className={classes.button}
          color="primary"
          size="medium"
          type="submit"
          variant="contained"
          disabled={formState.isSubmitting}
          onClick={onSubmit}
        >
          {t('Save')}
        </Button>
        <Button
          className={classes.button}
          color="primary"
          size="medium"
          type="button"
          variant="outlined"
          disabled={formState.isSubmitting}
          onClick={cancelHandler}
        >
          {t('Cancel')}
        </Button>
      </div>
    </>
  );
};
