import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@material-ui/core';
import {
  useController,
  FieldPath,
  FieldValues,
  UseControllerProps,
  FieldError,
} from 'react-hook-form';
import { ChangeEvent, useMemo } from 'react';
import { formatNumber } from '../utils';

export type InputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Omit<MuiTextFieldProps, 'name' | 'helperText' | 'error' | 'defaultValue'> &
  Omit<UseControllerProps<TFieldValues, TName>, 'defaultValue'> & {
    defaultValue?: string;
    formatted?: boolean;
    format?: (value: string) => string;
  };

export function Input<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(props: InputProps<TFieldValues, TName>) {
  const {
    name,
    variant = 'outlined',
    size = 'small',
    fullWidth = true,
    control,
    shouldUnregister,
    rules,
    formatted,
    format,
    onChange: customOnChange,
    ...rest
  } = props;

  const {
    field: { ref, value = '', onChange, ...inputProps },
    fieldState: { invalid, error },
  } = useController<TFieldValues, TName>({
    control,
    name,
    shouldUnregister,
    rules,
  });

  const formattedValue = useMemo(() => {
    if (value === null) {
      return '';
    }
    const valueString = value.toString();
    const valueNumber = parseInt(valueString.replaceAll(' ', ''), 10);
    let newValue = formatted
      ? isNaN(valueNumber)
        ? valueString
        : formatNumber(valueNumber, 0)
      : value;

    return format ? format(newValue ?? '') : newValue;
  }, [value, formatted, format]);

  const helperText = error !== undefined ? (error as FieldError).message : undefined;

  const onChangeHandler = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (customOnChange) customOnChange(evt);
    onChange(evt);
  };

  return (
    <MuiTextField
      {...rest}
      {...inputProps}
      onChange={onChangeHandler}
      value={formattedValue}
      variant={variant}
      fullWidth={fullWidth}
      size={size}
      inputRef={ref}
      helperText={helperText}
      error={invalid}
    />
  );
}
