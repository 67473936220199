import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableCell, TableHead, TableRow, TableBody } from '@material-ui/core';
import { EditButton } from '../EditButton';
import { QuotaLostReason, QuotaLostReasonSortBy, SortOrder } from 'schema/serverTypes';
import { TableSortLabel } from 'components/data-grid/TableSortLabel';

import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    change: {
      width: '41px',
    },
    actions: {
      display: 'flex',
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
    modalRoot: {
      textAlign: 'center',
    },
    modalTitle: {
      textAlign: 'center',
      marginBottom: theme.spacing(3),
    },
    modalText: {
      color: theme.palette.text.primary,
    },
    modalButton: {
      marginTop: theme.spacing(2),
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
  })
);

export type QuotaLostReasonsTableProps = {
  isLoading: boolean;
  data: QuotaLostReason[];
  sorting: {
    sortBy: QuotaLostReasonSortBy;
    order: SortOrder;
    setSortBy: (sortBy: QuotaLostReasonSortBy) => void;
    setOrder: (order: SortOrder) => void;
  };
  onOpen: (reason: QuotaLostReason) => void;
};

type TableItemProps = {
  item: QuotaLostReason;
  onOpen: (item: QuotaLostReason) => void;
};

const TableItem = (props: TableItemProps) => {
  const classes = useStyles();
  const { item, onOpen } = props;

  const handleOpen = useCallback(() => {
    onOpen(item);
  }, [item, onOpen]);

  return (
    <TableRow>
      <TableCell size="medium">{item.text}</TableCell>
      <TableCell size="medium" className={classes.actions}>
        <EditButton onClick={handleOpen} />
      </TableCell>
    </TableRow>
  );
};

export const QuotaLostReasonsTable = (props: QuotaLostReasonsTableProps) => {
  const classes = useStyles();
  const { data, sorting, onOpen } = props;
  const { t } = useTranslation();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell size="medium">
            <TableSortLabel columnName="text" sorting={sorting}>
              {t('Text')}
            </TableSortLabel>
          </TableCell>
          <TableCell className={classes.change} size="medium"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item: QuotaLostReason, index: number) => (
          <TableItem key={index} item={item} onOpen={onOpen} />
        ))}
      </TableBody>
    </Table>
  );
};
