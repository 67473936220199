import { useMissingOverdueListRequest } from 'services/api';
import { MissingOverdueListRequest, MissingOverdueSortBy, SortOrder } from 'schema/serverTypes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MissingOverdueFilterFormProps, MissingOverdueFilterFormValues } from './types';
import { useForm, useWatch } from 'react-hook-form';
import { usePaging } from 'components/paging';

export const useOverdueListData = (defaultValues?: MissingOverdueFilterFormValues) => {
  const filterParams = sessionStorage.overdueFilterValues
    ? JSON.parse(sessionStorage.overdueFilterValues)
    : null;

  const { control, reset, setValue } = useForm<MissingOverdueFilterFormValues>({
    mode: 'all',
    defaultValues: { ...defaultValues, ...filterParams },
  });

  const [sortBy, setSortBy] = useState<MissingOverdueSortBy>(MissingOverdueSortBy.OverdueAmount);
  const [order, setOrder] = useState(SortOrder.desc);

  const onReset = useCallback(() => {
    reset({});
  }, [reset]);

  const { page, pageSize, onPageChanged, onPageSizeChanged } = usePaging();
  const inn = useWatch({ control, name: 'inn' });
  const contractNumber = useWatch({ control, name: 'contractNumber' });
  const contractStatus = useWatch({ control, name: 'contractStatus' });
  const tabId = useWatch({ control, name: 'tabId' });
  const managerId = useWatch({ control, name: 'managerId' });

  const params = useMemo(() => {
    return {
      inn,
      contractNumber,
      contractStatus,
      tabId,
      managerId,
      pageSize,
      page,
    };
  }, [inn, contractNumber, contractStatus, tabId, managerId, pageSize, page]);

  useEffect(() => {
    onPageChanged(1);
  }, [inn, contractNumber, contractStatus, tabId, managerId, onPageChanged]);

  useEffect(() => {
    sessionStorage.setItem('overdueFilterValues', JSON.stringify(params));
  }, [params]);

  const managerIds = useMemo(() => {
    return managerId?.map((t) => t.id);
  }, [managerId]);

  const inns = useMemo(() => {
    return inn?.map((t) => t.inn);
  }, [inn]);

  const request: MissingOverdueListRequest = {
    tabId,
    inn: inns,
    contractNumber,
    contractStatus,
    page,
    pageSize,
    managerEmail: managerIds,
    sortBy,
    order,
  };

  const { isLoading, data: list } = useMissingOverdueListRequest(request);

  const handleSortBy = useCallback(
    (sortBy: MissingOverdueSortBy) => {
      setSortBy(sortBy);
    },
    [setSortBy]
  );

  const handleSortOrder = useCallback(
    (order: SortOrder) => {
      setOrder(order);
    },
    [setOrder]
  );

  return useMemo(() => {
    const rows = list?.data ?? [];
    const pageCount = list?.pageCount ?? 0;
    const totalCount = list?.totalCount ?? 0;

    return {
      isLoading,
      rows,
      sorting: {
        sortBy,
        order,
        setSortBy: handleSortBy,
        setOrder: handleSortOrder,
      },
      paging: {
        pageCount,
        totalCount,
        page,
        pageSize,
        dataCount: rows.length,
        onPageChanged,
        onPageSizeChanged,
      },
      filter: {
        control,
        onReset,
        setValue,
      } as MissingOverdueFilterFormProps,
    };
  }, [
    isLoading,
    list,
    page,
    pageSize,
    control,
    sortBy,
    order,
    onReset,
    setValue,
    handleSortBy,
    handleSortOrder,
    onPageChanged,
    onPageSizeChanged,
  ]);
};
