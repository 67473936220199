import { Autocomplete } from 'components';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteChangeDetails,
} from '@material-ui/lab/useAutocomplete';
import { Control, useController } from 'react-hook-form';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import { useLeasingProductQuery } from './useLeasingProductQuery';
import { LeasingProductItemViewModel } from '../../schema';
import { UserViewModel } from './types';

const getOptionLabel = (option: LeasingProductItemViewModel | null) => {
  if (option === null) {
    return '';
  }
  return option.name;
};

const getOptionSelected = (
  option: LeasingProductItemViewModel,
  value: LeasingProductItemViewModel
) => {
  return option.name === value.name;
};

type LeasingProductAutocompleteProps = {
  control: Control<UserViewModel, object>;
};

export const LeasingProductAutocomplete = (props: LeasingProductAutocompleteProps) => {
  const { control } = props;

  const { t } = useTranslation();

  const {
    field: { onChange, value: values = [] },
  } = useController({
    control,
    name: 'leasingPrograms',
    defaultValue: [],
  });

  const [leasingProduct, setLeasingProduct] = useState('');
  const [search] = useDebounce(leasingProduct, 300);

  const { data = [], isLoading } = useLeasingProductQuery(search);

  const options = data.filter((t) => {
    if (values.length === 0) {
      return true;
    }
    const leasingProduct = values.find((m) => m === t.name);
    return leasingProduct === undefined;
  });

  const handleOnInputChange = useCallback(
    (_ev: React.ChangeEvent<{}>, value: string, _reason: AutocompleteInputChangeReason) => {
      setLeasingProduct(value);
    },
    [setLeasingProduct]
  );

  const handleOnChange = useCallback(
    (
      _e: React.ChangeEvent<{}>,
      value: LeasingProductItemViewModel | null,
      _reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<LeasingProductItemViewModel | null>
    ) => {
      if (value === null) {
        onChange([]);
        setLeasingProduct('');
      } else {
        const newValues = values.length === 0 ? [value.name] : [...values, value.name];
        onChange(newValues);
        setLeasingProduct('');
      }
    },
    [onChange, values]
  );

  return (
    <Autocomplete<LeasingProductItemViewModel>
      label={t('Sales channel')}
      options={options}
      value={null}
      inputValue={leasingProduct}
      onChange={handleOnChange}
      onInputChange={handleOnInputChange}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      loading={isLoading}
      variant="outlined"
      popupIcon={<KeyboardArrowDownRoundedIcon color="inherit" fontSize="medium" />}
      openText={t('Open')}
      closeText={t('Close')}
      clearText={t('Clear')}
      autoHighlight={true}
      noOptionsText={t('no results')}
    />
  );
};
