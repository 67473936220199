import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, TableCell, TableRow, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      backgroundColor: 'transparent',
      '&&:hover $total': {
        backgroundColor: 'transparent',
      },
    },
    total: {
      display: 'flex',
      marginRight: theme.spacing(3),
      background: 'transparent',
      '&&': {
        padding: 0,
      },
    },
    name: {
      padding: theme.spacing(0.75, 0, 0.75, 0.75),
      marginRight: theme.spacing(3),
      fontSize: 12,
      fontWeight: 700,
    },
    item: {
      borderLeft: '1px solid transparent',
      borderRight: '1px solid transparent',
      fontSize: 12,
      textAlign: 'center',
      padding: theme.spacing(0.75, 0, 0.75, 0.75),
      background: 'white',
      marginRight: theme.spacing(3),
    },
    pink: {
      color: theme.palette.error.main,
    },
  })
);

export const InsideSalesTotal = (props: { unsolvedScoringIssuesCount: number[] }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { unsolvedScoringIssuesCount } = props;
  return (
    <TableRow className={classes.root}>
      <TableCell className={classes.total} colSpan={4}>
        <div className={classes.name}>{t('Tasks not sorted')}</div>
        <div className={classes.item}>
          {t('IssueType.Scoring')} {unsolvedScoringIssuesCount[0]}{' '}
          {unsolvedScoringIssuesCount[1] ? (
            <span className={classes.pink}>({unsolvedScoringIssuesCount[1]})</span>
          ) : (
            ''
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};
