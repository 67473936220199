import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IssueViewModel } from 'schema/serverTypes';
import { IconSprite } from '../icons';
import { themeOrange as theme } from 'theme';
import { useCallback, useMemo, useState } from 'react';
import { useIssueDeleteMutation, useSetContractFolderUrlMutation } from '../../services/api';
import { Button } from '../Button';
import { IssueEditForm } from './IssueEditForm';
import { ModalForm, useModalForm } from '../Modal';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Portal,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Input } from '../form';
import { useQueryClient } from 'react-query';
import { Grid, Role, useToast } from 'components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AddComment } from './AddComment';
import { CommentList } from './CommentList';
import { IssueMissing } from './IssueMissing';
import useFileDownloader from '../utils/useFileDownloader';
import { DocumentInput } from './DocumentInput';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.common.black,
      fontSize: 14,
    },
    issue: {
      background: 'white',
      marginBottom: theme.spacing(1.5),
    },
    title: {
      height: 47,
      borderBottom: '1px solid #E3E6F3',
      padding: theme.spacing(0, 2),
      color: theme.palette.primary.main,
      fontWeight: 700,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    edit: {
      marginLeft: theme.spacing(1),
      whiteSpace: 'nowrap',
    },
    action: {
      '& + &': {
        marginLeft: theme.spacing(0.5),
      },
    },
    descriptionWrapper: {
      borderTop: '1px solid #D1D7E4',
      padding: theme.spacing(2, 2.5),
    },
    descriptionTitle: {
      fontSize: 9,
      color: theme.palette.text.secondary,
    },
    description: {
      '& ol': {
        paddingLeft: theme.spacing(2),
        '& > li': {
          listStyleType: 'decimal',
          marginBottom: theme.spacing(1),
        },
      },
      '& ul': {
        paddingLeft: theme.spacing(2),
        '& > li': {
          listStyleType: 'initial',
          marginBottom: theme.spacing(1),
        },
      },
    },
    icon: {
      verticalAlign: 'middle',
      marginLeft: theme.spacing(1),
      marginBottom: 2,
    },
    link: {
      padding: theme.spacing(0, 2, 2),
      fontFamily: "'Halvar Breit', Helvetica, Arial, sans-serif",
      fontSize: 12,
      fontWeight: 500,
      display: 'inline-block',
      color: theme.palette.secondary.dark,
      '& a': {
        color: theme.palette.secondary.dark,
        textDecoration: 'none',
      },
    },
    contract: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1.5),
      padding: '0 16px 16px',
      alignItems: 'flex-start',
    },
    inn: {
      fontSize: 9,
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1.5),
    },
    value: {
      fontSize: 12,
      color: theme.palette.secondary.dark,
    },
    documents: {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: 14,
      fontWeight: 400,
      '& *': {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        cursor: 'pointer',
      },
    },
    actions: {
      position: 'absolute',
      right: 1,
      top: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      '& *': {
        marginRight: 9,
        cursor: 'pointer',
      },
    },
    file: {
      cursor: 'pointer',
      position: 'relative',
      margin: theme.spacing(0, 1.5, 1.5),
      padding: theme.spacing(1.5, 1.25, 1),
      backgroundColor: theme.palette.lightBlue3.main,
      color: theme.palette.primary.main,
    },
    modalRoot: {
      textAlign: 'center',
    },
    modalTitle: {
      textAlign: 'center',
      marginBottom: theme.spacing(3),
    },
    modalText: {
      color: theme.palette.text.primary,
    },
    modalButton: {
      marginTop: theme.spacing(2),
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
    contractFolderError: {
      color: theme.palette.error.main,
      fontSize: 10,
      fontWeight: 600,
      marginTop: theme.spacing(0.5),
    },
    details: {
      width: '100%',
    },
    templateList: {
      width: '100%',
    },
  })
);

export const IssuePayment = (props: { issue: IssueViewModel }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isEditMode, setEditMode] = useState<boolean>(false);
  const {
    title,
    payment,
    file,
    lessee,
    dealer,
    quotaId,
    documentUrl,
    contractNumber,
    contractFolderUrl,
    comments = [],
    statusHistory = [],
    id,
  } = props.issue;
  const { onOpen, onClose, open } = useModalForm();
  const { onOpen: onOpenContract, onClose: onCloseContract, open: openContract } = useModalForm();

  const downloadFile = useFileDownloader();
  const getFile = useCallback(
    async (ev) => {
      await downloadFile(ev, file?.downloadUrl ?? '', file?.fileName ?? '');
    },
    [downloadFile, file?.fileName, file?.downloadUrl]
  );

  const editModeHandler = () => {
    setEditMode((prev) => !prev);
  };

  const deleteHandler = useCallback(
    (id) => {
      onOpen();
    },
    [onOpen]
  );

  const { push } = useHistory();

  const { mutateAsync } = useIssueDeleteMutation(props.issue.id, {
    method: 'DELETE',
    onSuccess: () => {
      push('/tasks');
    },
  });

  const confirmDeleteHandler = useCallback(() => {
    mutateAsync(null);
    onClose();
  }, [onClose, mutateAsync]);

  const { control, handleSubmit } = useForm({
    mode: 'all',
  });

  const queryClient = useQueryClient();
  const toast = useToast();
  const { mutateAsync: mutateAsyncSetContractFolder } = useSetContractFolderUrlMutation(
    props.issue.id,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (query) => {
            return query.queryKey.indexOf('issue') > -1;
          },
        });
      },
      onError: () => {
        toast(t('There is no contract number'), 'error');
        onCloseContract();
      },
    }
  );

  const onSubmitContractFolder = useMemo(() => {
    const submitHanlder = async (values: any) => {
      await mutateAsyncSetContractFolder(values);
      onCloseContract();
    };

    return handleSubmit(submitHanlder);
  }, [mutateAsyncSetContractFolder, onCloseContract, handleSubmit]);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.issue}>
          {!isEditMode ? (
            <>
              <div className={classes.title}>
                <Typography variant={'h4'} color="primary">
                  {title}
                </Typography>
                <div className={classes.edit}>
                  <Button
                    variant="iconButton"
                    endIcon={<IconSprite icon={'edit'} width={'14px'} />}
                    onClick={editModeHandler}
                    className={classes.action}
                  />
                  <Button
                    variant="iconButton"
                    endIcon={<IconSprite icon={'delete'} width={'14px'} />}
                    onClick={deleteHandler}
                    className={classes.action}
                  />
                </div>
              </div>
              <div className={classes.contract}>
                <div className={classes.inn}>
                  {t('Lessee')}
                  <br />
                  {lessee && (
                    <>
                      <Link
                        to={`/counterparties/${lessee.inn}`}
                        className={classes.value}
                        target="_blank"
                        rel="noopener"
                      >
                        {lessee.name} | {lessee.inn}
                      </Link>
                      <IssueMissing inn={lessee.inn} counterpartyName={lessee.name} />
                    </>
                  )}
                </div>
                <div className={classes.inn}>
                  {t('Dealer')}
                  <br />
                  {dealer && (
                    <>
                      <Link
                        to={`/counterparties/${dealer.inn}`}
                        className={classes.value}
                        target="_blank"
                        rel="noopener"
                      >
                        {dealer.name} | {dealer.inn}
                      </Link>
                      <IssueMissing inn={dealer.inn} counterpartyName={dealer.name} />
                    </>
                  )}
                </div>
                <div className={classes.documents}>
                  <a
                    href={`/contracts?contractNumber=${contractNumber}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('Contract')} №{contractNumber}
                  </a>
                  <a href={`/quotas/calculator/${quotaId}`} target="_blank" rel="noreferrer">
                    {t('Calculation')} №{quotaId}
                  </a>
                </div>
                <div>
                  <div onClick={!contractFolderUrl ? () => onOpenContract() : undefined}>
                    <DocumentInput label={'Путь к договору клиента'} value={contractFolderUrl} />
                  </div>
                  <DocumentInput label={'Путь к документам клиента'} value={documentUrl} />
                </div>
              </div>
              <Grid container className={classes.descriptionWrapper} rowSpacing={2}>
                <Grid item md={12} xs={24}>
                  <div className={classes.descriptionTitle}>{t('Payment choice')}</div>
                  <div className={classes.description}>
                    {payment?.paymentType === 'advancePayment'
                      ? t('Advance payment')
                      : payment?.paymentType === 'prepayment'
                      ? t('Prepayment')
                      : payment?.paymentType === 'postpayment'
                      ? t('Postpaid')
                      : ''}
                  </div>
                </Grid>
                {payment?.comment && (
                  <Grid item md={24} xs={24}>
                    <div className={classes.descriptionTitle}>{t('Additional info')}</div>
                    <div
                      className={classes.description}
                      dangerouslySetInnerHTML={{ __html: payment?.comment }}
                    />
                  </Grid>
                )}
                {file && (
                  <Grid item md={24} xs={24}>
                    <div className={classes.file} onClick={getFile}>
                      {file.fileName}
                      <div className={classes.actions}>
                        <div>
                          <IconSprite
                            icon="download"
                            width="14px"
                            height="13px"
                            color={theme.palette.text.primary}
                            hoverColor={theme.palette.primary.main}
                          />
                        </div>
                      </div>
                    </div>
                  </Grid>
                )}
              </Grid>
            </>
          ) : (
            <IssueEditForm issue={props.issue} editModeHandler={editModeHandler} />
          )}
        </div>
      </div>
      <Portal container={document.getElementById('comments')}>
        <Accordion defaultExpanded={!!(comments?.length || statusHistory?.length)}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">{t('Comments')}</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <ul className={classes.templateList}>
              <Role>
                {props.issue.status !== 'closed' && <AddComment issueId={props.issue.id} />}
              </Role>
              {!!(comments?.length || statusHistory?.length) && (
                <CommentList comments={comments} issueId={id} statusHistory={statusHistory} />
              )}
            </ul>
          </AccordionDetails>
        </Accordion>
      </Portal>
      <ModalForm open={openContract} onClose={onCloseContract} width={500}>
        <Typography variant={'h2'} className={classes.modalTitle}>
          {t('Specify the address of the folder with the contract')}
        </Typography>
        <form onSubmit={onSubmitContractFolder}>
          <Input
            label={t('Link to folder')}
            control={control}
            name="url"
            rules={{
              required: {
                value: true,
                message: t('Required'),
              },
            }}
          />

          <div>
            <Button variant="contained" className={classes.modalButton} type="submit">
              {t('Save')}
            </Button>
          </div>
        </form>
      </ModalForm>
      <ModalForm open={open} onClose={onClose} width={500}>
        <div className={classes.modalRoot}>
          <Typography variant={'h2'} className={classes.modalTitle}>
            {t('Delete Task')}
          </Typography>
          <Typography variant="subtitle2" className={classes.modalText}>
            {t('Are you sure you want to delete the task')}
          </Typography>
          <Typography variant="body2" className={classes.modalText}>
            №{props.issue.id} {props.issue.title}?
          </Typography>
          <div>
            <Button
              variant="contained"
              className={classes.modalButton}
              onClick={confirmDeleteHandler}
            >
              {t('Yes')}
            </Button>
            <Button variant="outlined" onClick={onClose} className={classes.modalButton}>
              {t('No')}
            </Button>
          </div>
        </div>
      </ModalForm>
    </>
  );
};
