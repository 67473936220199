import { useMemo } from 'react';
import { UserViewModel } from 'schema/serverTypes';
import { useQuotasBackendQuery } from 'services/api/useQuotasBackend';

export type UseQuotaOwnersProps = {
  search?: string;
  count?: number;
};

export const useQuotaOwners = (props: UseQuotaOwnersProps | undefined = undefined) => {
  const { search, count = 20 } = props || {};

  let requestUrl = useMemo(() => {
    let url = 'users';
    const searchParams = new URLSearchParams();

    if (search !== undefined && search !== '') {
      searchParams.set('name', search);
    }
    url += `?${searchParams}`;

    searchParams.set('count', count.toString());

    return url;
  }, [search, count]);

  return useQuotasBackendQuery<UserViewModel[]>(
    requestUrl,
    search !== undefined && search !== ''
      ? ['quotas', 'users', count, search]
      : ['quotas', 'users', count]
  );
};
