import { makeStyles, Theme, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      fill: theme.palette.text.secondary,
    },
  })
);

export const IconBackTo = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.root}
      width="12"
      height="10"
      viewBox="0 0 12 10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.34491 5.87063L1.14178 3.34871L3.34491 0.826786C3.52294 0.622763 3.50217 0.312943 3.29815 0.134747C3.09412 -0.0432839 2.78447 -0.0223542 2.60611 0.181505L0.121148 3.02614C-0.0403828 3.21087 -0.0403828 3.48668 0.121148 3.67142L2.60613 6.51608C2.70323 6.62691 2.8391 6.68382 2.97577 6.68382C3.09021 6.68382 3.20512 6.64393 3.29815 6.56267C3.5022 6.38447 3.5231 6.07465 3.34491 5.87063Z" />
      <path d="M8.43586 2.85828H0.490453C0.219563 2.85828 0 3.07782 0 3.34873C0 3.61962 0.219563 3.83918 0.490453 3.83918H8.43586C9.8603 3.83918 11.0191 4.99796 11.0191 6.42225C11.0191 7.84655 9.8603 9.00532 8.43586 9.00532H5.14441C4.87352 9.00532 4.65395 9.22489 4.65395 9.49578C4.65395 9.76667 4.87352 9.98623 5.14441 9.98623H8.43586C10.401 9.98623 12 8.38735 12 6.42225C12 4.45716 10.4011 2.85828 8.43586 2.85828Z" />
    </svg>
  );
};
