import { Box, Grid } from '@material-ui/core';
import { Paging } from 'components';
import { ShipmentsFilterForm } from './ShipmentsFilterForm';
import { ShipmentsTable } from './ShipmentsTable';
import { useShipmentsData } from './useShipmentsData';
import { PageTitle } from 'components/utils/pageTitle';
import { useTranslation } from 'react-i18next';

export const ShipmentsDataGrid = () => {
  const { filter, paging, sorting, ...dataProps } = useShipmentsData();
  const { t } = useTranslation();
  const pageTitle = t('Shipment_plural') + ' - Bumblebee';
  PageTitle(pageTitle);

  return (
    <>
      <Box display="flex" justifyContent="space-between" mb={2.5} alignItems="flex-end">
        <Box>
          <ShipmentsFilterForm {...filter} />
        </Box>
      </Box>
      <Grid container item direction="column">
        <Grid item>
          <ShipmentsTable {...dataProps} sorting={sorting} />
        </Grid>
        <Grid item>
          <Paging {...paging} />
        </Grid>
      </Grid>
    </>
  );
};
