import { useCalculationFormContext } from 'components/calculator2/CalculationForm/types';
import { getNumber } from 'components/utils';
import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';

export type OnYearChangeProps = {
  index: number;
};

export const OnYearChange = (props: OnYearChangeProps) => {
  const { control, setValue, getValues } = useCalculationFormContext();
  const { index } = props;
  const value = useWatch({ control, name: `nomenclatures.${index}.year` });

  useEffect(() => {
    const selectedYear = getNumber(value);
    const currentYear = new Date().getFullYear();
    const isSecondHandCurrent = getValues(`nomenclatures.${index}.isSecondHand`);
    const isSecondHand = selectedYear < currentYear;
    if (isSecondHandCurrent !== isSecondHand) {
      setValue(`nomenclatures.${index}.isSecondHand`, isSecondHand);
    }
  }, [index, value, setValue, getValues]);

  return null;
};
