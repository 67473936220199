import clsx from 'clsx';
import {
  Tabs as MaterialTabs,
  Tab,
  Theme,
  createStyles,
  makeStyles,
  Typography,
  ClickAwayListener,
} from '@material-ui/core';
import { isTabProps } from './isTabProps';
import { TabProps, TabsProps } from './types';
import { Button } from '../Button';
import { ModalForm } from '../Modal';
import { useTranslation } from 'react-i18next';
import { IconSprite } from '../icons';
import { themeOrange as theme } from 'theme';
import { useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: 0,
      '& .Mui-selected': {
        color: theme.palette.secondary.darker,
      },
      '& .MuiTab-wrapper': {
        textTransform: 'uppercase',
        fontSize: theme.typography.subtitle1.fontSize,
        fontWeight: 700,
      },
    },
    currVerticalTab: {
      fontFamily: "'Halvar Breit', Helvetica, Arial, sans-serif",
      fontWeight: 700,
      width: 111,
      textAlign: 'left',
      textTransform: 'uppercase',
      fontSize: 12,
      height: 30.5,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      '& span': {
        marginBottom: -3,
        paddingBottom: theme.spacing(1),
        borderBottom: '3px solid' + theme.palette.secondary.dark,
      },
      '& $verticalArrow': {
        paddingBottom: 2,
      },
    },
    verticalArrow: {},
    vertical: {
      width: 112,
      position: 'absolute',
      top: 30.5,
      right: 120,
      zIndex: 1,
      background: 'white',
      filter: 'drop-shadow(0px 0px 5px rgba(56, 60, 97, 0.1))',
      '& .MuiTabs-scroller': {
        borderBottom: 0,
      },
      '& .MuiTab-wrapper': {
        alignItems: 'flex-start',
        fontWeight: 400,
      },
      '& .MuiTab-root': {
        padding: theme.spacing(0.5, 1),
      },
      '& .MuiTab-root:not(:first-child)': {
        marginLeft: 0,
      },
      '& .MuiTabs-indicator': {
        display: 'none',
      },
      '& .Mui-selected .MuiTab-wrapper': {
        fontWeight: 700,
      },
      '& .MuiTab-root:not(.Mui-selected):hover': {
        backgroundColor: theme.palette.primary.light,
        borderBottom: '3px solid transparent',
      },
    },
    horizontal: {},
    modalRoot: {
      textAlign: 'center',
    },
    modalTitle: {
      textAlign: 'center',
      marginBottom: theme.spacing(3),
    },
    modalText: {
      color: theme.palette.text.primary,
    },
    modalButton: {
      marginTop: theme.spacing(2),
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
  })
);

const getTabLabel = (name: string) => (name.length >= 15 ? `${name.substr(0, 11)}...` : name);

export const getTabProps = (tab: string | TabProps) => {
  if (isTabProps(tab)) {
    return {
      label: getTabLabel(tab.name),
      disabled: tab.disabled,
      confirmation: tab.confirmation,
    };
  }

  return {
    label: getTabLabel(tab),
    disabled: false,
  };
};

export const Tabs = (props: TabsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { tabs, className } = props;
  const {
    tabIndex,
    onChangeTab,
    a11yProps,
    onClose,
    open,
    confirmLeaveHandler,
    orientation = 'horizontal',
  } = props;
  const [verticalOpen, setVerticalNavOpen] = useState<boolean>(false);

  const toggleHandler = () => {
    setVerticalNavOpen((prev) => !prev);
  };

  return (
    <>
      {orientation === 'vertical' && (
        <div className={classes.currVerticalTab} onClick={toggleHandler}>
          <span>{tabs[tabIndex]}</span>
          <IconSprite
            className={classes.verticalArrow}
            icon={'button-arrow-small'}
            color={theme.palette.primary.main}
            width={16}
            height={10}
          />
        </div>
      )}
      {(orientation === 'horizontal' || verticalOpen) && (
        <ClickAwayListener onClickAway={toggleHandler as any}>
          <MaterialTabs
            className={clsx(className, classes.root, classes[orientation])}
            value={tabIndex}
            orientation={orientation}
            onChange={onChangeTab}
            variant="standard"
            aria-label="tabs"
          >
            {tabs.map((tab: string | TabProps, index: number) => {
              const { id, ...rest } = a11yProps(index);
              const { label, disabled } = getTabProps(tab);
              return <Tab key={id} label={label} id={id} {...rest} disabled={disabled} />;
            })}
          </MaterialTabs>
        </ClickAwayListener>
      )}
      <ModalForm open={open} onClose={onClose} width={500}>
        <div className={classes.modalRoot}>
          <Typography variant={'h2'} className={classes.modalTitle}>
            {t('Leave page')}
          </Typography>
          <Typography variant="subtitle2" className={classes.modalText}>
            {t('Are you sure you want to leave page without save data')}
          </Typography>
          <div>
            <Button
              variant="contained"
              className={classes.modalButton}
              onClick={confirmLeaveHandler}
            >
              {t('Yes')}
            </Button>
            <Button variant="outlined" onClick={onClose} className={classes.modalButton}>
              {t('No')}
            </Button>
          </div>
        </div>
      </ModalForm>
    </>
  );
};
