import { Switch } from 'components/form/Switch';
import { useCalculationFormContext } from '../types';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { usePrevious } from 'hooks';

export const CyclicityCheckbox = () => {
  const { control, getValues, setValue } = useCalculationFormContext();

  const hasCyclicity = useWatch({ control, name: 'seasonalPaymentOptions.hasCyclicity' });
  const prevHasCyclicity = usePrevious(hasCyclicity);

  useEffect(() => {
    const options = getValues('paymentOptions') ?? [];

    if (options.length <= 12) {
      return;
    }

    if (hasCyclicity && !prevHasCyclicity) {
      const patterns = options.slice(0, 12);
      for (let i = 11; i < options.length; i++) {
        const option = options[i];
        const pattern = patterns[i % 12];
        if (
          option.isPayment !== pattern.isPayment ||
          option.isPreferential !== pattern.isPreferential ||
          option.value !== pattern.value
        ) {
          setValue(`paymentOptions.${i}`, { ...pattern, number: option.number });
        }
      }
    }
  }, [hasCyclicity, prevHasCyclicity, setValue, getValues]);

  const { t } = useTranslation();

  return (
    <Switch
      control={control}
      name="seasonalPaymentOptions.hasCyclicity"
      label={t('Cyclicity')}
      type="checkbox"
    />
  );
};
