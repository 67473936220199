import { Autocomplete } from 'components';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import { MissingCounterpartyViewModel, MissingLesseeListRequest } from 'schema/serverTypes';
import { useMissingLesseeListRequest } from 'services/api';
import { MissingFilterFormProps } from './types';
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteChangeDetails,
} from '@material-ui/lab/useAutocomplete';
import { useController } from 'react-hook-form';

const getOptionLabel = (option: MissingCounterpartyViewModel | null) => {
  if (option === undefined || option === null) {
    return '';
  }
  return `${option.name} (${option.inn})`;
};

const getOptionSelected = (
  option: MissingCounterpartyViewModel | null,
  value: MissingCounterpartyViewModel | null
) => {
  return option?.inn === value?.inn;
};

export type MissingLesseeAutocompleteProps = Pick<MissingFilterFormProps, 'control'> & {
  count?: number;
};

export const MissingLesseeAutocomplete = (props: MissingLesseeAutocompleteProps) => {
  const { control, count = 20 } = props;

  const { t } = useTranslation();

  const {
    field: { onChange, value: inns },
  } = useController({
    control,
    name: 'inn',
    defaultValue: [],
  });

  const [inn, setInn] = useState('');
  const [search] = useDebounce(inn, 500);

  const request = useMemo<MissingLesseeListRequest>(() => {
    return {
      search,
      count,
    };
  }, [search, count]);

  const { data = [], isLoading } = useMissingLesseeListRequest(request);
  const options = data.filter((t) => {
    if (inns === undefined) {
      return true;
    }
    const counterpartyByInn = inns.find((m) => m.inn === t.inn);
    return counterpartyByInn === undefined;
  });

  const handleOnInputChange = useCallback(
    (_ev: React.ChangeEvent<{}>, value: string, _reason: AutocompleteInputChangeReason) => {
      setInn(value);
    },
    [setInn]
  );

  const handleOnChange = useCallback(
    (
      _e: React.ChangeEvent<{}>,
      value: MissingCounterpartyViewModel | null,
      _reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<MissingCounterpartyViewModel | null>
    ) => {
      if (value === null) {
        onChange([]);
        setInn('');
      } else {
        onChange(inns ? [...inns.filter((v) => v.inn !== value.inn), value] : [value]);
        setInn('');
      }
    },
    [onChange, inns]
  );

  return (
    <Autocomplete<MissingCounterpartyViewModel | null>
      label={t('Counterparty')}
      options={options}
      inputValue={inn}
      onInputChange={handleOnInputChange}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      onChange={handleOnChange}
      value={null}
      loading={isLoading}
      variant="standard"
    />
  );
};
