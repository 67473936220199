import { useCallback, useState } from 'react';
import { PagingProps } from './types';

export type UsePagingProps = Partial<Pick<PagingProps, 'pageSize' | 'page'>>;

export const usePaging = (props?: UsePagingProps) => {
  const defaultPageSize = props?.pageSize ?? 20;
  const [pageSize, setPageSize] = useState(defaultPageSize);

  const defaultPage = props?.page ?? 1;
  const [page, setPage] = useState(defaultPage);

  const onPageChanged = useCallback((value: number) => {
    setPage(value);
  }, []);

  const onPageSizeChanged = useCallback((value: number) => {
    setPageSize(value);
  }, []);

  const onReset = useCallback(() => {
    setPage(1);
    setPageSize(defaultPageSize);
  }, [defaultPageSize]);

  return {
    page,
    pageSize,
    onPageChanged,
    onPageSizeChanged,
    onReset,
  };
};
