import { Grid } from '@material-ui/core';
import { Paging, Role } from 'components';
import { TaskItem } from '../TaskItem';
import { useIssuesGroupedData } from './useIssuesData';
import { useTranslation } from 'react-i18next';
import { Empty } from '../../Empty';
import { TaskListProps } from './types';
import { ApplicationRole } from 'schema/serverTypes';
import { IssueQuotaListItem } from './IssueQuotaListItem';

export const IssueListGrouped = (props: TaskListProps) => {
  const { paging, grouped } = useIssuesGroupedData(props);
  const { t } = useTranslation();

  return (
    <>
      <Grid item>
        {grouped.length === 0 && <Empty>{t("You don't have any tasks yet")}</Empty>}
        <Role
          role={[
            ApplicationRole.Admin,
            ApplicationRole.SalesManager,
            ApplicationRole.SalesSupport,
            ApplicationRole.SuperSalesManager,
            ApplicationRole.RiskManager,
            ApplicationRole.AssetManager,
            ApplicationRole.InsideSales,
          ]}
        >
          {grouped.map(({ quota, issues }) => {
            return (
              <div key={quota.quotaId}>
                <IssueQuotaListItem {...quota} />
                {issues.map((issue) => {
                  return <TaskItem {...issue} key={issue.id} />;
                })}
              </div>
            );
          })}
        </Role>
      </Grid>
      {grouped.length > 0 && (
        <Grid item>
          <Paging {...paging} />
        </Grid>
      )}
    </>
  );
};
