import { CalculationAgreement, CalculationInput } from 'schema';

export const TelematicsValue = {
  caesar: {
    monthly: 534.0,
    oneTime: 15763.0,
  },
  xPro: {
    monthly: 0,
    oneTime: 16140.0,
  },
};

export type CalculationFormValues = CalculationInput &
  CalculationAgreement & {
    generalRatePercents?: number;
    tradeFeeAmount?: number;
    prepaymentAmount?: number;
    fundingAmount?: number;
    save?: boolean;
    copy?: boolean;
    dealer?: string;
    dealerName?: string;
    lessee?: string;
    lesseeName?: string;
    insuranceCompany?: string;
    insuranceCompanyName?: string;
    vatEnabled?: boolean;
  };
