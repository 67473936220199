import { FormLabelClassKey, StyleRules } from '@material-ui/core';
import palette from '../paletteOrange';

const style: Partial<StyleRules<FormLabelClassKey>> = {
  root: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: '12px',
    color: palette.text.secondary,
    '&.Mui-focused': {
      color: palette.text.secondary,
    },
    '&$error': {
      color: palette.text.primary,
    },
  },
};

export default style;
