import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(1),
      width: 256,
      flex: '0 0 auto',
    },
    editMode: {
      zIndex: 5,
      boxShadow: '0px 2px 20px rgba(56, 60, 97, 0.1)',
    },
    noEditMode: {
      '& .MuiInputBase-root': {
        pointerEvents: 'none',
        '& fieldset': {
          border: 'none',
          borderBottom: '1px solid #E3E6F3',
          paddingBottom: theme.spacing(4.5),
        },
      },
      '& .MuiSelect-icon': {
        display: 'none',
      },
    },
    header: {
      textAlign: 'center',
      fontSize: 12,
      fontWeight: 700,
      color: theme.palette.text.secondary,
      borderBottom: '1px solid #E3E6F3',
      padding: theme.spacing(1, 0),
      position: 'relative',
    },
    actions: {
      position: 'absolute',
      display: 'flex',
      right: 0,
      top: 'calc(50% - 7px)',
      '& div': {
        marginLeft: theme.spacing(1.25),
        cursor: 'pointer',
      },
    },
    owner: {
      fontSize: 10,
      fontWeight: 400,
      color: theme.palette.text.secondary,
    },
    container: {
      marginTop: theme.spacing(2),
    },
    label: {
      fontSize: 9,
      color: theme.palette.text.secondary,
    },
    value: {
      fontSize: 12,
      color: theme.palette.common.black,
    },
    gradeWrapper: {
      lineHeight: '29px',
      backgroundColor: theme.palette.secondary.light,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(6),
    },
    gradeLabel: {
      fontSize: 12,
      color: theme.palette.text.primary,
      paddingLeft: theme.spacing(1.5),
      marginRight: theme.spacing(3),
    },
    gradeValue: {
      fontSize: 24,
      fontWeight: 700,
      color: theme.palette.common.black,
      '&$gradeRed': {
        color: theme.palette.pink.main,
      },
    },
    gradeRed: {},
    number: {
      '& input': {
        textAlign: 'right',
      },
    },
    spacer: {
      marginBottom: theme.spacing(6.5),
    },
    optional: {
      marginTop: theme.spacing(2.25),
      marginBottom: theme.spacing(0),
      backgroundColor: theme.palette.secondary.light,
      height: '30px',
      width: '100%',
    },
    submit: {
      width: '100%',
      backgroundColor: 'white',
    },
    sticky: {
      position: 'sticky',
      bottom: 0,
    },
    modalRoot: {
      textAlign: 'center',
    },
    modalTitle: {
      textAlign: 'center',
      marginBottom: theme.spacing(3),
    },
    modalText: {
      color: theme.palette.text.primary,
    },
    modalButton: {
      marginTop: theme.spacing(2),
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },

    table: {
      backgroundColor: theme.palette.common.white,
      fontSize: 12,
      boxShadow: '0px 0px 20px 0px rgb(56 60 97 / 10%)',
      color: theme.palette.common.black,
      display: 'grid',
      gridTemplateColumns: '112fr 340fr 170fr 78fr 100fr 100fr 100px',
    },
    th: {
      fontSize: 12,
      color: theme.palette.text.secondary,
      fontWeight: 700,
      display: 'flex',
      alignItems: 'center',
      paddingTop: 16,
      paddingBottom: 16,
      paddingRight: 6,
      paddingLeft: 6,
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
    },
    rowWrapper: {
      display: 'contents',
      backgroundColor: 'green',
      '&:hover > *': {
        backgroundColor: theme.palette.background.light,
      },
      '& > *': {
        display: 'flex',
        alignItems: 'center',
        paddingTop: 16,
        paddingBottom: 16,
        paddingRight: 6,
        paddingLeft: 6,
        borderBottom: `2px solid ${theme.palette.background.default}`,
        color: theme.palette.common.black,
      },
      '& $firstChild': {
        paddingLeft: 20,
        fontWeight: 700,
      },
      '& $lastChild': {
        paddingRight: 20,
      },
    },
    firstChild: {},
    lastChild: {},
    rating: {
      position: 'relative',
      fontSize: 14,
      fontWeight: 700,
      textAlign: 'center',
    },
    manual: {
      display: 'block',
      position: 'absolute',
      bottom: 4,
      fontSize: 9,
      color: theme.palette.text.secondary,
      fontWeight: 400,
    },
    info: {
      cursor: 'pointer',
      marginLeft: 6,
    },
    icons: {
      paddingRight: 20,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& > div': {
        cursor: 'pointer',
      },
    },
    created: {
      color: theme.palette.text.secondary,
      fontSize: '11px',
    },
    right: {
      textAlign: 'right',
      justifyContent: 'flex-end',
    },
    center: {
      textAlign: 'center',
      justifyContent: 'center',
    },
    pink: {
      color: theme.palette.pink.main,
    },
    pinkBkg: {
      backgroundColor: theme.palette.lightPurple.main,
    },
    green: {
      color: theme.palette.green.main,
    },
    greenBkg: {
      backgroundColor: theme.palette.lightGreen.main,
    },
  })
);

export type RatingStyles = ReturnType<typeof useStyles>;
