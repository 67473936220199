import { Route, Switch } from 'react-router-dom';
import { Critical, NewsFeed } from 'components';
import { useErrorContext } from 'components/tasks';
import { useTranslation } from 'react-i18next';
import { IssueSource } from 'schema/serverTypes';
import { PageTitle } from 'components/utils/pageTitle';

export const NewsPage = () => {
  const { t } = useTranslation();

  const pageTitle = t('Section.news') + ' - Bumblebee';
  PageTitle(pageTitle);

  useErrorContext({
    title: t('News'),
    source: IssueSource.news,
  });

  return (
    <>
      <Critical />
      <Switch>
        <Route path="/news" exact component={NewsFeed} />
      </Switch>
    </>
  );
};
