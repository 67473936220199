import {
  SortOrder,
  TelematicsDictionaryItemSortBy,
  TelematicsDictionaryItemViewModel,
  UpdateTelematicsRequestViewModel,
} from 'schema/serverTypes';
import { useDictionaryBackendQuery, useDictionaryBackendMutation } from './useQuotasBackend';
import { MutationOptions } from './useBackend';

type TelematicsDictionaryQueryRequest = {
  sortBy: TelematicsDictionaryItemSortBy;
  order: SortOrder;
};

export const useTelematicsDictionaryQuery = (request: TelematicsDictionaryQueryRequest) => {
  const { sortBy, order } = request;
  let searchParams = new URLSearchParams();

  searchParams.append('sortBy', sortBy);
  searchParams.append('order', order);

  return useDictionaryBackendQuery<TelematicsDictionaryItemViewModel[]>(
    `telematics?${searchParams}`,
    {}
  );
};

export const useUpdateTelematicsDictionaryMutation = (
  id: number,
  options?: MutationOptions<UpdateTelematicsRequestViewModel, TelematicsDictionaryItemViewModel>
) =>
  useDictionaryBackendMutation<UpdateTelematicsRequestViewModel, TelematicsDictionaryItemViewModel>(
    `telematics/${id.toString()}`,
    { method: 'PUT', ...options }
  );
