import { ToastContent, toast } from 'react-toastify';
import { CloseButton } from './CloseButton';
import error from './error.svg';
import warning from './warning.svg';
import info from './info.svg';
import success from './success.svg';

export type ErrorType = 'error' | 'success' | 'warning' | 'info';

export const useToast = () => {
  const myToast = (message: ToastContent, type: ErrorType) => {
    let icon = info;
    switch (type) {
      case 'error':
        icon = error;
        break;
      case 'success':
        icon = success;
        break;
      case 'warning':
        icon = warning;
        break;
      default:
        icon = info;
    }

    return toast(message, {
      type: type,
      icon: ({ type }: any) => <img src={icon} alt={type} />,
      closeButton: CloseButton,
    });
  };
  return myToast;
};
