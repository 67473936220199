import { useIndustryLeasingProductsData } from './useIndustryLeasingProductsData';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { Input, Button } from 'components';
import { Grid } from 'components/Grid';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonReset: {
      display: 'flex',
      alignItems: 'flex-end',
      marginBottom: theme.spacing(0.2),
    },
  })
);

export type IndustryLeasingProductFilterFormProps = Pick<
  ReturnType<typeof useIndustryLeasingProductsData>,
  'filter'
>['filter'];

export const IndustryLeasingProductFilterForm = (props: IndustryLeasingProductFilterFormProps) => {
  const classes = useStyles();
  const { control, handleOnReset } = props;
  const { t } = useTranslation();

  return (
    <form>
      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid md={4} xs={24} item>
          <Input label={`${t('Name')}`} control={control} name="name" variant="standard" />
        </Grid>
        <Grid md={4} xs={24} item className={classes.buttonReset}>
          <FormControl>
            <Button variant="text" onClick={handleOnReset}>
              {t('Reset')}
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
};
