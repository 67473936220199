import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  Theme,
} from '@material-ui/core';
import { themeOrange as theme } from 'theme';
import { TabPanel } from 'components/Tabs';
import { useMissingOverdueTopRequest } from 'services/api/useMissingBackend';
import { MissingOverdueTop } from 'schema/serverTypes';
import { OverdueItem } from './OverdueItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'white',
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: theme.spacing(1.5),
    },
    border: {
      '&.MuiTable-root': {
        borderSpacing: '4px',
        borderCollapse: 'separate',
        boxShadow: 'none',
      },
      '& + $border': {
        marginLeft: theme.spacing(0.75),
      },
    },
  })
);

export type OverdueTabProps = {
  id: number;
  tabIndex: number;
  index: number;
};

const getKey = (top: MissingOverdueTop, index: number) =>
  `${top.contractNumber}-${top.lesseeName}-${top.lesseeInn}-${top.managerEmail}-${index}`;

export const OverdueTab = (props: OverdueTabProps) => {
  const classes = useStyles();
  const { id, tabIndex, index } = props;
  const { tab } = useMissingOverdueTopRequest(id, {
    enabled: index === tabIndex,
  });

  const debts = tab?.debts ?? [];
  const amounts = tab?.amounts ?? [];

  return (
    <TabPanel value={tabIndex} index={index} dir={theme.direction}>
      <TableContainer className={classes.root}>
        <Table className={classes.border}>
          <TableBody>
            {debts.map((item) => (
              <OverdueItem key={getKey(item, 1)} variant="debtDepth" {...item} />
            ))}
          </TableBody>
        </Table>
        <Table className={classes.border}>
          <TableBody>
            {amounts.map((item) => (
              <OverdueItem key={getKey(item, 2)} variant="overdueAmount" {...item} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </TabPanel>
  );
};
