import { useWatch } from 'react-hook-form';
import { QuotaChipsProps } from './types';
import { useCallback } from 'react';
import { Chip } from 'components/Chips';
import { useTranslation } from 'react-i18next';
import { ScoringModelType } from 'schema';

export const ScoringModelChips = (props: QuotaChipsProps) => {
  const { control, setValue } = props;
  const scoringModelType = useWatch({ control, name: 'scoringModelType' });

  const onRemove = useCallback(
    (id: string) => {
      const values = scoringModelType?.filter((v) => v !== id);
      setValue('scoringModelType', values);
    },
    [scoringModelType, setValue]
  );

  const { t } = useTranslation();

  const scoringModelTypeChips = scoringModelType?.map((value) => {
    const label =
      value === ScoringModelType.Retail
        ? t('ScoringModelType.Retail')
        : value === ScoringModelType.SevenMillion
        ? t('ScoringModelType.SevenMillion')
        : value === ScoringModelType.Excel
        ? t('ScoringModelType.Excel')
        : value === ScoringModelType.Manual
        ? t('ScoringModelType.Manual')
        : '';
    return <Chip label={label} id={value} key={`scoringModelType-${value}`} onRemove={onRemove} />;
  });

  return <>{scoringModelTypeChips}</>;
};
