import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Control, useController, UseFormSetValue } from 'react-hook-form';

import { ExternalQuotaFilter, ExternalQuotaStatus, ExternalQuotaTab } from '../../schema';
import { useTranslation } from 'react-i18next';
import { Tabs, useTabs } from '../Tabs';
import { useCallback, useMemo } from 'react';
import { Box, MenuItem } from '@material-ui/core';
import { Grid } from '../Grid';
import FormControl from '@material-ui/core/FormControl';
import { Button } from '../Button';
import { RequestsChips } from './RequestsChips';
import { RequestsRegionAutocomplete } from './RequestRegionAutocomplete';
import { Select } from '../form';
import { RequestsUserAutocomplete } from './RequestsUserAutocomplete';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '100%',
    },
    buttonReset: {
      display: 'flex',
      alignItems: 'flex-end',
      marginBottom: theme.spacing(0.2),
    },
    number: {
      '& input': {
        textAlign: 'right',
      },
    },
  })
);

export type RequestsFilterFormProps = {
  control: Control<ExternalQuotaFilter, object>;
  onReset: () => void;
  setValue: UseFormSetValue<ExternalQuotaFilter>;
};

export const RequestsFilterForm = (props: RequestsFilterFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { control, onReset, setValue } = props;
  const {
    field: { onChange: onTabIdChange },
  } = useController({ control, name: 'tabId' });

  const userTabs = useMemo(() => {
    return [
      {
        name: 'Мои',
        id: ExternalQuotaTab.Mine,
      },
      {
        name: 'Входящие',
        id: ExternalQuotaTab.Incoming,
      },
      {
        name: 'Все',
        id: ExternalQuotaTab.All,
      },
    ];
  }, []);

  const tabsProps = useTabs(userTabs.map((t) => t.name));
  const { tabIndex, onChangeTab, tabs } = tabsProps;

  const onMineChanged = useCallback(
    (e: any, index: number) => {
      onChangeTab(e, index);
      const tab = userTabs.find((t) => t.name === tabs[index]);
      if (tab) {
        onTabIdChange(tab.id);
      }
    },
    [onChangeTab, onTabIdChange, userTabs, tabs]
  );

  return (
    <form>
      <Box mb={2.5} display="flex" justifyContent="space-between" alignItems="flex-end">
        <Box>
          <Tabs {...tabsProps} value={tabIndex} onChangeTab={onMineChanged} />
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mb={2.5} alignItems="flex-end">
        <Box flex={1}>
          <Grid container columnSpacing={2} rowSpacing={2.5} className={classes.root}>
            <Grid md={4} xs={24} item>
              <RequestsRegionAutocomplete control={control} />
            </Grid>
            <Grid md={4} xs={24} item>
              <Select label={t('Status')} name="status" control={control} variant="standard">
                <MenuItem value={ExternalQuotaStatus.Created}>Открыта</MenuItem>
                <MenuItem value={ExternalQuotaStatus.InProgress}>В работе</MenuItem>
              </Select>
            </Grid>
            <Grid md={4} xs={24} item>
              <RequestsUserAutocomplete control={control} />
            </Grid>
            <Grid md={3} xs={24} item className={classes.buttonReset}>
              <FormControl>
                <Button variant="text" onClick={onReset}>
                  {t('Reset')}
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box mb={1}>
        <RequestsChips control={control} setValue={setValue} />
      </Box>
    </form>
  );
};
