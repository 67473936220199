import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { CommentWikiViewModel } from 'schema/serverTypes';
import { Button } from '../Button';
import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { createQuotasBackendUrl } from '../../services/api/createQuotasBackendUrl';
import clsx from 'clsx';
import { WysiwygEditor } from '../tasks/WysiwygEditor';
import { useAddWikiCommentMutation } from '../../services/api/useWikiBackend';
import { FileUpload } from './FileUpload';
import { Grid } from '../Grid';
import { FormHelperText } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.bgGray.main,
      padding: theme.spacing(0),
    },
    actions: {
      backgroundColor: theme.palette.bgGray.main,
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%',
      padding: theme.spacing(1, 2, 0, 0),
    },
    actionButton: {
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
    button: {
      display: 'block',
      margin: '4px auto 0',
    },
    save: {
      backgroundColor: 'white',
    },
    cancel: {
      color: theme.palette.primary.main,
      fontSize: 12,
      fontWeight: 600,
      textDecoration: 'none',
      display: 'inline-flex',
      alignItems: 'center',
      height: '100%',
    },
    container: {
      position: 'relative',
    },
    description: {},
  })
);

export type CommentEditFormProps = {
  articleId: number;
  commentId: number;
  text: string;
  files?: File[];
  onSuccess?: () => void;
  editModeHandler: () => void;
};

export const CommentEditForm = (props: CommentEditFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { editModeHandler, commentId, text, files = [] } = props;
  const { control, handleSubmit, setValue } = useForm<CommentWikiViewModel>({
    mode: 'all',
    defaultValues: {
      text,
      files,
    },
  });
  const queryClient = useQueryClient();

  const { mutateAsync } = useAddWikiCommentMutation(commentId);

  const onSubmit = useMemo(() => {
    const submit = async (values: CommentWikiViewModel) => {
      const comment = await mutateAsync(values);
      const queryKey = createQuotasBackendUrl(`/${props.articleId}`);
      if (comment) {
        await queryClient.invalidateQueries(queryKey);
      }
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync, props.articleId, queryClient]);

  return (
    <form className={classes.root} onSubmit={onSubmit}>
      <Grid container columnSpacing={2} rowSpacing={1.5} className={classes.container}>
        <Grid item xs={24} className={classes.description}>
          <Controller
            name="text"
            control={control}
            render={({ field, fieldState }) => {
              const helperText =
                fieldState.error !== undefined
                  ? (fieldState.error as FieldError).message
                  : undefined;

              return (
                <>
                  <WysiwygEditor value={field.value} onChange={field.onChange} />
                  {helperText && <FormHelperText error>{helperText}</FormHelperText>}
                </>
              );
            }}
            rules={{
              required: true,
              maxLength: {
                value: 10000,
                message: t('Maximum number of characters is', { count: 10000 }),
              },
            }}
          />
        </Grid>
        <Grid item xs={24}>
          <FileUpload control={control} setValue={setValue} />
        </Grid>
      </Grid>
      <div className={classes.actions}>
        <div className={classes.actionButton}>
          <Button
            className={clsx(classes.button, classes.save)}
            color="primary"
            size="medium"
            type="submit"
            variant="outlined"
          >
            {t('Save')}
          </Button>
        </div>

        <div className={classes.actionButton}>
          <Button
            className={clsx(classes.button, classes.cancel)}
            color="primary"
            size="medium"
            type="button"
            variant="text"
            onClick={editModeHandler}
          >
            {t('Cancel2')}
          </Button>
        </div>
      </div>
    </form>
  );
};
