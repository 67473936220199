import { createContext } from 'react';

export type LeasingProductBrandsContextProps = {
  brands: string[];
  setBrands: (brands: string[]) => void;
  clearBrands: () => void;
};

export const LeasingProductBrandsContext = createContext<LeasingProductBrandsContextProps>({
  brands: [],
  setBrands: (brands: string[]) => {},
  clearBrands: () => {},
});
