import { Autocomplete } from 'components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MissingLesseeListRequest } from 'schema/serverTypes';
import { useMissingDocumentTypeListRequest } from 'services/api';
import { MissingFilterFormProps } from './types';
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteChangeDetails,
} from '@material-ui/lab/useAutocomplete';
import { useController } from 'react-hook-form';

export type MissingDocumentTypeAutocompleteProps = Pick<MissingFilterFormProps, 'control'> & {
  count?: number;
};

export const MissingDocumentTypeAutocomplete = (props: MissingDocumentTypeAutocompleteProps) => {
  const { control, count = 20 } = props;

  const { t } = useTranslation();

  const {
    field: { onChange, value: documentTypes },
  } = useController({
    control,
    name: 'documentType',
    defaultValue: [],
  });

  const [documentType, setDocumentType] = useState('');

  useEffect(() => {
    if (documentTypes === undefined && documentType !== undefined && documentType !== '') {
      setDocumentType('');
    }
  }, [documentType, documentTypes, setDocumentType]);

  const request = useMemo<MissingLesseeListRequest>(() => {
    return {
      count,
    };
  }, [count]);

  const { data = [], isLoading } = useMissingDocumentTypeListRequest(request);
  const options = data.filter((t) => !(documentTypes ?? []).includes(t));

  const handleOnInputChange = useCallback(
    (_ev: React.ChangeEvent<{}>, value: string, _reason: AutocompleteInputChangeReason) => {
      setDocumentType(value);
    },
    [setDocumentType]
  );

  const handleOnChange = useCallback(
    (
      _e: React.ChangeEvent<{}>,
      value: string | null,
      _reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<string | null>
    ) => {
      if (value === null) {
        onChange([]);
        setDocumentType('');
      } else {
        onChange(documentTypes ? [...documentTypes.filter((v) => v !== value), value] : [value]);
        setDocumentType('');
      }
    },
    [onChange, documentTypes]
  );

  return (
    <Autocomplete<string | null>
      label={t('Document type')}
      options={options}
      inputValue={documentType}
      onInputChange={handleOnInputChange}
      onChange={handleOnChange}
      value={null}
      loading={isLoading}
      variant="standard"
    />
  );
};
