import { Route } from 'react-router-dom';
import { Critical, ContractCommentFormPanel } from 'components';
import { useErrorContext } from 'components/tasks';
import { useTranslation } from 'react-i18next';
import { IssueSource } from 'schema/serverTypes';
import { ContractsDataGrid } from 'components';

export const ContractsPage = () => {
  const { t } = useTranslation();

  useErrorContext({
    title: t('Contracts'),
    source: IssueSource.contracts,
  });

  return (
    <>
      <Critical />
      <Route path="/contracts">
        <Route path="/contracts" component={ContractsDataGrid} />
        <Route path="/contracts/comments" component={ContractCommentFormPanel} exact />
      </Route>
    </>
  );
};
