import { useCallback } from 'react';
import { useWatch, Control, UseFormSetValue } from 'react-hook-form';
import { ContractFilterFormValues } from './types';
import { MissingChip } from '../missing';
import { useTranslation } from 'react-i18next';

export type ContractChipsProps = {
  control: Control<ContractFilterFormValues, object>;
  setValue: UseFormSetValue<ContractFilterFormValues>;
  hideManagers?: boolean;
  hideCounterparties?: boolean;
};

const useEmptyStatus = (emptyLabel: string) => {
  return useCallback(
    (option: string) => {
      if (option === '') {
        return emptyLabel;
      }
      return option ?? emptyLabel;
    },
    [emptyLabel]
  );
};

export const ContractChips = (props: ContractChipsProps) => {
  const { control, setValue } = props;

  const lesseeInn = useWatch({ control, name: 'lesseeInn' });
  const dealerInn = useWatch({ control, name: 'dealerInn' });
  const statuses = useWatch({ control, name: 'status' });

  const onLesseeInnRemove = useCallback(
    (id: string) => {
      const inns = lesseeInn?.filter((v) => v.lesseeInn !== id);
      setValue('lesseeInn', inns);
    },
    [lesseeInn, setValue]
  );

  const onDealerInnRemove = useCallback(
    (id: string) => {
      const inns = dealerInn?.filter((v) => v.dealerInn !== id);
      setValue('dealerInn', inns);
    },
    [dealerInn, setValue]
  );

  const { t } = useTranslation();
  const notSet = t('NotSet');

  const onStatusRemove = useCallback(
    (id: string) => {
      const status = id !== notSet ? id : '';
      const values = statuses?.filter((v) => v !== status);
      setValue('status', values);
    },
    [statuses, notSet, setValue]
  );

  const getStatus = useEmptyStatus(notSet);

  const statusesChips = statuses?.map((value) => {
    const status = getStatus(value);
    return (
      <MissingChip label={status} id={status} key={`status-${status}`} onRemove={onStatusRemove} />
    );
  });

  const lesseeInnChips = lesseeInn?.map((value) => {
    const { lesseeInn, name } = value;
    return (
      <MissingChip
        label={name}
        id={lesseeInn}
        key={`lesseeInn-${lesseeInn}`}
        onRemove={onLesseeInnRemove}
      />
    );
  });

  const dealerInnChips = dealerInn?.map((value) => {
    const { dealerInn, name } = value;
    return (
      <MissingChip
        label={name}
        id={dealerInn}
        key={`dealerInn-${dealerInn}`}
        onRemove={onDealerInnRemove}
      />
    );
  });

  return (
    <>
      {lesseeInnChips}
      {dealerInnChips}
      {statusesChips}
    </>
  );
};
