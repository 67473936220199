import { useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { Control, useForm, useWatch } from 'react-hook-form';
import { useDebounce } from 'use-debounce';
import { IssueFilterTabs, IssuePagedList } from 'schema/serverTypes';
import { useIssuesBackendQuery } from 'services/api/useIssuesBackend';
import { SearchFilterFormValues } from '../types';
import { usePaging } from '../../paging';

const useSearchParams = (searchParams: SearchFilterFormValues) => {
  const {
    owned,
    search,
    assignee,
    author,
    status,
    type,
    group,
    quotaIds,
    fromDate,
    toDate,
    hideCompleted,
    page = 1,
    isPulse = false,
    pageSize = 20,
    contractNumber,
  } = searchParams;

  return useMemo(() => {
    const searchParams = new URLSearchParams();

    if (search && search !== '') {
      searchParams.set('search', search);
    }
    if (contractNumber && contractNumber !== '') {
      searchParams.set('contractNumber', contractNumber);
    }
    if (assignee) {
      assignee.forEach((t) => searchParams.append('assigneeId', t.id));
    }
    if (author) {
      author.forEach((t) => searchParams.append('authorId', t.id));
    }
    if (status) {
      status.forEach((t) => searchParams.append('statuses', t));
    }
    if (type) {
      type.forEach((t) => searchParams.append('types', t));
    }
    searchParams.set('isPulse', isPulse ? 'true' : 'false');

    if (hideCompleted !== undefined) {
      searchParams.set('hideCompleted', hideCompleted === true ? 'true' : 'false');
    }

    if (owned) {
      searchParams.set('owned', owned === true ? 'true' : 'false');
    }
    if (group) {
      searchParams.set('group', group);
    }

    if (quotaIds) {
      searchParams.set('quotaIds', quotaIds.toString());
    }

    if (fromDate) {
      searchParams.set('fromDate', fromDate.toString());
    }

    if (toDate) {
      searchParams.set('toDate', toDate.toString());
    }
    searchParams.set('page', page.toString());
    searchParams.set('pageSize', pageSize.toString());

    return searchParams;
  }, [
    owned,
    search,
    assignee,
    author,
    status,
    type,
    hideCompleted,
    quotaIds,
    fromDate,
    toDate,
    page,
    group,
    pageSize,
    isPulse,
    contractNumber,
  ]);
};

export const useIssuesFilter = (isPulse?: boolean) => {
  const filterParams = sessionStorage.errorFilterValues
    ? JSON.parse(sessionStorage.errorFilterValues)
    : null;

  const defaultValues: SearchFilterFormValues = useMemo(() => {
    return {
      search: '',
      group: IssueFilterTabs.Mine,
      isPulse,
      page: 1,
      pageSize: 20,
      hideCompleted: true,
    };
  }, [isPulse]);

  const { control, reset, setValue } = useForm<SearchFilterFormValues>({
    mode: 'onBlur',
    defaultValues: { ...defaultValues, ...filterParams },
  });

  const handleOnReset = useCallback(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  return {
    control,
    handleOnReset,
    setValue,
  };
};

export type UseErrorsDataProps = {
  control: Control<SearchFilterFormValues, any>;
};

export const useErrorsData = (props: UseErrorsDataProps) => {
  const { control } = props;

  const searchValue = useWatch({ control, name: 'search' });
  const [search] = useDebounce(searchValue ?? '', 500);

  const defaultPageSize = useWatch({ control, name: 'pageSize' });
  const defaultPage = useWatch({ control, name: 'page' });

  const { page, pageSize, onPageChanged, onPageSizeChanged } = usePaging({
    pageSize: defaultPageSize,
    page: defaultPage,
  });

  const assignee = useWatch({ control, name: 'assignee' });
  const author = useWatch({ control, name: 'author' });
  const status = useWatch({ control, name: 'status' });
  const type = useWatch({ control, name: 'type' });
  const quotaIds = useWatch({ control, name: 'quotaIds' });
  const fromDate = useWatch({ control, name: 'fromDate' });
  const toDate = useWatch({ control, name: 'toDate' });
  const hideCompleted = useWatch({ control, name: 'hideCompleted' });
  const group = useWatch({ control, name: 'group' });
  const isPulse = useWatch({ control, name: 'isPulse' });

  const contractNumberValue = useWatch({ control, name: 'contractNumber' });
  const [contractNumber] = useDebounce(contractNumberValue ?? '', 500);

  const params = useMemo(() => {
    return {
      search,
      assignee,
      author,
      status,
      type,
      quotaIds,
      fromDate,
      toDate,
      page,
      pageSize,
      group,
      hideCompleted,
      isPulse,
      contractNumber,
    };
  }, [
    search,
    assignee,
    author,
    status,
    type,
    quotaIds,
    fromDate,
    toDate,
    pageSize,
    page,
    group,
    hideCompleted,
    isPulse,
    contractNumber,
  ]);

  const onMount = useRef(false);

  useLayoutEffect(() => {
    if (onMount.current) {
      onPageChanged(1);
    } else {
      onMount.current = true;
    }
  }, [
    search,
    assignee,
    author,
    status,
    type,
    quotaIds,
    fromDate,
    toDate,
    pageSize,
    group,
    hideCompleted,
    isPulse,
    contractNumber,
    onPageChanged,
  ]);

  useEffect(() => {
    sessionStorage.setItem('errorFilterValues', JSON.stringify(params));
  }, [params]);

  const searchParams = useSearchParams(params);

  const { data, isLoading: loading } = useIssuesBackendQuery<IssuePagedList>({
    relativeUrl: '',
    searchParams,
    options: {
      keepPreviousData: false,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    },
  });

  const issues = data?.data ?? [];

  return {
    issues,
    loading,
    paging: {
      page: data?.page ?? 1,
      pageSize: pageSize ?? 20,
      pageCount: data?.pageCount ?? 0,
      totalCount: data?.totalCount ?? 0,
      dataCount: issues.length,
      onPageChanged,
      onPageSizeChanged,
    },
  };
};
