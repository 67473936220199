import { useCallback } from 'react';
import { Chip } from '../Chips';

type IssueChipProps = {
  label: string;
  id?: string;
  onRemove: (label: string) => void;
};

export const IssueChip = (props: IssueChipProps) => {
  const { label, id, onRemove } = props;

  const onRemoveHandler = useCallback(() => {
    if (id) {
      onRemove(id);
    } else {
      onRemove(label);
    }
  }, [label, id, onRemove]);

  return <Chip label={label} id={label} key={`missingId-${label}`} onRemove={onRemoveHandler} />;
};
