import dayjs from 'dayjs';

export const getMonth = (number: number, startDate?: string) => {
  let date = dayjs(startDate);

  date = date.add(number, 'months');

  const month = date.month() + 1;

  return month;
};
