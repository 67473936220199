import { useBackendQuery, QueryOptions, MutationOptions, useBackendMutation } from '.';
import { calculationUrl } from 'services/urls';
import {
  MissingListRequest,
  MissingPagedList,
  MissingCounterpartyViewModel,
  MissingLesseeListRequest,
  MissingContractListRequest,
  MissingStatusListRequest,
  MissingDocumentTypeListRequest,
  MissingViewModel,
  MissingManagerListRequest,
  MissingManagerViewModel,
  MissingOverdueViewModel,
  MissingHistoryRequest,
  MissingHistoryTime,
  MissingHistoryTab,
  MissingImportDates,
  MissingOverdueTab,
  MissingOverdueListRequest,
  MissingOverduePagedList,
  MissingDealerListRequest,
} from 'schema/serverTypes';

const createUrlFromParts = (relativeUrl?: string, searchParams?: URLSearchParams) => {
  const urlParts = relativeUrl ? relativeUrl.trim().split('/') : [];
  const parts = [calculationUrl, 'api', 'v1', 'missings', ...urlParts].filter((t) => t !== '');
  const url = parts.join('/');
  return searchParams ? `${url}?${searchParams}` : url;
};

const createOptions = <TResponse>(options: QueryOptions<TResponse> | undefined = {}) => {
  const defaultOptions: QueryOptions<TResponse> = {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: false,
  };
  return {
    ...defaultOptions,
    ...options,
  } as QueryOptions<TResponse>;
};

type MissingBackendQueryOptions<TResponse> = {
  relativeUrl?: string;
  searchParams?: URLSearchParams;
  options?: QueryOptions<TResponse>;
};

const useMissingBackendQuery = <TResponse>(options: MissingBackendQueryOptions<TResponse>) =>
  useBackendQuery(
    createUrlFromParts(options.relativeUrl, options.searchParams),
    options.relativeUrl === ''
      ? `Missings-${options.searchParams}`
      : `Missings-${options.relativeUrl}-${options.searchParams}`,
    createOptions(options.options)
  );

export const useMissingListRequest = (request: MissingListRequest) => {
  const {
    inn = [],
    contractNumber,
    documentType = [],
    status = [],
    sortBy,
    order,
    page,
    pageSize,
    debt,
    managerId = [],
    tabId,
    debtDays = 0,
  } = request;

  const searchParams = new URLSearchParams();

  if (inn.length > 0) {
    inn.forEach((t) => searchParams.append('inn', t));
  }
  if (contractNumber) {
    searchParams.set('contractNumber', contractNumber);
  }
  if (documentType.length > 0) {
    documentType.forEach((t) => searchParams.append('documentType', t));
  }
  if (status.length > 0) {
    status.forEach((t) => searchParams.append('status', t));
  }
  if (managerId.length > 0) {
    managerId.forEach((t) => searchParams.append('managerId', t));
  }
  if (sortBy) {
    searchParams.set('sortBy', sortBy);
  }
  if (order) {
    searchParams.set('order', order);
  }
  if (page) {
    searchParams.set('page', page.toString());
  }
  if (pageSize) {
    searchParams.set('pageSize', pageSize.toString());
  }
  if (debt !== undefined) {
    searchParams.set('debt', debt.toString());
    if (debt && debtDays > 0) {
      searchParams.set('debtDays', debtDays.toString());
    }
  }
  if (tabId !== undefined) {
    searchParams.set('tabId', tabId.toString());
  }

  return useMissingBackendQuery<MissingPagedList>({
    relativeUrl: '',
    searchParams,
  });
};

export const useMissingLesseeListRequest = (request: MissingLesseeListRequest) => {
  const { search, count } = request;

  const searchParams = new URLSearchParams();

  if (search && search !== '') {
    searchParams.set('search', search);
  }
  if (count) {
    searchParams.set('count', count.toString());
  }

  return useMissingBackendQuery<MissingCounterpartyViewModel[]>({
    relativeUrl: 'lessees',
    searchParams,
  });
};

export const useMissingDealerListRequest = (request: MissingDealerListRequest) => {
  const { search, count } = request;

  const searchParams = new URLSearchParams();

  if (search && search !== '') {
    searchParams.set('search', search);
  }
  if (count) {
    searchParams.set('count', count.toString());
  }

  return useMissingBackendQuery<MissingCounterpartyViewModel[]>({
    relativeUrl: 'dealers',
    searchParams,
  });
};

export const useMissingManagerListRequest = (request: MissingManagerListRequest) => {
  const { search, count, tabId } = request;

  const searchParams = new URLSearchParams();

  if (search && search !== '') {
    searchParams.set('search', search);
  }
  if (count) {
    searchParams.set('count', count.toString());
  }
  if (tabId) {
    searchParams.set('tabId', tabId.toString());
  }

  return useMissingBackendQuery<MissingManagerViewModel[]>({
    relativeUrl: 'managers',
    searchParams,
  });
};

export const useMissingContractListRequest = (request: MissingContractListRequest) => {
  const { search, count } = request;

  const searchParams = new URLSearchParams();

  if (search) {
    searchParams.set('search', search);
  }
  if (count) {
    searchParams.set('count', count.toString());
  }

  return useMissingBackendQuery<string[]>({
    relativeUrl: 'contracts',
    searchParams,
  });
};

export const useMissingStatusListRequest = (request: MissingStatusListRequest) => {
  const { search } = request;

  const searchParams = new URLSearchParams();

  if (search) {
    searchParams.set('search', search);
  }

  return useMissingBackendQuery<string[]>({
    relativeUrl: 'statuses',
    searchParams,
  });
};

export const useMissingDocumentTypeListRequest = (request: MissingDocumentTypeListRequest) => {
  const { search } = request;

  const searchParams = new URLSearchParams();

  if (search) {
    searchParams.set('search', search);
  }

  return useMissingBackendQuery<string[]>({
    relativeUrl: 'documentTypes',
    searchParams,
  });
};

export const useMissingRequest = (id: string) => {
  return useMissingBackendQuery<MissingViewModel>({
    relativeUrl: `/${id}`,
  });
};

const getMutationUrl = <TRequest>(relativeUrl: string | ((form: TRequest) => string)) => {
  if (typeof relativeUrl === 'string') {
    return createUrlFromParts(relativeUrl);
  }
  return (form: TRequest) => createUrlFromParts(relativeUrl(form));
};

export const useMissingCommentsBackendMutation = <TRequest, TResponse, TContext = unknown>(
  relativeUrl: string | ((form: TRequest) => string),
  options: MutationOptions<TRequest, TResponse, TContext> | undefined = { method: 'POST' }
) => useBackendMutation(getMutationUrl(relativeUrl), options);

export const useMissingLastImportDateRequest = () => {
  return useMissingBackendQuery<MissingImportDates>({
    relativeUrl: `import/date`,
    options: {
      cacheTime: 10000,
      refetchOnReconnect: true,
    },
  });
};

export const useMissingOverdueRequest = (contractNumber: string) => {
  const searchParams = new URLSearchParams();
  searchParams.append('contractNumber', contractNumber);
  return useMissingBackendQuery<MissingOverdueViewModel>({
    relativeUrl: 'overdue/comments',
    searchParams,
  });
};

export const useMissingHistoryRequest = (request: Partial<MissingHistoryRequest>) => {
  const { time = MissingHistoryTime.Weeks, documentType = [], managerId = [] } = request;

  const searchParams = new URLSearchParams();
  searchParams.append('time', time);

  if (documentType.length > 0) {
    documentType.forEach((t) => searchParams.append('documentType', t));
  }

  if (managerId.length > 0) {
    managerId.forEach((t) => searchParams.append('managerId', t));
  }

  const { data: tabs, isLoading } = useMissingBackendQuery<MissingHistoryTab[]>({
    relativeUrl: 'history',
    searchParams,
    options: {
      refetchOnMount: false,
      refetchOnReconnect: true,
    },
  });

  return { tabs, isLoading };
};

export const useMissingOverdueTopRequest = (
  tabId?: number,
  options: QueryOptions<MissingOverdueTab> | undefined = undefined
) => {
  const searchParams = new URLSearchParams();

  if (tabId) {
    searchParams.append('tabId', tabId?.toString());
  }

  const { data: tab, isLoading } = useMissingBackendQuery<MissingOverdueTab>({
    relativeUrl: 'overdue/top',
    searchParams,
    options,
  });

  return { tab, isLoading };
};

export const useMissingSetDeliveryStatusRequest = (missingId: number, onSuccess?: () => void) =>
  useMissingCommentsBackendMutation(`${missingId}/deliveryStatus`, {
    method: 'PUT',
    onSuccess,
  });

export const useMissingSetDeliveryNotFoundStatusRequest = (
  missingId: number,
  onSuccess?: () => void
) =>
  useMissingCommentsBackendMutation(`${missingId}/deliveryStatus`, {
    method: 'DELETE',
    onSuccess,
  });

export const useMissingContractStatusListRequest = (request: MissingStatusListRequest) => {
  const { search } = request;

  const searchParams = new URLSearchParams();

  if (search) {
    searchParams.set('search', search);
  }

  return useMissingBackendQuery<string[]>({
    relativeUrl: 'overdue/statuses',
    searchParams,
  });
};

export const useMissingOverdueListRequest = (request: MissingOverdueListRequest) => {
  const {
    inn = [],
    contractNumber,
    sortBy,
    order,
    page,
    pageSize,
    tabId = 0,
    contractStatus = [],
    managerEmail = [],
  } = request;

  const searchParams = new URLSearchParams();

  searchParams.set('tabId', tabId.toString());

  if (inn.length > 0) {
    inn.forEach((t) => searchParams.append('inn', t));
  }
  if (contractNumber) {
    searchParams.set('contractNumber', contractNumber);
  }
  if (contractStatus.length > 0) {
    contractStatus.forEach((t) => searchParams.append('contractStatus', t));
  }
  if (managerEmail.length > 0) {
    managerEmail.forEach((t) => searchParams.append('managerEmail', t));
  }
  if (sortBy) {
    searchParams.set('sortBy', sortBy);
  }
  if (order) {
    searchParams.set('order', order);
  }
  if (page) {
    searchParams.set('page', page.toString());
  }
  if (pageSize) {
    searchParams.set('pageSize', pageSize.toString());
  }

  return useMissingBackendQuery<MissingOverduePagedList>({
    relativeUrl: '/overdue',
    searchParams,
  });
};
