import {
  AddContractCommentModel,
  ContractDealersRequest,
  ContractDocumentListViewModel,
  ContractLesseesRequest,
  ContractListRequest,
  ContractNumberRequest,
  ContractPagedList,
  ContractStatusesRequest,
  ContractViewModel,
} from 'schema/serverTypes';
import { createUrlFromParts, createOptions, BackendQueryOptions, EmptyResponse } from './types';
import { useBackendQuery, useBackendMutation, MutationOptions } from './useBackend';

const useContractsBackendQuery = <TResponse>(options: BackendQueryOptions<TResponse>) =>
  useBackendQuery(
    createUrlFromParts('contracts', options.relativeUrl, options.searchParams),
    options.relativeUrl === ''
      ? `Contracts-${options.searchParams}`
      : `Contracts-${options.relativeUrl}-${options.searchParams}`,
    createOptions(options.options)
  );

export const useContractListRequest = (request: ContractListRequest) => {
  const {
    contractNumber,
    lesseeInn,
    dealerInn,
    sortBy,
    order,
    page,
    pageSize,
    tabId = 0,
    status = [],
  } = request;

  const searchParams = new URLSearchParams();
  searchParams.set('tabId', tabId.toString());

  if (contractNumber !== undefined && contractNumber !== '') {
    searchParams.set('contractNumber', contractNumber);
  }
  if (lesseeInn && lesseeInn.length > 0) {
    lesseeInn.forEach((t) => searchParams.append('lesseeInn', t));
  }
  if (dealerInn && dealerInn.length > 0) {
    dealerInn.forEach((t) => searchParams.append('dealerInn', t));
  }
  if (sortBy) {
    searchParams.set('sortBy', sortBy);
  }
  if (order) {
    searchParams.set('order', order);
  }
  if (page) {
    searchParams.set('page', page.toString());
  }
  if (pageSize) {
    searchParams.set('pageSize', pageSize.toString());
  }

  status.forEach((t) => searchParams.append('status', t.trim()));

  return useContractsBackendQuery<ContractPagedList>({
    relativeUrl: '',
    searchParams,
    options: {
      refetchOnWindowFocus: true,
    },
  });
};

export const useContractStatusesRequest = (request: ContractStatusesRequest) => {
  const { search, count } = request;

  const searchParams = new URLSearchParams();

  if (search && search !== '') {
    searchParams.set('search', search);
  }
  if (count) {
    searchParams.set('count', count.toString());
  }

  return useContractsBackendQuery<string[]>({
    relativeUrl: 'contractStatuses',
    searchParams,
  });
};

export const useContractNumberRequest = (request: ContractNumberRequest) => {
  const { search, count } = request;

  const searchParams = new URLSearchParams();

  if (search && search !== '') {
    searchParams.set('search', search);
  }
  if (count) {
    searchParams.set('count', count.toString());
  }

  return useContractsBackendQuery<string[]>({
    relativeUrl: 'contractNumbers',
    searchParams,
  });
};

type Counterparty = {
  inn: 'string';
  name: 'string';
  fullName: 'string';
};

export const useContractLesseesRequest = (request: ContractLesseesRequest) => {
  const { search = '', count, owned, ownerId = '', group = '' } = request;

  const searchParams = new URLSearchParams();

  if (owned !== undefined) {
    searchParams.set('owned', owned.toString());
  }
  if (ownerId !== '') {
    searchParams.set('ownerId', ownerId);
  }
  if (group !== '') {
    searchParams.set('group', group);
  }
  if (search !== '') {
    searchParams.set('search', search);
  }
  if (count) {
    searchParams.set('count', count.toString());
  }

  return useContractsBackendQuery<Counterparty[]>({
    relativeUrl: 'lessees',
    searchParams,
  });
};

export const useContractDealersRequest = (request: ContractDealersRequest) => {
  const { search = '', count, owned, ownerId = '', group = '' } = request;

  const searchParams = new URLSearchParams();

  if (owned !== undefined) {
    searchParams.set('owned', owned.toString());
  }
  if (ownerId !== '') {
    searchParams.set('ownerId', ownerId);
  }
  if (group !== '') {
    searchParams.set('group', group);
  }
  if (search !== '') {
    searchParams.set('search', search);
  }
  if (count) {
    searchParams.set('count', count.toString());
  }

  return useContractsBackendQuery<Counterparty[]>({
    relativeUrl: 'dealers',
    searchParams,
  });
};

export const useAddCommentMutation = (
  contractNumber: string,
  options: MutationOptions<AddContractCommentModel, ContractViewModel> | undefined
) => {
  let searchParams = new URLSearchParams();
  searchParams.append('contractNumber', contractNumber);

  return useBackendMutation<AddContractCommentModel, ContractViewModel>(
    createUrlFromParts('contracts/comments', undefined, searchParams),
    {
      ...options,
      method: 'POST',
    }
  );
};

export const useUpdateCommentMutation = (
  contractNumber: string,
  commentId: number,
  options: MutationOptions<AddContractCommentModel, ContractViewModel> | undefined
) => {
  let searchParams = new URLSearchParams();
  searchParams.append('contractNumber', contractNumber);

  return useBackendMutation<AddContractCommentModel, ContractViewModel>(
    createUrlFromParts(`contracts/comments/${commentId}`, undefined, searchParams),
    {
      ...options,
      method: 'PUT',
    }
  );
};

export const useSendTo1CMutation = (
  quotaId: number,
  options: MutationOptions<unknown, EmptyResponse> | undefined = undefined
) => {
  return useBackendMutation<unknown, EmptyResponse>(
    createUrlFromParts('contracts', `${quotaId}/external`),
    {
      ...options,
      method: 'POST',
    }
  );
};

export const useContractDocumentRequest = (contractNumber: string) =>
  useContractsBackendQuery<ContractDocumentListViewModel[]>({
    relativeUrl: `${contractNumber}/documents`,
  });
