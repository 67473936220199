import { Box, createStyles, Drawer, DrawerProps, makeStyles, Theme } from '@material-ui/core';
import { FC, useCallback, useEffect, useState } from 'react';
import { useGoBack } from '../../hooks';
import { IconSprite } from '../icons';
import { themeOrange as theme } from '../../theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: (props: any) => props.width || 400,
    },
    closeButtonWrapper: {
      position: 'absolute',
      width: '100%',
    },
    closeButton: {
      cursor: 'pointer',
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
      '& img': {
        maxWidth: '10px',
      },
    },
  })
);

export const Sidebar: FC<{ url?: string; width?: number; onClose?: () => void } & DrawerProps> = ({
  children,
  url,
  onClose,
  ...props
}) => {
  const classes = useStyles(props);
  const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false);
  const goBack = useGoBack();

  useEffect(() => {
    setDrawerOpen(true);
  }, [setDrawerOpen]);

  const handleOnClose = useCallback(() => {
    setDrawerOpen(false);
    if (url) {
      setTimeout(() => goBack(url), 500);
    }
    if (onClose) {
      setTimeout(() => onClose(), 500);
    }
  }, [goBack, setDrawerOpen, url, onClose]);

  return (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      classes={{
        paper: classes.paper,
      }}
      onClose={handleOnClose}
      transitionDuration={500}
      {...props}
    >
      <Box display="flex" justifyContent="flex-end" className={classes.closeButtonWrapper}>
        <div onClick={handleOnClose}>
          <IconSprite
            icon={'cross-small'}
            width="10px"
            height="10px"
            color={theme.palette.secondary.dark}
            className={classes.closeButton}
          />
        </div>
      </Box>
      {children}
    </Drawer>
  );
};
