import { CircularProgress, Grid } from '@material-ui/core';
import { Breadcrumbs, IconSprite, Paging, Button } from 'components';
import { useIndustriesData } from './useIndustriesData';
import { FilterForm } from './FilterForm';
import { IndustriesTable } from './IndustriesTable';
import { useTranslation } from 'react-i18next';
import React, { useCallback } from 'react';
import { AddButton } from '../AddButton';
import { useHistory } from 'react-router';
import { themeOrange as theme } from 'theme';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useDownloadDictionary } from '../../../services/api/useIndustryBackend';
import { PageTitle } from 'components/utils/pageTitle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actions: {
      '& > *': {
        marginLeft: theme.spacing(1.5),
      },
    },
  }),
);

export const IndustriesDataGrid = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { filter, paging, ...dataProps } = useIndustriesData();
  const history = useHistory();

  const pageTitle = t('Industries') + ' - Bumblebee';
  PageTitle(pageTitle);

  const addHandler = () => {
    history.push('/dictionaries/industries/add');
  };

  const { isLoading, downloadAsync } = useDownloadDictionary();
  const downloadHandler = useCallback(async () => {
    await downloadAsync();
  }, [downloadAsync]);

  return (
    <>
      <Breadcrumbs link={'/dictionaries'} text={t('Section.dictionaries')} />
      <Grid container spacing={2} direction="column">
        <Grid container item justifyContent="space-between" alignItems="center">
          <Grid item>
            <FilterForm {...filter} />
          </Grid>
          <Grid item className={classes.actions}>
            <AddButton onClick={addHandler} />
            <Button variant="outlined" onClick={downloadHandler} disabled={isLoading}>
              <IconSprite
                icon={'download'}
                width={'12px'}
                height={'12px'}
                color={theme.palette.secondary.dark}
              />
              {isLoading && (
                <CircularProgress
                  size={24}
                  style={{
                    color: theme.palette.secondary.dark,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Button>
          </Grid>
        </Grid>
        <Grid container item direction="column">
          <Grid item>
            <IndustriesTable {...dataProps} />
          </Grid>
          <Grid item>
            <Paging {...paging} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
