import {
  createStyles,
  makeStyles,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  Theme,
} from '@material-ui/core';
import {
  useController,
  FieldPath,
  FieldValues,
  UseControllerProps,
  FieldError,
} from 'react-hook-form';
import { useCallback, useState } from 'react';
import { UseFormClearErrors, UseFormSetError } from 'react-hook-form/dist/types/form';
import { Button, Chip } from 'components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    add: {
      padding: 0,
    },
    remove: {
      marginTop: theme.spacing(1),
    },
  })
);

type InputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Omit<MuiTextFieldProps, 'name' | 'helperText' | 'error' | 'defaultValue'> &
  Omit<UseControllerProps<TFieldValues, TName>, 'defaultValue'> & {
    defaultValue?: string;
  } & { setError: UseFormSetError<TFieldValues>; clearErrors: UseFormClearErrors<TFieldValues> };

export function EmailsInput<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(props: InputProps<TFieldValues, TName>) {
  const classes = useStyles();

  const {
    name,
    variant = 'outlined',
    size = 'small',
    fullWidth = true,
    control,
    shouldUnregister,
    rules,
    setError,
    clearErrors,
    ...rest
  } = props;

  const {
    field: { ref, value = [], onChange },
    fieldState: { invalid, error },
  } = useController<TFieldValues, TName>({
    control,
    name,
    shouldUnregister,
    rules,
  });

  const onRemove = useCallback(
    (id: string) => {
      const newValues = value?.filter((email) => email !== id);
      onChange(newValues);
    },
    [onChange, value]
  );

  const [currValue, setCurrValue] = useState<string>('');
  const onAdd = useCallback(() => {
    const emailPattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (currValue !== '' && emailPattern.test(currValue)) {
      const newValues = [...value, currValue];
      onChange(newValues);
      setCurrValue('');
      clearErrors(name);
    } else {
      setError(name, { type: 'custom', message: 'Invalid email format' }, { shouldFocus: true });
    }
  }, [currValue, name, value, onChange, clearErrors, setError]);

  const onChangeHandler = useCallback(
    (event) => {
      setCurrValue(event.target.value);
    },
    [setCurrValue]
  );

  const helperText = error !== undefined ? (error as FieldError).message : undefined;

  return (
    <>
      <MuiTextField
        {...rest}
        onChange={onChangeHandler}
        value={currValue}
        variant={variant}
        fullWidth={fullWidth}
        size={size}
        inputRef={ref}
        helperText={helperText}
        error={invalid}
        InputProps={{
          endAdornment: (
            <Button variant={'inInput'} onClick={onAdd} className={classes.add}>
              +
            </Button>
          ),
        }}
      />
      <div className={classes.remove}>
        {value?.map((email: string) => {
          return <Chip label={email} id={email} key={email} onRemove={onRemove} />;
        })}
      </div>
    </>
  );
}
