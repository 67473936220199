import { useTranslation } from 'react-i18next';
import { FixedAmountInput } from '../AmountInput';
import { useCalculationFormContext } from '../types';
import { useWatch } from 'react-hook-form';
import { useEffect } from 'react';
import { AmountType, Currency } from 'schema/serverTypes';
import { useCurrencyExchangerContext } from '../../CurrencyExchangerProvider';

export const LeaseTotalPriceInput = () => {
  const { getActualRate, exchangeMoney } = useCurrencyExchangerContext();
  const { control, setValue, getValues } = useCalculationFormContext();

  const saleTotalPrice =
    useWatch({
      control,
      name: 'itemPrice',
    }) ?? 0;

  const currencyRate =
    useWatch({
      control,
      name: 'currencyRate',
    }) ?? 0;

  const currencyLease = useWatch({
    control,
    name: 'currencyLease',
  });

  const currencySale = useWatch({
    control,
    name: 'currencySale',
  });

  useEffect(() => {
    const currentLeaseTotalPrice = getValues('leaseItemCost') ?? 0;
    const lease = currencyLease as Currency;
    const sale = currencySale as Currency;
    const actualRate = getActualRate(sale, lease, currencyRate);
    const leaseTotalPrice = exchangeMoney(saleTotalPrice, sale, lease, actualRate);

    if (leaseTotalPrice !== currentLeaseTotalPrice) {
      setValue('leaseItemCost', leaseTotalPrice);
    }
  }, [
    saleTotalPrice,
    currencyLease,
    currencyRate,
    currencySale,
    getValues,
    setValue,
    exchangeMoney,
    getActualRate,
  ]);

  const { t } = useTranslation();

  return (
    <FixedAmountInput
      name="leaseItemCost"
      label={t('LeaseItemCost')}
      amountMode={AmountType.Money}
      disabled
    />
  );
};
