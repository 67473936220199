import { IconButton } from '@material-ui/core';
import { IconSprite } from 'components/icons/IconSprite';
import { themeOrange as theme } from 'theme';

export type DeleteButtonProps = {
  onClick: () => void;
};

export const DeleteButton = (props: DeleteButtonProps) => {
  const { onClick } = props;

  return (
    <IconButton aria-label="edit" onClick={onClick}>
      <IconSprite
        width="14px"
        color={theme.palette.text.primary}
        hoverColor={theme.palette.primary.main}
        icon="delete"
      />
    </IconButton>
  );
};
