import { useMissingListRequest } from 'services/api';
import { MissingListRequest, MissingSortBy, SortOrder } from 'schema/serverTypes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MissingFilterFormProps, MissingFilterFormValues } from './types';
import { useForm, useWatch } from 'react-hook-form';
import { usePaging } from 'components/paging';

export const useMissingListData = (filterName: string, defaultValues?: MissingFilterFormValues) => {
  const filterParams = sessionStorage[filterName] ? JSON.parse(sessionStorage[filterName]) : null;

  const { control, reset, setValue } = useForm<MissingFilterFormValues>({
    mode: 'all',
    defaultValues: { ...filterParams, ...defaultValues },
  });

  const [sortBy, setSortBy] = useState<MissingSortBy>(MissingSortBy.PlannedDeliveryDate);
  const [order, setOrder] = useState(SortOrder.asc);

  const onReset = useCallback(() => {
    reset({});
  }, [reset]);

  const { page, pageSize, onPageChanged, onPageSizeChanged } = usePaging();
  const inn = useWatch({ control, name: 'inn' });
  const contractNumber = useWatch({ control, name: 'contractNumber' });
  const status = useWatch({ control, name: 'status' });
  const documentType = useWatch({ control, name: 'documentType' });
  const debt = useWatch({ control, name: 'debt' });
  const managerId = useWatch({ control, name: 'managerId' });
  const tabId = useWatch({ control, name: 'tabId' });
  const debtDays = useWatch({ control, name: 'debtDays' });

  useEffect(() => {
    if (debt === false && debtDays !== undefined && debtDays > 0) {
      setValue('debtDays', undefined);
    }
  }, [debt, debtDays, setValue]);

  const params = useMemo(() => {
    return {
      inn,
      contractNumber,
      status,
      documentType,
      debt,
      managerId,
      tabId,
      debtDays,
      pageSize,
      page,
    };
  }, [inn, contractNumber, status, documentType, debt, managerId, tabId, debtDays, pageSize, page]);

  useEffect(() => {
    onPageChanged(1);
  }, [inn, contractNumber, status, documentType, debt, managerId, tabId, debtDays, onPageChanged]);

  useEffect(() => {
    sessionStorage.setItem(filterName, JSON.stringify(params));
  }, [params, filterName]);

  const managerIds = useMemo(() => {
    return managerId?.map((t) => t.id);
  }, [managerId]);

  const inns = useMemo(() => {
    return inn?.map((t) => t.inn);
  }, [inn]);

  const request: MissingListRequest = {
    inn: inns,
    contractNumber,
    status,
    documentType,
    page,
    pageSize,
    debt,
    managerId: managerIds,
    sortBy,
    order,
    tabId,
    debtDays,
  };

  const { isLoading, data: list } = useMissingListRequest(request);

  const handleSortBy = useCallback(
    (sortBy: MissingSortBy) => {
      setSortBy(sortBy);
    },
    [setSortBy]
  );

  const handleSortOrder = useCallback(
    (order: SortOrder) => {
      setOrder(order);
    },
    [setOrder]
  );

  return useMemo(() => {
    const rows = list?.data ?? [];
    const pageCount = list?.pageCount ?? 0;
    const totalCount = list?.totalCount ?? 0;

    return {
      isLoading,
      rows,
      sorting: {
        sortBy,
        order,
        setSortBy: handleSortBy,
        setOrder: handleSortOrder,
      },
      paging: {
        pageCount,
        totalCount,
        page,
        pageSize,
        dataCount: rows.length,
        onPageChanged,
        onPageSizeChanged,
      },
      filter: {
        control,
        onReset,
        setValue,
      } as MissingFilterFormProps,
    };
  }, [
    isLoading,
    list,
    page,
    pageSize,
    control,
    sortBy,
    order,
    onReset,
    setValue,
    handleSortBy,
    handleSortOrder,
    onPageChanged,
    onPageSizeChanged,
  ]);
};
