import { useMemo } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { FieldArrayRenderProps } from 'react-final-form-arrays';
import { PaymentYearTable } from './PaymentYearTable';
import { CalculationMethod, MonthPaymentOption } from 'schema/serverTypes';
import { YearFields } from './types';
import Box from '@material-ui/core/Box';
import { OnCalculationMethodChanged } from './OnCalculationMethodChanged';
import { OnSeasonalDateChanged } from './OnSeasonalDateChanged';
import { Field } from 'react-final-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    table: {
      width: '50%',
      float: 'left',
      marginBottom: theme.spacing(3),
      '&:nth-child(2n-1)': {
        paddingRight: theme.spacing(1.3),
        '&:last-child': {
          marginBottom: 0,
        },
      },
      '&:nth-child(2n)': {
        float: 'right',
        paddingLeft: theme.spacing(1.3),
        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
    header: {
      marginBottom: 2,
      lineHeight: 1.2,
    },
    year: {
      fontFamily: "'Halvar Breit', sans-serif",
      fontSize: 12,
      fontWeight: 700,
      color: theme.palette.secondary.dark,
    },
  })
);

export type PaymentOptionListProps = FieldArrayRenderProps<MonthPaymentOption, HTMLDivElement> & {
  calculationMethod: CalculationMethod;
  numberOfMonths: number;
  date?: string;
};

export const PaymentOptionList = (props: PaymentOptionListProps) => {
  const classes = useStyles();
  const { fields, date, numberOfMonths, calculationMethod } = props;
  const { map, update } = fields;

  const disabled = calculationMethod === CalculationMethod.SuperSeasonal;

  const years = useMemo(() => {
    const years: YearFields[] = [];
    let currentDate = date ? new Date(date) : new Date();
    currentDate.setMonth(currentDate.getMonth() + 1);
    let current: YearFields | null = null;

    map((name: string, index: number) => {
      const monthField = { name, index, disabled };

      if (disabled && index > 11) {
        return;
      }

      if (current === null) {
        current = {
          year: currentDate.getFullYear(),
          fields: [monthField],
        };
        years.push(current);
      } else {
        const nextDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          currentDate.getDate()
        );

        if (nextDate.getFullYear() !== currentDate.getFullYear()) {
          current = {
            year: nextDate.getFullYear(),
            fields: [],
          };
          years.push(current);
        }
        current.fields.push(monthField);
        currentDate = nextDate;
      }
    });
    return years;
  }, [disabled, map, date]);

  return (
    <div className={classes.root}>
      {years.map((yearProps) => {
        const { year } = yearProps;
        const gridProps = {
          ...yearProps,
          numberOfMonths,
          update,
          date,
        };

        return (
          <div key={year} className={classes.table}>
            <div className={classes.header}>
              <Box className={classes.year}>{year}</Box>
            </div>
            <PaymentYearTable {...gridProps} />
          </div>
        );
      })}
      <Field name="seasonalPaymentOptions.date">
        {({ input }) => {
          return <OnCalculationMethodChanged fields={fields} date={input.value as string} />;
        }}
      </Field>

      <OnSeasonalDateChanged fields={fields} calculationMethod={calculationMethod} />
    </div>
  );
};
