import { useRequiredOnSave } from '../../useRequiredOnSave';
import { useTranslation } from 'react-i18next';
import { Box, createStyles, InputAdornment, makeStyles, TextField, Theme } from '@material-ui/core';
import { NomenclatureViewModel, QuotaNomenclatureViewModel } from 'schema/serverTypes';
import { useDebounce } from 'use-debounce';
import { useNomenclatureListSearchQuery } from 'services/api/useNomenclaturesBackend';
import { useCalculationFormContext } from 'components/calculator2/CalculationForm/types';
import { FieldError, useController, useWatch } from 'react-hook-form';
import { useCallback, useRef, useState } from 'react';
import { AutocompleteChangeReason } from '@material-ui/lab/useAutocomplete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { WarningTooltip } from 'components/form';
import { IconSprite } from '../../../../icons';
import { themeOrange as theme } from '../../../../../theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
        paddingTop: 0,
        paddingBottom: 0,
      },
      '& .MuiInputLabel-outlined': {
        transform: 'translate(14px, 11px) scale(1)',
        '&.MuiInputLabel-shrink': {
          transform: 'translate(9px, -6px) scale(0.75)',
        },
      },
    },
    warningIcon: {
      marginTop: 3,
      cursor: 'pointer',
      '&$warningIcon svg': {
        fill: theme.palette.primaryGradient[700],
        cursor: 'pointer',
        pointerEvents: 'auto',
      },
    },
    notTelematics: {
      filter: 'grayscale(1) brightness(1.2)',
    },
  })
);

const getOptionSelected = (option: NomenclatureViewModel, value: NomenclatureViewModel) => {
  if (typeof option === 'string' && typeof value === 'string') {
    return option === value;
  }
  return option.code === value.code;
};

const useNomenclatureQuery = (inputValue?: string) => {
  const [input] = useDebounce(inputValue, 300);

  const { data, isLoading, refetch } = useNomenclatureListSearchQuery({
    search: input,
  });

  const options = data?.data ?? [];

  return { options, isLoading, refetch };
};

const getOptionLabel = (option: NomenclatureViewModel) => {
  if (typeof option === 'string') {
    return option;
  }
  return option.name;
};

export type ModelAutocompleteProps = {
  index: number;
  item: QuotaNomenclatureViewModel;
  onOpenNomenclatures: () => void;
  quotaId?: number;
  taskId?: number;
};

export const ModelAutocomplete = (props: ModelAutocompleteProps) => {
  const classes = useStyles();

  const { index, quotaId, taskId, onOpenNomenclatures } = props;

  const { t } = useTranslation();

  const { control, setValue } = useCalculationFormContext();

  const resetCode = useCallback(
    (value: string | undefined = undefined) => {
      setValue(`nomenclatures.${index}.code`, value);
    },
    [index, setValue]
  );

  const resetIsTelematicsEnabled = useCallback(
    (value: boolean | undefined = undefined) => {
      setValue(`nomenclatures.${index}.isTelematicsEnabled`, value);
    },
    [index, setValue]
  );

  const rules = useRequiredOnSave({ name: `nomenclatures.${index}.name` });

  const {
    field: { onChange, ...rest },
    fieldState: { error },
  } = useController({
    control,
    name: `nomenclatures.${index}.name`,
    rules: rules,
  });

  const code = useWatch({ control, name: `nomenclatures.${index}.code` });
  const isTelematicsEnabled = useWatch({
    control,
    name: `nomenclatures.${index}.isTelematicsEnabled`,
  });
  const name = rest.value;

  const [inputValue, setInputValue] = useState(
    name !== undefined && name !== null && name !== '' ? name : ''
  );
  const [input] = useDebounce(inputValue, 500);

  const { options, refetch } = useNomenclatureQuery(input);

  const isFirstReset = useRef(name !== undefined && name !== null && name !== '');

  const handleOnInputChange = useCallback(
    (event, value, reason) => {
      if (reason === 'input' && (value || value === '')) {
        setInputValue(value);
        resetCode();
        resetIsTelematicsEnabled();
      } else if (reason === 'clear') {
        setInputValue('');
        resetCode();
        resetIsTelematicsEnabled();
        refetch();
      }
      if (!isFirstReset.current) {
        onChange(event, value, reason);
      } else if (isFirstReset.current) {
        isFirstReset.current = false;
      }
    },
    [onChange, setInputValue, resetCode, resetIsTelematicsEnabled, refetch]
  );

  const handleOnChange = useCallback(
    (_, value, reason: AutocompleteChangeReason) => {
      if (value && reason === 'select-option') {
        setInputValue(value.name);
        resetCode(value.code);
        resetIsTelematicsEnabled(value.isTelematicsEnabled);
      }
      if (reason === 'clear') {
        refetch();
      }
    },
    [resetCode, resetIsTelematicsEnabled, setInputValue, refetch]
  );

  const helperText = error !== undefined ? (error as FieldError).message : undefined;

  const icon = (
    <Box pt={1.1} pr={0} pb={1} pl={0}>
      <IconSprite
        icon={'view'}
        width={15}
        height={10}
        color={theme.palette.text.primary}
        hoverColor={theme.palette.primaryGradient[700]}
      />
    </Box>
  );

  return (
    <>
      <Autocomplete<NomenclatureViewModel, false, false, true>
        freeSolo
        value={inputValue === '' ? null : inputValue}
        options={options}
        onChange={handleOnChange}
        onInputChange={handleOnInputChange}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        inputValue={inputValue}
        disabled={!!taskId}
        renderInput={(params) => (
          <div>
            <TextField
              {...rest}
              {...params}
              className={classes.input}
              label={t('Lease subject')}
              error={error !== undefined}
              helperText={helperText}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                startAdornment:
                  quotaId && !code && inputValue! !== '' ? (
                    <InputAdornment position="start">
                      <WarningTooltip
                        arrow
                        placement="bottom-start"
                        className={classes.warningIcon}
                        onOpenNomenclatures={onOpenNomenclatures}
                        taskIds={taskId ? [taskId] : undefined}
                      />
                    </InputAdornment>
                  ) : (
                    <>
                      {code ? (
                        <InputAdornment position="start">
                          <a
                            href={`/dictionaries/nomenclatures?nomenclatureCode=${code}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {icon}
                          </a>
                        </InputAdornment>
                      ) : null}
                      {isTelematicsEnabled === true && (
                        <InputAdornment position="start">
                          <Box pt={0.2}>
                            <IconSprite icon={'telematics'} width={24} />
                          </Box>
                        </InputAdornment>
                      )}
                      {isTelematicsEnabled === false && (
                        <InputAdornment position="start">
                          <Box pt={0.2}>
                            <IconSprite
                              icon={'telematics'}
                              width={24}
                              className={classes.notTelematics}
                            />
                          </Box>
                        </InputAdornment>
                      )}
                    </>
                  ),
              }}
              fullWidth
            />
          </div>
        )}
      />
    </>
  );
};
