import { Box, createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Button, IconBackTo, TabPanel, Tabs, useTabs } from 'components';
import { themeOrange as theme } from 'theme';
import SwipeableViews from 'react-swipeable-views';
import { useState } from 'react';
import { PageTitle } from 'components/utils/pageTitle';
import { CounterpartyIndividualEditForm } from '../CounterpartyEditForm/CounterpartyIndividualEditForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navBottom: {
      marginBottom: '12px',
    },
    span: {
      marginLeft: theme.spacing(1.6),
    },
    overflowFix: {
      '& > div': {
        overflowX: 'clip !important',
        '& > div > div': {
          overflow: 'inherit !important',
        },
      },
    },
  })
);

export const CounterpartyIndividual = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const tabs = [
    {
      name: t('GeneralInfo'),
    },
  ];

  const tabsProps = useTabs(tabs, 0);
  const { tabIndex, onChangeTabIndex, onChangeTab } = tabsProps;
  const [counterpartyName, setCounterpartyName] = useState<string>();

  const pageTitle = counterpartyName + ' - Bumblebee';
  PageTitle(pageTitle);

  return (
    <Grid container direction="column">
      <Grid item>
        <Grid className={classes.navBottom} item>
          <Button variant="iconButton" endIcon={<IconBackTo />} to="/counterparties" />
          <span className={classes.span}>{counterpartyName}</span>
        </Grid>
      </Grid>
      <Box mb={2.5}>
        <Tabs {...tabsProps} value={tabIndex} onChangeTab={onChangeTab} />
      </Box>
      <Grid item className={classes.overflowFix}>
        <SwipeableViews
          containerStyle={{
            transition: 'transform 0.6s ease-out 0s',
          }}
          springConfig={{
            duration: '0.6s',
            easeFunction: 'transform 0.6s ease-out 0s',
            delay: '0s',
          }}
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={tabIndex}
          onChangeIndex={onChangeTabIndex}
        >
          <TabPanel value={tabIndex} index={0} dir={theme.direction}>
            {tabIndex === 0 && (
              <CounterpartyIndividualEditForm setCounterpartyName={setCounterpartyName} />
            )}
          </TabPanel>
        </SwipeableViews>
      </Grid>
    </Grid>
  );
};
