import { MenuItem, Typography } from '@material-ui/core';
import { Button, Grid, Input, Select, Radio, RadioOption } from 'components';
import { useEffect, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  DealQualificationGroup,
  DealQualificationRuleViewModel,
  DealQualificationType,
} from 'schema/serverTypes';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  useAddDealQualificationMutation,
  useDealQualificationsAvailableQuery,
} from '../../../services/api/useDealQualificationDictionaryBackend';
import { groupMap, typeMap } from './QualificationsTable';
import { useQueryClient } from 'react-query';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(5.5),
    },
    button: {
      display: 'block',
      margin: '4px auto 0',
    },
    required: {
      marginTop: theme.spacing(-1.5),
      color: theme.palette.text.primary,
    },
  })
);

export type QualificationsAddFormProps = {
  onSuccess: () => void;
};

const useQualificationForm = (message: string, onSuccess: () => void) => {
  const { handleSubmit, control, formState, setError, clearErrors, reset, watch, setValue } =
    useForm<
      DealQualificationRuleViewModel & {
        ruleCode?: string;
        scoringModelId?: number;
        ruleId?: string;
      }
    >({
      mode: 'onBlur',
    });

  const queryClient = useQueryClient();

  const { mutateAsync } = useAddDealQualificationMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey.indexOf('dealqualifications') > -1;
        },
      });
      onSuccess();
    },
    onError: () => {
      setError('name', { message }, { shouldFocus: true });
    },
  });

  const onSubmit = useMemo(() => {
    const submit = async (form: DealQualificationRuleViewModel) => {
      await mutateAsync(form);
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync]);

  return {
    control,
    onSubmit,
    reset,
    setError,
    clearErrors,
    watch,
    setValue,
    ...formState,
  };
};

export const QualificationsAddForm = (props: QualificationsAddFormProps) => {
  const classes = useStyles();
  const { onSuccess } = props;
  const { t } = useTranslation();
  const message = t('Duplicate parameter name');
  const { onSubmit, isSubmitting, control, setValue } = useQualificationForm(message, onSuccess);
  const { data: options } = useDealQualificationsAvailableQuery();

  const ruleOptionId = useWatch({ control, name: 'ruleId' });
  const type = useWatch({ control, name: 'type' });

  useEffect(() => {
    const selectedRule = options?.find((option) => option.id === ruleOptionId);
    setValue('scoringModelId', selectedRule?.scoringModelId);
    setValue('ruleCode', selectedRule?.ruleCode);
  }, [ruleOptionId, options, setValue]);

  useEffect(() => {
    if (type !== DealQualificationType.Rule) {
      setValue('scoringModelId', undefined);
      setValue('ruleCode', undefined);
    }
  }, [type, setValue]);

  return (
    <form>
      <Typography variant={'h2'} className={classes.title}>
        {t('Add')}
      </Typography>
      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item md={24} xs={24}>
          <Select
            label={t('Group of parameters')}
            control={control}
            name="group"
            rules={{
              required: {
                value: true,
                message: t('Required'),
              },
            }}
          >
            <MenuItem value={DealQualificationGroup.General}>
              {groupMap[DealQualificationGroup.General]}
            </MenuItem>
            <MenuItem value={DealQualificationGroup.Counterparties}>
              {groupMap[DealQualificationGroup.Counterparties]}
            </MenuItem>
            <MenuItem value={DealQualificationGroup.ContractBase}>
              {groupMap[DealQualificationGroup.ContractBase]}
            </MenuItem>
            <MenuItem value={DealQualificationGroup.LeaseCalculation}>
              {groupMap[DealQualificationGroup.LeaseCalculation]}
            </MenuItem>
            <MenuItem value={DealQualificationGroup.PaymentAndDeliveryOrder}>
              {groupMap[DealQualificationGroup.PaymentAndDeliveryOrder]}
            </MenuItem>
          </Select>
        </Grid>
        <Grid item md={24} xs={24}>
          <Input
            label={t('Parameter')}
            control={control}
            name="name"
            rules={{
              required: {
                value: true,
                message: t('Required'),
              },
            }}
          />
        </Grid>
        <Grid item md={24} xs={24}>
          <Radio
            label={t('Type of qualification')}
            control={control}
            name="type"
            options={[
              {
                label: typeMap[DealQualificationType.Manual],
                value: DealQualificationType.Manual,
              } as RadioOption,
              {
                label: typeMap[DealQualificationType.NoLimit],
                value: DealQualificationType.NoLimit,
              } as RadioOption,
              {
                label: typeMap[DealQualificationType.Rule],
                value: DealQualificationType.Rule,
              } as RadioOption,
            ]}
          />
        </Grid>
        {type === DealQualificationType.Rule && (
          <Grid item md={24} xs={24}>
            <Select
              label={t('Relation to the rule of the KR model')}
              control={control}
              name="ruleId"
              rules={{
                required: {
                  value: true,
                  message: t('Required'),
                },
              }}
            >
              {options?.map((option) => (
                <MenuItem value={option.id} key={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        )}
        <Grid item md={24} xs={24}>
          <Button
            className={classes.button}
            color="primary"
            size="medium"
            type="submit"
            variant="contained"
            disabled={isSubmitting}
            onClick={onSubmit}
          >
            {t('Save')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
