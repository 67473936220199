import { Grid } from 'components/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { CounterpartyRequisite } from 'schema/serverTypes';
import { Input } from 'components/form';
import { BicAutocomplete } from './BicAutocomplete';
import { BankAutocomplete } from './BankAutocomplete';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    head: {
      color: theme.palette.secondary.main,
    },
    delete: {
      textAlign: 'right',
      '& > *': {
        minWidth: 0,
        color: theme.palette.primary.light,
      },
    },
    whiteInput: {
      '& input': {
        backgroundColor: 'white',
      },
    },
    checkBox: {
      margin: theme.spacing(-2, 0),
    },
    wrapper: {
      width: '100%',
    },
  })
);

export type RequisiteProps = {
  item?: CounterpartyRequisite;
  type: 'lessee' | 'dealer';
  control: any;
  setValue: any;
};

export const Requisite = (props: RequisiteProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { control, setValue, type } = props;

  return (
    <>
      <Grid item md={6} xs={24}>
        <BicAutocomplete control={control} setValue={setValue} type={type} />
      </Grid>
      <Grid item md={6} xs={24}>
        <BankAutocomplete control={control} setValue={setValue} type={type} />
      </Grid>
      <Grid item md={6} xs={24}>
        <Input
          className={classes.whiteInput}
          label={t('Сorrespondent account')}
          name={`${type}.requisite.correspondentAccount` as const}
          control={control}
          rules={{
            required: {
              value: true,
              message: t('Required'),
            },
          }}
        />
      </Grid>
      <Grid item md={6} xs={24}>
        <Input
          className={classes.whiteInput}
          label={t('Number of account')}
          name={`${type}.requisite.account` as const}
          control={control}
          rules={{
            maxLength: {
              value: 20,
              message: t('Maximum number of characters is', { count: 20 }),
            },
            minLength: {
              value: 20,
              message: t('Minimum number of characters is', { count: 20 }),
            },
            required: {
              value: true,
              message: t('Required'),
            },
          }}
        />
      </Grid>
    </>
  );
};
