import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';
import { MissingFilter } from './MissingFilter';
import { MissingItem } from './MissingItem';
import { useCallback, useState } from 'react';
import { MissingHistoryCountGroupTab, MissingHistoryCountGroupTabId } from 'schema/serverTypes';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    head: {
      fontSize: 10,
      fontWeight: 600,
      padding: 6,
      '&.MuiTableCell-root': {
        color: theme.palette.text.primary,
      },
      '&.MuiTableCell-root:first-child': {
        padding: 6,
      },
      '&.MuiTableCell-root:last-child': {
        padding: 6,
      },
    },
    border: {
      '&.MuiTable-root': {
        borderSpacing: '4px',
        borderCollapse: 'separate',
        boxShadow: 'none',
      },
    },
  })
);

export type MissingHomeTableProps = {
  groups: MissingHistoryCountGroupTab[];
  isLoading: boolean;
};

export const MissingHomeTable = (props: MissingHomeTableProps) => {
  const { t } = useTranslation();

  const [currentFilter, setCurrentFilter] = useState<MissingHistoryCountGroupTabId>(
    MissingHistoryCountGroupTabId.Counterparties
  );

  const setCounterparties = useCallback(() => {
    setCurrentFilter(MissingHistoryCountGroupTabId.Counterparties);
  }, []);
  const setDocuments = useCallback(() => {
    setCurrentFilter(MissingHistoryCountGroupTabId.Documents);
  }, []);
  const setManagers = useCallback(() => {
    setCurrentFilter(MissingHistoryCountGroupTabId.Managers);
  }, []);

  const { groups } = props;

  const tab: MissingHistoryCountGroupTab | undefined =
    currentFilter === MissingHistoryCountGroupTabId.Counterparties
      ? groups.find((t) => t.id === MissingHistoryCountGroupTabId.Counterparties)
      : currentFilter === MissingHistoryCountGroupTabId.Documents
      ? groups.find((t) => t.id === MissingHistoryCountGroupTabId.Documents)
      : groups.find((t) => t.id === MissingHistoryCountGroupTabId.Managers);

  const groupByKey = (list: any, key: string) =>
    list.reduce(
      (hash: any, obj: any) => ({
        ...hash,
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      }),
      {}
    );

  const rows =
    currentFilter === MissingHistoryCountGroupTabId.Counterparties
      ? groupByKey(tab?.data, 'source')
      : tab?.data;

  return (
    <TableContainer>
      <MissingFilter
        setCounterparties={setCounterparties}
        setDocuments={setDocuments}
        setManagers={setManagers}
        currentFilter={currentFilter}
      />
      {currentFilter === MissingHistoryCountGroupTabId.Counterparties ? (
        <>
          {rows.dealer && (
            <TableBlock
              title={t('Dealer_plural')}
              rows={rows.dealer}
              currentFilter={currentFilter}
            />
          )}
          {rows.lessee && (
            <TableBlock
              title={t('Lessee_plural')}
              rows={rows.lessee}
              currentFilter={currentFilter}
            />
          )}
        </>
      ) : (
        <TableBlock rows={rows} currentFilter={currentFilter} />
      )}
    </TableContainer>
  );
};

const TableBlock = ({
  title,
  rows,
  currentFilter,
}: {
  title?: string;
  rows: any;
  currentFilter: string;
}) => {
  const classes = useStyles();

  return (
    <Table className={classes.border}>
      {title && (
        <TableHead>
          <TableRow>
            <TableCell className={classes.head}>{title}</TableCell>
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {rows.map((item: any, index: number) => (
          <MissingItem
            key={`${currentFilter}-${item.text}-${index}`}
            currentFilter={currentFilter}
            {...item}
          />
        ))}
      </TableBody>
    </Table>
  );
};
