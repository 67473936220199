export const formatNumber = (
  value: number | undefined,
  fractionDigits: number | undefined = 2,
  trimEnd: boolean | undefined = false
) => {
  if (value === undefined) {
    return undefined;
  }
  const fixedValue = value.toFixed(fractionDigits ?? 2);

  if (trimEnd) {
    const newValue = parseFloat(fixedValue);
    return newValue.toString();
  }
  var parts = fixedValue.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  return trimEnd ? parts.join(',').replace(',00', '') : parts.join(',');
};

export const formatPercents = (
  value: number | undefined,
  fractionDigits: number | undefined = 2,
  trimEnd: boolean | undefined = true
) => {
  const formatted = formatNumber(value, fractionDigits, trimEnd);
  return formatted ? `${formatted}%` : undefined;
};
