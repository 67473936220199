import { Typography } from '@material-ui/core';
import { Grid, Input, Button, useToast, Checkbox } from 'components';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useMemo } from 'react';
import { MailOfferViewModel } from 'schema/serverTypes';
import { useSendTemplateByEmailMutation } from '../../../services';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(5.5),
    },
    button: {
      display: 'block',
      margin: '4px auto 0',
    },
    required: {
      marginTop: theme.spacing(-1.5),
      color: theme.palette.text.primary,
    },
    period: {
      color: theme.palette.text.primary,
      display: 'flex',
      alignItems: 'center',
    },
    checkbox: {
      '& .MuiFormControlLabel-root': {
        alignItems: 'flex-start',
        marginRight: 0,
        marginTop: 0,
        '& .MuiCheckbox-root': {
          marginTop: -12,
        },
        '& .MuiCheckbox-label': {
          marginTop: 0,
        },
      },
    },
    link: {
      color: theme.palette.primary.main,
    },
  })
);

export type SendByEmailFormProps = {
  templateId: string;
  quotaId: string;
  onClose: () => void;
};

export const SendByEmailForm = (props: SendByEmailFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const toast = useToast();
  const { templateId, quotaId, onClose } = props;

  const { control, handleSubmit } = useForm<MailOfferViewModel>({
    mode: 'all',
  });

  const { mutateAsync, isLoading } = useSendTemplateByEmailMutation(templateId, quotaId, {
    onSuccess: () => {
      toast(t('TemplateSuccessMessage'), 'success');
      onClose();
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const sendByEmailHandler = useMemo(() => {
    const submit = async (form: MailOfferViewModel) => {
      try {
        await mutateAsync({ ...form });
      } catch (error) {
        console.log(error);
      }
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync]);

  return (
    <form>
      <Typography variant={'h2'} className={classes.title}>
        {t('Send By email')}
      </Typography>
      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item md={24} xs={24}>
          <Input
            label={t('FullName')}
            control={control}
            name="fullName"
            rules={{
              required: {
                value: true,
                message: t('Required'),
              },
            }}
          />
        </Grid>
        <Grid item md={24} xs={24}>
          <Input
            label={t('Email')}
            control={control}
            name="email"
            rules={{
              required: {
                value: true,
                message: t('Required'),
              },
            }}
          />
        </Grid>
        <Grid item md={24} xs={24} className={classes.checkbox}>
          <Checkbox
            name="isAgreePrivateDataProcess"
            label={
              <>
                Подтверждая отправку формы нажатием на кнопку, я даю свое согласие на обработку моих
                персональных данных, в соответствии с Федеральным законом от 27.07.2006 года №152-ФЗ
                «О персональных данных», на условиях и для целей, определенных в{' '}
                <Link
                  className={classes.link}
                  to="//fcm-leasing.ru/personal-info/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Согласии на обработку персональных данных
                </Link>
              </>
            }
            control={control}
            noWrapLabel={false}
            rules={{
              required: {
                value: true,
                message: t('Required'),
              },
            }}
          />
        </Grid>
        <Grid item md={24} xs={24} className={classes.checkbox}>
          <Checkbox
            name="isAgreeAdvertisingInformation"
            label={
              <>
                Подтверждая отправку формы нажатием на кнопку, я даю свое{' '}
                <Link
                  className={classes.link}
                  to="//fcm-leasing.ru/promotional/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Согласие на получение рекламно-информационных сообщений
                </Link>
              </>
            }
            control={control}
            noWrapLabel={false}
            rules={{
              required: {
                value: true,
                message: t('Required'),
              },
            }}
          />
        </Grid>
        <Grid item md={24} xs={24}>
          <Button
            className={classes.button}
            color="primary"
            size="medium"
            onClick={sendByEmailHandler}
            variant="contained"
            disabled={isLoading}
          >
            {t('Send')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
