import { HubConnectionBuilder } from '@microsoft/signalr';
import { ReactNode, useEffect, useMemo } from 'react';
import { calculationUrl, useUserAuth } from 'services';
import { useHub } from './useHub';
import { useClientMethod } from './useClientMethod';
import { useToast } from 'components';

export type SignalRContextProviderProps = {
  children?: ReactNode;
};

export const SignalRContextProvider = (props: SignalRContextProviderProps) => {
  const { children } = props;
  const { user } = useUserAuth();
  const accessToken = user?.access_token;

  const hubConnection = useMemo(() => {
    if (accessToken !== undefined) {
      return new HubConnectionBuilder()
        .withUrl(`${calculationUrl}/notification`, {
          accessTokenFactory: () => accessToken,
        })
        .withAutomaticReconnect()
        .build();
    }

    return undefined;
  }, [accessToken]);

  const { hubConnectionState, error } = useHub(hubConnection);

  useEffect(() => {
    console.log(hubConnectionState, error);
  }, [hubConnectionState, error]);

  const toast = useToast();

  useClientMethod(hubConnection, 'NotificationNewTaskComment', (taskId: number) => {
    const url = `/tasks/${taskId}`;
    const message = `Добавлен новый комментарий к задаче  #${taskId}`;

    toast(<a href={url}>{message}</a>, 'success');
  });

  useClientMethod(hubConnection, 'NotificationTaskStatusChanged', (taskId: number) => {
    const url = `/tasks/${taskId}`;
    const message = `Изменен статус задачи  #${taskId}`;

    toast(<a href={url}>{message}</a>, 'success');
  });

  useClientMethod(
    hubConnection,
    'MissingContractOverdueCreated',
    (inn: string, lesseeName: string) => {
      const url = `/counterparties/${inn}#missing`;
      const message = `Добавлена просрочка ${lesseeName}`;

      toast(<a href={url}>{message}</a>, 'success');
    },
  );

  return <>{children}</>;
};
