import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import { MissingImportDates, MissingOverdueListModel } from 'schema/serverTypes';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { IconSprite } from '../icons';
import { themeOrange as theme } from 'theme';
import { TableSortLabel } from '../data-grid/TableSortLabel';
import { formatCurrency, Colorize, User } from 'components';
import { MissingOverdueSortBy } from 'schema/serverTypes';

const useStyles = makeStyles((theme) =>
  createStyles({
    table: {
      backgroundColor: theme.palette.common.white,
      fontSize: 12,
      boxShadow: '0px 0px 20px 0px rgb(56 60 97 / 10%)',
      color: theme.palette.common.black,
      display: 'grid',
      gridTemplateColumns: '20fr 20fr 20fr 120px 120px 130px 100px 90px 38px',
    },
    th: {
      fontSize: 12,
      color: theme.palette.text.secondary,
      fontWeight: 700,
      display: 'flex',
      alignItems: 'center',
      paddingTop: 16,
      paddingBottom: 16,
      paddingLeft: 12,
      paddingRight: 12,
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
    },
    right: {
      textAlign: 'right',
      justifyContent: 'flex-end',
    },
    td: {
      paddingLeft: 12,
      paddingRight: 12,
      '& > a': {
        color: theme.palette.common.black,
        '&:hover': {
          textDecoration: 'underline',
          color: theme.palette.secondary.dark,
        },
      },
    },
    rowWrapper: {
      display: 'contents',
      backgroundColor: 'green',
      '&:hover > div': {
        backgroundColor: theme.palette.background.light,
      },
      '& > div': {
        paddingTop: 16,
        paddingBottom: 16,
        borderBottom: `2px solid ${theme.palette.background.default}`,
      },
    },
    lesseeName: {
      paddingLeft: 20,
      paddingRight: 12,
      '& > a': {
        color: theme.palette.common.black,
        '&:hover': {
          textDecoration: 'underline',
          color: theme.palette.secondary.dark,
        },
      },
    },
    managerName: {
      color: theme.palette.text.secondary,
      fontSize: 12,
    },
    contractStatus: {
      color: theme.palette.text.secondary,
      fontSize: '11px',
    },
    debt: {
      textAlign: 'center',
    },
    comments: {
      paddingRight: 20,
      position: 'relative',
      '&:after': {
        content: '""',
        pointerEvents: 'none',
        display: 'block',
        position: 'absolute',
        left: 6,
        top: 0,
        borderRadius: '50%',
        backgroundColor: 'transparent',
        width: 6,
        height: 6,
      },
      '&$notCommented:after': {
        backgroundColor: theme.palette.error.main,
      },
      '&$commented:after': {
        backgroundColor: theme.palette.green.main,
      },
    },
    notCommented: {},
    commented: {},
    received: {},
    updated: {
      textAlign: 'right',
      fontSize: 10,
      color: theme.palette.text.secondary,
      margin: theme.spacing(2, 0, 0),
    },
    ok: {
      color: theme.palette.green.main,
    },
    star: {
      marginLeft: -23,
      marginRight: 10,
    },
    statusOk: {
      color: theme.palette.green.main,
    },
  })
);

type OverdueTableProps = {
  rows: MissingOverdueListModel[];
  isLoading: boolean;
  importDates?: MissingImportDates;
  sorting: any;
};

export const OverdueTable = (props: OverdueTableProps) => {
  const { rows, sorting, importDates = {} } = props;
  const { missings, overdues } = importDates;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <div className={classes.table}>
        <div className={clsx(classes.th, classes.lesseeName)}>{t('Contract')}</div>
        <div className={clsx(classes.th)}>{t('Lessee')}</div>
        <div className={clsx(classes.th)}>{t('Status')}</div>
        <div className={clsx(classes.th, classes.right)}>
          <TableSortLabel columnName={MissingOverdueSortBy.Exposure} sorting={sorting}>
            {t('Exposure')}
          </TableSortLabel>
        </div>
        <div className={clsx(classes.th, classes.right)}>
          <TableSortLabel columnName={MissingOverdueSortBy.OverdueAmount} sorting={sorting}>
            {t('Overdue')}
          </TableSortLabel>
        </div>
        <div className={clsx(classes.th, classes.right)}>
          <TableSortLabel columnName={MissingOverdueSortBy.DebtDepth} sorting={sorting}>
            {t('(day)')}
          </TableSortLabel>
        </div>
        <div className={clsx(classes.th, classes.right)}>
          <TableSortLabel columnName={MissingOverdueSortBy.MissingCount} sorting={sorting}>
            {t('Missing')}
          </TableSortLabel>
        </div>
        <div className={classes.th} />
        <div className={classes.th} />
        {rows.map((row) => {
          return <TableItem key={row.id} {...row} />;
        })}
      </div>
      <div className={classes.updated}>
        {missings && (
          <>
            {t('Missings updated')} {dayjs(missings).format('DD.MM.YYYY')}
          </>
        )}
        {overdues && (
          <>
            {' '}
            | {t('Overdue payments updated')} {dayjs(overdues).format('DD.MM.YYYY')}
          </>
        )}
      </div>
    </>
  );
};

const TableItem = (props: MissingOverdueListModel) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    lesseeInn,
    lesseeName,
    managerName,
    isManagerActive,
    contractNumber,
    contractStatus,
    updatedDate,
    overdueAmount,
    debtDepth,
    missingCount,
    currency,
    dealerName,
    dealerInn,
    exposure,
  } = props;
  const { pathname } = useLocation();

  return (
    <div className={classes.rowWrapper}>
      <div className={classes.lesseeName}>
        {contractNumber}
        <br />
        <br />
        <Link to={`/counterparties/${dealerInn}`} target="_blank" rel="noopener">
          {dealerName}
        </Link>
        <br />
        <br />
        <Typography component="span" variant={'body2'} className={classes.managerName}>
          <User name={managerName} isActive={isManagerActive} />
        </Typography>
      </div>
      <div className={classes.td}>
        <Link to={`/counterparties/${lesseeInn}`} target="_blank" rel="noopener">
          {lesseeName}
        </Link>
      </div>
      <div className={classes.td}>{contractStatus}</div>
      <div className={clsx(classes.td, classes.right)}>
        {exposure ? (
          <Colorize
            limits={[0, 0]}
            value={exposure}
            unit={formatCurrency(currency)}
            isFormatMoney
          />
        ) : (
          t('Empty')
        )}
      </div>
      <div className={clsx(classes.td, classes.right)}>
        {overdueAmount ? (
          <Colorize
            limits={[0, 0]}
            value={overdueAmount}
            unit={formatCurrency(currency)}
            isFormatMoney
          />
        ) : (
          t('Empty')
        )}
      </div>
      <div className={clsx(classes.td, classes.right)}>
        {debtDepth ? <Colorize value={debtDepth} /> : '—'}
      </div>
      <div className={clsx(classes.td, classes.right)}>
        <Colorize value={missingCount} />
      </div>
      <div className={clsx(classes.td)}>{dayjs(updatedDate).format('DD.MM.YYYY')}</div>
      <div className={clsx(classes.comments)}>
        <Link to={`${pathname}/comments?dealerName=${lesseeName}&contractNumber=${contractNumber}`}>
          <IconSprite icon="chat" width="10px" color={theme.palette.primary.main} />
        </Link>
      </div>
    </div>
  );
};
