import { useCallback } from 'react';
import { calculationUrl, useBackendFetch } from 'services';

type DownloadFileType = (
  ev: React.MouseEvent,
  relativeUrl: string,
  fileName: string,
  additionalCallback?: () => void,
  options?: RequestInit
) => Promise<void>;

const useFileDownloader = (): DownloadFileType => {
  const fetchBackend = useBackendFetch();

  const downloadFile = useCallback(
    async (ev, relativeUrl, fileName, additionalCallback?: any, options?: RequestInit) => {
      ev.stopPropagation();

      const url = `${calculationUrl}${relativeUrl}`;

      const response = await fetchBackend(url, options);
      const result = await response.blob();
      const blob = new Blob([result], { type: result.type });
      let realFileName = fileName;
      const header = response.headers.get('content-disposition');
      if (header !== null) {
        realFileName =
          header
            .split(';')
            .find((n) => n.includes("filename*=UTF-8''"))
            ?.replace("filename*=UTF-8''", '')
            .trim() ?? fileName;

        realFileName = decodeURIComponent(decodeURI(realFileName));
      }

      const newUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = newUrl;
      a.download = realFileName || '';
      document.body.appendChild(a);
      a.click();

      // Execute the additional callback if provided
      if (additionalCallback) {
        additionalCallback();
      }
    },
    [fetchBackend]
  );

  return downloadFile;
};

export default useFileDownloader;
