import { Critical, MissingContainerFS } from 'components';
import { useTranslation } from 'react-i18next';
import { useErrorContext } from 'components/tasks';
import { IssueSource } from 'schema/serverTypes';
import { Route, Switch } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';

export const FullScreenPage = () => {
  const { t } = useTranslation();

  useErrorContext({
    title: t('Home'),
    source: IssueSource.home,
  });

  return (
    <>
      <CssBaseline />
      <Critical />
      <Switch>
        <Route path="/main/fullscreen" exact component={MissingContainerFS} />
      </Switch>
    </>
  );
};
