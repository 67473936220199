import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { useCallback } from 'react';
import sprite from 'img/svg-sprite.svg';
import { useCalculationFormContext } from 'components/calculator2/CalculationForm/types';
import { useController } from 'react-hook-form';

export type ActionButtonProps = {
  action: 'increase' | 'decrease';
  index: number;
  disabled?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 26,
      width: 8,
      padding: 0,
      margin: theme.spacing(0, 0.5),
      userSelect: 'none',
    },
    icon: {
      height: 8,
      width: 7,
      fill: theme.palette.text.secondary,
    },
  })
);

const getIconImageLink = (action: ActionButtonProps['action']) => {
  return action === 'increase' ? sprite + '#arrow-right' : sprite + '#arrow-left';
};

const treshold = 0.1;

export const ActionButton = (props: ActionButtonProps) => {
  const classes = useStyles();

  const { index, action, disabled = false } = props;

  const { control } = useCalculationFormContext();
  const { field } = useController({ control, name: `paymentOptions.${index}.value` });

  const { onChange, value } = field;

  const decrease = useCallback(() => {
    onChange(value && value >= treshold ? value - treshold : 0.0);
  }, [value, onChange]);

  const increase = useCallback(() => {
    onChange(value ? value + treshold : 1.0);
  }, [value, onChange]);

  const buttonPops = {
    onClick: !disabled ? (action === 'increase' ? increase : decrease) : undefined,
    disabled,
  };

  const iconImageLink = getIconImageLink(action);

  return (
    <IconButton className={classes.root} {...buttonPops}>
      <svg className={classes.icon}>
        <use xlinkHref={iconImageLink}></use>
      </svg>
    </IconButton>
  );
};
