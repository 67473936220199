import { Grid, Input } from 'components';
import { RatingStyles } from './useStyles';
import { Control } from 'react-hook-form';
import { RatingInputValues } from './types';
import {
  RatingQuestion1,
  RatingQuestion2,
  RatingQuestion3,
  RatingQuestion4,
  RatingQuestion5,
  RatingQuestion6,
  RatingQuestion7,
  RatingQuestion8,
  RatingQuestion9,
  RatingQuestion10,
  RatingQuestion11,
  RatingQuestion12,
  RatingQuestion13,
  RatingQuestion14,
  RatingQuestion15,
  RatingQuestion16,
  RatingQuestion17,
  RatingQuestion18,
} from './RatingQuestion';
import { Dispatch, SetStateAction, useCallback } from 'react';

export type RatingInputFormProps = {
  classes: RatingStyles;
  control: Control<RatingInputValues & { quarter: string; year: string }, any>;
  setOptional: Dispatch<SetStateAction<boolean>>;
  optional: boolean;
};

export const RatingInputForm = (props: RatingInputFormProps) => {
  const { control, classes, setOptional, optional } = props;

  const handlerOptional = useCallback(
    () => setOptional && setOptional(!optional),
    [setOptional, optional]
  );

  return (
    <form>
      <Grid container rowSpacing={2}>
        <Grid item xs={24}>
          <Input
            name="totalAssets"
            control={control}
            className={classes.number}
            rules={{
              required: true,
              pattern: /^[0-9,\-,\s]+$/i,
            }}
          />
        </Grid>
        <Grid item xs={24}>
          <Input
            name="netIntangibleAssets"
            control={control}
            className={classes.number}
            rules={{
              required: true,
              pattern: /^[0-9,\-,\s]+$/i,
            }}
          />
        </Grid>
        <Grid item xs={24}>
          <Input
            name="intragroupLoansOlderOneYear"
            control={control}
            className={classes.number}
            rules={{
              required: true,
              pattern: /^[0-9,\-,\s]+$/i,
            }}
          />
        </Grid>
        <Grid item xs={24}>
          <Input
            name="intragroupLoansNewerOneYear"
            control={control}
            className={classes.number}
            rules={{
              required: true,
              pattern: /^[0-9,\-,\s]+$/i,
            }}
          />
        </Grid>
        <Grid item xs={24}>
          <Input
            name="intragroupDZ"
            control={control}
            className={classes.number}
            rules={{
              required: true,
              pattern: /^[0-9,\-,\s]+$/i,
            }}
          />
        </Grid>
        <Grid item xs={24}>
          <Input
            name="accountCashierAndMoney"
            control={control}
            className={classes.number}
            rules={{
              required: true,
              pattern: /^[0-9,\-,\s]+$/i,
            }}
          />
        </Grid>
        <Grid item xs={24}>
          <Input
            name="totalCashAndInvestments"
            control={control}
            className={classes.number}
            rules={{
              required: true,
              pattern: /^[0-9,\-,\s]+$/i,
            }}
          />
        </Grid>
        <Grid item xs={24}>
          <Input
            name="capitalAndReserves"
            control={control}
            className={classes.number}
            rules={{
              required: true,
              pattern: /^[0-9,\-,\s]+$/i,
            }}
          />
        </Grid>
        <Grid item xs={24}>
          <Input
            name="longTermDuties"
            control={control}
            className={classes.number}
            rules={{
              required: true,
              pattern: /^[0-9,\-,\s]+$/i,
            }}
          />
        </Grid>
        <Grid item xs={24}>
          <Input
            name="otherLongTermDuties"
            control={control}
            className={classes.number}
            rules={{
              required: true,
              pattern: /^[0-9,\-,\s]+$/i,
            }}
          />
        </Grid>
        <Grid item xs={24}>
          <Input
            name="shortTermBorrowedFunds"
            control={control}
            className={classes.number}
            rules={{
              required: true,

              pattern: /^[0-9,\-,\s]+$/i,
            }}
          />
        </Grid>
        <Grid item xs={24}>
          <Input
            name="otherShortTermBorrowedFunds"
            control={control}
            className={classes.number}
            rules={{
              required: true,
              pattern: /^[0-9,\-,\s]+$/i,
            }}
          />
        </Grid>
        <Grid item xs={24}>
          <Input
            name="partOfSubordinatedDebtPayableIn12Months"
            control={control}
            className={classes.number}
            rules={{
              required: true,
              pattern: /^[0-9,\-,\s]+$/i,
            }}
          />
        </Grid>
        <Grid item xs={24}>
          <Input
            name="cashInParticipantsAccounts"
            control={control}
            className={classes.number}
            rules={{
              required: true,
              pattern: /^[0-9,\-,\s]+$/i,
            }}
          />
        </Grid>
        <Grid item xs={24}>
          <Input
            name="leasingObligations"
            control={control}
            className={classes.number}
            rules={{
              required: true,
              pattern: /^[0-9,\-,\s]+$/i,
            }}
          />
        </Grid>
        <Grid item xs={24}>
          <Input
            name="operationalLeasing"
            control={control}
            className={classes.number}
            rules={{
              required: true,
              pattern: /^[0-9,\-,\s]+$/i,
            }}
          />
        </Grid>
        <Grid item xs={24}>
          <Input
            name="revenue"
            control={control}
            className={classes.number}
            rules={{
              required: true,
              pattern: /^[0-9,\-,\s]+$/i,
            }}
          />
        </Grid>
        <Grid item xs={24}>
          <Input
            name="salesProfit"
            control={control}
            className={classes.number}
            rules={{
              required: true,
              pattern: /^[0-9,\-,\s]+$/i,
            }}
          />
        </Grid>
        <Grid item xs={24}>
          <Input
            name="sustainableEBITDA"
            control={control}
            className={classes.number}
            rules={{
              required: true,
              pattern: /^[0-9,\-,\s]+$/i,
            }}
          />
        </Grid>
        <Grid item xs={24}>
          <Input
            name="operatingResultOfActivitiesToBeDiscontinued"
            control={control}
            className={classes.number}
            rules={{
              required: true,
              pattern: /^[0-9,\-,\s]+$/i,
            }}
          />
        </Grid>
        <Grid item xs={24}>
          <Input
            name="profitBeforeTax"
            control={control}
            className={classes.number}
            rules={{
              required: true,
              pattern: /^[0-9,\-,\s]+$/i,
            }}
          />
        </Grid>
        <Grid item xs={24}>
          <Input
            name="amortization"
            control={control}
            className={classes.number}
            rules={{
              pattern: /^[0-9,\-,\s]+$/i,
            }}
          />
        </Grid>
        <Grid item xs={24} className={classes.spacer}>
          <Input
            name="expensesOnOffBalance"
            control={control}
            className={classes.number}
            rules={{
              pattern: /^[0-9,\-,\s]+$/i,
            }}
          />
        </Grid>

        <Grid item xs={24}>
          <RatingQuestion1 control={control} />
        </Grid>
        <Grid item xs={24}>
          <RatingQuestion2 control={control} />
        </Grid>
        <Grid item xs={24}>
          <RatingQuestion3 control={control} />
        </Grid>
        <Grid item xs={24}>
          <RatingQuestion4 control={control} />
        </Grid>
        <Grid item xs={24}>
          <RatingQuestion5 control={control} />
        </Grid>
        <Grid item xs={24}>
          <RatingQuestion6 control={control} />
        </Grid>

        <Grid item xs={24}>
          <div className={classes.optional} onClick={handlerOptional} />
        </Grid>
        {optional && (
          <>
            <Grid item xs={24}>
              <RatingQuestion7 control={control} />
            </Grid>
            <Grid item xs={24}>
              <RatingQuestion8 control={control} />
            </Grid>
            <Grid item xs={24}>
              <RatingQuestion9 control={control} />
            </Grid>
            <Grid item xs={24}>
              <RatingQuestion10 control={control} />
            </Grid>
            <Grid item xs={24}>
              <RatingQuestion11 control={control} />
            </Grid>
            <Grid item xs={24}>
              <RatingQuestion12 control={control} />
            </Grid>
            <Grid item xs={24}>
              <RatingQuestion13 control={control} />
            </Grid>
            <Grid item xs={24}>
              <RatingQuestion14 control={control} />
            </Grid>
            <Grid item xs={24}>
              <RatingQuestion15 control={control} />
            </Grid>
            <Grid item xs={24}>
              <RatingQuestion16 control={control} />
            </Grid>
            <Grid item xs={24}>
              <RatingQuestion17 control={control} />
            </Grid>
            <Grid item xs={24}>
              <RatingQuestion18 control={control} />
            </Grid>
          </>
        )}
      </Grid>
    </form>
  );
};
