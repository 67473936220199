import { TopDealerQuotaCountViewModel, TvDashboardViewModel } from 'schema/serverTypes';
import { createOptions, createUrlFromParts } from './types';
import { QueryOptions, useBackendQuery } from '.';

const entity = 'tvDashboard';

export const useTvDashboardQuery = (
  options: QueryOptions<TvDashboardViewModel> | undefined = undefined
) => {
  return useBackendQuery(createUrlFromParts(entity), entity, createOptions(options));
};

export const useTvVendorQuotasQuery = (options: QueryOptions<number> | undefined = undefined) => {
  return useBackendQuery(
    createUrlFromParts(entity, 'vendor/quotas'),
    entity + '/vendor/quotas',
    createOptions(options)
  );
};

export const useTvVendorContractsQuery = (
  options: QueryOptions<number> | undefined = undefined
) => {
  return useBackendQuery(
    createUrlFromParts(entity, 'vendor/contracts'),
    entity + '/vendor/contracts',
    createOptions(options)
  );
};

export const useTvVendorShipmentsQuery = (
  options: QueryOptions<number> | undefined = undefined
) => {
  return useBackendQuery(
    createUrlFromParts(entity, 'vendor/shipments'),
    entity + '/vendor/shipments',
    createOptions(options)
  );
};

export const useTvVendorTopDealersQuery = (
  options: QueryOptions<TopDealerQuotaCountViewModel[]> | undefined = undefined
) => {
  return useBackendQuery(
    createUrlFromParts(entity, 'vendor/dealers'),
    entity + '/vendor/dealers',
    createOptions(options)
  );
};
