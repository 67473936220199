import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteChangeDetails,
} from '@material-ui/lab/useAutocomplete';
import { Autocomplete } from 'components';
import { AddRegionTierRequest, RegionViewModel } from 'schema/serverTypes';
import { useRegionSearchQuery } from 'services/api/useRegionTiersBackend';
import { Control, UseFormSetValue, useController } from 'react-hook-form';

const getOptionLabel = (option: RegionViewModel | null) => {
  if (option === null) {
    return '';
  }
  return option.name;
};

const getOptionSelected = (option: RegionViewModel | null, value: RegionViewModel | null) => {
  if (option === null || value === null) {
    return false;
  }
  return option.code === value.code;
};

export type RegionAutocompleteProps = {
  control: Control<AddRegionTierRequest, any>;
  setValue: UseFormSetValue<AddRegionTierRequest>;
};

export const RegionAutocomplete = (props: RegionAutocompleteProps) => {
  const { control, setValue } = props;
  const { t } = useTranslation();

  const {
    field: { value },
  } = useController({ control, name: 'code' });

  const [user, setUser] = useState('');
  const [input] = useDebounce(user, 300);
  const { data: options = [] } = useRegionSearchQuery(input);

  const handleOnInputChange = useCallback(
    (_ev: React.ChangeEvent<{}>, value: string, _reason: AutocompleteInputChangeReason) => {
      setUser(value);
    },
    [setUser]
  );

  const handleOnChange = useCallback(
    (
      _e: React.ChangeEvent<{}>,
      value: RegionViewModel | null,
      _reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<RegionViewModel | null>
    ) => {
      if (value === null) {
        setValue('code', '');
        setValue('name', '');
      } else {
        setValue('code', value.code);
        setValue('name', value.name);
      }
    },
    [setValue]
  );

  const selectedOption = options.find((t) => t.code === value) ?? null;

  return (
    <Autocomplete<RegionViewModel | null>
      label={`${t('Region')}`}
      options={options}
      inputValue={user}
      onInputChange={handleOnInputChange}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      onChange={handleOnChange}
      variant="outlined"
      value={selectedOption}
    />
  );
};
