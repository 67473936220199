import { useEffect } from 'react';
import { FieldRenderProps } from 'react-final-form-hooks';
import { MonthPaymentOption } from 'schema/serverTypes';

export type OnIsPreferentialChangedProps = FieldRenderProps<MonthPaymentOption>;

export const OnIsPreferentialChanged = (props: OnIsPreferentialChangedProps) => {
  const { input } = props;
  const { value, onChange } = input;

  useEffect(() => {
    if (value.isPreferential && value.isPayment && value.value !== 0) {
      onChange({ ...value, value: 0 });
    }
  }, [value, onChange]);

  return <></>;
};
