import { useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { Control, useForm, useWatch } from 'react-hook-form';
import { useDebounce } from 'use-debounce';
import {
  IssueFilterTabs,
  IssuePagedList,
  IssueGroupedPagedList,
  TaskWithQuotaViewModel,
  TaskViewModel,
  IssueType,
} from 'schema/serverTypes';
import { useIssuesBackendQuery } from 'services/api/useIssuesBackend';
import { SearchFilterFormValues } from '../types';
import { usePaging } from '../../paging';
import { useUserRole } from 'services';

const useSearchParams = (searchParams: SearchFilterFormValues) => {
  const {
    owned,
    search,
    assignee,
    author,
    status,
    type,
    group,
    quotaIds,
    issueIds,
    fromDate,
    toDate,
    hideCompleted,
    contractNumber,
    isPulse,
    page = 1,
    pageSize = 20,
  } = searchParams;

  return useMemo(() => {
    const searchParams = new URLSearchParams();

    if (search && search !== '') {
      searchParams.set('search', search);
    }
    if (assignee) {
      assignee.forEach((t) => searchParams.append('assigneeId', t.id));
    }
    if (author) {
      author.forEach((t) => searchParams.append('authorId', t.id));
    }
    if (status) {
      status.forEach((t) => searchParams.append('statuses', t));
    }
    if (type) {
      type.forEach((t) => searchParams.append('types', t));
    }

    if (hideCompleted !== undefined) {
      searchParams.set('hideCompleted', hideCompleted === true ? 'true' : 'false');
    }

    if (owned) {
      searchParams.set('owned', owned === true ? 'true' : 'false');
    }
    if (group) {
      searchParams.set('group', group);
    }

    if (quotaIds) {
      searchParams.set('quotaIds', quotaIds.toString());
    }

    if (issueIds) {
      searchParams.set('issueIds', issueIds.toString());
    }
    //issueIds.forEach((id) => searchParams.set('issueIds', id.toString()));

    if (fromDate) {
      searchParams.set('fromDate', fromDate.toString());
    }

    if (toDate) {
      searchParams.set('toDate', toDate.toString());
    }

    if (contractNumber && contractNumber !== '') {
      searchParams.set('contractNumber', contractNumber);
    }

    searchParams.set('isPulse', isPulse ? 'true' : 'false');

    searchParams.set('page', page.toString());
    searchParams.set('pageSize', pageSize.toString());

    return searchParams;
  }, [
    owned,
    search,
    assignee,
    author,
    status,
    type,
    hideCompleted,
    quotaIds,
    issueIds,
    fromDate,
    toDate,
    page,
    group,
    pageSize,
    contractNumber,
    isPulse,
  ]);
};

export const useIssuesFilter = (isPulse?: boolean) => {
  const filterParams = sessionStorage.issuesFilterValues
    ? JSON.parse(sessionStorage.issuesFilterValues)
    : null;

  const { isAssetManager } = useUserRole();

  const defaultValues: SearchFilterFormValues = useMemo(() => {
    return {
      search: '',
      group: IssueFilterTabs.Mine,
      isPulse,
      page: 1,
      pageSize: 20,
      hideCompleted: true,
      type: isAssetManager ? [IssueType.Nomenclature] : undefined,
    };
  }, [isPulse, isAssetManager]);

  const { control, reset, setValue } = useForm<SearchFilterFormValues>({
    mode: 'onBlur',
    defaultValues: { ...defaultValues, ...filterParams },
  });

  const handleOnReset = useCallback(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  return {
    control,
    handleOnReset,
    setValue,
  };
};

export type UseIssuesDataProps = {
  control: Control<SearchFilterFormValues, any>;
};

export const useIssuesData = (props: UseIssuesDataProps) => {
  const { control } = props;

  const searchValue = useWatch({ control, name: 'search' });
  const [search] = useDebounce(searchValue ?? '', 500);

  const defaultPageSize = useWatch({ control, name: 'pageSize' });
  const defaultPage = useWatch({ control, name: 'page' });

  const { page, pageSize, onPageChanged, onPageSizeChanged } = usePaging({
    pageSize: defaultPageSize,
    page: defaultPage,
  });

  const assignee = useWatch({ control, name: 'assignee' });
  const author = useWatch({ control, name: 'author' });
  const status = useWatch({ control, name: 'status' });
  const type = useWatch({ control, name: 'type' });
  const quotaIds = useWatch({ control, name: 'quotaIds' });
  const issueIds = useWatch({ control, name: 'issueIds' });
  const fromDate = useWatch({ control, name: 'fromDate' });
  const toDate = useWatch({ control, name: 'toDate' });
  const hideCompleted = useWatch({ control, name: 'hideCompleted' });
  const group = useWatch({ control, name: 'group' });
  const isPulse = useWatch({ control, name: 'isPulse' });

  const contractNumberValue = useWatch({ control, name: 'contractNumber' });
  const [contractNumber] = useDebounce(contractNumberValue ?? '', 500);

  const params = useMemo(() => {
    return {
      search,
      assignee,
      author,
      status,
      type,
      quotaIds,
      issueIds,
      fromDate,
      toDate,
      page,
      pageSize,
      group,
      hideCompleted,
      isPulse,
      contractNumber,
    };
  }, [
    search,
    assignee,
    author,
    status,
    type,
    quotaIds,
    issueIds,
    fromDate,
    toDate,
    page,
    pageSize,
    group,
    hideCompleted,
    isPulse,
    contractNumber,
  ]);

  const onMount = useRef(false);

  useLayoutEffect(() => {
    if (onMount.current) {
      onPageChanged(1);
    } else {
      onMount.current = true;
    }
  }, [
    search,
    assignee,
    author,
    status,
    type,
    quotaIds,
    issueIds,
    fromDate,
    toDate,
    pageSize,
    group,
    hideCompleted,
    contractNumber,
    onPageChanged,
  ]);

  useEffect(() => {
    sessionStorage.setItem('issuesFilterValues', JSON.stringify(params));
  }, [params]);

  const searchParams = useSearchParams(params);

  const { data, isLoading: loading } = useIssuesBackendQuery<IssuePagedList>({
    relativeUrl: '',
    searchParams,
    options: {
      keepPreviousData: false,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    },
  });

  const issues = data?.data ?? [];

  return {
    issues,
    loading,
    paging: {
      page: data?.page ?? 1,
      pageSize: pageSize ?? 20,
      pageCount: data?.pageCount ?? 0,
      totalCount: data?.totalCount ?? 0,
      dataCount: issues.length,
      onPageChanged,
      onPageSizeChanged,
    },
  };
};

export type IssueQuota = Pick<
  TaskWithQuotaViewModel,
  | 'quotaId'
  | 'contractNumber'
  | 'contractStatus'
  | 'lesseeInn'
  | 'lesseeName'
  | 'fundingAmountNBV'
  | 'dealQualificationStatus'
  | 'scoringModel'
>;

export type IssueQuotaGroup = {
  quota: IssueQuota;
  issues: TaskViewModel[];
};

export const useIssuesGroupedData = (props: UseIssuesDataProps) => {
  const { control } = props;

  const searchValue = useWatch({ control, name: 'search' });
  const [search] = useDebounce(searchValue ?? '', 500);

  const defaultPageSize = useWatch({ control, name: 'pageSize' });
  const defaultPage = useWatch({ control, name: 'page' });

  const { page, pageSize, onPageChanged, onPageSizeChanged } = usePaging({
    pageSize: defaultPageSize,
    page: defaultPage,
  });
  const assignee = useWatch({ control, name: 'assignee' });
  const author = useWatch({ control, name: 'author' });
  const status = useWatch({ control, name: 'status' });
  const type = useWatch({ control, name: 'type' });
  const quotaIds = useWatch({ control, name: 'quotaIds' });
  const issueIds = useWatch({ control, name: 'issueIds' });
  const fromDate = useWatch({ control, name: 'fromDate' });
  const toDate = useWatch({ control, name: 'toDate' });
  const hideCompleted = useWatch({ control, name: 'hideCompleted' });
  const group = useWatch({ control, name: 'group' });

  const contractNumberValue = useWatch({ control, name: 'contractNumber' });
  const [contractNumber] = useDebounce(contractNumberValue ?? '', 500);

  const params = useMemo(() => {
    return {
      search,
      assignee,
      author,
      status,
      type,
      quotaIds,
      issueIds,
      fromDate,
      toDate,
      page,
      pageSize,
      group,
      hideCompleted,
      contractNumber,
    };
  }, [
    search,
    assignee,
    author,
    status,
    type,
    quotaIds,
    issueIds,
    fromDate,
    toDate,
    page,
    pageSize,
    group,
    hideCompleted,
    contractNumber,
  ]);

  const onMount = useRef(false);

  useLayoutEffect(() => {
    if (onMount.current) {
      onPageChanged(1);
    } else {
      onMount.current = true;
    }
  }, [
    search,
    assignee,
    author,
    status,
    type,
    quotaIds,
    issueIds,
    fromDate,
    toDate,
    pageSize,
    group,
    hideCompleted,
    contractNumber,
    onPageChanged,
  ]);

  useEffect(() => {
    sessionStorage.setItem('issuesFilterValues', JSON.stringify(params));
  }, [params]);

  const searchParams = useSearchParams(params);

  const { data, isLoading: loading } = useIssuesBackendQuery<IssueGroupedPagedList>({
    relativeUrl: 'grouped',
    searchParams,
    options: {
      keepPreviousData: false,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    },
  });

  const issues = data?.data ?? [];

  const grouped = useMemo(() => {
    const issues = data?.data ?? [];

    const quotas: IssueQuota[] = [];
    for (let i = 0; i < issues.length; i++) {
      const issue = issues[i];
      const {
        quotaId,
        contractNumber,
        contractStatus,
        lesseeInn,
        lesseeName,
        fundingAmountNBV,
        dealQualificationStatus,
        scoringModel,
      } = issue;
      if (quotas.find((t) => t.quotaId === quotaId) === undefined) {
        quotas.push({
          quotaId,
          contractNumber,
          contractStatus,
          lesseeInn,
          lesseeName,
          fundingAmountNBV,
          dealQualificationStatus,
          scoringModel,
        });
      } else {
        if (issue.scoringModel !== undefined) {
          const existQuotaIndex = quotas.findIndex((t) => t.quotaId === quotaId);
          const existQuota = quotas[existQuotaIndex];

          if (existQuota.scoringModel === undefined) {
            existQuota.scoringModel = issue.scoringModel;
          }
        }
      }
    }

    const groups: IssueQuotaGroup[] = [];
    for (let i = 0; i < quotas.length; i++) {
      const quota = quotas[i];
      const quotaIssues: TaskViewModel[] = issues
        .filter((t) => t.quotaId === quota.quotaId)
        .map((t) => {
          const { quotaId, contractNumber, contractStatus, ...issue } = t;
          return issue;
        });
      const group: IssueQuotaGroup = {
        quota,
        issues: quotaIssues,
      };
      groups.push(group);
    }

    return groups;
  }, [data?.data]);

  return {
    grouped,
    loading,
    paging: {
      page: data?.page ?? 1,
      pageSize: pageSize ?? 20,
      pageCount: data?.pageCount ?? 0,
      totalCount: data?.totalCount ?? 0,
      dataCount: issues.length,
      onPageChanged,
      onPageSizeChanged,
    },
  };
};
