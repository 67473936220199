import React from 'react';
import { NotificationSettingsEventsForm } from './NotificationSettingsEventsForm';
import { NotificationSettingsTaskForm } from './NotificationSettingsTaskForm';
import { useEventNotificationSettingsQuery, useIssueNotificationSettingsQuery } from 'services';

export const NotificationSettingsForm = () => {
  const { data: taskNotificationDefaultData, isLoading: isLoadingTaskNotification } =
    useIssueNotificationSettingsQuery();

  const { data: eventNotificationDefaultData, isLoading: isLoadingEventNotification } =
    useEventNotificationSettingsQuery();

  if (
    isLoadingTaskNotification ||
    taskNotificationDefaultData === undefined ||
    isLoadingEventNotification ||
    eventNotificationDefaultData === undefined
  ) {
    return null;
  }

  return (
    <>
      <NotificationSettingsTaskForm defaultValues={taskNotificationDefaultData} />
      <NotificationSettingsEventsForm defaultValues={eventNotificationDefaultData} />
    </>
  );
};
