import { Typography } from '@material-ui/core';
import { Grid, Button, useToast, Input } from 'components';

import { useTranslation } from 'react-i18next';
import { useCreateScoringApprovalIssueMutation } from 'services';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { themeOrange as theme } from 'theme';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { FileUpload } from '../../tasks/FileUpload';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(5.5),
    },
    nomenclature: {
      color: theme.palette.common.black,
      marginBottom: theme.spacing(1),
    },
    button: {
      display: 'block',
      margin: '4px auto 0',
    },
    required: {
      marginTop: theme.spacing(-1.5),
      color: theme.palette.text.primary,
    },
  })
);

export type ApprovalRequestFormProps = {
  quotaId: number;
  onClose: (...event: any[]) => void;
};

export const ApprovalRequestForm = (props: ApprovalRequestFormProps) => {
  const classes = useStyles();
  const { quotaId, onClose } = props;
  const { t } = useTranslation();
  const toast = useToast();

  const { control, handleSubmit, setValue } = useForm({
    mode: 'all',
  });
  const queryClient = useQueryClient();

  const { mutateAsync } = useCreateScoringApprovalIssueMutation(quotaId, {
    onSuccess: (result) => {
      toast(
        <>
          Задача создана
          <Link to={`/tasks/${result.id}`} style={{ color: theme.palette.primary.main }}>
            &nbsp;№{result.id}
          </Link>
        </>,
        'success'
      );
      queryClient.invalidateQueries({
        predicate: (query) => {
          if (typeof query.queryKey === 'string') {
            const key = query.queryKey as string;
            return ['quotas', 'scoring', 'margin'].some((substring) => key.includes(substring));
          }

          return false;
        },
      });
      onClose();
    },
    onError: () => {
      toast(t('Could not create task'), 'error');
    },
  });

  const onSubmit = useMemo(() => {
    const submit = async (form: any) => {
      await mutateAsync(form);
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync]);

  return (
    <form onSubmit={onSubmit}>
      <Typography variant={'h2'} className={classes.title}>
        {t('ApprovalRequest')}
      </Typography>
      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item xs={24}>
          <Input
            label={`${t('Additional info')}`}
            name="description"
            control={control}
            multiline={true}
          />
        </Grid>
        <Grid item xs={24}>
          <Input
            label={t('Link to documents')}
            control={control}
            name="documentUrl"
            rules={{
              required: {
                value: true,
                message: t('Required'),
              },
            }}
          />
        </Grid>
        <Grid item xs={24}>
          <FileUpload control={control} setValue={setValue} />
        </Grid>
        <Grid item xs={24}>
          <Button
            className={classes.button}
            color="primary"
            size="medium"
            type="submit"
            variant="contained"
          >
            {t('Create task')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
