import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Paper, Theme } from '@material-ui/core';
import { DatePicker } from '../../form';
import { useForm } from 'react-hook-form';
import { Button } from '../../Button';
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { ShipmentGroupItemViewModel } from 'schema/serverTypes';
import { useUpdateExpectedDateMutation } from './useUpdateExpectedDateMutation';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../utils/useLanguage';
import { formatMoney } from '../../utils';
import { useUserRole } from '../../../services';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: theme.spacing(1.5, 2.5),
      background: theme.palette.background.default,
      borderBottom: `1px solid ${theme.palette.text.primary}`,
      borderRadius: 0,
      marginBottom: theme.spacing(1),
      cursor: 'pointer',
      '&:hover': {
        boxShadow: '3px 3px 5px rgba(56, 60, 97, 0.1)',
      },
    },
    outdate: {
      backgroundColor: theme.palette.secondary.light,
      borderBottom: `1px solid ${theme.palette.error.main}`,
    },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: 12,
      '& + &': {
        marginTop: 12,
      },
      '& + $hide': {
        marginTop: 0,
      },
      transition: '0.3s',
    },
    show: {
      maxHeight: 50,
      overflow: 'hidden',
      opacity: 1,
    },
    hide: {
      maxHeight: 0,
      overflow: 'hidden',
      opacity: 0,
    },
    showButton: {
      display: 'flex',
      alignItems: 'center',
    },
    hideButton: {
      display: 'none',
    },
    infoContainer: {
      flex: '1 1 auto',
      '& > a': {
        color: theme.palette.text.primary,
        textDecoration: 'underline',
      },
    },
    subtitle: {
      fontWeight: 400,
      color: theme.palette.text.primary,
      display: 'inline-block',
      marginRight: 4,
    },
    info: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginTop: 5,
    },
    infoItem: {
      fontWeight: 700,
      color: theme.palette.text.primary,
      '&:nth-child(1)': {
        width: '44%',
      },
      '&:nth-child(2)': {
        width: '38%',
      },
      '&:nth-child(3)': {
        width: '18%',
      },
      '& > a': {
        color: theme.palette.text.primary,
        textDecoration: 'underline',
      },
    },
    number: {
      color: theme.palette.primary.main,
      fontWeight: 700,
    },
    date: {
      width: 146,
      display: 'flex',
      alignItems: 'center',
    },
    ship: {
      margin: theme.spacing(0, 2),
    },
  })
);

type LesseeLabelProps = {
  name?: string;
  inn?: string;
};

const LesseeLabel = (props: LesseeLabelProps) => {
  const { name, inn } = props;

  if (inn) {
    return (
      <Link rel="noopener" target="_blank" to={`/counterparties/${inn}`}>
        {name}
      </Link>
    );
  }

  return <>{name}</>;
};

export type ShipmentItemProps = {
  item: ShipmentGroupItemViewModel;
  onOpen: (item: ShipmentGroupItemViewModel) => void;
  refetch: () => void;
};

export const ShipmentItem = (props: ShipmentItemProps) => {
  const classes = useStyles();
  const [showMore, setShowMore] = useState<boolean>(false);
  const { t } = useTranslation();
  const lang = useLanguage();
  const { item, onOpen, refetch } = props;

  const expandHandler = useCallback(() => {
    setShowMore((prev) => !prev);
  }, [setShowMore]);

  const {
    id,
    quotaId,
    dealer,
    leaseSubject,
    expectedShipmentDate,
    lessee,
    lesseeName,
    expired,
    owner,
    fundingAmountNBV,
    canUpdateExpectedShipmentDate,
  } = item;

  const { id: currentUserId } = useUserRole();

  const { control } = useForm({
    mode: 'onBlur',
  });

  const outdateClass = expired ? classes.outdate : '';
  const showClass = showMore ? classes.show : classes.hide;
  const showButtonClass = showMore ? classes.showButton : classes.hideButton;

  const onSubmit = useUpdateExpectedDateMutation(quotaId, id);

  const onDateChange = useCallback(
    async (expectedShipmentDate: string | null) => {
      if (expectedShipmentDate !== null) {
        await onSubmit(expectedShipmentDate);
        refetch();
      } else {
        await onSubmit('');
        refetch();
      }
    },
    [onSubmit, refetch]
  );

  const handleOnClick = useCallback(() => {
    onOpen(item);
  }, [item, onOpen]);

  let lesseeDisplayName = lang === 'ru' ? lessee?.name : lessee?.transliteratedName;
  if (lesseeDisplayName === undefined || lesseeDisplayName === '') {
    lesseeDisplayName = lesseeName;
  }

  return (
    <Paper className={clsx(classes.root, outdateClass)} onClick={expandHandler}>
      <form name={`shipment${id}`}>
        <div className={classes.container}>
          <div className={classes.infoContainer}>
            <div className={classes.info}>
              <Link
                rel="noopener"
                target="_blank"
                to={`/quotas/calculator/${quotaId}`}
                className={clsx(classes.infoItem, classes.number)}
              >
                {item.contractNumber !== '' ? `№ ${item.contractNumber}` : t('Without number')}
                {item.contractStatus && item.contractStatus !== '' ? (
                  <span> / {item.contractStatus} </span>
                ) : null}
                / {item.owner.name}
              </Link>
              {item.issueId && (
                <div className={classes.infoItem}>
                  <span className={classes.subtitle}>{t('Telematics ordered')}</span>
                  .&#160;
                  <Link to={`/tasks/${item.issueId}`}>
                    {t('Task')} №{item.issueId}
                  </Link>
                </div>
              )}
            </div>
            <div className={classes.info}>
              <div className={classes.infoItem}>
                <span className={classes.subtitle}>{t('Lessee')}</span>{' '}
                <LesseeLabel inn={lessee?.inn} name={lesseeDisplayName} />
              </div>
              <div className={classes.infoItem}>
                <span className={classes.subtitle}>{t('Dealer')}</span>{' '}
                <Link rel="noopener" target="_blank" to={`/counterparties/${dealer?.inn}`}>
                  {lang === 'ru' ? dealer?.name : dealer?.transliteratedName}
                </Link>
              </div>
              <div className={classes.infoItem}>
                <span className={classes.subtitle}>{t('NBV')}</span>{' '}
                {formatMoney({ amount: fundingAmountNBV, currency: 'RUB' })}
              </div>
            </div>
          </div>
          {owner.id !== currentUserId && (
            <div className={showButtonClass}>
              <Button variant="contained" className={classes.ship} onClick={handleOnClick}>
                {t('Shipment reports')}
              </Button>
            </div>
          )}
          <div
            className={classes.date}
            onClick={(ev) => {
              ev.stopPropagation();
            }}
          >
            <DatePicker
              label={`${t('Expected date')}`}
              name="expectedShipmentDate"
              control={control}
              defaultValue={expectedShipmentDate ?? null}
              onDateChange={onDateChange}
              disabled={!canUpdateExpectedShipmentDate}
            />
          </div>
        </div>
        <div className={clsx(classes.container, showClass)}>
          <div className={classes.infoContainer}>
            <div className={classes.infoItem}>
              <span className={classes.subtitle}>{t('LeaseSubject')}:</span> {leaseSubject}
            </div>
          </div>
        </div>
      </form>
    </Paper>
  );
};
