export const formatDate = (date?: string | Date, compressed?: boolean) => {
  if (!date) {
    return undefined;
  }

  const localedDate =
    typeof date === 'string'
      ? new Date(date).toLocaleDateString('ru-RU')
      : date.toLocaleDateString('ru-RU');

  if (compressed) {
    const arrOfDate = localedDate.split('');

    arrOfDate.splice(-4, 2);

    return arrOfDate.join('');
  }

  return localedDate;
};
