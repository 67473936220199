import { createContext } from 'react';
import { ApplicationUserTab } from 'schema';

const setDocumentTypes = (value: string[]) => {};
const setManagerIds = (value: string[]) => {};
const setTabId = (value: number) => {};

export type MissingFilterValues = {
  documentType: string[];
  managerId: string[];
  setDocumentTypes: (value: string[]) => void;
  setManagerIds: (value: string[]) => void;
  setTabId: (value: number) => void;
  tabId: number;
};

const defaultValues: MissingFilterValues = {
  documentType: [],
  managerId: [],
  tabId: ApplicationUserTab.mine,
  setDocumentTypes,
  setManagerIds,
  setTabId,
};

export const MissingFilterContext = createContext<MissingFilterValues>(defaultValues);
