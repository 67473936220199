import React, { useCallback, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Breadcrumbs, Paging } from 'components';
import { ModalForm, useModalForm } from '../../Modal/ModalForm';
import { RegionTierViewModel } from 'schema/serverTypes';
import { useRegionTiersData } from './useRegionTiersData';
import { RegionTiersFilterForm } from './RegionTiersFilterForm';
import { RegionTiersTable } from './RegionTiersTable';
import { EditRegionTierForm } from './EditRegionTierForm';
import { AddRegionTierForm } from './AddRegionTierForm';
import { useTranslation } from 'react-i18next';
import { PageTitle } from 'components/utils/pageTitle';

export const RegionTiersDataGrid = () => {
  const { t } = useTranslation();
  const { filter, paging, sorting, ...dataProps } = useRegionTiersData();
  const { onOpen, onClose, open } = useModalForm();
  const [regionTier, setRegionTier] = useState<RegionTierViewModel>();

  const pageTitle = t('Matrix of Tiers') + ' - Bumblebee';
  PageTitle(pageTitle);

  const handleOnOpen = useCallback(
    (item: RegionTierViewModel) => {
      setRegionTier(item);
      onOpen();
    },
    [onOpen],
  );

  const handleOnClose = useCallback(() => {
    setRegionTier(undefined);
    onClose();
  }, [onClose]);

  return (
    <>
      <Breadcrumbs link={'/dictionaries'} text={t('Section.dictionaries')} />
      <Box display="flex" justifyContent="space-between" mb={2.5} alignItems="flex-end">
        <Box>
          <RegionTiersFilterForm {...filter} />
        </Box>
        {/*<Box>*/}
        {/*  <AddButton onClick={onOpen} />*/}
        {/*</Box>*/}
      </Box>
      <Grid container item direction="column">
        <Grid item>
          <RegionTiersTable {...dataProps} sorting={sorting} onOpen={handleOnOpen} />
        </Grid>
        <Grid item>
          <Paging {...paging} />
        </Grid>
      </Grid>
      <ModalForm open={open} onClose={handleOnClose}>
        {regionTier && <EditRegionTierForm onSuccess={handleOnClose} regionTier={regionTier} />}
        {regionTier === undefined && <AddRegionTierForm onSuccess={handleOnClose} />}
      </ModalForm>
    </>
  );
};
