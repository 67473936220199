import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import { QuotaOwnerFormSkeleton, QuotaOwnerForm } from './QuotaOwnerForm';
import { useQuotaHistoryQuery } from './useQuotaHistoryQuery';
import { useGoBack } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useErrorContext } from 'components/tasks';
import { IssueSource } from 'schema/serverTypes';

export const QuotaOwnerFormPanel = () => {
  const { id } = useParams<{ id: string }>();
  const goBack = useGoBack();

  const handleOnClose = useCallback(() => {
    goBack('/quotas/calculator');
  }, [goBack]);

  const handleOnOpen = useCallback(() => {}, []);

  const { quota, isLoading } = useQuotaHistoryQuery(id);

  const { t } = useTranslation();

  useErrorContext({
    title: t('Calculation'),
    source: IssueSource.quota,
    quotaId: quota?.id,
  });

  return (
    <SwipeableDrawer anchor="right" open={true} onClose={handleOnClose} onOpen={handleOnOpen}>
      {isLoading || quota === undefined ? (
        <QuotaOwnerFormSkeleton />
      ) : (
        <QuotaOwnerForm quota={quota} />
      )}
    </SwipeableDrawer>
  );
};
