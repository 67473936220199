import { formatCurrency } from './formatCurrency';
import { formatNumber } from './formatNumber';
import { getNumber } from './getNumber';

export type MoneyFormat = {
  amount: number | string;
  fractionDigits?: number | undefined;
  trimEnd?: boolean | undefined;
  currency?: string;
};

export const formatMoney = (input: MoneyFormat) => {
  const { amount, fractionDigits, trimEnd, currency } = input;

  const value = getNumber(amount);
  const formattedNumber = formatNumber(value, fractionDigits, trimEnd);

  if (currency === undefined) {
    return formattedNumber;
  }

  const formattedCurrency = formatCurrency(currency);

  return `${formattedNumber} ${formattedCurrency}`;
};
