import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Checkbox, DatePicker, Grid, Select } from 'components';
import { CircularProgress, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import MenuItem from '@material-ui/core/MenuItem';
import { UsersChips } from './UsersChips';
import { LesseeChips } from './LesseeChips';
import {
  ApplicationUsersTab,
  ApplicationUserTab,
  CounterpartyType,
  TemplateDataFilter,
  TemplatePrintViewModel,
} from 'schema/serverTypes';
import { useCounterpartyQuery } from '../../calculator/QuotaDataGrid/useCounterpartyQuery';
import { useCallback } from 'react';
import { useDownloadReport } from 'services/api/useTemplatesBackend';
import { QuotaOwnerAutocomplete } from './QuotaOwnerAutocomplete';
import { themeOrange as theme } from 'theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(4),
    },
    button: {
      display: 'block',
      margin: '4px auto 0',
    },
    actions: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    actionButton: {
      position: 'relative',
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
  })
);

export type TemplateDataFilterFormProps = {
  userTabs: ApplicationUsersTab[];
  onClose: () => void;
  closePopper: () => void;
  template: TemplatePrintViewModel;
};

export const TemplateDataFilterForm = (props: TemplateDataFilterFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { onClose, closePopper, userTabs, template } = props;

  const handleOnClose = useCallback(() => {
    onClose();
    closePopper();
  }, [onClose, closePopper]);

  const { isLoading, downloadAsync } = useDownloadReport({ template, onClose: handleOnClose });

  const { control, setValue, getValues } = useForm<TemplateDataFilter>({
    mode: 'all',
    defaultValues: {
      tabId: ApplicationUserTab.all,
      from: dayjs().startOf('month').toISOString(),
      to: dayjs().endOf('month').toISOString(),
    },
  });

  const onDownloadClick = useCallback(async () => {
    const values = getValues();
    await downloadAsync(values);
  }, [downloadAsync, getValues]);

  const fromVal = useWatch({ control, name: 'from' });
  const toVal = useWatch({ control, name: 'to' });
  const tabId = useWatch({ control, name: 'tabId' }) ?? ApplicationUserTab.all;

  const groupChangeHandler = () => {
    setValue('users', []);
  };

  const usersOptions =
    userTabs.find((userTab: ApplicationUsersTab) => {
      return userTab.id === tabId;
    })?.users ?? [];

  const { data: lesseesOptions } = useCounterpartyQuery(CounterpartyType.lessee);

  return (
    <form className={classes.root}>
      <Typography variant={'h2'} className={classes.title}>
        {t('Export to Excel')}
      </Typography>
      <Grid container columnSpacing={1.5} rowSpacing={2}>
        <Grid item md={24} xs={24}>
          <Select
            label={'Группа'}
            name={'tabId'}
            control={control}
            onChangeCallback={groupChangeHandler}
          >
            {userTabs.map((userTab: ApplicationUsersTab) => (
              <MenuItem key={userTab.id} value={userTab.id}>
                {userTab.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item md={24} xs={24}>
          <QuotaOwnerAutocomplete control={control} tabUsers={usersOptions} />
        </Grid>
        <Grid item md={12} xs={24}>
          <DatePicker
            label={`${t('From')}`}
            name="from"
            control={control}
            maxDate={toVal ? dayjs(toVal).toISOString() : undefined}
          />
        </Grid>
        <Grid item md={12} xs={24}>
          <DatePicker
            label={`${t('To')}`}
            name="to"
            control={control}
            minDate={fromVal ? dayjs(fromVal).toISOString() : undefined}
          />
        </Grid>
        <Grid item md={24} xs={24}>
          <Select label={t('Lessee')} name={'lessees'} control={control} multiple>
            {lesseesOptions?.map((lessee) => (
              <MenuItem key={lessee.inn} value={lessee.inn}>
                {lessee.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item md={24} xs={24}>
          <LesseeChips control={control} setValue={setValue} lesseesOptions={lesseesOptions} />
          <UsersChips control={control} setValue={setValue} usersOptions={usersOptions} />
        </Grid>
        <Grid item md={24} xs={24}>
          Включить в отчет:
          <br />
          <Checkbox name="isIncludeLost" label="потерянные" control={control} />
          <br />
          <Checkbox name="isIncludeDelivered" label="отгруженые" control={control} />
        </Grid>
        <Grid item md={24} xs={24}>
          <div className={classes.actions}>
            <div className={classes.actionButton}>
              <Button
                color="primary"
                size="medium"
                type="button"
                onClick={onDownloadClick}
                variant={'contained'}
                disabled={isLoading}
              >
                {t('Export')}
              </Button>
              {isLoading && (
                <CircularProgress
                  size={24}
                  style={{
                    color: theme.palette.primary.main,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};
