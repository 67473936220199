import palette from './paletteOrange';
import createTypography from '@material-ui/core/styles/createTypography';

const style = createTypography(palette, {
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  h1: {},
  h2: {
    fontFamily: "'Halvar Breit', Helvetica, Arial, sans-serif",
    color: palette.primary.main,
    fontSize: 16,
    fontWeight: 700,
  },
  h3: {},
  h4: {
    fontFamily: "'Halvar Breit', Helvetica, Arial, sans-serif",
    color: palette.text.primary,
    fontSize: 12,
    fontWeight: 700,
  },
  h5: {
    fontSize: 14,
    fontWeight: 400,
  },
  h6: {},
  subtitle1: {
    fontFamily: "'Halvar Breit', Helvetica, Arial, sans-serif",
    color: palette.secondary.dark,
    fontSize: 12,
    fontWeight: 700,
    lineHeight: 1.2,
  },
  subtitle2: {
    fontFamily: "'Halvar Breit', Helvetica, Arial, sans-serif",
    color: palette.common.white,
    fontSize: 12,
    fontWeight: 700,
    lineHeight: 1.2,
  },
  body1: {
    fontFamily: "'Halvar Breit', Helvetica, Arial, sans-serif",
    color: palette.text.primary,
    fontSize: 14,
    lineHeight: 1.2,
  },
  body2: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: 14,
    lineHeight: 1.2,
  },
  button: {},
  caption: {},
  overline: {},
});

export default style;
