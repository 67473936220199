import { RegionTierViewModel } from 'schema/serverTypes';
import { MenuItem, Typography } from '@material-ui/core';
import { Button, Grid, Input, Select } from 'components';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RegionTierValue } from 'schema/serverTypes';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useUpdateRegionTierMutation } from 'services/api/useRegionTiersBackend';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(5.5),
    },
    button: {
      display: 'block',
      margin: '4px auto 0',
    },
    required: {
      marginTop: theme.spacing(-1.5),
      color: theme.palette.text.primary,
    },
  })
);

export type EditRegionTierFormProps = {
  regionTier: RegionTierViewModel;
  onSuccess: () => void;
};

const useRegionTierForm = (
  regionTier: RegionTierViewModel,
  message: string,
  onSuccess: () => void
) => {
  const { handleSubmit, control, formState, setError, clearErrors, reset, setValue } =
    useForm<RegionTierViewModel>({
      mode: 'onBlur',
      defaultValues: regionTier,
    });

  const { mutateAsync } = useUpdateRegionTierMutation(regionTier.code);

  const onSubmit = useMemo(() => {
    const submit = async (form: RegionTierViewModel) => {
      try {
        await mutateAsync({ name: form.name, tier: form.tier });
        onSuccess();
      } catch (error) {
        setError('code', { message }, { shouldFocus: true });
      }
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync, setError, message, onSuccess]);

  return {
    control,
    onSubmit,
    reset,
    setValue,
    setError,
    clearErrors,
    ...formState,
  };
};

export const EditRegionTierForm = (props: EditRegionTierFormProps) => {
  const classes = useStyles();
  const { regionTier, onSuccess } = props;
  const { t } = useTranslation();
  const message = t('Could not update');
  const { onSubmit, isSubmitting, control } = useRegionTierForm(regionTier, message, onSuccess);

  return (
    <form>
      <Typography variant={'h2'} className={classes.title}>
        {t('Edit')}
      </Typography>
      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item md={24} xs={24}>
          <Input label={t('Code')} control={control} name="code" disabled />
        </Grid>
        <Grid item md={24} xs={24}>
          <Input label={t('Name')} control={control} name="name" disabled />
        </Grid>
        <Grid item md={24} xs={24}>
          <Select
            label={t('Region Tier')}
            name="tier"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('Required'),
              },
            }}
          >
            <MenuItem value={RegionTierValue.tier1}>{RegionTierValue.tier1.toUpperCase()}</MenuItem>
            <MenuItem value={RegionTierValue.tier2}>{RegionTierValue.tier2.toUpperCase()}</MenuItem>
            <MenuItem value={RegionTierValue.tier3}>{RegionTierValue.tier3.toUpperCase()}</MenuItem>
          </Select>
        </Grid>
        <Grid item md={24} xs={24}>
          <Button
            className={classes.button}
            color="primary"
            size="medium"
            type="submit"
            variant="contained"
            disabled={isSubmitting}
            onClick={onSubmit}
          >
            {t('Save')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
