import { useTranslation } from 'react-i18next';
import {
  CalculationFormFieldPath,
  CalculationFormPathValue,
  RulesType,
  useCalculationFormContext,
} from './types';
import { useCallback } from 'react';

type UseRequiredOnSaveProps<T extends CalculationFormFieldPath> = {
  name: T;
  message?: string;
};

export const useRequiredOnSave = <T extends CalculationFormFieldPath>({
  message,
}: UseRequiredOnSaveProps<T>) => {
  const { getValues } = useCalculationFormContext();

  const { t } = useTranslation();

  const errorMessage = message ?? t('Required');

  const validate = useCallback(
    (value: CalculationFormPathValue) => {
      const isSave = getValues('save');

      if (!isSave) {
        return undefined;
      }

      if (value === null || value === undefined || value === '' || Number.isNaN(value)) {
        return errorMessage;
      }

      if (Array.isArray(value)) {
        return value.length > 0 ? undefined : errorMessage;
      }

      return undefined;
    },
    [errorMessage, getValues]
  );

  const rules: RulesType<T> = {
    validate,
  };

  return rules;
};
