import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { themeOrange as theme } from 'theme';
import {
  ApplicationRole,
  RatingInput,
  RatingSortBy,
  RatingStatus,
  RatingViewModel,
} from 'schema/serverTypes';
import { TableSortLabel } from '../../data-grid/TableSortLabel';
import { IconSprite } from '../../icons';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { useDeleteRatingMutation } from '../../../services/api/useRatingsBackend';
import { useQueryClient } from 'react-query';
import { useToast } from '../../Toast';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { RatingModalForm } from './RatingModalForm';
import { ModalForm, useModalForm } from '../../Modal';
import { useStyles } from './useStyles';
import { RatingConfirmForm } from './RatingConfirmForm';
import { RatingUnConfirmForm } from './RatingUnConfirmForm';
import { formatNumber } from '../../utils';
import { getRatingString } from './getRatingString';
import { Role } from '../../authentication';

dayjs.extend(quarterOfYear);

const isEmptyValues = (input: RatingInput) =>
  input.totalAssets === 0 &&
  input.netIntangibleAssets === 0 &&
  input.intragroupLoansOlderOneYear === 0 &&
  input.intragroupLoansNewerOneYear === 0 &&
  input.intragroupDZ === 0 &&
  input.accountCashierAndMoney === 0 &&
  input.totalCashAndInvestments === 0 &&
  input.capitalAndReserves === 0 &&
  input.longTermDuties === 0 &&
  input.otherLongTermDuties === 0 &&
  input.shortTermBorrowedFunds === 0 &&
  input.otherShortTermBorrowedFunds === 0 &&
  input.partOfSubordinatedDebtPayableIn12Months === 0 &&
  input.cashInParticipantsAccounts === 0 &&
  input.leasingObligations === 0 &&
  input.operationalLeasing === 0 &&
  input.revenue === 0 &&
  input.salesProfit === 0 &&
  input.operatingResultOfActivitiesToBeDiscontinued === 0 &&
  input.profitBeforeTax === 0 &&
  input.amortization === 0 &&
  input.expensesOnOffBalance === 0;

type RatingTableProps = {
  rows: RatingViewModel[];
  isLoading: boolean;
  sorting: any;
  setCompareIds: Dispatch<SetStateAction<number[]>>;
  compareIds: number[];
  isDefault: boolean;
};

export const RatingTable = (props: RatingTableProps) => {
  const { rows, sorting, setCompareIds, compareIds, isDefault } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.table}>
      <div className={clsx(classes.th, classes.right)}>
        <TableSortLabel columnName={RatingSortBy.FromDate} sorting={sorting}>
          {t('Period')}
        </TableSortLabel>
      </div>
      <div className={clsx(classes.th)}>{t('Created')}</div>
      <div className={clsx(classes.th, classes.center)}>Итоговый PD</div>
      <div className={clsx(classes.th, classes.center)}>{t('Rating Grade')}</div>
      <div className={clsx(classes.th, classes.center)}>{t('Expires')}</div>
      <div className={clsx(classes.th, classes.center)}>{t('Status')}</div>
      <div className={clsx(classes.th)} />

      {rows.map((row: RatingViewModel) => {
        return (
          <TableItem
            key={row.id}
            setCompareIds={setCompareIds}
            compareIds={compareIds}
            values={row}
            isDefault={isDefault}
          />
        );
      })}
    </div>
  );
};

type TableItemProps = {
  compareIds: number[];
  setCompareIds?: Dispatch<SetStateAction<number[]>>;
  values: RatingViewModel;
  isDefault: boolean;
};

const TableItem = (props: TableItemProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setCompareIds, compareIds, isDefault } = props;
  const {
    id,
    inn,
    createdDate,
    fromDate,
    validFromDate,
    validToDate,
    user,
    output,
    status,
    isAutomatic,
    defaultLevel,
  } = props.values;
  const { pathname: pathnameWithSlash } = useLocation();
  const pathname = pathnameWithSlash.endsWith('/')
    ? pathnameWithSlash.slice(0, -1)
    : pathnameWithSlash;
  const queryClient = useQueryClient();
  const toast = useToast();
  const { onOpen, onClose, open } = useModalForm();
  const { onOpen: onOpenConfirm, onClose: onCloseConfirm, open: openConfirm } = useModalForm();
  const {
    onOpen: onOpenUnConfirm,
    onClose: onCloseUnConfirm,
    open: openUnConfirm,
  } = useModalForm();

  const humanStatus = useMemo(() => {
    return status === RatingStatus.Calculated
      ? 'рассчитан'
      : status === RatingStatus.Confirmed
      ? 'действующий'
      : status === RatingStatus.Expired
      ? 'истёк'
      : '';
  }, [status]);

  const statusClass = useMemo(() => {
    return status === RatingStatus.Confirmed
      ? classes.green
      : status === RatingStatus.Expired
      ? classes.pink
      : undefined;
  }, [status, classes.green, classes.pink]);

  const statusBkgClass = useMemo(() => {
    return status === RatingStatus.Confirmed
      ? classes.greenBkg
      : status === RatingStatus.Expired
      ? classes.pinkBkg
      : undefined;
  }, [status, classes.greenBkg, classes.pinkBkg]);

  const { mutateAsync: deleteAsync } = useDeleteRatingMutation(inn, id, {
    onSuccess: () => {
      toast(t('RatingSuccessMessage'), 'success');
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            typeof query.queryKey === 'string' && (query.queryKey as string).indexOf('ratings') >= 0
          );
        },
      });
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const handlerConfirmDelete = useCallback(async () => {
    onClose();
    await deleteAsync(null);
  }, [onClose, deleteAsync]);

  const handlerUpdateCompare = useCallback(() => {
    if (setCompareIds) {
      setCompareIds((prev: number[]) => {
        if (prev.indexOf(id) >= 0) {
          return [...prev.slice(0, prev.indexOf(id)), ...prev.slice(prev.indexOf(id) + 1)];
        } else {
          return [...prev, id];
        }
      });
    }
  }, [setCompareIds, id]);

  const humanDayMonth =
    dayjs(fromDate).quarter() === 1
      ? '1 квартал'
      : dayjs(fromDate).quarter() === 2
      ? 'полугодие'
      : dayjs(fromDate).quarter() === 3
      ? '9 месяцев'
      : dayjs(fromDate).quarter() === 4
      ? 'год'
      : undefined;

  const rating = props.values;
  const ratingLabel = getRatingString(rating.output.ratingGrade);

  return (
    <>
      <div className={clsx(classes.rowWrapper)}>
        <Link
          to={!defaultLevel ? `${pathname}/rating/${id}#ratings` : { pathname }}
          className={clsx(classes.firstChild, classes.right, statusBkgClass)}
        >
          {rating.isAutomatic && rating.year
            ? `год ${rating.year}`
            : rating.isAutomatic && rating.output.ratingGrade === 20 && isEmptyValues(rating.input)
            ? t('Not set')
            : fromDate
            ? `${humanDayMonth} ${dayjs(fromDate).format('YYYY')}`
            : ''}
        </Link>
        <Link
          to={!defaultLevel ? `${pathname}/rating/${id}#ratings` : { pathname }}
          className={statusBkgClass}
        >
          <Typography component="span" variant={'body2'} className={classes.created}>
            {user.name} | {dayjs(createdDate).format('DD.MM.YYYY HH:mm')}
          </Typography>
        </Link>
        <Link
          to={!defaultLevel ? `${pathname}/rating/${id}#ratings` : { pathname }}
          className={clsx(classes.center, statusBkgClass)}
        >
          {defaultLevel ? '' : `${formatNumber(output.finalPD, 6)} %`}
        </Link>
        <Link
          to={!defaultLevel ? `${pathname}/rating/${id}#ratings` : { pathname }}
          className={clsx(classes.rating, classes.center, statusBkgClass)}
        >
          {defaultLevel ? defaultLevel : ratingLabel}
          <br />
          {output.isManual ? (
            <span className={classes.manual}>manual</span>
          ) : isAutomatic ? (
            <span className={classes.manual}>auto</span>
          ) : null}
        </Link>
        <Link
          to={!defaultLevel ? `${pathname}/rating/${id}#ratings` : { pathname }}
          className={clsx(classes.center, statusBkgClass)}
        >
          {validToDate ? dayjs(validToDate).format('DD.MM.YYYY') : t('Not confirmed')}
        </Link>
        <Link
          to={!defaultLevel ? `${pathname}/rating/${id}#ratings` : { pathname }}
          className={clsx(classes.center, statusClass, statusBkgClass)}
        >
          {humanStatus}
        </Link>
        <div className={clsx(classes.icons, classes.lastChild, statusBkgClass)}>
          <div onClick={handlerUpdateCompare}>
            <IconSprite
              icon="compare"
              width="14px"
              color={
                compareIds.indexOf(id) >= 0
                  ? theme.palette.error.main
                  : theme.palette.text.secondary
              }
            />
          </div>
          <Link to={`${pathname}/rating/${id}/comments`}>
            <IconSprite icon="chat" width="12px" color={theme.palette.text.secondary} />
          </Link>
          {!isDefault && (
            <Role role={[ApplicationRole.Admin, ApplicationRole.RiskManager]}>
              {status === RatingStatus.Confirmed ? (
                <div onClick={onOpenUnConfirm}>
                  <IconSprite icon="padlock" width="12px" color={theme.palette.text.secondary} />
                </div>
              ) : (
                <div onClick={onOpenConfirm}>
                  <IconSprite icon="padopen" width="12px" color={theme.palette.text.secondary} />
                </div>
              )}
              {rating.status !== RatingStatus.Confirmed && (
                <div onClick={onOpen}>
                  <IconSprite icon="delete" width="12px" color={theme.palette.text.secondary} />
                </div>
              )}
            </Role>
          )}
        </div>
      </div>
      {rating.status !== RatingStatus.Confirmed && (
        <RatingModalForm
          open={open}
          classes={classes}
          onConfirm={handlerConfirmDelete}
          onClose={onClose}
        />
      )}
      <ModalForm open={openConfirm} onClose={onCloseConfirm}>
        <RatingConfirmForm
          id={id}
          inn={inn}
          rating={output.ratingGrade}
          validFrom={validFromDate}
          validTo={validToDate}
          onClose={onCloseConfirm}
        />
      </ModalForm>
      <ModalForm open={openUnConfirm} onClose={onCloseUnConfirm}>
        <RatingUnConfirmForm id={id} inn={inn} onClose={onCloseUnConfirm} />
      </ModalForm>
    </>
  );
};
