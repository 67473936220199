import { Autocomplete } from 'components';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MissingStatusListRequest } from 'schema/serverTypes';
import { useMissingStatusListRequest } from 'services/api';
import { MissingFilterFormProps } from './types';
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteChangeDetails,
} from '@material-ui/lab/useAutocomplete';
import { useController } from 'react-hook-form';

export type MissingStatusAutocompleteProps = Pick<MissingFilterFormProps, 'control'>;

const request: MissingStatusListRequest = {};

export const MissingStatusAutocomplete = (props: MissingStatusAutocompleteProps) => {
  const { control } = props;

  const { t } = useTranslation();

  const {
    field: { onChange, value: statuses },
  } = useController({
    control,
    name: 'status',
    defaultValue: [],
  });

  const [status, setStatus] = useState('');

  useEffect(() => {
    if (statuses === undefined && status !== undefined && status !== '') {
      setStatus('');
    }
  }, [status, statuses, setStatus]);

  const { data = [], isLoading } = useMissingStatusListRequest(request);
  const options = data.filter((t) => !(statuses ?? []).includes(t));

  const handleOnInputChange = useCallback(
    (_ev: React.ChangeEvent<{}>, value: string, _reason: AutocompleteInputChangeReason) => {
      setStatus(value);
    },
    [setStatus]
  );

  const handleOnChange = useCallback(
    (
      _e: React.ChangeEvent<{}>,
      value: string | null,
      _reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<string | null>
    ) => {
      if (value === null) {
        onChange([]);
        setStatus('');
      } else {
        onChange(statuses ? [...statuses.filter((v) => v !== value), value] : [value]);
        setStatus('');
      }
    },
    [onChange, statuses]
  );

  return (
    <Autocomplete<string | null>
      label={t('Provision state')}
      options={options}
      inputValue={status}
      onInputChange={handleOnInputChange}
      onChange={handleOnChange}
      value={null}
      loading={isLoading}
      variant="standard"
    />
  );
};
