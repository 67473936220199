import { Breadcrumbs, Button, Grid, Input, Radio } from 'components';
import { useForm } from 'react-hook-form';
import { IndustrySpecializationViewModel } from '../../../schema';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useAddIndustrySpecializationMutation } from '../../../services/api/useIndustryBackend';
import { FormValues } from './types';
import { IndustryAutocomplete } from './IndustryAutocomplete';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import { useHistory } from 'react-router';
import { useUserRole } from '../../../services';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    back: {
      marginBottom: theme.spacing(1.5),
    },
    span: {
      fontSize: 12,
      color: theme.palette.text.primary,
      marginLeft: theme.spacing(1.6),
    },
    form: {
      width: '100%',
    },
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(5.5),
    },
    required: {
      marginTop: theme.spacing(-1.5),
      color: theme.palette.text.primary,
    },
    horizontalRadio: {
      display: 'flex',
      alignItems: 'center',
      '& $legend': {
        marginTop: -6,
        fontSize: 14,
        color: theme.palette.text.primary,
      },
    },
    legend: {
      fontSize: 12,
      color: theme.palette.text.secondary,
    },
    actions: {
      marginTop: theme.spacing(3),
      display: 'flex',
    },
    button: {
      display: 'block',
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
  })
);

export type IndustrySpecializationEditFormProps = {
  industrySpecialization: IndustrySpecializationViewModel;
};

export const IndustrySpecializationAddForm = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isAdmin } = useUserRole();

  const { handleSubmit, control, formState } = useForm<FormValues>({
    mode: 'onBlur',
  });

  const { mutateAsync } = useAddIndustrySpecializationMutation();

  const onSubmit = useMemo(() => {
    const submit = async (form: FormValues) => {
      const { isLicensed, isFinanced, isHighRisk, industryName, ...request } = form;
      await mutateAsync({
        ...request,
        isLicensed: isLicensed === 'true',
        isFinanced: isFinanced === 'true',
        isHighRisk: isHighRisk === 'true',
      });
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync]);

  const history = useHistory();
  const cancelHandler = () => {
    history.push('/dictionaries/specialization');
  };

  return (
    <>
      <Breadcrumbs link={'/dictionaries/specialization'} text={t('IndustriesSpecialization')} />
      <Accordion defaultExpanded={true}>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="subtitle1">{t('Add note')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <form className={classes.form}>
            <Grid container columnSpacing={2} rowSpacing={2.5}>
              <Grid item md={24} xs={24}>
                {isAdmin && (
                  <Input
                    label={t('Specialization code')}
                    control={control}
                    name="code"
                    rules={{
                      required: {
                        value: true,
                        message: t('Required'),
                      },
                    }}
                  />
                )}
              </Grid>
              <Grid item md={24} xs={24}>
                {isAdmin && (
                  <Input
                    label={t('Description')}
                    control={control}
                    name="description"
                    rules={{
                      required: {
                        value: true,
                        message: t('Required'),
                      },
                    }}
                  />
                )}
              </Grid>
              <Grid item md={24} xs={24}>
                <IndustryAutocomplete control={control as any} />
              </Grid>
              <Grid item md={7} xs={24} className={classes.horizontalRadio}>
                <legend className={classes.legend}>{t('Licensed')}</legend>
                <Radio
                  name={'isLicensed'}
                  control={control}
                  options={[
                    { label: t('Is required'), value: 'true' },
                    { label: t('Not required'), value: 'false' },
                  ]}
                />
              </Grid>
              <Grid item md={7} xs={24} className={classes.horizontalRadio}>
                <legend className={classes.legend}>{t('Financed')}</legend>
                <Radio
                  name={'isFinanced'}
                  control={control}
                  options={[
                    { label: t('Yes'), value: 'true' },
                    { label: t('No'), value: 'false' },
                  ]}
                />
              </Grid>
            </Grid>
            <Grid item md={7} xs={24} className={classes.horizontalRadio}>
              <legend className={classes.legend}>{t('Increased risk/Manual review')}</legend>
              <Radio
                name={'isHighRisk'}
                control={control}
                options={[
                  { label: t('Is required'), value: 'true' },
                  { label: t('Not required'), value: 'false' },
                ]}
              />
            </Grid>
          </form>
        </AccordionDetails>
      </Accordion>
      <div className={classes.actions}>
        <Button
          className={classes.button}
          color="primary"
          size="medium"
          type="submit"
          variant="contained"
          disabled={formState.isSubmitting}
          onClick={onSubmit}
        >
          {t('Save')}
        </Button>
        <Button
          className={classes.button}
          color="primary"
          size="medium"
          type="button"
          variant="outlined"
          disabled={formState.isSubmitting}
          onClick={cancelHandler}
        >
          {t('Cancel')}
        </Button>
      </div>
    </>
  );
};
