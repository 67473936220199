import { Input } from '../../form';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { useCallback } from 'react';
import { RatingInput } from '../../../schema';
import { formatMoney } from '../../utils';
import { IconArrowCopy } from '../../icons';
import { Button } from '../../Button';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
    },
    kontur: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'right',
      alignItems: 'center',
      width: '50%',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      color: theme.palette.mobileGray.main,
      fontSize: 12,

      '&:hover': {
        color: theme.palette.common.black,
        cursor: (props: any) => {
          return props.konturValues ? 'pointer' : 'default';
        },
        '& $copyButton': {
          display: 'block',
        },
        '& $money': {
          transform: 'translateX(-36px)',
        },
      },
    },
    money: {
      transition: '0.3s',
    },
    copyButton: {
      display: 'none',
      position: 'absolute',
      right: 0,
      backgroundColor: theme.palette.background.light,
      border: '1px solid' + theme.palette.text.secondary,
    },
    number: {
      width: '50%',
      '& input': {
        textAlign: 'right',
      },
    },
  })
);

type RatingFinancialFieldProps = {
  name: keyof RatingInput;
  control: any;
  setValue: any;
  rules?: any;
  disabled?: boolean;
  konturValues?: RatingInput | null;
};

export const RatingFinancialField = (props: RatingFinancialFieldProps) => {
  const { control, name, rules, setValue, konturValues, disabled = false } = props;
  const classes = useStyles(props);

  const value = konturValues ? (konturValues[name] as number) : '';

  const copyHandler = useCallback(
    (ev) => {
      if (konturValues) {
        setValue(name, value);
      }
    },
    [name, setValue, konturValues, value]
  );

  return (
    <div className={classes.root}>
      <div className={classes.kontur} onClick={copyHandler}>
        {konturValues && (
          <>
            <span className={classes.money}>
              {value !== '' ? formatMoney({ amount: value, fractionDigits: 0 }) : ''}
            </span>
            <Button
              variant="iconButton"
              endIcon={<IconArrowCopy />}
              className={classes.copyButton}
            />
          </>
        )}
      </div>

      <Input
        name={name}
        control={control}
        className={classes.number}
        rules={rules}
        disabled={disabled}
        formatted
      />
    </div>
  );
};
