import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { ValidationProblemDetails } from 'schema/serverTypes';
import { createUrlFromParts } from './types';
import { MutationOptions, useBackendFetch } from './useBackend';

export const useFileUploadMutation = <TRequest, TResponse, TContext = unknown>(
  relativeUrl: string,
  getFormData: (request: TRequest) => FormData,
  options: MutationOptions<TRequest, TResponse, TContext> | undefined = { method: 'POST' }
) => {
  const fetchBackend = useBackendFetch(false);
  const requestUrl = createUrlFromParts(relativeUrl);

  const { method = 'POST', ...rest } = options;

  const mutationFn = useCallback(
    async (form: TRequest): Promise<TResponse> => {
      const requestMethod =
        method === undefined ? 'POST' : typeof method === 'string' ? method : method(form);
      const response = await fetchBackend(requestUrl, {
        method: requestMethod,
        body: getFormData(form),
      });

      if (response.status === 400) {
        const validationErrors: ValidationProblemDetails | undefined = await response.json();
        throw validationErrors ?? { errors: { '': ['Ошибка'] } };
      }
      if (response.status === 500) {
        const error: ValidationProblemDetails = { errors: { '': ['Ошибка'] } };
        throw error;
      }

      const result: TResponse = await response.json();
      return result;
    },
    [fetchBackend, method, requestUrl, getFormData]
  );

  return useMutation(mutationFn, rest);
};
