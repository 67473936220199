import { useCallback, useMemo } from 'react';
import { SearchFilterFormValues } from './types';
import { useForm, useWatch } from 'react-hook-form';
import { useDebounce } from 'use-debounce';
import { useTemplatesBackendQuery } from 'services/api';
import { TemplatePagedList } from 'schema';

const defaultValues: SearchFilterFormValues = {
  search: '',
  kind: '',
};

const useSearchParams = (searchParams: SearchFilterFormValues) => {
  const { search, users, kind } = searchParams;
  const userId = users?.id;

  return useMemo(() => {
    const searchParams = new URLSearchParams();

    if (search && search !== '') {
      searchParams.set('search', search);
    }
    if (userId) {
      searchParams.set('users', userId);
    }
    if (kind && kind !== '') {
      searchParams.set('kind', kind);
    }

    searchParams.set('pageSize', '1000000');

    return searchParams;
  }, [search, userId, kind]);
};

export const useTemplatesData = () => {
  const { control, reset } = useForm<SearchFilterFormValues>({
    mode: 'onBlur',
    defaultValues,
  });

  const handleOnReset = useCallback(() => {
    reset(defaultValues);
  }, [reset]);

  const searchValue = useWatch({ control, name: 'search' });
  const [search] = useDebounce(searchValue ?? '', 500);

  const users = useWatch({ control, name: 'users' });
  const kind = useWatch({ control, name: 'kind' });

  const searchParams = useSearchParams({
    search,
    users,
    kind,
  });

  const { data } = useTemplatesBackendQuery<TemplatePagedList>({
    queryKey: ['templates', kind, users?.id, search],
    searchParams,
    options: {
      refetchOnMount: true,
    },
  });

  const templates = data?.data ?? [];

  return {
    templates,
    filter: {
      control,
      handleOnReset,
    },
  };
};
