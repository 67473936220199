import { useFieldArray } from 'react-hook-form';
import { Requisite } from './Requisite';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CounterpartyFormProps } from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    add: {
      color: theme.palette.primary.light,
      marginTop: 0,
    },
    wrapper: {
      width: '100%',
    },
  })
);

export const RequisitesFields = (props: CounterpartyFormProps) => {
  const classes = useStyles();
  const { control, setValue } = props;

  const { fields } = useFieldArray({
    control,
    name: 'requisites',
  });

  return (
    <div className={classes.wrapper}>
      {fields.map((item, index) => {
        return (
          <Requisite
            key={item.id}
            item={item}
            index={index}
            control={control}
            setValue={setValue}
          />
        );
      })}
    </div>
  );
};
