import { themeOrange as theme } from 'theme';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { PageAuthenticatedLayout, Tabs, TabPanel, useTabs, Dnd } from 'components';
import SwipeableViews from 'react-swipeable-views';
import { Critical } from 'components';

const InsurancePageContent = () => {
  const { t } = useTranslation();
  const tabs = [t('Tariffs'), t('Groups_pl')];
  const tabsProps = useTabs(tabs);
  const { tabIndex, onChangeTabIndex, onChangeTab } = tabsProps;

  return (
    <>
      <Critical />
      <Grid container spacing={1} direction="column">
        <Tabs {...tabsProps} value={tabIndex} onChangeTab={onChangeTab} />
        <SwipeableViews
          containerStyle={{
            transition: 'transform 0.6s ease-out 0s',
            marginTop: theme.spacing(2),
          }}
          springConfig={{
            duration: '0.6s',
            easeFunction: 'transform 0.6s ease-out 0s',
            delay: '0s',
          }}
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={tabIndex}
          onChangeIndex={onChangeTabIndex}
        >
          <TabPanel value={tabIndex} index={0} dir={theme.direction}>
            <Dnd />
          </TabPanel>
          <TabPanel value={tabIndex} index={1} dir={theme.direction}>
            <Dnd />
          </TabPanel>
        </SwipeableViews>
      </Grid>
    </>
  );
};

export const InsurancePage = () => {
  return <PageAuthenticatedLayout pageContent={<InsurancePageContent />} />;
};
