import { Select, MenuItem } from '@material-ui/core';
import { useCallback } from 'react';
import { IconSprite, usePagingStyles } from 'components';
import { SelectPagingProps } from './types';

export const SelectPaging = (props: SelectPagingProps) => {
  const classes = usePagingStyles();

  const { onPageSizeChanged, pageSize } = props;

  const handleChange = useCallback(
    (e) => {
      onPageSizeChanged(e.target.value as number);
    },
    [onPageSizeChanged]
  );

  return (
    <Select
      value={pageSize}
      onChange={handleChange}
      className={classes.select}
      IconComponent={() => <IconSprite className={classes.icon} icon="dropdown" width="8px" />}
      MenuProps={{
        classes: {
          paper: classes?.paper,
          list: classes?.list,
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
    >
      <MenuItem value={20}> 20 </MenuItem>
      <MenuItem value={50}> 50 </MenuItem>
      <MenuItem value={100}> 100 </MenuItem>
    </Select>
  );
};
