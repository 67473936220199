import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
    },
  })
);

export interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

export const TabPanel = (props: TabPanelProps) => {
  const classes = useStyles();
  const { children, value, index, ...rest } = props;

  return (
    <Typography
      component="div"
      variant={'body2'}
      role="tabpanel"
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      hidden={value !== index}
      className={classes.root}
      {...rest}
    >
      {children}
    </Typography>
  );
};
