import { useCallback, useRef, useState } from 'react';
import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  Value,
} from '@material-ui/lab/useAutocomplete';
import { useCounterpartyQuery } from './useCounterpartyQuery';
import { TextFieldProps } from '../../types';
import { CounterpartyOption, CounterpartyType } from 'schema';
import { useAutocompleteTextField } from '../../useAutocompleteTextField';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';

type CounterpartyAutocompleteProps = FieldRenderProps<string | undefined, HTMLElement> &
  Omit<TextFieldProps, 'error' | 'helperText'> & {
    counterpartyType: CounterpartyType;
    label: string;
    disabled?: boolean;
    setFirstOptionAsDefault?: boolean;
    lesseeName?: string;
    setLesseeName: ((name: string) => void) | undefined;
    lesseeInn?: string;
    setLesseeInn: ((name: string | undefined) => void) | undefined;
    isOnlyAccredited?: boolean;
  };

type ChangeCallback = (
  event: React.ChangeEvent<{}>,
  value: Value<CounterpartyOption, false, false, true>,
  reason: AutocompleteChangeReason,
  details?: AutocompleteChangeDetails<CounterpartyOption>,
) => void;

const getOptionLabel = (option: CounterpartyOption) => {
  if (typeof option === 'string') {
    return option;
  }
  if (option.id === 0) {
    return option.name;
  }
  return `${option.inn} (${option.name})`;
};

const CounterpartyAutocomplete = (props: CounterpartyAutocompleteProps) => {
  const {
    counterpartyType,
    label,
    input,
    meta,
    variant = 'outlined',
    size = 'small',
    placeholder,
    disabled,
    setFirstOptionAsDefault = false,
    setLesseeName,
    lesseeName,
    setLesseeInn,
    isOnlyAccredited,
  } = props;
  const { value, onChange, name } = input;

  const [inputValue, setInputValue] = useState<string>('');
  const shouldFetchOptions = useRef<boolean>(true);

  const initialSet = useRef(false);

  const handleOnInputChange = useCallback(
    (_: React.ChangeEvent<{}>, newInputValue: string, reason: AutocompleteInputChangeReason) => {
      shouldFetchOptions.current = reason !== 'reset';

      if (reason !== 'reset') {
        setInputValue(newInputValue);
        if (setLesseeInn) {
          setLesseeInn(undefined);
        }
      } else if (!initialSet.current) {
        initialSet.current = true;
        setInputValue(newInputValue);
      }

      if (setLesseeName) {
        setLesseeName(newInputValue);
      }
    },
    [setInputValue, setLesseeName, setLesseeInn],
  );

  const { renderInput } = useAutocompleteTextField({
    name,
    error: !meta.valid && meta.touched,
    helperText: !meta.valid && meta.touched ? meta.error : null,
    label,
    variant,
    size,
    placeholder,
  });

  const { options, refetch } = useCounterpartyQuery(
    counterpartyType,
    shouldFetchOptions.current ? inputValue : '',
    value,
    isOnlyAccredited,
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  const hasNoValue = value === '' || value === undefined;
  const hasDefaultOptionSet = useRef(false);
  const inn = options.length > 0 ? options[0].inn : undefined;

  useEffect(() => {
    if (!setFirstOptionAsDefault && hasNoValue) {
      hasDefaultOptionSet.current = false;
    }
  }, [setFirstOptionAsDefault, hasNoValue]);

  useEffect(() => {
    if (
      setFirstOptionAsDefault &&
      inn !== undefined &&
      hasNoValue &&
      !hasDefaultOptionSet.current
    ) {
      hasDefaultOptionSet.current = true;
      onChange(inn);
    }
  }, [setFirstOptionAsDefault, hasNoValue, inn, onChange]);

  const handleOnChange: ChangeCallback = useCallback(
    (_event, value, reason, _details) => {
      if (reason === 'clear') {
        hasDefaultOptionSet.current = true;
      }

      if (value === null) {
        setInputValue('');
        onChange(undefined);
        if (setLesseeName) {
          setLesseeName('');
        }
      } else if (typeof value === 'string') {
        setInputValue(value);
        onChange(undefined);
        if (setLesseeName) {
          setLesseeName(value);
        }
      } else {
        const item = options.find((t) => t.inn === value?.inn);
        const name = item ? getOptionLabel(item) : '';
        setInputValue(name);
        onChange(item?.inn);
        if (setLesseeName) {
          setLesseeName(name);
        }
      }
    },
    [options, onChange, setLesseeName],
  );

  const selectedOption = useMemo(() => {
    if (value === undefined || value === '') {
      return lesseeName ?? null;
    }
    return options.find((t) => t.inn === value) || null;
  }, [value, lesseeName, options]);

  const { t } = useTranslation();

  return (
    <Autocomplete<CounterpartyOption, false, false, true>
      freeSolo
      value={selectedOption}
      options={options}
      onChange={handleOnChange}
      onInputChange={handleOnInputChange}
      getOptionSelected={(option, value) => option.inn === value.inn}
      getOptionLabel={getOptionLabel}
      noOptionsText={t('no results')}
      inputValue={inputValue}
      renderInput={renderInput}
      openText={t('Open')}
      closeText={t('Close')}
      clearText={t('Clear')}
      autoHighlight={true}
      disabled={disabled}
      popupIcon={<KeyboardArrowDownRoundedIcon color="primary" />}
    />
  );
};

type CounterpartyAutocompleteFieldProps = Omit<TextFieldProps, 'error' | 'helperText'> & {
  counterpartyType: CounterpartyType;
  validate?: UseFieldConfig<any, any>['validate'];
  disabled?: boolean;
  setFirstOptionAsDefault?: boolean;
  setLesseeName?: (name: string) => void;
  lesseeName?: string;
  setLesseeInn?: (name: string) => void;
  lesseeInn?: string;
  isOnlyAccredited?: boolean;
};

export const CounterpartyAutocompleteField = (props: CounterpartyAutocompleteFieldProps) => {
  const { counterpartyType, disabled = false, validate, ...rest } = props;
  return (
    <Field
      {...rest}
      counterpartyType={counterpartyType}
      name={counterpartyType}
      component={CounterpartyAutocomplete}
      disabled={disabled}
      validate={validate}
    />
  );
};
