import { FieldArrayWithId, useWatch } from 'react-hook-form';
import { CalculatorFormValues, useCalculationFormContext } from '../types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export type PaymentYearMonthProps = {
  item: FieldArrayWithId<CalculatorFormValues, 'paymentOptions', 'id'>;
  year: number;
};

const getMonth = (year: number, language: string, number: number, dateStr: string | undefined) => {
  const startDate =
    dateStr !== undefined && dateStr !== '' ? new Date(dateStr) : new Date(year, 1, 1);
  startDate.setMonth(startDate.getMonth() + 1);
  const date =
    number === 1
      ? startDate
      : new Date(startDate.getFullYear(), startDate.getMonth() + (number - 1), 1);
  return moment(date).locale(language).format('MMM');
};

export const PaymentMonth = (props: PaymentYearMonthProps) => {
  const { item, year } = props;
  const { control } = useCalculationFormContext();

  const seasonalPaymentOptionsDate = useWatch({
    control,
    name: `seasonalPaymentOptions.date`,
  });

  const {
    i18n: { language },
  } = useTranslation();

  const value = item.number;

  return (
    <>
      <td>{value}</td>
      <td>{getMonth(year, language, value, seasonalPaymentOptionsDate)}</td>
    </>
  );
};
