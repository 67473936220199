import { QueryKey } from 'react-query';
import { QueryOptions } from '.';
import { calculationUrl } from 'services/urls';

export const createUrlFromParts = (
  entity: string,
  relativeUrl?: string,
  searchParams?: URLSearchParams
) => {
  const urlParts = relativeUrl ? relativeUrl.trim().split('/') : [];
  const entityParts = entity.trim().split('/');
  const parts = [calculationUrl, 'api', 'v1', ...entityParts, ...urlParts].filter((t) => t !== '');
  const url = parts.join('/');
  return searchParams ? `${url}?${searchParams}` : url;
};

export const createOptions = <TResponse, TQueryKey extends QueryKey = QueryKey>(
  options: QueryOptions<TResponse, TQueryKey> | undefined = {}
) => {
  const defaultOptions: QueryOptions<TResponse> = {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: false,
  };
  return {
    ...defaultOptions,
    ...options,
  } as QueryOptions<TResponse>;
};

export type BackendQueryOptions<TResponse, TQueryKey extends QueryKey = string> = {
  relativeUrl?: string;
  searchParams?: URLSearchParams;
  options?: QueryOptions<TResponse, TQueryKey>;
};

export type EmptyResponse = {
  success: boolean;
};
