import { Button, Grid, IconBackTo } from 'components';
import { useTranslation } from 'react-i18next';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { useLocation, useParams } from 'react-router-dom';
import { WikiArticle } from './WikiArticle';
import { useWikiItemQuery } from '../../services/api/useWikiBackend';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    wrapper: {
      width: '100%',
    },
    span: {
      marginLeft: theme.spacing(1.6),
      color: theme.palette.common.black,
    },
    commentsLink: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(-4),
    },
    chatIcon: {
      marginLeft: theme.spacing(1.2),
    },
  })
);

export const WikiEdit = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { data } = useWikiItemQuery(parseInt(id, 10));

  let { pathname } = useLocation();
  pathname = '/' + pathname.split('/')[1];

  return (
    <div className={classes.wrapper}>
      <Box mb={2}>
        <Button variant="iconButton" endIcon={<IconBackTo />} to={pathname} />
        <span className={classes.span}>{t('New article')}</span>
      </Box>
      <Grid container columnSpacing={2.5}>
        <Grid item xs={24}>
          {data && <WikiArticle article={data} />}
        </Grid>
        <Grid item xs={24}>
          <div id="comments" />
        </Grid>
      </Grid>
    </div>
  );
};
