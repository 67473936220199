import { useTranslation } from 'react-i18next';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Button } from 'components/Button';
import { MissingHistoryCountGroupTabId } from 'schema/serverTypes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: theme.spacing(1.5, 2.5),
    },
    active: {
      backgroundColor: theme.palette.primary.light,
      pointerEvents: 'none',
      color: theme.palette.common.black,
    },
    nextMonth: {
      textTransform: 'capitalize',
    },
  })
);

export interface MissingFilterProps {
  setCounterparties: () => void;
  setDocuments: () => void;
  setManagers: () => void;
  currentFilter: MissingHistoryCountGroupTabId;
}

export const MissingFilter = (props: MissingFilterProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const activeClass = classes.active;
  const { setCounterparties, setDocuments, setManagers, currentFilter } = props;

  const counterpartiesClass =
    currentFilter === null || currentFilter === MissingHistoryCountGroupTabId.Counterparties
      ? activeClass
      : '';

  const documentsClass =
    currentFilter === MissingHistoryCountGroupTabId.Documents ? activeClass : '';

  const managersClass = currentFilter === MissingHistoryCountGroupTabId.Managers ? activeClass : '';

  return (
    <Box mt={2} mb={1.25}>
      <Button variant={'chip'} className={counterpartiesClass} onClick={setCounterparties}>
        {t('Counterparty_plural')}
      </Button>
      <Button variant={'chip'} className={documentsClass} onClick={setDocuments}>
        {t('Documents')}
      </Button>
      <Button variant={'chip'} className={managersClass} onClick={setManagers}>
        {t('Manager_plural')}
      </Button>
    </Box>
  );
};
