import { useCallback } from 'react';
import { useWatch, Control, UseFormSetValue } from 'react-hook-form';
import { MissingOverdueFilterFormValues } from './types';
import { MissingChip } from '../missing';

export type OverdueChipsProps = {
  control: Control<MissingOverdueFilterFormValues, object>;
  setValue: UseFormSetValue<MissingOverdueFilterFormValues>;
  hideManagers?: boolean;
  hideCounterparties?: boolean;
};

export const OverdueChips = (props: OverdueChipsProps) => {
  const { control, setValue, hideManagers = false, hideCounterparties = false } = props;

  const managerId = useWatch({ control, name: 'managerId' });

  const onManagerIdRemove = useCallback(
    (id: string) => {
      const managers = managerId?.filter((v) => v.id !== id);
      setValue('managerId', managers);
    },
    [managerId, setValue]
  );

  const managerChips = managerId?.map((value) => {
    const { id, name } = value;
    return (
      <MissingChip label={name} id={id} key={`managerId-${id}`} onRemove={onManagerIdRemove} />
    );
  });

  const inn = useWatch({ control, name: 'inn' });

  const onInnRemove = useCallback(
    (id: string) => {
      const inns = inn?.filter((v) => v.inn !== id);
      setValue('inn', inns);
    },
    [inn, setValue]
  );

  const innChips = inn?.map((value) => {
    const { inn, name } = value;
    return <MissingChip label={name} id={inn} key={`inn-${inn}`} onRemove={onInnRemove} />;
  });

  const status = useWatch({ control, name: 'contractStatus' });

  const onStatusRemove = useCallback(
    (label: string) => {
      const statuses = status?.filter((v) => v !== label);
      setValue('contractStatus', statuses);
    },
    [status, setValue]
  );

  const statusChips = status?.map((value) => {
    return <MissingChip label={value} key={`status-${value}`} onRemove={onStatusRemove} />;
  });

  return (
    <>
      {!hideCounterparties && innChips}
      {statusChips}
      {!hideManagers && managerChips}
    </>
  );
};
