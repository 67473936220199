import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Button, DatePicker, Grid, IconSprite, Input, IssueControlAdd, Role } from 'components';
import { IssueType, ReportShipmentViewModel } from 'schema/serverTypes';
import { useShipmentReportQuery } from 'services/api';
import { useToast } from '../../Toast';
import { ApplicationRoles } from 'services/authentication/ApplicationRoles';
import { themeOrange as theme } from 'theme';
import { Link } from 'react-router-dom';
import { IssueMissing } from '../../tasks/IssueMissing';
import { useReportItemsMutation } from '../../../services/api/useShipmentItemsBackend';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalTitle: {
      textAlign: 'center',
      marginBottom: theme.spacing(1.5),
    },
    modalText: {
      color: theme.palette.text.primary,
      textAlign: 'center',
      marginBottom: theme.spacing(4),
      fontWeight: 500,
      fontSize: 14,
    },
    item: {
      marginBottom: theme.spacing(1),
      fontSize: 10,
      color: theme.palette.text.secondary,
      '& a$value': {
        fontSize: 12,
        color: theme.palette.secondary.dark,
      },
    },
    contractContainer: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    },
    contract: {
      color: theme.palette.secondary.dark,
      fontWeight: 500,
      fontSize: 12,
    },
    contractStatus: {
      color: theme.palette.text.primary,
      fontWeight: 400,
      fontSize: 12,
    },
    sep: {
      color: theme.palette.secondary.main,
      display: 'inline-block',
      margin: theme.spacing(0, 0.75),
    },
    icon: {
      verticalAlign: 'middle',
      marginLeft: theme.spacing(1),
      marginBottom: 2,
    },

    label: {
      color: theme.palette.text.secondary,
      fontWeight: 700,
      fontSize: 12,
    },
    value: {
      color: theme.palette.common.black,
      fontWeight: 400,
      fontSize: 12,
    },
    button: {
      display: 'block',
      margin: '4px auto 0',
    },
    required: {
      marginTop: theme.spacing(-1.5),
      color: theme.palette.text.primary,
    },
    actions: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    actionButton: {
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
  })
);

export type ShipmentFormProps = {
  //item: ShipmentGroupItemViewModel | ShipmentItemViewModel;
  quotaId: number;
  checkedItemIds: number[];
  onClose: () => void;
};

export const MultiShipmentReportForm = (props: ShipmentFormProps) => {
  const classes = useStyles();
  const { quotaId, onClose, checkedItemIds } = props;
  const toast = useToast();

  const { data } = useShipmentReportQuery(quotaId);

  const { mutateAsync } = useReportItemsMutation(quotaId, {
    method: 'PUT',
    onSuccess: () => {
      toast(t('ShipmentSuccessMessage'), 'success');
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const { control, handleSubmit, setValue } = useForm<ReportShipmentViewModel>({
    defaultValues: {
      shipmentDate: new Date().toUTCString(),
    },
    mode: 'all',
  });

  const onSubmit = useMemo(() => {
    const submit = async (form: any) => {
      const obj = {
        quotaId: quotaId,
        ids: checkedItemIds,
        item: { ...form },
      };
      await mutateAsync(obj);
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync, quotaId, checkedItemIds]);

  const { t } = useTranslation();

  const message = t('Required');

  const formId = `shipment-multi-form-${quotaId}`;

  return (
    <form id={formId} onSubmit={onSubmit}>
      <Typography variant={'h2'} className={classes.modalTitle}>
        {t('IssueType.ShipmentReport')}
      </Typography>
      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item md={10} xs={24}>
          <DatePicker
            label={`${t('Expected date (act)')}`}
            name="shipmentDate"
            control={control}
            variant={'dialog'}
            rules={{
              required: {
                value: true,
                message,
              },
            }}
          />
        </Grid>

        {data?.contractNumber && (
          <Grid item md={14} xs={24}>
            <div className={classes.contractContainer}>
              <a
                href={`/quotas/calculator/${data?.quotaId}`}
                target="_blank"
                rel="noreferrer"
                className={classes.contract}
              >
                {`${t('Contract')} № ${data?.contractNumber}`}
                <IconSprite
                  className={classes.icon}
                  icon={'view'}
                  width={'15px'}
                  height={'10px'}
                  color={theme.palette.primary.main}
                />
              </a>
              <span className={classes.sep}>|</span>
              <span className={classes.contractStatus}>{data?.contractStatus}</span>
            </div>
          </Grid>
        )}

        <Grid item md={24} xs={24}>
          {data?.lessee && (
            <div className={classes.item}>
              {t('Lessee')}
              <br />
              <Link
                to={`/counterparties/${data.lessee.inn}`}
                className={classes.value}
                target="_blank"
                rel="noopener"
              >
                {data.lessee.name} | {data.lessee.inn}
              </Link>
              <IssueMissing inn={data.lessee.inn} counterpartyName={data.lessee.name} />
            </div>
          )}
          {data?.dealer && (
            <div className={classes.item}>
              {t('Dealer')}
              <br />
              <Link
                to={`/counterparties/${data.dealer.inn}`}
                className={classes.value}
                target="_blank"
                rel="noopener"
              >
                {data.dealer.name} | {data.dealer.inn}
              </Link>
              <IssueMissing inn={data.dealer.inn} counterpartyName={data.dealer.name} />
            </div>
          )}
        </Grid>
        <Grid item md={24} xs={24}>
          <IssueControlAdd issueType={IssueType.ShipmentReport} setValue={setValue} />
        </Grid>
        <Grid item md={24} xs={24}>
          <Input
            label={t('Additional info')}
            control={control}
            name="description"
            multiline={true}
          />
        </Grid>
        <Grid item md={24} xs={24}>
          <div className={classes.actions}>
            <Role role={ApplicationRoles.allExceptAssetManager}>
              <div className={classes.actionButton}>
                <Button
                  className={classes.button}
                  color="primary"
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  {t('Send report')}
                </Button>
              </div>
              <div className={classes.actionButton}>
                <Button
                  className={classes.button}
                  type="button"
                  color="primary"
                  size="medium"
                  variant="outlined"
                  onClick={onClose}
                >
                  {t('Cancel2')}
                </Button>
              </div>
            </Role>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};
