import React from 'react';
import { DragHandle } from './SortableItem';
import { ToggleItemHandle } from './ToggleItemHandle';

type SortableItemContainerProps = {
  children: React.ReactNode;
  toggleOpen: () => void;
  open: boolean;
};

export const SortableItemContainer = (props: SortableItemContainerProps) => {
  const { children, toggleOpen, open } = props;

  return (
    <>
      <DragHandle />
      <ToggleItemHandle toggleOpen={toggleOpen} open={open} />
      {children}
    </>
  );
};
