import { useParams } from 'react-router-dom';
import { CountryRiskEditForm } from './CountryRiskEditForm';
import { useCountryRiskByIdQuery } from 'services';

type FormProps = {
  id: number;
};

const Form = (props: FormProps) => {
  const { data, isLoading } = useCountryRiskByIdQuery(props.id);

  if (isLoading || data === undefined) {
    return null;
  }

  return <CountryRiskEditForm countryRisk={data} />;
};

export const CountryRiskEditFormPage = () => {
  const { id } = useParams<{ id: string }>();

  const specId = parseInt(id, 10);

  if (Number.isNaN(specId)) {
    return null;
  }

  return <Form id={specId} />;
};
