import { useCallback, useState } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IconCheckbox } from '../../../icons';
import { Autocomplete, Chip } from 'components';
import { useMissingManagerListRequest } from 'services/api/useMissingBackend';
import { MissingManagerViewModel } from 'schema/serverTypes';
import { AutocompleteChangeDetails } from '@material-ui/lab';
import { MissingSettingsFormProps } from '../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    valueList: {
      padding: theme.spacing(1.5, 1, 0, 0),
    },
    option: {
      marginTop: theme.spacing(-1),
      marginLeft: theme.spacing(-2),
      marginBottom: theme.spacing(-1),
    },
    error: {
      color: theme.palette.chartPurple.main,
      fontSize: 9,
      margin: '4px 0 0',
    },
  })
);

const getOptionLabel = (option: MissingManagerViewModel | null) => {
  if (option === null) {
    return '';
  }
  return option.name;
};

const getOptionSelected = (
  option: MissingManagerViewModel | null,
  value: MissingManagerViewModel | null
) => {
  if (option === null || value === null) {
    return false;
  }
  return option.id === value.id;
};

export const ManagerSelectField = (props: MissingSettingsFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { control, required = false } = props;

  const {
    field: { value, onChange },
    fieldState: { invalid },
  } = useController({
    control,
    name: 'managerId',
    rules: required
      ? {
          required: {
            value: true,
            message: t('Required'),
          },
        }
      : undefined,
  });

  const helperText = invalid ? t('Required') : undefined;
  const [inputValue, setInputValue] = useState('');

  const request = { count: 100 };
  const { data: options = [] } = useMissingManagerListRequest(request);

  const handleOnInputChange = useCallback(
    (_ev: React.ChangeEvent<{}>, value: string) => {
      setInputValue(value);
    },
    [setInputValue]
  );

  const managers = value
    .map((t) => options.find((o) => o.id === t))
    .filter((t) => t !== undefined)
    .map((t) => t as MissingManagerViewModel);
  const managerIds = value;

  const handleOnChange = useCallback(
    (
      _e: React.ChangeEvent<{}>,
      values: MissingManagerViewModel[],
      reason,
      details?: AutocompleteChangeDetails<MissingManagerViewModel>
    ) => {
      if (reason === 'remove-option') {
        const value = details?.option;
        if (value) {
          const newValues = managerIds.filter((t) => t !== value.id);
          onChange(newValues);
        }
      } else {
        onChange(values.map((t) => t.id));
      }
    },
    [onChange, managerIds]
  );

  const handleOnRemove = useCallback(
    (id: string) => {
      const newValue = value.filter((t) => t !== id);
      onChange(newValue);
    },
    [value, onChange]
  );

  const valueToOptions = value.map((v) => ({ id: v, name: v }));

  return (
    <>
      <Autocomplete<MissingManagerViewModel, true>
        error={invalid}
        helperText={helperText}
        multiple
        label={`${t('Manager')}`}
        options={options}
        inputValue={inputValue}
        onInputChange={handleOnInputChange}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        onChange={handleOnChange}
        value={valueToOptions}
        disableCloseOnSelect={true}
        renderTags={() => null}
        renderOption={(option: MissingManagerViewModel, { selected }) => {
          return (
            <div key={option.id} className={classes.option}>
              <Checkbox
                icon={<IconCheckbox checked={false} />}
                checkedIcon={<IconCheckbox checked={true} />}
                checked={selected}
              />
              {/*<User id={option.id}/>*/}
              {option.name}
            </div>
          );
        }}
      />
      <div className={classes.valueList}>
        {value
          ?.map((managerId: string) => {
            const manager = managers.find((t) => t.id === managerId);
            if (manager === undefined) {
              return null;
            }
            return (
              <Chip
                label={manager.name}
                id={manager.id}
                key={manager.id}
                onRemove={handleOnRemove}
              />
            );
          })
          .filter((t) => t !== null)}
      </div>
    </>
  );
};
