import { ContractDocumentListViewModel } from '../../../../schema';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { IconSprite } from 'components';
import { themeOrange as theme } from 'theme';
import React, { useCallback } from 'react';
import useFileDownloader from '../../../utils/useFileDownloader';
import { alpha } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      height: 30,
    },
    details: {
      minHeight: 80,
      alignItems: 'center',
      padding: theme.spacing(2, 2.5),
    },
    fileName: {
      color: theme.palette.text.primary,
    },
    fileDescription: {
      fontSize: 12,
      color: theme.palette.text.secondary,
    },
    text: {
      width: '100%',
    },
    link: {
      display: 'flex',
      cursor: 'pointer',
      width: '100%',
      border: `1px solid ${theme.palette.background.default}`,
      padding: theme.spacing(1),
      borderRadius: 4,
    },
    icon: {
      width: 26,
      height: 32,
      marginRight: theme.spacing(1),
    },
    downloadIcon: {
      width: 34,
      height: 32,
      display: 'flex',
      flex: '0 0 auto',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.secondary.light,
    },
    status: {
      height: 32,
      color: theme.palette.info.main,
      fontSize: 12,
      border: '1px solid',
      borderColor: alpha(theme.palette.info.main, 0.33),
      borderRadius: 4,
      padding: theme.spacing(1, 1.5),
      whiteSpace: 'nowrap',
      marginRight: theme.spacing(1),
    },
  })
);

type ContractDocumentProps = {
  document?: ContractDocumentListViewModel;
  title: string;
};

export const ContractDocument = (props: ContractDocumentProps) => {
  const classes = useStyles();
  const { title, document } = props;

  return (
    <Accordion defaultExpanded={true} disabled={true}>
      <AccordionSummary
        aria-controls="panel2a-content"
        id="panel2a-header"
        className={classes.title}
      >
        <Typography variant="subtitle1">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {document ? (
          <Doc document={document} />
        ) : (
          <Typography variant={'body2'} color={'textSecondary'}>
            Отсутсвует
          </Typography>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

const Doc = ({ document }: { document: ContractDocumentListViewModel }) => {
  const classes = useStyles();

  const downloadFile = useFileDownloader();
  const getFile = useCallback(
    async (ev) => {
      await downloadFile(ev, document.url, document.filename, undefined, { method: 'GET' });
    },
    [downloadFile, document.filename, document.url]
  );

  return (
    <div className={classes.link} onClick={getFile}>
      <IconSprite
        className={classes.icon}
        icon={document.documentType === 'Договор лизинга' ? 'dl' : 'dkp'}
      />
      <div className={classes.text}>
        <Typography variant="h5" className={classes.fileName}>
          {document.filename}
        </Typography>
        <Typography variant="body2" className={classes.fileDescription}>
          Нажмите чтобы скачать
        </Typography>
      </div>
      {document.signedDate && <div className={classes.status}>ЭДО: Подписан</div>}
      <div className={classes.downloadIcon}>
        <IconSprite
          width="14px"
          height="13px"
          color={theme.palette.secondary.dark}
          icon="download"
        />
      </div>
    </div>
  );
};
