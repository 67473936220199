import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { DatePicker, Grid } from 'components';
import { Button as MuiButton } from '@material-ui/core';
import dayjs from 'dayjs';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    button: {
      display: 'block',
      margin: '4px auto 0',
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
    },
    actionButton: {
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
  })
);

export type ShipmentRangeFilterFormProps = {
  from: string;
  to: string;
  customRangeHandler: (from: string, to: string) => void;
  onClose: () => void;
};

export const ShipmentRangeFilterForm = (props: ShipmentRangeFilterFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { customRangeHandler, onClose, from, to } = props;

  const { control, handleSubmit } = useForm({
    mode: 'all',
    defaultValues: {
      from: from,
      to: to,
    },
  });

  const onSubmit = (values: { from: string; to: string }) => {
    customRangeHandler(values.from, values.to);
    onClose();
  };

  const fromVal = useWatch({ control, name: 'from' });
  const toVal = useWatch({ control, name: 'to' });

  return (
    <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
      <Grid container columnSpacing={0} rowSpacing={0}>
        <Grid item md={12} xs={24}>
          <DatePicker
            label={`${t('From')}`}
            name="from"
            variant={'static'}
            control={control}
            maxDate={dayjs(toVal).toISOString()}
          />
        </Grid>
        <Grid item md={12} xs={24}>
          <DatePicker
            label={`${t('To')}`}
            name="to"
            variant={'static'}
            control={control}
            minDate={dayjs(fromVal).toISOString()}
          />
        </Grid>
        <Grid item md={24} xs={24}>
          <div className={classes.actions}>
            <div className={classes.actionButton}>
              <MuiButton color="primary" size="medium" type="submit" variant="text">
                {t('Ok')}
              </MuiButton>
            </div>
            <div className={classes.actionButton}>
              <MuiButton
                color="primary"
                size="medium"
                type="button"
                variant="text"
                onClick={onClose}
              >
                {t('Cancel')}
              </MuiButton>
            </div>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};
