import { Route } from 'react-router-dom';
import { RatingDataGrid } from './RatingDataGrid';
import { RatingAdd } from './RatingAdd';
import { RatingEdit } from './RatingEdit';
import { RatingCompare } from './RatingCompare';
import { useState } from 'react';

export const CounterpartyRating = () => {
  const [compareIds, setCompareIds] = useState<number[]>([]);

  return (
    <>
      <Route path="/counterparties/:inn">
        <Route path="/counterparties/:inn/rating/add" component={RatingAdd} exact />
        <Route path={`/counterparties/:inn/rating/:id(\\d+)`} component={RatingEdit} exact />
        <Route path="/counterparties/:inn/rating/compare" exact>
          <RatingCompare compareIds={compareIds} setCompareIds={setCompareIds} />
        </Route>
        <Route path="/counterparties/:inn" exact>
          <RatingDataGrid compareIds={compareIds} setCompareIds={setCompareIds} />
        </Route>
      </Route>
    </>
  );
};
