import { ApplicationRole, RoleName } from 'schema';

const roles = [
  'admin',
  'dealer',
  'super_sales_manager',
  'sales_manager',
  'sales_support',
  ApplicationRole.RiskManager,
  ApplicationRole.AssetManager,
  ApplicationRole.Vendor,
  ApplicationRole.Compliance,
  ApplicationRole.InsideSales,
] as const;

type ApplicationRoleNames = {
  [P in RoleName]: string;
} & {
  getRoleName(role: string | undefined): string | undefined;
  allExceptAssetManager: RoleName[];
  allExceptSalesSupport: RoleName[];
};

export const ApplicationRoles: ApplicationRoleNames = {
  admin: 'Administrator',
  dealer: 'Dealer',
  super_sales_manager: 'Super Sales Manager',
  sales_manager: 'Sales Manager',
  sales_support: 'Sales Support',
  risk_manager: 'Risk Manager',
  asset_manager: 'Asset Manager',
  vendor: 'Vendor',
  compliance: 'Compliance',
  inside_sales: 'InsideSales',

  getRoleName: (role: string | undefined) => {
    if (!role) {
      return undefined;
    }

    const existingRole = roles.find((r) => r === role);
    if (existingRole) {
      return ApplicationRoles[existingRole];
    }

    return undefined;
  },
  allExceptAssetManager: [
    ApplicationRole.Admin,
    ApplicationRole.Dealer,
    ApplicationRole.RiskManager,
    ApplicationRole.SalesManager,
    ApplicationRole.SalesSupport,
    ApplicationRole.SuperSalesManager,
    ApplicationRole.Vendor,
    ApplicationRole.Compliance,
    ApplicationRole.InsideSales,
  ],

  allExceptSalesSupport: [
    ApplicationRole.Admin,
    ApplicationRole.Dealer,
    ApplicationRole.RiskManager,
    ApplicationRole.AssetManager,
    ApplicationRole.SalesManager,
    ApplicationRole.SuperSalesManager,
    ApplicationRole.Vendor,
    ApplicationRole.Compliance,
    ApplicationRole.InsideSales,
  ],
};
