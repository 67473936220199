import { useQuotaMarginDetailsQuery, useUsersBackendQuery1 } from 'services';
import { MarginApprove } from './MarginApprove';
import { MarginSave } from './MarginSave';
import { NoMargin } from './NoMargin';
import { UserShortViewModel } from 'schema';

export const Margin = (props: { quotaId: number; authorId: string }) => {
  const { quotaId, authorId } = props;

  const { data: user } = useUsersBackendQuery1<UserShortViewModel>({
    relativeUrl: 'me',
    queryKey: 'me',
  });

  const isMarginAgreement = user?.isMarginAgreement ?? false;

  const { data } = useQuotaMarginDetailsQuery(quotaId);
  if (!data) return null;
  const CAN_APPROVE = 2;

  const isMarginIssue = data.marginIssue && data.marginIssue.isActive;

  if (!data.quotaId) {
    return <NoMargin />;
  } else if (
    (data.marginIssueActions.canApproveRed && data.current < CAN_APPROVE) ||
    (data.marginIssueActions.canApproveYellow && data.current >= CAN_APPROVE) ||
    (data.isStartup && isMarginAgreement && isMarginIssue && data.current < (data.minGreen ?? 0))
  ) {
    return (
      <MarginApprove
        data={data}
        canApprove={CAN_APPROVE}
        isMarginAgreement={isMarginAgreement}
        authorId={authorId}
      />
    );
  } else {
    return <MarginSave data={data} quotaId={quotaId} isMarginAgreement={isMarginAgreement} />;
  }
};
