import { createStyles, makeStyles } from '@material-ui/core/styles';

export const usePagingStyles = makeStyles((theme) =>
  createStyles({
    blockPages: {
      fontSize: 12,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: theme.spacing(2.7),
      marginBottom: theme.spacing(2.7),
      color: theme.palette.text.secondary,
    },
    blockControlItem: {
      display: 'flex',
      alignItems: 'center',
    },
    paginationLink: {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: 'none',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      textDecoration: 'none',
      width: 23,
      height: 23,
      color: theme.palette.text.primary,
      '&:not(:last-child)': {
        marginRight: theme.spacing(0.7),
      },
      '& svg': {
        fill: theme.palette.secondary.dark,
      },
      '&:focus': {
        color: theme.palette.text.primary,
      },
    },
    disabled: {
      cursor: 'default',
      '& svg': {
        fill: theme.palette.text.primary,
        '&:hover': {
          fill: theme.palette.text.primary,
        },
      },
    },
    active: {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.common.white,
      borderRadius: '50%',
      '&:focus': {
        color: theme.palette.common.white,
      },
    },
    select: {
      minWidth: '50px',
      backgroundColor: theme.palette.background.paper,
      marginRight: theme.spacing(1.5),
      position: 'relative',
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
      '& .MuiSelect-root': {
        color: theme.palette.text.primary,
        paddingLeft: theme.spacing(0.9),
      },
      '& .MuiSelect-select.MuiSelect-select': {
        paddingRight: 0,
      },
    },
    paper: {
      borderRadius: 0,
      boxShadow: '0px 5px 10px 0px #383C611A',
      border: '1px solid #D1D7E4',
      maxHeight: 185,
    },
    list: {
      paddingTop: 0,
      paddingBottom: 0,
      '& li': {
        fontFamily: 'Roboto, Helvetica, Arial, san-serif',
        color: theme.palette.text.primary,
      },
      '& .MuiListItem-button:hover': {
        backgroundColor: theme.palette.secondary.light,
      },
      '& .MuiListItem-button.Mui-selected': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
    icon: {
      position: 'absolute',
      right: '7px',
      pointerEvents: 'none',
    },
  })
);
