import { Breadcrumbs, Button, Grid, Input, Radio } from 'components';
import { useForm } from 'react-hook-form';
import { CountryRiskLevel, CountryRiskViewModel, EditCountryRiskRequest } from 'schema';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import { useHistory } from 'react-router';
import { useEditCountryRiskMutation } from '../../../services';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    back: {
      marginBottom: theme.spacing(1.5),
    },
    span: {
      fontSize: 12,
      color: theme.palette.text.primary,
      marginLeft: theme.spacing(1.6),
    },
    form: {
      width: '100%',
    },
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(5.5),
    },
    required: {
      marginTop: theme.spacing(-1.5),
      color: theme.palette.text.primary,
    },
    horizontalRadio: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& $legend': {
        marginTop: -6,
        fontSize: 14,
        color: theme.palette.text.primary,
      },
    },
    legend: {
      fontSize: 12,
      color: theme.palette.text.secondary,
      padding: '0 0 4px',
    },
    actions: {
      marginTop: theme.spacing(3),
      display: 'flex',
    },
    button: {
      display: 'block',
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
  })
);

export type CountryRiskEditFormProps = {
  countryRisk: CountryRiskViewModel;
};

export const CountryRiskEditForm = (props: CountryRiskEditFormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { countryRisk } = props;

  const { handleSubmit, control, formState } = useForm<EditCountryRiskRequest>({
    mode: 'onBlur',
    defaultValues: {
      ...countryRisk,
    },
  });

  const { mutateAsync } = useEditCountryRiskMutation(countryRisk.id);

  const onSubmit = useMemo(() => {
    const submit = async (form: EditCountryRiskRequest) => {
      await mutateAsync(form);
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync]);

  const history = useHistory();
  const cancelHandler = () => {
    history.push('/dictionaries/countryRisks');
  };

  return (
    <>
      <Breadcrumbs link={'/dictionaries/countryRisks'} text={t('Dictionaries.CountryRisks')} />
      <Accordion defaultExpanded={true}>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="subtitle1">{t('Edit note')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <form className={classes.form}>
            <Grid container columnSpacing={2} rowSpacing={2.5}>
              <Grid item md={24} xs={24}>
                <Input
                  label={`${t('Country')}`}
                  name="name"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t('Required'),
                    },
                  }}
                />
              </Grid>
              <Grid item md={24} xs={24} className={classes.horizontalRadio}>
                <legend className={classes.legend}>{t('RiskLevel.blackList')}</legend>
                <Radio
                  name={'blackListRiskLevel'}
                  control={control}
                  options={[
                    { label: t('RiskLevel.High'), value: CountryRiskLevel.High },
                    { label: t('RiskLevel.Medium'), value: CountryRiskLevel.Medium },
                    { label: t('RiskLevel.Low'), value: CountryRiskLevel.Low },
                  ]}
                />
              </Grid>
              <Grid item md={24} xs={24} className={classes.horizontalRadio}>
                <legend className={classes.legend}>{t('RiskLevel.greyList')}</legend>
                <Radio
                  name={'greyListRiskLevel'}
                  control={control}
                  options={[
                    { label: t('RiskLevel.High'), value: CountryRiskLevel.High },
                    { label: t('RiskLevel.Medium'), value: CountryRiskLevel.Medium },
                    { label: t('RiskLevel.Low'), value: CountryRiskLevel.Low },
                  ]}
                />
              </Grid>
              <Grid item md={24} xs={24} className={classes.horizontalRadio}>
                <legend className={classes.legend}>{t('RiskLevel.sanctions_full')}</legend>
                <Radio
                  name={'sanctionsRiskLevel'}
                  control={control}
                  options={[
                    { label: t('RiskLevel.High'), value: CountryRiskLevel.High },
                    { label: t('RiskLevel.Medium'), value: CountryRiskLevel.Medium },
                    { label: t('RiskLevel.Low'), value: CountryRiskLevel.Low },
                  ]}
                />
              </Grid>
              <Grid item md={24} xs={24} className={classes.horizontalRadio}>
                <legend className={classes.legend}>{t('RiskLevel.corruption_full')}</legend>
                <Radio
                  name={'corruptionRiskLevel'}
                  control={control}
                  options={[
                    { label: t('RiskLevel.High'), value: CountryRiskLevel.High },
                    { label: t('RiskLevel.Medium'), value: CountryRiskLevel.Medium },
                    { label: t('RiskLevel.Low'), value: CountryRiskLevel.Low },
                  ]}
                />
              </Grid>
              <Grid item md={24} xs={24} className={classes.horizontalRadio}>
                <legend className={classes.legend}>{t('RiskLevel.drugs_full')}</legend>
                <Radio
                  name={'drugsRiskLevel'}
                  control={control}
                  options={[
                    { label: t('RiskLevel.High'), value: CountryRiskLevel.High },
                    { label: t('RiskLevel.Medium'), value: CountryRiskLevel.Medium },
                    { label: t('RiskLevel.Low'), value: CountryRiskLevel.Low },
                  ]}
                />
              </Grid>
              <Grid item md={24} xs={24} className={classes.horizontalRadio}>
                <legend className={classes.legend}>{t('RiskLevel.terror_full')}</legend>
                <Radio
                  name={'terrorRiskLevel'}
                  control={control}
                  options={[
                    { label: t('RiskLevel.High'), value: CountryRiskLevel.High },
                    { label: t('RiskLevel.Medium'), value: CountryRiskLevel.Medium },
                    { label: t('RiskLevel.Low'), value: CountryRiskLevel.Low },
                  ]}
                />
              </Grid>
              <Grid item md={24} xs={24} className={classes.horizontalRadio}>
                <legend className={classes.legend}>{t('RiskLevel.terrorCountry_full')}</legend>
                <Radio
                  name={'terrorCountryRiskLevel'}
                  control={control}
                  options={[
                    { label: t('RiskLevel.High'), value: CountryRiskLevel.High },
                    { label: t('RiskLevel.Medium'), value: CountryRiskLevel.Medium },
                    { label: t('RiskLevel.Low'), value: CountryRiskLevel.Low },
                  ]}
                />
              </Grid>
              <Grid item md={24} xs={24} className={classes.horizontalRadio}>
                <legend className={classes.legend}>{t('RiskLevel.preferential_full')}</legend>
                <Radio
                  name={'preferentialRiskLevel'}
                  control={control}
                  options={[
                    { label: t('RiskLevel.High'), value: CountryRiskLevel.High },
                    { label: t('RiskLevel.Medium'), value: CountryRiskLevel.Medium },
                    { label: t('RiskLevel.Low'), value: CountryRiskLevel.Low },
                  ]}
                />
              </Grid>
              <Grid item md={24} xs={24} className={classes.horizontalRadio}>
                <legend className={classes.legend}>
                  {t('RiskLevel.specialEconomicMeasures_full')}
                </legend>
                <Radio
                  name={'specialEconomicMeasuresRiskLevel'}
                  control={control}
                  options={[
                    { label: t('RiskLevel.High'), value: CountryRiskLevel.High },
                    { label: t('RiskLevel.Medium'), value: CountryRiskLevel.Medium },
                    { label: t('RiskLevel.Low'), value: CountryRiskLevel.Low },
                  ]}
                />
              </Grid>
            </Grid>
          </form>
        </AccordionDetails>
      </Accordion>
      <div className={classes.actions}>
        <Button
          className={classes.button}
          color="primary"
          size="medium"
          type="submit"
          variant="contained"
          disabled={formState.isSubmitting}
          onClick={onSubmit}
        >
          {t('Save')}
        </Button>
        <Button
          className={classes.button}
          color="primary"
          size="medium"
          type="button"
          variant="outlined"
          disabled={formState.isSubmitting}
          onClick={cancelHandler}
        >
          {t('Cancel')}
        </Button>
      </div>
    </>
  );
};
