import { themeOrange as theme } from 'theme';

export type IconCheckboxUncheckedCircyleProps = {
  disabled?: boolean;
};

export const IconCheckboxUncheckedCircyle = (props: IconCheckboxUncheckedCircyleProps) => {
  const { disabled } = props;
  const colorDisabled = theme.palette.text.secondary;

  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.99992 0.333374C3.31992 0.333374 0.333252 3.32004 0.333252 7.00004C0.333252 10.68 3.31992 13.6667 6.99992 13.6667C10.6799 13.6667 13.6666 10.68 13.6666 7.00004C13.6666 3.32004 10.6799 0.333374 6.99992 0.333374ZM6.99992 12.3334C4.05325 12.3334 1.66659 9.94671 1.66659 7.00004C1.66659 4.05337 4.05325 1.66671 6.99992 1.66671C9.94659 1.66671 12.3333 4.05337 12.3333 7.00004C12.3333 9.94671 9.94659 12.3334 6.99992 12.3334Z"
        fill={disabled ? colorDisabled : theme.palette.primary.main}
      />
    </svg>
  );
};
