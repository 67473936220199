import { calculationUrl } from 'services/urls';

export const createQuotasBackendUrl = (relativeUrl?: string, searchParams?: URLSearchParams) => {
  const urlParts = relativeUrl ? relativeUrl.trim().split('/') : [];
  const parts: string[] = [calculationUrl, 'api', 'v1', 'issues', ...urlParts].filter(
    (t) => t !== ''
  );
  let url = parts.join('/');
  url = searchParams ? `${url}?${searchParams.toString()}` : url;
  return url;
};
