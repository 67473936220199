import {
  AddWikiItemRequest,
  AddWikiTagRequest,
  CommentWikiViewModel,
  PageOptions,
  UpdateWikiItemViewModel,
  UpdateWikiTagViewModel,
  ValidationProblemDetails,
  WikiItemViewModel,
  WikiPagedList,
  WikiSorting,
  WikiTagListRequest,
  WikiTagPagedList,
  WikiTagViewModel,
} from 'schema/serverTypes';
import { createUrlFromParts, createOptions, BackendQueryOptions, EmptyResponse } from './types';
import { useBackendQuery, useBackendMutation, MutationOptions } from './useBackend';
import { useFileUploadMutation } from './useFileUploadMutation';
import { useQueryClient } from 'react-query';
import { useCallback } from 'react';

const entity = 'wiki';
const tagEntity = `${entity}/tags`;

const useWikiBackendQuery = <TResponse>(options: BackendQueryOptions<TResponse>) => {
  let queryKey = entity;
  if (options.relativeUrl && options.relativeUrl !== '') {
    queryKey = `${queryKey}-${options.relativeUrl}`;
  }
  if (options.searchParams) {
    queryKey = `${queryKey}-${options.searchParams}`;
  }
  return useBackendQuery(
    createUrlFromParts(entity, options.relativeUrl, options.searchParams),
    queryKey,
    createOptions(options.options)
  );
};

export type WikiListRequest = PageOptions &
  WikiSorting & {
    tags?: string[];
    search?: string;
  };

export const useWikiListQuery = (request: WikiListRequest) => {
  const { page, pageSize, sortBy, order, tags = [], search } = request;
  let searchParams = new URLSearchParams();

  if (tags.length > 0) {
    tags.forEach((tag) => searchParams.append('tags', tag));
  }

  searchParams.append('sortBy', sortBy);
  searchParams.append('orderBy', order);

  if (page) {
    searchParams.set('page', page.toString());
  }
  if (pageSize) {
    searchParams.set('pageSize', pageSize.toString());
  }
  if (search && search !== '') {
    searchParams.set('search', search);
  }

  return useWikiBackendQuery<WikiPagedList>({
    searchParams,
    options: {
      refetchOnMount: true,
    },
  });
};

export const useWikiItemQuery = (id: number) => {
  return useWikiBackendQuery<WikiItemViewModel>({
    relativeUrl: `${id}`,
    options: {
      refetchOnMount: true,
    },
  });
};

const getFormData = (request: AddWikiItemRequest) => {
  const { title, text, tags = [], files = [] } = request;

  const formData = new FormData();
  formData.append('title', title);
  formData.append('text', text);
  tags.forEach((tag) => formData.append('tags', tag));
  files.forEach((file) => formData.append('files', file));

  return formData;
};

const getUpdateFormData = (request: UpdateWikiItemViewModel) => {
  const formData = getFormData(request);

  const { attachments = [] } = request;

  attachments.forEach((attachment) => formData.append('attachments', attachment));

  return formData;
};

const getCommentFormData = (request: CommentWikiViewModel) => {
  const { text, files } = request;

  const formData = new FormData();
  formData.append('text', text);
  files.forEach((file: string | Blob) => formData.append('files', file));

  return formData;
};

type MutationProps<TRequest, TResponse> = Omit<
  MutationOptions<TRequest, TResponse>,
  'onSuccess' | 'onError'
> & {
  onSuccess?: (item: TRequest) => void;
  onError?: (error: ValidationProblemDetails) => void;
};

export const useAddWikiMutation = (
  options?: MutationOptions<AddWikiItemRequest, WikiItemViewModel>
) => useFileUploadMutation<AddWikiItemRequest, WikiItemViewModel>('wiki', getFormData, options);

export const useUpdateWikiMutation = (
  id: number,
  options?: MutationOptions<UpdateWikiItemViewModel, WikiItemViewModel>
) =>
  useFileUploadMutation<UpdateWikiItemViewModel, WikiItemViewModel>(
    `${entity}/${id.toString()}`,
    getUpdateFormData,
    { ...options, method: 'PUT' }
  );

export const useDeleteWikiMutation = (
  id: number,
  options: MutationOptions<any, EmptyResponse> | undefined
) => {
  return useBackendMutation(createUrlFromParts(entity, id.toString()), {
    ...options,
    method: 'DELETE',
  });
};

export const useWikiTagListQuery = (request: WikiTagListRequest) => {
  const { page, pageSize, sortBy, order, search } = request;
  let searchParams = new URLSearchParams();

  searchParams.append('sortBy', sortBy);
  searchParams.append('orderBy', order);

  if (page) {
    searchParams.set('page', page.toString());
  }
  if (pageSize) {
    searchParams.set('pageSize', pageSize.toString());
  }
  if (search && search !== '') {
    searchParams.set('search', search);
  }

  return useWikiBackendQuery<WikiTagPagedList>({
    relativeUrl: 'tags',
    searchParams,
    options: {
      refetchOnMount: true,
    },
  });
};

export const useDeleteWikiTagMutation = (
  id: number,
  options: MutationOptions<any, EmptyResponse> | undefined
) => {
  return useBackendMutation(createUrlFromParts(tagEntity, id.toString()), {
    ...options,
    method: 'DELETE',
  });
};

export const useAddWikiCommentMutation = (
  id: number,
  options?: MutationOptions<CommentWikiViewModel, WikiItemViewModel>
) =>
  useFileUploadMutation<CommentWikiViewModel, WikiItemViewModel>(
    `${entity}/${id.toString()}/comments`,
    getCommentFormData,
    { ...options, method: 'POST' }
  );

export const useDeleteWikiCommentMutation = (
  id: number,
  options: MutationOptions<any, EmptyResponse> | undefined
) => {
  return useBackendMutation(createUrlFromParts(entity, `comments/${id}`), {
    ...options,
    method: 'DELETE',
  });
};

const useInvalidateQuery = () => {
  const queryClient = useQueryClient();

  return useCallback(() => {
    queryClient.invalidateQueries({
      predicate: (query) => {
        return query.queryKey.indexOf('wiki') > -1;
      },
    });
  }, [queryClient]);
};

export const useAddWikiTagMutation = (
  options: MutationProps<AddWikiTagRequest, WikiTagViewModel> | undefined = undefined
) => {
  const invalidateQuery = useInvalidateQuery();
  const onUpdated = options?.onSuccess;
  const onNotUpdated = options?.onError;

  const onSuccess = useCallback(
    (data: WikiTagViewModel, _variables, _context) => {
      invalidateQuery();

      if (onUpdated) {
        onUpdated(data);
      }
    },
    [invalidateQuery, onUpdated]
  );

  const onError = useCallback(
    (error: ValidationProblemDetails, _variables, _context) => {
      if (onNotUpdated) {
        onNotUpdated(error);
      }
    },
    [onNotUpdated]
  );

  return useBackendMutation(createUrlFromParts(tagEntity), {
    ...options,
    onSuccess,
    onError,
    method: 'POST',
  });
};

export const useUpdateWikiTagMutation = (
  id: number,
  options: MutationProps<UpdateWikiTagViewModel, WikiTagViewModel> | undefined = undefined
) => {
  const invalidateQuery = useInvalidateQuery();
  const onUpdated = options?.onSuccess;
  const onNotUpdated = options?.onError;

  const onSuccess = useCallback(
    (data: WikiTagViewModel, _variables, _context) => {
      invalidateQuery();

      if (onUpdated) {
        onUpdated(data);
      }
    },
    [invalidateQuery, onUpdated]
  );

  const onError = useCallback(
    (error: ValidationProblemDetails, _variables, _context) => {
      if (onNotUpdated) {
        onNotUpdated(error);
      }
    },
    [onNotUpdated]
  );

  return useBackendMutation(createUrlFromParts(tagEntity, id.toString()), {
    ...options,
    onSuccess,
    onError,
    method: 'PUT',
  });
};
