import { ShipmentFormProps } from './types';
import { useQuotasBackendQuery } from 'services/api';
import { ShipmentViewModel } from 'schema/serverTypes';
import { Button } from '../../Button';
import { useTranslation } from 'react-i18next';
import { Route } from 'react-router-dom';
import { useMemo } from 'react';
import { MultiExpectedDateForm } from './MultiExpectedDateForm';
import { createStyles, makeStyles, Paper, Theme } from '@material-ui/core';
import { ShipmentItem } from './ShipmentItem';
import { MultiTelematics } from './MultiTelematics';
import { MultiShipmentReport } from './MultiShipmentReport';
import { MultiShipmentAct } from './MultiShipmentAct';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panels: {
      marginBottom: (props: any) => (props.isTask ? '0' : '75px'),
    },
    actions: {
      padding: theme.spacing(2, 2.5),
      marginBottom: theme.spacing(2),
      borderRadius: 0,
      '& > *': {
        marginRight: theme.spacing(2.5),
      },
    },
  })
);

export const ShipmentForm = (props: ShipmentFormProps) => {
  const { t } = useTranslation();
  const classes = useStyles(props);

  const { quota, shipmentItemId, setIsDirty } = props;

  const { data, isLoading } = useQuotasBackendQuery<ShipmentViewModel>(
    `${quota.quotaId}/shipment`,
    ['quotas', quota.quotaId, 'shipment'],
    {
      refetchOnWindowFocus: true,
    }
  );

  const items = useMemo(() => {
    let initialItems = data?.items ?? []; // Инициализация items

    if (shipmentItemId) {
      return initialItems.filter((t) => t.id === shipmentItemId);
    }

    return initialItems;
  }, [data?.items, shipmentItemId]);

  const isLocked = data?.isLocked ?? false;
  const telematics = quota.calculationResult.telematics;

  return (
    <>
      <Route path="/quotas/calculator/:quotaId" exact>
        <Paper className={classes.actions}>
          <Button variant="contained" to={`/quotas/calculator/${quota.quotaId}/expecteddate`}>
            {t('Expected shipping date')}
          </Button>
          <Button
            variant="contained"
            to={`/quotas/calculator/${quota.quotaId}/telematics`}
            disabled={telematics === 'none'}
          >
            {t('Order telematics')}
          </Button>
          <Button variant="contained" to={`/quotas/calculator/${quota.quotaId}/act`}>
            {t('Request an act')}
          </Button>
          <Button variant="contained" to={`/quotas/calculator/${quota.quotaId}/report`}>
            {t('Shipment reports')}
          </Button>
        </Paper>
        <div className={classes.panels}>
          {items.map((item, index) => {
            return (
              <ShipmentItem
                key={item.id}
                item={item}
                index={index}
                quotaId={quota.quotaId}
                authorId={quota.user.id}
                dealer={quota.dealer}
                lessee={quota.lessee}
                lessor={data?.lessor}
                isLoading={isLoading}
                isLocked={isLocked}
                telematics={telematics}
                setIsDirty={setIsDirty}
              />
            );
          })}
        </div>
      </Route>
      <Route path="/quotas/calculator/:quotaId/expecteddate" exact>
        <MultiExpectedDateForm
          quota={quota}
          lessor={data?.lessor}
          items={items}
          isLocked={isLocked}
          isLoading={isLoading}
          telematics={telematics}
          setIsDirty={setIsDirty}
        />
      </Route>
      <Route path="/quotas/calculator/:quotaId/telematics" exact>
        <MultiTelematics
          quota={quota}
          lessor={data?.lessor}
          items={items}
          isLocked={isLocked}
          isLoading={isLoading}
          telematics={telematics}
          setIsDirty={setIsDirty}
        />
      </Route>
      <Route path="/quotas/calculator/:quotaId/act" exact>
        <MultiShipmentAct
          quota={quota}
          lessor={data?.lessor}
          items={items}
          isLocked={isLocked}
          isLoading={isLoading}
          telematics={telematics}
          setIsDirty={setIsDirty}
        />
      </Route>
      <Route path="/quotas/calculator/:quotaId/report" exact>
        <MultiShipmentReport
          quota={quota}
          lessor={data?.lessor}
          items={items}
          isLocked={isLocked}
          isLoading={isLoading}
          telematics={telematics}
          setIsDirty={setIsDirty}
        />
      </Route>
    </>
  );
};
