import { useCallback, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteChangeDetails,
} from '@material-ui/lab/useAutocomplete';
import { Autocomplete } from 'components';
import { IssueType, UserViewModel } from 'schema/serverTypes';
import { useIssueBackendMutation, useUsersBackendQuery } from 'services/api';
import { UserListResult } from 'components/users/types';
import { useController, useForm } from 'react-hook-form';
import { createQuotasBackendUrl } from '../../services/api/createQuotasBackendUrl';
import { useQueryClient } from 'react-query';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import { ApplicationRoles } from 'services';

const getOptionLabel = (option: UserViewModel | null) => {
  if (option === null) {
    return '';
  }
  return option.name;
};

const getOptionSelected = (option: UserViewModel | null, value: UserViewModel | null) => {
  if (option === null || value === null) {
    return false;
  }
  return option.id === value.id;
};

export type ChangeAssigneeAutocompleteProps = {
  assignee?: { id: string; name: string };
  issueId: number;
  issueType: IssueType;
};

export const ChangeAssigneeAutocomplete = (props: ChangeAssigneeAutocompleteProps) => {
  const { assignee = null, issueId, issueType } = props;

  const queryKey = createQuotasBackendUrl(`/${issueId}`);
  const queryClient = useQueryClient();

  const { control, handleSubmit } = useForm({
    mode: 'onBlur',
    defaultValues: {
      assignee,
    },
  });

  const [inputValue, setInputValue] = useState('');
  const [input] = useDebounce(inputValue, 500);

  const searchParams = useMemo(() => {
    const sParams = new URLSearchParams();
    if (input !== '') {
      sParams.set('search', input);
    }
    if (issueType === IssueType.Contract) {
      sParams.set('contractOnly', 'true');
    }

    return sParams;
  }, [issueType, input]);

  const { data } = useUsersBackendQuery<UserListResult>({
    queryKey: ['users', input],
    searchParams,
  });
  const options: UserViewModel[] =
    data?.data
      .filter((t) =>
        issueType !== IssueType.Verification
          ? true
          : t.roles.indexOf(ApplicationRoles.sales_support) > -1
      )
      .map((t) => {
        return {
          id: t.id,
          name: t.name,
        };
      }) ?? [];

  if (
    assignee !== undefined &&
    assignee !== null &&
    options.find((t) => t.id === assignee.id) === undefined
  ) {
    options.push(assignee);
  }

  const handleOnInputChange = useCallback(
    (_ev: React.ChangeEvent<{}>, value: string, _reason: AutocompleteInputChangeReason) => {
      setInputValue(value);
    },
    [setInputValue]
  );

  const {
    field: { onChange },
  } = useController({
    control,
    name: 'assignee',
  });

  const { mutateAsync } = useIssueBackendMutation<any, any>(`${issueId}/assignee`, {
    method: 'PUT',
  });

  const handleOnChange = useCallback(
    (
      _e: React.ChangeEvent<{}>,
      value: UserViewModel | null,
      _reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<UserViewModel | null>
    ) => {
      handleSubmit(async (data) => {
        if (value?.id) {
          const newAssignee = await mutateAsync({ assigneeId: value?.id });
          if (newAssignee) {
            await queryClient.invalidateQueries(queryKey);
          }
        }
      })();
      onChange(value);
    },
    [onChange, handleSubmit, mutateAsync, queryClient, queryKey]
  );

  return (
    <Autocomplete<UserViewModel | null>
      label={''}
      options={options}
      inputValue={inputValue}
      onInputChange={handleOnInputChange}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      onChange={handleOnChange}
      variant="filled"
      value={assignee}
      popupIcon={<KeyboardArrowDownRoundedIcon color="primary" />}
    />
  );
};
