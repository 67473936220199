import { Route, useRouteMatch } from 'react-router-dom';
import { UserList, UserEditFormPanel, PrivateRoute } from 'components';
import { useTranslation } from 'react-i18next';
import { useErrorContext } from 'components/tasks';
import { IssueSource } from 'schema/serverTypes';

export const UsersPage = () => {
  const { path } = useRouteMatch();
  const userPath = `/users/view/:id`;

  const { t } = useTranslation();

  useErrorContext({
    title: t('User_plural'),
    source: IssueSource.user,
  });

  return (
    <Route path="/users">
      <PrivateRoute path={path} component={UserList} role={['admin', 'super_sales_manager']} />
      <PrivateRoute path={userPath} component={UserEditFormPanel} exact />
    </Route>
  );
};
