import { useTranslation } from 'react-i18next';
import { FixedAmountInput } from '../AmountInput';
import { AmountType } from 'schema/serverTypes';
import { useCalculationFormContext } from '../types';
import { useWatch } from 'react-hook-form';
import { useEffect } from 'react';
import { calculateFundingAmount, calculatePrepaymentAmount } from '../calculations';

export const FundingAmountInput = () => {
  const { t } = useTranslation();

  const { control, getValues, setValue } = useCalculationFormContext();

  const leaseItemCost = useWatch({
    control,
    name: 'leaseItemCost',
  });

  const tradeFee = useWatch({
    control,
    name: 'tradeFee',
  });

  const itemDiscount = useWatch({
    control,
    name: 'itemDiscount',
  });

  const prepayment = useWatch({
    control,
    name: 'prepayment',
  });

  useEffect(() => {
    const prepaymentAmount = calculatePrepaymentAmount(leaseItemCost, prepayment, itemDiscount);
    const fundingAmount = calculateFundingAmount(leaseItemCost, prepaymentAmount, itemDiscount);

    const currentFundingAmount = getValues('fundingAmount') ?? 0;

    if (fundingAmount !== currentFundingAmount) {
      setValue('fundingAmount', fundingAmount);
    }
  }, [tradeFee, itemDiscount, leaseItemCost, prepayment, getValues, setValue]);

  return (
    <FixedAmountInput
      name="fundingAmount"
      label={t('FundingAmount')}
      amountMode={AmountType.Money}
      disabled
    />
  );
};
