import { useWatch } from 'react-hook-form';
import { QuotaChipsProps } from './types';
import { useCallback } from 'react';
import { Chip } from 'components/Chips';
import { useTranslation } from 'react-i18next';
import { QuotaStatus } from 'schema';

export const StatusChips = (props: QuotaChipsProps) => {
  const { control, setValue } = props;
  const status = useWatch({ control, name: 'status' });

  const onRemove = useCallback(
    (id: string) => {
      const values = status?.filter((v) => v !== id);
      setValue('status', values);
    },
    [status, setValue]
  );

  const { t } = useTranslation();

  const active = t('QuotaStatus.Active');
  const lost = t('QuotaStatus.Lost');
  const archived = t('QuotaStatus.Archived');

  const statusChips = status?.map((value) => {
    const label =
      value === QuotaStatus.Active
        ? active
        : value === QuotaStatus.Lost
        ? lost
        : value === QuotaStatus.Archived
        ? archived
        : '';
    return <Chip label={label} id={value} key={`managerId-${value}`} onRemove={onRemove} />;
  });

  return <>{statusChips}</>;
};
