import { makeStyles, Theme, createStyles, Paper, Portal, MenuItem } from '@material-ui/core';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { RatingFinancialLabels } from './RatingFinancialLabels';
import { RatingFinancialFields } from './RatingFinancialFields';
import { RatingBusinessLabels } from './RatingBusinessLabels';
import { RatingBusinessFields } from './RatingBusinessFields';
import { RatingOptionalLabels } from './RatingOptionalLabels';
import { RatingOptionalFields } from './RatingOptionalFields';
import { useForm, useWatch } from 'react-hook-form';
import { getRatingInput, RatingInputValues } from './types';
import {
  useAddRatingMutation,
  useCalculateRatingMutation,
} from '../../../services/api/useRatingsBackend';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useToast } from '../../Toast';
import { Button } from '../../Button';
import { RatingCalculationViewModel, RatingInput, RatingViewModel } from 'schema/serverTypes';
import { RatingOutput } from './RatingOutput';
import { Select } from '../../form';
import { useHistory } from 'react-router';
import { RatingFinancialParams } from './RatingFinancialParams';
import { RatingBusinessParams } from './RatingBusinessParams';
import dayjs from 'dayjs';
import { ModalForm, useModalForm } from '../../Modal';
import { RatingYearsModal } from './RatingYearsModal';
import { Grid } from '../../Grid';
import clsx from 'clsx';
import { IconArrowCopy } from '../../icons';
import { Role } from 'components/authentication';
import { ApplicationRoles, useCounterpartyRatingAnswersQuery } from 'services';
import { useCounterpartyProperties } from './useCounterpartyProperties';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: theme.spacing(1.5, 2),
      display: 'grid',
      gridTemplateColumns: '2fr 3fr',
      gridTemplateRows: 'auto 1fr',
      gridGap: 20,
      height: '100%',
      position: 'relative',
      marginBottom: (result: any) => (result ? '16px' : '75px'),
    },
    period: {
      display: 'flex',
      marginBottom: theme.spacing(2.5),
    },
    quarter: {
      width: 150,
      marginRight: theme.spacing(2),
    },
    year: {
      width: 100,
      marginRight: theme.spacing(2),
    },
    konturYearData: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.primary.main,
      marginLeft: theme.spacing(2),
    },
    financial: {
      gridRow: '1/3',
      '& > div': {
        display: 'flex',
        justifyContent: 'space-between',
        flex: '0 0 auto',
        boxShadow: 'none',
      },
    },
    business: {
      gridColumn: 2,
      '& > div': {
        display: 'flex',
        justifyContent: 'space-between',
        flex: '0 0 auto',
        boxShadow: 'none',
      },
    },
    optional: {
      gridColumn: 2,
      '& > div': {
        display: 'flex',
        justifyContent: 'space-between',
        flex: '0 0 auto',
        boxShadow: 'none',
      },
    },
    output: {
      marginBottom: theme.spacing(2),
    },
    outputFinancial: {
      marginBottom: theme.spacing(2),
    },
    outputBusiness: {
      marginBottom: 75,
    },
    header: {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.common.black,
      fontWeight: 600,
      fontSize: 12,
      padding: '10px 12px',
      marginBottom: 7,
    },
    headerItem: {
      position: 'relative',
      alignItems: 'center',
      display: 'flex',
      //width: 150,
    },
    right: {
      textAlign: 'right',
      justifyContent: 'flex-end',
    },
    actions: {
      [theme.breakpoints.down(1200)]: {
        width: 'calc(100% - 85px)',
        left: 63,
      },
      display: 'flex',
      justifyContent: 'flex-start',
      width: 'calc(100% - 238px)',
      padding: theme.spacing('20px', '20px'),
      bottom: 0,
      left: 216,
      position: 'fixed',
      zIndex: 20,
    },
    actionButton: {
      marginRight: theme.spacing(2.5),
    },
    copyButton: {
      backgroundColor: theme.palette.background.light,
      border: '1px solid' + theme.palette.text.secondary,
      display: 'inline-block',
      marginLeft: 16,
    },
    copyContainer: {},
  })
);

const staticDefaultValues: Omit<RatingInputValues, 'bussinessAnswers'> = {
  totalAssets: 0,
  netIntangibleAssets: 0,
  intragroupLoansOlderOneYear: 0,
  intragroupLoansNewerOneYear: 0,
  intragroupDZ: 0,
  accountCashierAndMoney: 0,
  totalCashAndInvestments: 0,
  capitalAndReserves: 0,
  longTermDuties: 0,
  otherLongTermDuties: 0,
  shortTermBorrowedFunds: 0,
  otherShortTermBorrowedFunds: 0,
  partOfSubordinatedDebtPayableIn12Months: 0,
  cashInParticipantsAccounts: 0,
  leasingObligations: 0,
  operationalLeasing: 0,
  revenue: 0,
  salesProfit: 0,
  operatingResultOfActivitiesToBeDiscontinued: 0,
  profitBeforeTax: 0,
  amortization: 0,
  expensesOnOffBalance: 0,
  question6: [],
  fromDate: '',
  sustainableEBITDA: 0,
  clearIncome: 0,
};

type RatingAddFormProps = {
  inn: string;
  defaultValues: Omit<RatingInputValues, 'bussinessAnswers'>;
};

const RatingAddForm = (props: RatingAddFormProps) => {
  const [result, setResult] = useState<RatingCalculationViewModel>();
  const classes = useStyles(result);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toast = useToast();
  const { onOpen, onClose, open } = useModalForm();
  const [editId, setEditId] = useState<number | null>(null);
  const resultRef = useRef<HTMLDivElement | null>(null);
  const [konturValues, setKonturValues] = useState<RatingInput | null>(null);
  const [konturYear, setKonturYear] = useState<number | null>(null);
  const { isLoading, isFinanced } = useCounterpartyProperties();

  const { inn, defaultValues } = props;
  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { isSubmitting },
    setValue,
  } = useForm<RatingInputValues & { quarter: string; year: string }>({
    mode: 'onBlur',
    defaultValues,
  });

  const { push } = useHistory();

  const { mutateAsync } = useAddRatingMutation(inn, {
    onSuccess: (result: RatingViewModel) => {
      toast(t('RatingSuccessMessage'), 'success');
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            typeof query.queryKey === 'string' && (query.queryKey as string).indexOf('ratings') >= 0
          );
        },
      });
      push(`/counterparties/${result.inn}/rating/${result.id}`);
    },
    onError: (error) => {
      const errors = error.errors;
      Object.keys(errors)
        .map((key) => errors[key])[0]
        .map((error) => toast(error ?? t('ErrorMessage'), 'error'));
    },
  });

  const onSubmit = useMemo(() => {
    const submit = async (values: RatingInputValues & { quarter: string; year: string }) => {
      if (typeof isFinanced === 'boolean' && !isFinanced) {
        toast('Запрет финансирования по номеру ОКВЭД. Расчет рейтинга не производится.', 'error');
        return null;
      }
      const input = getRatingInput(values);
      return await mutateAsync(input);
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync, isFinanced, toast]);

  const { mutateAsync: mutateAsyncCalculate } = useCalculateRatingMutation(inn, {
    onSuccess: (result: RatingCalculationViewModel) => {
      toast(t('RatingSuccessMessage'), 'success');
      setResult(result);
      resultRef.current?.scrollIntoView({ behavior: 'smooth' });
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const onCalculateSubmit = useMemo(() => {
    const submit = async (values: RatingInputValues & { quarter: string; year: string }) => {
      if (typeof isFinanced === 'boolean' && !isFinanced) {
        toast('Запрет финансирования по номеру ОКВЭД. Расчет рейтинга не производится.', 'error');
        return null;
      }
      const input = getRatingInput(values);
      return await mutateAsyncCalculate(input);
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsyncCalculate, isFinanced, toast]);
  const year = useWatch({ control, name: 'year' });

  useEffect(() => setValue('fromDate', `10.01.${year}`), [year, setValue]);

  // from 2012 to current year + 2
  const yearsCount = dayjs().year() - 2012 + 3;
  const yearOptions = Array(yearsCount)
    .fill(0)
    .map((e, i) => {
      return (
        <MenuItem key={i} value={2012 + i}>
          {2012 + i}
        </MenuItem>
      );
    });

  const getRating = useCallback(() => {
    onOpen();
  }, [onOpen]);

  const ratingCopy = useCallback(() => {
    if (konturValues) {
      reset({ ...getValues(), ...konturValues });
    }
  }, [konturValues, reset, getValues]);

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className={classes.period}>
          <div className={classes.year}>
            <Select
              label={t('Year')}
              name="year"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: '',
                },
              }}
            >
              <MenuItem value="">{t('Not set')}</MenuItem>
              {yearOptions}
            </Select>
          </div>
          <Button variant="contained" onClick={getRating}>
            Получить данные
          </Button>
          {konturYear && (
            <div className={classes.konturYearData}>{`Получены данные за ${konturYear} год`}</div>
          )}
        </div>
        <Paper square className={classes.root}>
          <div className={classes.financial}>
            <header className={classes.header}>
              <Grid container>
                <Grid item xs={12} className={classes.headerItem}>
                  Финансовые показатели (руб.)
                </Grid>
                <Grid
                  item
                  xs={6}
                  className={clsx(classes.right, classes.headerItem, classes.copyContainer)}
                >
                  {konturYear && (
                    <div onClick={ratingCopy}>
                      <span>Контур</span>
                      <Button
                        variant="iconButton"
                        endIcon={<IconArrowCopy />}
                        className={classes.copyButton}
                      />
                    </div>
                  )}
                </Grid>
                <Grid item xs={6} className={clsx(classes.right, classes.headerItem)}>
                  {year}
                </Grid>
              </Grid>
            </header>
            <div>
              <RatingFinancialLabels />
              <RatingFinancialFields
                inn={inn}
                editId={editId}
                setEdit={setEditId}
                control={control}
                setValue={setValue}
                konturValues={konturValues}
              />
            </div>
          </div>
          <div className={classes.business}>
            <header className={classes.header}>Бизнес-параметры</header>
            <div>
              <RatingBusinessLabels />
              <RatingBusinessFields
                inn={inn}
                editId={editId}
                setEdit={setEditId}
                control={control}
                setValue={setValue}
              />
            </div>
          </div>
          <div className={classes.optional}>
            <header className={classes.header}>Опционально</header>
            <div>
              <RatingOptionalLabels />
              <RatingOptionalFields
                inn={inn}
                editId={editId}
                setEdit={setEditId}
                control={control}
              />
            </div>
          </div>
        </Paper>
        {result?.output && (
          <Paper square className={classes.output} ref={resultRef}>
            <RatingOutput output={result?.output} />
          </Paper>
        )}
        {result?.input && result?.calculationContext && (
          <Paper square className={classes.outputFinancial}>
            <RatingFinancialParams input={result?.input} context={result?.calculationContext} />
          </Paper>
        )}
        {result?.input && result?.calculationContext && (
          <Paper square className={classes.outputBusiness}>
            <RatingBusinessParams input={result?.input} context={result?.calculationContext} />
          </Paper>
        )}
        <Portal container={document.body}>
          <Paper square className={classes.actions}>
            <Role role={ApplicationRoles.allExceptAssetManager}>
              <div className={classes.actionButton}>
                <Button
                  color="primary"
                  size="medium"
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting || isLoading}
                  onClick={onCalculateSubmit}
                >
                  {t('Calculate')}
                </Button>
              </div>
              <div className={classes.actionButton}>
                <Button
                  color="primary"
                  size="medium"
                  type="button"
                  variant="outlined"
                  disabled={isSubmitting || isLoading}
                  onClick={onSubmit}
                >
                  {t('Save')}
                </Button>
              </div>
            </Role>
          </Paper>
        </Portal>
      </form>
      <ModalForm open={open} onClose={onClose} width={500}>
        <RatingYearsModal
          inn={inn}
          setKonturValues={setKonturValues}
          setKonturYear={setKonturYear}
          onClose={onClose}
        />
      </ModalForm>
    </>
  );
};

export const RatingAdd = () => {
  const {
    params: { inn },
  } = useRouteMatch<{ inn: string }>();

  const { data, isLoading } = useCounterpartyRatingAnswersQuery(inn);

  if (isLoading) {
    return null;
  }

  let defaultValues = {
    ...staticDefaultValues,
    question1: data?.answer1,
    question2: data?.answer2,
    question3: data?.answer3,
    question4: data?.answer4,
    question5: data?.answer5,
    question6: data?.answer6 !== undefined ? [data?.answer6] : [],
  };

  return <RatingAddForm inn={inn} defaultValues={defaultValues} />;
};
