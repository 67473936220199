import { useCallback } from 'react';
import { useWatch, Control, UseFormSetValue } from 'react-hook-form';
import { IssueChip } from './IssueChip';
import { SearchFilterFormValues } from './types';
import { IssueStatus, IssueType } from 'schema/serverTypes';
import dayjs from 'dayjs';
import { useLanguage } from '../utils/useLanguage';
import { useIssueTypeString, useStatusSelectString } from './useStatusString';

export type IssueChipsProps = {
  control: Control<SearchFilterFormValues, object>;
  setValue: UseFormSetValue<SearchFilterFormValues>;
};

type IssueTypeChipProps = {
  issueType: IssueType;
  onTypeRemove: (id: string) => void;
};

const IssueTypeChip = (props: IssueTypeChipProps) => {
  const { issueType, onTypeRemove } = props;
  const label = useIssueTypeString(issueType);

  return <IssueChip label={label} id={issueType} onRemove={onTypeRemove} />;
};

type IssueStatusChipProps = {
  status: IssueStatus;
  onRemove: (label: string) => void;
};

const IssueStatusChip = (props: IssueStatusChipProps) => {
  const { status, onRemove } = props;
  const label = useStatusSelectString(status);

  return <IssueChip label={label} id={status} onRemove={onRemove} />;
};

export const IssueChips = (props: IssueChipsProps) => {
  const { control, setValue } = props;

  const assignee = useWatch({ control, name: 'assignee' });
  const onAssigneeRemove = useCallback(
    (id: string) => {
      const assignees = assignee?.filter((v) => v.id !== id);
      setValue('assignee', assignees);
    },
    [assignee, setValue]
  );
  const assigneeChips = assignee?.map((value) => {
    return (
      <IssueChip
        label={value.name}
        id={value.id}
        key={`assignee-${value.id}`}
        onRemove={onAssigneeRemove}
      />
    );
  });

  const author = useWatch({ control, name: 'author' });
  const onAuthorRemove = useCallback(
    (id: string) => {
      const authors = author?.filter((v) => v.id !== id);
      setValue('author', authors);
    },
    [author, setValue]
  );
  const authorChips = author?.map((value) => {
    return (
      <IssueChip
        label={value.name}
        id={value.id}
        key={`author-${value.id}`}
        onRemove={onAuthorRemove}
      />
    );
  });

  const type = useWatch({ control, name: 'type' });
  const onTypeRemove = useCallback(
    (id: string) => {
      const types = type?.filter((v) => v !== id);
      setValue('type', types);
    },
    [type, setValue]
  );

  const typeChips = type?.map((value) => {
    return <IssueTypeChip key={`type-${value}`} issueType={value} onTypeRemove={onTypeRemove} />;
  });

  const status = useWatch({ control, name: 'status' });
  const onStatusRemove = useCallback(
    (id: string) => {
      const statuses = status?.filter((v) => v !== id);
      setValue('status', statuses);
    },
    [status, setValue]
  );
  const statusChips = status?.map((value) => {
    return <IssueStatusChip status={value} key={`status-${value}`} onRemove={onStatusRemove} />;
  });

  const lang = useLanguage();
  const fromDate = useWatch({ control, name: 'fromDate' });
  const toDate = useWatch({ control, name: 'toDate' });
  const dateLabel = `${dayjs(fromDate).locale(lang).format('DD MMM YYYY')} - ${dayjs(toDate)
    .locale(lang)
    .format('DD MMM YYYY')}`;
  const onDateRemove = useCallback(() => {
    setValue('fromDate', undefined);
    setValue('toDate', undefined);
  }, [setValue]);

  const dateChips = fromDate && toDate && (
    <IssueChip
      label={dateLabel}
      id={`${fromDate} - ${toDate}`}
      key={`date-${fromDate}`}
      onRemove={onDateRemove}
    />
  );

  return (
    <>
      {assigneeChips}
      {typeChips}
      {authorChips}
      {statusChips}
      {dateChips}
    </>
  );
};
