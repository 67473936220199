import { useCallback, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      flexWrap: 'wrap',
      border: '1px solid #D1D7E4',
      background: 'white',
    },
    cell: {
      fontSize: 12,
      textAlign: 'center',
      color: theme.palette.common.black,
      width: 30,
      border: '1px solid #E9F1FE',
      padding: 5,
      cursor: 'pointer',
      userSelect: 'none',
      '&$selected': {
        position: 'relative',
        background: theme.palette.lightBlue.main,
        '&:after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          border: '1px solid white',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
      '&:hover': {
        background: theme.palette.primary.main,
        color: 'white',
      },
    },
    selected: {},
  })
);

type NumbersProps = {
  count: number;
};

export const Numbers = ({ count }: NumbersProps) => {
  const classes = useStyles();
  let div = [];
  const [selecting, setSelecting] = useState(false);
  const [values, setValues] = useState<number[]>([]);

  const beginSelection = (i: number) => {
    setSelecting(true);
    updateSelection(i, true);
  };

  const endSelection = () => {
    setSelecting(false);
  };

  const updateSelection = useCallback(
    (i: number | null, isClick?: boolean) => {
      if (i !== null) {
        if ((isClick || selecting) && values.indexOf(i) < 0) {
          setValues((values) => [...values, i]);
        } else if (isClick && values.indexOf(i) >= 0) {
          setValues((values) => [
            ...values.slice(0, values.indexOf(i)),
            ...values.slice(values.indexOf(i) + 1),
          ]);
        }
      }
    },
    [selecting, values]
  );

  for (let i = 1; i < count + 1; i++) {
    let a = (
      <span
        key={i}
        className={values.indexOf(i) >= 0 ? clsx(classes.cell, classes.selected) : classes.cell}
        onMouseDown={() => beginSelection(i)}
        onMouseUp={() => endSelection()}
        onMouseOver={() => updateSelection(i)}
      >
        {i}
      </span>
    );
    div.push(a);
  }

  return (
    <div className={classes.root} onMouseLeave={() => endSelection()}>
      {div}
    </div>
  );
};
