import { IconSprite } from 'components';
import { TagSearchForm } from './TagSearchForm';
import { useTagSearchForm } from './useTagSearchForm';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { themeOrange as theme } from 'theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    list: {
      position: 'absolute',
      backgroundColor: 'white',
      top: -20,
      right: -20,
      left: 0,
      width: `calc(100% + ${theme.spacing(2.5)}px)`,
      boxShadow: '-3px 3px 10px rgba(56, 60, 97, 0.1)',
    },
    tagsContainer: {
      padding: theme.spacing(0, 2.5),
    },
    tagListContainer: {
      maxHeight: 'calc(100vh - 180px)',
    },
    tagListTable: {
      borderCollapse: 'collapse',
      backgroundColor: 'white',
    },
    tagListTableHead: {},
    tagListTableRowHead: {},
    tagListTableCellHead: {
      backgroundColor: 'white',
    },
    tagSubheader: {
      color: theme.palette.secondary.main,
      fontSize: 12,
      fontWeight: 700,
      paddingBottom: 0,
    },
    tagSubheaderRow: {
      ' &&:hover': {
        backgroundColor: 'transparent',
      },
    },
    tagName: {
      wordWrap: 'break-word',
      width: 220,
      maxWidth: 220,
      [theme.breakpoints.up('lg')]: {
        width: 300,
        maxWidth: 300,
      },
      [theme.breakpoints.up('xl')]: {
        width: 380,
        maxWidth: 380,
      },
    },
    copy: {
      cursor: 'pointer',
    },
    hr: {},
  })
);

export const TagList = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { tags, control, onReset } = useTagSearchForm();

  return (
    <div className={classes.root}>
      <div className={classes.list}>
        <TagSearchForm control={control} onReset={onReset} />
        <TableContainer className={classes.tagListContainer}>
          <Table stickyHeader aria-label="sticky table" className={classes.tagListTable}>
            <TableHead className={classes.tagListTableHead}>
              <TableRow className={classes.tagListTableRowHead}>
                <TableCell size="medium" className={classes.tagListTableCellHead}>
                  {t('Name in the system')}
                </TableCell>
                <TableCell size="medium" className={classes.tagListTableCellHead}>
                  {t('Hashtags')}
                </TableCell>
                <TableCell size="medium" className={classes.tagListTableCellHead} />
              </TableRow>
            </TableHead>
            <TableBody>
              {tags.map((tag) => {
                return <TableItemsHeader key={tag.name} {...tag} />;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

const TableItemsHeader = (props: { name: string; tags: object }) => {
  const classes = useStyles();
  const { name, tags } = props;

  const entries = Object.entries(tags);

  return (
    <>
      <TableRow className={classes.tagSubheaderRow}>
        <TableCell colSpan={3} size="medium" className={classes.tagSubheader}>
          {name}
        </TableCell>
      </TableRow>
      {entries.map((entry) => {
        const [name] = entry;
        return <TableItem key={name} tag={entry} />;
      })}
    </>
  );
};

const TableItem = (props: { tag: [name: string, description: string] }) => {
  const classes = useStyles();
  const { tag } = props;
  const [name, description] = tag;

  const copyTextToClipboard = async () => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(name);
    } else {
      return document.execCommand('copy', true, name);
    }
  };

  return (
    <TableRow onClick={copyTextToClipboard} className={classes.copy}>
      <TableCell size="medium">{description}</TableCell>
      <TableCell size="medium" className={classes.tagName}>
        {name}
      </TableCell>
      <TableCell size="medium">
        <IconSprite
          width="12px"
          height="15px"
          color={theme.palette.text.primary}
          hoverColor={theme.palette.primary.main}
          icon="copy"
        />
      </TableCell>
    </TableRow>
  );
};
