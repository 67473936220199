import { Grid, Box } from '@material-ui/core';
import { IssuesFilterForm } from '../IssuesFilterForm';
import { useIssuesFilter } from './useIssuesData';
import { useState } from 'react';
import { IssueListFlat } from './IssueListFlat';
import { IssueListGrouped } from './IssueListGrouped';
import { PageTitle } from 'components/utils/pageTitle';
import { useTranslation } from 'react-i18next';

export const IssueList = () => {
  const filter = useIssuesFilter(false);
  const { control } = filter;
  const [isGrouped, setGrouped] = useState<boolean>(true);

  const { t } = useTranslation();
  const pageTitle = t('Section.issues') + ' - Bumblebee';
  PageTitle(pageTitle);

  return (
    <>
      <Box mb={2.5}>
        <Box>
          <IssuesFilterForm {...filter} isGrouped={isGrouped} setGrouped={setGrouped} />
        </Box>
      </Box>
      <Grid container direction="column">
        <Grid container item direction="column">
          {!isGrouped ? (
            <IssueListFlat control={control} />
          ) : (
            <IssueListGrouped control={control} />
          )}
        </Grid>
      </Grid>
    </>
  );
};
