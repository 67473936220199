import { useCallback } from 'react';
import { useWatch } from 'react-hook-form';
import { Chip } from 'components';
import { QuotaChipsProps } from './types';

export const LeasingProductChips = (props: QuotaChipsProps) => {
  const { control, setValue } = props;
  const name = 'leasingProduct';
  const leasingProducts = useWatch({ control, name });

  const onRemove = useCallback(
    (value: string) => {
      const values = leasingProducts?.filter((v) => v !== value);
      setValue(name, values);
    },
    [leasingProducts, setValue, name]
  );

  const leasingProductsChips = leasingProducts?.map((value) => {
    return <Chip label={value} id={value} key={`leasingProduct-${value}`} onRemove={onRemove} />;
  });

  return <>{leasingProductsChips}</>;
};
