import { Grid } from '@material-ui/core';
import { Paging, usePaging } from '../paging';
import { RequestsTable } from './RequestsTable';
import { RequestsFilterForm } from './RequestsFilterForm';
import { useExternalQuotasQuery } from '../../services';
import { ExternalQuotaFilter, ExternalQuotaSortBy, SortOrder } from '../../schema';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useSorting } from '../sorting';

type RequestsDataGridProps = {};

export const RequestsDataGrid = (props: RequestsDataGridProps) => {
  const { page, pageSize, onPageChanged, onPageSizeChanged, onReset } = usePaging();
  const { control, reset, setValue, watch } = useForm<ExternalQuotaFilter>({
    mode: 'all',
  });

  const { sortBy, order, setOrder, setSortBy } = useSorting<ExternalQuotaSortBy>({
    sortBy: ExternalQuotaSortBy.CreatedDate,
    order: SortOrder.desc,
  });

  const sorting = useMemo(() => {
    return {
      sortBy,
      order,
      setSortBy,
      setOrder,
    };
  }, [sortBy, order, setSortBy, setOrder]);

  const region = watch('region');
  const status = watch('status');
  const tabId = watch('tabId');
  const userId = watch('userId');
  const { data, isLoading } = useExternalQuotasQuery({
    filter: {
      region,
      tabId,
      status,
      userId,
    },
    sorting: {
      sortBy,
      order,
    },
    paging: {
      page,
      pageSize,
    },
  });

  const rows = data?.data ?? [];
  const paging = useMemo(() => {
    return {
      pageCount: data?.pageCount ?? 0,
      totalCount: data?.totalCount ?? 0,
      dataCount: rows.length,
      page,
      pageSize,
      onPageChanged,
      onPageSizeChanged,
      onReset,
    };
  }, [data, page, pageSize, onPageChanged, onPageSizeChanged, onReset, rows.length]);

  const onResetFilter = useCallback(() => {
    onReset();
    reset({});
  }, [reset, onReset]);

  return (
    <>
      <RequestsFilterForm control={control} onReset={onResetFilter} setValue={setValue} />
      <Grid container item direction="column">
        <Grid item>
          <RequestsTable rows={rows} isLoading={isLoading} sorting={sorting} />
        </Grid>
        <Grid item>
          <Paging {...paging} />
        </Grid>
      </Grid>
    </>
  );
};
