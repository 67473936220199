import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableCell, TableHead, TableRow, TableBody } from '@material-ui/core';
import { IndustryLeasingProductViewModel } from 'schema/serverTypes';
import { EditButton } from '../EditButton';
import { useTableStyles } from '../useTableStyles';
import { useIndustryLeasingProductsData } from './useIndustryLeasingProductsData';

export type IndustryLeasingProductsTableProps = Omit<
  ReturnType<typeof useIndustryLeasingProductsData>,
  'filter' | 'paging'
> & {
  onOpen: (item: IndustryLeasingProductViewModel) => void;
};

type TableItemProps = {
  item: IndustryLeasingProductViewModel;
  onOpen: (item: IndustryLeasingProductViewModel) => void;
};

const TableItem = (props: TableItemProps) => {
  const classes = useTableStyles();
  const { item, onOpen } = props;

  const handleOpen = useCallback(() => {
    onOpen(item);
  }, [item, onOpen]);

  return (
    <TableRow>
      <TableCell size="medium">{item.name}</TableCell>
      <TableCell size="medium" className={classes.actions}>
        <EditButton onClick={handleOpen} />
      </TableCell>
    </TableRow>
  );
};

export const IndustryLeasingProductsTable = (props: IndustryLeasingProductsTableProps) => {
  const classes = useTableStyles();
  const { rows, onOpen } = props;
  const { t } = useTranslation();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell size="medium">{t('Name')}</TableCell>
          <TableCell className={classes.change} size="medium"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((item: IndustryLeasingProductViewModel, index: number) => (
          <TableItem key={index} item={item} onOpen={onOpen} />
        ))}
      </TableBody>
    </Table>
  );
};
