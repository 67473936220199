import { Route, Switch } from 'react-router-dom';
import {
  NomenclaturesDataGrid,
  LeasingProductsDataGrid,
  WikiTagsDataGrid,
  PrivateRoute,
  PDCDataGrid,
  IndustriesDataGrid,
  IndustrySpecializationDataGrid,
  IndustryLeasingProductsDataGrid,
  IndustrySpecializationEditFormPage,
  IndustryEditFormPage,
  IndustryAddForm,
  IndustrySpecializationAddForm,
  QualificationsDataGrid,
  TelematicsDataGrid,
} from 'components';
import { DictionariesPage } from './DictionariesPage';
import { ApplicationRole } from 'schema';
import { RegionTiersDataGrid } from 'components/dictionaries/RegionTiers';
import { QuotaLostReasonsDataGrid } from 'components/dictionaries/QuotaLostReasons';
import { IssueControlsDataGrid } from 'components/dictionaries/IssueControls';
import { LeasingProductForm } from 'components/dictionaries/LeasingProducts';
import { SalesPointsDataGrid } from 'components/dictionaries/SalesPoints';

import {
  CountryRiskAddForm,
  CountryRiskEditFormPage,
  CountryRisksDataGrid,
} from 'components/dictionaries/CountryRisksDataGrid';

export const RoutesPage = () => {
  return (
    <Switch>
      <Route path="/dictionaries" exact component={DictionariesPage} />
      <Route path="/dictionaries/industries" exact component={IndustriesDataGrid} />
      <PrivateRoute path={`/dictionaries/industries/add`} exact component={IndustryAddForm} />
      <PrivateRoute
        path={`/dictionaries/industries/:id(\\d+)`}
        exact
        component={IndustryEditFormPage}
      />
      <Route path="/dictionaries/specialization" exact component={IndustrySpecializationDataGrid} />
      <PrivateRoute
        path={`/dictionaries/specialization/add`}
        exact
        component={IndustrySpecializationAddForm}
        role={[ApplicationRole.Admin]}
      />
      <PrivateRoute
        path={`/dictionaries/specialization/:id(\\d+)`}
        exact
        component={IndustrySpecializationEditFormPage}
        role={[ApplicationRole.Admin, ApplicationRole.RiskManager]}
      />
      <Route path="/dictionaries/salesPoints" exact component={SalesPointsDataGrid} />
      <Route path="/dictionaries/leasingProducts" exact component={LeasingProductsDataGrid} />
      <PrivateRoute path={`/dictionaries/leasingProducts/add`} component={LeasingProductForm} />
      <PrivateRoute
        path={`/dictionaries/leasingProducts/:id(\\d+)`}
        component={LeasingProductForm}
      />
      <PrivateRoute
        path="/dictionaries/wikiTags"
        exact
        component={WikiTagsDataGrid}
        role={[ApplicationRole.Admin]}
      />
      <Route path="/dictionaries/nomenclatures" exact component={NomenclaturesDataGrid} />
      <Route path="/dictionaries/regionTiers" exact component={RegionTiersDataGrid} />
      <PrivateRoute
        path="/dictionaries/quotaLostReasons"
        exact
        component={QuotaLostReasonsDataGrid}
        role={[ApplicationRole.Admin]}
      />
      <PrivateRoute
        path="/dictionaries/issueControls"
        exact
        component={IssueControlsDataGrid}
        role={[ApplicationRole.Admin]}
      />
      <Route path="/dictionaries/pdc" exact component={PDCDataGrid} />
      <PrivateRoute
        path="/dictionaries/industryLeasingProducts"
        exact
        component={IndustryLeasingProductsDataGrid}
        role={[ApplicationRole.Admin]}
      />
      <PrivateRoute
        path="/dictionaries/countryRisks"
        exact
        component={CountryRisksDataGrid}
        role={[ApplicationRole.Admin]}
      />
      <PrivateRoute
        path="/dictionaries/countryRisks/add"
        exact
        component={CountryRiskAddForm}
        role={[ApplicationRole.Admin, ApplicationRole.RiskManager]}
      />
      <PrivateRoute
        path={`/dictionaries/countryRisks/:id(\\d+)`}
        exact
        component={CountryRiskEditFormPage}
        role={[ApplicationRole.Admin, ApplicationRole.RiskManager]}
      />
      <PrivateRoute
        path={`/dictionaries/qualifications`}
        exact
        component={QualificationsDataGrid}
        role={[ApplicationRole.Admin, ApplicationRole.RiskManager]}
      />
      <Route path={`/dictionaries/telematics`} exact component={TelematicsDataGrid} />
    </Switch>
  );
};
