import { useCalculationFormContext } from '../types';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteChangeDetails,
} from '@material-ui/lab/useAutocomplete';
import { Autocomplete } from 'components';
import { FieldError, useController } from 'react-hook-form';
import { IndustryLeasingProductViewModel } from 'schema/serverTypes';
import { useIndustryLeasingProductPagedListQuery } from 'services/api/useIndustryLeasingProductBackend';
import { useRequiredOnSave } from '../useRequiredOnSave';

const getOptionLabel = (option: IndustryLeasingProductViewModel | null) => {
  if (option === null) {
    return '';
  }
  return option.name;
};

const getOptionSelected = (
  option: IndustryLeasingProductViewModel | null,
  value: IndustryLeasingProductViewModel | null
) => {
  if (option === null || value === null) {
    return false;
  }
  return option.id === value.id;
};

export const IndustryLeaseProductAutocomplete = () => {
  const { t } = useTranslation();

  const { control } = useCalculationFormContext();
  const rules = useRequiredOnSave({ name: 'industryLeasingProductId' });

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    control,
    name: 'industryLeasingProductId',
    rules,
  });

  const helperText = error !== undefined ? (error as FieldError).message : undefined;

  const [input, setInput] = useState('');

  const { data } = useIndustryLeasingProductPagedListQuery({ page: 1, pageSize: 10000 });
  const options = data?.data ?? [];

  const handleOnInputChange = useCallback(
    (_ev: React.ChangeEvent<{}>, value: string, _reason: AutocompleteInputChangeReason) => {
      setInput(value);
    },
    [setInput]
  );

  const handleOnChange = useCallback(
    (
      _e: React.ChangeEvent<{}>,
      value: IndustryLeasingProductViewModel | null,
      _reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<IndustryLeasingProductViewModel | null>
    ) => {
      if (value === null) {
        onChange(null);
      } else {
        onChange(value.id);
      }
    },
    [onChange]
  );

  const selectedOption = options.find((t) => t.id === value) ?? null;

  return (
    <Autocomplete<IndustryLeasingProductViewModel | null>
      label={`${t('Industry leasing product')}`}
      options={options}
      inputValue={input}
      onInputChange={handleOnInputChange}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      onChange={handleOnChange}
      variant="outlined"
      value={selectedOption}
      error={error !== undefined}
      helperText={helperText}
    />
  );
};
