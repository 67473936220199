import { useParams } from 'react-router-dom';
import { useIndustryByIdQuery } from 'services/api/useIndustryBackend';
import { IndustryEditForm } from './IndustryEditForm';

type FormProps = {
  id: number;
};

const Form = (props: FormProps) => {
  const { data, isLoading } = useIndustryByIdQuery(props.id);

  if (isLoading || data === undefined) {
    return null;
  }

  return <IndustryEditForm industry={data} />;
};

export const IndustryEditFormPage = () => {
  const { id } = useParams<{ id: string }>();

  const specId = parseInt(id, 10);

  if (Number.isNaN(specId)) {
    return null;
  }

  return <Form id={specId} />;
};
