import { createStyles, makeStyles, TableCell, TableRow, Theme } from '@material-ui/core';
import { MissingOverdueTop } from 'schema/serverTypes';
import { formatMoney } from 'components/utils';
import { Link } from 'react-router-dom';
import { IconSprite } from '../../icons';
import { themeOrange as theme } from 'theme';
import { User } from '../../users';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      '&& td': {
        padding: theme.spacing(1),
        textAlign: 'left',
        fontSize: 12,
        background: theme.palette.background.default,
        borderRadius: 4,
      },
      '&& a': {
        color: theme.palette.text.primary,
      },
      '&:first-child td': {
        color: theme.palette.primary.main,
        background: theme.palette.primary.light,
      },
      '&:first-child td a': {
        color: theme.palette.primary.main,
      },
    },
    name: {
      fontSize: 10,
      marginRight: theme.spacing(2),
    },
    row: {
      display: 'grid',
      gridTemplateColumns: '3fr 2fr 110px 20px',
    },
    amount: {
      fontWeight: 700,
      textAlign: 'right',
    },
    chat: {
      textAlign: 'right',
    },
  })
);

type OverdueItemProps =
  | (MissingOverdueTop & {
      debtDepth: number;
      overdueAmount?: never;
      currency?: never;
      variant: 'debtDepth';
    })
  | (MissingOverdueTop & {
      overdueAmount: number;
      currency: string;
      debtDepth?: never;
      variant: 'overdueAmount';
    });

export const OverdueItem = (props: OverdueItemProps) => {
  const classes = useStyles(props);
  const {
    lesseeName,
    lesseeInn,
    contractNumber,
    variant,
    debtDepth = 0,
    overdueAmount = 0,
    currency,
    managerName,
    isActive,
  } = props;

  const formattedValue =
    variant === 'debtDepth'
      ? debtDepth.toString()
      : formatMoney({ amount: overdueAmount, currency });

  return (
    <TableRow className={classes.root}>
      <TableCell>
        <div className={classes.row}>
          <div>
            <Link to={`counterparties/${lesseeInn}`} rel="noopener noreferrer" target="_blank">
              {lesseeName}
            </Link>
          </div>
          <div>
            <User name={managerName} isActive={isActive} />
          </div>
          <div className={classes.amount}>{formattedValue}</div>
          <div className={classes.chat}>
            <Link
              to={`/comments/overdue?dealerName=${lesseeName}&contractNumber=${contractNumber}`}
            >
              <IconSprite icon="chat" width="10px" color={theme.palette.secondary.dark} />
            </Link>
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
};
