import { Control, useWatch } from 'react-hook-form';
import { CalculatorFormValues } from './types';
import { useNomenclatureListSearchQuery } from '../../../services/api/useNomenclaturesBackend';
import { useLeasingProductByBrandQuery } from '../../../services/api/useLeasingProductBrandsBackend';

export const useLeasingProductByNomenclature = (control: Control<CalculatorFormValues, any>) => {
  const nomenclatures = useWatch({ control, name: 'nomenclatures' });

  const search = nomenclatures.length > 0 ? nomenclatures[0].code : '';

  const { data } = useNomenclatureListSearchQuery({
    search,
  });

  const vendor =
    nomenclatures.length === 1 && nomenclatures[0].code ? data?.data[0].vendor ?? '' : '';
  const { data: leasingProductByNomenclature } = useLeasingProductByBrandQuery({ brand: vendor });

  return {
    leasingProductByNomenclature,
    vendor,
  };
};
