import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { Input, Button } from 'components';
import { Grid } from 'components/Grid';
import { useTranslation } from 'react-i18next';
import { Control } from 'react-hook-form';
import { NomenclatureCategoryScheduleFilter } from 'schema';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonReset: {
      display: 'flex',
      alignItems: 'flex-end',
      marginBottom: theme.spacing(0.2),
    },
  })
);

type FilterFormProps = {
  control: Control<NomenclatureCategoryScheduleFilter, object>;
  handleOnReset: () => void;
};

export const FilterForm = (props: FilterFormProps) => {
  const classes = useStyles();
  const { control, handleOnReset } = props;
  const { t } = useTranslation();

  return (
    <form>
      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid md={4} xs={24} item>
          <Input label={`${t('Code')}`} control={control} name="code" variant="standard" />
        </Grid>
        <Grid md={4} xs={24} item>
          <Input
            label={`${t('Category')}`}
            control={control}
            name="categoryName"
            variant="standard"
          />
        </Grid>
        <Grid md={4} xs={24} item>
          <Input
            label={`${t('CategoryCode')}`}
            control={control}
            name="categoryCode"
            variant="standard"
          />
        </Grid>
        <Grid md={4} xs={24} item className={classes.buttonReset}>
          <FormControl>
            <Button variant="text" onClick={handleOnReset}>
              {t('Reset')}
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
};
