import { useTabs } from 'components/Tabs/useTabs';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { ApplicationUserTab } from 'schema/serverTypes';
import { useCurrentUserTabsQuery } from 'services/api/useApplicationUsersBackend';

export const useUserTabs = (initialTabId: { initialTabId?: number }) => {
  const { hash } = useLocation();

  const { data: userTabs = [], isLoading } = useCurrentUserTabsQuery();
  const initialTabIndex =
    hash === '#all' ? 1 : initialTabId ? userTabs.findIndex((tab) => tab.id === initialTabId) : 0;

  const tabsProps = useTabs(
    userTabs.map((t) => t.name),
    initialTabIndex
  );
  const { tabIndex, tabs } = tabsProps;
  const tab = userTabs.find((t) => t.name === tabs[tabIndex]);
  const tabId = tab !== undefined ? tab.id : ApplicationUserTab.mine;

  return useMemo(() => {
    return {
      tabId,
      isLoading,
      userTabs,
      ...tabsProps,
    };
  }, [tabId, isLoading, tabsProps, userTabs]);
};
