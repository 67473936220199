import { useDebounce } from 'use-debounce';
import { CounterpartyOption, CounterpartyType } from 'schema';
import { useDictionaryBackendQuery } from 'services';

const Counterparties = 'counterparties';

export const useCounterpartyQuery = (
  type: CounterpartyType,
  inputValue?: string,
  value?: string,
  isOnlyAccredited?: boolean
) => {
  const [input] = useDebounce(inputValue, 500);

  var requestUrl = Counterparties;
  const searchParams = new URLSearchParams();

  searchParams.set('type', type);

  if (input) {
    searchParams.set('name', input);
  }
  if (value) {
    searchParams.set('inn', value);
  }
  if (isOnlyAccredited) {
    searchParams.set('isOnlyAccredited', isOnlyAccredited ? 'true' : 'false');
  }
  requestUrl += `?${searchParams}`;

  const { data: options = [], refetch } = useDictionaryBackendQuery<CounterpartyOption[]>(
    requestUrl,
    {
      enabled: (input?.length ?? 0) > 0 || value !== undefined,
    }
  );

  return {
    options,
    refetch,
  };
};
