import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { useCallback } from 'react';
import { Chip } from 'components/Chips';
import { LeasingProductItemViewModel } from 'schema/serverTypes';

export type BrandChipsProps = {
  control: Control<LeasingProductItemViewModel, any>;
  setValue: UseFormSetValue<LeasingProductItemViewModel>;
};

export const BrandChips = (props: BrandChipsProps) => {
  const { control, setValue } = props;
  const brands = useWatch({ control, name: 'brands' });

  const onRemove = useCallback(
    (name: string) => {
      const values = brands?.filter((v) => v !== name);
      setValue('brands', values);
    },
    [brands, setValue]
  );

  const brandChips = brands?.map((value) => {
    return <Chip label={value} id={value} key={`brand-${value}`} onRemove={onRemove} />;
  });

  return <>{brandChips}</>;
};
