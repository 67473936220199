import {
  IndustrySpecializationFilter,
  IndustrySpecializationPagedList,
  IndustrySpecializationSorting,
  PageOptions,
} from 'schema/serverTypes';
import { BackendQueryOptions, createOptions, createUrlFromParts } from './types';
import { useBackendQuery } from './useBackend';

const entity = 'dictionaries/industries/specializations';

const useIndustrySpecializationBackend = <TResponse>(options: BackendQueryOptions<TResponse>) => {
  let queryKey = entity;
  if (options.relativeUrl && options.relativeUrl !== '') {
    queryKey = `${queryKey}-${options.relativeUrl}`;
  }
  if (options.searchParams) {
    queryKey = `${queryKey}-${options.searchParams}`;
  }
  return useBackendQuery(
    createUrlFromParts(entity, options.relativeUrl, options.searchParams),
    queryKey,
    createOptions(options.options)
  );
};

export type UseIndustrySpecializationListQueryProps = IndustrySpecializationFilter &
  Partial<IndustrySpecializationSorting> &
  PageOptions;

export const useIndustrySpecializationListQuery = (
  props: UseIndustrySpecializationListQueryProps
) => {
  const {
    code = '',
    industryName = '',
    isFinanced,
    isLicensed,
    description = '',
    order,
    sortBy,
    page = 1,
    pageSize = 20,
  } = props;
  let searchParams = new URLSearchParams();

  if (code !== '') {
    searchParams.append('code', code);
  }

  if (industryName !== '') {
    searchParams.append('industryName', industryName);
  }

  if (description !== '') {
    searchParams.append('description', description);
  }

  if (isFinanced !== undefined) {
    searchParams.append('isFinanced', isFinanced.toString());
  }

  if (isLicensed !== undefined) {
    searchParams.append('isLicensed', isLicensed.toString());
  }

  if (sortBy) {
    searchParams.append('sortBy', sortBy);
  }

  if (order) {
    searchParams.append('order', order);
  }

  searchParams.append('page', page.toString());
  searchParams.append('pageSize', pageSize.toString());

  return useIndustrySpecializationBackend<IndustrySpecializationPagedList>({
    searchParams,
    options: {
      refetchOnMount: true,
    },
  });
};
