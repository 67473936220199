import { useCallback, useState } from 'react';
import { Control, useController } from 'react-hook-form';
import { useDebounce } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Autocomplete, Button, Chip, ModalForm, useModalForm } from 'components';
import { CreateFormFieldValues } from '../types';
import { CounterpartyOption } from 'schema/serverTypes';
import { IconCheckbox } from '../../icons';
import { useDealersQuery } from './useDealersQuery';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    valueList: {
      padding: theme.spacing(1.5, 1, 0, 0),
      columnWidth: '48%',
      columnCount: 2,
      columnGap: '4%',
      [theme.breakpoints.up('lg')]: {
        columnWidth: '32%',
        columnCount: 3,
        columnGap: '2%',
      },
    },
    option: {
      marginTop: theme.spacing(-1),
      marginLeft: theme.spacing(-2),
      marginBottom: theme.spacing(-1),
    },
    error: {
      color: theme.palette.chartPurple.main,
      fontSize: 9,
      margin: '4px 0 0',
    },
    modalRoot: {
      textAlign: 'center',
    },
    modalTitle: {
      textAlign: 'center',
      marginBottom: theme.spacing(3),
    },
    modalText: {
      color: theme.palette.text.primary,
    },
    modalButton: {
      marginTop: theme.spacing(2),
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
  })
);

const getOptionLabel = (option: CounterpartyOption | null) => {
  if (option === null) {
    return '';
  }
  return `${option.inn} (${option.name})`;
};

const getOptionSelected = (option: CounterpartyOption | null, value: CounterpartyOption | null) => {
  if (option === null || value === null) {
    return false;
  }
  return option.inn === value.inn;
};

export type DealerSelectFieldProps = {
  control: Control<CreateFormFieldValues, object>;
  required?: boolean;
};

export const DealerSelectField = (props: DealerSelectFieldProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { control, required = false } = props;

  const {
    field: { value, onChange },
    fieldState: { invalid },
  } = useController({
    control,
    name: 'dealers',
    rules: required
      ? {
          required: {
            value: true,
            message: t('Required'),
          },
        }
      : undefined,
  });

  const helperText = invalid ? t('Required') : undefined;
  const [inputValue, setInputValue] = useState('');
  const [input] = useDebounce(inputValue, 500);
  const { onOpen, onClose, open: isModalOpen } = useModalForm();
  const [removedValues, setRemovedValues] = useState<CounterpartyOption[]>();
  const [dealer, setDealer] = useState<string>('');

  let { options } = useDealersQuery(input);

  if (options.length === 0 && value.length > 0) {
    options = value;
  }

  const handleOnInputChange = useCallback(
    (_ev: React.ChangeEvent<{}>, value: string) => {
      setInputValue(value);
    },
    [setInputValue]
  );

  const handleOnChange = useCallback(
    (_e: React.ChangeEvent<{}>, values: CounterpartyOption[], reason) => {
      onChange(values);
    },
    [onChange]
  );

  const handleOnRemove = useCallback(
    (name) => {
      const newValue = value.filter((t) => t.name !== name);
      setDealer(name);
      onOpen();
      setRemovedValues(newValue);
    },
    [value, onOpen]
  );

  const handleConfirmDelete = useCallback(() => {
    onChange(removedValues);
    onClose();
  }, [onChange, onClose, removedValues]);

  return (
    <>
      <Autocomplete<CounterpartyOption, true>
        error={invalid}
        helperText={helperText}
        multiple
        label={`${t('Search')}`}
        options={options}
        inputValue={inputValue}
        onInputChange={handleOnInputChange}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        onChange={handleOnChange}
        value={value}
        disableCloseOnSelect={true}
        renderTags={() => null}
        renderOption={(option: CounterpartyOption, { selected }) => {
          return (
            <div key={option.inn} className={classes.option}>
              <Checkbox
                icon={<IconCheckbox checked={false} />}
                checkedIcon={<IconCheckbox checked={true} />}
                checked={selected}
              />
              {option.name}
            </div>
          );
        }}
      />
      <div className={classes.valueList}>
        {value.map((dealer) => {
          return <Chip label={dealer.name} key={dealer.inn} onRemove={handleOnRemove} />;
        })}
      </div>
      <ModalForm open={isModalOpen} onClose={onClose}>
        <div className={classes.modalRoot}>
          <Typography variant={'h2'} className={classes.modalTitle}>
            Убрать дилера из списка
          </Typography>
          <Typography variant="subtitle2" className={classes.modalText}>
            Вы действительно хотите убрать <strong>{dealer}</strong>?
          </Typography>
          <div>
            <Button
              variant="contained"
              className={classes.modalButton}
              onClick={handleConfirmDelete}
            >
              {t('Yes')}
            </Button>
            <Button variant="outlined" onClick={onClose} className={classes.modalButton}>
              {t('No')}
            </Button>
          </div>
        </div>
      </ModalForm>
    </>
  );
};
