import { CardHeaderClassKey, StyleRules } from '@material-ui/core';
import palette from '../paletteOrange';

const style: Partial<StyleRules<CardHeaderClassKey>> = {
  root: {
    backgroundColor: palette.secondary.light,
  },
  title: {
    fontFamily: "'Halvar Breit', sans-serif",
    fontSize: 14,
    fontWeight: 700,
    color: palette.primary.main,
  },
};

export default style;
