import { QueryKey } from 'react-query';
import { useBackendQuery, QueryOptions, MutationOptions, useBackendMutation } from '.';
import { calculationUrl } from 'services/urls';

const createUrlFromParts = (relativeUrl?: string, searchParams?: URLSearchParams) => {
  const urlParts = relativeUrl ? relativeUrl.trim().split('/') : [];
  const parts = [calculationUrl, 'api', 'v1', 'news', ...urlParts].filter((t) => t !== '');
  const url = parts.join('/');
  return searchParams ? `${url}?${searchParams}` : url;
};

const createOptions = <TResponse, TQueryKey extends QueryKey = QueryKey>(
  options: QueryOptions<TResponse, TQueryKey> | undefined = {}
) => {
  const defaultOptions: QueryOptions<TResponse, TQueryKey> = {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: false,
  };
  return {
    ...defaultOptions,
    ...options,
  } as QueryOptions<TResponse, TQueryKey>;
};

export type NewsBackendQueryOptions<TResponse, TQueryKey extends QueryKey = QueryKey> = {
  relativeUrl?: string;
  searchParams?: URLSearchParams;
  queryKey: TQueryKey;
  options?: QueryOptions<TResponse, TQueryKey>;
};

export const useNewsBackendQuery = <TResponse, TQueryKey extends QueryKey = QueryKey>(
  options: NewsBackendQueryOptions<TResponse, TQueryKey>
) =>
  useBackendQuery(
    createUrlFromParts(options.relativeUrl, options.searchParams),
    options.queryKey,
    createOptions(options.options)
  );

export const useNewsBackendMutation = <TRequest, TResponse>(
  options: MutationOptions<TRequest, TResponse> | undefined = { method: 'POST' }
) => useBackendMutation(`${calculationUrl}/api/v1/news`, options);

export const useNewsEditMutation = <TRequest, TResponse, TContext = unknown>(
  newsId: string | ((form: TRequest) => string),
  options: MutationOptions<TRequest, TResponse, TContext> | undefined = { method: 'POST' }
) => useBackendMutation(`${calculationUrl}/api/v1/news/${newsId}`, options);

export const useNewsReadMutation = <TRequest, TResponse, TContext = unknown>(
  options: MutationOptions<TRequest, TResponse, TContext> | undefined = { method: 'POST' }
) => {
  return useBackendMutation(`${calculationUrl}/api/v1/news/read`, options);
};
