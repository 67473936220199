import { useWatch } from 'react-hook-form';
import { QuotaChipsProps, QuotaFilterFormContext } from './types';
import { useCallback, useContext } from 'react';
import { Chip } from 'components/Chips';
import { UserViewModel } from '../../users/types';

export const OwnersChips = (props: QuotaChipsProps) => {
  const { control, setValue } = props;
  const ownerId = useWatch({ control, name: 'ownerId' });

  const onRemove = useCallback(
    (id: string) => {
      const values = ownerId?.filter((v) => v !== id);
      setValue('ownerId', values);
    },
    [ownerId, setValue]
  );

  const usersFromSession: UserViewModel[] = sessionStorage.quotaFilterUsers
    ? JSON.parse(sessionStorage.quotaFilterUsers)
    : null;
  const { users: usersFromContext } = useContext(QuotaFilterFormContext);
  const users = usersFromSession || usersFromContext;

  const ownerChips = ownerId?.map((value) => {
    const user = users.find((t) => t.id === value);
    const label = user?.name ?? value;
    return <Chip label={label} id={value} key={`managerId-${value}`} onRemove={onRemove} />;
  });

  return <>{ownerChips}</>;
};
