import { Theme, createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      backgroundColor: theme.palette.background.default,
      '&$total': {
        backgroundColor: theme.palette.background.paper,
      },
      '&&&:hover td': {
        backgroundColor: 'transparent',
      },
      '&& td': {
        borderBottom: '4px solid' + theme.palette.background.paper,
      },
    },
    total: {},
    name: {
      fontSize: 12,
      marginRight: theme.spacing(2),
      '&&': {
        padding: 0,
      },
      '&& a': {
        color: theme.palette.common.black,
      },
    },
    fill: {
      width: '100%',
      height: '100%',
      padding: theme.spacing(0.75),
    },
    shipment: {
      width: 70,
      borderLeft: '1px solid transparent',
      borderRight: '1px solid transparent',
      fontSize: 12,
      textAlign: 'center',
      padding: theme.spacing(0.75),
      marginRight: theme.spacing(2),
      '&& a': {
        color: theme.palette.common.black,
      },
    },
    value: {
      width: 100,
      fontSize: 12,
      textAlign: 'right',
      padding: theme.spacing(0.75),
      '&$amount': {
        width: 112,
      },
      '&&': {
        padding: theme.spacing(0.75),
      },
    },
    amount: {},
    link: {
      cursor: 'pointer',
    },
  })
);
