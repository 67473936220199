import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useIssueControlListQuery } from '../../services/api/useIssueControlBackend';
import { IssueControl as IssueControlType, IssueType, ReportShipmentViewModel } from 'schema';
import { IconCheckbox } from 'components';
import { UseFormSetValue } from 'react-hook-form';
import { Checkbox as MuiCheckbox, FormControlLabel } from '@material-ui/core';
import { ChangeEvent, useEffect, useState } from 'react';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    label: {
      whiteSpace: 'nowrap',
      fontFamily: 'Roboto, Helvetica, Arial, san-serif',
    },
    columns: {
      columns: 2,
    },
  }),
);

type IssueControlProps = {
  issueType: IssueType;
  controls?: IssueControlType[];
  setValue: UseFormSetValue<ReportShipmentViewModel>;
};

export const IssueControlAdd = (props: IssueControlProps) => {
  const classes = useStyles();
  const { issueType, setValue } = props;
  const [controls, setControls] = useState<IssueControlType[]>([]);

  const { data } = useIssueControlListQuery({
    types: [issueType],
  });

  const toggleCheckbox = (ev: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      setControls((prev: IssueControlType[]) => {
        return [
          ...prev,
          {
            text: ev.target.name,
            issueType,
            id: parseInt(ev.target.id, 10),
            sortOrderNumber: 0,
          },
        ];
      });
    } else {
      setControls((prev: IssueControlType[]) => {
        const newControls = prev.filter((item) => item.text !== ev.target.name);
        return [...newControls];
      });
    }
  };

  useEffect(() => setValue('controls', controls), [controls, setValue]);

  const checkboxArray = data?.data.map((item) => {
    return (
      <li key={item.id}>
        <FormControlLabel
          classes={{
            root: classes.root,
            label: classes.label,
          }}
          control={
            <MuiCheckbox
              name={item.text}
              id={item.id.toString()}
              checkedIcon={<IconCheckbox checked={true} />}
              icon={<IconCheckbox checked={false} />}
              onChange={toggleCheckbox}
            />
          }
          label={item.text}
        />
      </li>
    );
  });

  const columnsClass = checkboxArray && checkboxArray?.length > 4 ? classes.columns : '';

  return (
    <div className={classes.root}>
      <ul className={columnsClass}>{checkboxArray}</ul>
    </div>
  );
};
