import { RatingCalculationContext, RatingInput } from 'schema/serverTypes';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { formatNumber } from 'components/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      display: 'grid',
      gridTemplateColumns: '4fr 1fr 1fr 1fr 1fr',
      boxSizing: 'border-box',
      gridGap: 1,
      fontSize: 12,
    },
    th: {
      backgroundColor: theme.palette.secondary.light,
      boxShadow: '0 0 0 1px' + theme.palette.secondary.light,
      padding: theme.spacing(1.5, 1),
      borderTop: '4px solid white',
      marginBottom: -1,
      zIndex: 1,
      '&:first-child ': {
        borderLeft: '4px solid white',
      },
      '&$last': {
        borderRight: '4px solid white',
      },
    },
    last: {},
    td: {
      backgroundColor: 'white',
      boxShadow: '0 0 0 1px #E3E6F3',
      padding: theme.spacing(0.75, 1),
    },
    totalLabel: {
      fontWeight: 600,
      gridColumn: '1/5',
    },
    totalValue: {
      fontWeight: 600,
    },
    right: {
      textAlign: 'right',
    },
  })
);

type RatingParamsProps = {
  input: RatingInput;
  context: RatingCalculationContext;
};

export const RatingFinancialParams = (props: RatingParamsProps) => {
  const classes = useStyles();

  const { context } = props;

  const ratios = ['Debt Cover Ratio', 'Liquidity Ratio', 'Profitability Ratio', 'Solvency Ratio'];

  return (
    <div className={classes.table}>
      <div className={classes.th}>Финансовые коэффициенты</div>
      <div className={clsx(classes.th, classes.right)}>Финальный коэффициент</div>
      <div className={clsx(classes.th, classes.right)}>Оценка показателя</div>
      <div className={clsx(classes.th, classes.right)}>Вес</div>
      <div className={clsx(classes.th, classes.right, classes.last)}>
        Взвешенная оценка значения
      </div>

      {ratios.map((title, index) => {
        return <RatioItem title={title} index={index} context={context} key={title} />;
      })}

      <div className={clsx(classes.td, classes.totalLabel)}>
        Общий балл по колличественным показателям
      </div>
      <div className={clsx(classes.td, classes.right, classes.totalValue)}>
        {formatNumber(context.totalQuan)}
      </div>
    </div>
  );
};

const RatioItem = ({
  title,
  index,
  context,
}: {
  title: string;
  index: number;
  context: RatingCalculationContext;
}) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.td}>{title}</div>
      <div className={clsx(classes.td, classes.right)}>
        {formatNumber(context.finalRatios[index])}
      </div>
      <div className={clsx(classes.td, classes.right)}>
        {formatNumber(context.adjustedRatioScores[index])}
      </div>
      <div className={clsx(classes.td, classes.right)}>
        {formatNumber(context.ratioWeights[index])}%
      </div>
      <div className={clsx(classes.td, classes.right)}>
        {formatNumber(context.weightedRatioScores[index])}
      </div>
    </>
  );
};
