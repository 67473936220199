import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { Input, Button } from 'components';
import { Grid } from 'components/Grid';
import { useTranslation } from 'react-i18next';
import { MissingLesseeAutocomplete } from './MissingLesseeAutocomplete';
import { MissingDocumentTypeAutocomplete } from './MissingDocumentTypeAutocomplete';
import { MissingStatusAutocomplete } from './MissingStatusAutocomplete';
import { MissingFilterFormProps } from './types';
import { Switch } from 'components/form';
import { Tabs, useTabs } from '../Tabs';
import { useController, useWatch } from 'react-hook-form';
import { useCallback } from 'react';
import { MissingChips } from './MissingChips';
import { MissingManagerAutocomplete } from './MissingManagerAutocomplete';
import { useCurrentUserTabsQuery } from 'services/api';
import { ApplicationUserTab } from '../../schema';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '100%',
    },
    buttonReset: {
      display: 'flex',
      alignItems: 'flex-end',
      marginBottom: theme.spacing(0.2),
    },
    number: {
      '& input': {
        textAlign: 'right',
      },
    },
  })
);

export const MissingFilterForm = (props: MissingFilterFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { control, onReset, setValue } = props;
  const {
    field: { onChange: onTabIdChange },
  } = useController({ control, name: 'tabId' });
  const tabId = useWatch({ control, name: 'tabId' }) ?? 0;

  const { data: userTabs = [] } = useCurrentUserTabsQuery();
  const tabsProps = useTabs(
    userTabs.map((t) => t.name),
    tabId === ApplicationUserTab.all ? 1 : 0
  );
  const { tabIndex, onChangeTab, tabs } = tabsProps;

  const onMineChanged = useCallback(
    (e: any, index: number) => {
      onChangeTab(e, index);
      const tab = userTabs.find((t) => t.name === tabs[index]);
      if (tab) {
        onTabIdChange(tab.id);
      }
    },
    [onChangeTab, onTabIdChange, userTabs, tabs]
  );

  const debt = useWatch({ control, name: 'debt' });

  return (
    <form>
      <Box mb={2.5} display="flex" justifyContent="space-between" alignItems="flex-end">
        <Box>
          <Tabs {...tabsProps} value={tabIndex} onChangeTab={onMineChanged} />
        </Box>
        <Box>
          <Switch label={`${t('Debt')}`} name="debt" control={control} labelOn={''} labelOff={''} />
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mb={2.5} alignItems="flex-end">
        <Box flex={1}>
          <Grid container columnSpacing={2} rowSpacing={2.5} className={classes.root}>
            {tabId !== 0 && (
              <Grid md={4} xs={24} item>
                <MissingManagerAutocomplete control={control} />
              </Grid>
            )}
            <Grid md={4} xs={24} item>
              <MissingLesseeAutocomplete control={control} />
            </Grid>
            <Grid md={4} xs={24} item>
              <Input
                label={t('Contract')}
                control={control}
                name="contractNumber"
                variant="standard"
              />
            </Grid>
            <Grid md={4} xs={24} item>
              <MissingDocumentTypeAutocomplete control={control} />
            </Grid>
            <Grid md={5} xs={24} item>
              <MissingStatusAutocomplete control={control} />
            </Grid>
            {debt === true && (
              <Grid md={2} xs={24} item>
                <Input
                  label={t('Debt days')}
                  control={control}
                  name="debtDays"
                  variant="standard"
                  className={classes.number}
                  rules={{
                    required: true,
                    pattern: /^[0-9,-]+$/i,
                  }}
                />
              </Grid>
            )}
            <Grid md={3} xs={24} item className={classes.buttonReset}>
              <FormControl>
                <Button variant="text" onClick={onReset}>
                  {t('Reset')}
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box mb={1}>
        <MissingChips control={control} setValue={setValue} hideManagers={tabId === 0} />
      </Box>
    </form>
  );
};
