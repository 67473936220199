import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { ShipmentViewModel, UpdateShipmentExpectedDateRequest } from 'schema/serverTypes';
import { useQuotasBackendMutation } from 'services/api';

export const useUpdateExpectedDateMutation = (quotaId: number, id: number) => {
  const queryClient = useQueryClient();

  const { mutateAsync } = useQuotasBackendMutation<
    UpdateShipmentExpectedDateRequest,
    ShipmentViewModel
  >(`${quotaId}/shipment/item/expectedDate`, {
    method: 'PUT',
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey.indexOf('shipment') > -1 || query.queryKey.indexOf('pipeline') > -1;
        },
      });
    },
  });

  const submit = useCallback(
    async (expectedShipmentDate: string) => {
      return await mutateAsync({ quotaId, id, expectedShipmentDate });
    },
    [quotaId, id, mutateAsync]
  );

  return submit;
};
