import palette from '../paletteOrange';

const style = {
  option: {
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"]': {
      backgroundColor: 'transparent',
    },
    '&:hover': {
      backgroundColor: palette.primary.light,
    },
  },
  paper: {
    fontFamily: 'Roboto, Helvetica, Arial, san-serif',
  },
  inputRoot: {
    '&&[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]': {
      padding: '0',
      '& $input': {
        padding: '12px 10px 8px',
      },
    },
    '&&[class*="MuiFilledInput-root"][class*="MuiFilledInput-marginDense"]': {
      paddingTop: 1,
      paddingLeft: 0,
      '& $input': {
        paddingLeft: 0,
      },
      '&:hover': {
        backgroundColor: palette.background.paper,
      },
      '&.Mui-focused': {
        backgroundColor: palette.background.paper,
      },
    },
  },
};

export default style;
