import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Currency, NewPipelineReportRowItem } from 'schema/serverTypes';
import { Grid } from '../../../Grid';
import { formatMoney, formatNumber } from '../../../utils';
import clsx from 'clsx';
import { User } from '../../../users';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: 12,
      marginBottom: theme.spacing(1.5),
    },
    head: {
      background: theme.palette.error.light,
      padding: theme.spacing(0.5, 1.5),
      textAlign: 'right',
    },
    value: {
      padding: theme.spacing(0.5, 1.5),
      textAlign: 'right',
    },
    userHead: {
      textAlign: 'left',
    },
    userName: {
      color: theme.palette.text.secondary,
      textAlign: 'left',
    },
  })
);

type PipelineUserTableProps = {
  users: NewPipelineReportRowItem[];
};

export const PipelineUserTable = (props: PipelineUserTableProps) => {
  const classes = useStyles(props);
  const { users } = props;

  return (
    <Grid container className={classes.root}>
      <Grid item xs={24} md={4} className={clsx(classes.head, classes.userHead)}>
        Sales manager
      </Grid>
      <Grid item xs={24} md={4} className={classes.head}>
        Ожидается за период
      </Grid>
      <Grid item xs={24} md={4} className={classes.head}>
        Отгружено
      </Grid>
      <Grid item xs={24} md={4} className={classes.head}>
        Committed
      </Grid>
      <Grid item xs={24} md={4} className={classes.head}>
        No Committed
      </Grid>
      <Grid item xs={24} md={4} className={classes.head}>
        Lost sale
      </Grid>
      {users.map((user) => (
        <PipelineUserRow {...user} key={user.id} />
      ))}
    </Grid>
  );
};

export const PipelineUserRow = (props: NewPipelineReportRowItem) => {
  const classes = useStyles(props);
  const {
    name,
    isActive,
    awaitingCount,
    awaitingAmount,
    readyCount,
    readyAmount,
    readyMargin,
    commitedCount,
    commitedAmount,
    noCommitedCount,
    noCommitedAmount,
    lostSale,
    margin,
  } = props;

  return (
    <>
      <Grid item xs={24} md={4} className={clsx(classes.value, classes.userName)}>
        <User name={name} isActive={isActive} />
      </Grid>
      <Grid item xs={24} md={4} className={classes.value}>
        {awaitingCount} | {formatMoney({ amount: awaitingAmount, currency: Currency.Ruble })} |{' '}
        {formatNumber(margin, 1)}%
      </Grid>
      <Grid item xs={24} md={4} className={classes.value}>
        {readyCount} | {formatMoney({ amount: readyAmount, currency: Currency.Ruble })} |{' '}
        {formatNumber(readyMargin, 1)}%
      </Grid>
      <Grid item xs={24} md={4} className={classes.value}>
        {commitedCount} | {formatMoney({ amount: commitedAmount, currency: Currency.Ruble })}
      </Grid>
      <Grid item xs={24} md={4} className={classes.value}>
        {noCommitedCount} | {formatMoney({ amount: noCommitedAmount, currency: Currency.Ruble })}
      </Grid>
      <Grid item xs={24} md={4} className={classes.value}>
        {formatMoney({ amount: lostSale, currency: Currency.Ruble })}
      </Grid>
    </>
  );
};
