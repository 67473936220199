import { useTranslation } from 'react-i18next';
import { FixedAmountInput } from '../AmountInput';
import { AmountType, Currency } from 'schema/serverTypes';
import { useCurrencyExchangerContext } from '../../CurrencyExchangerProvider';
import { useCalculationFormContext } from '../types';
import { useWatch } from 'react-hook-form';
import { useEffect, useRef } from 'react';

export const CurrencyRateInput = () => {
  const { t } = useTranslation();

  const { displayRate } = useCurrencyExchangerContext();
  const { control, setValue, getValues } = useCalculationFormContext();

  const currencySale = useWatch({
    control,
    name: 'currencySale',
  });

  const currencyLease = useWatch({
    control,
    name: 'currencyLease',
  });

  const disabled = currencyLease === currencySale;

  const initialSet = useRef(false);

  useEffect(() => {
    const sale = currencySale as Currency;
    const lease = getValues('currencyLease') as Currency;
    const currentRate = getValues('currencyRate');

    const rate = displayRate(sale, lease, initialSet.current === false ? currentRate : undefined);

    if (initialSet.current === false) {
      initialSet.current = true;
    }

    if (currentRate !== rate) {
      setValue('currencyRate', rate);
    }
  }, [currencySale, displayRate, setValue, getValues]);

  return (
    <FixedAmountInput
      name="currencyRate"
      label={t('Currencies.Rate')}
      amountMode={AmountType.Money}
      fractionDigits={4}
      disabled={disabled}
    />
  );
};
