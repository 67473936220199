import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(1, 1.25),
      display: 'flex',
      alignItems: 'center',
      backgroundColor: (props: WarningProps) => {
        return props.bgColor ? props.bgColor : 'rgba(255, 245, 224, 1)';
      },
    },
    icon: {
      display: 'inline-block',
      marginRight: theme.spacing(1),
    },
    text: {
      display: 'flex',
      alignItems: 'center',
    },
  })
);

type RGB = `rgb(${number}, ${number}, ${number})`;
type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
type HEX = `#${string}`;

type Color = RGB | RGBA | HEX;

type WarningProps = {
  icon?: React.ReactNode;
  bgColor?: Color;
  children: React.ReactNode;
};

export const Warning = (props: WarningProps) => {
  const classes = useStyles(props);
  const { icon, children } = props;

  return (
    <Box className={classes.root}>
      <div className={classes.icon}>{icon}</div>
      {children}
    </Box>
  );
};
