import { IconSprite } from 'components/icons/IconSprite';
import { themeOrange as theme } from 'theme';
import { IconButton } from '@material-ui/core';

export type EditButtonProps = {
  onClick: () => void;
};

export const EditButton = (props: EditButtonProps) => {
  const { onClick } = props;

  return (
    <IconButton aria-label="edit" onClick={onClick}>
      <IconSprite
        width="14px"
        color={theme.palette.text.primary}
        hoverColor={theme.palette.primary.main}
        icon="edit"
      />
    </IconButton>
  );
};
