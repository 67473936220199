import React, { useCallback } from 'react';
import { Field, useField } from 'react-final-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useAutocompleteFieldProps } from '../useAutocompleteFieldProps';
import { AutocompleteFieldProps, InputChangeCallback } from '../types';
import { useRequiredOnSave } from '../requiredOnSave';
import { useTranslation } from 'react-i18next';
import { createStyles, InputAdornment, makeStyles, TextField, Theme } from '@material-ui/core';
import { WarningTooltip } from 'components/form';
import { useNomenclatureQuery } from '../useNomenclatureQuery';
import { AutocompleteChangeReason } from '@material-ui/lab/useAutocomplete';
import { NomenclatureViewModel } from 'schema';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
        paddingTop: 0,
        paddingBottom: 0,
      },
      '& .MuiInputLabel-outlined': {
        transform: 'translate(14px, 11px) scale(1)',
        '&.MuiInputLabel-shrink': {
          transform: 'translate(9px, -6px) scale(0.75)',
        },
      },
    },
    warningIcon: {
      marginTop: 2,
      cursor: 'pointer',
      '&$warningIcon svg': {
        fill: theme.palette.darkAttention.main,
        cursor: 'pointer',
        pointerEvents: 'auto',
      },
    },
  })
);

export type ModelAutocompleteProps = AutocompleteFieldProps<true> & {
  code: string;
  nomenclatureName: string;
  setNomenclatureCode: (name: string, value: string | null) => void;
  quotaId?: number;
  onOpenNomenclatures: () => void;
};

const getOptionLabel = (option: NomenclatureViewModel) => {
  if (typeof option === 'string') {
    return option;
  }

  return option.name;
};

const ModelAutocomplete = (props: ModelAutocompleteProps) => {
  const classes = useStyles();
  const {
    label,
    quotaId,
    setNomenclatureCode,
    code,
    nomenclatureName,
    onOpenNomenclatures,
    ...other
  } = props;

  const {
    input: { value: taskId },
  } = useField(`${nomenclatureName}.taskId`);
  const codeName = `${nomenclatureName}.code`;

  const { onChange, inputValue, setInputValue, resetInputValue, ...rest } =
    useAutocompleteFieldProps(other);
  const { options } = useNomenclatureQuery(inputValue);

  const handleOnInputChange: InputChangeCallback = useCallback(
    (event, value, reason) => {
      if (setInputValue && reason === 'input' && (value || value === '')) {
        setInputValue(value);
        setNomenclatureCode(codeName, null);
      } else if (setInputValue && reason === 'clear') {
        setInputValue('');
        setNomenclatureCode(codeName, null);
      }
      onChange(event, value, reason);
    },
    [onChange, setInputValue, setNomenclatureCode, codeName]
  );

  const handleOnChange = useCallback(
    (_, value, reason: AutocompleteChangeReason) => {
      if (value && reason === 'select-option') {
        if (setInputValue) {
          setInputValue(value.name);
        }
        setNomenclatureCode(codeName, value.code);
      }
    },
    [setNomenclatureCode, codeName, setInputValue]
  );

  return (
    <Autocomplete<NomenclatureViewModel, false, false, true>
      freeSolo
      {...rest}
      options={options}
      onChange={handleOnChange}
      onInputChange={handleOnInputChange}
      getOptionLabel={getOptionLabel}
      getOptionSelected={(option, value) => option.code === value.code}
      inputValue={inputValue}
      disabled={!!taskId}
      renderInput={(params) => (
        <div>
          <TextField
            {...params}
            className={classes.input}
            label={label}
            error={!other.meta.valid && other.meta.touched}
            helperText={!other.meta.valid && other.meta.touched ? other.meta.error : null}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              startAdornment:
                quotaId && !code && inputValue! !== '' ? (
                  <InputAdornment position="start">
                    <WarningTooltip
                      arrow
                      placement="bottom-start"
                      className={classes.warningIcon}
                      onOpenNomenclatures={onOpenNomenclatures}
                      taskIds={taskId ? [taskId] : undefined}
                    />
                  </InputAdornment>
                ) : null,
            }}
            fullWidth
          />
        </div>
      )}
    />
  );
};

export const ModelAutocompleteField = (props: {
  name: string;
  code: string;
  setNomenclatureCode: (name: string, value: string) => void;
  quotaId?: number;
  onOpenNomenclatures: () => void;
}) => {
  const { requiredOnSave } = useRequiredOnSave();
  const { t } = useTranslation();

  return (
    <Field
      name={`${props.name}.name`}
      nomenclatureName={props.name}
      quotaId={props.quotaId}
      code={props.code}
      setNomenclatureCode={props.setNomenclatureCode}
      onOpenNomenclatures={props.onOpenNomenclatures}
      label={t('Lease subject')}
      component={ModelAutocomplete}
      validate={requiredOnSave}
    />
  );
};
